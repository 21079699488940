import { Popover, useMantineTheme, Group, useComputedColorScheme } from '@mantine/core';
import { Button } from '../../components/Button';
import { Bookmarks } from 'tabler-icons-react';
import { SavedViewsContent } from './SavedViewsContent';
import { useSavedViewsStore } from './store';

export const SavedViewsButton = () => {
  // const SavedViewsOpen = useSavedViewsStore((state) => state.isOpen);
   const closeOnClickOutside = useSavedViewsStore((state) => state.clickOutside);

  const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');

  return (
    <Popover
      position="left-start"
      withArrow
      arrowOffset={20}
      styles={{
        arrow: {
          border: `1px ${theme.colors.gray[6]} solid`,
          backgroundColor:
            colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[1]
        },
        dropdown: {
          border: `1px ${theme.colors.gray[6]} solid`,
          backgroundColor:
            colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[1]
        }
      }}
      closeOnClickOutside={closeOnClickOutside}
    >
      <Popover.Target>
        <Group>
          <Button
            testid="manage-saved-views"
            variant="subtle"
            leftSection={<Bookmarks style={{ marginRight: -2 }} size={24} />}
            style={{
              color: 'gray'
            }}
            tooltip={{
              label: 'Manage Saved Views',
              position: 'top',
              color: colorScheme === 'dark' ? 'gray' : 'black'
            }}
          />
        </Group>
      </Popover.Target>
      <Popover.Dropdown>
        <div>
          <SavedViewsContent />
        </div>
      </Popover.Dropdown>
    </Popover>
  );
};

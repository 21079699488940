import { Center, Group, useComputedColorScheme, lighten, darken } from '@mantine/core';
import { Button, StatusColor, StatusIcon } from '../../components';
import { iOfferingStatusCount } from '../Views/models';
import { capitalize } from '../../utils/utilities';
import { iStatusButtons } from './models';
import { useViewStore } from '../Views/store';
import { useStatusFilterStore } from './store';
import { useSearchStore } from '../Search/store';
import { useStatusCountsStore } from '../StatusCounts';

export const StatusFilterButtons = () => {
  const colorScheme = useComputedColorScheme('light');
  const statusColor = StatusColor();
  const gridCurrentAPI = useViewStore((state) => state.grid.current?.api);
  const statusCounts = useStatusCountsStore((state) => state.offeringStatusCounts);
  const statusButtonClicked = useStatusFilterStore((state) =>
    state.GetStatusButtonClicked()
  );
  const setStatusButtonClicked = useStatusFilterStore(
    (state) => state.SetStatusButtonClicked
  );
  const isSearching = useSearchStore((state) => state.isSearching);

  const onClick = (event: any) => {
    const statusFilters = gridCurrentAPI.getFilterInstance('status');
    const filterKeys = statusFilters.getFilterKeys(); 
    let filter: string[] = [];
    if (event.currentTarget.value === 'pending') {
      filter = ['queued'];
    } else if (event.currentTarget.value === 'completed') {
      filter = ['skipped'];
    }
    filterKeys.forEach((k: string) => {
      if (k?.toLowerCase().includes(event.currentTarget.value)){
        filter.push(k?.toLowerCase())
      }
    });
    
    statusFilters.setModel({
      values: filter,
      filterType: 'set'
    });
    gridCurrentAPI.onFilterChanged();
    let newButtonClicked: iStatusButtons = {
      error: false,
      failure: false,
      processing: false,
      pending: false,
      completed: false
    };
    newButtonClicked[event.currentTarget.value as keyof typeof newButtonClicked] = true;
    setStatusButtonClicked(newButtonClicked);
  };

  const createStatusFilterButton = (stat: string) => {
    let border = statusButtonClicked[stat as keyof typeof statusButtonClicked] ? 3 : 1;
    let disabled = isSearching
      ? true
      : statusCounts[stat as keyof iOfferingStatusCount] !== 0
      ? false
      : true;
    return (
      <Button
        testid={`status-filter-${stat}`}
        noIcon
        disabled={disabled}
        key={stat}
        size="xs"
        onClick={onClick}
        variant="outline"
        value={stat}
        style={{      
          opacity: 0.7 
        }}
        styles={(theme) => ({
          root: {
            background:
              colorScheme === 'dark'
                ? lighten(statusColor[stat], 0.4)
                : lighten(statusColor[stat], 0.8),
            border: `${border}px ${statusColor[stat]} solid`,
            color:
              colorScheme === 'dark'
                ? darken(statusColor[stat], 0.4)
                : lighten(statusColor[stat], 0.2),
            // '&:not([data-disabled])': theme.fn.hover({
            //   backgroundColor:
            //     colorScheme === 'dark'
            //       ? lighten(statusColor[stat], 0.4)
            //       : lighten(statusColor[stat], 0.6)
            // })
            // // '&:hover': {
            // //   backgroundColor:
            // //     theme.colorScheme === 'dark'
            // //       ? theme.fn.lighten(statusColor[stat], 0.4)
            // //       : theme.fn.lighten(statusColor[stat], 0.6)
            // // }
          }
        })}
      >
        &nbsp;
        <StatusIcon
          disabled={disabled}
          dark={true}
          status={stat}
          size={10}
        />
        &nbsp; {capitalize(stat)}: {statusCounts[stat as keyof iOfferingStatusCount]}
      </Button>
    );
  };

  return (
    <Center>
      <Group>
        {['error', 'failure', 'processing', 'pending', 'completed'].map(
          createStatusFilterButton
        )}
      </Group>
    </Center>
  );
};

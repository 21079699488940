import { Checkbox as MCheckbox, CheckboxProps } from '@mantine/core';
import {useTID} from '../../helpers/useTestId'

interface ICheckboxProps extends CheckboxProps {
  testid: string;
}

export const Checkbox = (props: ICheckboxProps) => {
  const TID = useTID('Checkbox', props.testid)

  return (
    <MCheckbox {...TID} {...props}>
      {props.children}
    </MCheckbox>
  );
};

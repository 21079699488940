import { FootprintsForm } from './_scdFootprintsForm';
import { SCDFootprintsTable } from './_scdFootprintsTable';
import { StatusCodeFootprint } from './models';
// import { useStatusCodeStore } from './store';

export const SCDFootprints = (footprints: StatusCodeFootprint[]) => {
  return (
    <>
      <FootprintsForm />
      <SCDFootprintsTable withButtons={true} />
    </>
  );
};

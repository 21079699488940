import { CodeHighlight } from '@mantine/code-highlight';
import { fetchEventData } from '../../../api/fetchEventData';
import { Anchor, Popover, ScrollArea, Text, Table } from '@mantine/core';
import { DetailAccordionItem } from '../../../components/DetailAccordion';

export const showManifestationAccordionItems = (k: any, width: number, classes: any) => {
  const manifestationJSON = k.data? typeof k.data === 'string' ? JSON.stringify(JSON.parse(k.data), null, 2) : k.data : null
  if (!manifestationJSON) {
    return
  }
  const label = k.label.split('Manifestation');
  const capitalLabel = label[0].charAt(0).toUpperCase() + label[0].slice(1);
  return (
    <Table.Tr key={capitalLabel + 'table'}>
      <Table.Td style={{ textAlign: 'left', maxWidth: 30 }}>{capitalLabel}</Table.Td>
      <Table.Td>
        <Popover
          key={k.label + 'Popover'}
          position="right"
          withArrow
          shadow="md"
          styles={{
            dropdown: {
              marginTop: 0
            }
          }}
          classNames={{
            arrow: 'popoverArrow',
            dropdown: 'popoverDropdown'
          }}
          zIndex={9999}
        >
          <Popover.Target>
            <Table.Td key={k.label + 'PopoverTarget'}>
              <Anchor type="button">Manifestation</Anchor>
            </Table.Td>
          </Popover.Target>
          <Popover.Dropdown>
            <Text fw={700}>{capitalLabel} Manifestation JSON</Text>
            <ScrollArea h={425}>
              <CodeHighlight maw={600} code={manifestationJSON} language="tsx" />
            </ScrollArea>
          </Popover.Dropdown>
        </Popover>
      </Table.Td>
    </Table.Tr>
  );
};

export async function fetchManifestations(tasks: any, setManifestations: any) {
  console.log(tasks)
  const manifestationTasks = tasks?.filter(
    (t: { type: string }) =>
      t.type === 'mezzcreation' ||
      t.type === 'pickpackrules' ||
      t.type === 'mezzgtpjobmonitor'
  );
  if (manifestationTasks) {
    let manifestationData: any[] = [];
    for (let i = 0; i < manifestationTasks.length; i++) {
      const result = await fetchEventData(manifestationTasks[i].eventMetaId, true);
      if (result && result.length > 0) {
        result[0].resolved?.forEach((r: any) => {
          manifestationData.push(r);
        });
      }
    }
    const sortOrder = [
      'sourceManifestation',
      'targetManifestation',
      'outputManifestation',
      'presentationManifestation'
    ];
    const sortByObject = sortOrder.reduce((obj, item, index) => {
      return {
        ...obj,
        [item]: index
      };
    }, {});
    const customSort = manifestationData.sort(
      (a, b) =>
        sortByObject[a.label as keyof typeof sortByObject] -
        sortByObject[b.label as keyof typeof sortByObject]
    );
    const filteredCustomSort = customSort.filter((i) => i.data !== 'undefined');
    console.log(filteredCustomSort)
    setManifestations(filteredCustomSort);
  }
}

export const latestManifestationAccordion = (
  manifestations: any,
  manifestationDetails: (id: string) => string | undefined,
  width: number
) => {
  return (
    manifestations.length > 0 && (
      <DetailAccordionItem
        testid={`DetailAccordionItem-Manifestations`}
        key="item-4-manifestations"
        name="item-4"
        title="Latest Manifestations"
        styles={{ borderBottom: 0 }}
      >
        <Table
          striped={false}
          highlightOnHover={true}
          style={{ fontSize: 'xs', width: '110%', margin: -16, marginTop: -32 }}
        >
          <Table.Thead>
            <Table.Tr>
              <Table.Td>&nbsp;</Table.Td>
              <Table.Td>&nbsp;</Table.Td>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {manifestations.map((k: any) =>
              showManifestationAccordionItems(k, width, '')
            )}
            {manifestationDetails('fulfilledAudioComponents') && (
              <Table.Tr>
                <Table.Td style={{ textAlign: 'left', minWidth: 80 }}>
                  Audio Languages
                </Table.Td>
                <Table.Td>{manifestationDetails('fulfilledAudioComponents')}</Table.Td>
              </Table.Tr>
            )}
            {manifestationDetails('fulfilledTimedTextComponents') && (
              <Table.Tr>
                <Table.Td style={{ textAlign: 'left' }}>TimedText Languages</Table.Td>
                <Table.Td>
                  {manifestationDetails('fulfilledTimedTextComponents')}
                </Table.Td>
              </Table.Tr>
            )}
            {manifestationDetails('fulfilledMarkers') && (
              <Table.Tr>
                <Table.Td style={{ textAlign: 'left' }}>Markers</Table.Td>
                <Table.Td>{manifestationDetails('fulfilledMarkers')}</Table.Td>
              </Table.Tr>
            )}
            {manifestationDetails('fulfilledAnnotationsLabels') && (
              <Table.Tr>
                <Table.Td style={{ textAlign: 'left' }}>Annotations</Table.Td>
                <Table.Td>{manifestationDetails('fulfilledAnnotationsLabels')}</Table.Td>
              </Table.Tr>
            )}
            {manifestationDetails('fulfilledMediaDuration') && (
              <Table.Tr>
                <Table.Td style={{ textAlign: 'left' }}>Duration</Table.Td>
                <Table.Td>{manifestationDetails('fulfilledMediaDuration')}</Table.Td>
              </Table.Tr>
            )}
            {manifestationDetails('dynamicRangeIndicator') && (
              <Table.Tr>
                <Table.Td style={{ textAlign: 'left' }}>Dynamic Range</Table.Td>
                <Table.Td>{manifestationDetails('dynamicRangeIndicator')}</Table.Td>
              </Table.Tr>
            )}
          </Table.Tbody>
        </Table>
      </DetailAccordionItem>
    )
  );
};

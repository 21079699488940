export const getAxiosConfig = () => {
  let OKTAConfigStr: string = localStorage!.getItem('okta-token-storage')!;
  let OKTAConfig: any = { accessToken: { accessToken: '' } };
  let bearerToken = {};
  OKTAConfig = JSON.parse(OKTAConfigStr);
  bearerToken = OKTAConfig.accessToken?.accessToken;
  return {
    OKTAConfig,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json'
    }
  };
};

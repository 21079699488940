import { ProcessTaskGrids } from '../components/DataPanels/PFDR/ProcessTaskGrids';
import { Timeline } from '../components/Timeline';
import { PFDR, OverallStatus } from './../components/DataPanels/PFDR/PFDR';

export const maxRecordsToFetch = 10000;

export const milestoneHeaders = {
  OverallStatus: {
    headerName: 'Overall Status',
    dataField: 'status',
    component: OverallStatus
  },
  AcquisitionOverallStatus: {
    headerName: 'Overall Status',
    dataField: 'status.acquisition',
    component: OverallStatus
  },
  Prep: {
    headerName: 'Prep',
    dataField: 'prep',
    component: PFDR
  },
  Fulfill: {
    headerName: 'Fulfill',
    dataField: 'fulfill',
    component: PFDR
  },
  Delivery: {
    headerName: 'Delivery',
    dataField: 'delivery',
    component: PFDR
  },
  Receipt: {
    headerName: 'Receipt',
    dataField: 'receipt',
    component: PFDR
  },
  Staging: {
    headerName: 'Staging',
    dataField: 'staging',
    component: PFDR
  },
  PrepStatus: {
    headerName: 'Prep Status',
    dataField: 'prep.status',
    component: PFDR
  },
  PrepOffering: {
    headerName: 'Prep Offering',
    dataField: 'prep.offering',
    component: PFDR
  },
  PrepMetadata: {
    headerName: 'Prep Metadata',
    dataField: 'prep.metadata',
    component: PFDR
  },
  PrepImage: {
    headerName: 'Prep Image',
    dataField: 'prep.image',
    component: PFDR
  },
  PrepVideo: {
    headerName: 'Prep Video',
    dataField: 'prep.video',
    component: PFDR
  },
  PrepAsset: {
    headerName: 'Acquisition Prep',
    dataField: 'acquisition',
    component: PFDR
  },
  FulfillOffering: {
    headerName: 'Fulfill Offering',
    dataField: 'fulfill.offering',
    component: PFDR
  },
  FulfillImage: {
    headerName: 'Fulfill Image',
    dataField: 'fulfill.image',
    component: PFDR
  },
  FulfillMetadata: {
    headerName: 'Fulfill Metadata',
    dataField: 'fulfill.metadata',
    component: PFDR
  },
  FulfillVideo: {
    headerName: 'Fulfill Video',
    dataField: 'fulfill.video',
    component: PFDR
  },
  FulfillStatus: {
    headerName: 'Fulfill Status',
    dataField: 'fulfill.status',
    component: PFDR
  },
  DeliveryStatus: {
    headerName: 'Delivery Status',
    dataField: 'delivery.status',
    component: PFDR
  },
  DeliveryOffering: {
    headerName: 'Delivery Offering',
    dataField: 'delivery.offering',
    component: PFDR
  },
  DeliveryMetadata: {
    headerName: 'Delivery Metadata',
    dataField: 'delivery.metadata',
    component: PFDR
  },
  DeliveryImage: {
    headerName: 'Delivery Image',
    dataField: 'delivery.image',
    component: PFDR
  },
  DeliveryVideo: {
    headerName: 'Delivery Video',
    dataField: 'delivery.video',
    component: PFDR
  },
  ReceiptOffering: {
    headerName: 'Receipt Offering',
    dataField: 'receipt.offering',
    component: PFDR
  },
  ReceiptMetadata: {
    headerName: 'Receipt Metadata',
    dataField: 'receipt.metadata',
    component: PFDR
  },
  ReceiptImage: {
    headerName: 'Receipt Image',
    dataField: 'receipt.image',
    component: PFDR
  },
  ReceiptVideo: {
    headerName: 'Receipt Video',
    dataField: 'receipt.video',
    component: PFDR
  },
  ReceiptStatus: {
    headerName: 'Receipt Status',
    dataField: 'receipt.status',
    component: PFDR
  },
  StagingOffering: {
    headerName: 'Staging Offering',
    dataField: 'staging.offering',
    component: PFDR
  },
  StagingMetadata: {
    headerName: 'Staging Metadata',
    dataField: 'staging.metadata',
    component: PFDR
  },
  StagingImage: {
    headerName: 'Staging Image',
    dataField: 'staging.image',
    component: PFDR
  },
  StagingVideo: {
    headerName: 'Staging Video',
    dataField: 'staging.video',
    component: PFDR
  },
  StagingStatus: {
    headerName: 'Staging Status',
    dataField: 'staging.status',
    component: PFDR
  },
  TransformAsset: {
    headerName: 'Transform',
    dataField: 'transform',
    component: PFDR
  },
  ManifestationAsset: {
    headerName: 'Manifestation',
    dataField: 'manifestation',
    component: PFDR
  },
  // This is where the new status object items come in
  Metadata: {
    headerName: 'Metadata',
    dataField: 'metadata',
    component: PFDR
  },
  Image: {
    headerName: 'Image',
    dataField: 'image',
    component: PFDR
  },
  Video: {
    headerName: 'Video',
    dataField: 'video',
    component: PFDR
  },
  MetadataPrep: {
    headerName: 'Metadata Prep',
    dataField: 'metadata.prep',
    component: PFDR
  },
  MetadataFulfill: {
    headerName: 'Metadata Fulfill',
    dataField: 'metadata.fulfill',
    component: PFDR
  },
  MetadataDelivery: {
    headerName: 'Metadata Delivery',
    dataField: 'metadata.delivery',
    component: PFDR
  },
  MetadataReceipt: {
    headerName: 'Metadata Receipt',
    dataField: 'metadata.receipt',
    component: PFDR
  },
  MetadataStaging: {
    headerName: 'Metadata Staging',
    dataField: 'metadata.staging',
    component: PFDR
  },
  ImagePrep: {
    headerName: 'Image Prep',
    dataField: 'image.prep',
    component: PFDR
  },
  ImageFulfill: {
    headerName: 'Image Fulfill',
    dataField: 'image.fulfill',
    component: PFDR
  },
  ImageDelivery: {
    headerName: 'Image Delivery',
    dataField: 'image.delivery',
    component: PFDR
  },
  ImageReceipt: {
    headerName: 'Image Receipt',
    dataField: 'image.receipt',
    component: PFDR
  },
  ImageStaging: {
    headerName: 'Image Staging',
    dataField: 'image.staging',
    component: PFDR
  },
  VideoPrep: {
    headerName: 'Video Prep',
    dataField: 'video.prep',
    component: PFDR
  },
  VideoFulfill: {
    headerName: 'Video Fulfill',
    dataField: 'video.fulfill',
    component: PFDR
  },
  VideoDelivery: {
    headerName: 'Video Delivery',
    dataField: 'video.delivery',
    component: PFDR
  },
  VideoReceipt: {
    headerName: 'Video Receipt',
    dataField: 'video.receipt',
    component: PFDR
  },
  VideoStaging: {
    headerName: 'Video Staging',
    dataField: 'video.staging',
    component: PFDR
  },
};

export const detailModalHeaders = {
  Prep: {
    headerName: 'Prep',
    dataField: 'prep',
    component: ProcessTaskGrids
  },
  Fulfill: {
    headerName: 'Fulfill',
    dataField: 'fulfill',
    component: ProcessTaskGrids
  },
  Delivery: {
    headerName: 'Delivery',
    dataField: 'delivery',
    component: ProcessTaskGrids
  },
  Receipt: {
    headerName: 'Receipt',
    dataField: 'receipt',
    component: ProcessTaskGrids
  },
  Staging: {
    headerName: 'Staging',
    dataField: 'staging',
    component: ProcessTaskGrids
  },
  AcquisitionPrep: {
    headerName: 'Prep',
    dataField: 'acquisition',
    component: ProcessTaskGrids
  },
  Transform: {
    headerName: 'Transform',
    dataField: 'transform',
    component: ProcessTaskGrids
  },
  Manifestation: {
    headerName: 'Manifestation',
    dataField: 'manifestation',
    component: ProcessTaskGrids
  },
  Metadata: {
    headerName: 'Metadata',
    dataField: 'metadata',
    component: ProcessTaskGrids
  },
  Image: {
    headerName: 'Image',
    dataField: 'image',
    component: ProcessTaskGrids
  },
  Video: {
    headerName: 'Video',
    dataField: 'video',
    component: ProcessTaskGrids
  },
  Timeline: {
    headerName: 'Timeline',
    dataField: 'timeline',
    component: Timeline
  }
};

//Legacy DiSCO status Mapping
export const STATUS_MAP = new Map([
  [
    'completed',
    [
      'complete',
      'completed',
      'backfill',
      'backfilled',
      'skipped',
      'fulfilled',
      'accepted'
    ]
  ],
  ['error', ['error']],
  ['failure', ['failure', 'not_ready', 'warning', 'incomplete']], // v4 originally incomplete
  ['processing', ['in-progress', 'started', 'inprogress', 'processing']], // v4 originally inProgress
  ['queued', ['not-started', 'not started', 'not_started', 'queued']], // v4 originally notStarted
  ['pending', ['hold', 'onhold', 'pending', 'not applicable']], // v4 originally hold
  ['inactive', ['inactive']], // v4 originally notApplicable
  ['na', ['na']],
  ['null', ['null']],
  ['nodata', ['nodata']],
  ['deleted', ['cancelled, deleted']] // v4 originally cancelled
]);

export const adminGroup = 'Dv5Admin';
export const adminViews = ['admin'];

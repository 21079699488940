import {
  Burger,
  Group,
  // Header as MHeader,
  Image,
  Popover,
  Text,
  // Title,
  UnstyledButton,
  useComputedColorScheme,
  useMantineTheme,
  Stack,
} from '@mantine/core';
// import cx from 'clsx';
import classes from './Header.module.css';
import { useState } from 'react';
import {
  ButtonAccount,
  ButtonTheme,
  DrawerSettings
} from '../../components/';
// import useStyles from './HeaderStyles';
import { Versions } from './_versions';
import { useConfigStore } from '../../app/store';
import { useNavBarStore } from '../Navbar/store';

export const Header = () => {
  // const { classes } = useStyles();
  const navBarOpen = useNavBarStore((state)=> state.Open);
  const ToggleNavBarOpen = useNavBarStore((state)=> state.ToggleOpen);
  const colorScheme = useComputedColorScheme('light');
  const [versOpened, setVersOpened] = useState(false);
  const config = useConfigStore((state) => state.config);
  const theme = useMantineTheme();
  const env = config.environment;
  const title = navBarOpen ? 'Close navigation' : 'Open navigation';

  const handleBurgerClick = (event: any) => {
    ToggleNavBarOpen();
  };
  return (
    <Stack 
    className={classes.header} 
    style={{
      borderBottom: `1px solid`,
      borderColor: colorScheme==='dark'? '#373A40': '#cfdae5',
    }}
    >
      <Group gap="xs" className={classes.leftSection}>
        <Group>
          <Burger
            opened={navBarOpen}
            onClick={handleBurgerClick}
            size="sm"
            title={title}
            color={
              env !== 'prod'
                ? colorScheme === 'dark'
                  ? theme.colors.orange[6]
                  : theme.colors.orange[6]
                : colorScheme === 'dark'
                ? '#cdcdcd'
                : '#275593'
            }
          />
          <Image
            src={`${window.location.origin}/foundry-logo-header.svg`}
            fit="contain"
            h={30}
            w={30}
            mt={4}
          />
          <Popover
            opened={versOpened}
            onClose={() => setVersOpened(false)}
            width={260}
            position="bottom"
            // placement="start"
            withArrow
          >
            <Popover.Target>
              <UnstyledButton onClick={() => setVersOpened((o) => !o)}>
                <Image
                  src={`${window.location.origin}/DiSCOv5_logo_dark.svg`}
                  fit="contain"
                  h={25}
                  w={80}
                  style={{ marginTop:5, marginLeft:2 }}
                />
              </UnstyledButton>
            </Popover.Target>
            <Popover.Dropdown>
              <Versions />
            </Popover.Dropdown>
          </Popover>
          <Text c={theme.colors.orange[6]} style={{fontSize: 'initial'}}>{env !== 'prod' ? env : ''}</Text>
        </Group>
      </Group>
      <Group 
      style={{
      position: 'absolute',
      top: '0',
      right: '0',
      bottom: '0',
      left: '16',
      display: 'flex',
      alignItems: 'left',
      justifyContent: 'left'
      }} 
      gap="xs">
        <ButtonTheme testid="Header" />
        <DrawerSettings testid="Header" />
        <ButtonAccount testid="Header" />
      </Group>
    </Stack>
  );
};

import { useCallback, useEffect, useMemo } from 'react';
import { usePageMonitorStore } from './store';

interface iPageMonitor {
  [key: string]: boolean;
}

export function usePageMonitor(callbacks?: {
  onLoad?(key: string): void;
  onUnload?(key: string): void;
  onIsLoaded?(key: string): void;
}) {
  const pagerChannel = useMemo(() => new BroadcastChannel('pageMonitor'), []);
  const pages = usePageMonitorStore((state) => state.Pages);
  const setPages = usePageMonitorStore((state) => state.SetPage);
  const { onLoad, onUnload, onIsLoaded } = callbacks || {};

  let pageState = {} as iPageMonitor;
  pageState = JSON.parse(localStorage.getItem('pageMonitor') || '{}');

  useEffect(() => {
    setPages('pageMonitor', true);
    pagerChannel.addEventListener('message', (event) => {
    });
    window.addEventListener('storage', (event) => {
      pageState = JSON.parse(localStorage.getItem('pageMonitor') || '{}');
      setPages('pageMonitor', true);
    });
  }, []);

  const load = useCallback(
    (key: string) => {
      pageState = JSON.parse(localStorage.getItem('pageMonitor') || '{}');
      const keys = Object.keys(pageState);
      if (!keys.includes(key)) {
        pageState[key] = true;
        localStorage.setItem('pageMonitor', JSON.stringify(pageState));
        setPages(key, true);
      }
    },
    [onLoad]
  );

  const unload = useCallback(
    (key: string) => {
      pageState = JSON.parse(localStorage.getItem('pageMonitor') || '{}');
      const keys = Object.keys(pageState);
      if (keys.includes(key)) {
        delete pageState[key];
        localStorage.setItem('pageMonitor', JSON.stringify(pageState));
        setPages('pageMonitor', false);
      }
    },
    [onUnload]
  );

  const isLoaded = useCallback(
    (key: string) => {
      let pageState = JSON.parse(localStorage.getItem('pageMonitor') || '{}');
      const keys = Object.keys(pageState);
      return keys.includes(key);
    },
    [onIsLoaded]
  );

  return [pages, { load, unload, isLoaded }] as const;
}

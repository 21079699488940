import { ValueGetterParams } from 'ag-grid-community';
import { formattedDate } from '../../../../helpers';
import { useAppConfigManager } from '../../../../components/AppConfigs';
import { Option } from '../../../../components/CascaderNetwork/_networks';
import { useStatusCodeStore } from '../../../../components/StatusCodeDictionary/store';
import { getPseComplianceStatus } from '../../../../utils/utilities';
import { iTasks } from '../../stores/details/models';
import { getRatings } from '../../../../utils/utilities';

export const footprintStatusGetter = (params: ValueGetterParams) => {
  const GetStatusCode = useStatusCodeStore.getState().GetStatusCode;
  let name = 'affiliateOfferings-footprint'
  let status = params.data.status ? params.data.status[name]: null
  if (status !== null && status?.status) status = status.status.charAt(0).toUpperCase() + status.status.slice(1);
  if (status?.codes && status?.codes.length > 0) {
    let sc = GetStatusCode(status.codes[0].code);
    if (sc.status && sc.badgeText !== '') {
      status =
        sc.status.charAt(0).toUpperCase() + sc.status.slice(1) + ' - ' + sc.badgeText;
    }
  }
  return status;
};

export const movieAssetIDGetter = (params: ValueGetterParams) => {
  const task = params?.data?.tasks?.find(
    (task: iTasks) => task.type === 'delivery_complete'
  );
  return task?.details?.movieAssetId?.id;
};

export const milestoneStatusGetter = (params: ValueGetterParams) => {
  const GetStatusCode = useStatusCodeStore.getState().GetStatusCode;
  let name = params.colDef.cellRendererParams.child
    ? params.colDef.cellRendererParams.parent +
      '|' +
      params.colDef.cellRendererParams.child
    : params.colDef.cellRendererParams.parent;
  const milestone = params.data.status ? params.data.status[name]: null
  let status = milestone ? milestone.status : null;
  if (status === 'na') status = 'N/A';
  if (status !== null) status = status.charAt(0).toUpperCase() + status.slice(1);
  if (milestone?.codes && milestone?.codes.length > 0) {
    let sc = GetStatusCode(milestone.codes[0].code);
    if (sc.status && sc.badgeText !== '') {
      status =
        sc.status.charAt(0).toUpperCase() + sc.status.slice(1) + ' - ' + sc.badgeText;
    }
  }
  return status;
};

export const createdDateGetter = (params: ValueGetterParams) => {
  let date = params.data.audit.createdAt;
  return formattedDate(date);
};

export const updatedDateGetter = (params: ValueGetterParams) => {
  let date = params.data.audit.updatedAt;
  return formattedDate(date);
};

export const milestoneDateGetter = (params: ValueGetterParams) => {
  let name = params.colDef.cellRendererParams.child
    ? params.colDef.cellRendererParams.parent +
      '|' +
      params.colDef.cellRendererParams.child
    : params.colDef.cellRendererParams.parent;
  const milestone = params.data.status ? params.data.status[name]: null
  let date = milestone?.audit?.updatedAt ? milestone.audit.updatedAt : null;

  if (!date) {
    return null;
  }
  return formattedDate(date);
};

export const milestoneDetailGetter = (params: ValueGetterParams) => {
  let name = params.colDef.cellRendererParams.child
    ? params.colDef.cellRendererParams.parent +
      '|' +
      params.colDef.cellRendererParams.child
    : params.colDef.cellRendererParams.parent;
  const milestone = params.data.status ? params.data.status[name]: null

  if (milestone && milestone?.codes !== null && milestone.codes.length > 0) {
    const codes = milestone.codes.find((element: any) => 'detail' in element);
    let detail = codes?.detail
    if (!detail) {
      const GetStatusCode = useStatusCodeStore.getState().GetStatusCode;
      let sc = GetStatusCode(codes.code);
      if (sc) {
        detail = sc.details
      }
    }
    if (detail && codes.data) {
      return `${detail} - ${codes.data}`;
    } else if (detail) {
      return detail;
    } else if (codes.data) {
      return codes.data
    }
  }
  return null;
};

export const milestoneCodeGetter = (params: ValueGetterParams) => {
  let name = params.colDef.cellRendererParams.child
    ? params.colDef.cellRendererParams.parent +
      '|' +
      params.colDef.cellRendererParams.child
    : params.colDef.cellRendererParams.parent;
  const milestone = params.data.status ? params.data.status[name]: null

  if (milestone && milestone?.codes !== null && milestone.codes.length > 0) {
    const codes = milestone.codes.find((element: any) => 'code' in element);
    if (codes.code) {
      return codes.code;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

function truncateUUID(uuid: string) {
  return uuid.substring(0, 4) + ' ... ' + uuid.substring(uuid.length - 4, uuid.length);
}

export const startDateFormatter = (props: any) => {
  if (props.data.offering) {
    const date = props.data.offering?.startDate;
    return formattedDate(date);
  }
  return '';
};

export const endDateFormatter = (props: any) => {
  const date = props.data.offering?.endDate;
  return formattedDate(date);
};

export const titleGetter = (params: ValueGetterParams) => {
  let title = '';
  const titles = params.data.offering?.titles;
  if (!titles) return '--No Title--';
  if (titles.length > 0) {
    const distribution = titles.find(
      (title: { type: string }) => title.type === 'distribution-title'
    );
    title = distribution ? distribution.full : null;
    if (!title) {
      const main = titles.find((title: { type: string }) => title.type === 'main-title');
      title = main ? main.full : null;
    }
    if (!title) {
      const cat = titles.find((title: { type: string }) => title.type === 'cat-title');
      title = cat ? cat.full : '';
    }
    if (!title) {
      const fallback = titles.find(
        (title: { type: string }) => title.type === 'internal-wm-name'
      );
      title = fallback ? fallback.full : '--No Title--';
    }
  }
  if (!title) title = '--No Title--';
  return title;
};

export const seriesTitleGetter = (params: ValueGetterParams) => {
  let title = '';
  const titles = params.data.offering?.seriesTitles;
  if (!titles) return title;
  if (titles.length > 0) {
    const distribution = titles.find(
      (title: { type: string }) => title.type === 'distribution-title'
    );
    title = distribution ? distribution.full : null;
    if (!title) {
      const main = titles.find((title: { type: string }) => title.type === 'main-title');
      title = main ? main.full : null;
    }
    if (!title) {
      const cat = titles.find((title: { type: string }) => title.type === 'cat-title');
      title = cat ? cat.full : '';
    }
    if (!title) {
      const fallback = titles.find(
        (title: { type: string }) => title.type === 'internal-wm-name'
      );
      title = fallback ? fallback.full : '';
    }
  }
  return title;
};

export const seasonTitleGetter = (params: ValueGetterParams) => {
  let title = '';
  const titles = params.data.offering?.seasonTitles;
  if (!titles) return title;
  if (titles.length > 0) {
    const distribution = titles.find(
      (title: { type: string }) => title.type === 'distribution-title'
    );
    title = distribution ? distribution.full : null;
    if (!title) {
      const main = titles.find((title: { type: string }) => title.type === 'main-title');
      title = main ? main.full : null;
    }
    if (!title) {
      const cat = titles.find((title: { type: string }) => title.type === 'cat-title');
      title = cat ? cat.full : '';
    }
    if (!title) {
      const fallback = titles.find(
        (title: { type: string }) => title.type === 'internal-wm-name'
      );
      title = fallback ? fallback.full : '';
    }
  }
  return title;
};

export const editIDGetter = (params: ValueGetterParams) => {
  if (
    !params.data.offering?.editId ||
    !params.data.offering?.editId.id ||
    params.data.offering?.editId.id === null
  ) {
    return '';
  }
  return truncateUUID(params.data.offering?.editId.id);
};

export const entityTypeGetter = (params: ValueGetterParams) => {
  let capitalType;
  if (params.data.offering?.entityType) {
    capitalType =
      params?.data?.offering?.entityType?.charAt(0)?.toUpperCase() +
      params?.data?.offering?.entityType?.slice(1);
  } else {
    capitalType = null;
  }
  return capitalType;
};

export const latestCommentGetter = (params: ValueGetterParams) => {
  const comment = params.data.latestComment;
  if (comment) {
    return `${comment.comment} | ${comment.audit?.createdBy} | ${formattedDate(
      comment.audit?.createdAt
    )}`;
  }
  return '';
};

export const destinationNameGetter = (params: ValueGetterParams) => {
  const destination = params.data?.offering?.destinationId?.id;
  const destinations = useAppConfigManager.getState().queryDestinations;

  let destinationName = '';

  if (destination) {
    destinations.forEach((c: Option) => {
      if (c.valueList.toLowerCase() === destination.toLowerCase()) {
        destinationName = c.label;
      }
    });
  }
  if (!destinationName){
    return `Destination ID: ${destination} label not found`
  }
  return destinationName;
};

export const contentClassGetter = (params: ValueGetterParams) => {
  let capitalType;
  if (params.data.offering?.contentClass) {
    let formattedType = params.data.offering.contentClass.split('_').slice(-1);
    capitalType = formattedType[0].charAt(0).toUpperCase() + formattedType[0].slice(1);
  } else {
    capitalType = null;
  }
  return capitalType;
};

const findManifestationTask = (tasks: any[]) => {
  const task = tasks.find((t: { type: string; eventSource: string }) => {
    return t.type === 'mezzcreation' && t.eventSource === 'fulfillment-workflow'
  });
  return task;
};
    
const findPSEComplianceTask = (tasks: any[]) => {
  const task = tasks.find((t: { type: string; eventSource: string }) => {
    return (t.type === 'mezzcreation')
  });
  return task;
};

const dynamicRangeGetter = (task: any, range: string) => {
  let fulfilledVideoComponents;
  if (task?.details?.fulfilledVideoComponents?.length > 0) {
    fulfilledVideoComponents = task.details?.fulfilledVideoComponents.find(
      (i: { dynamicRange: string }) =>
        i.dynamicRange && i.dynamicRange.toUpperCase().includes(range)
    );
    return fulfilledVideoComponents;
  }
};
export const hdrFrameSizeGetter = (params: any) => {
  let fulfilledVideoComponents;
  const tasks = params.data ? params.data.tasks : params.tasks;
  if (tasks?.length > 0) {
    const task = findManifestationTask(tasks);
    fulfilledVideoComponents = dynamicRangeGetter(task, 'HDR');
    if (fulfilledVideoComponents?.frameSize)
      return `${fulfilledVideoComponents?.frameSize?.width} x ${fulfilledVideoComponents?.frameSize?.height}`;
    else return '';
  }
  return '';
};
export const sdrFrameSizeGetter = (params: any) => {
  let fulfilledVideoComponents;
  const tasks = params.data ? params.data.tasks : params.tasks;
  if (tasks?.length > 0) {
    const task = findManifestationTask(tasks);
    fulfilledVideoComponents = dynamicRangeGetter(task, 'SDR');
    if (fulfilledVideoComponents?.frameSize)
      return `${fulfilledVideoComponents?.frameSize?.width} x ${fulfilledVideoComponents?.frameSize?.height}`;
    else return '';
  }
  return '';
};
export const hdrPictureAspectRatioGetter = (params: any) => {
  let fulfilledVideoComponents;
  const tasks = params.data ? params.data.tasks : params.tasks;
  if (tasks?.length > 0) {
    const task = findManifestationTask(tasks);
    fulfilledVideoComponents = dynamicRangeGetter(task, 'HDR');
    if (fulfilledVideoComponents?.pictureAspectRatio)
      return `${fulfilledVideoComponents?.pictureAspectRatio}`;
    else return '';
  }
  return '';
};
export const sdrPictureAspectRatioGetter = (params: any) => {
  let fulfilledVideoComponents;
  const tasks = params.data ? params.data.tasks : params.tasks;
  if (tasks?.length > 0) {
    const task = findManifestationTask(tasks);
    fulfilledVideoComponents = dynamicRangeGetter(task, 'SDR');
    if (fulfilledVideoComponents?.pictureAspectRatio)
      return `${fulfilledVideoComponents?.pictureAspectRatio}`;
    else return '';
  }
  return '';
};
export const hdrScanningFormatGetter = (params: any) => {
  let fulfilledVideoComponents;
  const tasks = params.data ? params.data.tasks : params.tasks;
  if (tasks?.length > 0) {
    const task = findManifestationTask(tasks);
    fulfilledVideoComponents = dynamicRangeGetter(task, 'HDR');
    if (fulfilledVideoComponents?.scanningFormat)
      return `${fulfilledVideoComponents?.scanningFormat
        ?.split(':')
        .slice(-1)[0]
        .toUpperCase()}`;
    else return '';
  }
  return '';
};
export const sdrScanningFormatGetter = (params: any) => {
  let fulfilledVideoComponents;
  const tasks = params.data ? params.data.tasks : params.tasks;
  if (tasks?.length > 0) {
    const task = findManifestationTask(tasks);
    fulfilledVideoComponents = dynamicRangeGetter(task, 'SDR');
    if (fulfilledVideoComponents?.scanningFormat)
      return `${fulfilledVideoComponents?.scanningFormat
        ?.split(':')
        .slice(-1)[0]
        .toUpperCase()}`;
    else return '';
  }
  return '';
};

export const complianceGetter = (params: any) => {
  const tasks = params.data ? params.data.tasks : params.tasks;
  if(!tasks) 
    return 'N/A';
  if (tasks) {
    if (tasks?.length > 0) {
      const task = findPSEComplianceTask(tasks);
      if(task) {  
        return getPseComplianceStatus(task);
      }
    } else {
      return 'N/A';
    }
  }
  return 'N/A';
};

export const ratingsGetter = (params: any) => {
  const offering = params.data ? params.data.offering : params.offering;
  if (offering?.ratings?.length > 0) {
    return getRatings(offering.ratings);
  }
  return null;
}
import { Group, Popover, Text } from "@mantine/core"
import { useState } from "react"
export const ValueTooltip = (props: {value: string}) => {
    const [open, SetOpen] = useState(false)

    function onMouseEnter() {
        SetOpen(true)
    }

    function onMouseLeave() {
        SetOpen(false)
    }

    return (
        <Popover
          opened={open}
          position="bottom"
          offset={-1}
          withArrow
          withinPortal
          classNames={{
            arrow: 'popoverArrow',
            dropdown: 'popoverDropdown'
          }}
        >
          <Popover.Target>
            <Group onMouseOver={onMouseEnter} onMouseOut={onMouseLeave}>
              <Text maw={400} style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                {props.value}
              </Text>
            </Group>
          </Popover.Target>
          <Popover.Dropdown>
            <Text>{props.value}</Text>
          </Popover.Dropdown>
        </Popover>
      )
}
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { HierarchyPathType, iAdminModule, initialAdminModuleValues } from './models';
import { iItemType, iSelectDataSource } from '../../components/ListSortable/models';
//import { v4 as uuidv4 } from 'uuid';

export const useAdminModuleStore = create<iAdminModule>()(
  devtools((set, get) => ({
    ...initialAdminModuleValues,
    SetActiveTab: (tab: string | null) => {
      set({ ActiveTab: tab });
    },
    SetHierarchyPath: (levels: HierarchyPathType[]) => {
      set({ hierarchyPath: levels });
    },
    SetItem: (newItem: iItemType) => {
      const dd = Object.assign({}, get().DropDown);
      dd.Item = newItem;
      set({ DropDown: dd });
    },
    SetDropdownList: (DropdownList: iSelectDataSource[]) => {
      DropdownList = DropdownList?.map((value, i) => ({ ...value, id: i + 'dd' }));
      set({ DropdownList });
    },
    SetNewDropdown: (newDropdown: boolean) => {
      set({ newDropdown });
    }
  }))
);

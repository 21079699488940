import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { initialNotification, Notification, NotificationStore } from './models';
import { randomId } from '@mantine/hooks';
import { ViewsService } from '../../services/views/viewsService';

export const useNotificationStore = create<NotificationStore>()(
  devtools((set, get) => ({
    editing: false,
    currentNotification: { ...initialNotification },
    notifications: [],
    updateValue: (newValue: any) => {
      set({ currentNotification: { ...get().currentNotification, ...newValue } });
    },
    getNotifications: async () => {
      const notifications = await ViewsService.getNotifications();
      set({ notifications });
    },
    resetValues: () => {
      set({ currentNotification: {
        active: true,
        footprint: '',
        timeInterval: '',
        view: '',
        deliveryMethod: 'email',
        destination: '',
        schedule: [
          { label: 'Sun', checked: false, key: randomId(), value: 0 },
          { label: 'Mon', checked: false, key: randomId(), value: 1 },
          { label: 'Tue', checked: false, key: randomId(), value: 2 },
          { label: 'Wed', checked: false, key: randomId(), value: 3 },
          { label: 'Thu', checked: false, key: randomId(), value: 4 },
          { label: 'Fri', checked: false, key: randomId(), value: 5 },
          { label: 'Sat', checked: false, key: randomId(), value: 6 }
        ],
      } });
    },
    setCurrentNotification: (payload: Notification) => {
      set({ currentNotification: { ...payload } });
    },
    setEditing: (payload: boolean) => set({ editing: payload }),
  }))
);

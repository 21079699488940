import {
  Box,
  Center,
  Divider,
  Flex,
  Group,
  Loader,
  // Text,
  Title,
  Tooltip as MTooltip,
  useMantineTheme,
  useComputedColorScheme
} from '@mantine/core';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale
} from 'chart.js';
import 'chart.js/auto';
import { StatusColor } from '../..';
import { iWidget } from '../state/models';
import { Pie } from 'react-chartjs-2';

import { useEffect, useMemo, useRef, useState } from 'react';
import { BoxMultiple, DotsVertical, X } from 'tabler-icons-react';
import {
  useClickOutside,
  useDisclosure,
  useElementSize,
  useHotkeys,
  useMergedRef
} from '@mantine/hooks';
import { InformationBox } from './_informationBox';
import { getStatus } from '../../../utils/getStatus';
import {
  fetchOfferingStatusCount,
  formattedMilestoneNames
} from '../../../api/offering/fetchOfferingStatusCount';
import { useNavigate } from 'react-router';
import Countdown, { zeroPad } from 'react-countdown';
import { usePageMonitor } from '../../PageMonitor/PageMonitor';
import { useHUDStore } from '../state';
// import HudStyles from '../HudStyles';
import classes from '../Hud.module.css';
import { fetchLinearStatusCount } from '../../../api/linear/fetchLinearStatusCount';
import { useMultiWindowCommsStore } from '../../../containers/MultiWindowComms/store';
import { useStatusCountsStore } from '../../../containers/StatusCounts/store';
import { fetchAcquireStatusCount } from '../../../api/acquire/fetchAcquireStatusCount';
import { useAppConfigManager } from '../../AppConfigs';
import { fetchAssetStatusCount } from '../../../api/asset/fetchAssetStatusCount';
import { fetchCrossPlatformStatusCount } from '../../../api/crossplatform/fetchCrossPlatformStatusCount';
import { deriveDateFromRelativeString } from '../../../utils/utilities';

export const StatusChart = ({ widget }: { widget: iWidget }) => {
  const canvasRef = useRef(null);
  const [infoOpen, infoHandlers] = useDisclosure(false);
  const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');
  const onDrag = useHUDStore((state) => state.onDragHUD);
  const SetHUDFilterView = useHUDStore((state) => state.SetHUDFilterView);
  const SetStatusField = useStatusCountsStore((state) => state.SetStatusField);
  const navigate = useNavigate();
  const [totalCount, setTotalCount] = useState(0);
  const [countdownKey, setCountdownKey] = useState(1);
  const defaultTime = widget.refreshDelay ? widget.refreshDelay : '60000';
  const [defaultDelay, setDefaultDelay] = useState(defaultTime);
  const GetDropDown = useAppConfigManager((state) => state.GetDropDown);

  const statusColor = StatusColor();
  const clickRef = useClickOutside(() => infoHandlers.close());
  useHotkeys([['Escape', () => infoHandlers.close()]]);

  const { ref, width } = useElementSize();
  const mergedRef = useMergedRef(clickRef, ref);

  const channel = useMemo(() => new BroadcastChannel('hud-sync'), []);
  const [pages, _] = usePageMonitor();
  const enableMultiWindowComms = useMultiWindowCommsStore((state) => state.Enabled);

  const isViewsLoaded = () => {
    const keys = Object.keys(pages);
    return keys.includes('Views');
  };

  // const { classes } = HudStyles();

  const defaultBGFills = [
    statusColor['error'],
    statusColor['failure'],
    statusColor['processing'],
    statusColor['pending'],
    statusColor['completed']
  ];

  const [statusCounts, setStatusCounts] = useState({
    error: 0,
    failure: 0,
    processing: 0,
    pending: 0,
    completed: 0
  });
  const [statusesLoading, setStatusesLoading] = useState<boolean>(true);

  const setFieldStatusChartData = (data: any[]) => {
    const dataCount = {
      error: 0,
      failure: 0,
      processing: 0,
      pending: 0,
      completed: 0
    };
    let count = 0;
    data.forEach((i: any) => {
      const status = getStatus(i.status);
      if (status === 'queued' || status === 'pending') {
        dataCount.pending += i.count;
        count += i.count;
      } else if (status === 'skipped' || status === 'completed') {
        dataCount.completed += i.count;
        count += i.count;
      } else if (Object.keys(dataCount).includes(status)) {
        dataCount[status as keyof typeof dataCount] = i.count;
        count += i.count;
      }
    });
    setTotalCount(count);
    setStatusCounts(dataCount);
    setStatusesLoading(false);
  };

  async function fetchRowData(searchCriteria: any, milestoneName: string) {
    setStatusesLoading(true);
    let result;
    let start = new Date(searchCriteria?.startDate);
    let end = new Date(searchCriteria?.endDate);
    if (searchCriteria?.timeRelative) {
      start = deriveDateFromRelativeString(
        searchCriteria?.startDate,
        false,
        searchCriteria?.startDateTime
      );
      end = deriveDateFromRelativeString(
        searchCriteria?.endDate,
        true,
        searchCriteria?.endDateTime
      );
    }
    switch (widget.footprint) {
      case 'footprint-offering':
        result = await fetchOfferingStatusCount(
          start,
          end,
          searchCriteria,
          milestoneName
        );
        break;
      case 'footprint-linear':
        result = await fetchLinearStatusCount(
          start,
          end,
          searchCriteria,
          milestoneName
        );
        break;
      case 'footprint-acquire':
        result = await fetchAcquireStatusCount(
          start,
          end,
          searchCriteria,
          milestoneName
        );
        break;
      case 'footprint-asset':
        result = await fetchAssetStatusCount(
          start,
          end,
          searchCriteria,
          milestoneName
        );
        break;
      case 'footprint-crossplatform':
        result = await fetchCrossPlatformStatusCount(
          start,
          end,
          searchCriteria,
          milestoneName
        );
        break;
      default:
        result = await fetchOfferingStatusCount(
          start,
          end,
          searchCriteria,
          milestoneName
        );
        break;
    }
    setFieldStatusChartData(result);
  }

  useEffect(() => {
    // fix all old widgets that say "Overall Offering" instead of "Overall Status"
    // remove later if need be
    if (widget.milestoneName === 'Overall Offering')
      widget.milestoneName = 'Overall Status';
    let copyWidgetSearch = Object.assign({}, widget.search);
    fetchRowData(copyWidgetSearch, widget.milestoneName);
  }, []);

  const defaultInnerBGFills = [statusColor['completed'], statusColor['pending']];

  ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale);

  let arrayData: any[] = [];
  let innerArrayData: any[] = [0, 0, 0];
  for (const status in statusCounts) {
    if (status === 'pending' || status === 'completed') {
      innerArrayData.push(statusCounts[status as keyof typeof statusCounts]);
    } else {
      arrayData.push(statusCounts[status as keyof typeof statusCounts]);
    }
  }

  const data = {
    labels: [`Error`, `Failure`, `Processing`, `Pending`, `Completed`],
    datasets: [
      {
        label: 'Offering Status',
        data: arrayData,
        backgroundColor: defaultBGFills,
        borderColor:
          colorScheme === 'dark' ? theme.colors.gray[8] : theme.colors.gray[3]
      },
      {
        label: 'Offering Status',
        data: innerArrayData,
        backgroundColor: defaultInnerBGFills,
        borderColor:
          colorScheme === 'dark' ? theme.colors.gray[8] : theme.colors.gray[3]
      }
    ]
  };

  const options = {
    animation: false,
    plugins: {
      legend: {
        labels: {
          boxWidth: 40,
          color:
            colorScheme === 'dark' ? theme.colors.gray[5] : theme.colors.dark[9],
          generateLabels(chart: any) {
            const data = chart.data;
            if (data.labels.length && data.datasets.length) {
              const {
                labels: { pointStyle, color }
              } = chart.legend.options;

              return data.labels.map((label: any, i: any) => {
                const meta = chart.getDatasetMeta(0);
                const style = meta.controller.getStyle(i);

                let labelText = `${label}: ${statusCounts[
                  label.toLowerCase() as keyof typeof statusCounts
                ].toLocaleString('en-US')}`;
                return {
                  text: labelText,
                  fillStyle: statusColor[label.toLowerCase()],
                  strokeStyle: style.borderColor,
                  fontColor: color,
                  lineWidth: style.borderWidth,
                  pointStyle: pointStyle,
                  hidden: !chart.getDataVisibility(i),

                  // Extra data used for toggling the correct item
                  index: i
                };
              });
            }
            return [];
          }
        }
      }
    },
    maintainAspectRatio: false,
    onClick: function (evt: any, element: any) {
      if (element.length > 0) {
        let formattedMilestone = widget.milestoneName;
        const ms = GetDropDown('milestones:' + widget.footprint);
        if (widget.milestoneName === 'Overall Status') {
          ms.forEach((m) => {
            if (m.label === 'Overall Status') formattedMilestone = m.value;
          });
        } else {
          formattedMilestoneNames.forEach((n) => {
            if (widget.milestoneName === n.mName) {
              formattedMilestone = n.searchName;
            }
          });
        }
        let copySearch = Object.assign({}, widget.search);
        let clickedStatus = data.labels[element[0].index].toLocaleLowerCase();
        let newStatus = clickedStatus;
        if (clickedStatus === 'pending') {
          newStatus = 'pending","queued';
        } else if (clickedStatus === 'completed') {
          newStatus = 'completed","skipped';
        }
        copySearch.MilestoneStatus = [newStatus];
        copySearch.Milestone = [formattedMilestone];
        let newFilter = {
          field: formattedMilestone,
          search: copySearch,
          footprint: widget.footprint
        };
        SetHUDFilterView(newFilter);
        if (isViewsLoaded() && enableMultiWindowComms) {
          channel.postMessage(newFilter);
          return;
        }
        SetStatusField(formattedMilestone);
        navigate('/views');
      }
    }
  };
  let cursor = onDrag ? 'grabbing' : 'grab';

  const onComplete = () => {
    let copyWidgetSearch = Object.assign({}, widget.search);
    fetchRowData(copyWidgetSearch, widget.milestoneName);
    setCountdownKey(countdownKey + 1);
  };
  const renderer = ({ minutes, seconds }: { minutes: any; seconds: any }) => {
    return (
      <span>
        {zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
    );
  };
  return (
    <Box ref={mergedRef} className={`react-grid-item ${colorScheme === 'dark'? classes.detailDark : classes.detailLight }`}>
      <Flex justify={'space-between'}>
        <Center className="drag-handle" style={{ width: '100%', cursor: cursor }}>
          <Title order={5} ml={6} mb={12} style={{ cursor: cursor }}>
            {widget.customName}
          </Title>
        </Center>
        {isViewsLoaded() && enableMultiWindowComms && (
          <MTooltip label="Multiple open tabs/windows detected.">
            <Box>
              <BoxMultiple
                color={
                  colorScheme == 'dark'
                    ? theme.colors.gray[8]
                    : theme.colors.gray[4]
                }
              />
            </Box>
          </MTooltip>
        )}
        {!infoOpen && (
          <DotsVertical
            style={{ cursor: 'pointer' }}
            size={24}
            color={
              colorScheme === 'dark' ? theme.colors.titan[4] : theme.colors.titan[7]
            }
            strokeWidth={1.5}
            onClick={() => infoHandlers.open()}
          />
        )}
        {infoOpen && (
          <X
            style={{ cursor: 'pointer' }}
            size={24}
            color={
              colorScheme === 'dark' ? theme.colors.titan[4] : theme.colors.titan[7]
            }
            strokeWidth={1.5}
            onClick={() => infoHandlers.close()}
          />
        )}
      </Flex>
      <Divider mt={-8} mb={12} />
      {!infoOpen && (
        <Box style={{ height: '85%' }}>
          {statusesLoading && (
            <Center style={{ height: '100%' }}>
              <Loader />
            </Center>
          )}
          {!statusesLoading && <Pie ref={canvasRef} data={data} options={options} />}
        </Box>
      )}
      {infoOpen && (
        <InformationBox
          widget={widget}
          defaultDelay={defaultDelay}
          setDefaultDelay={setDefaultDelay}
        />
      )}
      {!infoOpen && (
        <Group
          justify="space-between"
          style={{
            // position: 'absolute',
            // bottom: 6,
            width: width,
            fontSize: 10, 
          }}
        >
          <Countdown
            date={Date.now() + Number(defaultDelay)}
            onComplete={onComplete}
            key={countdownKey}
            daysInHours={true}
            renderer={renderer}
          />
          <>Total: {totalCount.toLocaleString('en-US')}</>
        </Group>
      )}
    </Box>
  );
};

export default StatusChart;

export interface iNavBarNested {
  ActiveItem: string;
  ActiveLink: string;
  SetActiveItem: (newItem: string) => void;
  SetActiveLink: (newLink: string) => void;
}

export const initialNavBarNestedValues: iNavBarNested = {
  ActiveItem: '',
  ActiveLink: '',
  SetActiveItem: (newItem: string) => {},
  SetActiveLink: (newLink: string) => {}
};

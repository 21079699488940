import { Popover, ScrollArea, Text, ActionIcon, Anchor } from '@mantine/core';
import { InfoBlock } from './InfoBlock';
import { FileInfo } from 'tabler-icons-react';
import { CodeHighlight } from '@mantine/code-highlight';
import { useEffect, useState } from 'react';
import { fetchEventData } from '../../api/fetchEventData';
import { getStatus } from '../../utils/getStatus';
import { StatusIcon } from '../Status';
import { useStatusCodeStore } from '../StatusCodeDictionary/store';
import { formattedDate } from '../../helpers';


export const TimelineInfoBlock = (props: { item: any, data?: any }) => {
  const item = props.data ? props.data : props.item

  const [rawStatus, setRawStatus] = useState<any[]>([]);
  const [csData, setCsData] = useState<any[]>([]);
  const [manifestationData, setManifestations] = useState<any>([]);

  async function fetchRawData() {
    const result = await fetchEventData(item.eventMetaId);
    if (result && result.length > 0) {
      setRawStatus(result);
    }
    setCsData(calculateCSIDJson(result));
    const manifestations = await fetchEventData(item.eventMetaId, true);

    let manifestationData: any[] = [];
    if (manifestations && manifestations.length > 0) {
      manifestations[0].resolved?.forEach((r: any) => {
        manifestationData.push(r);
      });
    }

    const sortOrder = [
      'sourceManifestation',
      'targetManifestation',
      'outputManifestation',
      'presentationManifestation'
    ];

    const sortByObject = sortOrder.reduce((obj, item, index) => {
      return {
        ...obj,
        [item]: index
      };
    }, {});
    const customSort = manifestationData.sort(
      (a, b) =>
        sortByObject[a.label as keyof typeof sortByObject] -
        sortByObject[b.label as keyof typeof sortByObject]
    );
    const filteredCustomSort = customSort.filter((i) => i.data !== 'undefined');
    setManifestations(filteredCustomSort);
  }

  useEffect(() => {
    if (item) fetchRawData();
  }, []);

  let status = item.status;
  let statusName =
    status?.codes.length > 0
      ? status.codes[0].code
      : status?.type.includes('type_')
      ? status?.type.split('type_')[1]
      : status?.type;
  status?.type ? (statusName = getStatus(statusName)) : (statusName = 'inactive');

  let statusDetail = '';
  if (status.codes && status.codes?.length > 0) {
    const codes = status.codes.find((element: any) => 'detail' in element);
    let detail = codes?.detail;
    if (!detail || detail === codes.data) {
      const GetStatusCode = useStatusCodeStore.getState().GetStatusCode;
      let sc = GetStatusCode(codes.code);
      if (sc) {
        statusDetail = sc.details;
      }
    }
    if (detail && codes.data) {
      statusDetail = `${detail} - ${codes.data}`;
    } else if (detail) {
      statusDetail = detail;
    } else if (codes.data) {
      statusDetail = codes.data;
    }
  }

  const dri = item.details?.dynamicRangeIndicator;
  const fulfillmentType = item.details?.fulfillmentType;
  const fulfillmentId = item.details?.correlationMetadata?.find(
    (j: { idNamespace: string }) => j.idNamespace.includes('fulfillmentid')
  );
  return (
    <div className="timeline-info" >
      {status && (
        <div className="timeline-info-block-outer">
          <div className="timeline-info-block">
            {statusName && (
              <InfoBlock label="Status">
                <StatusIcon status={statusName} />
                &nbsp;
                {statusName.charAt(0).toUpperCase() + statusName.slice(1)}
              </InfoBlock>
            )}
            {status.codes?.length > 0 && status.codes[0].code && (
              <InfoBlock label="Code">{status.codes[0].code}</InfoBlock>
            )}
          </div>
          <Popover
            width={800}
            position="right"
            withArrow
            shadow="md"
            classNames={{
              arrow: 'popoverArrow',
              dropdown: 'popoverDropdown'
            }}
            zIndex={20000}
            withinPortal
          >
            <Popover.Target>
              <ActionIcon
                variant="subtle"
                style={() => ({
                  color: 'gray',
                  marginRight: '10px'
                })}
              >
                <FileInfo />
              </ActionIcon>
            </Popover.Target>
            <Popover.Dropdown key={item.eventMetaId + 'Popover-Dropdown'}>
              <ScrollArea h={425}>
                <Text fw={700}>Raw Status JSON</Text>
                <CodeHighlight code={JSON.stringify(rawStatus, null, 2)} language="tsx" />
              </ScrollArea>
            </Popover.Dropdown>
          </Popover>
        </div>
      )}
      {statusDetail && (
        <div className="timeline-info-block">
          <InfoBlock label="Full Detail">{statusDetail}</InfoBlock>
        </div>
      )}
      {item.audit && (
        <div className="timeline-info-block">
          {item.audit.createdAt && (
            <InfoBlock label="Created At">
              {formattedDate(item.audit.createdAt)}
            </InfoBlock>
          )}
          {item.audit.updatedAt && (
            <InfoBlock label="Updated At">
              {formattedDate(item.audit.updatedAt)}
            </InfoBlock>
          )}
        </div>
      )}
      {(dri || fulfillmentType || fulfillmentId) && (
        <div className="timeline-info-block">
          {dri && <InfoBlock label="DRI">{dri}</InfoBlock>}
          {fulfillmentType && (
            <InfoBlock label="Fulfillment Type">{fulfillmentType}</InfoBlock>
          )}
          {fulfillmentId && (
            <InfoBlock label="Fulfillment ID">{fulfillmentId.id}</InfoBlock>
          )}
        </div>
      )}
      {(csData.length > 0 || manifestationData.length > 0) && (
        <div className="timeline-info-block">
          {csData.length > 0 && (
            <InfoBlock label="CSID">
              {
                <Popover
                  key={csData[0].requestId + 'CSIDPopover'}
                  position="right"
                  withArrow
                  shadow="md"
                  classNames={{
                    arrow: 'popoverArrow',
                    dropdown: 'popoverDropdown'
                  }}
                  zIndex={9999}
                >
                  <Popover.Target>
                    <Anchor type="button">{csData[0].requestId}</Anchor>
                  </Popover.Target>
                  <Popover.Dropdown>
                    <ScrollArea h={425}>
                      <Text fw={700}>Content Subscription JSON</Text>
                      <CodeHighlight
                        code={JSON.stringify(csData, null, 2)}
                        language="tsx"
                      />
                    </ScrollArea>
                  </Popover.Dropdown>
                </Popover>
              }
            </InfoBlock>
          )}
          {manifestationData.length > 0 &&
            manifestationData.map((i: any) => {
              const label = i.label.split('Manifestation');
              const capitalLabel = label[0].charAt(0).toUpperCase() + label[0].slice(1);
              const manifestationJSON = i.data
                ? JSON.stringify(JSON.parse(i.data), null, 2)
                : null;
              if (!manifestationJSON) {
                return;
              }
              return (
                <InfoBlock label={capitalLabel}>
                  {
                    <Popover
                      key={capitalLabel + 'manifestationPopover'}
                      position="right"
                      withArrow
                      shadow="md"
                      classNames={{
                        arrow: 'popoverArrow',
                        dropdown: 'popoverDropdown'
                      }}
                      zIndex={9999}
                    >
                      <Popover.Target>
                        <Anchor type="button">Manifestation</Anchor>
                      </Popover.Target>
                      <Popover.Dropdown>
                        <ScrollArea h={425}>
                          <Text fw={700}>{capitalLabel} Manifestation JSON</Text>
                          <CodeHighlight
                            code={manifestationJSON}
                            maw={600}
                            language="tsx"
                          />
                        </ScrollArea>
                      </Popover.Dropdown>
                    </Popover>
                  }
                </InfoBlock>
              );
            })}
        </div>
      )}
    </div>
  );
};

const calculateCSIDJson = (csData: any[]) => {
  if (csData && csData.length > 0) {
    if (csData[0].data?.payload?.contentRequests) {
      return csData[0].data?.payload?.contentRequests;
    }
  }
  return [];
};

import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { persist } from 'zustand/middleware';
import { iPageMonitor, initialPageMonitorValues } from './models';

export const usePageMonitorStore = create<iPageMonitor>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialPageMonitorValues,
        SetPage: (key: string, value: boolean) => {
          set({ Pages: Object.assign({}, get().Pages, { key: value }) });
        }
      }),
      {
        name: 'page-monitor-settings'
      }
    )
  )
);

import { Accordion, AccordionProps } from '@mantine/core';
import { useInformationAccordionStore } from './store';

interface iInformationAccordionProps extends AccordionProps<true> {
  defaultValue: string[];
}

export const InformationAccordion = (props: iInformationAccordionProps) => {
  const items = useInformationAccordionStore((state) => state.Items)
  const setItems = useInformationAccordionStore((state) => state.SetItems)

  const onChange = (evt: any) => {
    setItems(evt);
  };


  return (
    <>
      <Accordion multiple defaultValue={items} onChange={onChange} chevronPosition="left"
        classNames={{
          label: 'accordionLabel',
        }}
      >
        {props.children}
      </Accordion>
    </>
  );
};

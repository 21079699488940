import { useEffect } from 'react';
import { AppShell, Center, Group, Loader, useComputedColorScheme, useMantineColorScheme } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { AsideSettings, Footer } from '../components/';
import { useNavBarStore } from '../components/Navbar/store';
import { Header, Navbar, AsideAccount } from '../components';
import { useConfigStore, useUserPrefsStore } from './store';
import Routes from './routing/Routes';
import { useAsideSettingsStore } from '../components/Aside/store';
// import { globalColors as colors } from '../theme/globalColors';

export const App = () => {
  const ConfigStore = useConfigStore();
  const config = useConfigStore((state) => state.config);
  const isConfigLoading = useConfigStore((state) => state.isConfigLoading);
  const isFetching = useConfigStore((state) => state.fetching);
  const userData = useConfigStore((state) => state.userData);
  const UserPreferencesStore = useUserPrefsStore();
  const { setColorScheme } = useMantineColorScheme();
  const colorScheme = useComputedColorScheme('light');
  useEffect(() => {
    if (isConfigLoading && !isFetching) {
      ConfigStore.Load();
    }
  }, [isConfigLoading, isFetching]);

  useEffect(() => {
    if(UserPreferencesStore.selectedTheme){
      setColorScheme(UserPreferencesStore.selectedTheme === "light" ? 'light' : 'dark');
    }
  }, [UserPreferencesStore.selectedTheme])

  const showAside = useAsideSettingsStore((state)=> state.Show)
  const navbarOpened = useNavBarStore((state) => state.Open)
  const navigate = useNavigate();

  const restoreOriginalUri = (_oktaAuth: any, originalUri: any) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  if (isConfigLoading) {
    return (
      <Center>
      <Group>
        <Loader size="sm" variant="dots" />
        loading Configs…
      </Group>
      </Center>
    );
  } else {
    const oktaAuth = new OktaAuth({
      issuer: config.oidc.issuer,
      clientId: config.oidc.clientId,
      redirectUri: window.location.origin + '/login',
      responseType: 'code'
    });

    document.title =
      config.environment === 'prod'
        ? 'DiSCO v5'
        : 'DiSCO v5 ' + config.environment.toLowerCase() + '!';

    const displayAsides = (authClient: any) => {
      switch (showAside) {
        case 'AsideSettings':
          return <AsideSettings testid="AppRouter" />;
        case 'AsideAccount':
          return (
            <AsideAccount oktaAuth={authClient} testid="AppRouter" userData={userData} />
          );
        default:
          return <></>;
      }
    };

    return (
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <AppShell
            className={'body'}
            style = {{
                backgroundColor:
                 colorScheme === 'dark' ? '#030315' : '#E6EFFF',
                overflowY: 'auto'              
            }}
            padding={10}
            footer={{ height: 60 }}
            aside={{ width: { sm: 200, md: 300 }, breakpoint: 'md', collapsed: { desktop: !showAside, mobile: !showAside } }}
            header={{height: 50 }}
            navbar={{
              width: 300,
              breakpoint: 'sm',
              collapsed: { desktop: !navbarOpened },
            }}
          >
          { showAside && <AppShell.Aside>{displayAsides(oktaAuth)}</AppShell.Aside>}

          <AppShell.Header>
            <Header />
          </AppShell.Header>
          {navbarOpened && <AppShell.Navbar p="md">
            <AppShell.Section grow>
              {navbarOpened ? <Navbar /> : <></>}
            </AppShell.Section>            
          </AppShell.Navbar> }
          <AppShell.Main style={{height: '100vh', overflowY: 'auto', marginTop:-4}}>
            <Routes />
          </AppShell.Main>
          <AppShell.Footer>
            <Footer testid="AppRouterFooterBar" />
          </AppShell.Footer>
          </AppShell>
      </Security>
    );
  }
};

export default App;

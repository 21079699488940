import { Cascader as AntDCascader, ConfigProvider, theme as andtdTheme } from 'antd';
import {
  Group,
  useMantineTheme,
  Text,
  Stack,
  useComputedColorScheme,
  lighten,
  darken
} from '@mantine/core';
import { DefaultOptionType } from 'antd/es/cascader';
import { globalColors as colors } from '../../../theme';
import { useTimelineStore } from '../store/store';

export interface iCascaderProps {
  id: string;
  data: string[][];
  field: string;
  name: string;
  noOpen: boolean;
  options: string[][];
  onChange?: {};
}

export const CascaderTimeline = (props: any) => {
  const { id, data, name, options, disabled } = props;
  const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');
  const { SetTaskFilters, SetIsStatusCode } = useTimelineStore.getState();

  function onChange(props: any) {
    SetIsStatusCode(props.length > 0);
    SetTaskFilters(props);
  }

  const getTextColor = () => {
    return colorScheme === 'dark' ? theme.colors.gray[1] : theme.colors.gray[7];
  };

  const getCrumbColor = () => {
    return colorScheme === 'dark' ? darken(colors.wbdblue[9], 0.4) : colors.wbdblue[0];
  };

  const getBorderColor = () => {
    return colorScheme === 'dark' ? theme.colors.gray[4] : theme.colors.gray[4];
  };

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(
      (option) =>
        (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );
    // light-dark(var(--mantine-color-wbdblue-0), var(--mantine-color-dark-6)) !important;
    // colorScheme === 'dark' ? 'ag-theme-alpine-dark' : 'ag-theme-alpine';
  return (
    <Group style={{ gap: 3 }}>
      <ConfigProvider
        theme={{
          algorithm:
            colorScheme === 'dark'
              ? andtdTheme.darkAlgorithm
              : andtdTheme.defaultAlgorithm,
          token: {
            borderRadiusSM: 12,
            colorBgContainer:
              colorScheme === 'dark' ? 'ag-theme-alpine-dark' : 'ag-theme-alpine',
            colorBorder: getBorderColor(),
            colorPrimaryHover:
              colorScheme === 'dark' ? lighten(colors.wbdblue[9], 0.15) : '#B8D2FD',
            colorText: getTextColor(),
            colorFillSecondary: getCrumbColor(),
            fontSize: 13
          }
        }}
      >
        <Stack>
          <Text
            size={'xs'}
            fw={500}
            // c={colorScheme === 'dark' ? theme.colors.gray[1] : theme.colors.gray[7]}
            mb={-14}
          >
            {name}
          </Text>
          <AntDCascader
            disabled={disabled}
            id={id}
            autoFocus={false}
            multiple
            value={data}
            onChange={onChange}
            options={options}
            placeholder={`Select ${name}`}
            dropdownStyle={{ zIndex: 10000 }}
            showSearch={{ filter }}
          />
        </Stack>
      </ConfigProvider>
    </Group>
  );
};

import { Box, Button, Group, Popover, Tooltip, Text, ScrollArea, Table, Anchor, CopyButton, Divider, Flex } from '@mantine/core';
import { Button as CustomButton} from '../../../components/Button';
import { fetchEventData } from '../../../api/fetchEventData';
import { useEffect, useMemo, useState } from 'react';
import { Clipboard, FileInfo, MessageOff } from 'tabler-icons-react';
import { CodeHighlight } from '@mantine/code-highlight';
import { useConfigStore } from '../../../app/store';
import { useMessagePopOverStore } from '../../../containers/MessagePopovers/store';
import { hasOKTAGroup } from '../../../utils/oktaGroups';
import useAxios from '../../../services/axios/axios';
import { useFootprintStore } from '../../../containers/Footprints/store';

export const CustomInfoPanel = (props: any) => {
  const { get } = useAxios();

  const openedOuter = useMessagePopOverStore((state) => state.IsOuterOpen)
  const openedInner = useMessagePopOverStore((state) => state.IsInnerOpen)
  const inactiveOpened = useMessagePopOverStore((state) => state.IsInactiveOpen)
  // const setOpened = useMessagePopOverStore((state) => state.SetIsOpen)
  const toggleOpenOuter = useMessagePopOverStore((state) => state.ToggleIsOuterOpen)
  const toggleOpenInner = useMessagePopOverStore((state) => state.ToggleIsInnerOpen)
  const toggleInactiveOpen = useMessagePopOverStore((state) => state.ToggleIsInactiveOpen)
  const openedIDOuter = useMessagePopOverStore((state) => state.PopoverIDOuter)
  const openedIDInner = useMessagePopOverStore((state) => state.PopoverIDInner)
  const setOpenedIDOuter = useMessagePopOverStore((state) => state.SetIDOuter)
  const setOpenedIDInner = useMessagePopOverStore((state) => state.SetIDInner)
  const activeFootprint = useFootprintStore.getState().active.id;
  
  const [csData, setCsData] = useState<any[]>([]);
  // const [durationDetail, setDurationDetail] = useState<string>('');
  const config = useConfigStore((state) => state.config);
  const isAdmin = hasOKTAGroup(config.environment, config.oktaAdminGroup);
  async function fetchCSData() {
    const result = await fetchEventData(props.data.eventMetaId);
    if (result && result.length > 0) {
      setCsData(result);
      // if (result[0]?.data?.payload?.details?.contents) {
      //   const videoIndex = result[0]?.data?.payload?.details?.contents.findIndex(
      //     (contentValue: any) => contentValue.contentType === 'video'
      //   );
      //   if (videoIndex !== -1) {
      //     const videoContents = result[0]?.data?.payload?.details?.contents[videoIndex];
      //     setDurationDetail(videoContents.duration);
      //   }
      // }
    }
  }

  useEffect(() => {
    if (props.data) fetchCSData();
  }, []);

  const showInfo = (d: any, newTable: boolean): any => {
    // if (!d[1]) return
    let content = d[1];
    if (typeof d[1] !== 'string' && d[1]) {
      if (Array.isArray(d[1])) {
        content = d[1].map((i) => {
          if (d[0] === 'trackingIds') {
            const formattedIdNameArr = i.idNamespace?.split(':');
            const formattedIdName = formattedIdNameArr[formattedIdNameArr.length - 1];
            let newId: any = {};
            newId[formattedIdName as keyof typeof formattedIdName] = i.id;
            return Object.entries(newId).map((i) => showInfo(i, true));
          }
          return Object.entries(i).map((i) => showInfo(i, true));
        });
      } else {
        content = Object.entries(d[1]).map((i) => showInfo(i, true));
      }
    }
    switch (d[0]) {
      case 'jobId':
        content = (
          <Anchor
            target="_blank"
            href={`https://backoffice.${config.couiEnvironment}.api.discomax.com/prismatic-microapps/co-ui/jobs/${d[1]}`}
          >
            {d[1]}
          </Anchor>
        );
        break;
      case 'corelationId':
        content = (
          <Anchor
            target="_blank"
            href={`https://backoffice.${config.couiEnvironment}.api.discomax.com/prismatic-microapps/co-ui/jobs?correlationId=${d[1]}`}
          >
            {d[1]}
          </Anchor>
        );
        break;
      case 'detail':
        content = (
          <div
            style={{
              whiteSpace: 'normal'
            }}
          >
            {`${d[1]} ${
              csData[0]?.data?.header?.status?.codes[0]?.data
                ? ' - ' + csData[0]?.data?.header?.status?.codes[0]?.data
                : ''
            } `}
          </div>
        );
        break;
    }
    if (newTable) {
      return (
        <Table
          style={{ marginLeft: -14, marginTop: -6, marginBottom: -6 }}
          key={JSON.stringify(d) + 'table'}
        >
          <Table.Tbody key={JSON.stringify(d) + 'body'}>
            <Table.Tr key={JSON.stringify(d)}>
              <Table.Td style={{ textAlign: 'start' }}>
                {d[0].charAt(0).toUpperCase() + d[0].slice(1)}
              </Table.Td>
              <Table.Td>{content}</Table.Td>
            </Table.Tr>
          </Table.Tbody>
        </Table>
      );
    }
    return (
      <Table.Tr key={JSON.stringify(d)}>
        <Table.Td style={{ textAlign: 'start' }}>
          {d[0].charAt(0).toUpperCase() + d[0].slice(1)}
        </Table.Td>
        <Table.Td>{content}</Table.Td>
      </Table.Tr>
    );
  };


  const formattedData  = props.deliverable ? formatData(props.data) : props.data
  const statusInfo = useMemo(
    () => Object.entries(formattedData).map((i) => showInfo(i, false)),
    [csData]
  );

  const makeInactive = async () => {
    const deactivateUrl = `${config.baseApiGatewayUrl}/rest/refresh/v1/ignore-task/${props.data?.id}?type=${props.data?.type}`
    await get({ url: deactivateUrl });
  };

  const canInactivate = () => {
    let canInactivate = true
    if (!props.deliverable) {
      return false
    }
    switch (activeFootprint) {
      case 'footprint-acquire':
        canInactivate = false
        break;
      default:
        canInactivate = true
        break;
    }
    return canInactivate
  }

  const calculateOpened = () =>{
    if (props.deliverable) {
      return (openedOuter && props.data.eventMetaId === openedIDOuter)
    } else {
      return (openedInner && props.data.eventMetaId === openedIDInner)
    }
  }

  return (
    <Box p={24} style={{ height: 250, overflow: 'auto' }}>
      <Group grow justify="space-between">
        <Text fw={700}>Status Info</Text>
        <Group justify="flex-end">
          {/* {durationDetail !== '' ? (
            <Text fw={700}>Duration: {durationDetail}</Text>
          ) : (
            false
          )} */}
          {(isAdmin && canInactivate()) && (
            <Popover
              width={420}
              position="left"
              withArrow
              shadow="md"
              classNames={{
                arrow: 'popoverArrow',
                dropdown: 'popoverDropdown'
              }}
              zIndex={20000}
              // withinPortal
              //   closeOnClickOutside={false}
              opened={inactiveOpened}
              onChange={toggleInactiveOpen}
            >
              <Popover.Target>
                <Tooltip label={'Deactivate Status'} position="left">
                  <Button
                    variant="subtle"
                    leftSection={<MessageOff size={24} />}
                    style={() => ({
                      color: 'gray'
                    })}
                    // styles={{ leftIcon: { marginRight: -2 } }}
                    onClick={() => {
                      toggleInactiveOpen();
                    }}
                  />
                </Tooltip>
              </Popover.Target>
              <Popover.Dropdown>
                <Flex justify={'center'} wrap={'wrap'}>
                  <Text>Are you sure you would like to deactivate the latest status for:</Text>
                  <Text fw={'bolder'}>{props.data?.type}?</Text>
                </Flex>
                <Divider mt={5} mb={10}/>
                <Group align="end" justify="center">
                  <CustomButton base='remove' noIcon testid='inactivate-item' onClick={makeInactive}>Deactivate</CustomButton>
                  <CustomButton base= 'cancel' noIcon testid='cancel-inactivate-item' onClick={(e) => {
                    e.stopPropagation()
                    toggleInactiveOpen()
                  }}>Cancel</CustomButton>
                </Group>
              </Popover.Dropdown>
            </Popover>
          )}
          <CopyButton value={JSON.stringify(props.data, null, '\t')}>
            {({ copied, copy }) => (
              <Tooltip label={'Copy Status Info'} position="left">
                <Button
                  variant="subtle"
                  leftSection={<Clipboard size={24} />}
                  // styles={{ leftIcon: { marginRight: -2 } }}
                  color={copied ? 'teal' : 'blue'}
                  onClick={copy}
                ></Button>
              </Tooltip>
            )}
          </CopyButton>
          <Popover
            width={800}
            position="left"
            withArrow
            shadow="md"
            classNames={{
              arrow: 'popoverArrow',
              dropdown: 'popoverDropdown'
            }}
            onChange={(opened) => {
              if (props.deliverable) {
                toggleOpenOuter()
                if (!opened) {
                  setOpenedIDOuter('');
                } else {
                  setOpenedIDOuter(props.data.eventMetaId);
                }
              } else {
                toggleOpenInner()
                if (!opened) {
                  setOpenedIDInner('');
                } else {
                  setOpenedIDInner(props.data.eventMetaId);
                }
              }
            }}
            zIndex={20000}
            withinPortal
            opened={calculateOpened()}
            //   closeOnClickOutside={false}
          >
            <Popover.Target>
              <Tooltip label={'View Raw Status'} position="left">
                <Button
                  variant="subtle"
                  leftSection={<FileInfo size={24} />}
                  style={() => ({
                    color: 'gray'
                  })}
                  // styles={{ leftIcon: { marginRight: -2 } }}
                  onClick={(opened) => {
                    if (props.deliverable) {
                      toggleOpenOuter()
                      if (!opened) {
                        setOpenedIDOuter('');
                      } else {
                        setOpenedIDOuter(props.data.eventMetaId);
                      }
                    } else {
                      toggleOpenInner()
                      if (!opened) {
                        setOpenedIDInner('');
                      } else {
                        setOpenedIDInner(props.data.eventMetaId);
                      }
                    }
                  }}
                />
              </Tooltip>
            </Popover.Target>
            <Popover.Dropdown key={props.data.eventMetaId + 'Popover-Dropdown'}>
              <ScrollArea h={425}>
                <Text fw={700}>Raw Status JSON</Text>
                <CodeHighlight code={JSON.stringify(csData, null, 2)} language="tsx" />
              </ScrollArea>
            </Popover.Dropdown>
          </Popover>
        </Group>
      </Group>
      <Group justify="space-between" align="top" wrap="nowrap" gap="lg">
        <Table
          striped={false}
          // fontSize="xs"
          highlightOnHover={true}
          // style={{ width: '110%', margin: -16, marginTop: -32 }}
          style={{fontSize:"xs", marginBottom: 30 }}
        >
          <Table.Tbody>{statusInfo}</Table.Tbody>
        </Table>
      </Group>
    </Box>
  );
};

const formatData = (data:any) => {
  const formattedData = {
    "type": data.type,
    "status":data.status,
    "eventSource":data.eventSource,
    "audit": data.audit,
    "payloadId": data.payloadId,
    "eventMetaId": data.eventMetaId,
    "details": {
        "trackingIds": data.details?.trackingIds
    }
  }
  return formattedData
}
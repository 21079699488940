import { Box, useComputedColorScheme, useMantineTheme } from '@mantine/core';
import { ChevronDown, ChevronRight } from 'tabler-icons-react';
import { useSearchBarStore } from './store';
import { useTID } from '../../helpers/useTestId';
export const Chevron = () => {
  const searchBarOpen = useSearchBarStore((state) => state.searchBarOpen);
  const ToggleSearchBarOpen = useSearchBarStore((state) => state.ToggleSearchBarOpen);  
  const colorScheme= useComputedColorScheme('light');
  const theme = useMantineTheme();
  const TID = useTID('Icon', 'toggle-search-bar');
  return (
    <Box
     {...TID}
      mt={6}
      style={{
        color:
          colorScheme === 'dark'
            ? searchBarOpen
              ? theme.colors.yellow[8]
              : theme.colors.gray[6]
            : searchBarOpen
            ? theme.colors.blue[5]
            : theme.colors.gray[5]
      }}
      onClick={() => ToggleSearchBarOpen()}
    >
      {searchBarOpen ? <ChevronDown /> : <ChevronRight />}
    </Box>
  );
};

export default Chevron;

import { useConfigStore } from '../../app/store';
import { iItemType } from '../../components/ListSortable/models';
import { getAxiosConfig } from '../axios/config';
import axios from 'axios';

export const DropdownService = {
  upsertDropdown: async (
    id: string,
    dropdownList: { id: string; type: string; data: iItemType[] }
  ) => {
    const { headers } = getAxiosConfig();
    const baseURL = useConfigStore.getState().config.baseApiGatewayUrl;
    const res = await axios.put(baseURL + `/rest/appconfigs/v1/${id}`, dropdownList, {
      headers
    });
    return res.data;
  }
};

import { Group, Popover, Text } from "@mantine/core"
import { useState } from "react"

export const NullTitleTooltip = () => {
    const [open, SetOpen] = useState(false)

    function onMouseEnter() {
        SetOpen(true)
    }

    function onMouseLeave() {
        SetOpen(false)
    }

    return (
        <Popover
          opened={open}
          position="top"
          offset={-1}
          withArrow
          withinPortal
          classNames={{
            arrow: 'popoverArrow',
            dropdown: 'popoverDropdown'
          }}
        >
          <Popover.Target>
            <Group onMouseOver={onMouseEnter} onMouseOut={onMouseLeave}>
              <Text>--No Title--</Text>
            </Group>
          </Popover.Target>
          <Popover.Dropdown>
            <Text>An en-US main-title is not available</Text>
          </Popover.Dropdown>
        </Popover>
      )
}
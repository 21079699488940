import { Group, Loader, Stack } from '@mantine/core';
import { SearchBar } from '../SearchBar';
import { useEffect, useMemo } from 'react';
import { StatusFilterBar } from '../StatusFilterBar';
import { usePageMonitor } from '../../components/PageMonitor/PageMonitor';
import { useViewStore } from './store';
import { useSavedViewsStore } from '../SavedViews/store';
import { useNavigate } from 'react-router';
import { useStatusCountsStore } from '../StatusCounts';
import { useFootprintStore } from '../Footprints/store';
import { useSearchStore } from '../Search/store';
import { fetchOfferingRowData } from '../../api/offering/fetchOfferingRowData';
import { fetchLinearRowData } from '../../api/linear/fetchLinearRowData';
import { DetailsModalOffering } from './_details/offering/_detailsModalOffering';
import { DetailsModalLinear } from './_details/linear/_detailsModalLinear';
import { SetOfferingValueGetters } from './_columnDefs/offering/offeringColumnDefs';
import { SetLinearValueGetters } from './_columnDefs/linear/linearColumnDefs';
import { fetchOfferingStatusCount } from '../../api/offering/fetchOfferingStatusCount';
import { fetchLinearStatusCount } from '../../api/linear/fetchLinearStatusCount';
import { useHUDStore } from '../../components/Hud/state';
import { NotFoundCodesModal } from './_notFoundCodesModal';
import { useAppConfigManager } from '../../components/AppConfigs/store';
import Grid from './_grid/_grid';
import { fetchAcquireRowData } from '../../api/acquire/fetchAcquireRowData';
import { fetchAcquireStatusCount } from '../../api/acquire/fetchAcquireStatusCount';
import { SetAcquireValueGetters } from './_columnDefs/acquire/acquireColumnDefs';
import { DetailsModalAcquire } from './_details/acquire/_detailsModalAcquire';
import { useStatusCodeStore } from '../../components/StatusCodeDictionary/store';
import { DetailsModalAsset } from './_details/asset/_detailsModalAsset';
import { fetchAssetRowData } from '../../api/asset/fetchAssetRowData';
import { fetchAssetStatusCount } from '../../api/asset/fetchAssetStatusCount';
import { SetAssetValueGetters } from './_columnDefs/asset/assetColumnDefs';
import { DetailsModalCrossPlatform } from './_details/crossplatform/_detailsModalCrossPlatform';
import { fetchCrossPlatformRowData } from '../../api/crossplatform/fetchCrossPlatformRowData';
import { fetchCrossPlatformStatusCount } from '../../api/crossplatform/fetchCrossPlatformStatusCount';
import { SetCrossPlatformValueGetters } from './_columnDefs/crossplatform/crossPlatformColumnDefs';

/* Views is the main container element for the views page
it contains the views' Grid, the SearchBar, and handles the fetching of initial
View, Grid, and Hud data */

export const Views = () => {
  const views = useViewStore();
  const rowData = useSavedViewsStore((state) => state.rowData);
  const ToggleIsLoading = useSavedViewsStore((state) => state.ToggleIsLoading);
  const ToggleIsLoadingConfig = useAppConfigManager((state) => state.ToggleIsLoading);
  const navigate = useNavigate();
  const nav = useViewStore((state) => state.navigate);
  const SetNavigate = useViewStore((state) => state.SetNavigate);
  const activeID = useSavedViewsStore((state) => state.active?.id);
  const SetSearch = useSearchStore((state) => state.SetSearch);
  const ToggleIsSearching = useSearchStore((state) => state.ToggleIsSearching);
  const SetStatusField = useStatusCountsStore((state) => state.SetStatusField);
  // const isFetching = useSavedViewsStore.getState().isFetching;
  const LoadViews = useSavedViewsStore.getState().LoadViews;
  const LoadFootPrints = useFootprintStore.getState().LoadFootprints;
  const LoadAPI = useSearchStore.getState().LoadAPI;
  // const isLoading = useSavedViewsStore.getState().isLoading;
  const activeFootprint = useFootprintStore.getState().active.id;
  // const SetActiveFootprint = useFootprintStore.getState().SetActiveFootprint;
  const hudFilterView = useHUDStore((state) => state.hudFilterView);
  const GetConfigs = useAppConfigManager((state) => state.GetConfig);
  const GetDropDown = useAppConfigManager((state) => state.GetDropDown);
  const { statusCodes, FetchStatusCodes} = useStatusCodeStore();
  // const dropdowns = useAppConfigManager((state) => state.dropdowns);

  useEffect(() => {
    (async () => {
      try {
        await LoadFootPrints();
      } catch (err) {
        console.error('Error Loading Footprints: ', err);
      }
      try {
        await LoadViews(true);
      } catch (err) {
        console.error('Error Loading Views: ', err);
      }
      try {
        await GetConfigs('taskValueMapper');
      } catch (err) {
        console.error('Error Loading Task Value Mapper: ', err);
      }
      try {
        await GetConfigs('componentValueMapper');
      } catch (err) {
        console.error('Error Loading Component Value Mapper: ', err);
      }
      try {
        await GetConfigs('dropdown');
      } catch (err) {
        console.error('Error Dropdowns: ', err);
      }
      try {
        await GetConfigs('fieldMappings');
      } catch (err) {
        console.error('Error Loading FieldMappings: ', err);
      }
      if(statusCodes.length ===0){
      try {
        await FetchStatusCodes();
      } catch (err) {
        console.error('Error Fetching StatusCodes: ', err);
      }}
      try {
        await LoadAPI();
      } catch (err) {
        console.error('Error Loading API ', err);
      }
      // TODO: This boolean is currently used for the footprint and views
      // TODO: dropdown loading icon - may want to change to isFetching instead?
      // TODO: or unify the values
      ToggleIsLoading();
      // Explicityly toggle the fact that the app config has loaded
      // including dropdowns, fieldMappings, etc.
      ToggleIsLoadingConfig(true);
    })();
  }, []);

  const [_, handlers] = usePageMonitor();
  const channel = useMemo(() => new BroadcastChannel('hud-sync'), []);

  const handleUnload = () => {
    handlers.unload('Views');
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload);
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []);

  useEffect(() => {
    handlers.load('Views');
    // This loads the views page regardless of how you navigate there
    channel.addEventListener('message', (event) => {
      if (event.data.field) {
        SetSearch(event.data.search);
        const statusField =
          event.data.field === 'Overall Offering'
            ? 'offering-footprint'
            : event.data.field;
        SetStatusField(statusField);
        ToggleIsSearching();
      }
    });
    if (activeID) {
      ToggleIsLoading();
    }
  }, []);

  useEffect(() => {
    handlers.load('Views');
  }, []);

  useEffect(() => {
    // Handles navigation to avoid invalid hook calls in store
    if (nav) {
      SetNavigate(false);
      navigate('/views');
    }
  }, [nav]);

  // TODO handle multi window sending hud status message
  //         channel.addEventListener('message', (event) => {
  //           if (event.data.filter.status) {
  //             SetActiveView({
  //               id: defaultView!.id,
  //               label: 'test-hud',
  //               search: event.data.search
  //             });
  //             const statusField =
  //               event.data.field === 'Overall Status'
  //                 ? 'offering-footprint'
  //                 : event.data.field;
  //             SetOfferingStatusField(statusField);
  //             return;

  let gridContent = <></>;

  let footprint = activeFootprint;
  if (hudFilterView.field) {
    footprint = hudFilterView.footprint;
  }
  // if (hudFilterView.field && activeFootprint !== hudFilterView.footprint) {
  //   // footprint = hudFilterView.footprint
  //   SetActiveFootprint(hudFilterView.footprint)
  // }
  const { startDate, endDate } = useSearchStore();
  const ms = GetDropDown('milestones:' + footprint);
  switch (footprint) {
    case 'footprint-offering':
      gridContent = (
        <Grid
          startDate={startDate}
          endDate={endDate}
          rowData={rowData}
          footprint={footprint}
          milestones={ms}
          apiName="offering"
          DetailComponent={<DetailsModalOffering />}
          fetchServerRowData={fetchOfferingRowData}
          setValueGetters={SetOfferingValueGetters}
          fetchStatusCount={fetchOfferingStatusCount}
        />
      );
      break;
    case 'footprint-linear':
      gridContent = (
        <Grid
          startDate={startDate}
          endDate={endDate}
          rowData={rowData}
          footprint={footprint}
          milestones={ms}
          apiName="schedule"
          DetailComponent={<DetailsModalLinear />}
          fetchServerRowData={fetchLinearRowData}
          setValueGetters={SetLinearValueGetters}
          fetchStatusCount={fetchLinearStatusCount}
        />
      );
      break;
    case 'footprint-acquire':
      gridContent = (
        <Grid
          startDate={startDate}
          endDate={endDate}
          rowData={rowData}
          footprint={footprint}
          milestones={ms}
          apiName="acquire"
          DetailComponent={<DetailsModalAcquire />}
          fetchServerRowData={fetchAcquireRowData}
          setValueGetters={SetAcquireValueGetters}
          fetchStatusCount={fetchAcquireStatusCount}
        />
      );
      break;
    case 'footprint-asset':
      gridContent = (
        <Grid
          startDate={startDate}
          endDate={endDate}
          rowData={rowData}
          footprint={footprint}
          milestones={ms}
          apiName="offeringAsset"
          DetailComponent={<DetailsModalAsset />}
          fetchServerRowData={fetchAssetRowData}
          setValueGetters={SetAssetValueGetters}
          fetchStatusCount={fetchAssetStatusCount}
          defaultDetailGridItem="Metadata"
        />
      );
      break;
    case 'footprint-crossplatform':
      gridContent = (
        <Grid
          startDate={startDate}
          endDate={endDate}
          rowData={rowData}
          footprint={footprint}
          milestones={ms}
          apiName="affiliateOfferings"
          DetailComponent={<DetailsModalCrossPlatform />}
          fetchServerRowData={fetchCrossPlatformRowData}
          setValueGetters={SetCrossPlatformValueGetters}
          fetchStatusCount={fetchCrossPlatformStatusCount}
          defaultDetailGridItem="Prep"
        />
      );
      break;
    default:
      gridContent = (
        <Group
          display={'flex'}
          style={{ justifyContent: 'center', alignItems: 'center' }}
        >
          <Loader size="lg" variant="dots" />
          Loading Grid...
        </Group>
      );
      break;
  }

  return (
    <Stack gap="xs" style={{ height: '100%', marginLeft: 10, marginRight: 10 }}>
      <SearchBar views={views} />
      <NotFoundCodesModal />
      <StatusFilterBar />
      {gridContent}
    </Stack>
  );
};

import { create } from 'zustand';
import { iDetails, initialDetailValues, iPreModalState } from './models';

export const useDetailsStore = create<iDetails>()(
  (set, get) => ({
    ...initialDetailValues,
    SetPreModalState: (newPreModal: iPreModalState) => {
      let pMS = { filters: newPreModal.filters, columns: newPreModal.columns };
      set({ preModalState: pMS });
    },
    SetDetails: (newDetails: iDetails) => {
      set({ ...newDetails });
    },
    ToggleIsOpen: () => {
      set({ isOpen: !get().isOpen });
    },
    SetDetailsVisible: (newState: boolean) => {
      set({ detailsVisible: newState });
    },
    ToggleDestailsVisible: () => {
      set({ detailsVisible: !get().detailsVisible });
    },
    SetActiveTab: (newTab: any) => {
      set({ activeTab: newTab });
    }
  })
);

import { ColDef, ValueGetterParams } from 'ag-grid-community';
import dayjs from 'dayjs';

const DateFormatter = (props: any) => {
  const date = props.data.audit.generatedAt;
  let formattedDate = dayjs(date).format('MMM DD, YYYY (hh:mm a | [EST])');

  if (!formattedDate) {
    return <>NA</>;
  }
  return <>{formattedDate}</>;
};

const ConCatURN = (props: any) => {
  if (!props.data.status.codes[0]) return <></>
  return props.data.status.codes[0].code
};


export const Capitalize = (params: ValueGetterParams) => {
  let status = params.data.status.type;
  if (status !== null) status = status.charAt(0).toUpperCase() + status.slice(1);
  return status;
};

export const DetailHistoryColumnDefs: ColDef[] = [
  {
    field: 'status.type',
    headerName: 'Status',
    sortable: true,
    sortingOrder: ['asc', 'desc'],
    width: 150,
    minWidth: 90,
    wrapText: false,
    filter: 'agTextColumnFilter',
    resizable: true,
    // group cell renderer needed for expand / collapse icons
    cellRenderer: 'agGroupCellRenderer',
    valueGetter: Capitalize
  },
  {
    field: 'audit.generatedAt',
    headerName: 'Date',
    sortable: true,
    sortingOrder: ['asc', 'desc'],
    minWidth: 135,
    wrapText: false,
    filter: 'agTextColumnFilter',
    resizable: true,
    cellRenderer: DateFormatter
  },
  {
    field: 'type',
    headerName: 'Event Type',
    sortable: true,
    sortingOrder: ['asc', 'desc'],
    width: 130,
    minWidth: 100,
    wrapText: false,
    filter: 'agTextColumnFilter',
    resizable: true
  },
  {
    field: 'status.codes.0.detail',
    headerName: 'Details',
    sortable: true,
    sortingOrder: ['asc', 'desc'],
    flex: 2,
    minWidth: 90,
    filter: 'agTextColumnFilter',
    resizable: true,
    cellRenderer: 'capitalize'
  }
];

export const DetailHistoryColumnDefsVideo: ColDef[] = [
  {
    field: 'status.type',
    headerName: 'Status',
    sortable: true,
    sortingOrder: ['asc', 'desc'],
    width: 150,
    minWidth: 90,
    wrapText: false,
    filter: 'agTextColumnFilter',
    resizable: true,
    // group cell renderer needed for expand / collapse icons
    cellRenderer: 'agGroupCellRenderer',
    valueGetter: Capitalize
  },
  {
    field: 'audit.generatedAt',
    headerName: 'Date',
    sortable: true,
    sortingOrder: ['asc', 'desc'],
    minWidth: 135,
    wrapText: false,
    filter: 'agTextColumnFilter',
    resizable: true,
    cellRenderer: DateFormatter
  },
  {
    field: 'type',
    headerName: 'Event Type',
    sortable: true,
    sortingOrder: ['asc', 'desc'],
    width: 130,
    minWidth: 100,
    wrapText: false,
    filter: 'agTextColumnFilter',
    resizable: true
  },
  {
    field: 'status.codes.0.detail',
    headerName: 'Details',
    sortable: true,
    sortingOrder: ['asc', 'desc'],
    flex: 2,
    minWidth: 90,
    filter: 'agTextColumnFilter',
    resizable: true,
    cellRenderer: 'capitalize'
  },
  {
    field: 'details.fulfillmentType',
    headerName: 'Fulfillment Type',
    sortable: true,
    sortingOrder: ['asc', 'desc'],
    width: 160,
    minWidth: 100,
    wrapText: false,
    filter: 'agTextColumnFilter',
    resizable: true,
    cellRenderer: 'allCaps'
  },
  {
    field: 'details.dynamicRangeIndicator',
    headerName: 'DRI',
    sortable: true,
    sortingOrder: ['asc', 'desc'],
    width: 80,
    wrapText: false,
    filter: 'agTextColumnFilter',
    resizable: true,
    cellRenderer: 'allCaps'
  },
  {
    field: 'fulfillmentId',
    headerName: 'Fulfillment ID',
    sortable: true,
    sortingOrder: ['asc', 'desc'],
    width: 150,
    minWidth: 100,
    wrapText: false,
    filter: 'agTextColumnFilter',
    resizable: true,
    cellRenderer: 'truncateUUID'
  }
];

export const FullDetailHistoryColumnDefs: ColDef[] = [
  {
    field: 'status.type',
    headerName: 'Status',
    sortable: true,
    sortingOrder: ['asc', 'desc'],
    minWidth: 90,
    wrapText: false,
    filter: 'agTextColumnFilter',
    resizable: true,
    // group cell renderer needed for expand / collapse icons
    cellRenderer: 'agGroupCellRenderer',
    valueGetter: Capitalize
  },
  {
    field: 'audit.generatedAt',
    headerName: 'Date',
    sortable: true,
    sortingOrder: ['asc', 'desc'],
    minWidth: 120,
    wrapText: false,
    filter: 'agTextColumnFilter',
    resizable: true,
    cellRenderer: DateFormatter
  },
  {
    field: 'status.codes.0.code',
    headerName: 'Code',
    sortable: true,
    sortingOrder: ['asc', 'desc'],
    minWidth: 150,
    filter: 'agTextColumnFilter',
    resizable: true,
    cellRenderer: ConCatURN
  },
  {
    field: 'status.codes.0.detail',
    headerName: 'Details',
    sortable: true,
    sortingOrder: ['asc', 'desc'],
    flex: 2,
    minWidth: 90,
    filter: 'agTextColumnFilter',
    resizable: true
  }
];

export const FullDetailHistoryColumnDefsVideo: ColDef[] = [
  {
    field: 'status.type',
    headerName: 'Status',
    sortable: true,
    sortingOrder: ['asc', 'desc'],
    width: 150,
    minWidth: 90,
    wrapText: false,
    filter: 'agTextColumnFilter',
    resizable: true,
    // group cell renderer needed for expand / collapse icons
    cellRenderer: 'agGroupCellRenderer',
    valueGetter: Capitalize
  },
  {
    field: 'audit.generatedAt',
    headerName: 'Date',
    sortable: true,
    sortingOrder: ['asc', 'desc'],
    minWidth: 120,
    wrapText: false,
    filter: 'agTextColumnFilter',
    resizable: true,
    cellRenderer: DateFormatter
  },
  {
    field: 'details.fulfillmentType',
    headerName: 'Fulfillment Type',
    sortable: true,
    sortingOrder: ['asc', 'desc'],
    width: 160,
    minWidth: 100,
    wrapText: false,
    filter: 'agTextColumnFilter',
    resizable: true
  },
  {
    field: 'details.dynamicRangeIndicator',
    headerName: 'DRI',
    sortable: true,
    sortingOrder: ['asc', 'desc'],
    width: 80,
    wrapText: false,
    filter: 'agTextColumnFilter',
    resizable: true
  },
  {
    field: 'status.codes.0.code',
    headerName: 'Code',
    sortable: true,
    sortingOrder: ['asc', 'desc'],
    minWidth: 150,
    filter: 'agTextColumnFilter',
    resizable: true,
    cellRenderer: ConCatURN
  },
  {
    field: 'status.codes.0.detail',
    headerName: 'Details',
    sortable: true,
    sortingOrder: ['asc', 'desc'],
    flex: 2,
    minWidth: 90,
    filter: 'agTextColumnFilter',
    resizable: true
  }
];

export const PFDRMilestoneColumnDefs: ColDef[] = [
  {
    field: 'status.type',
    headerName: 'Status',
    sortable: true,
    sortingOrder: ['asc', 'desc'],
    width: 130,
    minWidth: 90,
    wrapText: false,
    filter: 'agTextColumnFilter',
    resizable: true,
    // group cell renderer needed for expand / collapse icons
    // cellRenderer: 'agGroupCellRenderer',
    valueGetter: Capitalize
  },
  {
    field: 'audit.generatedAt',
    headerName: 'Date',
    sortable: true,
    sortingOrder: ['asc', 'desc'],
    flex: 2,
    minWidth: 130,
    wrapText: false,
    filter: 'agTextColumnFilter',
    resizable: true,
    cellRenderer: DateFormatter
  },
  {
    field: 'type',
    headerName: 'Event Type',
    sortable: true,
    sortingOrder: ['asc', 'desc'],
    width: 130,
    minWidth: 100,
    wrapText: false,
    filter: 'agTextColumnFilter',
    resizable: true
  },
  {
    field: 'status.codes.0.code',
    headerName: 'Code',
    sortable: true,
    sortingOrder: ['asc', 'desc'],
    minWidth: 150,
    flex: 2,
    filter: 'agTextColumnFilter',
    resizable: true,
    cellRenderer: ConCatURN
  },
  {
    field: 'status.codes.0.detail',
    headerName: 'Details',
    sortable: true,
    sortingOrder: ['asc', 'desc'],
    flex: 2,
    minWidth: 90,
    filter: 'agTextColumnFilter',
    resizable: true
  }
];

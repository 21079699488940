import { useConfigStore } from "../app/store";
import { useSearchStore } from "../containers/Search/store";
import useAxios from "../services/axios/axios";

export const fetchPlaylistEntriesData = async (
  id: string
) => {
    const { post } = useAxios();
    const baseURL = useConfigStore.getState().config.baseApiGatewayUrl + '/graphql/search'
    let eventPage = 1;

    const query = await constructQuery(eventPage, id);
    if (!query) return null;
    const schema = {
        query: query
    };

    const eventData = await post({
        url: baseURL,
        data: JSON.stringify(schema)
    });
    if (!eventData) return []

    return eventData.data.playlistEntries.records
};

const constructQuery = async (eventPage: number, id: string) => {
    let queryReturn = await useSearchStore.getState().queries.find(q=>q.id.includes('footprint-offering'));
    if (!queryReturn) {
        const LoadAPI = useSearchStore.getState().LoadAPI;
        await LoadAPI()
        queryReturn = await useSearchStore.getState().queries.find(q=>q.id.includes('footprint-offering'));
      }
    const query = `query {
            playlistEntries(
                filters: {
                    offering: {
                        parentId: {
                            id: "${id}"
                        }
                    }
                }
            ) {
                ${queryReturn?.query}
              }
        }`;

  return query;
};

import { useConfigStore } from '../app/store';
import useAxios from '../services/axios/axios';

export const fetchTimelineHistory = async (
  taskNames: string[],
  footprintId: string,
  footprintType: string,
  statusObject: boolean
) => {
  const { post } = useAxios();
  const baseURL = useConfigStore.getState().config.baseApiGatewayUrl + '/graphql/search';

  if (!taskNames) {
    let result = { rowData: [], rowCount: 0 };
    return result;
  }
  // TODO Check count if too many stop
  let eventPage = 1;

  let combinedRecords: any[] = [];
  let fetchingData = true;
  let totalCount;
  while (fetchingData) {
    const query = constructQuery(
      eventPage,
      taskNames,
      footprintId,
      footprintType,
      statusObject
    );
    if (!query) return null;
    const schema = {
      query: query
    };
    const eventData = await post({
      url: baseURL,
      data: JSON.stringify(schema)
    });
    if (!eventData) return { rowData: [], rowCount: 0 };
    if (eventData.data.history.records){
      if (eventData.data.history.records.length === 0){
        fetchingData = false;
      }
    }
    totalCount = eventData.data.history.count;
    combinedRecords = combinedRecords.concat(eventData.data.history.records);
    if (combinedRecords.length >= totalCount) fetchingData = false;
    eventPage += 1;
  }

  const allRows = combinedRecords;
  const result = { rowData: allRows, rowCount: totalCount };
  return result;
};

const constructQuery = (
  eventPage: number,
  taskNames: string[],
  footprintId: string,
  footprintType: string,
  statusObject: boolean,
) => {
  const queryFilters = constructFilters(
    eventPage,
    taskNames,
    footprintId,
    footprintType,
    statusObject
  );
  if (!queryFilters) return null;
  const query = `query {
            history(
                ${queryFilters}
            ) {
                records {
                    type
                    status {
                      type
                      codes {
                        code
                        detail
                        data
                      }
                    }
                    eventSource
                    eventMetaId
                    audit {
                      createdAt
                      generatedAt
                      updatedAt
                    }
                    details{
                      correlationMetadata {
                        id
                        idNamespace
                      }
                      trackingIds {
                        id
                        idNamespace
                      }
                      fulfillmentType
                      dynamicRangeIndicator
                    }
                  }
                count
            }
          }`;

  return query;
};

const constructFilters = (
  eventPage: number,
  taskNames: string[],
  footprintId: string,
  footprintType: string,
  statusObject: boolean
) => {
  let taskString = ''
  if (taskNames.length > 0) {
    taskString = `task:${JSON.stringify(taskNames)}`;
  } else {
    return null;
  }

  // TODO Add date
  const queryFilters = `filters: {
        id:"${footprintId}"
        type:${footprintType}
        ${taskString}
        page:${eventPage}
        limit:1000
    }`;
  return queryFilters;
};

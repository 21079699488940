import { ColumnState } from 'ag-grid-community';
import { iSearch, initialSearchValues } from '../../../Search/models';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Notification, initialNotification } from '../../../../components/Notification/models';
dayjs.extend(utc);

export interface View {
  id: string; //                  uuid
  label: string; //               human readable name of this view
  timestamp?: number; //          datetime created
  position?: number; //           the row # in the saved views listing, -1 if not saved
  filters?: any; //                explicit ANY in ag-grid code,
  columnState?: ColumnState[]; // the state of the ag-grid, as defined by colDefs,
  type: string; //                < shared | personal | global >:< email address >
  user?: string; //               user's email address - why?
  footprint: string; //           < offering | linear | acquisition >
  default?: boolean; //           is the default view for this user
  search: iSearch; //             the saved search for this view
  chartActive: boolean; //        stores whether the user wants the grid chart active or not
  notification: Notification;  // The notification configuration for this particular view
  query: string;  //              The GraphQL query for the saved search's counts
  searchURL?: string; //          The URL for the saved search
}

export interface iGridStore {
  grid: View; //            the view/grid state
  activeRowIndex: number; //      index of the row last selected, used to restore selection
  gridLayoutModified: boolean; // the grid has been modified flag
  SetActiveRowIndex: (newIndex: number) => void;
  SetGridLayoutModified: (newValue?: boolean) => void;
}

export const initialActiveGridState = {
  id: 'mock-id',
  label: 'Default - test',
  timestamp: dayjs.utc().valueOf(),
  position: -1,
  chartActive: false,
  filters: {},
  columnState: [],
  type: 'mock:test',
  footprint: 'footprint-1',
  default: false,
  search: initialSearchValues,
  notification: initialNotification,
  query: '',
} as View;

export const initialGlobalGridState = [
  {
    label: '',
    id: '',
    filters: {},
    columnState: [],
    position: 1,
    type: '',
    footprint: '',
    default: false,
    chartActive: false,
    search: initialSearchValues,
    notification: initialNotification,
    query: ''
  } as View
];

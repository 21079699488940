export interface iStatusButtons {
  completed: boolean;
  error: boolean;
  failure: boolean;
  pending: boolean;
  processing: boolean;
}

export interface iStatusButtonsStore {
  statusButtons: iStatusButtons;
  GetStatusButtonClicked: () => void;
  SetStatusButtonClicked: (buttons: iStatusButtons) => void;
}

export const initialStatusButtonsStoreValues = {
  statusButtons: {
    completed: false,
    error: false,
    failure: false,
    pending: false,
    processing: false
  },
  GetStatusButtonClicked: () => {},
  SetStatusButtonClicked: (buttons: iStatusButtons) => {}
};

import { Ad, AdOff } from 'tabler-icons-react';
import { useMantineTheme, Tooltip, useComputedColorScheme } from '@mantine/core';

export const showIdMidRollIcon = (csData: any) => {
  //Icons must be wrapped inside a valid component for the hover action to work well
  const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');
  let icons: JSX.Element[] = [];

  const getIconsStyle = (allowed: boolean) => {
    return {
      color: allowed
        ? theme.colors.blue[5]
        : colorScheme === 'dark'
        ? theme.colors.gray[5]
        : theme.colors.gray[7]
    };
  };

  if (
    Object.keys(csData).length !== 0 &&
    csData?.indicators?.midRollAdsAllowedIndicator !== undefined
  ) {
    icons = [
      <Tooltip
        label={
          csData.indicators.midRollAdsAllowedIndicator === true
            ? 'Ads Allowed'
            : 'Ads Not Allowed'
        }
      >
        <div>
          {csData.indicators.midRollAdsAllowedIndicator ? (
            <Ad style={getIconsStyle(true)} />
          ) : (
            <AdOff style={getIconsStyle(false)} />
          )}
        </div>
      </Tooltip>
    ];
  }

  return icons.length == 0 ? false : icons;
};

import { Anchor, Box, Popover, ScrollArea, Stack, Table, Text } from '@mantine/core';
import { InformationAccordion, InformationAccordionItem } from '../../../../components';
import { getRatings } from '../../../../utils/utilities';
import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import {
  DetailAccordion,
  DetailAccordionItem
} from '../../../../components/DetailAccordion';
import { useElementSize, useViewportSize } from '@mantine/hooks';
import { CodeHighlight } from '@mantine/code-highlight';
import { useDetailsStore } from '../../store';
import { useConfigStore } from '../../../../app/store';
import { fetchCSData } from '../../../../api/fetchCSData';
import { fetchFacts } from '../../../../api/fetchFacts';
import { fetchImageCounts } from '../../../../api/fetchImageCounts';
import { showGeneralInfoIcons } from '../_showGeneralInfoIcons';
import { showIdMidRollIcon } from '../_showIdMidRollIcon';
import { showImageAccordionItems } from '../_showImageAccordionItems';
import { showMetaAccordionItems } from '../_showMetaAccordionItems';
import { showMezzAccordionItems } from '../_showMezzAccordionItems';
import { showOfferingAccordionItems } from '../_showOfferingAccordionItems';
import {
  fetchManifestations,
  latestManifestationAccordion
} from '../_showManifestationAccordionItems';
import {
  BrandType,
  adMarkerGetter
} from '../../_columnDefs/offering/offeringValueGetters';
import { useAppConfigManager } from '../../../../components/AppConfigs';
import { iManifestationDetailsParams } from '../../models';
import { fetchJSONID } from '../../../../api/fetchJSONID';
import dayjs from 'dayjs';
import {
  dynamicRangeIndicatorGetter,
  fulfilledAnnotationslabelsGetter,
  fulfilledAudioComponentsGetter,
  fulfilledMarkersGetter,
  fulfilledMediaDurationGetter,
  fulfilledTimedTextComponentsGetter
} from '../../_columnDefs/columnsHelpers/fulfilledComponentsGetters';
import {
  taskCIDGetter,
} from '../../_columnDefs/columnsHelpers/globalValueGetters';

export const offeringPartnerGetter = (partnerId: string) => {
  let partner = '';
  if (partnerId) {
    switch (partnerId) {
      case 'c01c1c07-e7bf-4cee-be6b-87092a30d41c':
        partner = 'Max';
        break;
      case '2195c719-d08d-49e2-b568-234ec46414b5':
        partner = 'Max';
        break;
      case '9646297a-acd4-4884-8977-d56b280a0c4a':
        partner = 'Max';
        break;
      case 'f6bc2fc8-8191-4920-8e9f-99ce4b0ff057':
        partner = 'Max';
        break;
      case '65e74175-2142-4e5a-a85f-65f4ed69f570':
        partner = 'Max';
        break;
      case '155ecceb-bb5d-4cd3-b106-183686d5a2d4':
        partner = 'Max';
        break;
      case 'bdb0b66c-4cb4-49e0-980d-a19145cecf80':
        partner = 'Max';
        break;
      case '22352a43-1948-4ea4-8daa-9bd00749fc5c':
        partner = 'Max';
        break;
      case '4a7ad113-8fda-4939-bda8-a9474a912805':
        partner = 'Amazon PVC';
        break;
      case 'd5c3de78-11ca-4ff3-be8f-d1855e2373ed':
        partner = 'YouTube';
        break;
      case 'ac176604-f0c4-4cf3-b06f-bf9e8aea3ffe':
        partner = 'Telefonica';
        break;
      case 'd646434d-bcf9-4e40-88fb-c5ca52d8ccf0':
        partner = 'Bleacher Report';
        break;
      case 'dd4b242c-7f40-4628-bb79-8e69e5f98fad':
        partner = 'Canal+';
        break;
      default:
        partner = '';
    }
  }
  return partner;
};

export const offeringMarketGetter = (marketId: string) => {
  if (!marketId) {
    return '';
  }
  if (marketId) {
    const markets = useAppConfigManager.getState().queryMarkets;
    const market = markets.find((m) => m.valueList === marketId);
    if (market) {
      return market.label;
    } else {
      return `Market ID: ${marketId} label not found`;
    }
  }
};

export const OfferingsDetail = (props: any) => {
  const details = props.details?.offering
    ? props.details
    : useDetailsStore((state) => state.offering);
  const { tasks, offering, audit, csId } = details;

  const config = useConfigStore((state) => state.config);

  const [imageFacts, setImageFacts] = useState([]);
  const [imageFactsDelivered, setImageFactsDelivered] = useState(0);
  const [imageFactsRequired, setImageFactsRequired] = useState(0);

  const [mezzFacts, setMezzFacts] = useState([]);
  const [metaFacts, setMetaFacts] = useState([]);
  const [offeringFacts, setOfferingFacts] = useState([]);

  const [openOfferingIdPop, setOpenOfferingIdPop] = useState({
    idMenu: false,
    idJSON: false
  });
  const [offeringIdJson, setOfferingIdJson] = useState([]);

  const [csData, setCsData] = useState<any>({});
  const [manifestations, setManifestations] = useState<any>({});

  async function fetchJsonId() {
    const baseUrl = config.baseApiGatewayUrl + '/graphql/search';
    let result = await fetchJSONID(baseUrl, offering.eventMetaId, 'ondemand');
    setOfferingIdJson(result?.data?.event?.records);
  }

  async function fetchFactsData() {
    let result = await fetchFacts(offering.id);
    setImageFacts(
      result
        .filter((i: { type: string }) => i.type === 'imagedelivery')
        .sort(function (a: any, b: any) {
          var nA = a.details.asset.imageSpec.imageRole;
          var nB = b.details.asset.imageSpec.imageRole;

          if (nA < nB) return -1;
          else if (nA > nB) return 1;
          return 0;
        })
    );
    setMezzFacts(result.filter((i: { type: string }) => i.type === 'mezzdelivery'));
    setMetaFacts(result.filter((i: { type: string }) => i.type === 'metadelivery'));
    setOfferingFacts(
      result.filter((i: { type: string }) => i.type === 'offeringdelivery')
    );
    let imageFactCount = await fetchImageCounts(offering.id);
    if (imageFactCount && imageFactCount.length > 0) {
      imageFactCount.sort(function (a: any, b: any) {
        return b.audit.generatedAt.localeCompare(a.audit.generatedAt);
      });
      setImageFactsDelivered(imageFactCount[0].delivered);
      setImageFactsRequired(imageFactCount[0].required);
    }
  }

  async function fetchCS() {
    const baseUrl = config.baseApiGatewayUrl + '/graphql/search';
    let result = await fetchCSData(baseUrl, offering.id, 'ondemand');
    if (result) setCsData(result);
  }

  useEffect(() => {
    fetchFactsData();
    fetchCS();
    fetchJsonId();
    fetchManifestations(tasks, setManifestations);
  }, []);

  const offeringContentSourceGetter = (offeringSource: string) => {
    let source = '';
    if (offeringSource) {
      switch (offeringSource) {
        case 'esp':
          source = 'WarnerMedia';
          break;
        case 'offramp':
          source = 'Discovery';
          break;
        default:
          source = 'N/A';
      }
    }
    return source;
  };

  const idFields = useAppConfigManager
    ?.getState()
    ?.GetSearchInputs('footprint-offering')
    ?.map((field) => ({
      label: field.label,
      value: field.footprint
        ?.find((fp) => fp.footprint === 'footprint-offering')
        ?.path?.replace(/offering./g, '')
    }));

  const showDetail = (d: any) => {
    if (d === undefined) {
      return;
    }
    if (offering === undefined) {
      return;
    }
    const path = d?.value?.split('.');
    let value = null;
    switch (d.label) {
      case 'CSID':
        if (csId?.id) value = csId?.id;
        break;
      case 'CID':
        value = taskCIDGetter(tasks, 'mezzcreation');
        break;
      default:
        if (!offering?.[path?.[0]]) return;
        let pathValue = offering;
        path.forEach((e: string) => {
          pathValue = pathValue[e];
        });
        if (typeof pathValue !== 'string') return;
        value = pathValue;
    }
    if (!value) return;
    const formattedType = offering?.type?.split('_').slice(-1)[0];
    const piEnv = config.environment === 'prod' ? '' : `${config.environment}.`;
    switch (d.label) {
      case 'Offering Id':
        const offeringLinkableTypes = ['program', 'season', 'series', 'promo playlist'];
        const topic =
          formattedType === 'promo playlist' ? 'playlistoffering' : 'offering';
        const objectType =
          formattedType === 'promo playlist' ? 'playlistoffering' : 'offering';
        return (
          <Popover
            key={value + 'OfferingPopover'}
            position={openOfferingIdPop?.idJSON ? 'right' : 'bottom'}
            width={openOfferingIdPop?.idJSON ? 800 : 'auto'}
            withArrow
            shadow="md"
            onClose={() => setOpenOfferingIdPop({ idMenu: false, idJSON: false })}
            opened={openOfferingIdPop?.idMenu}
            zIndex={9999}
          >
            <Popover.Target>
              <Table.Tr
                key={value + 'Offering'}
                onClick={() => setOpenOfferingIdPop({ idMenu: true, idJSON: false })}
              >
                <Table.Td style={{ textAlign: 'left' }}>{d.label}</Table.Td>
                <Table.Td>
                  <Anchor type="button">{value}</Anchor>
                </Table.Td>
              </Table.Tr>
            </Popover.Target>
            <Popover.Dropdown>
              {openOfferingIdPop?.idJSON && (
                <ScrollArea h={425}>
                  <Text fw={700}>JSON</Text>
                  <CodeHighlight
                    code={JSON.stringify(offeringIdJson, null, 2)}
                    language="tsx"
                  ></CodeHighlight>
                </ScrollArea>
              )}
              <Stack align={'center'}>
                {!openOfferingIdPop?.idJSON &&
                  offeringLinkableTypes.includes(formattedType) && (
                    <>
                      {(offering.seriesId?.id && ['season', 'series'].includes(formattedType)) && (
                        <Anchor
                          target="_blank"
                          href={
                            offering?.seasonId?.id
                              ? `https://studio.${config.couiEnvironment}.api.discomax.com/content-visualization/series/${offering.seriesId?.id}/seasons/${offering?.seasonId?.id}/offerings/${value}`
                              : `https://studio.${config.couiEnvironment}.api.discomax.com/content-visualization/series/${offering.seriesId?.id}/offerings/${value}`
                          }
                        >
                          CV
                        </Anchor>
                      )}
                      {(formattedType === 'program' && offering.contentId?.id) && (
                        <Anchor
                          target="_blank"
                          href={`https://studio.${config.couiEnvironment}.api.discomax.com/content-visualization/programs/${offering.contentId?.id}/offerings/${value}`}
                        >
                          CV
                        </Anchor>
                      )}
                      <Anchor
                        target="_blank"
                        href={`https://backoffice.${config.couiEnvironment}.api.discomax.com/prismatic-microapps/co-ui/objects/${value}?db=cvs&topic=${topic}&objectType=${objectType}`}
                      >
                        COUI
                      </Anchor>
                      {offering.workflowId?.id && (
                        <Anchor
                          target="_blank"
                          href={`https://apps.${piEnv}dsuite.tech/nlcd-drs-ui/details/${offering.workflowId.id}`}
                        >
                          DRS
                        </Anchor>
                      )}
                      {offering.workflowId?.id &&
                        offeringContentSourceGetter(offering.transcodingSystem) ===
                          'Discovery' && (
                          <>
                            <Anchor
                              target="_blank"
                              href={`https://apps.${piEnv}dsuite.tech/nlcd-transcode/#/detail/${offering.workflowId.id}`}
                            >
                              TRS
                            </Anchor>
                          </>
                        )}
                    </>
                  )}
                {!openOfferingIdPop?.idJSON && (
                  <Anchor
                    onClick={(e) => setOpenOfferingIdPop({ idJSON: true, idMenu: true })}
                  >
                    View JSON
                  </Anchor>
                )}
              </Stack>
            </Popover.Dropdown>
          </Popover>
        );

        break;
      case 'Content Id':
        const contentLinkableTypes = ['program', 'season', 'series'];
        if (contentLinkableTypes.includes(formattedType)) {
          let topic;
          let objectType;
          if (formattedType === 'program') {
            topic = 'program';
            objectType = 'program';
          } else if (formattedType === 'season') {
            topic = 'season';
            objectType = 'season';
          } else {
            topic = 'series';
            objectType = 'series';
          }
          return (
            <Popover
              key={value + 'ContentPopover'}
              position="bottom"
              withArrow
              shadow="md"
              classNames={{
                arrow: 'popoverArrow',
                dropdown: 'popoverDropdownBelow'
              }}
              zIndex={9999}
            >
              <Popover.Target>
                <Table.Tr key={value + 'Content'}>
                  <Table.Td style={{ textAlign: 'left' }}>{d.label}</Table.Td>
                  <Table.Td>
                    <Anchor type="button">{value}</Anchor>
                  </Table.Td>
                </Table.Tr>
              </Popover.Target>
              <Popover.Dropdown>
                <Stack align={'center'}>
                  {formattedType === 'program' && (
                    <Anchor
                      target="_blank"
                      href={`https://studio.${config.couiEnvironment}.api.discomax.com/content-visualization/programs/${value}`}
                    >
                      CV
                    </Anchor>
                  )}
                  {formattedType === 'series' && (
                    <Anchor
                      target="_blank"
                      href={`https://studio.${config.couiEnvironment}.api.discomax.com/content-visualization/series/${value}`}
                    >
                      CV
                    </Anchor>
                  )}
                  {(formattedType === 'season' && offering.seriesId?.id )&& (
                    <Anchor
                      target="_blank"
                      href={`https://studio.${config.couiEnvironment}.api.discomax.com/content-visualization/series/${offering.seriesId?.id}/seasons/${value}`}
                    >
                      CV
                    </Anchor>
                  )}
                  <Anchor
                    target="_blank"
                    href={`https://backoffice.${config.couiEnvironment}.api.discomax.com/prismatic-microapps/co-ui/objects/${value}?db=cvs&topic=${topic}&objectType=${objectType}`}
                  >
                    COUI
                  </Anchor>
                  <Anchor
                    target="_blank"
                    href={`https://apps.${piEnv}dsuite.tech/prometheus/api/v4/canonical/asset/${value}`}
                  >
                    PI
                  </Anchor>
                  <Anchor
                    target="_blank"
                    href={`${window.location.origin}/views/offerings/keyword/${value}`}
                  >
                    Related Offerings
                  </Anchor>
                </Stack>
              </Popover.Dropdown>
            </Popover>
          );
        }
        break;
      case 'Edit Id':
        return (
          <Popover
            key={value + 'EditPopover'}
            position="bottom"
            withArrow
            shadow="md"
            classNames={{
              arrow: 'popoverArrow',
              dropdown: 'popoverDropdownBelow'
            }}
            zIndex={9999}
          >
            <Popover.Target>
              <Table.Tr key={value + 'Edit'}>
                <Table.Td style={{ textAlign: 'left' }}>{d.label}</Table.Td>
                <Table.Td>
                  <Anchor type="button">{value}</Anchor>
                </Table.Td>
              </Table.Tr>
            </Popover.Target>
            <Popover.Dropdown>
              <Stack align={'center'}>
                {offering?.contentId?.id && (
                  <Anchor
                    target="_blank"
                    href={`https://studio.${config.couiEnvironment}.api.discomax.com/content-visualization/programs/${offering?.contentId?.id}/edits/${value}`}
                  >
                    CV
                  </Anchor>
                )}
                <Anchor
                  target="_blank"
                  href={`https://backoffice.${config.couiEnvironment}.api.discomax.com/prismatic-microapps/co-ui/objects/${value}?db=cvs&topic=edit&objectType=edit`}
                >
                  COUI
                </Anchor>
                {offering.entityType !== 'promo playlist' &&
                  offering.entityType !== 'promo' && (
                    <Anchor
                      target="_blank"
                      href={`https://apps.${piEnv}dsuite.tech/prometheus/api/v4/canonical/asset/${value}`}
                    >
                      PI
                    </Anchor>
                  )}
              </Stack>
            </Popover.Dropdown>
          </Popover>
        );
      case 'CSID':
        return (
          <Popover
            key={value + 'CSIDPopover'}
            width={800}
            position="right"
            withArrow
            shadow="md"
            styles={{
              dropdown: {
                marginTop: 0
              }
            }}
            classNames={{
              arrow: 'popoverArrow',
              dropdown: 'popoverDropdown'
            }}
            zIndex={9999}
          >
            <Popover.Target>
              <Table.Tr key={value + 'CSID'}>
                <Table.Td style={{ textAlign: 'left' }}>{d.label}</Table.Td>
                <Table.Td>
                  <Anchor type="button">{value}</Anchor>
                </Table.Td>
              </Table.Tr>
            </Popover.Target>
            <Popover.Dropdown>
              <ScrollArea h={425}>
                <Text fw={700}>Content Subscription JSON</Text>
                <CodeHighlight code={JSON.stringify(csData, null, 2)} language="tsx" />
              </ScrollArea>
            </Popover.Dropdown>
          </Popover>
        );
      case 'Season Id':
        return (
          <Popover
            key={value + 'SeasonPopover'}
            position="bottom"
            withArrow
            shadow="md"
            classNames={{
              arrow: 'popoverArrow',
              dropdown: 'popoverDropdownBelow'
            }}
            zIndex={9999}
          >
            <Popover.Target>
              <Table.Tr key={value + 'Season'}>
                <Table.Td style={{ textAlign: 'left' }}>{d.label}</Table.Td>
                <Table.Td>
                  <Anchor type="button">{value}</Anchor>
                </Table.Td>
              </Table.Tr>
            </Popover.Target>
            <Popover.Dropdown>
              <Stack align={'center'}>
                {offering?.seriesId?.id && (
                  <Anchor
                    target="_blank"
                    href={`https://studio.${config.couiEnvironment}.api.discomax.com/content-visualization/series/${offering?.seriesId?.id}/seasons/${value}`}
                  >
                    CV
                  </Anchor>
                )}
                <Anchor
                  target="_blank"
                  href={`https://backoffice.${config.couiEnvironment}.api.discomax.com/prismatic-microapps/co-ui/objects/${value}?db=cvs&topic=season&objectType=season`}
                >
                  COUI
                </Anchor>
                {offering.entityType !== 'promo playlist' &&
                  offering.entityType !== 'promo' && (
                    <Anchor
                      target="_blank"
                      href={`https://apps.${piEnv}dsuite.tech/prometheus/api/v4/canonical/asset/${value}`}
                    >
                      PI
                    </Anchor>
                  )}
                {offering.contentId?.id && (
                  <Anchor
                    target="_blank"
                    href={`${window.location.origin}/views/offerings/keyword/${value}`}
                  >
                    Related Offerings
                  </Anchor>
                )}
              </Stack>
            </Popover.Dropdown>
          </Popover>
        );
      case 'Series Id':
        return (
          <Popover
            key={value + 'SeriesPopover'}
            position="bottom"
            withArrow
            shadow="md"
            classNames={{
              arrow: 'popoverArrow',
              dropdown: 'popoverDropdownBelow'
            }}
            zIndex={9999}
          >
            <Popover.Target>
              <Table.Tr key={value + 'Series'}>
                <Table.Td style={{ textAlign: 'left' }}>{d.label}</Table.Td>
                <Table.Td>
                  <Anchor type="button">{value}</Anchor>
                </Table.Td>
              </Table.Tr>
            </Popover.Target>
            <Popover.Dropdown>
              <Stack align={'center'}>
                <Anchor
                  target="_blank"
                  href={`https://studio.${config.couiEnvironment}.api.discomax.com/content-visualization/series/${value}`}
                >
                  CV
                </Anchor>
                <Anchor
                  target="_blank"
                  href={`https://backoffice.${config.couiEnvironment}.api.discomax.com/prismatic-microapps/co-ui/objects/${value}?db=cvs&topic=series&objectType=series`}
                >
                  COUI
                </Anchor>
                {offering.entityType !== 'promo playlist' &&
                  offering.entityType !== 'promo' && (
                    <Anchor
                      target="_blank"
                      href={`https://apps.${piEnv}dsuite.tech/prometheus/api/v4/canonical/asset/${value}`}
                    >
                      PI
                    </Anchor>
                  )}
                {offering.contentId?.id && (
                  <Anchor
                    target="_blank"
                    href={`${window.location.origin}/views/offerings/keyword/${value}`}
                  >
                    Related Offerings
                  </Anchor>
                )}
              </Stack>
            </Popover.Dropdown>
          </Popover>
        );
      default:
        return (
          <Table.Tr key={value + 'DefaultPopover'}>
            <Table.Td style={{ textAlign: 'left' }}>{d.label}</Table.Td>
            <Table.Td>{value}</Table.Td>
          </Table.Tr>
        );
    }
  };

  const extraIdFields = [
    {
      label: 'CID',
      value: 'cId.id'
    }
  ];

  const shownIDs = useMemo(
    () => [...idFields, ...extraIdFields].map(showDetail),
    [csData, offeringIdJson, openOfferingIdPop]
  );

  const { ref, width } = useElementSize();

  const { height } = useViewportSize();

  const offeringPartnerGetter = (partnerId: string) => {
    let partner = '';
    if (partnerId) {
      switch (partnerId) {
        case 'c01c1c07-e7bf-4cee-be6b-87092a30d41c':
          partner = 'Max';
          break;
        case '2195c719-d08d-49e2-b568-234ec46414b5':
          partner = 'Max';
          break;
        case '9646297a-acd4-4884-8977-d56b280a0c4a':
          partner = 'Max';
          break;
        case 'f6bc2fc8-8191-4920-8e9f-99ce4b0ff057':
          partner = 'Max';
          break;
        case '65e74175-2142-4e5a-a85f-65f4ed69f570':
          partner = 'Max';
          break;
        case '155ecceb-bb5d-4cd3-b106-183686d5a2d4':
          partner = 'Max';
          break;
        case 'bdb0b66c-4cb4-49e0-980d-a19145cecf80':
          partner = 'Max';
          break;
        case '22352a43-1948-4ea4-8daa-9bd00749fc5c':
          partner = 'Max';
          break;
        case '4a7ad113-8fda-4939-bda8-a9474a912805':
          partner = 'Amazon PVC';
          break;
        case 'd5c3de78-11ca-4ff3-be8f-d1855e2373ed':
          partner = 'YouTube';
          break;
        case 'ac176604-f0c4-4cf3-b06f-bf9e8aea3ffe':
          partner = 'Telefonica';
          break;
        case 'd646434d-bcf9-4e40-88fb-c5ca52d8ccf0':
          partner = 'Bleacher Report';
          break;

        default:
          partner = '';
      }
    }
    return partner;
  };

  const adMarkerParams: iManifestationDetailsParams = {
    tasks: tasks
  };
  const adMarkerTaskCount = adMarkerGetter(adMarkerParams);

  const manifestationDetails = (m: string) => {
    const params: iManifestationDetailsParams = {
      tasks
    };
    switch (m) {
      case 'fulfilledAudioComponents':
        return fulfilledAudioComponentsGetter(params);
      case 'fulfilledTimedTextComponents':
        return fulfilledTimedTextComponentsGetter(params);
      case 'fulfilledMarkers':
        return fulfilledMarkersGetter(params);
      case 'fulfilledAnnotationsLabels':
        return fulfilledAnnotationslabelsGetter(params);
      case 'fulfilledMediaDuration':
        return fulfilledMediaDurationGetter(params);
      case 'dynamicRangeIndicator':
        return dynamicRangeIndicatorGetter(params);
      default:
        return null;
    }
  };

  if (offering === undefined) return <></>;

  return (
    <Box
      ref={ref}
      style={{ margin: 8, marginLeft: 24, overflow: 'auto', maxHeight: height - 250 }}
    >
      <InformationAccordion
        defaultValue={['item-1-information', 'item-2-information']}
        chevronPosition="left"
        multiple={true}
        style={{ minWidth: 350, width: width }}
      >
        <InformationAccordionItem
          key="item-1-information"
          name="item-1"
          title="General Info"
          icons={showGeneralInfoIcons(offering?.actions)}
        >
          <Table
            striped={false}
            highlightOnHover={true}
            style={{ width: '110%', margin: -16, marginTop: -32 }}
          >
            <Table.Thead>
              <Table.Tr>
                <Table.Td>&nbsp;</Table.Td>
                <Table.Td>&nbsp;</Table.Td>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              <Table.Tr>
                <Table.Td style={{ textAlign: 'left' }}>Title</Table.Td>
                <Table.Td>
                  {offering?.titles?.length > 0 ? (
                    findTitle(offering.titles)
                  ) : (
                    <>--No Title--</>
                  )}
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td style={{ textAlign: 'left' }}>Content Class</Table.Td>
                <Table.Td>
                  {offering.type ? (
                    offering.type.split('_').slice(-1)[0].charAt(0).toUpperCase() +
                    offering.type.split('_').slice(-1)[0].slice(1)
                  ) : (
                    <>N/A</>
                  )}
                </Table.Td>
              </Table.Tr>
              {offering.entityType && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Entity Type</Table.Td>
                  <Table.Td>
                    {offering.entityType.charAt(0).toUpperCase() +
                      offering.entityType.slice(1)}
                  </Table.Td>
                </Table.Tr>
              )}
              {offering?.seriesTitles?.length > 0 && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Series Title</Table.Td>
                  <Table.Td>{findTitle(offering.seriesTitles)}</Table.Td>
                </Table.Tr>
              )}
              {offering?.seasonTitles?.length > 0 && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Season Title</Table.Td>
                  <Table.Td>{findTitle(offering.seriesTitles)}</Table.Td>
                </Table.Tr>
              )}
              {offering.seasonNumber && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Season #</Table.Td>
                  <Table.Td>{offering.seasonNumber}</Table.Td>
                </Table.Tr>
              )}
              {offering.episodeNumberInSeason && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Episode #</Table.Td>
                  <Table.Td>{offering.episodeNumberInSeason}</Table.Td>
                </Table.Tr>
              )}
              {offering.transcodingSystem && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Content Source</Table.Td>
                  <Table.Td>
                    {offeringContentSourceGetter(offering.transcodingSystem)}
                  </Table.Td>
                </Table.Tr>
              )}
              {offering.partnerId?.id && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Partner</Table.Td>
                  <Table.Td>{offeringPartnerGetter(offering.partnerId.id)}</Table.Td>
                </Table.Tr>
              )}
              {offering.marketId?.id && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Market</Table.Td>
                  <Table.Td>{offeringMarketGetter(offering.marketId.id)}</Table.Td>
                </Table.Tr>
              )}
              {offering.brands && offering.brands.length > 0 && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Primary Brand</Table.Td>
                  <Table.Td>
                    {offering.brands
                      ?.filter((brand: BrandType) => brand.primary)
                      ?.map((brand: BrandType) => brand.label)
                      ?.join(', ')}
                  </Table.Td>
                </Table.Tr>
              )}
              {offering.brands && offering.brands.length > 0 && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Secondary Brand</Table.Td>
                  <Table.Td>
                    {offering.brands
                      ?.filter((brand: BrandType) => !brand.primary)
                      ?.map((brand: BrandType) => brand.label)
                      ?.join(', ')}
                  </Table.Td>
                </Table.Tr>
              )}
              {offering.controllingNetwork && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Controlling Network</Table.Td>
                  <Table.Td>{offering.controllingNetwork}</Table.Td>
                </Table.Tr>
              )}
              {typeof adMarkerTaskCount === 'number' && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>
                    Fulfill Ad Marker Count
                  </Table.Td>
                  <Table.Td>{adMarkerTaskCount}</Table.Td>
                </Table.Tr>
              )}
              {offering.globalReleaseDate && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Global Release Date</Table.Td>
                  <Table.Td>
                    {offering.globalReleaseDate ? (
                      dayjs(offering.globalReleaseDate).format(
                        'MMM DD, YYYY (hh:mm a | [EST])'
                      )
                    ) : (
                      <></>
                    )}
                  </Table.Td>
                </Table.Tr>
              )}
              {offering.ratings?.length > 0 && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Ratings </Table.Td>
                  <Table.Td>{getRatings(offering.ratings)}</Table.Td>
                </Table.Tr>
              )}
              <Table.Tr>
                <Table.Td style={{ textAlign: 'left' }}>Start Date/Time(ET)</Table.Td>
                <Table.Td>
                  {offering.startDate ? (
                    dayjs(offering.startDate).format('MMM DD, YYYY (hh:mm a | [EST])')
                  ) : (
                    <>N/A</>
                  )}
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td style={{ textAlign: 'left' }}>End Date/Time(ET)</Table.Td>
                <Table.Td>
                  {offering.endDate ? (
                    dayjs(offering.endDate).format('MMM DD, YYYY (hh:mm a | [EST])')
                  ) : (
                    <>N/A</>
                  )}
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td style={{ textAlign: 'left' }}>Date Received(ET)</Table.Td>
                <Table.Td>
                  {audit.createdAt ? (
                    dayjs(audit.createdAt).format('MMM DD, YYYY (hh:mm a | [EST])')
                  ) : (
                    <>N/A</>
                  )}
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td style={{ textAlign: 'left' }}>Last Updated(ET)</Table.Td>
                <Table.Td>
                  {audit.updatedAt ? (
                    dayjs(audit.updatedAt).format('MMM DD, YYYY (hh:mm a | [EST])')
                  ) : (
                    <>N/A</>
                  )}
                </Table.Td>
              </Table.Tr>
            </Table.Tbody>
          </Table>
        </InformationAccordionItem>
        {!_.isEmpty(_.compact(shownIDs)) && (
          <InformationAccordionItem
            key="item-2-information"
            name="item-2"
            title="IDs"
            icons={showIdMidRollIcon(csData)}
          >
            <Table
              striped={false}
              highlightOnHover={true}
              style={{ width: '110%', margin: -16, marginTop: -32 }}
            >
              <Table.Thead>
                <Table.Tr>
                  <Table.Td>&nbsp;</Table.Td>
                  <Table.Td>&nbsp;</Table.Td>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{shownIDs}</Table.Tbody>
            </Table>
          </InformationAccordionItem>
        )}
      </InformationAccordion>

      <DetailAccordion
        defaultValue={[]}
        chevronPosition="left"
        multiple={true}
        style={{ minWidth: 350, overflow: 'auto' }}
      >
        {latestManifestationAccordion(manifestations, manifestationDetails, width)}
        {imageFacts.length > 0 && (
          <DetailAccordionItem
            testid={`DetailAccordionItem-ImagesDelivered`}
            key="item-0-detail"
            name="item-0"
            title="Images Delivered"
            title2={`${imageFactsDelivered}/${imageFactsRequired}`}
            styles={{ borderBottom: 0 }}
          >
            {imageFacts.map((k) => showImageAccordionItems(k, width))}
          </DetailAccordionItem>
        )}
        {metaFacts.length > 0 && (
          <DetailAccordionItem
            testid={`DetailAccordionItem-MetaDelivered"`}
            key="item-1-detail"
            name="item-1"
            title="Meta Delivered"
            styles={{ borderBottom: 0 }}
          >
            {metaFacts.map((k) => showMetaAccordionItems(k, width))}
          </DetailAccordionItem>
        )}
        {mezzFacts.length > 0 && (
          <DetailAccordionItem
            testid={`DetailAccordionItem-MezzDelivered`}
            key="item-2-detail"
            name="item-2"
            title="Mezz Delivered"
            styles={{ borderBottom: 0 }}
          >
            {mezzFacts.map((k) => showMezzAccordionItems(k, width))}
          </DetailAccordionItem>
        )}
        {offeringFacts.length > 0 && (
          <DetailAccordionItem
            testid={`DetailAccordionItem-OfferingDelivered`}
            key="item-3-detail"
            name="item-3"
            title="Offering Delivered"
            styles={{ borderBottom: 0 }}
          >
            {offeringFacts.map((k) => showOfferingAccordionItems(k, width))}
          </DetailAccordionItem>
        )}
      </DetailAccordion>
    </Box>
  );
};

interface Title {
  full: string;
  type: string;
  language: string;
}

export const Truncate = (title: any, width: number) => {
  if (width === 0) return '';
  const truncateSize = Math.floor(width / 100) - 1;
  if (title.length >= truncateSize * 10) {
    return title.split('', truncateSize * 10).join('') + '...';
  }
  return title;
};

export const findTitle = (titles: Title[], TruncateTitle?: boolean, width?: number) => {
  let title;
  if (!titles) return '--No Title--';
  if (titles.length > 0) {
    const distribution = titles.find(
      (title: { type: string }) => title.type === 'distribution-title'
    );
    title = distribution ? distribution.full : null;
    if (!title) {
      const main = titles.find((title: { type: string }) => title.type === 'main-title');
      title = main ? main.full : null;
    }
    if (!title) {
      const cat = titles.find((title: { type: string }) => title.type === 'cat-title');
      title = cat ? cat.full : '';
    }
    if (!title) {
      const fallback = titles.find(
        (title: { type: string }) => title.type === 'internal-wm-name'
      );
      title = fallback ? fallback.full : '--No Title--';
    }
  }
  if (!title) title = '--No Title--';
  if (TruncateTitle && width) {
    return Truncate(title, width);
  }
  return title;
};

export default OfferingsDetail;

export interface iSearch {
  // Offering Fields Begin
  ContentSource: Array<string>;
  OfferingType: Array<string>;
  EntityType: Array<string>;
  SearchInputField: string;
  DateField: string;
  startDate: string;
  startDateTime: string;
  endDate: string;
  endDateTime: string;
  dateEnabled: boolean;
  Footprint: string;
  SearchInput: string;
  timeRelative: boolean;
  Milestone: Array<string>;
  MilestoneStatus: Array<string>;
  StatusCodes: string[][];
  DeletedAssets: string;
  ExpiredAssets: string;
  Partner: string[][];
  Market: string[][];
  AdMarkers: string;
  MidRollAdsAllowed: string,
  Tier1: boolean,
  // Offering Fields End
  // Linear Fields Begin
  SuppressRepeats: string;
  Network: string[][];
  ScheduleType: string;
  ContentType: Array<string>;
  Feed: string;
  LinearExpired: boolean;
  IsLive: string;
  // Linear Fields End
  // Acquire Fields Begin
  ContentTypeAcquire: Array<string>;
  Distributor: Array<string>;
  // Acquire Fields End
  // Cross Platform Fields Begin
  Destinations: string[][];
  // Cross Platform Fields End
}

export const offeringFields = [
  'ContentSource',
  'OfferingType',
  'EntityType',
  'SearchInputField',
  'DateField',
  'startDate',
  'startDateTime',
  'endDate',
  'endDateTime',
  'dateEnabled',
  'Footprint',
  'SearchInput',
  'timeRelative',
  'Milestone',
  'MilestoneStatus',
  'StatusCodes',
  'DeletedAssets',
  'ExpiredAssets',
  'Partner',
  'Market',
  'MidRollAdsAllowed',
  'AdMarkers'
];

export const linearFields = [
  'SuppressRepeats',
  'Network',
  'ScheduleType',
  'ContentType',
  'Feed',
  'LinearExpired',
  'IsLive'
];

export const acquireFields = [
  'ContentTypeAcquire',
  'Distributor'
]

export interface iQueryReturn {
  id: string;
  type: string;
  query: string;
}

export interface iSearchState {
  startDate: Date | null;
  endDate: Date | null;
  startDateTime: string;
  endDateTime: string;
  isSearching: boolean;
  search: iSearch;
  chartSearch: iSearch;
  currentRowCount: number;
  recordCount: number;
  queries: iQueryReturn[];
  SetSearch: (newSearch: iSearch) => void;
  SetChartSearch: (newSearch: iSearch) => void;
  SetCurrentRowCount: (currentRowCount: number) => void;
  SetRecordCount: (recordCount: number) => void;
  ToggleIsSearching: (explicit?: boolean) => void;
  UpdateSearchInput: (payload: { field: string; value: any }) => void;
  ClearSearchInput: (fp: string, inputs: { SearchInputField: string, SearchInput: string, DateField: string }) => void;
  UpdateStartDate: (date: Date | null) => void;
  UpdateEndDate: (date: Date | null) => void;
  ToggleRelativeMode: () => void;
  LoadAPI: () => void;
}

export const initialSearchValues: iSearch = {
  ContentSource: [],
  OfferingType: [],
  EntityType: [],
  SearchInputField: 'keyword',
  endDate: '',
  startDate: '',
  endDateTime: '',
  startDateTime: '',
  Footprint: '',
  SearchInput: '',
  timeRelative: true,
  Milestone: [],
  MilestoneStatus: [],
  StatusCodes: [],
  DeletedAssets: 'notDeleted',
  ExpiredAssets: 'active',
  Partner: [],
  Market: [],
  Network: [],
  ScheduleType: '',
  AdMarkers: '',
  MidRollAdsAllowed: '',
  Tier1: false,
  SuppressRepeats: '',
  ContentType: [],
  Feed: '',
  DateField: 'startDate',
  dateEnabled: true,
  LinearExpired: false,
  IsLive: 'false',
  ContentTypeAcquire: [],
  Distributor: [],
  Destinations: []
};

export const initialSearchStateValues: iSearchState = {
  startDate: new Date(),
  endDate: new Date(),
  startDateTime: '00:00:00',
  endDateTime: '23:59:59',
  isSearching: false,
  search: initialSearchValues,
  chartSearch: initialSearchValues,
  currentRowCount: 0,
  recordCount: 0,
  queries: [] as iQueryReturn[],
  SetSearch: (newSearch: iSearch) => {},
  SetChartSearch: (newSearch: iSearch) => {},
  SetCurrentRowCount: (currentRowCount: number) => {},
  SetRecordCount: (recordCount: number) => {},
  ToggleIsSearching: () => {},
  UpdateSearchInput: (payload: { field: string; value: any }) => {},
  ClearSearchInput: (fp: string, inputs: { SearchInputField: string, SearchInput: string, DateField: string }) => {},
  UpdateStartDate: (date: Date | null) => {},
  UpdateEndDate: (date: Date | null) => {},
  ToggleRelativeMode: () => {},
  LoadAPI: () => undefined
};

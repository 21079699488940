import { Card, Group, Table, Text, useComputedColorScheme, useMantineTheme } from '@mantine/core';
import { StatusColor, StatusIcon } from '../../components/';
import { capitalize } from '../../utils/utilities';
import { useRef, useEffect, useState } from 'react'
import dayjs from 'dayjs';
import { useStatusCodeStore } from '../StatusCodeDictionary/store';

interface iMilestoneBriefPanelProps {
  column: string;
  status: string;
  code?: string;
  detail?: string;
  data?: string;
  type?: string;
  updated: string;
}

export const MilestoneBriefPanel = (props: iMilestoneBriefPanelProps) => {
  const statusColor = StatusColor();
  const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');
  const statusCodeRef = useRef<HTMLDivElement>(null);
  const [statusCodeWidth ,setStatusCodeWidth] = useState('100px')
  let detail = props.detail
  if (props.code) {
    if (!detail || detail === props.data) {
      const GetStatusCode = useStatusCodeStore.getState().GetStatusCode;
      let sc = GetStatusCode(props.code);
      if (sc) {
        detail = sc.details
      }
    } 
  }

  useEffect(() => {
    if (statusCodeRef?.current?.clientWidth) {
      setStatusCodeWidth(`${statusCodeRef.current.clientWidth}px`);
    }
  }, [statusCodeRef?.current]);

  let nonStatus;
  const nonStatuses = ['nodata', 'na'];
  if (nonStatuses.includes(props.status)) {
    nonStatus = props.status === 'nodata' ? 'No Data' : 'N/A';
  }

  let statusText = nonStatus ? nonStatus : props.status;

  let formattedDate
  if (props.updated) {
    formattedDate = dayjs(props.updated).format('MMM DD, YYYY (hh:mm a | [EST])');
  }

  return (
    <Card
      radius="sm"
      withBorder
      style={{ border: `1px ${statusColor[props.status]} solid` }}
    >
      <Card.Section
        style={{
          backgroundColor:
            props.status === 'deleted' ? '#222' : statusColor[props.status],
          borderBottom: `2px ${statusColor[props.status]} solid`,
          color: '#fff',
          padding: 8
        }}
      >
        <Group>
          <StatusIcon status={props.status} white />{' '}
          <Text c={'#fff'}>
            {statusText[0].toUpperCase() + statusText.substring(1)}
          </Text>
        </Group>
      </Card.Section>
      <Card.Section
        style={{
          backgroundColor:
            colorScheme === 'dark' ? theme.colors.gray[8] : theme.colors.gray[1],
          padding: 8
        }}
      >
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Td></Table.Td>
              <Table.Td></Table.Td>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            <Table.Tr>
              <Table.Td style={{ textAlign: 'right' }}>
                <Text size="xs" c={statusColor[props.status]}>
                  column:
                </Text>
              </Table.Td>
              <Table.Td>
                <Text size="xs" pl={4}>
                  {!props.type ? props.column : `${props.column} ${capitalize(props.type!)}`}
                </Text>
              </Table.Td>
            </Table.Tr>
            {formattedDate && (
              <Table.Tr>
                <Table.Td style={{ textAlign: 'right' }}>
                  <Text size="xs" c={statusColor[props.status]}>
                    last updated:
                  </Text>
                </Table.Td>
                <Table.Td>
                  <Text size="xs" pl={4}>
                    {formattedDate}
                  </Text>
                </Table.Td>
              </Table.Tr>
            )}
            {props.code && (
              <Table.Tr>
                <Table.Td style={{ textAlign: 'right' }}>
                  <Text size="xs" c={statusColor[props.status]}>
                    status code:
                  </Text>
                </Table.Td>
                <Table.Td>
                  <div ref={statusCodeRef}>
                    <Text size="xs" pl={4}>
                      {props.code}
                    </Text>
                  </div>
                </Table.Td>
              </Table.Tr>
            )}
            {detail && (
              <Table.Tr>
                <Table.Td style={{ textAlign: 'right' }}>
                  <Text size="xs" c={statusColor[props.status]}>
                    status detail:
                  </Text>
                </Table.Td>
                <Table.Td>
                  <Text size="xs" pl={4}>
                    <div
                      style={{
                        overflow:'hidden',
                        maxWidth: statusCodeWidth,
                        whiteSpace: 'wrap',
                        maxHeight: '150px'
                      }}
                    >
                      {detail}
                    </div>
                  </Text>
                </Table.Td>
              </Table.Tr>
            )}
            {props.data && (
              <Table.Tr>
                <Table.Td style={{ textAlign: 'right' }}>
                  <Text size="xs" c={statusColor[props.status]}>
                    status data:
                  </Text>
                </Table.Td>
                <Table.Td>
                  <Text size="xs" pl={4}>
                    <div
                      style={{
                        overflow:'hidden',
                        maxWidth: statusCodeWidth,
                        whiteSpace: 'wrap',
                        maxHeight: '150px'
                      }}
                    >
                      {props.data}
                    </div>
                  </Text>
                </Table.Td>
              </Table.Tr>
            )}
          </Table.Tbody>
        </Table>
      </Card.Section>
    </Card>
  );
};

import { create } from 'zustand';
import { iTimelineState, initialTimelineStateValues } from './models';
import { useUserPrefsStore } from '../../../app/store';
import { iDetailTaskFilter } from '../../../app/models';
import { useFootprintStore } from '../../../containers/Footprints/store';

export const useTimelineStore = create<iTimelineState>()((set, get) => ({
  ...initialTimelineStateValues,
  SetTaskFilters: async (filters) => {
    const SetDetailTaskFilters = useUserPrefsStore.getState().SetDetailTaskFilters
    const isStatusCode = get().isStatusCode
    const activeFootprint = useFootprintStore.getState().active.id;
    const newUserFilter: iDetailTaskFilter = {
      footprint: activeFootprint,
      statusCode: isStatusCode,
      filter: filters
    } 
    SetDetailTaskFilters(newUserFilter)
    set({ taskFilters: filters });
  },
  SetIsStatusCode: (explicit: boolean) => {
    set({ isStatusCode: explicit });
  }
}));

import { Divider, Group, Modal, Text, TextInput } from '@mantine/core';
import { useRef, useState } from 'react';
import { Button } from '../../components';
import { useSavedViewsStore } from './store';
import { useGridStore, useViewStore } from '../Views/store';
import { useSearchStore } from '../Search/store';
import { userPreferencesService } from '../../services/preferences/userPreferencesService';
import { createViewsList } from '../../utils/utilities';
import { iSelectItem } from '../../components/ListSortable/models';
import { v4 as uuidv4 } from 'uuid';
import { initialNotification } from '../../components/Notification/models';

export const SavedViewsModal = () => {
  const ref = useRef<HTMLInputElement>(null);
  const isOpen = useSavedViewsStore((state) => state.isOpen);
  const SetIsOpen = useSavedViewsStore((state) => state.SetIsOpen);
  const [hasError, setHasError] = useState(false);
  const { search } = useSearchStore();
  const active = useSavedViewsStore((state) => state.active);
  const SetGridLayoutModified = useGridStore((state) => state.SetGridLayoutModified);

  const closeGridConfirmation = () => {
    setHasError(false);
    SetIsOpen(false);
  };

  const isSaveable = () => {
    const { type, label } = useSavedViewsStore((state) => {
      return state.active;
    });
    if(encodeURIComponent(label) === window.location.href.split('/')[4]) {
      return true;
    }
    if (type) {
      return (
        type.split(':')[0] === 'personal' && window.location.href.split('/').length < 5
      );
    }
  };

  const saveView = async (event: any, label?: string) => {
    const active = useSavedViewsStore.getState().active;
    const chartActive = useSavedViewsStore.getState().active.chartActive;
    const gridCurrentColumnApi = useViewStore.getState().grid?.current?.columnApi;
    const columnState = gridCurrentColumnApi.getColumnState();
    const gridCurrentAPI = useViewStore.getState().grid?.current?.api;
    const filters = gridCurrentAPI.getFilterModel();
    const list = useSavedViewsStore.getState().list;
    const personal = useSavedViewsStore.getState().personal;
    const SaveActiveView = useSavedViewsStore.getState().SaveActiveView;
    const SetList = useSavedViewsStore.getState().SetList;
    const copyOfActiveView = Object.assign({}, active);

    copyOfActiveView.filters = filters;
    copyOfActiveView.columnState = columnState;
    copyOfActiveView.search = search;
    if (label) {
      const user = await userPreferencesService.getUser();
      copyOfActiveView.label = label;
      copyOfActiveView.type = `personal:${user.email}`;
      copyOfActiveView.id = uuidv4();
      copyOfActiveView.position = personal.length;
      copyOfActiveView.notification = initialNotification;
    }
    let newList: iSelectItem[] = createViewsList([copyOfActiveView], 'Personal');
    list.forEach((i) => newList.push(i));
    copyOfActiveView.chartActive = chartActive;
    SaveActiveView(copyOfActiveView);
    SetGridLayoutModified(false);
    SetIsOpen(false);
    SetList(newList);
  };

  const isValidName = (event: any) => {
    const list = useSavedViewsStore.getState().list;
    const global = useSavedViewsStore.getState().global;
    const nameExists = [...list, ...global]
      .map((v) => v.label.toLowerCase())
      .includes(event.target.value.toLowerCase());
    if (event) {
      if (event.target.value) {
        setHasError(nameExists);
      }
    }
  };

  return (
    <Modal
      opened={isOpen}
      onClose={() => closeGridConfirmation()}
      title="Saved Views"
      centered
      padding="xl"
    >
        <>
        {!isSaveable() && (<Text>
          You are currently using a preset or shared view!</Text>)}
          <Text>
          <Divider my="md" labelPosition="center" label="Save View"></Divider>
            Would you like to save your current filters, columns, and search options to{' '}
            {active.label}?
          </Text>
          <Group justify="flex-end" style={{ marginTop: 10 }}>
            <Button
              testid="modalConfirmationAccept"
              base="accept"
              label="Save"
              onClick={(e) => saveView(e)}
            />
            <Button
              testid="modalConfirmationCancel"
              base="cancel"
              onClick={() => SetIsOpen(false)}
            />
          </Group>
          <Divider my="md" labelPosition="center" label="Save As New View"></Divider>
          <Text>
            Or you can save them as a new view:{' '}
            <Group justify="flex-end" style={{ marginTop: 10 }}>
              <Button
                noIcon
                testid="save-view-button"
                disabled={hasError}
                onClick={(e) => saveView(e, ref!.current!.value)}
              >
                Save
              </Button>
              <TextInput
                error={hasError ? 'This name already exists.' : ''}
                onChange={(e) => isValidName(e)}
                ref={ref}
                defaultValue={`${active.label}_copy`}
              />
            </Group>{' '}
          </Text>
        </>
      
      {/* {!isSaveable() && (
        <>
          <Text>
            You are currently using a preset or shared view. Would you like to save your
            current filters, and columns to a new personal view?
          </Text>
          <Divider my="md" labelPosition="center" label="Save As New"></Divider>
          <Group justify="flex-end" style={{ marginTop: 10 }}>
            <Button
              noIcon
              testid="save-as-new-view-button"
              disabled={hasError}
              onClick={(e) => saveView(e, ref!.current!.value)}
            >
              Save
            </Button>
            <TextInput
              error={hasError ? 'This name already exists.' : ''}
              onChange={(e) => isValidName(e)}
              ref={ref}
              defaultValue={`${active.label}_copy`}
            />
            <Button
              testid="modalConfirmationCancel"
              base="cancel"
              onClick={() => SetIsOpen(false)}
            />
          </Group>{' '}
        </>
      )} */}
    </Modal>
  );
};

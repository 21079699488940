import {
  AnnotationslabelsGetter,
  AudioComponentsGetter,
  dynamicRangeIndicator,
  MarkersGetter,
  MediaDurationGetter,
  TimedTextComponentsGetter
} from './globalValueGetters';

export const fulfilledAudioComponentsGetter = (params: any) =>
  AudioComponentsGetter(
    params.data ? params?.data?.tasks : params?.tasks,
    'fulfilledAudioComponents',
    'mezzcreation',
    'fulfillment-workflow'
  );

export const fulfilledTimedTextComponentsGetter = (params: any) =>
  TimedTextComponentsGetter(
    params.data ? params?.data?.tasks : params?.tasks,
    'fulfilledTimedTextComponents',
    'mezzcreation',
    'fulfillment-workflow'
  );

export const fulfilledMarkersGetter = (params: any) =>
  MarkersGetter(
    params.data ? params?.data?.tasks : params?.tasks,
    'fulfilledMarkers',
    'mezzcreation',
    'fulfillment-workflow'
  );

export const fulfilledAnnotationslabelsGetter = (params: any) =>
  AnnotationslabelsGetter(
    params.data ? params?.data?.tasks : params?.tasks,
    'fulfilledAnnotationslabels',
    'mezzcreation',
    'fulfillment-workflow'
  );

export const fulfilledMediaDurationGetter = (params: any) =>
  MediaDurationGetter(
    params.data ? params?.data?.tasks : params?.tasks,
    'fulfilledMediaDuration',
    'mezzcreation',
    'fulfillment-workflow'
  );

export const dynamicRangeIndicatorGetter = (params: any) =>
  dynamicRangeIndicator(
    params.data ? params?.data?.tasks : params?.tasks,
    'fulfilledVideoComponents',
    'mezzcreation',
    'fulfillment-workflow'
  );

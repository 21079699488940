// import { Card, Group, Text, useMantineTheme } from '@mantine/core';
// import { AgGridReact } from 'ag-grid-react';
import { useComputedColorScheme } from '@mantine/core';
import { ColumnState, FirstDataRenderedEvent, GridOptions } from 'ag-grid-community';
import { useRef } from 'react';
import { AgGridContainer } from '../../components';
import { AgGridReact } from 'ag-grid-react';
import { useDetailsStore } from './store';
import { useDataPanelAccordionStore } from '../../components/DataPanelAccordion/store';

interface iDataPanelGridProps {
  // data is an array of the column defs objects which change constantly
  data: any[];
  gridOptions: GridOptions;
  columnState: ColumnState[];
  pinned: string;
}

const DetailCellRendererGridOptions: GridOptions = {
  headerHeight: 50,
  rowHeight: 30,
  rowSelection: 'single',
  suppressCellFocus: true,
  defaultColDef: {
    filter: true,
    sortable: true,
    resizable: true,
    hide: true
  }
};

export const ComponentDetailsRendererGrid = ({
  data,
  gridOptions,
  columnState
}: iDataPanelGridProps) => {
  const gridRef = useRef<any>();
  const details = useDetailsStore();
  const SetDetails = useDetailsStore((state) => state.SetDetails);
  const setPreModalState = useDetailsStore((state) => state.SetPreModalState);
  const SetDefaultItem = useDataPanelAccordionStore((state) => state.SetDefaultItem);
  const setDetailsVisible = useDetailsStore((state) => state.SetDetailsVisible);
  const colorScheme = useComputedColorScheme('light');
  const gridCSS = colorScheme === 'dark' ? 'ag-theme-alpine-dark' : 'ag-theme-alpine';
  let newGridOptions = Object.assign({}, DetailCellRendererGridOptions);
  newGridOptions.columnDefs = gridOptions.columnDefs;
  delete newGridOptions.rowClassRules;
  newGridOptions.components = gridOptions.components;
  const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    if (columnState) {
      newGridOptions.columnApi?.applyColumnState({
        state: columnState,
        applyOrder: true
      });
    }
    newGridOptions.api?.refreshCells();
  };
  newGridOptions.onFirstDataRendered = onFirstDataRendered;

  const onCellDoubleClicked = (params: any) => {
    let newDetails = Object.assign({}, details);
    if (Object.keys(params.data).includes('offering')) {
      newDetails = Object.assign({}, details, {
        offering: params.data,
        headerName: params.colDef.headerName
      });
      SetDetails(newDetails);
    }
    const previousFilterState = gridOptions.api?.getFilterModel();
    const previousColumnState = gridOptions.columnApi?.getColumnState();
    if (previousFilterState && previousColumnState) {
      setPreModalState({
        filters: previousFilterState,
        columns: previousColumnState
      });
    }
    SetDefaultItem('Video');
    setDetailsVisible(true);
  };
  newGridOptions.onCellDoubleClicked = onCellDoubleClicked;

  const checkGridLayoutModified = (e: any) => {
    if (e.type === 'columnResized' && e.finished && e.column) {
      const previousColumnState = gridRef?.current?.columnApi?.getColumnState();
      const updatedColumnState = previousColumnState.map((c: any) => {
        if (c.colId === e.column.colId) {
          c.width = e.column.actualWidth;
        }
        return c;
      });
      gridRef?.current?.columnApi?.applyColumnState(updatedColumnState);
      const params = {
        force: true
      };
      gridRef?.current?.api?.refreshCells(params);
    }
  };
  newGridOptions.onColumnResized = checkGridLayoutModified;
  return (
    <AgGridContainer>
      <div
        className={gridCSS}
        style={{
          height: 198,
          width: '100%',
          padding: 3
        }}
      >
        <AgGridReact ref={gridRef} gridOptions={newGridOptions} rowData={data} />
      </div>
    </AgGridContainer>
  );

  return <></>;
};

import { Box, Tabs } from '@mantine/core';
import { StatusCodeDictionary } from '../../components/StatusCodeDictionary';
// import { DropDownMenus } from './_dropDownMenus';
import { BigPandaSettings } from '../../components/BigPandaConfig';
import { useAdminModuleStore } from './store';
import { DropDownMenus } from './_dropDownMenus';
import { NotificationsTab } from './_notifications';

export function Admin() {
  const { ActiveTab, SetActiveTab }= useAdminModuleStore();

  return (
    <Tabs value={ActiveTab} onChange={SetActiveTab} style={{ marginTop: 5 }}>
      <Tabs.List>
        <Tabs.Tab value="admin">Admin</Tabs.Tab>
        <Tabs.Tab value="statusCodeDictionary">Status Code Dictionary</Tabs.Tab>
        <Tabs.Tab value="dropdowns">Drop Downs</Tabs.Tab>
        <Tabs.Tab value="userdefaults">User Defaults</Tabs.Tab>
        <Tabs.Tab value="bigPandaSettings">BigPanda</Tabs.Tab>
        <Tabs.Tab value="notifications">Notifications</Tabs.Tab>
      </Tabs.List>
      <Tabs.Panel value="admin">
        <Box style={{ margin: 24 }}>This is the Admin Panel</Box>
      </Tabs.Panel>
      <Tabs.Panel value="statusCodeDictionary" pt={12}>
        <StatusCodeDictionary />
      </Tabs.Panel>
       <Tabs.Panel value="dropdowns">
        <DropDownMenus />
      </Tabs.Panel>
      <Tabs.Panel value="userdefaults">
        <Box style={{ margin: 24 }}>This is the User Defaults Panel</Box>
      </Tabs.Panel>

      <Tabs.Panel value="bigPandaSettings" pt={12}>
        <BigPandaSettings />
      </Tabs.Panel>
      <Tabs.Panel value="notifications" pt={12}>
        <NotificationsTab />
      </Tabs.Panel>

    </Tabs>
  );
}

export default Admin;

import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { persist } from 'zustand/middleware';
import { iNavBar, initialNavBarValues } from './models';

export const useNavBarStore = create<iNavBar>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialNavBarValues,
        ToggleCloseOnSelect: () => {
          set({ CloseOnSelect: !get().CloseOnSelect });
        },
        ToggleOpen: () => {
          set({ Open: !get().Open });
        },
        SetOpen: (newState: boolean) => {
          set({ Open: newState });
        }
      }),
      {
        name: 'navbar-state'
      }
    )
  )
);

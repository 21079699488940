import { Box, Text, useComputedColorScheme } from '@mantine/core';
import { AgGridReact } from 'ag-grid-react';
import { AgGridContainer } from '../..';
import { useEffect, useMemo, useRef, useState } from 'react';
import { DataPanelGridOptions } from '../../MilestoneDataPanels/MilestonesGridOptions';
import { CustomInfoPanel, CustomInfoPanel as DetailCellRenderer } from './_customInfoPanel';
import { useFootprintStore } from '../../../containers/Footprints/store';
import { fetchServerTaskHistory } from '../../../api/fetchServerTaskHistory';
import { FullDetailHistoryColumnDefs, FullDetailHistoryColumnDefsVideo } from './historyColumnDefs';
import { useDetailsStore } from '../../../containers/Views/store';

// interface iDataPanelGridProcessProps {
//   data: any;
//   width: string | number | undefined;
//   activity: string;
//   coldefs: any;
//   process?: TabsValue;
//   footprint?: string;
// }

export const DataPanelGridProcess = (props: any) => {
  const [sortedData, setSortedData] = useState<any>([]);
  // const [loading, setLoading] = useState<boolean>(true);
  const detailCellRenderer = useMemo(() => {
    return DetailCellRenderer;
  }, []);
  const activeFootprint = useFootprintStore.getState().active.id;
  const details = useDetailsStore((state) => state)


  const gridRef = useRef<any>();
  const colorScheme = useComputedColorScheme('light');
  const gridCSS =
    colorScheme === 'dark' ? 'ag-theme-alpine-dark' : 'ag-theme-alpine';
  const gridOptions = { ...DataPanelGridOptions };
  gridOptions.columnDefs = FullDetailHistoryColumnDefs;
  if (props.process === 'Prep' || props.process === 'Fulfill'){
    if (props.activity === 'Video' && props.data.type !== 'csdelivery') {
      gridOptions.columnDefs = FullDetailHistoryColumnDefsVideo
    }
  }
  gridOptions.rowData = sortedData;
  gridOptions.detailCellRenderer = detailCellRenderer;
  gridOptions.detailRowHeight = 200;
  gridOptions.detailCellRendererParams = {
    deliverable: false
  };

  const fetchTaskHistoryRowData = async () => {
    let footprintId = '';
    let footprintType = '';
    let data: any = {}
    // Add additional footprints as needed
    let fp = props.footprint ? props.footprint : activeFootprint;
    switch (fp) {
      case 'footprint-offering':
        footprintId = details?.offering?.offering?.id;
        footprintType = 'ondemand';
        data = details?.offering
        break;
      case 'footprint-linear':
        footprintId = details?.schedule?.id;
        footprintType = 'linear';
        data = details?.schedule
        break;
      case 'footprint-acquire':
        footprintId = details?.acquire?.id;
        footprintType = 'acquisition';
        data = details?.acquire
        break;
      case 'footprint-asset':
        footprintId = details?.asset?.id;
        footprintType = 'offeringsAsset';
        data = details?.asset
        break;
      case 'footprint-crossplatform':
        footprintId = details?.crossplatform?.offering.id;
        footprintType = 'affiliateofferings';
        data = details?.crossplatform
        break;
      default:
        footprintId = details?.offering?.offering?.id;
        footprintType = 'ondemand';
        data = details?.offering
        break;
    }
    let result = await fetchServerTaskHistory(
      data,
      footprintId,
      footprintType,
      props.child || '',
      props.parent || '',
      props.statusObject,
      props.data.type,
    );
    if (!result) {
      setSortedData([]);
      gridRef?.current?.api?.setRowData([]);
      gridRef?.current?.api?.sizeColumnsToFit();
      // setLoading(false);
      return;
    }

    const removedNulls = result.rowData.filter((d: any) => d !== null);
    const fulfillmentFiltered = removedNulls.filter((i)=> {
      if (props.data.fulfillmentId){
        const historyFulfillment = i.details?.correlationMetadata?.find((j: { idNamespace: string; })=>j.idNamespace.includes('fulfillmentid'))
        if (historyFulfillment){
          const historyFulfillmentId = historyFulfillment.id
          if (historyFulfillmentId === props.data.fulfillmentId) {
            return i
          }
        }
      } else {
        return i
      }
    })
    const sortedData =
      fulfillmentFiltered.length > 0
        ? fulfillmentFiltered.sort(function (a: any, b: any) {
            return b.audit.generatedAt.localeCompare(a.audit.generatedAt);
          })
        : [];
    setSortedData(sortedData);
    gridRef?.current?.api?.setRowData(sortedData);
    // gridRef?.current?.api?.SizeColumnsToFit();
    // setLoading(false);
  };

  useEffect(() => {
    fetchTaskHistoryRowData();
  }, []);

  return ( 
    <Box
      style={{
        paddingBottom: 10,
        margin: 'auto',
        width: '95%'
      }}
    >
      <CustomInfoPanel deliverable={true} {...props}/>
      <Text fw={'bolder'}>Full Transaction History: </Text>
      <AgGridContainer>
        <div
          className={gridCSS}
          style={{
            height: 300,
            minWidth: 1000,
            paddingTop: 10
            // margin: -16,
            // marginTop: -2
          }}
        >
          <AgGridReact ref={gridRef} gridOptions={gridOptions} />
        </div>
      </AgGridContainer>
    </Box>
  )
};

import { Group, useComputedColorScheme, useMantineTheme } from '@mantine/core';
import { Button } from '../../components/Button';
import { Filter } from 'tabler-icons-react';
import { iStatusButtons } from './models';
import { useViewStore } from '../Views/store';
import { useStatusFilterStore } from './store';
// import { useRecordCountsStore } from '../Views/stores/recordCounts/store';
import { useSearchStore } from '../Search/store';

export const TotalRecords = () => {
  const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');
  const recordCount = useSearchStore((state) => state.recordCount);
  const currentRowCount = useSearchStore((state) => state.currentRowCount);
  const SetStatusButtonClicked = useStatusFilterStore(
    (state) => state.SetStatusButtonClicked
  );
  const gridCurrentAPI = useViewStore((state) => state.grid?.current?.api);

  const onClick = (event: any) => {
    gridCurrentAPI.setFilterModel({});
    let newButtonClicked: iStatusButtons = {
      error: false,
      failure: false,
      processing: false,
      pending: false,
      completed: false
    };
    SetStatusButtonClicked(newButtonClicked);
  };

  let result =
    currentRowCount < recordCount ? (
      <Group>
        <Button
          noIcon
          testid="clear-all-grid-filters"
          color={theme.colors.blue[6]}
          size="xs"
          variant="subtle"
          leftSection={<Filter />}
          onClick={onClick}
          tooltip={{
            label: 'Clear All Grid Filters',
            position: 'top',
            color: colorScheme === 'dark' ? 'gray' : 'black'
          }}
        >
          Rows:&nbsp;{' '}
          <b>
            {currentRowCount}/{recordCount}
          </b>
        </Button>
      </Group>
    ) : (
      <Button noIcon testid="grid-filters" color="gray" size="xs" variant="subtle">
        Rows:&nbsp; <b>{recordCount}</b>
      </Button>
    );
  return <>{result}</>;
};

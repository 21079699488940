import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { iMessagePopovers, initialMessagePopOverValues } from './models';

export const useMessagePopOverStore = create<iMessagePopovers>()(
  devtools((set, get) => ({
    ...initialMessagePopOverValues, 
    SetIsOuterOpen: (newState: boolean) => {
      set({ IsOuterOpen: newState })
    },
    SetIsInnerOpen: (newState: boolean) => {
      set({ IsInnerOpen: newState })
    },
    SetIDOuter: (newID: string) => {
      set({ PopoverIDOuter: newID})
    },
    SetIDInner: (newID: string) => {
      set({ PopoverIDInner: newID})
    },
    ToggleIsOuterOpen: () => {
      set({ IsOuterOpen: !get().IsOuterOpen })
    },
    ToggleIsInnerOpen: () => {
      set({ IsInnerOpen: !get().IsInnerOpen })
    },
    ToggleIsInactiveOpen: () => {
      set({ IsInactiveOpen: !get().IsInactiveOpen })
    }
  }))
);

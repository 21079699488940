import { Option as OptionMarket } from './../../components/CascaderMarket/_markets';
import { useConfigStore } from '../../app/store';
import useAxios from '../../services/axios/axios';
import { iSearch } from '../../containers/Search/models';
import { useAppConfigManager } from '../../components/AppConfigs';
import { useSearchStore } from '../../containers/Search/store';
import { getStatusCodeString } from '../../utils/utilities';

export type Result = {
  rowData: any[];
  rowCount: number;
};

export const fetchOfferingRowData = async (
  startDate: Date | null,
  endDate: Date | null,
  searchState: iSearch,
  limit: number,
  page: number
) => {
  const { post } = useAxios();
  const baseURL = useConfigStore.getState().config.baseApiGatewayUrl + '/graphql/search';

  const query = await constructQuery(startDate, endDate, searchState, limit, page);
  const schema = {
    query: query
  };
  let result: Result = { rowData: [], rowCount: 0 };
  try {
    const eventData = await post({
      url: baseURL,
      data: JSON.stringify(schema)
    });
    if (!eventData) {
      result = { rowData: [], rowCount: 0 };
    } else {
      let totalCount = eventData?.data?.offering?.count;
      result = { rowData: eventData.data.offering.records, rowCount: totalCount };
    }
  } catch (err) {
    console.error('Error fetching offering row data: ', err);
  } finally {
    return result;
  }
};

const constructQuery = async (
  startDate: Date | null,
  endDate: Date | null,
  searchState: iSearch,
  limit: number,
  page: number
) => {
  const queryFilters = constructFilters(startDate, endDate, searchState, limit, page);

  let queryReturn = await useSearchStore
    .getState()
    .queries.find((q) => q.id.includes('footprint-offering'));
  if (!queryReturn) {
    const LoadAPI = useSearchStore.getState().LoadAPI;
    await LoadAPI();
    queryReturn = await useSearchStore
      .getState()
      .queries.find((q) => q.id.includes('footprint-offering'));
  }

  const query = `query {
            offering(
              ${queryFilters}
            ) {
              ${queryReturn?.query}
              count
            }
          }`;
  return query;
};

const constructFilters = (
  startDate: Date | null,
  endDate: Date | null,
  filters: iSearch,
  limit: number,
  page: number
) => {
  const milestoneStatuses =
    filters.MilestoneStatus?.length > 0
      ? `[${filters.MilestoneStatus?.map((status: any) => `"${status}"`)}]`
      : '[]';
  const milestones =
    filters.Milestone?.length > 0
      ? `[${filters.Milestone?.map((status: any) => `"${status}"`)}]`
      : '[]';

  const milestoneString = `milestones:{
      name: ${milestones},
      status: ${milestoneStatuses},
    }`;

  let partialSearchInput: string = '';
  if (filters.SearchInput) {
    let searchInputs;
    if (
      filters.SearchInputField === 'keyword' ||
      filters.SearchInputField === 'playlistSearch'
    ) {
      let copy = filters.SearchInput;
      let newInput = copy.replaceAll('"', '\\"');
      searchInputs = newInput;
    } else {
      searchInputs = filters.SearchInput.split(' ');
      if (searchInputs.length > 1) {
        searchInputs = searchInputs.join('","');
      }
    }
    partialSearchInput = searchInputs.toString();
  }

  let csId = '';
  if (filters.SearchInputField && filters.SearchInputField === 'csId') {
    let input = '';
    let searchInputs = filters.SearchInput.split(' ');
    if (searchInputs.length > 1) {
      input = searchInputs.join('","');
    } else {
      input = searchInputs[0];
    }
    csId = `
    csId: {
      id: ["${input}"]
    }
    `;
  }

  let isDeleted = '';
  if (filters.DeletedAssets === 'notDeleted') {
    isDeleted = 'isDeleted: false';
  } else if (filters.DeletedAssets === 'deleted') {
    isDeleted = 'isDeleted: true';
  }

  let fulfilledMediaMarkerCount = '';
  if (filters.AdMarkers === 'true') {
    fulfilledMediaMarkerCount = `
    fulfilledMediaMarkerCount: {
      value: 1
      operation: gte
    }
    `;
  } else if (filters.AdMarkers === 'false') {
    fulfilledMediaMarkerCount = `
    fulfilledMediaMarkerCount: {
      value: 1
      operation: lt
    }
    `;
  }
  let contentRequests='';
  if (filters.MidRollAdsAllowed === 'true') {
    contentRequests = `
    contentRequests: {
      indicators: {
        midRollAdsAllowedIndicator: true
      }
    }
    `;
  } else if (filters.MidRollAdsAllowed === 'false') {
    contentRequests = `
    contentRequests: {
      indicators: {
        midRollAdsAllowedIndicator: false
      }
    }
    `;
  }

  let idPagination;
  // if (isPowerUser){
  //   idPagination = lastId ? `
  //   paginationType:id
  //   _id:"${lastId}"
  //   ` :
  //   'paginationType:id'
  // } else {
  //   idPagination = 'page: 1'
  // }
  idPagination = `page: ${page}`;

  const offeringObject = constructOfferingObject(startDate, endDate, filters);
  let partialObject = '';
  if (
    partialSearchInput &&
    (filters.SearchInputField === 'keyword' ||
      filters.SearchInputField === 'playlistSearch')
  ) {
    partialObject = constructPartialOfferingObject(partialSearchInput);
  }

  let auditObject = `audit:{}`;
  if (filters.DateField === 'lastUpdated' && filters.dateEnabled) {
    auditObject = `audit:{
            updatedAtFrom:"${startDate!.toISOString()}",
            updatedAtTo:"${endDate!.toISOString()}"
          }`;
  }

  let tasks='';
  if(contentRequests!=''||fulfilledMediaMarkerCount!='')
    tasks=`tasksv2 : {
                csdelivery: { details: { ${contentRequests} } }
                mezzcreation: { details: { ${fulfilledMediaMarkerCount} } } 
          }`;
  const taskValueMapper = useAppConfigManager.getState().taskValueMapper;
  const statusCodeString = getStatusCodeString(filters.StatusCodes, taskValueMapper);

  let tier1Tag = ''
  if (filters.Tier1){
    tier1Tag = 
    `tags:[
      "tier1"
    ]`
  }

  const queryFilters = `filters: {
        ${auditObject}
        ${milestoneString}
        ${statusCodeString}
        ${offeringObject}
        ${partialObject}
        ${csId}
        ${isDeleted}
        ${tasks}
        limit: ${limit}
        ${tier1Tag}
        ${idPagination}
    }`;
  return queryFilters;
};

export const constructOfferingObject = (
  startDate: Date | null,
  endDate: Date | null,
  filters: iSearch
) => {
  let offeringObject: any = {};
  const offeringArrayMapping = {
    entityType: 'EntityType',
    transcodingSystem: 'ContentSource',
    type: 'OfferingType'
  };

  Object.keys(offeringArrayMapping).forEach((k) => {
    const filterKey = offeringArrayMapping[k as keyof typeof offeringArrayMapping];
    if (filters[filterKey as keyof typeof filters]) {
      if (Array.isArray(filters[filterKey as keyof typeof filters])) {
        if ((filters[filterKey as keyof typeof filters] as string[]).length > 0) {
          const filterString = `${k}:[${(
            filters[filterKey as keyof typeof filters] as string[]
          ).map((i: any) => `"${i}"`)}]`;
          offeringObject[k as keyof typeof offeringObject] = filterString;
        }
      }
    }
  });

  if (filters.Market) {
    if (typeof filters.Market === 'string') {
      // TODO: Remove after migration of Partner value in searches
      offeringObject.marketId = `marketId:{id: "${filters.Market}"},`;
    } else {
      const { queryMarkets } = useAppConfigManager.getState();
      let value: any[] = [];
      filters.Market.forEach((market: string[]) => {
        const option = queryMarkets.find(
          (o: OptionMarket) => o.value === market[market.length - 1]
        );
        if (option) {
          option.valueList.split(',').forEach((id: string) => {
            value.push(id);
          });
        } else {
          console.error('Could not find market option within markets!: ', market);
        }
        offeringObject.marketId = `marketId:{id: [${value.map(
          (marketId: any) => `"${marketId}"`
        )}]},`;
      });
    }
  }

  if (
    filters.DateField === 'startDate' &&
    filters.startDateTime &&
    filters.endDateTime &&
    filters.dateEnabled
  ) {
    offeringObject.startDateFrom = `startDateFrom:"${startDate!.toISOString()}"`;
    offeringObject.startDateTo = `startDateTo:"${endDate!.toISOString()}"`;
  }
  if (
    filters.DateField === 'endDate' &&
    filters.startDateTime &&
    filters.endDateTime &&
    filters.dateEnabled
  ) {
    offeringObject.endDateFrom = `endDateFrom:"${startDate!.toISOString()}"`;
    offeringObject.endDateTo = `endDateTo:"${endDate!.toISOString()}"`;
  }
  if (filters.DateField === 'createdAt' && filters.dateEnabled) {
    offeringObject.createdAt = `audit:{
            createdFrom:"${startDate!.toISOString()}",
            createdTo:"${endDate!.toISOString()}",
          }`;
  }

  if (filters.ExpiredAssets === 'expired') {
    const expired = `
      endDate:{
        value: "${new Date().toISOString()}"
        operation:lte
      }
    `;
    offeringObject.endDate = expired;
  } else if (filters.ExpiredAssets === 'active') {
    const expired = `
      endDate:{
        value: "${new Date().toISOString()}"
        operation:gte
      }
    `;
    offeringObject.endDate = expired;
  }

  if (filters.SearchInputField && filters.SearchInputField !== 'keyword') {
    let input = '';
    let searchInputs = filters.SearchInput.split(' ');
    if (searchInputs.length > 1) {
      input = searchInputs.join('","');
    } else {
      input = searchInputs[0];
    }

    const excludedIds = ['playlistSearch', 'keyword', 'csId'];

    const ids = useAppConfigManager
      ?.getState()
      ?.GetSearchInputs('footprint-offering')
      ?.map((id) => id.value)
      .filter((i) => !excludedIds.includes(i));

    if (filters.SearchInputField === 'offeringId') {
      offeringObject.id = `id: ["${input}"]`;
    } else if (ids.includes(filters.SearchInputField)) {
      offeringObject[
        filters.SearchInputField
      ] = `${filters.SearchInputField}:{id: ["${input}"]}`;
    }
  }

  if (Object.keys(offeringObject).length > 0) {
    let offeringString = '';
    Object.keys(offeringObject).forEach((k: string) => {
      offeringString = offeringString + offeringObject[k];
    });
    return `offering: {${offeringString}} ${
      filters.SearchInputField === 'playlistSearch' ? ' playlistSearch:true' : ''
    }`;
  }
  return '';
};

export const constructPartialOfferingObject = (searchInput: string) => {
  const partialTitles = ['titles', 'seriesTitles', 'seasonTitles'];
  const excludedIds = ['keyword', 'playlistSearch', 'offeringId', 'csId'];

  const partialIds = useAppConfigManager
    ?.getState()
    ?.GetSearchInputs('footprint-offering')
    ?.map((id) => id.value)
    .filter((i) => !excludedIds.includes(i));
  let partialObject: any = {
    id: `id:["${searchInput}"]`
  };

  partialTitles.map((t: string) => {
    const partialTitle = `
      ${t}:{
        full:["${searchInput}"]
        type:["main-title", "internal-wm-name", "distribution-title"],
        language:"en-US"
      }`;
    partialObject[t as keyof typeof partialObject] = partialTitle;
  });

  if (searchInput.length > 4 || !searchInput.split('').includes(' ')) {
    partialIds.map((i: string) => {
      partialObject[i as keyof typeof partialObject] = `\n${i}:{id: ["${searchInput}"]}`;
    });
  }

  // csId moved to root level
  let csId = '';
  if (searchInput.length > 4 || !searchInput.split('').includes(' ')) {
    csId = `
    csId: {
      id: ["${searchInput}"]
    }
    `;
  }

  if (Object.keys(partialObject).length > 0) {
    let offeringString = '';
    Object.keys(partialObject).forEach((k: string) => {
      offeringString = offeringString + partialObject[k];
    });
    return `
      partialSearch:{
        offering:{
          ${offeringString}
        }
        ${csId}
      }
    `;
  }
  return '';
};

import { Box, Group, Space, Text } from '@mantine/core';
import { Aside } from '../Aside';
import { Button } from '../Button';
import { Switch } from '../Switch';
import { useNavBarStore } from '../Navbar/store';
import { useUserPrefsStore } from '../../app/store';
import { useAsideSettingsStore } from '../Aside/store';

interface IAsideSettingsProps {
  testid: string;
}

export const AsideSettings = (props: IAsideSettingsProps) => {
  const showTooltips = useUserPrefsStore((state) => state.enableTooltips);
  const toggleTooltips = useUserPrefsStore((state) => state.SetEnableTooltips);
  const SetShowAside = useAsideSettingsStore((state) => state.SetShow)
  const navCloseOnSelect = useNavBarStore((state) => state.CloseOnSelect)
  const ToggleCloseOnSelect = useNavBarStore((state) => state.ToggleCloseOnSelect)

  return (
    <Aside
      testid={props.testid}
      p="md"
      // width={{ sm: 200, lg: 300 }}
    >
      <Text>Settings</Text>
      <Space h="xl" />
      <Box>
        <Switch
          checked={navCloseOnSelect}
          label="Close Navbar when clicking a selection."
          testid="close-navbar"
          onChange={() => {
            ToggleCloseOnSelect();
          }}
        />
      </Box>
      <Space h="xl" />
      <Box>
        <Switch
          checked={showTooltips}
          label="Show Tooltips."
          testid="show-tooltips"
          onChange={(e) => {
            toggleTooltips();
          }}
        />
      </Box>
      <Space h="xl" />
      <Group justify="flex-end">
        <Button
          base="cancel"
          testid="AsideSettingsCancel"
          onClick={() => {
            SetShowAside('');
          }}
        >
          Close
        </Button>
      </Group>
    </Aside>
  );
};

export default AsideSettings;

import { useEffect, useRef, useCallback, useMemo, useState } from 'react';
import { useComputedColorScheme } from '@mantine/core';
import { AgGridContainer } from '../../../components';
import { AgGridReact } from 'ag-grid-react';
import {
  AgGridEvent,
  CellDoubleClickedEvent,
  ColDef,
  ColumnState,
  GridOptions,
  GroupCellRendererParams
} from 'ag-grid-community';
import { components } from '../cellRenderers';
import { ErrorBoundary } from 'react-error-boundary';
import { GridStatusChart } from '../StatusChart';
import { useDetailsStore } from '../stores/details/store';
import { useViewStore } from '../store';
import dayjs from 'dayjs';
import { useSavedViewsStore } from '../../SavedViews/store';
// import useStyles from '../styles';
import '../Views.module.css';
import { useHUDStore } from '../../../components/Hud/state';
import { useFootprintStore } from '../../Footprints/store';
import { DetailCellRenderer } from '../_detailCellRenderer';
import { useSearchStore } from '../../Search/store';
import { useStatusCountsStore } from '../../StatusCounts';
import { iSearch } from '../../Search/models';
import { Result } from '../../../api/offering/fetchOfferingRowData';
import { useLocation } from 'react-router-dom';
import { useDataPanelAccordionStore } from '../../../components/DataPanelAccordion/store';
import { iStatusButtons } from '../../StatusFilterBar/models';
import { useStatusFilterStore } from '../../StatusFilterBar/store';
import { useAppConfigManager } from '../../../components/AppConfigs';
import '../aggridViews.css';
import { ExpandableComponentsRenderer } from '../_componentDetailsRenderer';

const ErrorFallback = (props: any) => {
  console.log('ErrorFallback',props.error)
  return <></>;
};

const myErrorHandler = (error: Error, info: { componentStack: string }) => {
  // Do something with the error
  // E.g. log to an error logging client here
};

interface iGridProps {
  startDate: Date | null;
  endDate: Date | null;
  rowData: any;
  footprint: string;
  milestones: { value: string; label: string }[];
  apiName: string;
  DetailComponent: JSX.Element;
  fetchServerRowData: (
    startDate: Date | null,
    endDate: Date | null,
    searchState: iSearch,
    limit: number,
    page: number
  ) => Promise<Result>;
  setValueGetters: (columnDefs: ColDef<any>[]) => void;
  fetchStatusCount: (
    startDate: Date | null,
    endDate: Date | null,
    searchState: iSearch,
    milestoneName: string
  ) => Promise<any>;
  defaultDetailGridItem?: string;
}
/* The Grid component displays a view's dataset in an AgGrid */
export const Grid = (props: iGridProps) => {
  const { startDate, endDate } = props;
  const columnDefs = useFootprintStore((state) => state.active.columnDefs);
  const { columnState, chartActive, filters } = useSavedViewsStore(
    (state) => state.active
  );
  const { rowData, FetchGridRowData } = useSavedViewsStore((state) => state);
  const search = useSearchStore.getState().search;
  const chartSearch = useSearchStore.getState().chartSearch;
  const memoSearch = useMemo(() => useSearchStore.getState().search, [search]);
  const searchFilters = useSavedViewsStore((state) => state.active.filters);
  const isSearching = useSearchStore((state) => state.isSearching);
  const isLoaded = useAppConfigManager((state) => state.isLoaded);
  const setFieldStatusChartData = useStatusCountsStore.getState().SetFieldStatusChartData;
  const gridRef = useRef<AgGridReact>(null);
  // const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');
  const details = useDetailsStore();
  const statusField = useStatusCountsStore((state) => state.statusField);
  const hudFilterView = useHUDStore((state) => state.hudFilterView);
  const SetGrid = useViewStore((state) => state.SetGrid);
  const SetDetails = useDetailsStore((state) => state.SetDetails);
  const { detailsVisible, SetDetailsVisible } = useDetailsStore();
  const setPreModalState = useDetailsStore((state) => state.SetPreModalState);
  const { SetDefaultItem } = useDataPanelAccordionStore();
  // Open detail on load if 'detailOpen=true'
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [detailOpened, setDetailOpened] = useState<boolean>(false);

  const { startDateTime, endDateTime } = memoSearch;
  // const { classes, cx } = useStyles();

  const setStatusButtonClicked = useStatusFilterStore(
    (state) => state.SetStatusButtonClicked
  );
  useEffect(() => {
    gridRef?.current?.columnApi?.applyColumnState({
      state: columnState,
      applyOrder: true
    });
  }, [columnState]);

  useEffect(() => {
    props.setValueGetters(columnDefs);
    gridRef?.current?.api?.setColumnDefs(columnDefs);
  }, [columnDefs]);

  useEffect(() => {
    SetGrid(gridRef);
  }, []);

  useEffect(() => {
    let filterKey: string;
    for (filterKey in searchFilters) {
      const filterColumn = gridRef?.current?.api?.getFilterInstance(filterKey);
      if (searchFilters.status && searchFilters[filterKey]!.values) {
        searchFilters[filterKey].values = searchFilters[filterKey]!.values!.map(
          (d: any) => d.toLowerCase()
        );
        if (searchFilters[filterKey].values.includes('pending')) {
          searchFilters[filterKey].values = ['pending', 'queued'];
        } else if (searchFilters[filterKey].values.includes('completed')) {
          searchFilters[filterKey].values = ['completed', 'skipped'];
        }
        let newButtonClicked: iStatusButtons = {
          error: false,
          failure: false,
          processing: false,
          pending: false,
          completed: false
        };
        newButtonClicked[filters['status']!.values[0] as keyof typeof newButtonClicked] =
          true;
        setStatusButtonClicked(newButtonClicked);
      }
      filterColumn?.setModel(searchFilters[filterKey]);
    }
    gridRef?.current?.api?.onFilterChanged();
  }, [filters, rowData?.length]);

  const gridCSS = colorScheme === 'dark' ? 'ag-theme-alpine-dark' : 'ag-theme-alpine';

  const onCellDoubleClicked = (params: CellDoubleClickedEvent) => {
    const activeFootprint = useFootprintStore.getState().active.id;
    let newDetails = Object.assign({}, details);
    if (activeFootprint === 'footprint-offering') {
      newDetails = Object.assign({}, details, {
        offering: params.data,
        headerName: params.colDef.headerName
      });
    } else if (activeFootprint === 'footprint-linear') {
      newDetails = Object.assign({}, details, {
        schedule: params.data,
        headerName: params.colDef.headerName
      });
    } else if (activeFootprint === 'footprint-acquire') {
      newDetails = Object.assign({}, details, {
        acquire: params.data,
        headerName: params.colDef.headerName
      });
    } else if (activeFootprint === 'footprint-asset') {
      newDetails = Object.assign({}, details, {
        asset: params.data,
        headerName: params.colDef.headerName
      });
    } else if (activeFootprint === 'footprint-crossplatform') {
      newDetails = Object.assign({}, details, {
        crossplatform: params.data,
        headerName: params.colDef.headerName
      });
    }

    SetDetails(newDetails);
    const previousFilterState = gridRef?.current?.api?.getFilterModel();
    const previousColumnState = gridRef?.current?.columnApi?.getColumnState();
    setPreModalState({
      filters: previousFilterState,
      columns: previousColumnState || []
    });
    if (props.defaultDetailGridItem) {
      SetDefaultItem(props.defaultDetailGridItem);
    } else {
      SetDefaultItem('Video');
    }
    SetDetailsVisible(true);
  };

  const onGridReady = (e: AgGridEvent) => {
    e.columnApi.applyColumnState({
      state: columnState,
      applyOrder: true
    });
  };

  const checkGridLayoutModified = (e: any) => {
    if (e.type === 'columnResized' && e.finished && e.column) {
      const previousColumnState = gridRef?.current?.columnApi?.getColumnState() || [];
      const updatedColumnState: ColumnState[] = previousColumnState.map(
        (c: ColumnState) => {
          if (c.colId === e.column.colId) {
            c.width = e.column.actualWidth;
          }
          return c;
        }
      );
      const columnStateParams = {
        state: updatedColumnState
      };
      gridRef?.current?.columnApi?.applyColumnState(columnStateParams);
      const params = {
        force: true
      };
      gridRef?.current?.api?.refreshCells(params);
    }

    if (e.type === 'filterChanged') {
      let currentRowNumber = gridRef?.current?.api?.getDisplayedRowCount();
      if (currentRowNumber || currentRowNumber === 0) {
        useSearchStore.getState().SetCurrentRowCount(currentRowNumber);
      }
    }
  };

  const gridOptions: GridOptions = {
    columnDefs: columnDefs,
    components: components,
    detailRowHeight: 200,
    headerHeight: 50,
    masterDetail: true,
    onCellDoubleClicked: onCellDoubleClicked,
    onColumnResized: checkGridLayoutModified,
    onFilterChanged: checkGridLayoutModified,
    onGridReady,
    rowHeight: 30,
    rowSelection: 'single',
    suppressCellFocus: true,
    embedFullWidthRows: true,
    rowClassRules: {
      deleted: (params) => {
        if (params?.data) {
          return params?.data[props.apiName]?.isDeleted;
        }
      }
    },
    defaultColDef: {
      minWidth: 70,
      width: 140,
      filter: true,
      sortable: true,
      resizable: true,
      hide: true
    },
    sideBar: {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivotMode: true
          }
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel'
        }
      ]
    },
    detailCellRenderer: function (params: GroupCellRendererParams) {
      const activeFootprint = useFootprintStore.getState().active.id;
      switch (activeFootprint) {
        case 'footprint-linear':
          return ExpandableComponentsRenderer(params, gridOptions);
        default:
          return DetailCellRenderer(params, gridOptions);
      }
    }
  };

  useEffect(() => {
    if (chartSearch) {
      setFieldStatusChartData(props.fetchStatusCount, props.apiName);
    }
  }, [statusField, chartSearch]);

  useEffect(() => {
    let currentRowNumber = gridRef?.current?.api?.getDisplayedRowCount();
    if (currentRowNumber || currentRowNumber === 0) {
      useSearchStore.getState().SetCurrentRowCount(currentRowNumber);
    }

    // const url = window.location.href.split('/')
    // let urlFootprint
    // if (url.length >= 4){
    //   urlFootprint = mapUrlFootprint(url[4])
    // }
    // if (params.get('detailOpen') && !detailOpened && rowData.length > 0 && urlFootprint === props.footprint){
    if (params.get('detailOpen') && !detailOpened && rowData.length > 0) {
      const data = rowData[0];
      let newDetails = Object.assign({}, details);
      const activeFootprint = useFootprintStore.getState().active.id;
      if (activeFootprint === 'footprint-offering') {
        newDetails = Object.assign({}, details, {
          offering: data
        });
      } else if (activeFootprint === 'footprint-linear') {
        newDetails = Object.assign({}, details, {
          schedule: data
        });
      } else if (activeFootprint === 'footprint-acquire') {
        newDetails = Object.assign({}, details, {
          acquire: data
        });
      } else if (activeFootprint === 'footprint-asset') {
        newDetails = Object.assign({}, details, {
          asset: data
        });
      } else if (activeFootprint === 'footprint-crossplatform') {
        newDetails = Object.assign({}, details, {
          crossplatform: data
        });
      }

      SetDetails(newDetails);
      SetDefaultItem('Video');
      SetDetailsVisible(true);
      setDetailOpened(true);
    }
  }, [rowData]);

  useEffect(() => {
    if (!isLoaded) {
      return;
    }
    if (isSearching) {
      (async () => {
        let searchCriteria = memoSearch;

        if (hudFilterView.field) {
          searchCriteria = hudFilterView.search;
        }

        if (searchCriteria.timeRelative) {
          // TODO :handle widget dates
          searchCriteria = Object.assign({}, searchCriteria, {
            startDate,
            endDate,
            startDateTime,
            endDateTime
          });
        }

        if (params.get('detailOpen')) {
          searchCriteria.ExpiredAssets = '';
          // linear
          searchCriteria.LinearExpired = true;
          if (
            params.get('scheduleType') === 'final' ||
            params.get('scheduleType') === 'air'
          ) {
            searchCriteria.ScheduleType = 'air","final';
          } else if (params.get('scheduleType') === 'advanced') {
            searchCriteria.ScheduleType = 'advanced';
          }
        }

        await FetchGridRowData(
          props.startDate,
          props.endDate,
          searchCriteria,
          props.apiName,
          props.fetchServerRowData,
          props.fetchStatusCount
        );
      })();
    }
  }, [isSearching, isLoaded]);

  const getFilename = () => {
    const fp = useFootprintStore.getState().active.label;
    return 'DiSCO_' + fp + '_' + dayjs(new Date()).format('YYYY-MM-DDTHH-mm');
  };

  const getContextMenuItems = useCallback(() => {
    var result = [
      'cut',
      'copy',
      'copyWithHeaders',
      'copyWithGroupHeaders',
      'paste',
      'separator',
      {
        name: 'Export',
        icon: '<span class="ag-icon ag-icon-save"/>',
        subMenu: [
          {
            name: 'CSV Export',
            action: () => {
              gridRef.current?.api?.exportDataAsCsv({
                fileName: getFilename()
              });
            },
            icon: '<span class="ag-icon ag-icon-csv"/>'
          },
          {
            name: 'Excel Export',
            action: () => {
              gridRef.current?.api?.exportDataAsExcel({
                fileName: getFilename(),
                sheetName: 'DiscoReport'
              });
            },
            icon: '<span class="ag-icon ag-icon-excel"/>'
          }
        ]
      }
    ];
    return result;
  }, [startDateTime, endDateTime]);
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={myErrorHandler}>
      <AgGridContainer>
        {detailsVisible && props.DetailComponent}
        <div
          className={gridCSS}
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center'
          }}
        >
          {!isSearching && chartActive && (
            <div style={{ height: '100%', width: '20%', marginTop: -2, marginRight: 10 }}>
              <GridStatusChart
                footprint={props.footprint}
                milestones={props.milestones}
              />
            </div>
          )}
          <div style={{ height: '100%', width: '100%' }}>
            <AgGridReact
              className={colorScheme === 'light' ? 'viewLight' : 'viewDark'}
              rowData={props.rowData}
              ref={gridRef}
              gridOptions={gridOptions}
              getContextMenuItems={getContextMenuItems}
            />
          </div>
        </div>
      </AgGridContainer>
    </ErrorBoundary>
  );
};

export default Grid;

import {
  Box,
  Button as MButton,
  CopyButton,
  darken,
  Divider,
  Flex,
  Grid,
  Group,
  lighten,
  Modal,
  Select,
  Stack,
  Text,
  useComputedColorScheme,
  useMantineTheme,
  Center,
  Popover,
  InputLabel
} from '@mantine/core';
import {
  Bookmark,
  Search,
  Share,
  Eraser,
  Bell,
  BellOff,
  Users
} from 'tabler-icons-react';
import { Button, Checkbox, Collapse } from '../../components';
import { createSharedSearchURL } from '../../utils/utilities';
import { DateRangePicker } from '../../components/DateRangePicker';
import { FootprintSelect } from '../Footprints/FootprintSelect';
import { FormProvider, useForm } from './_form-context';
import { globalColors as colors, globalColors } from '../../theme/globalColors';
import { View } from '../Views/stores/grid/models';
import { initialSearchValues } from '../Search/models';
import { ResetViewFilters } from './ResetViewFilters';
import { SavedViewsButton } from '../SavedViews';
import { SavedViewsSelect } from '../SavedViews/SavedViewsSelect';
import { SearchBarConfigureButton } from '../SearchBarConfigure';
import { SearchBarInput } from './_searchBarInput';
import { SearchOptionsLinear } from './_searchOptionsLinear';
import { SearchOptionsOffering } from './_searchOptionsOffering';
import { SearchSynopsis } from '../SearchSynopsis/SearchSynopsis';
import { useAppConfigManager } from '../../components/AppConfigs/store';
import { useConfigStore, useUserPrefsStore } from '../../app/store';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router";
import { useFootprintStore } from '../Footprints/store';
import { useViewStore } from '../Views/store';
import { useHUDStore } from '../../components/Hud/state';
import { useSearchBarStore } from './store';
import { useSearchParams } from 'react-router-dom';
import { useSearchStore } from '../Search/store';
import { useTID } from '../../helpers/useTestId';
import Chevron from './_chevron';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { SearchOptionsAcquire } from './_searchOptionsAcquire';
import { SavedViewsModal } from '../SavedViews/SavedViewsModal';
import { useSavedViewsStore } from '../SavedViews/store';
import { SearchOptionsAsset } from './_searchOptionsAsset';
import { SearchOptionsCrossPlatform } from './_searchOptionsCrossPlatform';
import { Notifications } from '../../components/Notification/Notifications';
dayjs.extend(utc);

type iSearchBarProps = {
  views: any;
};

export const SearchBar = (props: iSearchBarProps) => {
  const hudFilterView = useHUDStore((state) => state.hudFilterView);
  const SetHUDFilterView = useHUDStore((state) => state.SetHUDFilterView);
  const searchBarOpen = useSearchBarStore((state) => state.searchBarOpen);
  const ToggleSearchBarOpen = useSearchBarStore((state) => state.ToggleSearchBarOpen);
  const {
    isSearching,
    ToggleIsSearching,
    UpdateSearchInput,
    search,
    startDate,
    endDate,
    startDateTime,
    endDateTime,
    ClearSearchInput
  } = useSearchStore();
  const { timeRelative } = search;
  const shareModalOpen = useSearchBarStore((state) => state.shareModalOpen);
  const ToggleShareModalOpen = useSearchBarStore((state) => state.ToggleShareModalOpen);
  const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');
  const SetActiveFootprint = useFootprintStore((state) => state.SetActiveFootprint);
  const activeFootprintId = useFootprintStore((state) => state.active.id);
  const activeFootprintLabel = useFootprintStore((state) => state.active.label);
  const { SetIsOpen, notificationModalOpen } = useSavedViewsStore();
  const activeView = useSavedViewsStore().active;
  const [sharedURL, setSharedURL] = useState('');
  const isLoaded = useAppConfigManager((state) => state.isLoaded);
  const navigate = useNavigate();

  const form = useForm({
    initialValues: Object.assign({}, search)
  });
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {}, [useUserPrefsStore.getState().groups]);
  const updateGroupPreference = (group: string) => {
    useUserPrefsStore.getState().UpdateGroups(group);
  };

  const createManagedGroups = (groups: any) => {
    return groups.sort().map((groupString: any) => {
      const splitGroup = groupString.split('|');
      const group = splitGroup[splitGroup.length - 1];
      // There are a few required groups to access the application that all users
      // are assigned. We don't want to show these in the manager.
      if(group.split('Application').length > 1){
        return <></>;
      }
      return (
        <Group key={groupString}>
          <Checkbox
            id={groupString}
            checked={useUserPrefsStore.getState().groups?.includes(group)}
            testid={`${groupString}-checkbox`}
            onChange={() => updateGroupPreference(group)}
          />
          <InputLabel htmlFor={groupString}>{group}</InputLabel>
        </Group>
      );
    });
  };

  const handleShareSearch = async (event: any) => {
    const email = useConfigStore.getState().userData.email;
    const gridCurrentAPI = useViewStore.getState().grid?.current?.api;
    let filters: any = gridCurrentAPI.getFilterModel();
    const savedSearch = {
      label: 'Shared Search from <' + email + '>',
      filters,
      type: 'shared:' + email,
      footprint: activeFootprintId,
      search
    } as View;
    const url = await createSharedSearchURL(savedSearch);
    setSharedURL(url);
    ToggleShareModalOpen();
  };

  const handleUpdateViewSearch = (value: string | null) => {
    UpdateSearchInput({ field: 'SearchInputField', value: value! });
    if (value === 'playlistSearch') {
      UpdateSearchInput({ field: 'OfferingType', value: ['promo playlist entry'] });
      UpdateSearchInput({ field: 'EntityType', value: [] });
    }
  };

  const handleSetActiveFootprint = (value: string) => {
    const param = searchParams.get('detailOpen');
    if (param) {
      searchParams.delete('detailOpen');
      setSearchParams(searchParams);
    }

    SetActiveFootprint(value);
  };

  function showSelectFootprint() {
    return (
      <>
        <FootprintSelect
          style={{
            backgroundColor:
              colorScheme === 'dark'
                ? darken(globalColors.wbdblue[9], 0.4)
                : globalColors.wbdblue[0],
            borderColor:
              colorScheme === 'dark' ? lighten(globalColors.wbdblue[9], 0.15) : '#B8D2FD'
          }}
          disabled={isSearching}
          value={activeFootprintLabel}
          changeHandler={(v: string) => handleSetActiveFootprint(v)}
        />
      </>
    );
  }
  const searchInputsFiltered = useAppConfigManager((state) =>
    state.GetSearchInputs(activeFootprintId)
  );
  const dateInputsFiltered = useAppConfigManager((state) =>
    state.GetDropDown('dateInputs', activeFootprintId)
  );

  function showSelectSearchInput() {
    const TID = useTID('select', 'select-search-input');
    return (
      <>
        <Text
          c={colorScheme === 'dark' ? theme.colors.gray[2] : theme.colors.gray[7]}
          fz="xs"
        >
          Search By:
        </Text>
        <Select
          {...TID}
          classNames={{
            input: colorScheme === 'dark' ? 'inputDark' : 'inputdd'
          }}
          onChange={(v) => handleUpdateViewSearch(v)}
          data={searchInputsFiltered}
          value={search.SearchInputField || searchInputsFiltered[0]?.value}
          placeholder="Select Search Input"
          size="xs"
          style={{ maxWidth: '9vw' }}
          allowDeselect = {false}
        />
      </>
    );
  }

  function handleSearch() {
    if (hudFilterView.field) {
      // reset so this doesnt run again
      SetHUDFilterView({
        footprint: '',
        field: '',
        search: initialSearchValues
      });
    }
    ToggleIsSearching(true);
  }

  function handleClearSearch() {
    let inputs = {
      SearchInputField: search.SearchInputField,
      SearchInput: search.SearchInput,
      DateField: search.DateField,
    }
    ClearSearchInput(activeFootprintLabel, inputs)
    navigate('/views');
  }

  const showSearchOptions = () => {
    switch (activeFootprintId) {
      case 'footprint-linear':
        return <SearchOptionsLinear search={search} />;
      case 'footprint-acquire':
        return <SearchOptionsAcquire search={search} />;
      case 'footprint-asset':
        return <SearchOptionsAsset search={search} />;
      case 'footprint-crossplatform':
        return <SearchOptionsCrossPlatform search={search} />;
      default:
        return <SearchOptionsOffering search={search} />;
    }
  };

  const showSearchSynopsis = () => {
    if (!isLoaded) {
      return <>Loading Search Synopsis...</>;
    }
    switch (activeFootprintId) {
      case 'footprint-linear':
        return (
          <SearchSynopsis
            synopsisOrder={[
              'ScheduleType',
              'ContentType',
              'Network',
              'Feed',
              'Milestone',
              'MilestoneStatus',
              'StatusCodes',
              'SuppressRepeats',
              'LinearExpired',
              'IsLive'
            ]}
          />
        );
      case 'footprint-acquire':
        return (
          <SearchSynopsis
            synopsisOrder={[
              'ContentTypeAcquire',
              'Milestone',
              'MilestoneStatus',
              'StatusCodes'
            ]}
          />
        );
      case 'footprint-crossplatform':
        return (
          <SearchSynopsis
            synopsisOrder={[
              'Destinations',
              'Milestone',
              'MilestoneStatus',
              'StatusCodes'
            ]}
          />
        );
      default:
        return (
          <SearchSynopsis
            synopsisOrder={[
              'Partner',
              'Market',
              'ContentSource',
              'OfferingType',
              'EntityType',
              'Milestone',
              'MilestoneStatus',
              'StatusCodes',
              'AdMarkers',
              'MidRollAdsAllowed'
            ]}
          />
        );
    }
  };

  const handleUpdateSearch = (value: string) => {
    UpdateSearchInput({ field: 'DateField', value });
  };

  return (
    <>
      <SavedViewsModal />
      <Modal
        opened={shareModalOpen}
        onClose={ToggleShareModalOpen}
        title="Shared Search Link"
        centered
        padding="xl"
        size="lg"
      >
        <Divider></Divider>
        <Text style={{ marginTop: '10px', marginBottom: '10px' }} size="md">
          {sharedURL}
        </Text>
        <Divider></Divider>
        <Group justify="space-between">
          <CopyButton value={sharedURL}>
            {({ copied, copy }) => (
              <Button
                noIcon
                testid="copy-sharedURL-button"
                style={{ marginTop: '10px' }}
                color={copied ? 'teal' : 'blue'}
                onClick={copy}
              >
                {copied ? 'Copied URL' : 'Copy URL'}
              </Button>
            )}
          </CopyButton>
        </Group>
      </Modal>
      <Box
        className={'baseContainer'}
        style={{
          border: '1px solid',
          borderColor:
            colorScheme === 'dark'
              ? darken(colors.wbdblue[6], 0.3)
              : lighten(colors.wbdblue[3], 0.6),
          backgroundColor:
            colorScheme === 'dark' ? darken(colors.wbdblue[9], 0.4) : '#E6EFFF'
        }}
      >
        <FormProvider form={form}>
          <form onSubmit={form.onSubmit(() => handleSearch())}>
            <Stack>
              <Group>
                <Group style={{ marginRight: '245px' }}>
                  <Chevron />
                  {showSelectFootprint()}
                  <Text
                    c={
                      colorScheme === 'dark' ? theme.colors.gray[1] : theme.colors.gray[7]
                    }
                    fz="xs"
                  >
                    Active View:
                  </Text>
                  <SavedViewsSelect />
                  {showSelectSearchInput()}
                  <SearchBarInput
                    size="xs"
                    handleSearch={handleSearch}
                    maxWidth={355}
                    testid={'SearchInputText'}
                  />
                  <MButton
                    variant="subtle"
                    leftSection={
                      <Search
                        size={24}
                        style={{ margin: -8, marginLeft: 1, color: theme.colors.blue[6] }}
                      />
                    }
                    loading={isSearching}
                    justify="left"
                    color={isSearching ? 'dark' : timeRelative ? 'blue' : 'violet'}
                    type="submit"
                  />
                  <Select
                    value={search.DateField}
                    classNames={{
                      input: colorScheme === 'dark' ? 'inputDark' : 'inputdd'
                    }}
                    styles={{
                      input: {
                        width: '170px',
                        height: '20px',
                        fontSize: '.9em'
                      }
                    }}
                    onChange={(v) => {
                      if (v) handleUpdateSearch(v!);
                      if (v! === 'none') {
                        UpdateSearchInput({ field: 'dateEnabled', value: false });
                      } else {
                        UpdateSearchInput({ field: 'dateEnabled', value: true });
                      }
                    }}
                    data={dateInputsFiltered}
                    allowDeselect= {false}
                  />
                  <Group>
                    <Text
                      c={
                        colorScheme === 'dark'
                          ? theme.colors.gray[2]
                          : theme.colors.gray[7]
                      }
                      fz="xs"
                    >
                      Date Range:
                    </Text>
                    <DateRangePicker
                      timeRelative={search.timeRelative}
                      startDateTime={startDateTime}
                      endDateTime={endDateTime}
                      startDate={startDate}
                      endDate={endDate}
                      disabled={search.DateField === 'none'}
                    />
                  </Group>
                </Group>
                <Group
                  style={{
                    position: 'absolute',
                    right: '25px',
                    alignSelf: 'flex-start'
                  }}
                >
                  <Flex>
                    <Button
                      testid="share-current-search-button"
                      variant="subtle"
                      styles={{ section: { marginRight: -2 } }}
                      style={{
                        color: 'gray'
                      }}
                      leftSection={<Share size={24} />}
                      onClick={handleShareSearch}
                      tooltip={{
                        label: 'Share Current Search',
                        position: 'top',
                        color: colorScheme === 'dark' ? 'gray' : 'black'
                      }}
                    />
                    <SavedViewsButton />
                    <Button
                      testid="save-current-view-button"
                      variant="subtle"
                      leftSection={<Bookmark size={24} />}
                      style={{
                        color: 'gray'
                      }}
                      styles={{ section: { marginRight: -2 } }}
                      onClick={() => {
                        SetIsOpen(true);
                      }}
                      tooltip={{
                        label: 'Save Current View',
                        position: 'top',
                        color: colorScheme === 'dark' ? 'gray' : 'black'
                      }}
                    />
                    <Group justify="flex-end">
                      <Popover
                        width={420}
                        position="left"
                        withArrow
                        shadow="md"
                        classNames={{
                          arrow: 'popoverArrow',
                          dropdown: 'popoverDropdown'
                        }}
                        closeOnClickOutside={notificationModalOpen ? false : true}
                      >
                        <Popover.Target>
                          <MButton
                            disabled={activeView.type === 'global'}
                            variant="subtle"
                            styles={{ section: { marginRight: -2 } }}
                            style={{
                              color: 'gray',
                              background: 'transparent'
                            }}
                            leftSection={
                              activeView.type === 'global' ? (
                                <BellOff size={24} />
                              ) : (
                                <Bell size={24} />
                              )
                            }
                          ></MButton>
                        </Popover.Target>
                        <Popover.Dropdown>
                          <Notifications />
                        </Popover.Dropdown>
                      </Popover>
                    </Group>
                    <ResetViewFilters />
                    <>
                      <Center>
                        <Group>
                          <Button
                            testid="reset-to-default-grid-button"
                            onClick={handleClearSearch}
                            variant="subtle"
                            style={{
                              color: 'gray'
                            }}
                            tooltip={{
                              label: 'This will clear all Search parameters',
                              position: 'top',
                              color: colorScheme === 'dark' ? 'gray' : 'black'
                            }}
                            leftSection={<Eraser style={{ marginRight: -2 }} size={24} />}
                          />
                        </Group>
                      </Center>
                    </>
                    <>
                      <Center>
                        <Popover
                          width={420}
                          position="left"
                          withArrow
                          shadow="md"
                          classNames={{
                            arrow: 'popoverArrow',
                            dropdown: 'popoverDropdown'
                          }}
                          closeOnClickOutside={true}
                        >
                          <Popover.Dropdown>
                            <h3 style={{margin: '0 0 12px 0'}}>Manage Groups</h3>
                            {createManagedGroups(
                              useConfigStore.getState().userData.groups
                            )}
                          </Popover.Dropdown>
                          <Popover.Target>
                            <Group>
                              <Button
                                testid="manage-groups-grid-button"
                                variant="subtle"
                                style={{
                                  color: 'gray'
                                }}
                                tooltip={{
                                  label: 'Manage your active groups',
                                  position: 'top',
                                  color: colorScheme === 'dark' ? 'gray' : 'black'
                                }}
                                leftSection={
                                  <Users style={{ marginRight: -2 }} size={24} />
                                }
                              />
                            </Group>
                          </Popover.Target>
                        </Popover>
                      </Center>
                    </>
                  </Flex>
                </Group>
              </Group>
              {showSearchSynopsis()}
              <Collapse
                testid="SearchBarCollapse"
                in={searchBarOpen}
                style={{ marginTop: -18 }}
              >
                <Divider
                  mt={8}
                  label="Search Options"
                  labelPosition="center"
                  styles={{
                    label: {
                      color:
                        colorScheme === 'dark'
                          ? theme.colors.gray[2]
                          : theme.colors.gray[7]
                    }
                  }}
                />
                <Grid>
                  <Grid.Col span={10}>{showSearchOptions()}</Grid.Col>
                  <Grid.Col
                    span={2}
                    style={{ textAlign: 'right', paddingRight: 16, marginTop: 8 }}
                  >
                    <Stack align="flex-end" justify="space-around">
                      <SearchBarConfigureButton />
                      <MButton
                        leftSection={
                          <Search size={24} style={{ margin: -8, marginLeft: 1 }} />
                        }
                        color={timeRelative ? 'blue' : 'violet'}
                        loading={isSearching}
                        justify="left"
                        type="submit"
                        onClick={() => ToggleSearchBarOpen()}
                      >
                        Search
                      </MButton>
                    </Stack>
                  </Grid.Col>
                </Grid>
              </Collapse>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </>
  );
};

export default SearchBar;

import { useEffect, useState } from "react"
import { iSearch, initialSearchStateValues } from "../../../Search/models"
import { Center, Group, Loader, Text } from "@mantine/core"
import { Result } from "../../../../api/offering/fetchOfferingRowData"
import React from "react";
import { useLocation } from "react-router-dom";
import { useDetailsStore } from "../../store";
import { useAppConfigManager } from "../../../../components/AppConfigs";




interface iDetailsSoloProps {
    footprint: string,
    startDate: Date | null,
    endDate: Date | null,
    searchInputField: string,
    DetailComponent: JSX.Element;
    fetchServerRowData: (
        startDate: Date | null,
        endDate: Date | null,
        searchState: iSearch,
        limit: number,
        page: number
      ) => Promise<Result>
}

export const DetailsSolo = (props: iDetailsSoloProps) => {
    const { startDate, endDate } = props;
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const details = useDetailsStore((state) => state)
    const SetDetails = useDetailsStore((state) => state.SetDetails);
    const [detailData, setDetailData] = useState<any>()
    const GetConfigs = useAppConfigManager((state) => state.GetConfig);
    const ToggleIsLoadingConfig = useAppConfigManager((state) => state.ToggleIsLoading);
    const isLoaded = useAppConfigManager((state) => state.isLoaded);

    const fetchData = async () => {
        
        let searchCriteria = Object.assign({}, initialSearchStateValues.search);
        searchCriteria.SearchInput = window.location.href.split('/')[4].split('?')[0]
        searchCriteria.SearchInputField = props.searchInputField
        // offering
        searchCriteria.ExpiredAssets = ""
        // linear
        searchCriteria.LinearExpired = true
        if (params.get('scheduleType') === 'final' || params.get('scheduleType') === 'air'){
            searchCriteria.ScheduleType = "air\",\"final"
        } else if (params.get('scheduleType') === 'advanced'){
            searchCriteria.ScheduleType = "advanced"
        }
        
        const data = await props.fetchServerRowData(
            startDate,
            endDate,
            searchCriteria,
            1,
            1
          );
        if (data?.rowData.length > 0){
            let newDetails = Object.assign({}, details);
            if (props.footprint === 'footprint-offering') {
            newDetails = Object.assign({}, details, {
                offering: data?.rowData[0],
            });
            } else if (props.footprint === 'footprint-linear') {
            newDetails = Object.assign({}, details, {
                schedule: data?.rowData[0],
            });
            } else if (props.footprint === 'footprint-acquire') {
            newDetails = Object.assign({}, details, {
                acquire: data?.rowData[0],
            });
            } else if (props.footprint === 'footprint-asset') {
            newDetails = Object.assign({}, details, {
                asset: data?.rowData[0],
            });
            } else if (props.footprint === 'footprint-crossplatform') {
            newDetails = Object.assign({}, details, {
                crossplatform: data?.rowData[0],
            });
            }
            SetDetails(newDetails);
            setDetailData(data?.rowData[0])
        }
    }
    useEffect(() => {
        fetchData()
    }, []);

    useEffect(() => {
        (async () => {
          try {
            await GetConfigs('taskValueMapper');
          } catch (err) {
            console.error('Error Loading Task Value Mapper: ', err);
          }
          try {
            await GetConfigs('dropdown');
          } catch (err) {
            console.error('Error Dropdowns: ', err);
          }
          try {
            await GetConfigs('fieldMappings');
          } catch (err) {
            console.error('Error Loading FieldMappings: ', err);
          }
          ToggleIsLoadingConfig(true);
        })();
      }, []);

    return (
        <>
            {(!detailData || !isLoaded ) && (
                <Center>
                    <Group align="apart">
                        <Loader />
                        <Text style={{ marginTop: 8, fontStyle: 'bold' }}>
                            Loading Details
                        </Text>
                    </Group>
                </Center>
            )}
            {(detailData && isLoaded ) &&(
                React.cloneElement(props.DetailComponent, {details:detailData})
            )}
        </>
    )
}
import useAxios from '../../services/axios/axios';

import { iSearch } from '../../containers/Search/models';
import { useConfigStore } from '../../app/store';
import {
  constructPartialLinearObject,
  constructScheduleObject
} from './fetchLinearRowData';
import { useAppConfigManager } from '../../components/AppConfigs/store';
import { getStatusCodeString } from '../../utils/utilities';

export const fetchLinearStatusCount = async (
  startDate: Date | null,
  endDate: Date | null,
  searchState: iSearch,
  milestoneName: string
) => {
  startDate;
  endDate;
  const { post } = useAxios();
  const baseURL = useConfigStore.getState().config.baseApiGatewayUrl + '/graphql/search';
  const query = constructQuery(startDate, endDate, searchState, milestoneName);

  const schema = {
    query: query
  };

  const result = await post({
    url: baseURL,
    data: JSON.stringify(schema)
  });

  if (
    result &&
    result.data &&
    result.data.scheduleStatusCount &&
    result.data.scheduleStatusCount.records
  ) {
    return result.data.scheduleStatusCount.records;
  }
  return 0;
};

export const constructQuery = (
  startDate: Date | null,
  endDate: Date | null,
  searchState: iSearch,
  milestoneName: string
) => {
  const queryFilters = constructFilters(startDate, endDate, searchState, milestoneName);
  const query = `query {
          scheduleStatusCount(
            ${queryFilters}
          ) {
            records {
              status
              count
            }
          }
        }`;

  return query;
};

const constructFilters = (
  startDate: Date | null,
  endDate: Date | null,
  filters: any,
  milestoneName: string
) => {
  const milestoneStatuses =
    filters.MilestoneStatus?.length > 0
      ? `[${filters.MilestoneStatus?.map((status: any) => `"${status}"`)}]`
      : '[]';
  const milestones =
    filters.Milestone?.length > 0
      ? `[${filters.Milestone?.map((status: any) => `"${status}"`)}]`
      : '[]';
  const milestoneString = `milestones:{
      name: ${milestones},
      status: ${milestoneStatuses}
    }`;

  formattedMilestoneNames.forEach((n) => {
    if (milestoneName === n.mName) milestoneName = n.searchName;
  });

  let partialSearchInput: string = '';
  if (filters.SearchInput) {
    let searchInputs;
    if (filters.SearchInputField === 'keyword') {
      let copy = filters.SearchInput;
      let newInput = copy.replaceAll('"', '\\"');
      searchInputs = newInput;
    } else {
      searchInputs = filters.SearchInput.split(' ');
      if (searchInputs.length > 1) {
        searchInputs = searchInputs.join('","');
      }
    }
    partialSearchInput = searchInputs.toString();
  }

  let csId = '';
  if (filters.SearchInputField && filters.SearchInputField === 'csId') {
    let input = '';
    let searchInputs = filters.SearchInput.split(' ');
    if (searchInputs.length > 1) {
      input = searchInputs.join('","');
    } else {
      input = searchInputs[0];
    }
    csId = `
    csId: {
      id: ["${input}"]
    }
    `;
  }

  let suppressRepeats = '';
  // let networkList: string[] = [];
  if (filters.SuppressRepeats) {
    if (filters.SuppressRepeats === 'full') {
      suppressRepeats = `suppressBy: full`;
    } else {
      suppressRepeats = `suppressBy: network`;
      //networkList = networks.map((n) => n.value);
    }
  }

  const scheduleObject = constructScheduleObject(
    startDate,
    endDate,
    filters,
  );
  let partialObject = '';
  if (partialSearchInput && filters.SearchInputField === 'keyword') {
    partialObject = constructPartialLinearObject(partialSearchInput);
  }
  const taskValueMapper = useAppConfigManager.getState().taskValueMapper
  const statusCodeString = getStatusCodeString(filters.StatusCodes, taskValueMapper)

  let auditObject = `audit:{}`;
  if (filters.DateField === 'lastUpdated' && filters.dateEnabled) {
    auditObject = `audit:{
            updatedAtFrom:"${startDate!.toISOString()}",
            updatedAtTo:"${endDate!.toISOString()}"
          }`;
  }

  const queryFilters = `filters: {
        ${auditObject}
        ${milestoneString}
        ${statusCodeString}
        ${scheduleObject}
        ${partialObject}
        ${csId}
        ${suppressRepeats}
        byMilestone: "${milestoneName}"
    }`;
  return queryFilters;
};

export const formattedMilestoneNames = [
  { searchName: 'schedule-footprint', mName: 'Overall Status' }
];

import { Collapse as MCollapse, CollapseProps } from '@mantine/core';
import {useTID} from '../../helpers/useTestId'

interface ICollapseProps extends CollapseProps {
  testid: string;
}

export const Collapse = (props: ICollapseProps) => {
  const TID = useTID('Collapse', props.testid)

  return (
    <MCollapse {...TID} {...props}>{props.children}</MCollapse>
  );
};

import { create } from 'zustand';
import { iComment, iCommentsState } from './models';
import { CommentsService } from '../../services/comments/commentsService';
import { useViewStore } from '../Views/store';

export const useCommentStore = create<iCommentsState>()((set, get) => ({
  comments: [] as iComment[],
  id: '',
  SetComments: (comments: iComment[]) => {
    set({ comments });
  },
  GetComments: async (id: string, rowId: string) => {
    const gridRef = useViewStore.getState().grid;
    let rowData = gridRef?.current?.api?.getRowNode(rowId);
    const res = await CommentsService.getComments(id);
    // Update the actual cell in the grid
    if (res.length > 0) {
      rowData?.setDataValue('hasComments', true)
      rowData?.setDataValue('latestComment', res[0])
    } else {
      rowData?.setDataValue('hasComments', false)
      rowData?.setDataValue('latestComment', '')
    }
    set({ comments: res });
  },
  SetOfferingCommentsOpen: (id: string) => {
    set({ id });
  }
}));

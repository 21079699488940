import { getAxiosConfig } from '../axios/config';
import { View } from '../../containers/Views/stores/grid/models';
import axios from 'axios';
import { useSavedViewsStore } from '../../containers/SavedViews/store';
import { createViewsList, sortByPosition } from '../../utils/utilities';
import { useFootprintStore } from '../../containers/Footprints/store';
import { useConfigStore } from '../../app/store';
import { Notification, initialNotification } from '../../components/Notification/models';
import _ from 'lodash';

export const ViewsService = {
  getGlobalViews: async () => {
    const baseURL = useConfigStore.getState().config.baseApiGatewayUrl;
    const { headers } = getAxiosConfig();

    const res = await axios.get(baseURL + '/rest/appconfigs/v1?type=global', { headers });
    return res.data;
  },
  getPersonalViews: async () => {
    const baseURL = useConfigStore.getState().config.baseApiGatewayUrl;
    const { OKTAConfig, headers } = getAxiosConfig();
    const uid = OKTAConfig.idToken.claims.sub;
    const email = OKTAConfig.idToken.claims.email;
    const emailName = email.split('@')[0];
    // API call for Views data by UserID
    let res;
    res = await axios.get(baseURL + `/rest/appconfigs/v1?type=personal:${uid}`, {
      headers
    });
    // for some reason multiple emails werent being fixed, leave like this for now
    // TODO add conditional eventually
    // if(res.data.length === 0) {
      const result = await axios.get(baseURL + `/rest/appconfigs/v1?type=personal:${emailName}`, {
        headers
      });
      if (result.data.length > 0) {
        console.log('converting email to uid')
        result.data.forEach(async(view: any) => {
          const id = view.id;
          const viewUpdate =  {
            ...view, 
            type: `personal:${uid}:${emailName}`
          }
          await axios.put(baseURL + `/rest/appconfigs/v1/${id}`, viewUpdate, { headers });
          // return res.data;   
  
        });
      }
    // }

    return res.data;
  },
  getNotifications: async () => {
    const baseURL = useConfigStore.getState().config.baseApiGatewayUrl;
    const { headers } = getAxiosConfig();
    const userData = useConfigStore.getState().userData;
    const activeViewFootprint = useSavedViewsStore.getState().active.footprint;
    // API call for Views data by UserID
    const res = await axios.get(
      baseURL + `/rest/appconfigs/v1?type=personal:${userData.uid}`,
      {
        headers
      }
    );
    const notificationList = res.data.map((v: any) => {
      if (v.notification) {
        v.notification.view = v.label;
        v.notification.footprint = v.footprint
        return v.notification;
      }
      const notification = _.cloneDeep(initialNotification);
      notification.deliveryMethod = 'email';
      notification.footprint = v.footprint;
      notification.view = v.label;
      notification.timeInterval = '8';
      notification.active = false;
      return notification;
    });
    return notificationList.filter((n: Notification) => {
      return n.footprint === activeViewFootprint;
    });
  },
  getViewByID: async (id: string) => {
    const { headers } = getAxiosConfig();
    // API call for Views data by UserID
    const baseURL = useConfigStore.getState().config.baseApiGatewayUrl;
    const res = await axios.get(baseURL + `/rest/appconfigs/v1?id=${id}`, {
      headers
    });
    return res.data;
  },
  upsertView: async (id: string, view: View) => {
    const baseURL = useConfigStore.getState().config.baseApiGatewayUrl;
    const { OKTAConfig, headers } = getAxiosConfig();
    const uid = OKTAConfig.idToken.claims.sub;
    const email = OKTAConfig.idToken.claims.email;
    const emailName = email.split('@')[0];
    const viewUpdate =  {
      ...view, 
      type: view.type.includes('shared') ? view.type : `personal:${uid}:${emailName}`
    }
    const res = await axios.put(baseURL + `/rest/appconfigs/v1/${id}`, viewUpdate, { headers });
    return res.data;
  },
  deleteView: async (id: string) => {
    const baseURL = useConfigStore.getState().config.baseApiGatewayUrl;
    const { headers } = getAxiosConfig();
    // API call for deleting a view by view ID
    const res = await axios.delete(baseURL + `/rest/appconfigs/v1/${id}`, { headers });
    return res.data;
  },
  defaultView: async (item: any) => {
    const baseURL = useConfigStore.getState().config.baseApiGatewayUrl;
    const { OKTAConfig, headers } = getAxiosConfig();
    const uid = OKTAConfig.idToken.claims.sub;
    const email = OKTAConfig.idToken.claims.email;
    const emailName = email.split('@')[0];
    const res = await axios.get(baseURL + `/rest/appconfigs/v1?type=personal:${uid}`, {
      headers
    });
    const result = await axios.get(
      baseURL + `/rest/appconfigs/v1?type=personal:${emailName}`,
      {
        headers
      }
    );
    const personal = [...res.data, ...result.data]
    useSavedViewsStore.setState({personal})
    const activeFootprintViews = [
      ...useSavedViewsStore.getState().global,
      ...personal
    ]
      .sort(sortByPosition)
      .filter((v) => v.footprint === useFootprintStore.getState().active.id);
    const currentDefaultView = activeFootprintViews.find((d) => d.default);
    const newDefaultView = activeFootprintViews.find((d) => d.id === item.id);
    const hasDefault = currentDefaultView !== undefined;
    if (!hasDefault && newDefaultView) {
      // No default selected case
      newDefaultView.default = true;
      ViewsService.upsertView(item.id, newDefaultView);
    } else if (hasDefault && currentDefaultView && newDefaultView) {
      currentDefaultView.default = false;
      ViewsService.upsertView(currentDefaultView.id, currentDefaultView);
      if (currentDefaultView.id !== newDefaultView.id) {
        newDefaultView.default = true;
        ViewsService.upsertView(newDefaultView.id, newDefaultView);
      }
    }
    const sortedViews = createViewsList(
      activeFootprintViews.filter((v) => v.type !== 'global'),
      'Personal'
    );
    useSavedViewsStore.getState().SetList(sortedViews);
  }
};

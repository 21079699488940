import { ViewsService } from '../../services/views/viewsService';
import { TrashX } from 'tabler-icons-react';
import { Group, Popover, Text, 
  useComputedColorScheme, 
  // useMantineTheme 
} from '@mantine/core';
import { Button } from '../../components/Button';
import { useRef } from 'react';
import { useSavedViewsStore } from './store';
import classes from './SavedViews.module.css';

export const SavedViewsRemove = (props: any) => {
  const personal = useSavedViewsStore((state) => state.personal);
  const global = useSavedViewsStore((state) => state.global);
  const activeID = useSavedViewsStore((state) => state.active.id);
  const SetActiveView = useSavedViewsStore((state) => state.SetActiveView);
  const list = useSavedViewsStore((state) => state.list);
  const SetList = useSavedViewsStore((state) => state.SetList);
  const removeModal = useSavedViewsStore((state) => state.removeModal);
  const SetRemoveModal = useSavedViewsStore((state) => state.SetRemoveModal);
  const ToggleClickOutside = useSavedViewsStore((state) => state.ToggleClickOutside);
  const closeOnClickOutside = useSavedViewsStore((state) => state.clickOutside);

  // const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');
  const ref = useRef<any>();
  const removeItem = async () => {
    await ViewsService.deleteView(props.id);
    if (props.id === activeID) {
      let defaultView = [...personal]
        .filter((v) => v.id !== props.id)
        .find((v) => v.default);
      if (!defaultView) {
        defaultView = [...global].find((v) => v.id === 'default-view');
      }
      if (defaultView) {
        SetActiveView(defaultView.id);
      }
    } else {
      let newList = list.filter(i=>i.id !== removeModal)
      SetList(newList)
      SetRemoveModal('');
      ToggleClickOutside();

    }
  };

  const deleteModal = (id: any) => {
    ToggleClickOutside();
    SetRemoveModal(id);
  }
  const closeModal = () => {
    SetRemoveModal('');
    if(!closeOnClickOutside) {
      ToggleClickOutside(); 
    }
  }
  return (
    <Popover
      opened={removeModal === props.id}
      onClose={closeModal}
      classNames={{
        arrow: colorScheme === 'dark' ? classes.arrowDark : classes.arrowLight,
        dropdown: colorScheme === 'dark' ? classes.dropdownDark : classes.dropdownLight
      }}
    >
      <Popover.Target>
        <div ref={ref}>
          <TrashX
            style={{ cursor: 'pointer' }}
            size={24}
            strokeWidth={1.5}
            onClick={() => deleteModal(props.id)}
          ></TrashX>
        </div>
      </Popover.Target>
      <Popover.Dropdown>
        <div>
          <Text mt={-40} mb={20}>Are you sure you'd like to delete {props.label}?</Text>
          <Group mt={70} justify="center">
            <Button noIcon testid='saveView-remove-item' onClick={removeItem}>Delete</Button>
            <Button noIcon testid='saveView-cancel-remove-item' onClick={closeModal}>Cancel</Button>
          </Group>
        </div>
      </Popover.Dropdown>
    </Popover>
  );
};

import styled from '@emotion/styled';
import { Button, Select, useComputedColorScheme } from '@mantine/core';
import { range } from 'lodash';
import { ChevronRight, ChevronLeft } from 'tabler-icons-react';
import { useDatePickerStore } from './store';

export const DatePickerComp = styled.div`
  position: relative;
  .columns {
    position: absolute;
    top: 5px;
    left: 5px;
    display: flex;
    justify-content: space-evenly;
    .chevron {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      width: 30px;
      height: 30px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
    .month-year-wrapper {
      margin: 0 15px;
      width: 200px;
      display: flex;
      justify-content: space-between;
      .month,
      .year {
        margin-bottom: 10px;
      }
      .month {
        width: 100px;
      }
      .year {
        width: 80px;
      }
    }
  }
  .mantine-DatePicker-calendarHeader {
    visibility: hidden;
  }
`;

type CustomMonthAndYearDropdownProps = {
  columns?: 1 | 2;
};

export const CustomMonthAndYearDropdown = (props: CustomMonthAndYearDropdownProps) => {
  const { columns = 1 } = props;
  const customMonthYearSelected = useDatePickerStore.getState().customMonthYearSelected
  const SetCustomMonthYearSelected = useDatePickerStore.getState().SetCustomMonthYearSelected

  const colorScheme = useComputedColorScheme('light');

  const years = range(new Date().getFullYear() - 50, new Date().getFullYear() + 50, 1);

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  return (
    <div className="columns">
      <Button
        className="chevron"
        onClick={() =>
          SetCustomMonthYearSelected({
            month:
              customMonthYearSelected.month === 0
                ? 11
                : customMonthYearSelected.month - 1,
            year:
              customMonthYearSelected.month === 0
                ? customMonthYearSelected.year - 1
                : customMonthYearSelected.year
          })
        }
      >
        <ChevronLeft />
      </Button>
      {range(columns).map((col) => (
        <div className={`month-year-wrapper ${col}`} key={`month-year-wrapper ${col}`}>
          <div className="month">
            <Select
              key={'LeftDateSelect' + col}
              classNames={{ input: colorScheme === 'dark' ? 'inputDark' : 'inputdd' }}
              comboboxProps={{ withinPortal: false }}
              onChange={(v, opt) => {
                if (v) {
                  SetCustomMonthYearSelected({
                    month: Number(opt.value) - col === -1 ? 11 : Number(opt.value) - col,
                    year:
                      Number(opt.value) - col === -1
                        ? customMonthYearSelected.year - 1
                        : customMonthYearSelected.year
                  });
                }
              }}
              data={months.map((month, index) => ({
                label: month,
                value: String(index),
                key: month + col
              }))}
              defaultValue={String(
                Number(customMonthYearSelected.month) === 11 && col > 0
                  ? 0
                  : customMonthYearSelected.month + col
              )}
              value={String(
                Number(customMonthYearSelected.month) === 11 && col > 0
                  ? 0
                  : customMonthYearSelected.month + col
              )}
              size="xs"
            />
          </div>
          <div className="year">
            <Select
              key={'RightDateSelect' + col}
              classNames={{ input: colorScheme === 'dark' ? 'inputDark' : 'inputdd' }}
              comboboxProps={{ withinPortal: false }}
              onChange={(v, opt) => {
                if (v) {
                  SetCustomMonthYearSelected({
                    ...customMonthYearSelected,
                    year:
                      Number(customMonthYearSelected.month) === 11 && col > 0
                        ? Number(opt.value) - 1
                        : Number(opt.value)
                  });
                }
              }}
              data={years.map((year) => ({
                label: String(year),
                value: String(year),
                key: year + col
              }))}
              defaultValue={String(
                Number(customMonthYearSelected.month) === 11 && col > 0
                  ? Number(customMonthYearSelected.year) + 1
                  : Number(customMonthYearSelected.year)
              )}
              value={String(
                Number(customMonthYearSelected.month) === 11 && col > 0
                  ? Number(customMonthYearSelected.year) + 1
                  : Number(customMonthYearSelected.year)
              )}
              size="xs"
            />
          </div>
        </div>
      ))}
      <Button
        className="chevron"
        onClick={() =>
          SetCustomMonthYearSelected({
            month:
              customMonthYearSelected.month === 11
                ? 0
                : customMonthYearSelected.month + 1,
            year:
              customMonthYearSelected.month === 11
                ? customMonthYearSelected.year + 1
                : customMonthYearSelected.year
          })
        }
      >
        <ChevronRight />
      </Button>
    </div>
  );
};

import { Box } from '@mantine/core';
import { useNotificationStore } from '../../components/Notification/store';
import { useEffect } from 'react';

export const NotificationsTab = () => {
  const { getNotifications, notifications } = useNotificationStore();

  // load Notifications
  useEffect(() => {
    (async () => {
      try {
        await getNotifications();
      } catch (err) {
        console.error('Error Loading Notifications: ', err);
      }
    })();
  }, [notifications.length]);



  return (
    <Box style={{ width: '80rem' }}>
    </Box>
  );
};

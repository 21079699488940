import './styles/fnd-info-block.scss'



export const InfoBlock = (props: {label:string, children: any}) => {
  return (
    <div className="fnd-info-block">
      <div className="fnd-info-inner-block">
        <span className="fnd-info-block-label">{props.label}</span>
        <span  className="fnd-info-inner-block-children">{props.children}</span>
      </div>
    </div>
  );
};

import { Cascader } from '../Cascader/Cascader';
import { useAppConfigManager } from '../AppConfigs';
import { useSearchStore } from '../../containers/Search/store';

export const CascaderNetwork = (props: any) => {
  const { Network } = useSearchStore((state) => state.search);
  const options = useAppConfigManager((state) => state.GetDropDown('networks'));

  return (
    <Cascader
      id="cascaderNetworks"
      data={Network}
      field="Network"
      name="Network"
      noOpen={props.noOpen}
      options={options}
    />
  );
};

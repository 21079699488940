import { Select, useComputedColorScheme } from '@mantine/core';
import { useSavedViewsStore } from './store';
import { useGridStore } from '../Views/store';
import classes from './SavedViews.module.css';
import _ from 'lodash';
import { useFootprintStore } from '../Footprints/store';
import { useUserPrefsStore } from '../../app/store';

 
export const SavedViewsSelect = () => {
  const SavedViews = useSavedViewsStore((state) => state);
  const SetGridLayoutModified = useGridStore((state) => state.SetGridLayoutModified);
  const currentFootprint = useFootprintStore((state) => state.active.id);
  const groups = useUserPrefsStore().groups;
  const defaultVw = useUserPrefsStore().defaultViews;

  const { 
    // global, personal, 
    globalList, SetActiveView, list, active } = SavedViews;

  const handleViewSelect = (id: string) => {
    SetActiveView?.(id);
    SetGridLayoutModified?.(false);
  };
  // const defaultView = [...(personal || []), ...(global || [])].find((v) => v.default);
  const defaultView = defaultVw && defaultVw.find((v) => v.default);
  const colorScheme = useComputedColorScheme('light');

  const selectData: any = [];
  if (list?.length > 0) {
    const group = {
      group: 'Personal',
      items: list?.filter((i: { footprint: string }) => i.footprint === currentFootprint)
    };
    selectData.push(group);
  }
  if (globalList?.length > 0) {
    const group = {
      group: 'Presets - Global',
      items: globalList.filter(
        (i: { footprint: string; role?: string }) => {
          return (i.footprint === currentFootprint && i.role === 'all');
        }
      )
    };
    selectData.push(group);
    groups?.forEach((g: any) => {
      const group = {
        group: `Presets - ${g}`,
        items: globalList.filter(
          (i: { footprint: string; role?: string }) => {
            return i.footprint === currentFootprint && g === i.role!;
          }
        )
      }
      selectData.push(group);
    })
  }

  return (
    <Select
      classNames={{
        // item: classes.item,
        input: colorScheme === 'dark' ? 'inputDark' : classes.input,
        wrapper: classes.wrapper
      }}
      data={selectData}
      value={active?.id || defaultView?.default}
      onChange={(v) => {
        if (v) handleViewSelect(v!);
      }}
      placeholder="Select Saved Search"
      size="xs"
      allowDeselect = {false}
    ></Select>
  );
};

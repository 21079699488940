import { useComputedColorScheme } from '@mantine/core';
// import { useMantineTheme } from '@mantine/styles';

export const GetThemedStatusColor = (color: string) => {
  // const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');

  let parts = color.split(':');
  if (parts.length > 1) {
    return colorScheme === 'dark' ? parts[0] : parts[1];
  }

  return color;
};

import { capitalize } from './../../../../utils/utilities';
import { ValueGetterParams } from 'ag-grid-community';
import { useAppConfigManager } from '../../../../components/AppConfigs/store';
import { Option } from '../../../../components/CascaderNetwork/_networks';
import { useStatusCodeStore } from '../../../../components/StatusCodeDictionary/store';
import { formattedDate } from '../../../../helpers';
import dayjs from 'dayjs';
import { getPseComplianceStatus } from '../../../../utils/utilities';
import { getRatings } from '../../../../utils/utilities';
import { getMilestone } from '../offering/offeringValueGetters';

export const footprintStatusGetter = (params: ValueGetterParams) => {
  const GetStatusCode = useStatusCodeStore.getState().GetStatusCode;
  const footprintStatus = params?.data?.milestones?.find(
    (element: { name: string }) => element.name === 'schedule-footprint'
  );
  let status = footprintStatus ? footprintStatus.status : null;
  if (status !== null) status = status.charAt(0).toUpperCase() + status.slice(1);
  if (footprintStatus?.codes && footprintStatus?.codes.length > 0) {
    let sc = GetStatusCode(footprintStatus.codes[0].code);
    if (sc.status && sc.badgeText !== '') {
      status =
        sc.status.charAt(0).toUpperCase() + sc.status.slice(1) + ' - ' + sc.badgeText;
    }
  }
  return status;
};

export const milestoneStatusGetter = (params: ValueGetterParams) => {
  const GetStatusCode = useStatusCodeStore.getState().GetStatusCode;
  const milestone = getMilestone(params);
  let status = milestone ? milestone.status : null;
  if (status === 'na') status = 'N/A';
  if (status !== null) status = status.charAt(0).toUpperCase() + status.slice(1);
  if (milestone?.codes && milestone?.codes.length > 0) {
    let sc = GetStatusCode(milestone.codes[0].code);
    if (sc.status && sc.badgeText !== '') {
      status =
        sc.status.charAt(0).toUpperCase() + sc.status.slice(1) + ' - ' + sc.badgeText;
    }
  }
  return status;
};

export const milestoneDetailGetter = (params: ValueGetterParams) => {
  let type = params.colDef.cellRendererParams.activity ? 'Activity' : 'Process';
  let name = params.colDef.cellRendererParams.activity
    ? `${params.colDef.cellRendererParams.field}|${params.colDef.cellRendererParams.activity}`
    : params.colDef.cellRendererParams.field;

  const milestone = params.data.milestones?.find(
    (element: { type: string; name: string }) =>
      element.type === type && element.name === name
  );

  if (milestone && milestone?.codes !== null && milestone.codes.length > 0) {
    const codes = milestone.codes.find((element: any) => 'detail' in element);
    let detail = codes?.detail;
    if (!codes.detail) {
      const GetStatusCode = useStatusCodeStore.getState().GetStatusCode;
      let sc = GetStatusCode(codes.code);
      if (sc) {
        detail = sc.details;
      }
    }
    if (detail && codes.data) {
      return `${detail} - ${codes.data}`;
    } else if (detail) {
      return detail;
    } else if (codes.data) {
      return codes.data;
    }
  }
  return null;
};

function truncateUUID(uuid: string) {
  return uuid.substring(0, 4) + ' ... ' + uuid.substring(uuid.length - 4, uuid.length);
}

export const startDateFormatter = (props: any) => {
  if (props?.data?.schedule) {
    const date = props?.data?.schedule.startDateTime;
    let formattedDate = dayjs(date).format('MMM DD, YYYY (hh:mm a | [EST])');

    return formattedDate;
  }
  return '';
};

export const segmentDateFormatter = (props: any) => {
  if (props?.data?.schedule) {
    const date = props?.data?.schedule.segment?.startDateTime;
    return formattedDate(date);
  }
  return '';
};

export const segmentDateFormatterUTC = (props: any) => {
  if (props?.data?.schedule) {
    const date = props?.data?.schedule.segment?.startDateTime;
    let formattedDate = new Date(date).toLocaleString('en-us', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
      timeZone: 'UTC'
    });

    return formattedDate;
  }
  return '';
};

export const endDateFormatter = (props: any) => {
  const date = props?.data?.schedule.endDateTime;
  return formattedDate(date);
};

export const scheduleIDGetter = (params: ValueGetterParams) => {
  return truncateUUID(params?.data?.id);
};

export const contentTypeGetter = (params: ValueGetterParams) => {
  let capitalContentType;
  if (params.data.schedule?.segment?.contentType) {
    capitalContentType =
      params.data.schedule?.segment?.contentType.charAt(0).toUpperCase() +
      params.data.schedule?.segment?.contentType.slice(1);
  } else {
    capitalContentType = null;
  }
  return capitalContentType;
};

export const scheduleTypeGetter = (params: ValueGetterParams) => {
  let capitalScheduleType;
  if (params.data.schedule?.scheduleType) {
    capitalScheduleType =
      params.data.schedule?.scheduleType.charAt(0).toUpperCase() +
      params.data.schedule?.scheduleType.slice(1);
  } else {
    capitalScheduleType = '';
  }
  return capitalScheduleType;
};

export const createdDateGetter = (params: ValueGetterParams) => {
  let date = params?.data?.audit.createdAt;
  return formattedDate(date);
};

export const updatedDateGetter = (params: ValueGetterParams) => {
  let date = params?.data?.audit.updatedAt;
  return formattedDate(date);
};

export const updatedScheduleDateGetter = (params: ValueGetterParams) => {
  let date = params?.data?.schedule?.audit.updatedAt;
  return formattedDate(date);
};

export const componentCountGetter = (params: ValueGetterParams) => {
  if (params.data.schedule.components) {
    return params.data.schedule.components.length.toLocaleString();
  }
  return null;
};

export const combinedColumnGetter = (params: ValueGetterParams) => {
  const combinedFields = params.colDef.cellRendererParams.field.split('|');
  const foundField = combinedFields.filter((c: string) =>
    Object.keys(params.data.details.component).find((k) => k === c)
  );
  if (foundField.length > 0 && foundField[0]) {
    let value =
      params.data.details.component[
        foundField[0] as keyof typeof params.data.details.component
      ];
    if (
      [
        'textUsageType',
        'audioContentKind',
        'soundfieldGroupType',
        'type',
        'componentType'
      ].includes(foundField[0])
    ) {
      value = value.split(':').slice(-1)[0].toUpperCase();
    }
    return value;
  }
  return null;
};

export const componentDetailGetter = (params: ValueGetterParams) => {
  const columnType = combinedColumnGetter(params);
  switch (columnType) {
    case 'AUDIO':
      // type = Audio: Spoken Language (“Audio Content Kind”,“Soundfield Group Type”)
      const spokenLanguage = params.data.details.component.spokenLanguage;
      const audioContentKind = params.data.details.component.audioContentKind.split(':').slice(-1)[0].toUpperCase();
      const soundfieldGroupType = params.data.details.component.soundfieldGroupType.split(':').slice(-1)[0].toUpperCase();
      return `${spokenLanguage} (${audioContentKind}/${soundfieldGroupType})`;
    case 'TIMED-TEXT':
      // type = Timed-Text: Written Language (“Text Usage Type”)
      const writtenLanguage = params.data.details.component.writtenLanguage.split(':').slice(-1)[0];
      const textUsageType = params.data.details.component.textUsageType.split(':').slice(-1)[0].toUpperCase();
      return `${writtenLanguage} (${textUsageType})`;
    case 'VIDEO':
      // type = Video: blank
      return '';
    default:
      return '';
  }
};

export const componentStatusGetter = (params: ValueGetterParams) => {
  switch (params.colDef.cellRendererParams.field) {
    case 'scheduled':
      if (params.data?.status.scheduled?.status) {
        return capitalize(params.data?.status.scheduled?.status);
      }
      break;
    case 'delivered':
      if (params.data?.status.delivered?.status) {
        return capitalize(params.data?.status.delivered?.status);
      }
      break;
    case 'received':
      if (params.data?.status.received?.status) {
        return capitalize(params.data?.status.received?.status);
      }
      break;
    default:
      return 'No Status';
  }
  return null;
};

export const networkNameGetter = (params: ValueGetterParams) => {
  const network = params.data?.schedule?.network;
  const networks = useAppConfigManager.getState().queryNetworks;

  let networkName = '';

  if (network) {
    networks.forEach((c: Option) => {
      if (c.valueList.toLowerCase() === network.toLowerCase()) {
        networkName = c.label;
      }
    });
  }
  return networkName;
};

export const networkAbbreviationGetter = (params: ValueGetterParams) => {
  const network = params.data?.schedule?.network;
  const networks = useAppConfigManager.getState().queryNetworks;

  let networkAbbreviation = '';

  if (network) {
    networks.forEach((c: Option) => {
      if (c.valueList.toLowerCase() === network.toLowerCase()) {
        networkAbbreviation = c.valueList.toUpperCase();
      }
    });
  }
  return networkAbbreviation;
};

export const milestoneDateGetter = (params: ValueGetterParams) => {
  let type = params.colDef.cellRendererParams.activity ? 'Activity' : 'Process';
  let name = params.colDef.cellRendererParams.activity
    ? `${params.colDef.cellRendererParams.field}|${params.colDef.cellRendererParams.activity}`
    : params.colDef.cellRendererParams.field;
  const milestone = params.data.milestones?.find(
    (element: { type: string; name: string }) =>
      element.type === type && element.name === name
  );
  let date = milestone?.audit?.updatedAt ? milestone.audit.updatedAt : null;

  if (!date) {
    return null;
  }
  return formattedDate(date);
};

export const milestoneCodeGetter = (params: ValueGetterParams) => {
  let type = params.colDef.cellRendererParams.activity ? 'Activity' : 'Process';
  let name = params.colDef.cellRendererParams.activity
    ? `${params.colDef.cellRendererParams.field}|${params.colDef.cellRendererParams.activity}`
    : params.colDef.cellRendererParams.field;

  const milestone = params.data.milestones?.find(
    (element: { type: string; name: string }) =>
      element.type === type && element.name === name
  );

  if (milestone && milestone?.codes !== null && milestone.codes.length > 0) {
    const codes = milestone.codes.find((element: any) => 'code' in element);
    if (codes.code) {
      return codes.code;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const isLiveGetter = (params: ValueGetterParams) => {
  if (params.data?.schedule?.segment) {
    // Error if segment null
    if ('isLive' in params.data?.schedule?.segment) {
      const isLiveString = params.data.schedule.segment.isLive.toLocaleString();
      return isLiveString.charAt(0).toUpperCase() + isLiveString.slice(1);
    }
  }
  return null;
};

export const feedGetter = (params: ValueGetterParams) => {
  if (params.data?.schedule?.feed) {
    return (
      params.data.schedule.feed.charAt(0).toUpperCase() +
      params.data.schedule.feed.slice(1)
    );
  }
  return null;
};

export const titleGetter = (params: ValueGetterParams) => {
  let title = '';
  const titles = params.data.schedule.titles;
  if (!titles) return '--No Title--';
  if (titles.length > 0) {
    const distribution = titles.find(
      (title: { type: string }) => title.type === 'distribution-title'
    );
    title = distribution ? distribution.full : null;
    if (!title) {
      const main = titles.find((title: { type: string }) => title.type === 'main-title');
      title = main ? main.full : null;
    }
    if (!title) {
      const cat = titles.find((title: { type: string }) => title.type === 'cat-title');
      title = cat ? cat.full : '';
    }
    if (!title) {
      const fallback = titles.find(
        (title: { type: string }) => title.type === 'internal-wm-name'
      );
      title = fallback ? fallback.full : '';
    }
  }
  // if (!title) title = '--No Title--';
  return title;
};

export const seriesTitleGetter = (params: ValueGetterParams) => {
  let title = '';
  const titles = params.data.schedule.seriesTitles;
  if (!titles) return title;
  if (titles.length > 0) {
    const distribution = titles.find(
      (title: { type: string }) => title.type === 'distribution-title'
    );
    title = distribution ? distribution.full : null;
    if (!title) {
      const main = titles.find((title: { type: string }) => title.type === 'main-title');
      title = main ? main.full : null;
    }
    if (!title) {
      const cat = titles.find((title: { type: string }) => title.type === 'cat-title');
      title = cat ? cat.full : '';
    }
    if (!title) {
      const fallback = titles.find(
        (title: { type: string }) => title.type === 'internal-wm-name'
      );
      title = fallback ? fallback.full : '';
    }
    if (!title) {
      const cat = titles.find((title: { type: string }) => title.type === 'cat-title');
      title = cat ? cat.full : '';
    }
  }
  return title;
};

export const seasonTitleGetter = (params: ValueGetterParams) => {
  let title = '';
  const titles = params.data.schedule.seasonTitles;
  if (!titles) return title;
  if (titles.length > 0) {
    const distribution = titles.find(
      (title: { type: string }) => title.type === 'distribution-title'
    );
    title = distribution ? distribution.full : null;
    if (!title) {
      const main = titles.find((title: { type: string }) => title.type === 'main-title');
      title = main ? main.full : null;
    }
    if (!title) {
      const cat = titles.find((title: { type: string }) => title.type === 'cat-title');
      title = cat ? cat.full : '';
    }
    if (!title) {
      const fallback = titles.find(
        (title: { type: string }) => title.type === 'internal-wm-name'
      );
      title = fallback ? fallback.full : '';
    }
    if (!title) {
      const cat = titles.find((title: { type: string }) => title.type === 'cat-title');
      title = cat ? cat.full : '';
    }
  }
  return title;
};

export const entityTypeGetter = (params: ValueGetterParams) => {
  let capitalType;
  if (params.data.schedule.entityType) {
    capitalType =
      params?.data?.schedule?.entityType?.charAt(0)?.toUpperCase() +
      params?.data?.schedule?.entityType?.slice(1);
  } else {
    capitalType = null;
  }
  return capitalType;
};

export const latestCommentGetter = (params: ValueGetterParams) => {
  const comment = params.data.latestComment;
  if (comment) {
    return `${comment.comment} | ${comment.audit?.createdBy} | ${formattedDate(
      comment.audit?.createdAt
    )}`;
  }
  return '';
};

export const relatedAcquisitionStatusGetter = (params: ValueGetterParams) => {
  const GetStatusCode = useStatusCodeStore.getState().GetStatusCode;
  const footprintStatus = params?.data?.related?.acquisition;
  let status;
  if (footprintStatus) {
    if (footprintStatus.length > 0) {
      const acquireStatus = footprintStatus[0].status?.['acquire-footprint'];
      status = acquireStatus.status ? acquireStatus.status : null;
      if (status !== null) status = status.charAt(0).toUpperCase() + status.slice(1);
      if (acquireStatus?.codes && acquireStatus?.codes.length > 0) {
        let sc = GetStatusCode(acquireStatus.codes[0].code);
        if (sc.status && sc.badgeText !== '') {
          status =
            sc.status.charAt(0).toUpperCase() + sc.status.slice(1) + ' - ' + sc.badgeText;
        }
      }
    }
  }
  return status;
};

export const acquisitionUpdatedDateGetter = (params: ValueGetterParams) => {
  const footprintStatus = params?.data?.related?.acquisition;
  if (footprintStatus) {
    if (footprintStatus.length > 0) {
      const acquireStatus = footprintStatus[0].status?.['acquire-footprint'];
      const date = acquireStatus?.audit?.updatedAt;
      if (date) {
        return formattedDate(date);
      }
    }
  }
  return null;
};

export const acquisitionCodeGetter = (params: ValueGetterParams) => {
  const footprintStatus = params?.data?.related?.acquisition;
  if (footprintStatus) {
    if (footprintStatus.length > 0) {
      const acquireStatus = footprintStatus[0].status?.['acquire-footprint'];
      if (
        acquireStatus &&
        acquireStatus?.codes !== null &&
        acquireStatus.codes.length > 0
      ) {
        const codes = acquireStatus.codes.find((element: any) => 'code' in element);
        if (codes.code) {
          return codes.code;
        } else {
          return null;
        }
      }
    }
  } else {
    return null;
  }
};

export const acquisitionDetailGetter = (params: ValueGetterParams) => {
  const footprintStatus = params?.data?.related?.acquisition;
  if (footprintStatus) {
    if (footprintStatus.length > 0) {
      const acquireStatus = footprintStatus[0].status?.['acquire-footprint'];
      if (
        acquireStatus &&
        acquireStatus?.codes !== null &&
        acquireStatus.codes.length > 0
      ) {
        const codes = acquireStatus.codes.find((element: any) => 'code' in element);
        if (codes.detail && codes.data) {
          return `${codes.detail} - ${codes.data}`;
        } else if (codes.detail) {
          return codes.detail;
        }
      }
    }
  } else {
    return null;
  }
};

const findPSEComplianceTask = (tasks: any[]) => {
  const task = tasks.find((t: { type: string; eventSource: string }) => {
    return t.type === 'mezzcreation';
  });
  return task;
};

export const complianceGetter = (params: any) => {
  const tasks = params.data ? params.data.tasks : params.tasks;
  if (!tasks) return 'N/A';
  if (tasks) {
    if (tasks?.length > 0) {
      const task = findPSEComplianceTask(tasks);
      if (task) {
        return getPseComplianceStatus(task);
      }
    } else {
      return 'N/A';
    }
  }
  return 'N/A';
};
const dynamicRangeGetter = (task: any, range: string) => {
  let fulfilledVideoComponents;
  if (task?.details?.fulfilledVideoComponents?.length > 0) {
    fulfilledVideoComponents = task.details?.fulfilledVideoComponents.find(
      (i: { dynamicRange: string }) =>
        i.dynamicRange && i.dynamicRange.toUpperCase().includes(range)
    );
    return fulfilledVideoComponents;
  }
};
const taskGetter = (tasks: any[]) => {
  return tasks.find((t: { type: string; eventSource: string }) => {
    return (
      (t.type === 'mezzcreation' || t.type === 'pickpackrules') &&
      t.eventSource === 'fulfillment-workflow'
    );
  });
};
export const hdrFrameSizeGetter = (params: any) => {
  let fulfilledVideoComponents;
  const tasks = params.data ? params.data.tasks : params.tasks;
  if (tasks?.length > 0) {
    const task = taskGetter(tasks);
    fulfilledVideoComponents = dynamicRangeGetter(task, 'HDR');
    if (fulfilledVideoComponents?.frameSize)
      return `${fulfilledVideoComponents?.frameSize?.width} x ${fulfilledVideoComponents?.frameSize?.height}`;
    else return '';
  }
  return '';
};
export const sdrFrameSizeGetter = (params: any) => {
  let fulfilledVideoComponents;
  const tasks = params.data ? params.data.tasks : params.tasks;
  if (tasks?.length > 0) {
    const task = taskGetter(tasks);
    fulfilledVideoComponents = dynamicRangeGetter(task, 'SDR');
    if (fulfilledVideoComponents?.frameSize)
      return `${fulfilledVideoComponents?.frameSize?.width} x ${fulfilledVideoComponents?.frameSize?.height}`;
    else return '';
  }
  return '';
};
export const hdrPictureAspectRatioGetter = (params: any) => {
  let fulfilledVideoComponents;
  const tasks = params.data ? params.data.tasks : params.tasks;
  if (tasks?.length > 0) {
    const task = taskGetter(tasks);
    fulfilledVideoComponents = dynamicRangeGetter(task, 'HDR');
    if (fulfilledVideoComponents?.pictureAspectRatio)
      return `${fulfilledVideoComponents?.pictureAspectRatio}`;
    else return '';
  }
  return '';
};
export const sdrPictureAspectRatioGetter = (params: any) => {
  let fulfilledVideoComponents;
  const tasks = params.data ? params.data.tasks : params.tasks;
  if (tasks?.length > 0) {
    const task = taskGetter(tasks);
    fulfilledVideoComponents = dynamicRangeGetter(task, 'SDR');
    if (fulfilledVideoComponents?.pictureAspectRatio)
      return `${fulfilledVideoComponents?.pictureAspectRatio}`;
    else return '';
  }
  return '';
};
export const hdrScanningFormatGetter = (params: any) => {
  let fulfilledVideoComponents;
  const tasks = params.data ? params.data.tasks : params.tasks;
  if (tasks?.length > 0) {
    const task = taskGetter(tasks);
    fulfilledVideoComponents = dynamicRangeGetter(task, 'HDR');
    if (fulfilledVideoComponents?.scanningFormat)
      return `${fulfilledVideoComponents?.scanningFormat
        ?.split(':')
        .slice(-1)[0]
        .toUpperCase()}`;
    else return '';
  }
  return '';
};
export const sdrScanningFormatGetter = (params: any) => {
  let fulfilledVideoComponents;
  const tasks = params.data ? params.data.tasks : params.tasks;
  if (tasks?.length > 0) {
    const task = taskGetter(tasks);
    fulfilledVideoComponents = dynamicRangeGetter(task, 'SDR');
    if (fulfilledVideoComponents?.scanningFormat)
      return `${fulfilledVideoComponents?.scanningFormat
        ?.split(':')
        .slice(-1)[0]
        .toUpperCase()}`;
    else return '';
  }
  return '';
};

export const ratingsGetter = (params: any) => {
  const schedule = params.data ? params.data.schedule : params.schedule;
  if (schedule?.ratings?.length > 0) {
    return getRatings(schedule.ratings);
  }
  return null;
};

import { Modal, List, Stack, Group } from '@mantine/core';
import { useSavedViewsStore } from '../SavedViews/store';
import { useEffect, useState } from 'react';

export const NotFoundCodesModal = () => {
  const notFoundCodes = useSavedViewsStore((state) => state.notFoundCodes);
  const [showNotFoundCodes, setShowNotFoundCodes] = useState(false);

  useEffect(() => {
    if (notFoundCodes.length > 0) {
      setShowNotFoundCodes(true);
    }
  }, [notFoundCodes]);

  return (
    <Modal
      title="IDs Not Found:"
      opened={showNotFoundCodes}
      onClose={() => {
        setShowNotFoundCodes(false);
      }}
      size="auto"
    >
      <Group style={{ margin: 10 }}>
        <Stack>
          <List>
            {notFoundCodes.map((code, index) => (
              <List.Item key={`${code}-${index}`}>{code}</List.Item>
            ))}
          </List>
        </Stack>
      </Group>
    </Modal>
  );
};

export default NotFoundCodesModal;

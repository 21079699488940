import { useEffect, useState } from 'react';
import { fetchPlaylistEntriesData } from '../../api/fetchPlaylistEntriesData';
import { ColumnState, GridOptions, GroupCellRendererParams } from 'ag-grid-community';
import { DetailCellRendererGrid } from './_detailCellRendererGrid';

export const DetailCellRenderer = (params: GroupCellRendererParams, gridOptions: GridOptions) => {
  const [entries, setEntries] = useState([])
  const [columns, setColumns] = useState<ColumnState[]>([])
  // const [_columnsLeft, setColumnsLeft] = useState<ColumnState[]>([])
  // const [_columnsRight, setColumnsRight] = useState<ColumnState[]>([])

  async function fetchData() {
    const result = await fetchPlaylistEntriesData(
      params.data.offering.id
    );

    if (result && result.length > 0){
      const columnState = gridOptions.columnApi?.getColumnState()
      let formattedColumns: ColumnState[] = []
      // let formattedColumnsLeft: ColumnState[] = []
      // let formattedColumnsRight: ColumnState[] = []

      const pushColumns = (column: ColumnState) => {
        // if (params.pinned === 'left' && column.pinned === 'left') {
        //   formattedColumnsLeft.push(column)
        // } else if (params.pinned === 'right' && column.pinned === 'right') {
        //   formattedColumnsRight.push(column)
        // } else {
          if (!column.pinned){
            formattedColumns.push(column)
          } else {
            column.pinned = false
            formattedColumns.push(column)
          }
        // }
      }

      columnState?.forEach(column=>{
        if (column.colId === 'offering.entries'){
          column.colId = 'offering.playOrder'
          column.sort = "asc"
          pushColumns(column)
        } else if (column.colId !== 'offering.playOrder'){
          column.sort = null
          pushColumns(column)       
        }
      })
      setEntries(result)
      setColumns(formattedColumns)
      // setColumnsLeft(formattedColumnsLeft)
      // setColumnsRight(formattedColumnsRight)
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  let content

  if (params.pinned === 'left') {
    // content = (<DetailCellRendererGrid gridOptions={gridOptions} data={entries} columnState={columnsLeft} pinned='left'/>)
  } else if (params.pinned === 'right') {
    // content = (<DetailCellRendererGrid gridOptions={gridOptions} data={entries} columnState={columnsRight} pinned='right'/>)
  } else {
    content = (<DetailCellRendererGrid gridOptions={gridOptions} data={entries} columnState={columns} pinned=''/>)
  }

  return (
    <>
      {entries.length > 0 ? content : <></>}
    </>
  );
};

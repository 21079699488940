import { Box, Grid, Group, Space, Text, TextInput, useComputedColorScheme } from '@mantine/core';
import { AdminListSortable } from './_adminListSortable';
import { DropDownForm } from './_dropdownForm';
import { iItemType, iSelectDataSource } from '../../components/ListSortable/models';
import { useAdminModuleStore } from './store';
import { useAppConfigManager } from '../../components/AppConfigs';
import { useEffect, useRef, useState } from 'react';
import { AgGridContainer, Button, ContainerComponent } from '../../components';
import { AgGridReact } from 'ag-grid-react';
import { dropDownsColumnDef } from './_colDefs';
import { CellClickedEvent } from 'ag-grid-community';
import { ChevronRight } from 'tabler-icons-react';
import { HierarchyPathType } from './models';

export const currentListInDDsHierarchy = (
  list: iSelectDataSource[],
  hierarchyPath: HierarchyPathType[],
  i: number = 0
): iSelectDataSource[] => {
  const ddChildren = list?.find(
    (a) => a?.value?.toLowerCase() === hierarchyPath?.[i + 1]?.value?.toLowerCase()
  )?.children;

  if (list && ddChildren)
    return currentListInDDsHierarchy(ddChildren, hierarchyPath, i + 1);

  return list;
};

export const DropDownMenus = () => {
  const gridRef = useRef<AgGridReact>(null);
  const {
    SetItem,
    SetDropdownList,
    SetNewDropdown,
    newDropdown,
    hierarchyPath,
    SetHierarchyPath
  } = useAdminModuleStore();
  const colorScheme = useComputedColorScheme('light');
  const gridCSS =
    colorScheme === 'dark' ? 'ag-theme-alpine-dark' : 'ag-theme-alpine';
  const { GetConfig, dropdowns } = useAppConfigManager();
  const [dropdownId, set_dropdownId] = useState('');
  const [showGrid, set_showGrid] = useState(true);
  const [loading, set_loading] = useState(true);
  const [dropdownIdError, set_dropdownIdError] = useState('');

  const currentDD = [...hierarchyPath]?.reverse?.()?.[0]?.value;

  const prevHierarchyPath = hierarchyPath?.slice(0, hierarchyPath.length - 1);

  const onCellClicked = (props: CellClickedEvent) => {
    SetNewDropdown(false);
    set_dropdownId(props.data.dropdown);
    SetDropdownList(
      hierarchyPath.length <= 1
        ? dropdowns[props.data.dropdown]
        : (currentListInDDsHierarchy(
            dropdowns[hierarchyPath?.[0]?.value],
            prevHierarchyPath
          )?.find((a) => a.value === props.data.dropdown)?.children as iItemType[])
    );
    SetItem({} as iItemType);
    if (hierarchyPath.length <= 1) {
      SetHierarchyPath([{ value: props.data.dropdown, label: props.data.dropdown }]);
    } else {
      SetHierarchyPath([
        ...prevHierarchyPath,
        { value: props.data.dropdown, label: props.data.label }
      ]);
    }
  };

  const gridOptions = {
    onCellClicked,
    columnDefs: [
      {
        ...dropDownsColumnDef,
        headerName: hierarchyPath?.[hierarchyPath?.length - 2]?.value ?? 'DropDowns',
        valueGetter: (params: { data: { label: string; dropdown: string } }) => {
          return params.data?.label ?? params.data?.dropdown;
        }
      }
    ],
    onCellDoubleClicked: onCellClicked
  };

  const getDropdowns = async () => {
    set_loading(true);
    try {
      await GetConfig('dropdown');
      set_loading(false);
    } catch (err) {
      console.error('Error Dropdowns: ', err);
    }
    0;
  };

  useEffect(() => {
    getDropdowns();
  }, []);

  const createNewDropdown = () => {
    SetHierarchyPath([]);
    SetDropdownList([]);
    SetNewDropdown(true);
    set_dropdownId('');
    set_dropdownIdError('');
    SetItem({ label: '', value: '' });
    reloadGrid();
  };

  const backInHierarchy = (index: number) => {
    const itemClicked = hierarchyPath?.slice(0, index + 1);
    SetDropdownList(
      currentListInDDsHierarchy(dropdowns[hierarchyPath[0]?.value], itemClicked)
    );
    SetItem({} as iItemType);
    SetHierarchyPath(itemClicked);
    reloadGrid();
  };

  const rows = () => {
    if (hierarchyPath.length > 1) {
      return currentListInDDsHierarchy(
        dropdowns[hierarchyPath[0]?.value],
        prevHierarchyPath
      )?.map((dd) => ({ dropdown: dd.value, label: dd.label }));
    }
    return Object.keys(dropdowns).map((dd) => ({ dropdown: dd }));
  };

  const reloadGrid = () => {
    set_showGrid(false);
    setTimeout(() => set_showGrid(true), 1);
  };

  return (
    <Box style={{ width: '80rem' }}>
      <ContainerComponent
        testid="StatusCodeDictionary"
        title={'Dropdowns Editor'}
        rightSection={<></>}
      >
        <Grid>
          <Grid.Col span={3}>
            <AgGridContainer>
              <div className={gridCSS} style={{ height: 700, width: '100%' }}>
                {showGrid && (
                  <AgGridReact
                    ref={gridRef}
                    gridOptions={gridOptions}
                    rowData={rows()}
                    getRowStyle={(params) => {
                      if (params?.data?.dropdown === currentDD) {
                        return { background: '#9bc9f0' };
                      }
                    }}
                  />
                )}
              </div>
            </AgGridContainer>
            <Space h="md" />
          </Grid.Col>
          <Grid.Col span={9}>
            <Grid>
              {hierarchyPath?.length > 1 && (
                <Grid.Col span={12}>
                  <p style={{ margin: '0' }}>
                    <strong>Breadcrumbs:</strong>{' '}
                    {hierarchyPath.map((item, index) => (
                      <span>
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={() => backInHierarchy(index)}
                        >
                          {item?.label}
                        </span>
                        {hierarchyPath.length - 1 > index && (
                          <ChevronRight size={18} style={{ verticalAlign: 'text-top' }} />
                        )}
                      </span>
                    ))}
                  </p>
                </Grid.Col>
              )}
              {!!currentDD || newDropdown ? (
                <Grid.Col span={12}>
                  <Group style={{ width: '100%', gap: 0, margin: '0' }}>
                    <label htmlFor="dropdownId" style={{ margin: '0 5px 0 0' }}>
                      <strong>Dropdown id:</strong>
                    </label>
                    {newDropdown ? (
                      <div>
                        <TextInput
                          id="dropdownId"
                          style={
                            dropdownIdError
                              ? { border: '1px solid red', borderRadius: '2%' }
                              : {}
                          }
                          value={dropdownId}
                          onChange={(e) => set_dropdownId(e.target.value)}
                        />
                        {!!dropdownIdError && (
                          <p
                            style={{
                              color: '#fa5252',
                              fontSize: 'calc(0.75rem - 0.125rem)',
                              margin: 0,
                              width: '100%'
                            }}
                          >
                            {dropdownIdError}
                          </p>
                        )}
                      </div>
                    ) : (
                      <Text>{currentDD}</Text>
                    )}
                  </Group>
                </Grid.Col>
              ) : (
                <Button
                  testid="admin-dropDownForm-new"
                  noIcon
                  style={{
                    width: 150,
                    margin: 10
                  }}
                  loading={loading}
                  onClick={createNewDropdown}
                >
                  New dropdown
                </Button>
              )}
              <Grid.Col span={4}>
                <AdminListSortable
                  reloadGrid={reloadGrid}
                  loading={loading}
                  set_loading={set_loading}
                
                
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <DropDownForm
                  dropdownId={dropdownId}
                  getDropdowns={getDropdowns}
                  set_dropdownIdError={set_dropdownIdError}
                  loading={loading}
                  set_loading={set_loading}
                />
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      </ContainerComponent>
    </Box>
  );
};
import { useViewportSize } from '@mantine/hooks';
import DetailGrid from '../_detailGrid';

interface iViewsDetailProps {
  data: any;
  parents: string[];
  children: string[];
  footprint?: string
  statusObject: boolean
}

// The ViewsDetail component holds the DetailGrid
export const ViewsDetail = (props: iViewsDetailProps) => {
  const { data, parents, children, footprint, statusObject } = props;
  const { height } = useViewportSize();
  const gridHeight = height - 120;
  const buttonPadding = 40;

  return <DetailGrid footprint={footprint ? footprint : ''} data={data} parents={parents} children={children} statusObject={statusObject} modalHeight={gridHeight - buttonPadding} />;
};

export default ViewsDetail;

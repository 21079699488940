import { iGridComponent, iPersonalHUD, iWidget } from './models';
import StatusChart from '../Widgets/_statusChart';

export const makePersonalHUDLayout = (personalHUD: iPersonalHUD) => {
  let gridComponents: iGridComponent[] = [];
  personalHUD.widgets.forEach((item: iWidget) => {
    const cidx = gridComponents.findIndex((obj) => obj.key === item.i);
    if (cidx < 0) {
      gridComponents.push({
        key: item.i,
        component: <StatusChart widget={item} />
      });
    }
  });
  return gridComponents;
};

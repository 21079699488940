import { Option } from './../../components/CascaderNetwork/_networks';
import { useConfigStore } from '../../app/store';
import useAxios from '../../services/axios/axios';
import dayjs from 'dayjs';
import { iSearch } from '../../containers/Search/models';
import { useAppConfigManager } from '../../components/AppConfigs';
import { useSearchStore } from '../../containers/Search/store';
import { getStatusCodeString } from '../../utils/utilities';

export const fetchLinearRowData = async (
  startDate: Date | null,
  endDate: Date | null,
  searchState: iSearch,
  limit: number,
  page: number
) => {
  const { post } = useAxios();
  const baseURL = useConfigStore.getState().config.baseApiGatewayUrl + '/graphql/search';

  const query = await constructQuery(startDate, endDate, searchState, limit, page);
  const schema = {
    query: query
  };

  const eventData = await post({
    url: baseURL,
    data: JSON.stringify(schema)
  });
  if (!eventData) {
    return { rowData: [], rowCount: 0 };
  } else {
    let totalCount = eventData?.data?.schedule?.count;
    return { rowData: eventData?.data?.schedule?.records, rowCount: totalCount };
  }
};

const constructQuery = async (
  startDate: Date | null,
  endDate: Date | null,
  searchState: iSearch,
  limit: number,
  page: number
) => {
  const queryFilters = constructFilters(startDate, endDate, searchState, limit, page);
  let queryReturn = await useSearchStore
    .getState()
    .queries.find((q) => q.id.includes('footprint-linear'));
  if (!queryReturn) {
    const LoadAPI = useSearchStore.getState().LoadAPI;
    await LoadAPI();
    queryReturn = await useSearchStore
      .getState()
      .queries.find((q) => q.id.includes('footprint-linear'));
  }
  const query = `query {
            schedule(
              ${queryFilters}
            ) {
              ${queryReturn?.query}
              count
            }
          }`;
  return query;
};

const constructFilters = (
  startDate: Date | null,
  endDate: Date | null,
  filters: iSearch,
  limit: number,
  page: number
) => {
  const milestoneStatuses =
    filters.MilestoneStatus?.length > 0
      ? `[${filters.MilestoneStatus?.map((status: any) => `"${status}"`)}]`
      : '[]';
  const milestones =
    filters.Milestone?.length > 0
      ? `[${filters.Milestone?.map((status: any) => `"${status}"`)}]`
      : '[]';
  const milestoneString = `milestones:{
      name: ${milestones},
      status: ${milestoneStatuses}
    }`;

  let partialSearchInput: string = '';
  if (filters.SearchInput) {
    let searchInputs;
    if (filters.SearchInputField === 'keyword') {
      let copy = filters.SearchInput;
      let newInput = copy.replaceAll('"', '\\"');
      searchInputs = newInput;
    } else {
      searchInputs = filters.SearchInput.split(' ');
      if (searchInputs.length > 1) {
        searchInputs = searchInputs.join('","');
      }
    }
    partialSearchInput = searchInputs.toString();
  }

  let csId = '';
  if (filters.SearchInputField && filters.SearchInputField === 'csId') {
    let input = '';
    let searchInputs = filters.SearchInput.split(' ');
    if (searchInputs.length > 1) {
      input = searchInputs.join('","');
    } else {
      input = searchInputs[0];
    }
    csId = `
    csId: {
      id: ["${input}"]
    }
    `;
  }

  let suppressRepeats = '';
  // let networkList: string[] = []
  if (filters.SuppressRepeats) {
    if (filters.SuppressRepeats === 'full') {
      suppressRepeats = `suppressBy: full`;
    } else {
      suppressRepeats = `suppressBy: network`;
      // networkList = networks.map(n=>n.value)
    }
  }

  let idPagination;
  idPagination = `page: ${page}`;

  const scheduleObject = constructScheduleObject(startDate, endDate, filters);
  let partialObject = '';
  if (partialSearchInput && filters.SearchInputField === 'keyword') {
    partialObject = constructPartialLinearObject(partialSearchInput);
  }

  let auditObject = `audit:{}`;
  if (filters.DateField === 'lastUpdated' && filters.dateEnabled) {
    auditObject = `audit:{
            updatedAtFrom:"${startDate!.toISOString()}",
            updatedAtTo:"${endDate!.toISOString()}"
          }`;
  }

  const taskValueMapper = useAppConfigManager.getState().taskValueMapper;
  const statusCodeString = getStatusCodeString(filters.StatusCodes, taskValueMapper);
  const queryFilters = `filters: {
        ${auditObject}
        ${milestoneString}
        ${statusCodeString}
        ${scheduleObject}
        ${partialObject}
        ${csId}
        ${suppressRepeats}
        related:[acquisition]
        limit: ${limit}
        ${idPagination}
    }`;
  return queryFilters;
};

export const constructScheduleObject = (
  startDate: Date | null,
  endDate: Date | null,
  filters: iSearch
) => {
  let scheduleObject: any = {};

  if (filters.Network.length > 0) {
    const { queryNetworks } = useAppConfigManager.getState();
    let value: any[] = [];
    filters.Network.forEach((network: string[]) => {
      const option = queryNetworks.find(
        (o: Option) => o.value === network[network.length - 1]
      );
      if (option) {
        option.valueList.split(',').forEach((id: string) => {
          value.push(id);
        });
      } else {
        console.error('Could not find network option within networks!: ', network);
      }
    });
    const filterString = `network:[${value.map((i: any) => `"${i}"`)}]`;
    scheduleObject.network = filterString;
  }

  if (filters.Feed) scheduleObject.feed = `feed: "${filters.Feed}"`;
  if (filters.ScheduleType) {
    scheduleObject.scheduleType = `scheduleType: ["${filters.ScheduleType}"]`;
  }

  if (startDate && endDate && filters.DateField === 'txDate') {
    scheduleObject.startDateTimeFrom = `startDateTimeFrom:"${dayjs(
      startDate
    ).toISOString()}"`;
    scheduleObject.startDateTimeTo = `startDateTimeTo:"${dayjs(endDate).toISOString()}"`;
  }
  if (startDate && endDate && filters.DateField === 'endDate') {
    scheduleObject.endDateTimeFrom = `endDateTimeFrom:"${dayjs(
      startDate
    ).toISOString()}"`;
    scheduleObject.endDateTimeTo = `endDateTimeTo:"${dayjs(endDate).toISOString()}"`;
  }

  if (filters.DateField === 'createdAt' && filters.dateEnabled) {
    // TODO: not working with the dev API at the moment. Need to investigate with backend.
    scheduleObject.audit = `audit:{
            createdFrom:"${dayjs(startDate).toISOString()}",
            createdTo:"${dayjs(endDate).toISOString()}",
          }`;
  }

  const excludedIds = [
    'keyword',
    'csId',
    'versionId',
    'entityId'
  ]

  const ids = useAppConfigManager
    ?.getState()
    ?.GetSearchInputs('footprint-linear')
    ?.map((id) => id.value)
    .filter(i=>!excludedIds.includes(i));

  if (filters.SearchInputField && filters.SearchInputField !== 'keyword') {
    let input = '';
    let searchInputs = filters.SearchInput.split(' ');
    if (searchInputs.length > 1) {
      input = searchInputs.join('","');
    } else {
      input = searchInputs[0];
    }
    if (filters.SearchInputField === 'scheduleId') {
      scheduleObject.id = `id: ["${input}"]`;
    } else if (ids.includes(filters.SearchInputField)) {
      scheduleObject[
        filters.SearchInputField
      ] = `${filters.SearchInputField}:{id: ["${input}"]}`;
    }
  }

  let segmentObject: any = {};
  if (filters.SearchInputField) {
    if (filters.SearchInputField === 'versionId') {
      let input = '';
      let searchInputs = filters.SearchInput.split(' ');
      if (searchInputs.length > 1) {
        input = searchInputs.join('","');
      } else {
        input = searchInputs[0];
      }
      segmentObject.versionId = `versionId: {
        id: ["${input}"]
      }`;
    }
  }

  if (
    filters.DateField === 'segmentDate' &&
    filters.dateEnabled &&
    dayjs(startDate).isValid() &&
    dayjs(endDate).isValid()
  ) {
    segmentObject.startDateFrom = `
    startDateFrom:"${startDate!.toISOString()}"
    `;
    segmentObject.startDateTo = `
    startDateTo:"${endDate!.toISOString()}"
    `;
  }

  if (!filters.LinearExpired) {
    segmentObject.startDateFrom = `
    startDateFrom:"${new Date().toISOString()}"
    `;
    let startDateTo;
    if (endDate && filters.DateField !== 'none') {
      if (dayjs(endDate).isBefore(dayjs(new Date()))) {
        startDateTo = dayjs('2099-01-01T11:59:59.999Z').toISOString();
      } else {
        startDateTo = dayjs(endDate).toISOString();
      }
    } else {
      startDateTo = dayjs('2099-01-01T11:59:59.999Z').toISOString();
    }

    segmentObject.startDateTo = `
    startDateTo:"${startDateTo}"
    `;
  }

  if (filters.ContentType) {
    if (filters.ContentType.length > 0) {
      const filterString = `contentType:[${filters.ContentType.map(
        (i: any) => `"${i}"`
      )}]`;
      segmentObject.contentType = filterString;
    }
  }

  if (filters.IsLive === 'true') {
    const filterString = `isLive: true`;
    segmentObject.isLive = filterString;
  } else if (filters.IsLive === 'false') {
    const filterString = `isLive: false`;
    segmentObject.isLive = filterString;
  }

  if (Object.keys(segmentObject).length > 0) {
    let segmentString = '';
    Object.keys(segmentObject).forEach((k: string) => {
      segmentString = segmentString + '\n' + segmentObject[k];
    });
    scheduleObject.segment = `\nsegment: {
      ${segmentString}
    }`;
  }

  if (Object.keys(scheduleObject).length > 0) {
    let scheduleString = '';
    Object.keys(scheduleObject).forEach((k: string) => {
      scheduleString = scheduleString + '\n' + scheduleObject[k];
    });
    return `\nschedule: {
      ${scheduleString}
    }`;
  }
  return '';
};

export const constructPartialLinearObject = (searchInput: string) => {
  let scheduleObject: any = {};
  let segmentObject: any = {};

  let csId = '';
  if (searchInput.length > 4 || !searchInput.split('').includes(' ')) {
    csId = `
    csId: {
      id: ["${searchInput}"]
    }
    `;

    scheduleObject.id = `id: ["${searchInput}"]`;

    segmentObject.versionId = `
    versionId: {
      id: ["${searchInput}"]
    }
    `;
  }

  const partialTitles = ['titles', 'seriesTitles', 'seasonTitles'];

  const excludedIds = [
    'keyword', 
    'scheduleId', 
    'versionId', 
    'csId',
    'entityId'
  ];
  
  const partialIds = useAppConfigManager
    ?.getState()
    ?.GetSearchInputs('footprint-linear')
    ?.map((id) => id.value)
    .filter((i) => !excludedIds.includes(i));

  if (searchInput.length > 4 || !searchInput.split('').includes(' ')) {
    partialIds.map((i: string) => {
      scheduleObject[
        i as keyof typeof scheduleObject
      ] = `\n${i}:{id: ["${searchInput}"]}`;
    });
  }

  segmentObject.title = `title: ["${searchInput}"]`;

  if (Object.keys(segmentObject).length > 0) {
    let segmentString = '';
    Object.keys(segmentObject).forEach((k: string) => {
      segmentString = segmentString + '\n' + segmentObject[k];
    });
    scheduleObject.segment = `\nsegment: {
      ${segmentString}
    }`;
  }

  partialTitles.map((t: string) => {
    const partialTitle = `
      ${t}:{
        full:["${searchInput}"]
        type:["main-title", "internal-wm-name", "distribution-title"],
        language:"en-US"
      }`;
    scheduleObject[t as keyof typeof scheduleObject] = partialTitle;
  });

  if (Object.keys(scheduleObject).length > 0) {
    let scheduleString = '';
    Object.keys(scheduleObject).forEach((k: string) => {
      scheduleString = scheduleString + '\n' + scheduleObject[k];
    });
    scheduleString = `\nschedule: {
      ${scheduleString}
    }`;
    return `
      partialSearch:{
        ${csId}
        ${scheduleString}
      }
    `;
  }
  return '';
};

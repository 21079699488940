import { Box, Divider, Group, Stack, Text, useComputedColorScheme, useMantineTheme } from '@mantine/core';
import { iComment } from './models';
import dayjs from 'dayjs';
import { Trash } from 'tabler-icons-react';
import { CommentsService } from '../../services/comments/commentsService';
import { useHover } from '@mantine/hooks';
import { useConfigStore } from '../../app/store';

export const CommentRow = (props: { data: iComment; rowId: string }) => {
  const { hovered, ref } = useHover();
  const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');
  const email = useConfigStore((state) => state.userData.email);

  const CheckEmail = (userEmail:string | undefined, commentEmail:string) => {
    if (!userEmail || !commentEmail) return false
    const userEmailName = userEmail.split('@')[0]
    const commentEmailName = commentEmail.split('@')[0]
    if (userEmailName === commentEmailName) {
      return true
    }
    return false
  }

  const dt = dayjs(props.data.audit.createdAt).format('MMM DD, YYYY (hh:mm a | [EST])');
  return (
    <Box style={{ borderRadius: 12, padding: 8 }}>
      <Stack>
        <Box style={{ width: '300px', wordWrap: 'break-word' }}>
          <Text size="sm">{props.data.comment}</Text>
        </Box>
        <Group justify="space-between" style={{ marginTop: -12, marginBottom: 6 }}>
          <Text size="xs">{dt}</Text>
          <Text size="xs">{props.data.audit.createdBy}</Text>
          {CheckEmail(email, props.data.audit.createdBy) && (
            <Box ref={ref}>
              {hovered ? (
                <Trash
                  size={15}
                  color="red"
                  onClick={() =>
                    CommentsService.deleteComment(
                      props.data.id,
                      props.data._id,
                      props.rowId
                    )
                  }
                ></Trash>
              ) : (
                <Trash
                  size={15}
                  color={
                    colorScheme === 'dark'
                      ? theme.colors.dark[3]
                      : theme.colors.dark[8]
                  }
                  onClick={() =>
                    CommentsService.deleteComment(
                      props.data.id,
                      props.data._id,
                      props.rowId
                    )
                  }
                ></Trash>
              )}
            </Box>
          )}
        </Group>
      </Stack>
      <Divider h="sm" />
    </Box>
  );
};

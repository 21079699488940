import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale
} from 'chart.js';
import 'chart.js/auto';
import { Pie } from 'react-chartjs-2';
import { StatusColor } from '../../../components';
import {
  Box,
  Divider,
  Group,
  Modal,
  Select,
  Tooltip as MTooltip,
  useMantineTheme,
  Text,
  TextInput,
  Flex,
  useComputedColorScheme,
  lighten,
  darken
} from '@mantine/core';
import { Button } from '../../../components/Button';
import { Plus } from 'tabler-icons-react';
import { HUDService } from '../../../services/hud/hudService';
import { iPersonalHUD, iWidget } from '../../../components/Hud/state/models';
import { v4 as uuidv4 } from 'uuid';
import { useViewStore } from '../store';
import { useSearchStore } from '../../Search/store';
import { globalColors as colors } from '../../../theme/globalColors';
import { useStatusCountsStore } from '../../StatusCounts';

interface iGridStatusChartProps {
  footprint: string;
  milestones: { value: string; label: string }[];
}
export const GridStatusChart = (props: iGridStatusChartProps) => {
  const [arrayData, setArrayData] = useState<any>([]);
  const [innerArrayData, setInnerArrayData] = useState<any>([]);
  const [addWidgetModal, setAddWidgetModal] = useState(false);
  const canvasRef = useRef(null);
  const statusCount = useStatusCountsStore((state) => state.statusCounts);
  const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');
  const gridCurrentAPI = useViewStore((state) => state.grid?.current?.api);
  const statusColor = StatusColor();
  // const { classes } = useStyles();
  const statusField = useStatusCountsStore((state) => state.statusField);
  const SetStatusField = useStatusCountsStore((state) => state.SetStatusField);
  const { isSearching } =
    useSearchStore();

  // Name adjustments to help when swapping footprints
  let fieldName = statusField.includes('footprint') ? 'Overall Status' : statusField;
  useEffect(() => {
    const milestoneFound = props.milestones.find((m) => m.value === statusField);
    if (milestoneFound === undefined) {
      const footprintMilestone = props.milestones.find((m) =>
        m.value.includes('footprint')
      );
      if (footprintMilestone) SetStatusField(footprintMilestone.value);
    }
  }, [props.milestones]);

  const [milestoneLabel, setMilestoneLabel] = useState(fieldName);
  const [widgetName, setWidgetName] = useState('My Custom Widget Name');
  const [hasError, setHasError] = useState(false);

  const channel = useMemo(() => new BroadcastChannel('hud-add-widget'), []);

  const defaultBGFills = [
    statusColor['error'],
    statusColor['failure'],
    statusColor['processing'],
    statusColor['pending'],
    statusColor['completed']
  ];

  const defaultInnerBGFills = [statusColor['completed'], statusColor['pending']];

  ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale);

  const data = {
    labels: ['Error', 'Failure', 'Processing', 'Pending', 'Completed'],
    datasets: [
      {
        label: 'Offering Status',
        data: arrayData,
        backgroundColor: defaultBGFills,
        borderColor: colorScheme === 'dark' ? theme.colors.gray[8] : theme.colors.gray[3]
      },
      {
        label: 'Offering Status',
        data: innerArrayData,
        backgroundColor: defaultInnerBGFills,
        borderColor: colorScheme === 'dark' ? theme.colors.gray[8] : theme.colors.gray[3]
      }
    ]
  };

  useEffect(() => {
    if (!isSearching) {
      let newArrayData: any[] = [];
      let newInnerArrayData: any[] = [0, 0, 0];
      for (const status in statusCount) {
        if (status === 'pending' || status === 'completed') {
          newInnerArrayData.push(statusCount[status as keyof typeof statusCount]);
        } else {
          newArrayData.push(statusCount[status as keyof typeof statusCount]);
        }
      }
      setArrayData(newArrayData);
      setInnerArrayData(newInnerArrayData);
    }
  }, [statusCount, isSearching]);

  const options = {
    animation: false,
    plugins: {
      legend: {
        labels: {
          boxWidth: 40,
          color: colorScheme === 'dark' ? theme.colors.gray[5] : theme.colors.dark[9],
          generateLabels(chart: any) {
            const data = chart.data;
            if (data.labels.length && data.datasets.length) {
              const {
                labels: { pointStyle, color }
              } = chart.legend.options;

              return data.labels.map((label: any, i: any) => {
                const meta = chart.getDatasetMeta(0);
                const style = meta.controller.getStyle(i);
                let labelText = `${label}: ${statusCount[
                  label.toLowerCase() as keyof typeof statusCount
                ].toLocaleString('en-US')}`;
                return {
                  text: labelText,
                  fillStyle: statusColor[label.toLowerCase()],
                  strokeStyle: style.borderColor,
                  fontColor: color,
                  lineWidth: style.borderWidth,
                  pointStyle: pointStyle,
                  hidden: !chart.getDataVisibility(i),

                  // Extra data used for toggling the correct item
                  index: i
                };
              });
            }
            return [];
          }
        }
      }
    },
    onClick: function (evt: any, element: any) {
      if (element.length > 0) {
        const excluded = [
          'Prep|Video|CC Ready',
          'Prep|Video|CS Delivery',
          'Prep|Video|Master Lookup',
          'Prep|Video|AVOD Gating',
          'Prep|Video|Pick/Pack Rules'
        ];
        if (excluded.includes(milestoneLabel)) return;
        let fieldName =
          milestoneLabel === 'Overall Status'
            ? 'status'
            : milestoneLabel.split('|').join('.').toLowerCase();

        let newFilter: any = {};
        newFilter[fieldName] = {
          values: [data.labels[element[0].index]],
          filterType: 'set'
        };
        gridCurrentAPI.setFilterModel(newFilter);
      }
    }
  };

  const handleUpdateStatusField = (field: string) => {
    SetStatusField(field);
    let milestone = props.milestones.find((m) => m.value === field);
    if (milestone) {
      setMilestoneLabel(milestone.label);
      setWidgetName('My Custom Widget Name');
    } else {
      setMilestoneLabel('Overall Status');
      setWidgetName('My Custom Widget Name');
    }
  };

  const handleOnClick = () => {
    setAddWidgetModal(true);
  };

  const closeModal = () => {
    setAddWidgetModal(false);
  };

  const changeWidgetName = (event: any) => {
    let name = event.target.value;
    if (name.length > 26) {
      setHasError(true);
    } else {
      setHasError(false);
    }
    setWidgetName(event.target.value);
  };

  const addWidgetToHud = async () => {
    const personalHUD = await HUDService.getPersonalHUD();
    const chartSearch = useSearchStore.getState().chartSearch;
    let newHud;
    let newWidget: iWidget = {
      i: uuidv4(),
      h: 13,
      w: 3,
      x: 0,
      y: 0,
      customName: widgetName,
      milestoneName: milestoneLabel,
      search: chartSearch,
      refreshDelay: '60000',
      footprint: props.footprint
    };
    if (personalHUD.length > 0) {
      let copyHUD = Object.assign({}, personalHUD[0]);
      copyHUD.widgets.push(newWidget);
      newHud = copyHUD;
    } else {
      let defaultHud: iPersonalHUD = {
        id: '',
        type: '',
        widgets: [newWidget],
        layout: {
          lg: [],
          md: [],
          sm: [],
          xs: []
        }
      };
      newHud = defaultHud;
    }

    await HUDService.upsertHUD(newHud.id, newHud);
    channel.postMessage('newwidget');
    setAddWidgetModal(false);
  };

  return (
    <Box
      className={`react-grid-item baseContainer`}
      style={{
        backgroundColor:
          colorScheme === 'dark' ? darken(colors.wbdblue[9], 0.4) : '#E6EFFF',
        border: '1px solid',
        borderColor:
          colorScheme === 'dark'
            ? darken(colors.wbdblue[6], 0.3)
            : lighten(colors.wbdblue[3], 0.6),
        borderRadius: theme.radius.md
      }}
    >
      <Group justify="space-between">
        <MTooltip label="Select Status field to base Chart">
          <Select
            data={props.milestones}
            classNames={{
              input: colorScheme === 'dark' ? 'inputDark' : 'inputdd'
            }}
            size="xs"
            placeholder="Select Status Field"
            value={statusField}
            onChange={(v: string | null) => v && handleUpdateStatusField(v)}
            style={{
                backgroundColor:
                  colorScheme === 'dark'
                    ? darken(colors.wbdblue[9], 0.4)
                    : colors.wbdblue[0],
                borderColor:
                  colorScheme === 'dark' ? lighten(colors.wbdblue[9], 0.15) : '#B8D2FD'
            }}
          />
        </MTooltip>
        <Button
          variant="subtle"
          testid="add-widget-to-HUD"
          leftSection={<Plus size={24} style={{ marginRight: -8 }} />}
          // styles={{ leftIcon:  { marginRight: 0 }}}
          style={{
            color: 'gray',
            padding: 8
          }}
          onClick={handleOnClick}
          tooltip={{
            label: 'Add Widget to HUD',
            position: 'top',
            color: colorScheme === 'dark' ? 'gray' : 'black'
          }}
        />
        <Modal
          opened={addWidgetModal}
          onClose={closeModal}
          title="Add Widget to HUD"
          centered
          padding="xl"
        >
          <>
            <Divider my="md" labelPosition="center" label="Current Widget"></Divider>
            <Text>
              {`Would you like to add the current "${milestoneLabel}" chart to your HUD.`}
            </Text>
            <Text>This widget will correspond to your current search</Text>
            <Flex style={{ marginTop: '10px' }} justify="center" gap="md">
              <Button
                testid="confirm-add-widget-to-HUD"
                noIcon
                disabled={hasError}
                onClick={addWidgetToHud}
              >
                Add
              </Button>
              <TextInput
                style={{ width: '65%' }}
                error={hasError ? 'This name is too long.' : ''}
                onChange={(e) => changeWidgetName(e)}
                // ref={ref}
                defaultValue={`My Custom Widget Name`}
              />
            </Flex>
          </>
        </Modal>
      </Group>
      <Divider size="xs" mt={6} mb={9} />
      <Pie ref={canvasRef} data={data} options={options} />
    </Box>
  );
};

export default GridStatusChart;

import { ActionIcon, Table } from '@mantine/core';
// import { StatusCodeFootprint } from './models';
import { Pencil, Plus } from 'tabler-icons-react';
import { useStatusCodeStore } from './store';
import { StatusCodeFootprint } from './models';

export interface iSCDFootprintsTableProps {
  withButtons?: boolean;
}

export const SCDFootprintsTable = (props: iSCDFootprintsTableProps) => {
  const withBtns = props.withButtons || true;
  const ToggleIsEditing = useStatusCodeStore.getState().ToggleFormIsEditing;
  const SetFootprintsIndex = useStatusCodeStore.getState().SetFootprintsIndex;
  const footprints = useStatusCodeStore.getState().footprints;

  function onAddClick() {
    SetFootprintsIndex(-2);
    ToggleIsEditing(true);
  }

  return (
    <Table striped highlightOnHover withColumnBorders mb={18}>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Footprint</Table.Th>
          <Table.Th>Process</Table.Th>
          <Table.Th>Activity</Table.Th>
          <Table.Th>Task</Table.Th>
          {withBtns && (
            <Table.Th>
              <ActionIcon variant="subtle" color="cyan" onClick={onAddClick}>
                <Plus />
              </ActionIcon>
            </Table.Th>
          )}
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>{ footprints && RenderRows(footprints, withBtns)}</Table.Tbody>
    </Table>
  );
};

function RenderRows(footprints: StatusCodeFootprint[], withButtons?: boolean) {
  const withBtns = withButtons || true;
  const ToggleIsEditing = useStatusCodeStore.getState().ToggleFormIsEditing;
  const SetFootprintsIndex = useStatusCodeStore.getState().SetFootprintsIndex;

  function onEditClick(idx: number) {
    SetFootprintsIndex(idx);
    ToggleIsEditing(true);
  }

  return footprints.map((fp: any, index: number) => {
    return (
      <Table.Tr key={fp.footprint}>
        <Table.Td>{fp.footprint}</Table.Td>
        <Table.Td>{fp.process}</Table.Td>
        <Table.Td>{fp.activity}</Table.Td>
        <Table.Td>{fp.task}</Table.Td>
        {withBtns && (
          <Table.Td style={{ maxWidth: 32 }}>
            <ActionIcon
              variant="subtle"
              color="teal"
              onClick={() => onEditClick(index)}
            >
              <Pencil />
            </ActionIcon>
          </Table.Td>
        
  )}
      </Table.Tr>
    );
  });
}

import { Button } from '../Button';
import { Tooltip } from '../Tooltip';
import { UserCircle } from 'tabler-icons-react';
import { useTID } from '../../helpers/useTestId';
import { OktaAuth } from '@okta/okta-auth-js';
import {
  ActionIcon,
  Group,
  Popover,
  useComputedColorScheme
} from '@mantine/core';
import { useRef } from 'react';
import { useAsideSettingsStore } from '../Aside/store';
import { useConfigStore } from '../../app/store';

interface IButtonAccountProps {
  testid: string;
}

const ButtonAccount = (props: IButtonAccountProps) => {
  const colorScheme = useComputedColorScheme('light');
  const dark = colorScheme === 'dark';
  const showAside = useAsideSettingsStore((state) => state.Show);
  const userData = useConfigStore((state) => state.userData);
  const config = useConfigStore((state) => state.config);
  const TID = useTID('ButtonAccount', props.testid);
  const ref = useRef<any>();
  const oktaAuth = new OktaAuth({
    issuer: config.oidc.issuer,
    clientId: config.oidc.clientId,
    redirectUri: window.location.origin + '/login',
    responseType: 'code'
  });


  const logout = async () => {
    await oktaAuth.signOut();
  };

  return (
    <Group>
      <Tooltip
        label={`Toggle Account panel ${showAside === 'AsideAccount' ? 'close' : 'open'}`}
        color={showAside === 'AsideAccount' ? 'blue' : 'gray'}
        position="bottom-end"
        withArrow
      >
        <Popover width={150} withArrow position="bottom-end" offset={5}>
          <Popover.Target>
            <div
              ref={ref}
              className={`${!dark && 'themebtn-bg-color'}`}
              style={{
                height: '36px',
                width: '45px',
                borderRadius: '5px',
                marginRight: 30
              }}
            >
              {userData.name ? (
              <ActionIcon
                mt={1}
                ml={5}
                style={{ marginRight: 30, border: '2px solid gray' }}
                variant="outline"
                color="gray"
                size="lg"
                radius="xl"
                {...TID}
              >              
                  {`${userData?.name && userData?.name[0]}${
                    userData?.surName && userData.surName[0]
                  } `}
                
              </ActionIcon>
              ) : (
                <Button
                  className={`${!dark && 'themebtn-bg-color'}`}
                  {...TID}
                  testid="ButtonAccount"
                  variant="subtle"
                  style={{
                    color: showAside === 'AsideAccount' ? 'var(--mantine-color-blue-5)' : 'gray'
                  }}
                  mr="xl"
                  leftSection={<UserCircle style={{ margin: -8, marginRight:-12, marginLeft: -2 }}/>}
                /> 
              )}
            </div>
          </Popover.Target>
          {userData.name && <Popover.Dropdown>
             <Button noIcon testid="AsideAccount" onClick={() => logout()}>
              Sign Out
            </Button>
          </Popover.Dropdown>}
        </Popover>
      </Tooltip>
    </Group>
  );
};

export default ButtonAccount;

import { Box, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { useAdminModuleStore } from './store';
import { iItemType } from '../../components/ListSortable/models';
import { Button } from '../../components';
import { DeleteItemConfirmationModal } from './ConfirmationModals/_deleteItem';
import { ChangeItemConfirmationModal } from './ConfirmationModals/_changeValueItem';
import { currentListInDDsHierarchy } from './_dropDownMenus';
import { useAppConfigManager } from '../../components/AppConfigs';
import { DropdownService } from '../../services/dropdown/dropdownService';

const standarizeList = (list: iItemType[]): iItemType[] => {
  return list?.map((item) => {
    const { label, value } = item;
    return { label, value };
  });
};

const setListInDDsHierarchy = (
  tree: iItemType[],
  replacementArray: iItemType[],
  listToChange: string
) => {
  let newtree = [...tree];
  if (!newtree || !Array.isArray(newtree)) {
    return;
  }
  newtree?.forEach((node) => {
    if (node.value === listToChange) {
      return (node.children = replacementArray);
    }
    if (node.children && node.children.length > 0) {
      return setListInDDsHierarchy(node.children, replacementArray, listToChange);
    }
  });
  return newtree;
};

const deleteTempListKeys = (listModified: iItemType[]) => {
  return listModified.map((item) => {
    let newitem = Object.assign({}, item);
    delete newitem.chosen;
    delete newitem.id;
    return newitem;
  });
};

type DropDownFormPropsType = {
  getDropdowns: () => void;
  dropdownId: string;
  set_dropdownIdError: (ddError: string) => void;
  loading: boolean;
  set_loading: (loading: boolean) => void;
};

export const DropDownForm = (props: DropDownFormPropsType) => {
  const { getDropdowns, dropdownId, set_dropdownIdError, set_loading, loading } = props;
  const { Item } = useAdminModuleStore((state) => state.DropDown);
  const {
    SetItem,
    newDropdown,
    SetHierarchyPath,
    hierarchyPath,
    SetNewDropdown,
    DropdownList,
    SetDropdownList
  } = useAdminModuleStore();
  const [openDeleteModal, set_openDeleteModal] = useState(false);
  const [openChangeItemValueModal, set_openChangeItemValueModal] = useState(false);
  const { dropdowns } = useAppConfigManager();

  const form = useForm({
    initialValues: {
      label: Item.label || '',
      value: Item.value || ''
    },

    validate: {
      value: (value) => {
        const duplicatedItemValue = DropdownList.find(
          (ddValue) => ddValue.value.toLowerCase() === value.toLowerCase()
        );
        if (duplicatedItemValue && Item.value !== value)
          return `This value is already in use by ${duplicatedItemValue?.label}`;
      },
      label: (label) =>
        label.length > 1 ? null : 'Label must be longer than 1 character'
    }
  });

  const getFormData = () => {
    const { label, value } = form.values;
    return { label, value };
  };

  function OnSubmit() {
    if (newDropdown) {
      if (!dropdownId) return set_dropdownIdError('Please add a dropdown id');
      if (
        Object.keys(dropdowns).some(
          (ddId) => ddId.toLowerCase() === dropdownId.toLowerCase()
        )
      )
        return set_dropdownIdError('This dropdown id is already in use');
      set_dropdownIdError('');
    }
    if (
      Item?.value === getFormData()?.value ||
      (!!Object.keys(Item).length && !Item?.id)
    ) {
      AddOrUpdateItemToList();
    } else {
      set_openChangeItemValueModal(true);
    }
  }

  async function AddOrUpdateItemToList() {
    SetItem(getFormData());
    let newItem: iItemType = {
      ...Item,
      id: Item?.id ?? DropdownList?.length + 1 + 'dd',
      label: getFormData().label,
      value: getFormData().value || ''
    };

    let updatedList = DropdownList;
    if (newItem.label) {
      if (Item?.id) {
        updatedList = updatedList.map((ddVal: iItemType) =>
          ddVal.id === Item.id ? newItem : ddVal
        );
      } else {
        updatedList = [...updatedList, newItem];
      }
    }

    await SendData(updatedList);

    resetForm();
    SetDropdownList(updatedList);
  }

  async function SendData(list: iItemType[]) {
    set_loading(true);
    const standardList = deleteTempListKeys(list);
    let listInHierarchy = standardList;
    if (hierarchyPath.length > 1) {
      listInHierarchy = setListInDDsHierarchy(
        dropdowns[hierarchyPath?.[0]?.value] as any,
        standardList,
        [...hierarchyPath]?.reverse()?.[0]?.value
      ) as iItemType[];
    }

    let id = `dropdown:${hierarchyPath?.[0]?.value}`;
    if (newDropdown) {
      id = `dropdown:${dropdownId}`;
      SetHierarchyPath([{ value: dropdownId, label: dropdownId }]);
      SetNewDropdown(false);
    }
    if (listInHierarchy) {
      await DropdownService.upsertDropdown(id, {
        id,
        type: id,
        data: listInHierarchy
      });
      getDropdowns();
    }
    set_loading(false);
  }

  const resetForm = () => {
    SetItem({} as iItemType);
    form.setFieldValue('label', '');
    form.setFieldValue('value', '');
  };

  function updateItem() {
    Item.label = getFormData().label;
    Item.value = getFormData().value;
    SetItem(Item);
  }

  useEffect(() => {
    form.setValues(Item);
  }, [Item]);

  function OnNewClick() {
    SetItem({ label: '', value: '' });
  }

  const cancelDropdownSelection = () => {
    resetForm();
    SetDropdownList([]);
    SetHierarchyPath([]);
    SetNewDropdown(false);
  };

  const modifiedList =
    JSON.stringify(
      standarizeList(
        currentListInDDsHierarchy(dropdowns[hierarchyPath?.[0]?.value], hierarchyPath)
      )
    ) !== JSON.stringify(standarizeList(DropdownList));

  return (
    <Box>
      <DeleteItemConfirmationModal
        openDeleteModal={openDeleteModal}
        set_openDeleteModal={set_openDeleteModal}
        SendData={SendData}
      />
      <ChangeItemConfirmationModal
        openChangeItemValueModal={openChangeItemValueModal}
        set_openChangeItemValueModal={set_openChangeItemValueModal}
        SendData={AddOrUpdateItemToList}
        prevValue={Item?.value}
        newValue={getFormData()?.value}
      />
      {!!DropdownList?.length && !Object.keys(Item)?.length ? (
        <Stack>
          <Button
            testid="admin-dropDownForm-new"
            noIcon
            style={{
              width: 90,
              marginRight: 4
            }}
            loading={loading}
            onClick={OnNewClick}
          >
            New
          </Button>
          {modifiedList && (
            <Button
              testid="admin-dropDownForm-save"
              noIcon
              color="green"
              onClick={() => SendData(DropdownList)}
              style={{ width: 90, marginRight: 4 }}
              loading={loading}
            >
              Save
            </Button>
          )}
          <Button
            noIcon
            color="dark"
            testid="admin-dropDownForm-delete"
            onClick={cancelDropdownSelection}
            style={{
              width: 90
            }}
            loading={loading}
          >
            Cancel
          </Button>
        </Stack>
      ) : null}
      <form
        onSubmit={form.onSubmit(() => {
          OnSubmit();
        })}
      >
        {(!!Object.keys(Item).length || (newDropdown && !DropdownList?.length)) && (
          <>
            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              {!!Item?.id && (
                <Button
                  noIcon
                  color="red"
                  testid="admin-dropDownForm-delete"
                  onClick={() => set_openDeleteModal(true)}
                  size="xs"
                  style={{
                    width: 80
                  }}
                  loading={loading}
                >
                  Delete
                </Button>
              )}
            </div>
            <Stack>
              <TextInput
                withAsterisk
                label="Label"
                placeholder="label"
                size="xs"
                onBlur={updateItem}
                {...form.getInputProps('label')}
              />
              <TextInput
                withAsterisk
                label="Value"
                placeholder="value"
                size="xs"
                onBlur={updateItem}
                {...form.getInputProps('value')}
              />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  color="dark"
                  noIcon
                  loading={loading}
                  testid="admin-dropDownForm-reset"
                  onClick={() => (newDropdown ? cancelDropdownSelection() : resetForm())}
                  style={{
                    width: 90,
                    marginRight: 4
                  }}
                >
                  Cancel
                </Button>
                <Button
                  testid="admin-dropDownForm-save"
                  noIcon
                  disabled={
                    JSON.stringify(standarizeList([Item])) ===
                      JSON.stringify(standarizeList([getFormData()])) && !modifiedList
                  }
                  loading={loading}
                  color="green"
                  type="submit"
                  style={{ width: 90, marginRight: 4 }}
                  onClick={OnSubmit}
                >
                  Save
                </Button>
              </div>
            </Stack>
          </>
        )}
      </form>
    </Box>
  );
};

import { Anchor, Box, Popover, ScrollArea, Stack, Table, Text } from '@mantine/core';
import { InformationAccordion, InformationAccordionItem } from '../../../../components';
// import { capitalize } from '../../../utils/utilities';
import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import {
  DetailAccordion,
  DetailAccordionItem
} from '../../../../components/DetailAccordion';
import { useElementSize, useViewportSize } from '@mantine/hooks';
import { CodeHighlight } from '@mantine/code-highlight';
import { useDetailsStore } from '../../store';
import { useConfigStore } from '../../../../app/store';
import { fetchCSData } from '../../../../api/fetchCSData';
import { showIdMidRollIcon } from '../_showIdMidRollIcon';
import { fetchEventData } from '../../../../api/fetchEventData';
import { showManifestationAccordionItems } from '../_showManifestationAccordionItems';
import {
  adMarkerGetter,
} from '../../_columnDefs/offering/offeringValueGetters';
import {
  dynamicRangeIndicatorGetter,
  fulfilledAnnotationslabelsGetter,
  fulfilledAudioComponentsGetter,
  fulfilledMarkersGetter,
  fulfilledMediaDurationGetter,
  fulfilledTimedTextComponentsGetter
} from '../../_columnDefs/columnsHelpers/fulfilledComponentsGetters';
import { useAppConfigManager } from '../../../../components/AppConfigs';
import { iManifestationDetailsParams } from '../../models';
import dayjs from 'dayjs';

export const offeringPartnerGetter = (partnerId: string) => {
  let partner = '';
  if (partnerId) {
    switch (partnerId) {
      case 'c01c1c07-e7bf-4cee-be6b-87092a30d41c':
        partner = 'Max';
        break;
      case '2195c719-d08d-49e2-b568-234ec46414b5':
        partner = 'Max';
        break;
      case '9646297a-acd4-4884-8977-d56b280a0c4a':
        partner = 'Max';
        break;
      case 'f6bc2fc8-8191-4920-8e9f-99ce4b0ff057':
        partner = 'Max';
        break;
      case '65e74175-2142-4e5a-a85f-65f4ed69f570':
        partner = 'Max';
        break;
      case '155ecceb-bb5d-4cd3-b106-183686d5a2d4':
        partner = 'Max';
        break;
      case 'bdb0b66c-4cb4-49e0-980d-a19145cecf80':
        partner = 'Max';
        break;
      case '22352a43-1948-4ea4-8daa-9bd00749fc5c':
        partner = 'Max';
        break;
      case '4a7ad113-8fda-4939-bda8-a9474a912805':
        partner = 'Amazon PVC';
        break;
      case 'd5c3de78-11ca-4ff3-be8f-d1855e2373ed':
        partner = 'YouTube';
        break;
      case 'ac176604-f0c4-4cf3-b06f-bf9e8aea3ffe':
        partner = 'Telefonica';
        break;
      case 'd646434d-bcf9-4e40-88fb-c5ca52d8ccf0':
        partner = 'Bleacher Report';
        break;
      case 'dd4b242c-7f40-4628-bb79-8e69e5f98fad':
        partner = 'Canal+';
        break;
      default:
        partner = '';
    }
  }
  return partner;
};

export const offeringMarketGetter = (marketId: string) => {
  if (!marketId) {
    return '';
  }
  if (marketId) {
    const markets = useAppConfigManager.getState().queryMarkets;
    const market = markets.find((m) => m.valueList === marketId);
    if (market) {
      return market.label;
    } else {
      return `Market ID: ${marketId} label not found`;
    }
  }
};

export const AssetDetail = (props: any) => {
  const details = props.details ? props.details : useDetailsStore((state) => state.asset);
  const { tasks } = details;

  const config = useConfigStore((state) => state.config);

  // const [imageFacts, setImageFacts] = useState([]);
  // const [imageFactsDelivered, setImageFactsDelivered] = useState(0);
  // const [imageFactsRequired, setImageFactsRequired] = useState(0);

  // const [mezzFacts, setMezzFacts] = useState([]);
  // const [metaFacts, setMetaFacts] = useState([]);
  // const [offeringFacts, setOfferingFacts] = useState([]);

  // const [offeringIdJson, setOfferingIdJson] = useState([]);

  const [csData, setCsData] = useState<any>({});
  const [manifestations, setManifestations] = useState<any>({});

  // async function fetchJsonSch() {
  //   const baseUrl = config.baseApiGatewayUrl + '/graphql/search';
  //   let result = await fetchJSONID(baseUrl, offering.eventMetaId, 'ondemand');
  //   setOfferingIdJson(result?.data?.event?.records);
  // }

  // async function fetchFactsData() {
  //   let result = await fetchFacts(details.offeringId);
  //   setImageFacts(
  //     result
  //       .filter((i: { type: string }) => i.type === 'imagedelivery')
  //       .sort(function (a: any, b: any) {
  //         var nA = a.details.asset.imageSpec.imageRole;
  //         var nB = b.details.asset.imageSpec.imageRole;

  //         if (nA < nB) return -1;
  //         else if (nA > nB) return 1;
  //         return 0;
  //       })
  //   );
  //   setMezzFacts(result.filter((i: { type: string }) => i.type === 'mezzdelivery'));
  //   setMetaFacts(result.filter((i: { type: string }) => i.type === 'metadelivery'));
  //   setOfferingFacts(
  //     result.filter((i: { type: string }) => i.type === 'offeringdelivery')
  //   );
  //   let imageFactCount = await fetchImageCounts(details.offeringId);
  //   if (imageFactCount && imageFactCount.length > 0) {
  //     imageFactCount.sort(function (a: any, b: any) {
  //       return b.audit.generatedAt.localeCompare(a.audit.generatedAt);
  //     });
  //     setImageFactsDelivered(imageFactCount[0].delivered);
  //     setImageFactsRequired(imageFactCount[0].required);
  //   }
  // }

  async function fetchManifestations() {
    const manifestationTasks = tasks?.filter(
      (t: { type: string }) =>
        t.type === 'mezzcreation' ||
        t.type === 'pickpackrules' ||
        t.type === 'mezzgtpjobmonitor'
    );
    if (manifestationTasks) {
      let manifestationData: any[] = [];
      for (let i = 0; i < manifestationTasks.length; i++) {
        const result = await fetchEventData(manifestationTasks[i].eventMetaId, true);
        if (result && result.length > 0) {
          result[0].resolved?.forEach((r: any) => {
            manifestationData.push(r);
          });
        }
      }
      const sortOrder = [
        'sourceManifestation',
        'targetManifestation',
        'outputManifestation',
        'presentationManifestation'
      ];
      const sortByObject = sortOrder.reduce((obj, item, index) => {
        return {
          ...obj,
          [item]: index
        };
      }, {});
      const customSort = manifestationData.sort(
        (a, b) =>
          sortByObject[a.label as keyof typeof sortByObject] -
          sortByObject[b.label as keyof typeof sortByObject]
      );
      const filteredCustomSort = customSort.filter((i) => i.data !== 'undefined');
      setManifestations(filteredCustomSort);
    }
  }

  async function fetchCS() {
    const baseUrl = config.baseApiGatewayUrl + '/graphql/search';
    let result = await fetchCSData(baseUrl, details.id, 'offeringsAsset');
    if (result) setCsData(result);
  }

  useEffect(() => {
    // fetchFactsData();
    fetchCS();
    // fetchJsonSch();
    fetchManifestations();
  }, []);

  const idFields = useAppConfigManager
    ?.getState()
    ?.GetSearchInputs('footprint-asset')
    ?.map((field) => ({
      label: field.label,
      value: field.footprint
      ?.find((fp) => fp.footprint === 'footprint-asset')
      ?.path
    }));

  const showDetail = (d: any) => {
    if (d === undefined) {
      return;
    }
    if (details === undefined) {
      return;
    }
    const path = d?.value?.split('.');
    let value = null;

    if (!details[path?.[0]]) return;
    let pathValue = details;
    path.forEach((e: string) => {
      pathValue = pathValue[e];
    });
    if (typeof pathValue !== 'string') return;
    value = pathValue;

    if (!value) return;
    const formattedType = details?.type?.split('_').slice(-1)[0];
    const piEnv = config.environment === 'prod' ? '' : `${config.environment}.`;
    switch (d.label) {
      case 'Content Id':
        const contentLinkableTypes = ['program', 'season', 'series'];
        if (contentLinkableTypes.includes(formattedType)) {
          let topic;
          let objectType;
          if (formattedType === 'program') {
            topic = 'program';
            objectType = 'program';
          } else if (formattedType === 'season') {
            topic = 'season';
            objectType = 'season';
          } else {
            topic = 'series';
            objectType = 'series';
          }
          return (
            <Popover
              key={value + 'PopoverContentId'}
              position="bottom"
              withArrow
              shadow="md"
              classNames={{
                arrow: 'popoverArrow',
                dropdown: 'popoverDropdownBelow'
              }}
              zIndex={9999}
            >
              <Popover.Target>
                <Table.Tr key={value + 'ContentId'}>
                  <Table.Td style={{ textAlign: 'left' }}>{d.label}</Table.Td>
                  <Table.Td>
                    <Anchor type="button">{value}</Anchor>
                  </Table.Td>
                </Table.Tr>
              </Popover.Target>
              <Popover.Dropdown>
                <Stack align={'center'}>
                  <Anchor
                    target="_blank"
                    href={`https://studio.${config.couiEnvironment}.api.discomax.com/content-visualization/programs/${value}`}
                  >
                    CV
                  </Anchor>
                  <Anchor
                    target="_blank"
                    href={`https://backoffice.${config.couiEnvironment}.api.discomax.com/prismatic-microapps/co-ui/objects/${value}?db=cvs&topic=${topic}&objectType=${objectType}`}
                  >
                    COUI
                  </Anchor>
                  <Anchor
                    target="_blank"
                    href={`https://apps.${piEnv}dsuite.tech/prometheus/api/v4/canonical/asset/${value}`}
                  >
                    PI
                  </Anchor>
                  <Anchor
                    target="_blank"
                    href={`${window.location.origin}/views/offeringAssets/keyword/${value}`}
                  >
                    Related Offerings
                  </Anchor>
                </Stack>
              </Popover.Dropdown>
            </Popover>
          );
        }
        break;
      case 'Edit Id':
        return (
          <Popover
            key={value + 'PopoverEditId'}
            position="bottom"
            withArrow
            shadow="md"
            classNames={{
              arrow: 'popoverArrow',
              dropdown: 'popoverDropdownBelow'
            }}
            zIndex={9999}
          >
            <Popover.Target>
              <Table.Tr key={value + 'EditId'}>
                <Table.Td style={{ textAlign: 'left' }}>{d.label}</Table.Td>
                <Table.Td>
                  <Anchor type="button">{value}</Anchor>
                </Table.Td>
              </Table.Tr>
            </Popover.Target>
            <Popover.Dropdown>
              <Stack align={'center'}>
                {details?.contentId?.id && (
                  <Anchor
                    target="_blank"
                    href={`https://studio.${config.couiEnvironment}.api.discomax.com/content-visualization/programs/${details?.contentId?.id}/edits/${value}`}
                  >
                    CV
                  </Anchor>
                )}
                <Anchor
                  target="_blank"
                  href={`https://backoffice.${config.couiEnvironment}.api.discomax.com/prismatic-microapps/co-ui/objects/${value}?db=cvs&topic=edit&objectType=edit`}
                >
                  COUI
                </Anchor>
                {details.entityType !== 'promo playlist' &&
                  details.entityType !== 'promo' && (
                    <Anchor
                      target="_blank"
                      href={`https://apps.${piEnv}dsuite.tech/prometheus/api/v4/canonical/asset/${value}`}
                    >
                      PI
                    </Anchor>
                  )}
              </Stack>
            </Popover.Dropdown>
          </Popover>
        );
      case 'CSID':
        return (
          <Popover
            key={value + 'PopoverCSID'}
            width={800}
            position="right"
            withArrow
            shadow="md"
            styles={{
              dropdown: {
                marginTop: 0
              }
            }}
            classNames={{
              arrow: 'popoverArrow',
              dropdown: 'popoverDropdown'
            }}
            zIndex={9999}
          >
            <Popover.Target>
              <Table.Tr key={value + 'CSID'}>
                <Table.Td style={{ textAlign: 'left' }}>{d.label}</Table.Td>
                <Table.Td>
                  <Anchor type="button">{value}</Anchor>
                </Table.Td>
              </Table.Tr>
            </Popover.Target>
            <Popover.Dropdown>
              <ScrollArea h={425}>
                <Text fw={700}>Content Subscription JSON</Text>
                <CodeHighlight code={JSON.stringify(csData, null, 2)} language="tsx" />
              </ScrollArea>
            </Popover.Dropdown>
          </Popover>
        );
      case 'Season Id':
        return (
          <Popover
            key={value + 'PopoverSeasonId'}
            position="bottom"
            withArrow
            shadow="md"
            classNames={{
              arrow: 'popoverArrow',
              dropdown: 'popoverDropdownBelow'
            }}
            zIndex={9999}
          >
            <Popover.Target>
              <Table.Tr key={value + 'SeasonId'}>
                <Table.Td style={{ textAlign: 'left' }}>{d.label}</Table.Td>
                <Table.Td>
                  <Anchor type="button">{value}</Anchor>
                </Table.Td>
              </Table.Tr>
            </Popover.Target>
            <Popover.Dropdown>
              <Stack align={'center'}>
                {details?.seriesId?.id && (
                  <Anchor
                    target="_blank"
                    href={`https://studio.${config.couiEnvironment}.api.discomax.com/content-visualization/series/${details?.seriesId?.id}/seasons/${value}`}
                  >
                    CV
                  </Anchor>
                )}
                <Anchor
                  target="_blank"
                  href={`https://backoffice.${config.couiEnvironment}.api.discomax.com/prismatic-microapps/co-ui/objects/${value}?db=cvs&topic=season&objectType=season`}
                >
                  COUI
                </Anchor>
                {details.entityType !== 'promo playlist' &&
                  details.entityType !== 'promo' && (
                    <Anchor
                      target="_blank"
                      href={`https://apps.${piEnv}dsuite.tech/prometheus/api/v4/canonical/asset/${value}`}
                    >
                      PI
                    </Anchor>
                  )}
                {details.contentId?.id && (
                  <Anchor
                    target="_blank"
                    href={`${window.location.origin}/views/offeringAssets/keyword/${value}`}
                  >
                    Related Offerings
                  </Anchor>
                )}
              </Stack>
            </Popover.Dropdown>
          </Popover>
        );
      case 'Series Id':
        return (
          <Popover
            key={value + 'PopoverSeriesId'}
            position="bottom"
            withArrow
            shadow="md"
            classNames={{
              arrow: 'popoverArrow',
              dropdown: 'popoverDropdownBelow'
            }}
            zIndex={9999}
          >
            <Popover.Target>
              <Table.Tr key={value + 'SeriesId'}>
                <Table.Td style={{ textAlign: 'left' }}>{d.label}</Table.Td>
                <Table.Td>
                  <Anchor type="button">{value}</Anchor>
                </Table.Td>
              </Table.Tr>
            </Popover.Target>
            <Popover.Dropdown>
              <Stack align={'center'}>
                <Anchor
                  target="_blank"
                  href={`https://studio.${config.couiEnvironment}.api.discomax.com/content-visualization/series/${value}`}
                >
                  CV
                </Anchor>
                <Anchor
                  target="_blank"
                  href={`https://backoffice.${config.couiEnvironment}.api.discomax.com/prismatic-microapps/co-ui/objects/${value}?db=cvs&topic=series&objectType=series`}
                >
                  COUI
                </Anchor>
                {details.entityType !== 'promo playlist' &&
                  details.entityType !== 'promo' && (
                    <Anchor
                      target="_blank"
                      href={`https://apps.${piEnv}dsuite.tech/prometheus/api/v4/canonical/asset/${value}`}
                    >
                      PI
                    </Anchor>
                  )}
                {details.contentId?.id && (
                  <Anchor
                    target="_blank"
                    href={`${window.location.origin}/views/offeringAssets/keyword/${value}`}
                  >
                    Related Offerings
                  </Anchor>
                )}
              </Stack>
            </Popover.Dropdown>
          </Popover>
        );
      default:
        return (
          <Table.Tr key={value}>
            <Table.Td style={{ textAlign: 'left' }}>{d.label}</Table.Td>
            <Table.Td>{value}</Table.Td>
          </Table.Tr>
        );
    }
  };

  const shownIDs = useMemo(
    () => idFields.map(showDetail),
    [csData]
  );

  const { ref, width } = useElementSize();

  const { height } = useViewportSize();

  // const offeringPartnerGetter = (partnerId: string) => {
  //   let partner = '';
  //   if (partnerId) {
  //     switch (partnerId) {
  //       case 'c01c1c07-e7bf-4cee-be6b-87092a30d41c':
  //         partner = 'Max';
  //         break;
  //       case '2195c719-d08d-49e2-b568-234ec46414b5':
  //         partner = 'Max';
  //         break;
  //       case '9646297a-acd4-4884-8977-d56b280a0c4a':
  //         partner = 'Max';
  //         break;
  //       case 'f6bc2fc8-8191-4920-8e9f-99ce4b0ff057':
  //         partner = 'Max';
  //         break;
  //       case '65e74175-2142-4e5a-a85f-65f4ed69f570':
  //         partner = 'Max';
  //         break;
  //       case '155ecceb-bb5d-4cd3-b106-183686d5a2d4':
  //         partner = 'Max';
  //         break;
  //       case 'bdb0b66c-4cb4-49e0-980d-a19145cecf80':
  //         partner = 'Max';
  //         break;
  //       case '22352a43-1948-4ea4-8daa-9bd00749fc5c':
  //         partner = 'Max';
  //         break;
  //       case '4a7ad113-8fda-4939-bda8-a9474a912805':
  //         partner = 'Amazon PVC';
  //         break;
  //       case 'd5c3de78-11ca-4ff3-be8f-d1855e2373ed':
  //         partner = 'YouTube';
  //         break;

  //       default:
  //         partner = '';
  //     }
  //   }
  //   return partner;
  // };

  const adMarkerParams: iManifestationDetailsParams = {
    tasks: tasks,
  };
  const adMarkerTaskCount = adMarkerGetter(adMarkerParams);

  const manifestationDetails = (m: string) => {
    const params: iManifestationDetailsParams = {
      tasks: tasks,
    };
    switch (m) {
      case 'fulfilledAudioComponents':
        return fulfilledAudioComponentsGetter(params);
      case 'fulfilledTimedTextComponents':
        return fulfilledTimedTextComponentsGetter(params);
      case 'fulfilledMarkers':
        return fulfilledMarkersGetter(params);
      case 'fulfilledAnnotationsLabels':
        return fulfilledAnnotationslabelsGetter(params);
      case 'fulfilledMediaDuration':
        return fulfilledMediaDurationGetter(params);
      case 'dynamicRangeIndicator':
        return dynamicRangeIndicatorGetter(params);
      default:
        return null;
    }
  };

  if (details === undefined) return <></>;

  return (
    <Box
      ref={ref}
      style={{ margin: 8, marginLeft: 24, overflow: 'auto', maxHeight: height - 250 }}
    >
      <InformationAccordion
        defaultValue={['item-1-information', 'item-2-information']}
        chevronPosition="left"
        multiple={true}
        style={{ minWidth: 350, width: width }}
      >
        <InformationAccordionItem
          key="item-1-information"
          name="item-1"
          title="General Info"
        >
          <Table
            striped={false}
            highlightOnHover={true}
            style={{ width: '110%', margin: -16, marginTop: -42 }}
          >
            <Table.Thead>
              <Table.Tr>
                <Table.Td>&nbsp;</Table.Td>
                <Table.Td>&nbsp;</Table.Td>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              <Table.Tr>
                <Table.Td style={{ textAlign: 'left' }}>Title</Table.Td>
                <Table.Td>
                  {details?.titles?.length > 0 ? (
                    findTitle(details.titles)
                  ) : (
                    <>--No Title--</>
                  )}
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td style={{ textAlign: 'left' }}>Content Class</Table.Td>
                <Table.Td>
                  {details.type ? (
                    details.type.split('_').slice(-1)[0].charAt(0).toUpperCase() +
                    details.type.split('_').slice(-1)[0].slice(1)
                  ) : (
                    <>N/A</>
                  )}
                </Table.Td>
              </Table.Tr>
              {details.entityType && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Entity Type</Table.Td>
                  <Table.Td>
                    {details.entityType.charAt(0).toUpperCase() +
                      details.entityType.slice(1)}
                  </Table.Td>
                </Table.Tr>
              )}
              {details?.seriesTitles?.length > 0 && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Series Title</Table.Td>
                  <Table.Td>{findTitle(details.seriesTitles)}</Table.Td>
                </Table.Tr>
              )}
              {details?.seasonTitles?.length > 0 && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Season Title</Table.Td>
                  <Table.Td>{findTitle(details.seriesTitles)}</Table.Td>
                </Table.Tr>
              )}
              {details.seasonNumber && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Season #</Table.Td>
                  <Table.Td>{details.seasonNumber}</Table.Td>
                </Table.Tr>
              )}
              {details.episodeNumberInSeason && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Episode #</Table.Td>
                  <Table.Td>{details.episodeNumberInSeason}</Table.Td>
                </Table.Tr>
              )}
              {/* {details.partnerId?.id && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Partner</Table.Td>
                  <Table.Td>{offeringPartnerGetter(details.partnerId.id)}</Table.Td>
                </Table.Tr>
              )} */}
              {/* {offering.marketId?.id && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Market</Table.Td>
                  <Table.Td>{offeringMarketGetter(offering.marketId.id)}</Table.Td>
                </Table.Tr>
              )} */}
              {/* {offering.brands && offering.brands.length > 0 && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Primary Brand</Table.Td>
                  <Table.Td>
                    {offering.brands
                      ?.filter((brand: BrandType) => brand.primary)
                      ?.map((brand: BrandType) => brand.label)
                      ?.join(', ')}
                  </Table.Td>
                </Table.Tr>
              )}
              {offering.brands && offering.brands.length > 0 && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Secondary Brand</Table.Td>
                  <Table.Td>
                    {offering.brands
                      ?.filter((brand: BrandType) => !brand.primary)
                      ?.map((brand: BrandType) => brand.label)
                      ?.join(', ')}
                  </Table.Td>
                </Table.Tr>
              )} */}
              {details.controllingNetwork && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Controlling Network</Table.Td>
                  <Table.Td>{details.controllingNetwork}</Table.Td>
                </Table.Tr>
              )}
              {typeof adMarkerTaskCount === 'number' && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Ad Marker Count</Table.Td>
                  <Table.Td>{adMarkerTaskCount}</Table.Td>
                </Table.Tr>
              )}
              {details.globalReleaseDate && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Global Release Date</Table.Td>
                  <Table.Td>
                    {details.globalReleaseDate ? (dayjs(details.globalReleaseDate).format('MMM DD, YYYY (hh:mm a | [EST])')) : <></>}
                  </Table.Td>
                </Table.Tr>
              )}
              {/* <Table.Tr>
                <Table.Td style={{ textAlign: 'left' }}>Start Date/Time(ET)</Table.Td>
                <Table.Td>
                  {offering.startDate ? (
                    dayjs(offering.startDate)format('MMM DD, YYYY (hh:mm a | [EST])')
                  ) : (
                    <>N/A</>
                  )}
                </Table.Td>
              </Table.Tr> */}
            </Table.Tbody>
          </Table>
        </InformationAccordionItem>
        {!_.isEmpty(_.compact(shownIDs)) && (
          <InformationAccordionItem
            key="item-2-information"
            name="item-2"
            title="IDs"
            icons={showIdMidRollIcon(csData)}
          >
            <Table
              striped={false}
              highlightOnHover={true}
              style={{ width: '110%', margin: -16, marginTop: -32 }}
            >
              <Table.Thead>
                <Table.Tr>
                  <Table.Td>&nbsp;</Table.Td>
                  <Table.Td>&nbsp;</Table.Td>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{shownIDs}</Table.Tbody>
            </Table>
          </InformationAccordionItem>
        )}
      </InformationAccordion>

      <DetailAccordion
        defaultValue={[]}
        chevronPosition="left"
        multiple={true}
        style={{ minWidth: 350, overflow: 'auto' }}
      >
        {manifestations.length > 0 && (
          <DetailAccordionItem
            testid={`DetailAccordionItem-Manifestations`}
            key="item-4-manifestations"
            name="item-4"
            title="Latest Manifestations"
            styles={{ borderBottom: 0 }}
          >
            <Table
              striped={false}
              highlightOnHover={true}
              style={{ fontSize: 'xs', width: '110%', margin: -16, marginTop: -32 }}
            >
              <Table.Thead>
                <Table.Tr>
                  <Table.Td>&nbsp;</Table.Td>
                  <Table.Td>&nbsp;</Table.Td>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {manifestations.map((k: any) =>
                  showManifestationAccordionItems(k, width, '')
                )}
                {manifestationDetails('fulfilledAudioComponents') && (
                  <Table.Tr>
                    <Table.Td style={{ textAlign: 'left', minWidth: 80 }}>
                      Audio Languages
                    </Table.Td>
                    <Table.Td>
                      {manifestationDetails('fulfilledAudioComponents')}
                    </Table.Td>
                  </Table.Tr>
                )}
                {manifestationDetails('fulfilledTimedTextComponents') && (
                  <Table.Tr>
                    <Table.Td style={{ textAlign: 'left' }}>TimedText Languages</Table.Td>
                    <Table.Td>
                      {manifestationDetails('fulfilledTimedTextComponents')}
                    </Table.Td>
                  </Table.Tr>
                )}
                {manifestationDetails('fulfilledMarkers') && (
                  <Table.Tr>
                    <Table.Td style={{ textAlign: 'left' }}>Markers</Table.Td>
                    <Table.Td>{manifestationDetails('fulfilledMarkers')}</Table.Td>
                  </Table.Tr>
                )}
                {manifestationDetails('fulfilledAnnotationsLabels') && (
                  <Table.Tr>
                    <Table.Td style={{ textAlign: 'left' }}>Annotations</Table.Td>
                    <Table.Td>
                      {manifestationDetails('fulfilledAnnotationsLabels')}
                    </Table.Td>
                  </Table.Tr>
                )}
                {manifestationDetails('fulfilledMediaDuration') && (
                  <Table.Tr>
                    <Table.Td style={{ textAlign: 'left' }}>Duration</Table.Td>
                    <Table.Td>{manifestationDetails('fulfilledMediaDuration')}</Table.Td>
                  </Table.Tr>
                )}
                {manifestationDetails('dynamicRangeIndicator') && (
                  <Table.Tr>
                    <Table.Td style={{ textAlign: 'left' }}>Dynamic Range</Table.Td>
                    <Table.Td>{manifestationDetails('dynamicRangeIndicator')}</Table.Td>
                  </Table.Tr>
                )}
              </Table.Tbody>
            </Table>
          </DetailAccordionItem>
        )}
        {/* {imageFacts.length > 0 && (
          <DetailAccordionItem
            testid={`DetailAccordionItem-ImagesDelivered`}
            key="item-0-detail"
            name="item-0"
            title="Images Delivered"
            title2={`${imageFactsDelivered}/${imageFactsRequired}`}
            styles={{ borderBottom: 0 }}
          >
            {imageFacts.map((k) => showImageAccordionItems(k, width))}
          </DetailAccordionItem>
        )}
        {metaFacts.length > 0 && (
          <DetailAccordionItem
            testid={`DetailAccordionItem-MetaDelivered"`}
            key="item-1-detail"
            name="item-1"
            title="Meta Delivered"
            styles={{ borderBottom: 0 }}
          >
            {metaFacts.map((k) => showMetaAccordionItems(k, width))}
          </DetailAccordionItem>
        )}
        {mezzFacts.length > 0 && (
          <DetailAccordionItem
            testid={`DetailAccordionItem-MezzDelivered`}
            key="item-2-detail"
            name="item-2"
            title="Mezz Delivered"
            styles={{ borderBottom: 0 }}
          >
            {mezzFacts.map((k) => showMezzAccordionItems(k, width))}
          </DetailAccordionItem>
        )}
        {offeringFacts.length > 0 && (
          <DetailAccordionItem
            testid={`DetailAccordionItem-OfferingDelivered`}
            key="item-3-detail"
            name="item-3"
            title="Offering Delivered"
            styles={{ borderBottom: 0 }}
          >
            {offeringFacts.map((k) => showOfferingAccordionItems(k, width))}
          </DetailAccordionItem>
        )} */}
      </DetailAccordion>
    </Box>
  );
};

interface Title {
  full: string;
  type: string;
  language: string;
}

export const Truncate = (title: any, width: number) => {
  if (width === 0) return '';
  const truncateSize = Math.floor(width / 100) - 1;
  if (title.length >= truncateSize * 10) {
    return title.split('', truncateSize * 10).join('') + '...';
  }
  return title;
};

export const findTitle = (titles: Title[], TruncateTitle?: boolean, width?: number) => {
  let title;
  if (!titles) return '--No Title--';
  if (titles.length > 0) {
    const distribution = titles.find(
      (title: { type: string }) => title.type === 'distribution-title'
    );
    title = distribution ? distribution.full : null;
    if (!title) {
      const main = titles.find((title: { type: string }) => title.type === 'main-title');
      title = main ? main.full : null;
    }
    if (!title) {
      const cat = titles.find((title: { type: string }) => title.type === 'cat-title');
      title = cat ? cat.full : '';
    }
    if (!title) {
      const fallback = titles.find(
        (title: { type: string }) => title.type === 'internal-wm-name'
      );
      title = fallback ? fallback.full : '--No Title--';
    }
  }
  if (!title) title = '--No Title--';
  if (TruncateTitle && width) {
    return Truncate(title, width);
  }
  return title;
};

export default AssetDetail;

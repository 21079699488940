import { getAxiosConfig } from '../axios/config';
import { iUserPrefs, initialUserPreferencesState } from '../../app/models';
import { v4 as uuidv4 } from 'uuid';

import a from 'axios';
import axios from 'axios';
import { useConfigStore } from '../../app/store';

export interface userConfig {
  selectedTheme?: string;
  enableTooltips?: boolean;
  type: string;
  defaultFootprint: string;
}

export const userPreferencesService = {
  getUser: async () => {
    const { OKTAConfig, headers } = getAxiosConfig();
    const res = await a.get(OKTAConfig.accessToken.userinfoUrl, { headers });
    return res.data;
  },
  getPersonalSettings: async () => {
    const baseURL = useConfigStore.getState().config.baseApiGatewayUrl;
    const { OKTAConfig, headers } = getAxiosConfig();
    const uid = OKTAConfig.idToken.claims.sub;
    const email = OKTAConfig.idToken.claims.email;
    const emailName = email.split('@')[0]
    let res;
    res = await axios.get(
      baseURL + `/rest/appconfigs/v1?type=userPreferences:${uid}`,
      {
        headers
      }
    );
    if(res.data.length === 0) {
      console.log('no views from uid')
      res = await axios.get(baseURL + `/rest/appconfigs/v1?type=userPreferences:${emailName}`, {
        headers
      });
      res.data.forEach(async(view: any) => {
        const id = view.id;
        const viewUpdate =  {
          ...view, 
          type: `userPreferences:${uid}:${emailName}`
        }
        await axios.put(baseURL + `/rest/appconfigs/v1/${id}`, viewUpdate, { headers });
        // return res.data;   

      });
    }
    if (res.data.length === 0){
      let newPref = Object.assign({}, initialUserPreferencesState, {
        type: `userPreferences:${email}`,
      })
      return userPreferencesService.upsertSettings(newPref)
    }
    return res.data[0];
  },
  upsertSettings: async (
    userConfig: iUserPrefs,
    id?: string
  ) => {
    if (!userConfig.id){
      const newId = uuidv4()
      userConfig.id = newId
      id = newId
    }
    const baseURL = useConfigStore.getState().config.baseApiGatewayUrl;
    const { headers } = getAxiosConfig();
    const res = await axios.put(baseURL + `/rest/appconfigs/v1/${id}`, userConfig, {
      headers
    });
    return res.data;
  },
  deleteSettings: async (baseURL: string, id: string) => {
    const { headers } = getAxiosConfig();
    const res = await axios.delete(baseURL + `/rest/appconfigs/v1/${id}`, { headers });
    return res.data;
  }
};

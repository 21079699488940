import { create } from 'zustand';
import {
  iStatusButtons,
  iStatusButtonsStore,
  initialStatusButtonsStoreValues
} from './models';

export const useStatusFilterStore = create<iStatusButtonsStore>()(
  (set, get) => ({
    ...initialStatusButtonsStoreValues,
    GetStatusButtonClicked: () => {
      return get().statusButtons;
    },
    SetStatusButtonClicked: (buttons: iStatusButtons) => {
      set({ statusButtons: buttons });
    }
  })
);

import { iSearch } from '../Search/models';

export interface iStatusCounts {
  error: number;
  failure: number;
  processing: number;
  pending: number;
  completed: number;
}

export const initialStatusCountsValue: iStatusCounts = {
  error: 0,
  failure: 0,
  processing: 0,
  pending: 0,
  completed: 0
};

export interface iStatusCountsStore {
  statusCounts: iStatusCounts;
  offeringStatusCounts: iStatusCounts;
  isLoading: boolean;
  isFetching: boolean;
  statusField: string;
  SetOfferingStatusCounts: (newCounts: iStatusCounts) => void;
  SetStatusCounts: (newCounts: iStatusCounts) => void;
  SetStatusField: (newStatusField: string) => void;
  SetFieldStatusChartData: (
    fetchStatusCount: (
      startDate: Date | null,
      endDate: Date | null,
      searchState: iSearch,
      milestoneName: string
    ) => Promise<any>,
    apiName?: string
  ) => void;
}

export const initialStatusCountsStore: iStatusCountsStore = {
  statusCounts: initialStatusCountsValue,
  offeringStatusCounts: initialStatusCountsValue,
  isLoading: false,
  isFetching: false,
  statusField: 'offering-footprint',
  SetOfferingStatusCounts: (newCounts: iStatusCounts) => {},
  SetStatusCounts: (newCounts: iStatusCounts) => {},
  SetStatusField: (newStatusField: string) => {},
  SetFieldStatusChartData: () => {}
};

import { Box, Overlay, useComputedColorScheme } from '@mantine/core';
import { useClickOutside, useHotkeys, useViewportSize } from '@mantine/hooks';
// import useStyles from '../../theme/globalStyles';
import { useMessagePopOverStore } from '../MessagePopovers/store';
import { useDetailsStore } from '../Views/store';

export interface iDetailsProps {
  children: JSX.Element;
  top?: string | number | undefined;
  left?: string | number | undefined;
  bottom?: string | number | undefined;
  right?: string | number | undefined;
}

export const Details = ({ ...props }: iDetailsProps) => {
  const detailsVisible = useDetailsStore((state) => state.detailsVisible);
  const setDetailsVisible = useDetailsStore((state) => state.SetDetailsVisible);
  const rawMessagePopoverOpen = useMessagePopOverStore((state) => state.IsOuterOpen)
  const ref = useClickOutside(() => {
    if (rawMessagePopoverOpen) return;
    // setDetailsVisible(false)
  });
  useHotkeys([['Escape', () => setDetailsVisible(false)]]);
  // const { classes } = useStyles();
  // const theme = useMantineTheme();
  const top = props.top || 100;
  const left = props.left || 100;
  const bottom = Number(props.bottom) + 80 || 100;
  const right = Number(props.right) || 100;
  const { height, width } = useViewportSize();
  const colorScheme = useComputedColorScheme('light');

  return (
    <>
      <Overlay
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 9998,
          display: detailsVisible ? 'block' : 'none'
        }}
        onClick={() => setDetailsVisible(false)}
        color="#000"
        opacity={colorScheme === 'dark' ? 0.85 : 0.65}
      />
      <Box
        ref={ref}
        // className={classes.baseBox}
        style={{
          maxHeight: height - 70,
          maxWidth: width - 40,
          height: '90%',
          position: 'absolute',
          top: top,
          left: left,
          bottom: bottom,
          right: right,
          zIndex: 9999,
          borderRadius: 24,
          padding: 16,
          overflowY: 'auto',
          display: detailsVisible ? 'block' : 'none',
          backgroundColor:
            colorScheme === 'dark'
              ? '#181D1F'
              : '#fff',
          border: '1px solid',
          borderColor:
            colorScheme === 'dark'
              ? '#444'
              : '#ccc',
          // borderRadius: 'var(--mantine-radius-md)',
          // padding: 8,
          // height: '100%'
        }}
      >
        {props.children}
      </Box>
    </>
  );
};

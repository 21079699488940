import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { iMultiWindowComms, initialMultiWindowCommsValues } from './models';

export const useMultiWindowCommsStore = create<iMultiWindowComms>()(
  devtools((set, get) => ({
    ...initialMultiWindowCommsValues,
    ToggleEnabled:() => {
      set({ Enabled: !get().Enabled })
    },
    SetEnabled: (newState: boolean) => {
      set({ Enabled: newState })
    }
  }))
);

export interface iDataPanelAccordion {
  Items: string | null;
  DefaultAccordionItem: string | null;
  SetItems: (newItem:string | null) => void;
  SetDefaultItem: (newItem:string | null) => void;
}

export const initialDataPanelAccordionValues = {
  Items: 'Video',
  DefaultAccordionItem: 'Video',
  SetItem: (newItem:string | null) => {},
  SetDefaultItem: (newItem:string | null) => {}
}

import { GridApi } from 'ag-grid-community';
import { Option } from './StatusHierarchy';

export type StatusCode = {
  _id?: string; // because the backend may send this, and it must be deleted on edit
  id: string; // uuid
  type: string; // should always be "statusCodes"
  appService: string;
  appName: string;
  appFunction: string;
  footprints: StatusCodeFootprint[]; //
  status: string;
  statusNumber: string;
  statusCode: string;
  badgeColor: string;
  badgeTextColor: string;
  badgeText: string;
  details: string;
  description: string;
  enableBPAlert: boolean;
  supportLink: string;
  deprecated: boolean;
  overridden?: boolean;
};

export type StatusCodeFootprint = {
  footprint: string;
  process: string;
  activity: string;
  task: string;
};

export const initialStatusCodeFootprint = {
  footprint: '',
  process: '',
  activity: '',
  task: ''
};

export const initialStatusCode: StatusCode = {
  id: '',
  type: '',
  footprints: [
    {
      footprint: '',
      process: '',
      activity: '',
      task: ''
    } as StatusCodeFootprint
  ],
  appService: '',
  appName: '',
  appFunction: '',
  status: 'unk',
  statusNumber: '0000',
  statusCode: '',
  badgeColor: '',
  badgeTextColor: '',
  badgeText: '',
  details: '',
  description: '',
  enableBPAlert: false,
  supportLink: '',
  deprecated: false,
  overridden: false
};

export interface StatusCodeStore {
  includeDepreciated: boolean;
  statusCode: StatusCode;
  statusCodeCount: number;
  statusCodes: StatusCode[];
  searchStatusCode: string;
  formIsEditing: boolean;
  footprints: StatusCodeFootprint[];
  footprintIndex: number;
  footprintIsDirty: boolean;
  fullGrid: boolean;
  gridAPI: GridApi | undefined;
  hierarchy: Option[];
  DeleteFootprint: () => void;
  FetchStatusCodes: () => Promise<void>;
  GetFootprintRow: (index: number) => StatusCodeFootprint;
  GetHierarchy: () => Option[];
  GetStatusCode: (urn: string) => StatusCode;
  ResetFootprints: () => void;
  SetGridAPI: (grid: any) => void;
  SetFootprint: (newFootprint: StatusCodeFootprint) => void;
  SetFootprintIsDirty: (explicit: boolean) => void;
  SetFootprints: (newFootprints: StatusCodeFootprint[]) => void;
  SetFootprintsIndex: (newFootprintIndex: number) => void;
  SetIncludeDepreciated: (explicit?: boolean) => void;
  SetStatusCode: (newStatusCode: StatusCode) => void;
  SetStatusCodeCount: (count: number) => void;
  SetSearchStatusCode: (newSeachStatusCode: string) => void;
  ToggleFormIsEditing: (explicit?: boolean) => void;
  ToggleFullGrid: (explicit?: boolean) => void;
  UpdateStatusCode: (newStatusCode: StatusCode) => void;
}

import { getAxiosConfig } from './../axios/config';
import axios from 'axios';

export const FootprintsService = {
  getFootprints: async (baseURL: string) => {
    const { headers } = getAxiosConfig();
    const res = await axios.get(baseURL + '/rest/appconfigs/v1?type=footprint:', { headers })

    return res.data;
  }
};

export interface iItemType {
  id?: string;
  label: string;
  value: string;
  group?: string;
  children?: iItemType[];
  footprint?: string[];
  path?: string;
  chosen?: boolean;
}
export interface iSelectItem {
  id: string;
  value: string;
  label: string;
  footprint: string;
  role?: string;
}
export interface iSelectData {
  group: string,
  items: iSelectItem[]
}

export interface iSelectDataSource {
  label: string;
  value: string;
  footprint?: string[];
  role?: string,
  children?: iSelectDataSource[];
}

export interface iSelectSearchInputsSource {
  label: string;
  value: string;
  footprint?: { footprint: string; path: string }[];
}

export interface iFieldMapping {
  path: string;
  value: string;
}
export interface iListSortable {
  List: string[];
  IsEditing: string;
  Draggable: boolean;
  SetList: (newList: string[]) => void;
  SetIsEditing: (newState: string) => void;
  SetDraggable: (newState: boolean) => void;
  ToggleDraggable: () => void;
}

export const initialListSortableValues = {
  List: [] as string[],
  IsEditing: '',
  Draggable: false,
  SetList: (newList: string[]) => {},
  SetIsEditing: (newState: string) => {},
  SetDraggable: (newState: boolean) => {},
  ToggleDraggable: () => {}
};

import { Box, Stack, Textarea } from '@mantine/core';
import { Button } from '../../components';
import { CommentList } from './CommentList';
import { useState } from 'react';
import { CommentsService } from '../../services/comments/commentsService';

export const CommentPanel = (props: { rowId: string; id: string }) => {
  const [comment, setComment] = useState('');
  const addCommentHandler = async () => {
    if (comment) {
      await CommentsService.createComment(comment, props.id, props.rowId);
      setComment('');
    }
  };
  return (
    <Box style={{ width: 380, minHeight: 200 }}>
      <Stack>
        <CommentList id={props.id} rowId={props.rowId} />
        <Textarea
          placeholder="Your comment"
          label="New Comment"
          withAsterisk
          onChange={(a) => setComment(a.target.value)}
          autosize
          minRows={2}
        />
        <Button
          onClick={() => addCommentHandler()}
          testid=":addCommentsBtn"
          disabled={!comment.trim()}
          base="add"
          label="Add Comment"
        />
      </Stack>
    </Box>
  );
};

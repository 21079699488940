import { Center, Group, useComputedColorScheme } from '@mantine/core';
import { Button } from '../../components/Button';
import { RotateClockwise } from 'tabler-icons-react';
import { iStatusButtons } from '../StatusFilterBar/models';
import { useViewStore } from '../Views/store';
import { useSavedViewsStore } from '../SavedViews/store';
import { useStatusFilterStore } from '../StatusFilterBar/store';
import { useNavigate } from "react-router";
import { useHUDStore } from '../../components/Hud/state';
import { initialSearchValues } from '../Search/models';

export const ResetViewFilters = () => {
  // const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');
  const personal = useSavedViewsStore((state) => state.personal);
  const global = useSavedViewsStore((state) => state.global);
  const gridCurrentAPI = useViewStore((state) => state.grid?.current?.api);
  const gridCurrentColumnApi = useViewStore((state) => state.grid?.current?.columnApi);
  const SetActiveView = useSavedViewsStore((state) => state.SetActiveView);
  const navigate = useNavigate();

  const setStatusButtonClicked = useStatusFilterStore(
    (state) => state.SetStatusButtonClicked
  );
  const hudFilterView = useHUDStore((state) => state.hudFilterView);
  const SetHUDFilterView = useHUDStore((state) => state.SetHUDFilterView);
  const onClick = (event: any) => {
    const activeID = useSavedViewsStore.getState().active.id
    const activeView = [...personal, ...global].find((v) => v.id === activeID);
    gridCurrentAPI.setFilterModel(activeView!.filters);
    gridCurrentColumnApi.applyColumnState({
      state: activeView!.columnState,
      applyOrder: true
    });
    if (hudFilterView.field) {
      // reset so this doesnt run again
      SetHUDFilterView({
        footprint:'',
        field: '',
        search: initialSearchValues
      });
    }
    let newButtonClicked: iStatusButtons = {
      error: false,
      failure: false,
      processing: false,
      pending: false,
      completed: false
    };
    newButtonClicked[event.currentTarget.value as keyof typeof newButtonClicked] = true;
    setStatusButtonClicked(newButtonClicked);
    SetActiveView(activeID);
    navigate('/views');
  };

  return (
    <Center>
      <Group>
        <Button
          testid="reset-to-default-grid-button"
          onClick={onClick}
          variant="subtle"
          style={{
            color: 'gray'
          }}
          tooltip={{
            label: 'Reset to Default Grid',
            position: 'top',
            color: colorScheme === 'dark' ? 'gray' : 'black'
          }}
          leftSection={<RotateClockwise style={{ marginRight: -2 }} size={24} />}
        />
      </Group>
    </Center>
  );
};

import { Cascader as AntDCascader, ConfigProvider, theme as andtdTheme } from 'antd';
import {
  Group,
  useMantineTheme,
  Text,
  Stack,
  useComputedColorScheme,
  lighten,
  darken
} from '@mantine/core';
import { useSearchStore } from '../../containers/Search/store';
import { globalColors as colors } from '../../theme';
import { DefaultOptionType } from 'antd/es/cascader';
import styled from '@emotion/styled';
import { useEffect, useState } from 'react';

export interface iCascaderProps {
  id: string;
  data: string[][];
  field: string;
  name: string;
  noOpen: boolean;
  options: string[][];
  onChange?: {};
}

export const Cascader = (props: any) => {
  const { id, data, field, name, noOpen, options, disabled } = props;
  const { UpdateSearchInput, search } = useSearchStore((state) => state);
  const [showAll, setShowAll] = useState(false);
  const { timeRelative } = search;
  const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');

  const cascaderHeight =
    document?.querySelector?.(`#${id} .ant-select-selection-overflow`)?.clientHeight ||
    40;

  useEffect(() => {
    if (cascaderHeight < 100 && showAll) setShowAll(false);
  }, [cascaderHeight]);

  const NoOpenCascaderWrapper = styled(Group)`
    position: relative;
    .ant-select-selector {
      max-height: ${showAll ? '100%' : '100px'};
      padding: ${cascaderHeight > 90 ? '0 0 30px 0' : '0'};
      overflow-y: hidden;
    }
    .more {
      position: absolute;
      bottom: 1px;
      left: 12px;
      background-color: ${colorScheme === 'dark' ? '#030315' : '#e6efff'};
      color: ${colors.blue[7]};
      font-size: 12px;
      width: 94%;
      cursor: pointer;
      font-weight: bold;
    }
  `;

  function onChange(props: any) {
    UpdateSearchInput({ field: field, value: props! });
  }

  const getTextColor = () => {
    if (noOpen) {
      return timeRelative ? colors.blue[7] : colors.violet[6];
    }
    return colorScheme === 'dark' ? theme.colors.gray[1] : theme.colors.gray[7];
  };

  const getCrumbColor = () => {
    if (noOpen) {
      return colorScheme === 'dark' ? darken(colors.wbdblue[9], 0.4) : colors.wbdblue[0];
    }
    return colorScheme === 'dark' ? darken(colors.wbdblue[9], 0.4) : colors.wbdblue[0];
  };

  const getBorderColor = () => {
    if (noOpen) {
      return timeRelative ? colors.blue[7] : colors.violet[6];
    }
    return colorScheme === 'dark' ? lighten(colors.wbdblue[9], 0.15) : '#B8D2FD';
  };

  const displayRender = (labels: string[], selectedOptions?: DefaultOptionType[]) =>
    labels.map((label, i) => {
      const option = selectedOptions![i];
      if (!option) {
        return '';
      }
      if (i === labels.length - 1) {
        return <span key={option.value}>{label}</span>;
      }
      return <span key={option.value}>{label} &gt; </span>;
    });

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(
      (option) =>
        (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );

  return (
    <Group style={{ gap: 3 }}>
      <ConfigProvider
        theme={{
          algorithm:
            colorScheme === 'dark'
              ? andtdTheme.darkAlgorithm
              : andtdTheme.defaultAlgorithm,
          token: {
            borderRadiusSM: 12,
            colorBgContainer:
              colorScheme === 'dark' ? darken(colors.wbdblue[9], 0.4) : colors.wbdblue[0],
            colorBorder: getBorderColor(),
            colorPrimaryHover:
              colorScheme === 'dark' ? lighten(colors.wbdblue[9], 0.15) : '#B8D2FD',
            colorText: getTextColor(),
            colorFillSecondary: getCrumbColor(),
            fontSize: 13
          }
        }}
      >
        {noOpen ? (
          <Group>
            <Text
              c={colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[8]}
              // mb={-14}
              mr={-8}
            >
              {name}:
            </Text>
            <NoOpenCascaderWrapper id={id}>
              <AntDCascader
                disabled={disabled}
                displayRender={displayRender}
                size="small"
                open={false}
                suffixIcon={<></>}
                style={{ width: '100%', maxWidth: 400, fontSize: '12px' }}
                id={id}
                autoFocus={false}
                multiple
                value={data}
                onChange={onChange}
                options={options}
                placeholder={`Select ${name}(s)`}
                dropdownStyle={{ zIndex: 10000 }}
                showSearch={{ filter }}
              />
              {cascaderHeight > 100 && (
                <div className="more" onClick={() => setShowAll(!showAll)}>
                  {showAll ? '- Less' : '+ More'}
                </div>
              )}
            </NoOpenCascaderWrapper>
          </Group>
        ) : (
          <Stack>
            <Text
              size={'.75rem'}
              c={colorScheme === 'dark' ? theme.colors.gray[1] : theme.colors.gray[7]}
              mb={-14}
            >
              {name}
            </Text>
            <AntDCascader
              disabled={disabled}
              id={id}
              autoFocus={false}
              multiple
              value={data}
              onChange={onChange}
              options={options}
              placeholder={`Select ${name}`}
              dropdownStyle={{ zIndex: 10000 }}
              showSearch={{ filter }}
            />
          </Stack>
        )}
      </ConfigProvider>
    </Group>
  );
};

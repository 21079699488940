import { Accordion, Text, useMantineTheme, useComputedColorScheme, lighten, darken } from '@mantine/core';
import { StatusColor, StatusIcon } from '../../components/';
// import { AccordionProviderProps } from '@mantine/core/lib/Accordion/AccordionProvider';
import { ChevronRight, ChevronUp } from 'tabler-icons-react';
import { useDataPanelAccordionStore } from './store';
import { getStatus } from '../../utils/getStatus';

interface iDataPanelAccordionItemProps {
  name: string;
  title: string;
  status: string;
  colorText?: boolean;
  disabled?: boolean;
  content: any;
}

export const DataPanelAccordionItem = (props: iDataPanelAccordionItemProps) => {
  const status = getStatus(props.status)
  const statusColor = StatusColor();
  const theme = useMantineTheme();
  const item = useDataPanelAccordionStore((state) => state.Items)
  const colorScheme = useComputedColorScheme('light')
  function color() {
    return colorScheme === 'dark' ? 'white' : 'gray';
    // return theme.colorScheme === 'dark'
    //   ? lighten(statusColor[props.status], 0.8)
    //   : theme.fn.darken(statusColor[props.status], 0.8);
  }

  function backgroundColor() {
    return colorScheme === 'dark'
      ? darken(statusColor[status], 0.5)
      : lighten(statusColor[status], 0.8);
  }

  if (props.disabled) {
    return (
      <Accordion.Item value={props.name}>
        <Accordion.Control
          disabled
          icon={<StatusIcon size={14} status={props.status} color={color()} />}
          chevron={
            item === props.name ? (
              <ChevronUp size={16} strokeWidth={3} />
            ) : (
              <ChevronRight size={16} strokeWidth={2} />
            )
          }
          style={{
            color: color(),
            backgroundColor: backgroundColor(),
            border: `1px solid ${
              item === props.name
                ? statusColor[status]
                : darken(statusColor[status], 0.6)
            }`,
            borderRadius: 6,
            padding: 6,
            height: 35
          }}
        >
          <Text
            size="sm"
            color={
              props.colorText
                ? colorScheme === 'dark'
                  ? theme.white
                  : theme.black
                : colorScheme === 'dark'
                ? 'white' // theme.fn.lighten(statusColor[status], 0.4)
                : 'gray' //theme.fn.darken(statusColor[status], 0.5)
            }
          >
            {props.title}
          </Text>
        </Accordion.Control>
        <Accordion.Panel>{props.content}</Accordion.Panel>
      </Accordion.Item>
    );
  }
  return (
    <Accordion.Item value={props.name}>
      <Accordion.Control
        icon={<StatusIcon size={14} status={status} color={color()} />}
        chevron={
          item === props.name ? (
            <ChevronUp size={16} strokeWidth={3} />
          ) : (
            <ChevronRight size={16} strokeWidth={2} />
          )
        }
        style={{
          color: color(),
          backgroundColor: backgroundColor(),
          border: `1px solid ${
            item === props.name
              ? statusColor[status]
              : darken(statusColor[status], 0.6)
          }`,
          borderRadius: 6,
          padding: 6,
          height: 35
        }}
      >
        <Text
          size="sm"
          c={
            props.colorText
              ? colorScheme === 'dark'
                ? theme.white
                : theme.black
              : colorScheme === 'dark'
              ? 'white' // theme.fn.lighten(statusColor[props.status], 0.4)
              : 'black' // theme.fn.darken(statusColor[props.status], 0.5)
          }
        >
          {props.title}
        </Text>
      </Accordion.Control>
      <Accordion.Panel>{props.content}</Accordion.Panel>
    </Accordion.Item>
  );
};

import {
  MultiSelect as MantineMultiselect,
  MultiSelectProps,
  useComputedColorScheme,
} from '@mantine/core';

interface IMultiSelectProps extends MultiSelectProps {
  inputPropName: string;
  data: { value: string; label: string }[];
}

export const MultiSelect = (props: IMultiSelectProps) => {
  const colorScheme = useComputedColorScheme('light');
  const { inputPropName, data, ...mantineMultiselectProps } = props;
  // var dataSize = Object.keys(data).length;
  //search bar values that use "all" option must have
  //this value key as lower case
  // const ALL = 'all';

  // const existAllItem = data.some((itemValue) => {
  //   return Object.values(itemValue).includes(ALL);
  // });

  /**
   * If the "all" option exists, using this option removes the other
   * selected options, if all multi-selector elements are selected,
   * all those will be removed and the "all" option will be placed
   * @param selectedItems
   */
  // const onMultiSelectChange = (selectedItems: any) => {
  //   if (selectedItems.includes(ALL)) {
  //     if (form.values[inputPropName].includes(ALL)) {
  //       selectedItems.splice(selectedItems.indexOf(ALL), 1);
  //       form.setFieldValue(inputPropName, selectedItems);
  //     } else {
  //       form.setFieldValue(inputPropName, [ALL]);
  //     }
  //   } else {
  //     if (selectedItems.length === dataSize - 1) {
  //       form.setFieldValue(inputPropName, [ALL]);
  //     } else {
  //       form.setFieldValue(inputPropName, selectedItems);
  //     }
  //   }
  // };

  return (
    <MantineMultiselect
      {...mantineMultiselectProps}
      classNames={{
        // item: classes.item,
        input: colorScheme === 'dark' ? 'inputDark' : 'multiselect',
        label: 'item'
      }}
      data={data}
      onChange={props.onChange}
    />
  );
};

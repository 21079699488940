import { ColDef } from 'ag-grid-community';
import {
  footprintStatusGetter,
  startDateFormatter,
  segmentDateFormatter,
  milestoneStatusGetter,
  updatedDateGetter,
  createdDateGetter,
  updatedScheduleDateGetter,
  networkNameGetter,
  contentTypeGetter,
  scheduleTypeGetter,
  segmentDateFormatterUTC,
  milestoneDateGetter,
  milestoneCodeGetter,
  componentCountGetter,
  isLiveGetter,
  feedGetter,
  networkAbbreviationGetter,
  seriesTitleGetter,
  seasonTitleGetter,
  titleGetter,
  entityTypeGetter,
  latestCommentGetter,
  milestoneDetailGetter,
  relatedAcquisitionStatusGetter,
  acquisitionUpdatedDateGetter,
  acquisitionCodeGetter,
  acquisitionDetailGetter,
  combinedColumnGetter,
  complianceGetter,
  hdrFrameSizeGetter,
  sdrFrameSizeGetter,
  hdrPictureAspectRatioGetter,
  sdrPictureAspectRatioGetter,
  hdrScanningFormatGetter,
  sdrScanningFormatGetter,
  ratingsGetter,
  endDateFormatter
} from './linearValueGetters';
import { comparator, dateComparator } from '../comparators';
import {
  fulfilledAudioComponentsGetter,
  fulfilledTimedTextComponentsGetter,
  fulfilledMarkersGetter,
  fulfilledAnnotationslabelsGetter,
  fulfilledMediaDurationGetter,
  dynamicRangeIndicatorGetter
} from '../columnsHelpers/fulfilledComponentsGetters';
import { taskCIDGetter } from '../columnsHelpers/globalValueGetters';
import {
  activePresManifAudioComponentsGetter,
  activePresManifTimedTextComponentsGetter,
  activePresManifMarkersGetter,
  activePresManifAnnotationslabelsGetter,
  activePresManifMediaDurationGetter,
  activePresManifDynamicRangeIndicatorGetter
} from '../columnsHelpers/activePresManifCompGetters';

export function SetLinearValueGetters(columnDefs: ColDef[]) {
  columnDefs.forEach((cd) => {
    let keys = Object.keys(cd);
    if (keys.includes('valueGetter')) {
      if (typeof cd.valueGetter === 'string') {
        switch (cd.valueGetter) {
          case 'footprintStatusGetter':
            cd.valueGetter = footprintStatusGetter;
            break;
          case 'startDateFormatter':
            cd.valueGetter = startDateFormatter;
            break;
          case 'endDateFormatter':
            cd.valueGetter = endDateFormatter;
            break;
          case 'segmentDateFormatter':
            cd.valueGetter = segmentDateFormatter;
            break;
          case 'segmentDateFormatterUTC':
            cd.valueGetter = segmentDateFormatterUTC;
            break;
          case 'createdDateGetter':
            cd.valueGetter = createdDateGetter;
            break;
          case 'updatedDateGetter':
            cd.valueGetter = updatedDateGetter;
            break;
          case 'updatedScheduleDateGetter':
            cd.valueGetter = updatedScheduleDateGetter;
            break;
          case 'milestoneStatusGetter':
            cd.valueGetter = milestoneStatusGetter;
            break;
          case 'networkNameGetter':
            cd.valueGetter = networkNameGetter;
            break;
          case 'networkAbbreviationGetter':
            cd.valueGetter = networkAbbreviationGetter;
            break;
          case 'contentTypeGetter':
            cd.valueGetter = contentTypeGetter;
            break;
          case 'scheduleTypeGetter':
            cd.valueGetter = scheduleTypeGetter;
            break;
          case 'milestoneDateGetter':
            cd.valueGetter = milestoneDateGetter;
            break;
          case 'milestoneCodeGetter':
            cd.valueGetter = milestoneCodeGetter;
            break;
          case 'componentCountGetter':
            cd.valueGetter = componentCountGetter;
            break;
          case 'isLiveGetter':
            cd.valueGetter = isLiveGetter;
            break;
          case 'feedGetter':
            cd.valueGetter = feedGetter;
            break;
          case 'seriesTitleGetter':
            cd.valueGetter = seriesTitleGetter;
            break;
          case 'seasonTitleGetter':
            cd.valueGetter = seasonTitleGetter;
            break;
          case 'titleGetter':
            cd.valueGetter = titleGetter;
            break;
          case 'entityTypeGetter':
            cd.valueGetter = entityTypeGetter;
            break;
          case 'latestCommentGetter':
            cd.valueGetter = latestCommentGetter;
            break;
          case 'milestoneDetailGetter':
            cd.valueGetter = milestoneDetailGetter;
            break;
          case 'fulfilledAudioComponentsGetter':
            cd.valueGetter = fulfilledAudioComponentsGetter;
            break;
          case 'fulfilledTimedTextComponentsGetter':
            cd.valueGetter = fulfilledTimedTextComponentsGetter;
            break;
          case 'fulfilledMarkersGetter':
            cd.valueGetter = fulfilledMarkersGetter;
            break;
          case 'fulfilledAnnotationslabelsGetter':
            cd.valueGetter = fulfilledAnnotationslabelsGetter;
            break;
          case 'fulfilledMediaDurationGetter':
            cd.valueGetter = fulfilledMediaDurationGetter;
            break;
          case 'dynamicRangeIndicatorGetter':
            cd.valueGetter = dynamicRangeIndicatorGetter;
            break;
          case 'relatedAcquisitionStatusGetter':
            cd.valueGetter = relatedAcquisitionStatusGetter;
            break;
          case 'acquisitionUpdatedDateGetter':
            cd.valueGetter = acquisitionUpdatedDateGetter;
            break;
          case 'acquisitionCodeGetter':
            cd.valueGetter = acquisitionCodeGetter;
            break;
          case 'acquisitionDetailGetter':
            cd.valueGetter = acquisitionDetailGetter;
            break;
          case 'fulfilledAudioComponentsGetter':
            cd.valueGetter = fulfilledAudioComponentsGetter;
            break;
          case 'fulfilledTimedTextComponentsGetter':
            cd.valueGetter = fulfilledTimedTextComponentsGetter;
            break;
          case 'fulfilledMarkersGetter':
            cd.valueGetter = fulfilledMarkersGetter;
            break;
          case 'fulfilledAnnotationslabelsGetter':
            cd.valueGetter = fulfilledAnnotationslabelsGetter;
            break;
          case 'fulfilledMediaDurationGetter':
            cd.valueGetter = fulfilledMediaDurationGetter;
            break;
          case 'dynamicRangeIndicatorGetter':
            cd.valueGetter = dynamicRangeIndicatorGetter;
            break;
          case 'complianceGetter':
            cd.valueGetter = complianceGetter;
            break;
          case 'hdrFrameSizeGetter':
            cd.valueGetter = hdrFrameSizeGetter;
            break;
          case 'sdrFrameSizeGetter':
            cd.valueGetter = sdrFrameSizeGetter;
            break;
          case 'hdrPictureAspectRatioGetter':
            cd.valueGetter = hdrPictureAspectRatioGetter;
            break;
          case 'sdrPictureAspectRatioGetter':
            cd.valueGetter = sdrPictureAspectRatioGetter;
            break;
          case 'hdrScanningFormatGetter':
            cd.valueGetter = hdrScanningFormatGetter;
            break;
          case 'sdrScanningFormatGetter':
            cd.valueGetter = sdrScanningFormatGetter;
            break;
          case 'taskCIDGetter':
            cd.valueGetter = (params) =>
              taskCIDGetter(params?.data?.tasks, 'mezzcreation');
            break;
          case 'ratingsGetter':
            cd.valueGetter = ratingsGetter;
            break;
          case 'combinedColumnGetter':
            cd.valueGetter = combinedColumnGetter;
            break;
          case 'activePresManifAudioComponentsGetter':
            cd.valueGetter = activePresManifAudioComponentsGetter;
            break;
          case 'activePresManifTimedTextComponentsGetter':
            cd.valueGetter = activePresManifTimedTextComponentsGetter;
            break;
          case 'activePresManifMarkersGetter':
            cd.valueGetter = activePresManifMarkersGetter;
            break;
          case 'activePresManifAnnotationslabelsGetter':
            cd.valueGetter = activePresManifAnnotationslabelsGetter;
            break;
          case 'activePresManifMediaDurationGetter':
            cd.valueGetter = activePresManifMediaDurationGetter;
            break;
          case 'activePresManifDynamicRangeIndicatorGetter':
            cd.valueGetter = activePresManifDynamicRangeIndicatorGetter;
            break;
          default:
            break;
        }
      }
    }
    if (keys.includes('comparator')) {
      if (typeof cd.comparator === 'string') {
        switch (cd.comparator) {
          case 'comparator':
            cd.comparator = comparator;
          case 'dateComparator':
            cd.comparator = dateComparator;
        }
      }
    }
  });
}

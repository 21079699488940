import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { iInformationAccordion, initialInformationAccordionValues } from './models';

export const useInformationAccordionStore = create<iInformationAccordion>()(
  devtools((set, get) => ({
    ...initialInformationAccordionValues,
    SetItems: (newItems: string[]) => {
      set({ Items: newItems })
    }
  }))
);

import { custom } from '../vendor/react-test-id/dist';

export const useTID = (name: string, testid: string) => {
  const [, useRole, ] = custom({
    attr: "data-testid",
    display: /\bwithRoles\b/.test(globalThis.location.search),
    separator: ":",
  });
  const TID = useRole(':'+ name + '-' + testid);
  return TID
}

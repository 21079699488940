import { getAxiosConfig } from '../axios/config';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useConfigStore } from '../../app/store';
import { iAppConfig } from '../../components/AppConfigs/models';

export const AppConfigsService = {
  getAppConfigs: async (type: string): Promise<iAppConfig[]> => {
    const baseURL = useConfigStore.getState().config.baseApiGatewayUrl;
    const { headers } = getAxiosConfig();
    const res = await axios.get(baseURL + '/rest/appconfigs/v1?type=' + type, {
      headers
    });
    return res.data;
  },

  upsertAppConfig: async (appConfig: iAppConfig): Promise<any> => {
    const baseURL = useConfigStore.getState().config.baseApiGatewayUrl;
    const { headers } = getAxiosConfig();

    // Add Id, if it does not already have one.
    if (appConfig.id == undefined || appConfig.id.length == 0) {
      appConfig.id = uuidv4();
    }

    // Set App.config schema type
    // if(appConfig.type == undefined || appConfig.type.length == 0) {
    //   appConfig.type = "dropdown";
    // }

    try{
      const res = await axios.put(
        baseURL + `/rest/appconfigs/v1/` + appConfig.id,
        appConfig,
        {
          headers
        }
        );
        alert('Successfully generated Status Code Hierarchy')
        return res.data;
    } catch(err: any){
      if(err){
        alert(err);
        return err
      }
    }
  }
};

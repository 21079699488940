import { Stack, Switch, Text, useMantineTheme } from '@mantine/core';
import { ListSortable } from '../../components/ListSortable';
import { useSavedViewsStore } from './store';
import _ from 'lodash';
import { useFootprintStore } from '../Footprints/store';

export const SavedViewsList = () => {
  const personal = useSavedViewsStore((state) => state.personal);
  const list = useSavedViewsStore((state) => state.list);
  // const SetList = useSavedViewsStore((state) => state.SetList);
  const SaveView = useSavedViewsStore((state) => state.SaveActiveView);
  const SetListComplete = useSavedViewsStore((state) => state.SetListComplete);
  const globalList = useSavedViewsStore((state) => state.globalList);
  const SetGlobalList = useSavedViewsStore((state) => state.SetGlobalList);
  const draggable = useSavedViewsStore((state) => state.isDraggable);
  const setDraggable = useSavedViewsStore((state) => state.ToggleIsDraggable);
  const currentFootprint = useFootprintStore((state) => state.active.id);

  const theme = useMantineTheme();
  return (
    <Stack>
      <Text size={'md'}>Personal</Text>
      <div style={{ maxHeight: '350px', overflowY: 'auto' }}>
        {list.length > 0 ? (
          <ListSortable
            list={list.filter(
              (i: { footprint: string }) => i.footprint === currentFootprint
            )}
            draggableDefault={draggable}
            removeable={true}
            editable={true}
            defaultable={true}
            setList={SetListComplete}
          />
        ) : (
          <Text>Looks like you haven't saved any personal searches yet.</Text>
        )}
      </div>
      <Text size={'md'}>Presets</Text>
      <ListSortable
        list={globalList.filter(
          (i: { footprint: string }) => i.footprint === currentFootprint
        )}
        removeable={false}
        draggableDefault={draggable}
        setList={SetGlobalList}
      />
      <Switch
        size="xs"
        label={`${draggable ? '' : 'NOT '} draggable`}
        checked={draggable}
        // color= {draggable? "green": "red"}
        styles={{
          track: {
            background: draggable ? 'green' : theme.colors.red[4]
          }
        }}
        onChange={(event) => {
          setDraggable();
          personal.forEach((v) => {
            v.position = list.findIndex((x: any) => x.value === v.id);
            SaveView(v);
          });
        }}
      />
    </Stack>
  );
};

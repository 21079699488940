import {
  Box,
  Center,
  Divider,
  Group,
  Table,
  Text,
  Tooltip,
  useMantineTheme,
  Button as MButton,
  Select,
  useComputedColorScheme
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { BookUpload, TrashX } from 'tabler-icons-react';
import {
  capitalize,
  createSharedWidgetURL,
  deriveDateFromRelativeString
} from '../../../utils/utilities';
// import { capitalize } from '../../../utils/utilities';
import { useHUDStore } from '../state';
import { iWidget } from '../state/models';
import { RemoveWidgetBox } from './_removeWidgetBox';
import { ShareWidgetBox } from './_shareWidgetBox';
import { getSynopsisLabel } from '../../../containers/SearchSynopsis/SynopsisBadge';
import {
  acquireFields,
  iSearch,
  linearFields,
  offeringFields
} from '../../../containers/Search/models';
import { getRangeString } from '../../DateRangePicker/DateRangePicker';
import { useAppConfigManager } from '../../AppConfigs';
import { iSelectDataSource } from '../../ListSortable/models';
import { offeringPartnerGetter } from '../../../containers/Views/_details/offering/offeringsDetail';

export const InformationBox = ({
  widget,
  defaultDelay,
  setDefaultDelay
}: {
  widget: iWidget;
  defaultDelay: string;
  setDefaultDelay: any;
}) => {
  const [removalOpen, removalHandlers] = useDisclosure(false);
  const [shareWidgetOpen, shareWidgetHandlers] = useDisclosure(false);
  const SetWidgetShareURL = useHUDStore((state) => state.SetWidgetShareURL);
  const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');
  const HUDStore = useHUDStore();
  const GetDropDown = useAppConfigManager((state) => state.GetDropDown);

  function PascalArray(arr: string[]) {
    let copyArr = [...arr].map((item) => item);
    for (var i = 0; i < copyArr.length; i++) {
      let exceptions = copyArr[i].split('","');
      if (exceptions.length > 1) {
        copyArr[i] = exceptions[0].charAt(0).toUpperCase() + exceptions[0].slice(1);
      } else {
        copyArr[i] = copyArr[i].charAt(0).toUpperCase() + copyArr[i].slice(1);
      }
    }
    return copyArr;
  }

  const formattedMilestones = (Milestone: string[]) => {
    let copyArr = [...Milestone].map((item) => {
      if (item.includes('footprint')) return 'Overall Status';
      return item;
    });
    return copyArr;
  };

  const formattedOfferingType = (OfferingType: string[]) => {
    let newTypes: string[] = [];
    OfferingType?.forEach((w) => {
      switch (w) {
        case 'entity_class_cfps_series':
          newTypes.push('series');
          break;
        case 'entity_class_cfps_season':
          newTypes.push('season');
          break;
        case 'entity_class_program':
          newTypes.push('program');
          break;
        case 'promo playlist':
          newTypes.push('playlist');
          break;
        case 'promo playlist entry':
          newTypes.push('entry');
          break;
        default:
          newTypes.push(w);
          break;
      }
    });
    return PascalArray(newTypes);
  };

  function getSearchTerm(uuid: string) {
    if (uuid.length < 50) return uuid;
    return (
      uuid.substring(0, 12) + ' ... ' + uuid.substring(uuid.length - 12, uuid.length)
    );
  }

  const handleShareWidget = async () => {
    const url = await createSharedWidgetURL(widget);
    SetWidgetShareURL(url);
    shareWidgetHandlers.toggle();
  };

  let widgetFootprint = (footprint: string) => {
    let formattedFootprint;
    switch (footprint) {
      case 'footprint-offering':
        formattedFootprint = 'On Demand';
        break;
      case 'footprint-linear':
        formattedFootprint = 'Linear';
        break;
      case 'footprint-acquire':
        formattedFootprint = 'Acquisition';
        break;
      default:
        formattedFootprint = 'On Demand';
        break;
    }
    return formattedFootprint;
  };
  const getDropdownLabel = (field: string, item: string) => {
    field =
      field.split('_').slice(-1)[0].charAt(0).toLowerCase() +
      field.split('_').slice(-1)[0].slice(1);

    const fields = GetDropDown(field);
    let fieldName = fields.find((field) => item === field.value)?.label || item;
    return capitalize(fieldName);
  };

  const WidgetLayout = (props: { children: React.ReactNode; label: string }) => (
    <Table.Tr>
      <Table.Td style={{ textAlign: 'left' }}>{getSynopsisLabel(props.label)}</Table.Td>
      <Table.Td>{props.children}</Table.Td>
    </Table.Tr>
  );

  const findLabelOnFieldsTree = (
    fields: iSelectDataSource[] = [],
    selectionList: string[],
    marketIndex = 0
  ): string => {
    let fieldFinded = fields?.find(
      (field: iSelectDataSource) => field.value === selectionList[marketIndex]
    );
    if (fieldFinded?.children?.length && selectionList[marketIndex + 1]) {
      return findLabelOnFieldsTree(fieldFinded?.children, selectionList, marketIndex + 1);
    }
    return fieldFinded?.label || '';
  };

  const verifyFPHudVar = (widgetSearch: iSearch, footprint: string): iSearch =>
    Object.entries(widgetSearch).reduce((acc, [key, val]) => {
      if (
        (footprint.includes('offering') && offeringFields.includes(key)) ||
        (footprint.includes('linear') && linearFields.includes(key)) ||
        (footprint.includes('acquire') && acquireFields.includes(key))
      ) {
        return { ...acc, [key]: val };
      }
      return acc;
    }, {} as iSearch);

  const formattedScheduleType = (value: string) => {
    if (value === 'air","final') {
      return 'Final';
    }
    return capitalize(value);
  };

  const dynamicSearchWidget = (widgetSearch: iSearch, footprint: string) => {
    return Object.entries(verifyFPHudVar(widgetSearch, footprint)).map(
      ([label, value]) => {
        if (label === 'OfferingType' && value?.length) {
          return (
            <WidgetLayout label={label}>
              {formattedOfferingType(value).join(', ')}
            </WidgetLayout>
          );
        }
        if (label === 'SearchInput' && value) {
          return <WidgetLayout label={label}>{getSearchTerm(value)}</WidgetLayout>;
        }
        if (label === 'SearchInputField' && value) {
          return (
            <WidgetLayout label={label}>
              {getDropdownLabel('searchInputs', value)}
            </WidgetLayout>
          );
        }
        if (label === 'Milestone' && value?.length) {
          return (
            <WidgetLayout label={label}>
              {formattedMilestones(value)?.join(', ')}
            </WidgetLayout>
          );
        }
        if (label === 'MilestoneStatus' && value?.length) {
          return (
            <WidgetLayout label={label}>
              {value
                .map((v: string) => v?.replace(/"/g, '')?.split(','))
                ?.flat()
                ?.map((v: string) => capitalize(v))
                ?.join(', ')}
            </WidgetLayout>
          );
        }
        if (label === 'Partner' && value?.length) {
          return (
            <WidgetLayout label={label}>
              {value.map((v: string) => offeringPartnerGetter(v))?.join(', ')}
            </WidgetLayout>
          );
        }
        if (label === 'ScheduleType' && value) {
          return (
            <WidgetLayout label={label}>{formattedScheduleType(value)}</WidgetLayout>
          );
        }
        if (label === 'DateField' && value !== 'none') {
          return (
            <WidgetLayout label={label}>
              {getDropdownLabel('dateInputs', value)}
            </WidgetLayout>
          );
        }
        if (label === 'startDate' && widget.search?.DateField !== 'none') {
          let start = new Date(widget.search?.startDate);
          let end = new Date(widget.search?.endDate);
          if (widget.search?.timeRelative) {
            start = deriveDateFromRelativeString(
              widget.search?.startDate,
              false,
              widget.search?.startDateTime
            );
            end = deriveDateFromRelativeString(
              widget.search?.endDate,
              true,
              widget.search?.endDateTime
            );
          }
          const dateString = getRangeString([start, end], widget.search?.timeRelative);
          return (
            <WidgetLayout
              label={getDropdownLabel(
                'dateInputs',
                widget.search?.DateField || 'startDate'
              )}
            >
              {dateString}
            </WidgetLayout>
          );
        }
        if (label === 'ContentTypeAcquire' && value?.length) {
          return (
            <WidgetLayout label={label}>
              {value
                .map((v: string) => getDropdownLabel('contentTypesAcquire', v))
                .join(', ')}
            </WidgetLayout>
          );
        }
        if ((label === 'Market' || label === 'Network') && value?.length) {
          const fields = GetDropDown(label.toLowerCase() + 's');
          return (
            <WidgetLayout label={label}>
              {value
                .map((market: string[]) => findLabelOnFieldsTree(fields, market))
                ?.join(', ')}
            </WidgetLayout>
          );
        }
        if (
          [
            'timeRelative',
            'startDateTime',
            'endDateTime',
            'endDate',
            'startDate',
            'dateEnabled'
          ].indexOf(label) !== -1
        ) {
          return null;
        }
        if (label === 'IsLive' && footprint !== 'footprint-linear') return null;
        if (Array.isArray(value) && typeof value?.flat(2)?.[0] === 'string') {
          return (
            <WidgetLayout label={label}>
              {value
                ?.flat(2)
                .map((v: string) => getDropdownLabel(label, v))
                .join(', ')}
            </WidgetLayout>
          );
        }
        if ((typeof value === 'string' && value) || typeof value === 'boolean') {
          return (
            <WidgetLayout label={label}>
              {getDropdownLabel(label, String(value))}
            </WidgetLayout>
          );
        }
      }
    );
  };

  return (
    <Box style={{ margin: 16, height: '100%' }}>
      {!removalOpen && !shareWidgetOpen && (
        <>
          <Center>
            <Text size="md" mr={6}>
              Statuses for{' '}
            </Text>
            <Text size="md" fw={600}>
              {widget.milestoneName}
            </Text>
          </Center>
          <Divider mt={8} />
          <Table
            striped={false}
            // fontSize="xs"
            highlightOnHover={true}
            style={{
              width: '100%',
              display: 'block',
              overflow: 'hidden scroll',
              height: 'calc( 100% - 185px )'
            }}
          >
            <Table.Thead>
              <Table.Tr>
                <Table.Td>&nbsp;</Table.Td>
                <Table.Td>&nbsp;</Table.Td>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              <WidgetLayout label={'Footprint'}>
                {widgetFootprint(widget.footprint)}
              </WidgetLayout>
              {dynamicSearchWidget(widget.search, widget.footprint)}
            </Table.Tbody>
          </Table>
          <Divider mt={8} mb={16} />
          <Select
            label="Refresh Delay"
            size="xs"
            data={[
              { value: '60000', label: '1 minute' },
              { value: '300000', label: '5 minutes' },
              { value: '1800000', label: '30 minutes' },
              { value: '3600000', label: '60 minutes' }
            ]}
            onChange={(value) => {
              setDefaultDelay(value);
              let copyHUD = Object.assign({}, HUDStore.personalHUD);
              let copyWidget = copyHUD.widgets.find((w: iWidget) => w.i === widget.i);
              if (copyWidget) {
                copyWidget.refreshDelay = value as string;
                HUDStore.SavePersonalHUD(copyHUD);
              }
            }}
            value={defaultDelay}
          />
          <Group justify="flex-end">
            <Tooltip label="Share Current Widget">
              <MButton
                variant="subtle"
                // styles={{ leftSection: { marginRight: -2 } }}
                style={{
                  color: 'gray'
                }}
                leftSection={<BookUpload size={24} />}
                onClick={handleShareWidget}
              />
            </Tooltip>
            <TrashX
              onClick={() => removalHandlers.toggle()}
              style={{ cursor: 'pointer' }}
              size={24}
              color={
                colorScheme === 'dark' ? theme.colors.titan[4] : theme.colors.titan[7]
              }
              strokeWidth={1.5}
            />
          </Group>
        </>
      )}
      {removalOpen && !shareWidgetOpen && (
        <>
          <RemoveWidgetBox widget={widget} closeFunc={removalHandlers} />
        </>
      )}
      {!removalOpen && shareWidgetOpen && (
        <>
          <ShareWidgetBox widget={widget} closeFunc={shareWidgetHandlers} />
        </>
      )}
    </Box>
  );
};

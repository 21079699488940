import { Badge } from '@mantine/core';

export interface iDateRangeBadgeProps {
  timeRelative: boolean;
  startDateTime: string;
  endDateTime: string;
}

export const DateRangeBadge = (props: iDateRangeBadgeProps) => {
  const relativeColor = props.timeRelative ? 'cerulean' : 'violet';


  return (
    <Badge ml={-12} size="sm" variant="filled" color={relativeColor}>
    </Badge>
  );
};

import { ColDef } from 'ag-grid-community';

export const dropDownsColumnDef: ColDef = {
  field: 'dropdown',
  sortable: true,
  sortingOrder: ['asc', 'desc', null],
  flex: 1,
  wrapHeaderText: true,
  autoHeaderHeight: true,
  filter: 'agTextColumnFilter',
  filterParams: {
    buttons: ['reset']
  },
  resizable: false,
  cellStyle: {
    padding: 10
  }
};

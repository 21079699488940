import OktaAuth, { AuthState } from "@okta/okta-auth-js";

export interface iOktaAuth {
    oktaAuth: OktaAuth | null
    authState: AuthState | null
    SetOktaAuth: (newOktaAuth: OktaAuth) => void;
    SetAuthState: (newAuthState: AuthState) => void;
}

export const initialOktaAuth = {
    oktaAuth: null,
    authState: null,
    SetOktaAuth: () => {},
    SetAuthState: () => {},
};
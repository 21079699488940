export interface iMultiWindowComms {
  Enabled: boolean;
  ToggleEnabled:() => void;
  SetEnabled: (newState: boolean) => void;
}

export const initialMultiWindowCommsValues = {
  Enabled: false,
  ToggleEnabled:() => {},
  SetEnabled: (newState: boolean) => {}
}

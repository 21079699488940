import { Box, TextInput, TextInputProps, 
  useComputedColorScheme, 
  useMantineTheme
} from '@mantine/core';
import { Search, X } from 'tabler-icons-react';
import { useTID } from '../../helpers/useTestId';
import { useEffect, useState } from 'react';
// import { globalColors as colors } from '../../theme/globalColors';
// import classes from './SearchBar.module.css';
import { useSearchStore } from '../Search/store';

interface ISearchInput extends TextInputProps {
  maxWidth: number;
  handleSearch: Function;
  testid: string;
}

export const SearchBarInput = (props: ISearchInput) => {
  const UpdateSearchInput = useSearchStore((state) => state.UpdateSearchInput);
  const searchInput = useSearchStore((state) => state.search.SearchInput);
  const [inputWidth, setInputWidth] = useState(props.maxWidth);
  const TID = useTID('SearchInput', props.testid);
  const SearchInputTextTID = useTID('SearchInputTextArea', props.testid);
  // const { classes } = useStyles();
  const colorScheme = useComputedColorScheme('light');
  const setSearch = (v: any) => {
    UpdateSearchInput({ field: 'SearchInput', value: v.target.value });
  };
  const theme = useMantineTheme();

  const DoSearchIcon = () => {
    return (
      <Search
        size={14}
        style={{
          cursor: 'pointer',
          color: theme.colors.blue[6]
        }}
        onClick={(x) => x}
      />
    );
  };

  const ResetSearchIcon = () => {
    const resetSearchIconTID = useTID('Icon', 'ResetSearchIcon');
    return (
      <X
        {...resetSearchIconTID}
        color="gray"
        size={14}
        style={{
          cursor: 'pointer'
        }}
        onClick={() => {
          setSearch({ target: { value: '' } });
        }}
      />
    );
  };

  function handleKeyDown(e: any) {
    if (e.keyCode === 13) {
      props.handleSearch(searchInput);
      e.target.blur();
    }
  }

  useEffect(() => {
    if (searchInput.length > 18) {
      const newWidth = 200 + (searchInput.length - 18) * 6.6;
      setInputWidth(newWidth < props.maxWidth ? Math.ceil(newWidth) : props.maxWidth);
    } else {
      setInputWidth(0);
    }
  }, [searchInput]);

  return (
    <Box {...TID}>
      <TextInput
        {...SearchInputTextTID}
        onBlur={(e) => {
          if (e.target.value) {
            UpdateSearchInput({ field: 'DateField', value: 'none' });
          }
        }}
        classNames={{ wrapper: colorScheme === 'dark' ? 'wrapperDark' : 'wrapperLight' }}
        leftSection={DoSearchIcon()}
        placeholder={`Search`}
        variant="unstyled"
        value={searchInput}
        radius="xl"
        size="xs"
        onChange={setSearch}
        onKeyDown={handleKeyDown}
        rightSection={ResetSearchIcon()}
        style={inputWidth ? { width: inputWidth } : {}}
        // style={{
        //   '.mantine-Input-input': {
        //     borderColor:
        //       colorScheme === 'dark'
        //         ? lighten(colors.wbdblue[9], 0.15)
        //         : '#B8D2FD'
        //   }, 
        //   width: inputWidth && inputWidth
        // }}
      />
    </Box>
  );
};

export default SearchBarInput;

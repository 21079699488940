// import React from 'react';
import { LoginCallback } from '@okta/okta-react';
import { Routes, Route } from 'react-router-dom';
import { SecureRoute } from './SecureRoute';
import { Hud } from '../../components/Hud';
// import { FootprintProvider } from '../../containers/Footprints/provider';
import { hasOKTAGroup } from '../../utils/oktaGroups';
import { Admin } from '../../containers/Admin/Admin';
import { Home } from '../../containers/Home';
import { Loading } from './Loading';
import { Views } from '../../containers/Views';
import NotAuthorized from '../../pages/NotAuthorized';
import NotFoundPage from '../../pages/NotFoundPage';
import { useConfigStore } from '../store';
import { DetailsSolo } from '../../containers/Views/_details/_detailsSolo.tsx';
import { fetchOfferingRowData } from '../../api/offering/fetchOfferingRowData';
import { fetchLinearRowData } from '../../api/linear/fetchLinearRowData';
import { DetailsModalSoloOffering } from '../../containers/Views/_details/_detailsSolo.tsx/_detailsModalSoloOffering';
import { DetailsModalSoloLinear } from '../../containers/Views/_details/_detailsSolo.tsx/_detailsModalSoloLinear';
import { useSearchStore } from '../../containers/Search/store';
import { fetchAcquireRowData } from '../../api/acquire/fetchAcquireRowData';
import { DetailsModalSoloAcquire } from '../../containers/Views/_details/_detailsSolo.tsx/_detailsModalSoloAcquire';
import { DetailsModalSoloAsset } from '../../containers/Views/_details/_detailsSolo.tsx/_detailsModalSoloAsset';
import { fetchAssetRowData } from '../../api/asset/fetchAssetRowData';
import { fetchCrossPlatformRowData } from '../../api/crossplatform/fetchCrossPlatformRowData';
import { DetailsModalSoloCrossPlatform } from '../../containers/Views/_details/_detailsSolo.tsx/_detailsModalSoloCrossPlatform';

function canAccessAdmin(env: string, group: string) {
  const hasViews = hasOKTAGroup(env, group);
  if (!hasViews) {
    return <Route path="" element={<NotAuthorized />} />;
  }

  return <Route path="" element={<Admin />} />;
}

// function canAccessViews(env: string, group: string) {
//   const hasViews = hasOKTAGroup(env, group);
//   if (!hasViews) {
//     return <Route path="" element={<NotAuthorized />} />;
//   }

//   return (
// <Route
//   path=""
//   element={
//     <ViewProvider>
//       <Views />
//     </ViewProvider>
//   }
// />
//   );
// }

const AppRoutes = () => {
  const config = useConfigStore((state) => state.config);
  const { startDate, endDate } = useSearchStore();
  return (
    <Routes>
      <Route index path="/" element={<Home />} />
      <Route path="/login" element={<LoginCallback loadingElement={<Loading />} />} />
      <Route path="/admin" element={<SecureRoute />}>
        {canAccessAdmin(config.environment, config.oktaAdminGroup)}
      </Route>
      <Route path="/hud" element={<SecureRoute />}>
        <Route path="" element={<Hud name="" testid="HUD" />} />
      </Route>
      <Route path="/hud/:id" element={<SecureRoute />}>
        <Route path="" element={<Hud name="" testid="HUD" />} />
      </Route>
      <Route path="/views" element={<SecureRoute />}>
        <Route path="" element={<Views />} />
      </Route>
      <Route path="/views/:id" element={<SecureRoute />}>
        <Route path="" element={<Views />} />
      </Route>
      <Route path="/views/shared/:id" element={<SecureRoute />}>
        <Route path="" element={<Views />} />
      </Route>
      <Route path="/views/offerings/keyword/:id" element={<SecureRoute />}>
        <Route path="" element={<Views />} />
      </Route>
      <Route path="/views/segments/keyword/:id" element={<SecureRoute />}>
        <Route path="" element={<Views />} />
      </Route>
      <Route path="/views/assets/keyword/:id" element={<SecureRoute />}>
        <Route path="" element={<Views />} />
      </Route>
      <Route path="/views/offeringAssets/keyword/:id" element={<SecureRoute />}>
        <Route path="" element={<Views />} />
      </Route>
      <Route path="/views/crossplatform/keyword/:id" element={<SecureRoute />}>
        <Route path="" element={<Views />} />
      </Route>
      <Route path="/offerings/:id" element={<SecureRoute />}>
        <Route
          path=""
          element={
            <DetailsSolo
              footprint={"footprint-offering"}
              startDate={startDate}
              endDate={endDate}
              searchInputField="offeringId"
              fetchServerRowData={fetchOfferingRowData}
              DetailComponent={<DetailsModalSoloOffering />}
            />
          }
        />
      </Route>
      <Route path="/segments/:id" element={<SecureRoute />}>
        <Route
          path=""
          element={
            <DetailsSolo
              footprint={"footprint-linear"}
              startDate={startDate}
              endDate={endDate}
              searchInputField="scheduleId"
              fetchServerRowData={fetchLinearRowData}
              DetailComponent={<DetailsModalSoloLinear />}
            />
          }
        />
      </Route>
      <Route path="/assets/:id" element={<SecureRoute />}>
        <Route
          path=""
          element={
            <DetailsSolo
              footprint={"footprint-acquire"}
              startDate={startDate}
              endDate={endDate}
              searchInputField="acquireId"
              fetchServerRowData={fetchAcquireRowData}
              DetailComponent={<DetailsModalSoloAcquire />}
            />
          }
        />
      </Route>
      <Route path="/offeringAssets/:id" element={<SecureRoute />}>
        <Route
          path=""
          element={
            <DetailsSolo
              footprint={"footprint-asset"}
              startDate={startDate}
              endDate={endDate}
              searchInputField="offeringId"
              fetchServerRowData={fetchAssetRowData}
              DetailComponent={<DetailsModalSoloAsset />}
            />
          }
        />
      </Route>
      <Route path="/crossplatform/:id" element={<SecureRoute />}>
        <Route
          path=""
          element={
            <DetailsSolo
              footprint={"footprint-crossplatform"}
              startDate={startDate}
              endDate={endDate}
              searchInputField="offeringId"
              fetchServerRowData={fetchCrossPlatformRowData}
              DetailComponent={<DetailsModalSoloCrossPlatform />}
            />
          }
        />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppRoutes;

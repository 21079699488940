import { Modal, Text } from '@mantine/core';
import { Flex } from 'antd';
import { Button } from '../../../components';

type ChangeItemConfirmationModalType = {
  openChangeItemValueModal: boolean;
  set_openChangeItemValueModal: (open: boolean) => void;
  SendData: () => void;
  prevValue: string;
  newValue: string;
};

export const ChangeItemConfirmationModal = (props: ChangeItemConfirmationModalType) => {
  const {
    openChangeItemValueModal,
    set_openChangeItemValueModal,
    SendData,
    prevValue,
    newValue
  } = props;

  return (
    <Modal
      opened={openChangeItemValueModal}
      onClose={() => set_openChangeItemValueModal(false)}
      title="Change item"
      centered
    >
      <>
        <Text>{`Would you like to change the value`}</Text>
        <Text>{`from: "${prevValue}", to: "${newValue}".`}</Text>
        <Flex style={{ marginTop: '10px' }} justify="center" gap="md">
          <Button
            testid="confirm-to-change-dropdown-item"
            noIcon
            color="red"
            onClick={() => {
              SendData();
              set_openChangeItemValueModal(false);
            }}
          >
            Yes, change it
          </Button>
          <Button
            testid="cancel-to-change-dropdown-item"
            style={{ margin: '0 0 0 10px' }}
            noIcon
            onClick={() => set_openChangeItemValueModal(false)}
          >
            Cancel
          </Button>
        </Flex>
      </>
    </Modal>
  );
};

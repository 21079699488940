import { useRef } from 'react';
import { Box, TextInput, useMantineTheme } from '@mantine/core';
import { Search, X } from 'tabler-icons-react';
import { useTID } from '../../helpers/useTestId';
import { useStatusCodeStore } from './store';

export const SearchStatusCodes = (props: any) => {
  const setSearchStatusCode = useStatusCodeStore((state) => state.SetSearchStatusCode);
  const theme = useMantineTheme();
  const TID = useTID('SearchStatusCodes', props.testid);
  const ref = useRef<any>();

  const DoSearchIcon = () => {
    return (
      <Search
        size={14}
        style={{
          cursor: 'pointer',
          color: theme.colors.blue[6]
        }}
        onClick={(x) => x}
      />
    );
  };

  const ResetSearchIcon = () => {
    return (
      <X
        color="gray"
        size={14}
        style={{
          cursor: 'pointer'
        }}
        onClick={() => {
          ref.current.value = '';
          setSearchStatusCode('');
        }}
      />
    );
  };

  return (
    <Box {...TID}>
      <TextInput
        ref={ref}
        leftSection={DoSearchIcon()}
        style={{ marginTop: -16 }}
        placeholder={`Search Status Codes`}
        onChange={(e) => setSearchStatusCode(e.currentTarget.value)}
        radius="xl"
        size="xs"
        rightSection={ResetSearchIcon()}
      />
    </Box>
  );
};

export default SearchStatusCodes;

import { useAppConfigManager } from '../../components/AppConfigs/store';

export const eventSource = [
  // { value: 'all', label: 'All' },
  { value: 'beam-offering-service', label: 'Beam Offering Service' }
];
export const footprint = [{ value: 'offset', label: 'Offset' }];

const mapFormValues = (dropdownName: string) => {
  let valuesSet = useAppConfigManager.getState().GetDropDown(dropdownName);

  return valuesSet.reduce(
    (accumulatorValues, elements: { value: string; label: string }) => ({
      ...accumulatorValues,
      [elements.value]: elements.label
    }),
    {}
  );
};

export const searchBarValuesMapper: any = {
  AdStatus: mapFormValues('adstatus'),
  AdSupported: mapFormValues('adsupported'),
  ContentSource: mapFormValues('contentSource'),
  ContentType: mapFormValues('contentTypes'),
  EntityType: mapFormValues('entityType'),
  EventSource: mapFormValues('eventSource'),
  Feed: mapFormValues('feed'),
  Footprint: mapFormValues('footprint'),
  FulfillmentType: mapFormValues('fulfillmentType'),
  LinearExpired: mapFormValues('linearExpired'),
  Milestone: mapFormValues('milestones'),
  MilestoneStatus: mapFormValues('milestoneStatuses'),
  Network: mapFormValues('networks'),
  OfferingStatus: mapFormValues('offeringStatus'),
  OfferingType: mapFormValues('contentClassTypes'),
  Partner: mapFormValues('partners'),
  Platform: mapFormValues('platforms'),
  ScheduleType: mapFormValues('scheduleTypes'),
  SearchInput: mapFormValues('searchInputs'),
  SuppressRepeats: mapFormValues('suppressRepeats'),
  Territories: mapFormValues('territories')
};

import { VariableObject } from '../../components/AppConfigs';
import { iItemType } from '../../components/ListSortable/models';

export const defaultItem: iItemType = { id: '', label: '', value: '' };

export interface iDropDownMenu {
  Item: iItemType;
  DropdownList: iItemType[];
}

export const initialDropDownValues: iDropDownMenu = {
  Item: {} as iItemType,
  DropdownList: [] as iItemType[],
};

export interface HierarchyPathType {
  value: string;
  label: string;
}

export interface iAdminModule {
  ActiveTab: string | null;
  DropDown: iDropDownMenu;
  SetActiveTab: (tab: string | null) => void;
  SetItem: (newItem: iItemType) => void;
  DropdownList: iItemType[];
  SetDropdownList: (newList: iItemType[]) => void;
  SetAllDropDowns: (all: VariableObject) => void;
  SetNewDropdown: (newDropdown: boolean) => void;
  newDropdown: boolean;
  hierarchyPath: HierarchyPathType[];
  SetHierarchyPath: (levels: HierarchyPathType[]) => void;
}

export const initialAdminModuleValues: iAdminModule = {
  ActiveTab: 'statusCodeDictionary',
  DropDown: initialDropDownValues,
  DropdownList: [] as iItemType[],
  SetActiveTab: (tab: string | null) => {},
  SetItem: (newItem: iItemType) => {},
  SetDropdownList: (newList: iItemType[]) => {},
  SetAllDropDowns: (all: VariableObject) => {},
  SetNewDropdown: (newDropdown: boolean) => {},
  newDropdown: false,
  hierarchyPath: [],
  SetHierarchyPath: (levels: HierarchyPathType[]) => {}
};

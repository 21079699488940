import {
  Box,
  Center,
  Flex,
  useComputedColorScheme,
  darken,
  lighten
} from '@mantine/core';
import { ChartVisibilty } from './ChartVisibility';
import { StatusFilterButtons } from './StatusFilterButtons';
import { TotalRecords } from './TotalRecords';
import { globalColors as colors } from '../../theme/globalColors';

export const StatusFilterBar = () => {
  const colorScheme = useComputedColorScheme('light');

  return (
    <Box
      style={{
        border: '1px solid',
        borderColor:
          colorScheme === 'dark'
            ? darken(colors.wbdblue[6], 0.3)
            : lighten(colors.wbdblue[3], 0.6),
        backgroundColor:
            colorScheme === 'dark'
            ? darken(colors.wbdblue[9], 0.4)
            : '#E6EFFF',
      }}
      className={'baseContainer'}
    >
      <Flex justify="space-between">
        <ChartVisibilty />
        <Center>
          <StatusFilterButtons />
        </Center>
        <TotalRecords />
      </Flex>
    </Box>
  );
};

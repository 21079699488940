import useAxios from '../services/axios/axios';

export const fetchJSONID = async (baseURL: string, id: string, type: string) => {
  const { post } = useAxios();

  const query = constructQuery(id, type);
  if (!query) return null;
  const schema = {
    query: query
  };

  const eventData = await post({
    url: baseURL,
    data: JSON.stringify(schema)
  });

  return eventData;
};

const constructQuery = (id: string, type: string) => {
  const queryFilters = constructFilters(id, type);
  if (!queryFilters) return null;
  const query = `query {
            event(
                ${queryFilters}
            ) {
              records {
                data
              }
            }
          }`;

  return query;
};

const constructFilters = (id: string, type: string) => {
  const queryFilters = `filters: {
    id:"${id}"
    type:${type}
    page:1
    limit:1000
  }`;
  return queryFilters;
};

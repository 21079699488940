import { Switch as MSwitch, SwitchProps, useComputedColorScheme } from '@mantine/core';
import {useTID} from '../../helpers/useTestId'

interface ISwitchProps extends SwitchProps {
  testid: string;
}

export const Switch = (props: ISwitchProps) => {
  const colorScheme = useComputedColorScheme('light');
  const TID = useTID('Switch', props.testid)
  return (
    <MSwitch
      style ={(theme) => ({
        input: {
          '&:checked': {
            backgroundColor: colorScheme === 'dark' ? theme.colors.blue[9] : theme.colors.blue[6],
            borderColor: colorScheme === 'dark' ? theme.colors.blue[6] : theme.colors.gray[4]
            // border: '2px solid',
          }
        }
      })}
      {...TID}
      {...props}
    >
      {props.children}
    </MSwitch>
  );
};

import { useComputedColorScheme, useMantineTheme } from '@mantine/core';

export const StatusColor = () => {
  const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');

  const colors: Record<string, string> = {
    completed:
      colorScheme === 'dark' ? theme.colors.success[9] : theme.colors.success[7],
    error: colorScheme === 'dark' ? theme.colors.danger[8] : theme.colors.danger[7],
    warning:
      colorScheme === 'dark' ? theme.colors.warning[9] : theme.colors.warning[7],
    expired: colorScheme === 'dark' ? theme.colors.gray[7] : theme.colors.gray[5],
    failure: colorScheme === 'dark' ? '#e6c044' : '#FFA117', //theme.colorScheme === 'dark' ? theme.colors.orange[9] : theme.colors.orange[7],
    pending: colorScheme === 'dark' ? theme.colors.gray[6] : theme.colors.gray[6],
    waiting: colorScheme === 'dark' ? theme.colors.gray[6] : theme.colors.gray[6],
    processing:
      colorScheme === 'dark' ? theme.colors.blue[9] : theme.colors.blue[7],
    skipped:
      colorScheme === 'dark' ? theme.colors.success[9] : theme.colors.success[7],
    queued: colorScheme === 'dark' ? theme.colors.gray[6] : theme.colors.gray[6],
    inactive: colorScheme === 'dark' ? theme.colors.gray[4] : theme.colors.gray[8],
    null: colorScheme === 'dark' ? theme.colors.gray[9] : theme.colors.gray[4],
    nodata: colorScheme === 'dark' ? theme.colors.gray[9] : theme.colors.gray[4],
    na: colorScheme === 'dark' ? theme.colors.gray[9] : theme.colors.gray[4]
  };

  return colors;
};

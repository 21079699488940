import { useFootprintStore } from '../../containers/Footprints/store';
import { getStatus } from '../../utils/getStatus';
import { calculateMilestoneFromTask } from './TimelineData';

interface iFilterTimelineTasksProps {
  data: any;
  filter: any[];
  isTaskName: boolean;
  isStatusCode: boolean;
  milestoneData: any;
  statusObject: boolean;
}

export const FilterTimelineTasks = (props: iFilterTimelineTasksProps) => {
  const { data, filter, isTaskName, isStatusCode, milestoneData, statusObject } = props;
  if (isTaskName) {
    return data.filter((i: { type: string }) => {
      return filter.includes(i.type);
    });
  } else if (isStatusCode) {
    const footprint = useFootprintStore.getState().active.id;
    // footprint-offering_prep_video_pickpackrules_completed_urn:acdc:fulfill:pickpackrules:completed:1002
    return data.filter((i: { status: any; type: string }) => {
      const milestoneName = calculateMilestoneFromTask(
        milestoneData,
        statusObject,
        i.type
      )
        .toLocaleLowerCase()
        .split('|')
        .join('_');

      let filterNames: string[] = [];
      let filterName = `${footprint}_${milestoneName}`;

      let status = i.status;
      if (status?.codes.length > 0) {
        status.codes.forEach((code: { code: string }) => {
          let codeName = '';
          let statusName = code.code;
          status?.type ? (statusName = getStatus(statusName)) : (statusName = 'inactive');
          if (statusName) {
            codeName = filterName + `_${statusName}_${code.code}`;
            filterNames.push(codeName);
          }
        });
      } else {
        let statusName = status?.type.includes('type_')
          ? status?.type.split('type_')[1]
          : status?.type;
        status?.type ? (statusName = getStatus(statusName)) : (statusName = '');

        if (statusName) {
          filterNames.push(filterName + `_${statusName}`);
        }
      }

      let match = false;
      filterNames.forEach((name) => {
        if (filter.length > 0) {
          filter.forEach((f) => {
            if (name.includes(f.slice(-1)[0])) {
              match = true;
            }
          });
        }
      });

      return match;
    });
  }
  return data;
};

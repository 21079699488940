import { Option as OptionMarket } from '../../components/CascaderMarket/_markets';
import { useConfigStore } from '../../app/store';
import useAxios from '../../services/axios/axios';
import { iSearch } from '../../containers/Search/models';
import { useAppConfigManager } from '../../components/AppConfigs';
import { useSearchStore } from '../../containers/Search/store';
import { getStatusCodeString } from '../../utils/utilities';

export type Result = {
  rowData: any[];
  rowCount: number;
};

export const fetchAssetRowData = async (
  startDate: Date | null,
  endDate: Date | null,
  searchState: iSearch,
  limit: number,
  page: number
) => {
  const { post } = useAxios();
  const baseURL = useConfigStore.getState().config.baseApiGatewayUrl + '/graphql/search';

  const query = await constructQuery(startDate, endDate, searchState, limit, page);

  const schema = {
    query: query
  };
  let result: Result = { rowData: [], rowCount: 0 };
  try {
    const eventData = await post({
      url: baseURL,
      data: JSON.stringify(schema)
    });
    if (!eventData) {
      result = { rowData: [], rowCount: 0 };
    } else {
      let totalCount = eventData?.data?.offeringAsset?.count;
      result = { rowData: eventData.data.offeringAsset.records, rowCount: totalCount };
    }
  } catch (err) {
    console.error('Error fetching Max Asset row data: ', err);
  } finally {
    return result;
  }
};

const constructQuery = async (
  startDate: Date | null,
  endDate: Date | null,
  searchState: iSearch,
  limit: number,
  page: number
) => {
  const queryFilters = constructAssetFilters(startDate, endDate, searchState, limit, page);

  let queryReturn = await useSearchStore
    .getState()
    .queries.find((q) => q.id.includes('footprint-asset'));
  if (!queryReturn) {
    const LoadAPI = useSearchStore.getState().LoadAPI;
    await LoadAPI();
    queryReturn = await useSearchStore
      .getState()
      .queries.find((q) => q.id.includes('footprint-asset'));
  }

  const query = `query {
            offeringAsset(
              ${queryFilters}
            ) {
              ${queryReturn?.query}
              count
            }
          }`;
  return query;
};

export const constructAssetFilters = (
  startDate: Date | null,
  endDate: Date | null,
  filters: iSearch,
  limit: number,
  page: number
) => {

  const milestoneString = constructMilestoneObject(filters.Milestone, filters.MilestoneStatus)

  let partialSearchInput: string = '';
  if (filters.SearchInput) {
    let searchInputs;
    if (filters.SearchInputField === 'keyword') {
      let copy = filters.SearchInput;
      let newInput = copy.replaceAll('"', '\\"');
      searchInputs = newInput;
    } else {
      searchInputs = filters.SearchInput.split(' ');
      if (searchInputs.length > 1) {
        searchInputs = searchInputs.join('","');
      }
    }
    partialSearchInput = searchInputs.toString();
  }

  let tasks = '';
  if (filters.AdMarkers === 'true') {
    tasks = `
    tasks:{
      details:{
        fulfilledMediaMarkerCount: {
          value: 1
          operation: gte
        }
      }
    }
    `;
  } else if (filters.AdMarkers === 'false') {
    tasks = `
    tasks:{
      details:{
        fulfilledMediaMarkerCount: {
          value: 1
          operation: lt
        }
      }
    }
    `;
  }

  let idPagination;
  idPagination = `page: ${page}`;

  const assetObject = constructAssetObject(startDate, endDate, filters);
  let partialObject = '';
  if (partialSearchInput && filters.SearchInputField === 'keyword') {
    partialObject = constructPartialAssetObject(partialSearchInput);
  }

  const taskValueMapper = useAppConfigManager.getState().taskValueMapper;
  const statusCodeString = getStatusCodeString(filters.StatusCodes, taskValueMapper);

  const queryFilters = `filters: {
        ${statusCodeString}
        ${milestoneString}
        ${assetObject}
        ${partialObject}
        ${tasks}
        limit: ${limit}
        ${idPagination}
    }`;
  return queryFilters;
};

export const constructMilestoneObject = (milestones: string[], milestoneStatus: string[]) => {
  const status: any = {}
  if (milestones.length > 0 && milestoneStatus.length > 0) {
    milestones.forEach(i=> {
      status[i] = `${i}: {status: [${milestoneStatus?.map((status: any) => `"${status}"`)}]}`
    })
  }
  if (Object.keys(status).length > 0) {
    let statusString = '';
    Object.keys(status).forEach((k: string) => {
      statusString = statusString + status[k];
    });
    return `
      status:{
        ${statusString}
      }
    `;
  }
  return ''
}

export const constructAssetObject = (
  startDate: Date | null,
  endDate: Date | null,
  filters: iSearch
) => {
  let assetObject: any = {};
  let offeringObject: any = {};
  const offeringArrayMapping = {
    entityType: 'EntityType',
    type: 'OfferingType'
  };

  Object.keys(offeringArrayMapping).forEach((k) => {
    const filterKey = offeringArrayMapping[k as keyof typeof offeringArrayMapping];
    if (filters[filterKey as keyof typeof filters]) {
      if (Array.isArray(filters[filterKey as keyof typeof filters])) {
        if ((filters[filterKey as keyof typeof filters] as string[]).length > 0) {
          const filterString = `${k}:[${(
            filters[filterKey as keyof typeof filters] as string[]
          ).map((i: any) => `"${i}"`)}]`;
          assetObject[k as keyof typeof assetObject] = filterString;
        }
      }
    }
  });

  if (filters.Market) {
    if (typeof filters.Market === 'string') {
      // TODO: Remove after migration of Partner value in searches
      offeringObject.marketId = `marketId:{id: "${filters.Market}"},`;
    } else {
      const { queryMarkets } = useAppConfigManager.getState();
      let value: any[] = [];
      filters.Market.forEach((market: string[]) => {
        const option = queryMarkets.find(
          (o: OptionMarket) => o.value === market[market.length - 1]
        );
        if (option) {
          option.valueList.split(',').forEach((id: string) => {
            value.push(id);
          });
        } else {
          console.error('Could not find market option within markets!: ', market);
        }
        offeringObject.marketId = `marketId:{id: [${value.map(
          (marketId: any) => `"${marketId}"`
        )}]},`;
      });
    }
  }

  if (
    filters.DateField === 'startDate' &&
    filters.startDateTime &&
    filters.endDateTime &&
    filters.dateEnabled
  ) {
    offeringObject.startDate = `startDate:{from:"${startDate!.toISOString()}",to:"${endDate!.toISOString()}"}`;
    // offeringObject.endDate = `endDate:{value:"${endDate!.toISOString()}",operation:lte}`;
  }

  if (
    filters.DateField === 'endDate' &&
    filters.startDateTime &&
    filters.endDateTime &&
    filters.dateEnabled
  ) {
    offeringObject.endDateFrom = `endDateFrom: "${startDate!.toISOString()}"`
    offeringObject.endDateTo = `endDateTo: "${endDate!.toISOString()}"`
  }

  if (filters.SearchInputField && filters.SearchInputField !== 'keyword' && filters.SearchInput) {
    let input = '';
    let searchInputs = filters.SearchInput.split(' ');
    if (searchInputs.length > 1) {
      input = searchInputs.join('","');
    } else {
      input = searchInputs[0];
    }

    const excludedIds = [
      'keyword'
    ]

    const ids = useAppConfigManager
      ?.getState()
      ?.GetSearchInputs('footprint-asset')
      ?.map((id) => id.value)
      .filter(i=>!excludedIds.includes(i));

    if (filters.SearchInputField === 'offeringId') {
      offeringObject.offeringId = `offeringId: ["${input}"]`;
    } else if (ids.includes(filters.SearchInputField)) {
      assetObject[
        filters.SearchInputField
      ] = `${filters.SearchInputField}:{id: ["${input}"]}`;
    }
  }

  if (Object.keys(offeringObject).length > 0) {
    let offeringString = '';
    Object.keys(offeringObject).forEach((k: string) => {
      offeringString = offeringString + '\n' + offeringObject[k];
    });
    assetObject.offerings = `\nofferings: {
      ${offeringString}
    }`;
  }

  if (Object.keys(assetObject).length > 0) {
    let assetString = '';
    Object.keys(assetObject).forEach((k: string) => {
      assetString = assetString + assetObject[k];
    });
    return `${assetString}`;
  }
  return '';
};

export const constructPartialAssetObject = (searchInput: string) => {
  const partialTitles = ['titles', 'seriesTitles', 'seasonTitles'];

  const excludedIds = [
    'keyword',
    'offeringId'
  ] 
  
  const partialIds = useAppConfigManager
    ?.getState()
    ?.GetSearchInputs('footprint-asset')
    ?.map((id) => id.value)
    .filter(i=>!excludedIds.includes(i));
  let partialObject: any = {
    offerings: `offerings: { offeringId: ["${searchInput}"] }`
  };

  partialTitles.map((t: string) => {
    const partialTitle = `
      ${t}:{
        full:["${searchInput}"]
        type:["main-title", "internal-wm-name", "distribution-title"],
        language:"en-US"
      }`;
    partialObject[t as keyof typeof partialObject] = partialTitle;
  });

  if (searchInput.length > 4 || !searchInput.split('').includes(' ')) {
    partialIds.map((i: string) => {
      partialObject[i as keyof typeof partialObject] = `\n${i}:{id: ["${searchInput}"]}`;
    });
  }

  if (Object.keys(partialObject).length > 0) {
    let offeringString = '';
    Object.keys(partialObject).forEach((k: string) => {
      offeringString = offeringString + partialObject[k];
    });
    return `
      partialSearch:{
        ${offeringString}
      }
    `;
  }
  return '';
};

import packagejson from '../../../package.json';
import mantinejson from '../../../node_modules/@mantine/core/package.json';
import reactjson from '../../../node_modules/react/package.json';
import zustandjson from '../../../node_modules/zustand/package.json';
import aggridjson from '../../../node_modules/ag-grid-community/package.json';
import { Anchor, Text, useComputedColorScheme, useMantineTheme, Table } from '@mantine/core';
import { Code } from '../../components/';

export const Versions = () => {
  const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');

  return (
    <Table style={{ width: '100%' }}>
      <Table.Thead>
        <Table.Tr>
          <Table.Td>
            <Text
              size="sm"
              style={{
                color:
                  colorScheme === 'dark'
                    ? theme.colors.gray[4]
                    : theme.colors.gray[8]
              }}
            >
              <b>Versions</b>
            </Text>
          </Table.Td>
          <Table.Td></Table.Td>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        <Table.Tr>
          <Table.Td>
            <Anchor
              href="https://git.wmedia.tech/ops/lasso/web-ui"
              target={'_blank'}
              size="sm"
            >
              DiSCOv5 Web-UI
            </Anchor>
          </Table.Td>
          <Table.Td>
            <Code
              testid="HeaderVersionsPackage"
              style={{
                color:
                  colorScheme === 'dark'
                    ? theme.colors.blue[9]
                    : theme.colors.gray[9],
                backgroundColor:
                  colorScheme === 'dark'
                    ? theme.colors.gray[4]
                    : theme.colors.blue[0]
              }}
            >
              {packagejson.version}
            </Code>
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Anchor href="https://mantine.dev" target={'_blank'} size="sm">
              Mantine
            </Anchor>
          </Table.Td>
          <Table.Td>
            <Code
              testid="HeaderVersionsMantine"
              style={{
                color:
                  colorScheme === 'dark'
                    ? theme.colors.blue[9]
                    : theme.colors.gray[9],
                backgroundColor:
                  colorScheme === 'dark'
                    ? theme.colors.gray[4]
                    : theme.colors.blue[0]
              }}
            >
              {mantinejson.version}
            </Code>
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Anchor href="https://reactjs.org" target={'_blank'} size="sm">
              React
            </Anchor>
          </Table.Td>
          <Table.Td>
            <Code
              testid="HeaderVersionsReact"
              style={{
                color:
                  colorScheme === 'dark'
                    ? theme.colors.blue[9]
                    : theme.colors.gray[9],
                backgroundColor:
                  colorScheme === 'dark'
                    ? theme.colors.gray[4]
                    : theme.colors.blue[0]
              }}
            >
              {reactjson.version}
            </Code>
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Anchor href="https://github.com/pmndrs/zustand" target={'_blank'} size="sm">
              Zustand
            </Anchor>
          </Table.Td>
          <Table.Td>
            <Code
              testid="HeaderVersionsZustand"
              style={{
                color:
                  colorScheme === 'dark'
                    ? theme.colors.blue[9]
                    : theme.colors.gray[9],
                backgroundColor:
                  colorScheme === 'dark'
                    ? theme.colors.gray[4]
                    : theme.colors.blue[0]
              }}
            >
              {zustandjson.version}
            </Code>
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Anchor
              href="https://www.ag-grid.com/react-data-grid/"
              target={'_blank'}
              size="sm"
            >
              Ag-Grid
            </Anchor>
          </Table.Td>
          <Table.Td>
            <Code
              testid="HeaderVersionsAgGrid"
              style={{
                color:
                  colorScheme === 'dark'
                    ? theme.colors.blue[9]
                    : theme.colors.gray[9],
                backgroundColor:
                  colorScheme === 'dark'
                    ? theme.colors.gray[4]
                    : theme.colors.blue[0]
              }}
            >
              {aggridjson.version}
            </Code>
          </Table.Td>
        </Table.Tr>
      </Table.Tbody>
    </Table>
  );
};

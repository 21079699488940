export type CustomMonthYearSelectedType = {
  month: number;
  year: number;
};

export interface iDatePickerState {
  customMonthYearSelected: CustomMonthYearSelectedType
  SetCustomMonthYearSelected: (selection: CustomMonthYearSelectedType) => void;
}

export const initialDatePickerStateValues: iDatePickerState = {
  customMonthYearSelected: {
    month: 1,
    year: 1
  },
  SetCustomMonthYearSelected: (selection: CustomMonthYearSelectedType) => {}
};

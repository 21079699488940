import { Divider, Flex, Text } from '@mantine/core';
import { Button } from '../../Button';
import { HUDService } from '../../../services/hud/hudService';
import { iWidget } from '../state/models';
import { useHUDStore } from '../state';

export const RemoveWidgetBox = ({
  widget,
  closeFunc
}: {
  widget: iWidget;
  closeFunc: any;
}) => {
  const HUDStore = useHUDStore();

  const handleDelete = async () => {
    const personalHUD = await HUDService.getPersonalHUD();

    let copyHUD = Object.assign({}, personalHUD[0]);

    for (const key in copyHUD.layout) {
      const deletedLayout = copyHUD.layout[key].find((l: any) => l.i === widget.i);
      if (deletedLayout) {
        const index = copyHUD.layout[key].indexOf(deletedLayout);
        if (index > -1) {
          // only splice array when item is found
          copyHUD.layout[key].splice(index, 1); // 2nd parameter means remove one item only
        }
      }
    }
    const deletedWidget = copyHUD.widgets.find((w: any) => w.i === widget.i);
    const index = copyHUD.widgets.indexOf(deletedWidget);
    if (index > -1) {
      // only splice array when item is found
      copyHUD.widgets.splice(index, 1); // 2nd parameter means remove one item only
    }

    HUDStore.SavePersonalHUD(copyHUD)
    HUDStore.SetPersonalHUD(copyHUD);
  };

  return (
    <>
      <Divider my="md" labelPosition="center"></Divider>
      <Text>
        {`Would you like to remove the "${widget.customName}" widget from your HUD.`}
      </Text>
      <Flex style={{ marginTop: '10px' }} justify="center" gap="md">
        <Button
          testid="widgetRemoveAccept"
          base="accept"
          label="Yes"
          onClick={handleDelete}
        />
        <Button
          testid="widgetRemoveCancel"
          base="cancel"
          onClick={() => closeFunc.close()}
        />
      </Flex>
    </>
  );
};

import { Box, Text, useComputedColorScheme } from '@mantine/core';
import { AgGridReact } from 'ag-grid-react';
import { AgGridContainer } from '../..';
import { useEffect, useMemo, useRef, useState } from 'react';
import { DataPanelAccordionItem } from '../../DataPanelAccordion';
import { DataPanelGridOptions } from '../../MilestoneDataPanels/MilestonesGridOptions';
import { useDataPanelAccordionStore } from '../../DataPanelAccordion/store';
import { useFootprintStore } from '../../../containers/Footprints/store';
import { fetchLatestDeliverables } from '../../../api/fetchLatestDeliverables';
import { DataPanelGridProcess } from './_dataPanelGridProcess';
import { components } from '../../../containers/Views/cellRenderers';
import { AgGridEvent } from 'ag-grid-community';

interface iDetailGridLatestProps {
  data: any;
  width: string | number | undefined;
  child: string;
  coldefs: any;
  parent?: any;
  footprint?: string;
  statusObject: boolean
}

export const DetailGridLatest = ({
  data,
  width,
  child,
  coldefs,
  parent,
  footprint,
  statusObject
}: iDetailGridLatestProps) => {
  const [sortedData, setSortedData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const detailCellRenderer = useMemo(() => {
    return DataPanelGridProcess
  }, []);
  const activeFootprint = useFootprintStore.getState().active.id;

  const SetDefaultItem = useDataPanelAccordionStore((state) => state.SetDefaultItem);

  const gridRef = useRef<any>();
  const colorScheme = useComputedColorScheme('light');
  const gridCSS =
    colorScheme === 'dark' ? 'ag-theme-alpine-dark' : 'ag-theme-alpine';
  const gridOptions = { ...DataPanelGridOptions };
  gridOptions.columnDefs = coldefs;
  gridOptions.rowData = sortedData;
  gridOptions.components = components;
  const columnResized = (e: AgGridEvent) => {
    const params = {
      force: true
    };
    gridRef?.current?.api?.refreshCells(params);
  }
  gridOptions.onColumnResized = columnResized
  gridOptions.detailCellRenderer = detailCellRenderer;
  gridOptions.detailCellRendererParams = {
    footprint,
    parent,
    child,
    width,
    statusObject
  };
  gridOptions.detailRowAutoHeight = true

  let content;

  const fetchLatestDeliverablesData = async () => {
    let fp = footprint ? footprint : activeFootprint;
    const result = await fetchLatestDeliverables(
      data,
      fp,
      parent || '',
      child,
      statusObject
    )
    if (!result) {
      setSortedData([]);
      gridRef?.current?.api?.setRowData([]);
      setLoading(false);
      return;
    }

    const removedNulls = result.filter((d: any) => d !== null);
    const sortedData =
      removedNulls.length > 0
        ? removedNulls.sort(function (a: any, b: any) {
            return b.audit.generatedAt.localeCompare(a.audit.generatedAt);
          })
        : [];
    setSortedData(sortedData);
    gridRef?.current?.api?.setRowData(sortedData);
    setLoading(false);
  };

  useEffect(() => {
    fetchLatestDeliverablesData();
  }, []);

  if (loading) {
    return <></>;
  } else {
    let mappedStatus
    if (statusObject){
      const statusName = `${parent}|${child}`
      mappedStatus = data.status[statusName]
    } else {
      mappedStatus = data.milestones?.find(
        (milestone: { type: string; name: string }) =>
          milestone.type === 'Activity' &&
          milestone.name.split('|')[1] === child &&
          milestone.name.split('|')[0] === parent
      );
    }
    mappedStatus = mappedStatus?.status ? mappedStatus.status : null;

    content = (
      <Box>
        <Text fw={'bolder'}>Latest Statuses:</Text>
        <AgGridContainer>
          <div
            className={gridCSS}
            style={{
              height: 400,
              width: width,
              minWidth: 1000,
              margin: -16,
              marginTop: -2
            }}
          >
            <AgGridReact ref={gridRef} gridOptions={gridOptions} />
          </div>
        </AgGridContainer>
      </Box>
    );

    const nonStatuses = ['nodata', 'na'];
    if (mappedStatus && !nonStatuses.includes(mappedStatus)) {
      if (child === 'Video' || child === 'Asset' || child === 'Prep') {
        SetDefaultItem(child);
      }
      return (
        <DataPanelAccordionItem
          name={child}
          status={mappedStatus}
          title={`${mappedStatus[0].toUpperCase() + mappedStatus.substring(1)} - ${
            child[0].toUpperCase() + child.substring(1)
          }`}
          content={content}
        />
        //   {content}
        // </DataPanelAccordionItem>
      );
    } else {
      if (child === 'Video' || child === 'Asset' || child === 'Prep') {
        SetDefaultItem('');
      }
      let newStatus = mappedStatus ? mappedStatus : 'null';
      return (
        <DataPanelAccordionItem
          name={child}
          status={newStatus}
          title={`${child[0].toUpperCase() + child.substring(1)}`}
          disabled={true}
          content={content}
        />
        //   {content}
        // </DataPanelAccordionItem>
      );
    }
  }
};

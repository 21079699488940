import { Home, ClipboardList, Columns, LayoutDashboard } from 'tabler-icons-react';

export const links = [
  { label: 'Home', icon: Home, path: '/' },
  { label: 'HUD', icon: LayoutDashboard, path: '/hud' },
  { label: 'Views', icon: Columns, path: '/views' },
  { label: 'Admin', icon: ClipboardList, path: '/admin' },
];



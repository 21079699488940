import { ColDef } from 'ag-grid-community';
import { useStatusCodeStore } from './store';

export const scdColumnDefs = () => {
  const fullGrid = useStatusCodeStore();
  if (fullGrid) {
    return scdColumnDefsFullGrid;
  }
  return statusCodeDictionaryColumnDefs;
};

export const statusCodeDictionaryColumnDefs: ColDef[] = [
  {
    field: 'statusCode',
    headerName: 'StatusCode',
    sortable: true,
    sortingOrder: ['asc', 'desc', null],
    flex: 1,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      buttons: ['reset']
    },
    resizable: true,
    cellStyle: {
      padding: 10
    }
  }
];

export const scdColumnDefsFullGrid: ColDef[] = [
  {
    field: 'statusCode',
    headerName: 'StatusCode',
    sortable: true,
    sortingOrder: ['asc', 'desc', null],
    width: 410,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      buttons: ['reset']
    },
    resizable: true,
    cellStyle: {
      padding: 10
    }
  },
  {
    field: 'appService',
    headerName: 'App Service',
    sortable: true,
    sortingOrder: ['asc', 'desc', null],
    flex: 1,
    hide: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      buttons: ['reset']
    },
    resizable: true,
    cellStyle: {
      padding: 10
    }
  },
  {
    field: 'appName',
    headerName: 'App Name',
    sortable: true,
    sortingOrder: ['asc', 'desc', null],
    flex: 1,
    hide: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      buttons: ['reset']
    },
    resizable: true,
    cellStyle: {
      padding: 10
    }
  },
  {
    field: 'appFunction',
    headerName: 'App Function',
    sortable: true,
    sortingOrder: ['asc', 'desc', null],
    flex: 1,
    hide: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      buttons: ['reset']
    },
    resizable: true,
    cellStyle: {
      padding: 10
    }
  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: true,
    sortingOrder: ['asc', 'desc', null],
    flex: 1,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      buttons: ['reset']
    },
    resizable: true,
    cellStyle: {
      padding: 10
    }
  },
  {
    field: 'statusNumber',
    headerName: 'Status Number',
    sortable: true,
    sortingOrder: ['asc', 'desc', null],
    flex: 1,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      buttons: ['reset']
    },
    resizable: true,
    cellStyle: {
      padding: 10
    }
  },
  {
    field: 'badgeColor',
    headerName: 'badgeColor',
    sortable: true,
    sortingOrder: ['asc', 'desc', null],
    flex: 1,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      buttons: ['reset']
    },
    resizable: true,
    cellStyle: {
      padding: 10
    }
  },
  {
    field: 'badgeTextColor',
    headerName: 'badgeTextColor',
    sortable: true,
    sortingOrder: ['asc', 'desc', null],
    flex: 1,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      buttons: ['reset']
    },
    resizable: true,
    cellStyle: {
      padding: 10
    }
  },
  {
    field: 'badgeText',
    headerName: 'badgeText',
    sortable: true,
    sortingOrder: ['asc', 'desc', null],
    flex: 1,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      buttons: ['reset']
    },
    resizable: true,
    cellStyle: {
      padding: 10
    }
  },

  {
    field: 'details',
    headerName: 'Details',
    sortable: true,
    sortingOrder: ['asc', 'desc', null],
    flex: 1,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      buttons: ['reset']
    },
    resizable: true,
    cellStyle: {
      padding: 10
    }
  },
  {
    field: 'description',
    headerName: 'Description',
    sortable: true,
    sortingOrder: ['asc', 'desc', null],
    flex: 1,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      buttons: ['reset']
    },
    resizable: true,
    cellStyle: {
      padding: 10
    }
  }
];

import { create } from 'zustand';
import { initialSearchBarValues, iSearchBarState } from './models';

export const useSearchBarStore = create<iSearchBarState>()(
    (set, get) => ({
        ...initialSearchBarValues,
        ToggleSearchBarOpen: () => {
            set({ searchBarOpen: !get().searchBarOpen });
        },
        ToggleShareModalOpen: () => {
            set({ shareModalOpen: !get().shareModalOpen });
        }
    })
);
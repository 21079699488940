import { Box, Center, Space, Text, useComputedColorScheme, useMantineTheme, lighten, Group } from '@mantine/core';
// import { Aside } from '../../components/';
import { useTID } from '../../helpers';
import { Aside, Button } from '../../components/';
import { useAsideSettingsStore } from '../Aside/store';

interface IUserData {
  name?: string;
  surName?: string;
  email?: string;
}

export const AsideAccount = ({
  oktaAuth,
  testid,
  userData
}: {
  oktaAuth: any;
  testid: string;
  userData: IUserData;
}) => {
  const logout = async () => {
    await oktaAuth.signOut();
    // oktaAuth.stop()
  };
  const colorScheme = useComputedColorScheme('light');
  const TID = useTID('AsideAccount', testid);
  const theme = useMantineTheme();
  const setShowAside = useAsideSettingsStore((state) => state.SetShow);

  return (
    <Aside
      {...TID}
      testid="AsideAccount"
      p="md"
      // hiddenBreakpoint="sm"
      // width={{ sm: 200, lg: 300 }}
    >
      <Box
        style={{
          backgroundColor:
            colorScheme === 'dark'
              ? lighten(theme.colors.gray[9], 0.6)
              : lighten(theme.colors.gray[6], 0.8),
          border: `1px solid ${
            colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.titan[6]
          }`,
          color: theme.colors.dark[9],
          borderRadius: 6,
          padding: 8,
          margin: -12,
          marginBottom: 12
        }}
      >
        <Text>User Account</Text>
      </Box>
      <Box key={userData?.name} style={{ padding: 8 }}>
        <Center>
          <Text>
            {userData?.name} {userData?.surName}
          </Text>
        </Center>
        <Center>
          <Text> {userData?.email} </Text>
        </Center>
        <Space h="md" />
        <Center>
          <Button noIcon testid="AsideAccount" onClick={() => logout()}>
            LogOut
          </Button>
        </Center>
      </Box>
      <Box style={{ marginTop: '20vh'}}>
      <Group justify="flex-end">
          <Button
            base="cancel"
            testid="DrawerSettingsCancel"
            onClick={() => {
              setShowAside('')
            }}
          >
            Close
          </Button>
        </Group>
      </Box>
    </Aside>
  );
};

export default AsideAccount;

import {
  Button as MantineButton,
  Group,
  useComputedColorScheme,
  ButtonProps
} from '@mantine/core';
import { useTID } from '../../helpers/useTestId';
import { Tooltip } from '../Tooltip';
import { ButtonIcon as Icon } from './ButtonIcon';

export const ButtonBaseNames = ['button', 'accept', 'add', 'cancel', 'remove', 'search'];
export type ButtonBaseName = typeof ButtonBaseNames[number];

export interface IButtonBaseProps {
  color: string;
  compact: boolean;
  icon: ButtonBaseName;
  label: string;
}

interface IButtonProps extends ButtonProps {
  base?: ButtonBaseName;
  label?: string;
  noIcon?: boolean;
  testid: string;
  value?: string;
  onClick?: (props?: any) => void;
  type?: 'button' | 'submit' | 'reset' | undefined;
  tooltip?: {
    label: string;
    color?: string;
    placement?: 'end' | 'start' | 'center' | undefined;
    position?: 'top' | 'left' | 'bottom' | 'right' | undefined;
    withArrow?: boolean;
  };
}

export const Button = (props: IButtonProps) => {
  // const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');
  const base: Record<string, IButtonBaseProps> = {
    button: { color: 'blue', compact: false, icon: 'button', label: '' },
    accept: { color: 'green', compact: false, icon: 'accept', label: 'Accept' },
    add: { color: 'green', compact: false, icon: 'add', label: 'Add' },
    cancel: { color: 'gray', compact: false, icon: 'cancel', label: 'Cancel' },
    remove: { color: 'red', compact: false, icon: 'remove', label: 'Remove' },
    search: { color: 'blue', compact: false, icon: 'search', label: 'Search' }
  };

  const baseButton: IButtonBaseProps = base[props.base || 'button'];
  const TID = useTID('Button', props.testid || 'unnamed');

  const defaultValues = {
    base: 'button',
    label: props.label || baseButton.label,
    variant: colorScheme === 'dark' ? 'outline' : 'filled'
  };

  let combinedProps = Object.assign({}, defaultValues, props);
  delete combinedProps.noIcon;
  if (props.base && props.base !== undefined && props.base !== 'button') {
    combinedProps = Object.assign({}, baseButton, combinedProps);
  }

  if (combinedProps.base === 'cancel') {
    combinedProps.variant = 'outline';
  }

  const IconRender = () => {
    if (baseButton !== undefined && !props.noIcon) {
      return (
        <Icon
          icon={baseButton.icon}
          // size={combinedProps.compact ? 16 : 20}
          // strokeWidth={combinedProps.compact ? 1.5 : 2}
          size={16}
          strokeWidth={1.5}

        />
      );
    }
    return <></>;
  };

  const padding = () => {
    // if (combinedProps.compact && (props.children || combinedProps.label)) {
    //   return 14;
    // }
    // if (combinedProps.compact) {
    //   return 4;
    // }
    if (!props.children && !combinedProps.label) {
      return 10;
    }
    return 18;
  };

  const iconRight = () => {
    if (props.noIcon) {
      return 0;
    }
    if (props.children || combinedProps.label) {
      return 10;
    }
    return 0;
  };

  if (props.tooltip) {
    return (
      <Group>
        <Tooltip {...props.tooltip} zIndex={9999}>
          <MantineButton
            style={{ leftSection: { marginRight: iconRight() }}}
            leftSection={<IconRender />}
            styles={{
              root: {
                // paddingLeft: padding(),
                paddingRight: padding()
              }
            }}
            {...combinedProps}
            {...TID}
          >
            {props.children || combinedProps.label}
          </MantineButton>
        </Tooltip>
      </Group>
    );
  }

  return (
    <MantineButton
      leftSection={<IconRender />}
      styles={{
        root: {
          // paddingLeft: padding(),
          paddingRight: padding()
        },
        // leftIcon: {
        //   marginRight: iconRight()
        // }
      }}
      {...TID}
      {...combinedProps}
    >
      {props.children || combinedProps.label}
    </MantineButton>
  );
};

export default Button;


import { Group, useComputedColorScheme, useMantineColorScheme } from '@mantine/core';
import { Tooltip } from '../Tooltip';
import { Sun, Moon } from 'tabler-icons-react';
import { useTID } from '../../helpers/useTestId';
import { Button } from '../Button';
import { useRef } from 'react';
import { useUserPrefsStore } from '../../app/store';

interface IButtonThemeProps {
  testid: string;
}

export const ButtonTheme = (props: IButtonThemeProps) => {
  const { setColorScheme } = useMantineColorScheme();
  const colorScheme = useComputedColorScheme('light');
  const SetSelectedTheme = useUserPrefsStore(state => state.SetSelectedTheme);
  const dark = colorScheme === 'dark';
  const TID = useTID('ButtonTheme', props.testid);
  const toggleTheme = () => {
    setColorScheme(colorScheme === 'dark' ? 'light' : 'dark');
    SetSelectedTheme(colorScheme === 'dark' ? 'light' : 'dark');
  };
  const ref = useRef<any>();

  return (
    <Group>
      <Tooltip label={`Toggle theme to ${dark ? 'light' : 'dark'}`} withArrow>
        <div ref={ref}>
          <Button
            className={`${!dark && 'themebtn-bg-color'}`}
            {...TID}
            testid="ButtonTheme"
            variant="subtle"
            style={{
              color: dark ? 'yellow' : '#4D8DFE'
            }}
            onClick={() => toggleTheme()}
            mr="xl"
            leftSection={dark ? <Sun size={24} style={{ margin: -8, marginLeft: 1}}/> : <Moon size={20} style={{ margin: -8, marginLeft: 0}} />}
          />
        </div>
      </Tooltip>
    </Group>
  );
};

import { HudGrid } from './HudGrid';
// import { HudHeader } from './HudHeader';
import { useHUDStore } from './state';
import { useEffect, useMemo } from 'react';
import { usePageMonitor } from '../PageMonitor/PageMonitor';
import { useNavigate } from 'react-router';
import { useAppConfigManager } from '../AppConfigs';

export interface IHudProps {
  name: string;
  testid: string;
}

export const Hud = (props: IHudProps) => {
  const HUDStore = useHUDStore();
  const [_pages, handlers] = usePageMonitor();
  const channel = useMemo(() => new BroadcastChannel('hud-add-widget'), []);
  const navigate = useNavigate();
  const GetConfigs = useAppConfigManager((state) => state.GetConfig);

  window.onstorage = () => {
    return handlers.isLoaded('Home') ? 'loaded' : 'not loaded';
  };

  useEffect(() => {
    handlers.load('HUD');
    channel.addEventListener('message', () => {
      navigate(0);
    });
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await GetConfigs('dropdown');
      } catch (err) {
        console.error('Error Loading Dropdowns: ', err);
      }
      try {
        await HUDStore.GetPersonalHUD();;
      } catch (err) {
        console.error('Error Loading Personal HUD: ', err);
      }
      HUDStore.SetOnLoad(true);
    })();
  }, []);

  useEffect(() => {
    // Handles navigation to avoid invalid hook calls in store
    if (HUDStore.navigate) {
      HUDStore.SetNavigate(false);
      HUDStore.GetPersonalHUD();
      HUDStore.SetOnLoad(true);
      navigate('/hud');
    }
  }, [HUDStore.navigate]);

  return <HudGrid />;
};

import { Center, Group, Loader, Text } from '@mantine/core';

export const Loading = () => {
  return (
    <Center>
      <Group align="apart">
        <Loader />
        <Text style={{ marginTop: 8, fontStyle: 'bold' }}>
          SSO Authentication in progress...
        </Text>
      </Group>
    </Center>
  );
};

import { Box, AppShellAsideProps } from '@mantine/core';
import {useTID} from '../../helpers/useTestId'

interface IAsideProps extends AppShellAsideProps {
  testid: string;
}

export const Aside = (props: IAsideProps) => {
  const TID = useTID('Aside', props.testid)
  return (
    <Box {...TID} {...props}>{props.children}</Box>
  );
};

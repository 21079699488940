import { useEffect, useRef, useState } from 'react';
import { DatePicker, DatesRangeValue, TimeInput } from '@mantine/dates';
import { lighten, darken, useComputedColorScheme } from '@mantine/core';
import { Clock } from 'tabler-icons-react';
import {
  ActionIcon,
  Badge,
  Box,
  Center,
  Divider,
  Group,
  Popover,
  Switch,
  Text,
  useMantineTheme
} from '@mantine/core';
import dayjs from 'dayjs';
import { useSearchStore } from '../../containers/Search/store';
import './DateRangePicker.css';
import {
  CustomMonthAndYearDropdown,
  DatePickerComp
} from './CustomMonthAndYear';
import { useDatePickerStore } from './store';
import { createRelativeDateRangeString } from '../../utils/utilities';
import { CustomMonthYearSelectedType } from './models';

export interface iDateRangePickerProps {
  timeRelative: boolean;
  startDate: Date | null;
  endDate: Date | null;
  startDateTime: string;
  endDateTime: string;
  disabled?: boolean;
}

export const DateRangePicker = (props: iDateRangePickerProps) => {
  const { timeRelative, startDate, endDate, startDateTime, endDateTime } = props;
  const { UpdateStartDate, UpdateEndDate, ToggleRelativeMode } = useSearchStore();
  const relativeColor = timeRelative ? '#0080FF' : 'violet';
  const [opened, setOpened] = useState(false);
  const customMonthYearSelected = useDatePickerStore((state)=>state.customMonthYearSelected)
  const SetCustomMonthYearSelected = useDatePickerStore((state)=>state.SetCustomMonthYearSelected)
  const theme = useMantineTheme();
  const startTimePickerRef = useRef<HTMLInputElement>();
  const endTimePickerRef = useRef<HTMLInputElement>();
  const colorScheme = useComputedColorScheme('light');
  let dateDisabled = props.disabled || false;
  let dateDisabledBackground =
    colorScheme === 'dark'
      ? darken(theme.colors.gray[8], 0.3)
      : lighten(theme.colors.gray[3], 0.8);
  let dateDisabledStyle = dateDisabled
    ? {
        backgroundColor: dateDisabled ? dateDisabledBackground : relativeColor,
        color: colorScheme === 'dark' ? theme.colors.gray[6] : theme.colors.gray[5]
      }
    : {};

  const [valueRange, setValueRange] = useState<[Date | null, Date | null]>([
    new Date(),
    new Date()
  ]);

  const [dateRange, setDateRange] = useState<Date>(
    new Date()
  );

  useEffect(() => {
    setValueRange([startDate, endDate]);
    setDateRange(startDate?? new Date())
    if (startDate) {
      const initialMonthAndYear: CustomMonthYearSelectedType = {
        month: startDate.getMonth(),
        year: startDate.getFullYear()
      }
      SetCustomMonthYearSelected(initialMonthAndYear)
    }
  }, [startDate, endDate, timeRelative, startDateTime, endDateTime]);

  useEffect(() => {
    setDateRange(new Date(customMonthYearSelected?.year, customMonthYearSelected?.month));
  }, [customMonthYearSelected]);

  const isCurrentDate = (date: Date) => {
    const currentDate = new Date();
    return (
      date.getDate() === currentDate.getDate() &&
      date.getMonth() === currentDate.getMonth() &&
      date.getFullYear() === currentDate.getFullYear()
    );
  };

  const onChangeDate = (range: DatesRangeValue) => {
    if (range[0]) {
      UpdateStartDate(dayjs(range[0]!.setHours(0, 0)).toDate());
    } else {
      UpdateStartDate(null);
    }
    if (range[1]) {
      UpdateEndDate(dayjs(range[1]!.setHours(23, 59)).toDate());
    } else {
      UpdateEndDate(null);
    }
  };
  const onTimeChange = (event: { target: { value: string } }, isEnd: boolean) => {
    const [hours, minutes] = event.target.value.split(':');
    if (isEnd) {
      UpdateEndDate(
        dayjs(valueRange[1]!.setHours(parseInt(hours), parseInt(minutes))).toDate()
      );
    } else {
      UpdateStartDate(
        dayjs(valueRange[0]!.setHours(parseInt(hours), parseInt(minutes))).toDate()
      );
    }
  };

  const toggleRelativeMode = (event: { target: { value: string } }) => {
    ToggleRelativeMode();
  };

  return (
    <Popover
      classNames={{
        arrow: 'arrow',
        dropdown: 'dropdown'
      }}
      opened={opened}
      onOpen={() =>
        SetCustomMonthYearSelected({
          month: dayjs(startDate).month(),
          year: dayjs(startDate).year()
        })
      }
      onClose={() => setOpened(false)}
      position="bottom"
      transitionProps={{ duration: 80 }}
      withArrow
      styles={{
        dropdown: { backgroundColor: colorScheme === 'dark' ? '#1A1B1E' : '#f1f3f5' }
      }}
    >
      <Popover.Target>
        <div>
          <Badge
            ml={-12}
            size="sm"
            variant="filled"
            color={relativeColor}
            onClick={(event: any) => {
              if (!dateDisabled) setOpened((o) => !o);
              event.stopPropagation();
            }}
            style={dateDisabledStyle}
          >
            {getRangeString(valueRange, timeRelative)}
          </Badge>
        </div>
      </Popover.Target>
      <Popover.Dropdown>
        <Box style={{ paddingBottom: '25px' }}>
          <DatePickerComp>
            <CustomMonthAndYearDropdown
              columns={2}
            />
            <DatePicker
              className={timeRelative ? 'relative' : 'exact'}
              onChange={onChangeDate}
              size={'sm'}
              type="range"
              numberOfColumns={2}
              firstDayOfWeek={0}
              date={dateRange}
              allowSingleDateInRange
              value={valueRange}
              onClick={(event: any) => {
                event.stopPropagation();
              }}
              getDayProps={(date) => {
                if (isCurrentDate(date)) {
                  return {
                    style: {
                      fontWeight: 'bold',
                      color: `${
                        colorScheme === 'dark'
                          ? theme.colors.green[8]
                          : theme.colors.green[7]
                      } !important`
                    }
                  };
                }
                if (date.getDay() == 6 || date.getDay() == 0) {
                  return {
                    style: {
                      color: `${
                        colorScheme === 'dark' ? theme.colors.red[8] : theme.colors.red[6]
                      } !important`
                    }
                  };
                }
                return {};
              }}
              // TODO: Requires logic to restrict start time to be before end time if on
              // TODO: the same day
              // fullWidth
            />
          </DatePickerComp>

          <Group>
            <TimeInput
              // @ts-ignore picker ref doesn't match type even though it looks like the docs
              ref={startTimePickerRef}
              onChange={(e) => onTimeChange(e, false)}
              value={
                dayjs(valueRange[0]).isValid()
                  ? dayjs(valueRange[0]).format('HH:mm')
                  : '00:00'
              }
              label={`Start Date Time ${dayjs(valueRange[0]).isValid()
                ? ` for ${dayjs(valueRange[0]).format('MM/DD/YY')}`
                : ''}`}
              rightSection={
                <ActionIcon
                  variant="subtle"
                  color={colorScheme === 'light' ? '#868E96' : 'white'}
                  onClick={() => startTimePickerRef!.current!.showPicker()}
                >
                  <Clock size="1rem" />
                </ActionIcon>
              }
              maw={200}
              mx="auto"
            />
            <TimeInput
              // @ts-ignore picker ref doesn't match type even though it looks like the docs
              ref={endTimePickerRef}
              onChange={(e) => onTimeChange(e, true)}
              value={
                dayjs(valueRange[1]).isValid()
                  ? dayjs(valueRange[1]).format('HH:mm')
                  : '23:59'
              }
              label={`End Date Time ${dayjs(valueRange[1]).isValid()
                ? ` for ${dayjs(valueRange[1]).format('MM/DD/YY')}`
                : ''}`}
              rightSection={
                <ActionIcon
                  variant="subtle"
                  color={colorScheme === 'light' ? '#868E96' : 'white'}
                  onClick={() => endTimePickerRef!.current!.showPicker()}
                >
                  <Clock size="1rem" />
                </ActionIcon>
              }
              maw={200}
              mx="auto"
            />
          </Group>
        </Box>
        <Divider size="xs" />
        <Center mt={6}>
          <Group>
            <Text size="sm" style={{ cursor: 'default' }}>
              Exact
            </Text>
            <Switch
              onChange={toggleRelativeMode}
              checked={timeRelative}
              label="Relative"
              styles={{
                track: {
                  background: timeRelative ? '#0080FF' : '#7048e8'
                }
              }}
            />
          </Group>
        </Center>
      </Popover.Dropdown>
    </Popover>
  );
};

export const getRangeString = (
  range: [Date | null, Date | null],
  timeRelative: boolean
) => {
  if (!range[0] || !range[1]) {
    return '';
  }
  const startTimeMeridian = range[0]!.getHours() >= 12 ? 'PM' : 'AM';
  const endTimeMeridian = range[1]!.getHours() >= 12 ? 'PM' : 'AM';
  const displayStartHours =
    range[0]?.getHours()! > 12 || range[0]?.getHours()! === 0
      ? Math.abs(range[0]?.getHours()! - 12)
      : range[0]?.getHours();
  const displayEndHours =
    range[1]?.getHours()! > 12 || range[1]?.getHours()! === 0
      ? Math.abs(range[1]?.getHours()! - 12)
      : range[1]?.getHours();
  const displayStartMinutes =
    range[0]?.getMinutes()! < 10 ? `0${range[0]?.getMinutes()!}` : range[0]?.getMinutes();
  const displayEndMinutes =
    range[1]?.getMinutes()! < 10 ? `0${range[1]?.getMinutes()!}` : range[1]?.getMinutes();
  if (timeRelative) {
    const today = dayjs(new Date()).startOf('day');
    const startDayOrDays =
      Math.abs(dayjs(range[0]!).diff(today, 'day')) === 1 ? 'day' : 'days';
    const endDayOrDays =
      Math.abs(dayjs(range[1]!).diff(today, 'day')) === 1 ? 'day' : 'days';
    const dates = createRelativeDateRangeString(range[0], range[1]);
    const startAgoOrFromNow = dayjs(range[0]!).isBefore(today) ? 'ago' : 'from now';
    const endAgoOrFromNow = dayjs(range[1]!).isBefore(today) ? 'ago' : 'from now';
    const rangeString =
      Math.abs(dayjs(range[0]!).diff(today, 'day')) === 0 &&
      Math.abs(dayjs(range[1]!).diff(today, 'day')) === 0
        ? 'Today'
        : `${displayStartHours}:${displayStartMinutes}${startTimeMeridian} ${dates.startDateTime} ${startDayOrDays} ${startAgoOrFromNow} to ${displayEndHours}:${displayEndMinutes}${endTimeMeridian}
    ${dates.endDateTime} ${endDayOrDays} ${endAgoOrFromNow}`;
    return rangeString;
  } else {
    return `${dayjs(
      range[0]!
    ).format(
      'MMM DD, YYYY'
    )} (${displayStartHours}:${displayStartMinutes} ${startTimeMeridian} | EST) to ${dayjs(
      range[1]!
    ).format('MMM DD, YYYY')} (${displayEndHours}:${displayEndMinutes} ${endTimeMeridian} | EST) `;
  }
};

export default DateRangePicker;

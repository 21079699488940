export interface iMessagePopovers {
  IsOuterOpen: boolean;
  IsInnerOpen: boolean;
  IsInactiveOpen: boolean;
  PopoverIDOuter: string;
  PopoverIDInner: string;
  SetIsOpenOuter: (newState: boolean) => void;
  SetIsOpenInner: (newState: boolean) => void;
  SetIDOuter: (newID: string) => void;
  SetIDInner: (newID: string) => void;
  ToggleIsOuterOpen: () => void;
  ToggleIsInnerOpen: () => void;
  ToggleIsInactiveOpen: () => void;
}

export const initialMessagePopOverValues = {
  IsOuterOpen: false,
  IsInnerOpen: false,
  IsInactiveOpen: false,
  PopoverIDOuter: '',
  PopoverIDInner: '',
  SetIsOpenOuter: (newState: boolean) => {},
  SetIsOpenInner: (newState: boolean) => {},
  SetIDOuter: (newID: string) => {},
  SetIDInner: (newID: string) => {},
  ToggleIsOuterOpen: () => {},
  ToggleIsInnerOpen: () => {},
  ToggleIsInactiveOpen: () => {}
}

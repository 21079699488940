import { useRef } from 'react';
import { Box, Popover, useMantineTheme, useComputedColorScheme, lighten } from '@mantine/core';
import { File, FileText } from 'tabler-icons-react';
import { globalColors as colors } from '../../theme/globalColors';
import { CommentPanel } from './CommentPanel';
import { useCommentStore } from './store';

export const CommentsButton = (hasComments: boolean, id: string, rowId: string) => {
  const ref = useRef<any>();
  const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');
  const activeOfferingComments = useCommentStore((state) => state.id);
  const opened = activeOfferingComments === id;
  const SetOfferingCommentsOpen = useCommentStore(
    (state) => state.SetOfferingCommentsOpen
  );

  function onClick() {
    if (opened) {
      SetOfferingCommentsOpen('');
    } else {
      SetOfferingCommentsOpen(id);
    }
  }

  if (hasComments) {
    return (
      <Popover
        position="bottom"
        withArrow
        shadow="md"
        withinPortal
        classNames={{
          arrow: 'popoverArrow',
          dropdown: 'popoverDropdownBelow'
        }}
      >
        <Popover.Target>
          <Box
            ref={ref}
            style={{ display: 'flex', justifyContent: 'center' }}
            onClick={onClick}
          >
            <FileText size={22} strokeWidth={2} color={colors.wbdblue[4]} />
          </Box>
        </Popover.Target>
        <Popover.Dropdown>
          <Box>
            <CommentPanel id={id} rowId={rowId} />
          </Box>
        </Popover.Dropdown>
      </Popover>
    );
  }
  return (
    <Popover
      position="bottom"
      withArrow
      shadow="md"
      withinPortal
      classNames={{
        arrow: 'popoverArrow',
        dropdown: 'popoverDropdownBelow'
      }}
    >
      <Popover.Target>
        <Box
          ref={ref}
          style={{ display: 'flex', justifyContent: 'center' }}
          onClick={onClick}
        >
          <File
            size={22}
            strokeWidth={2}
            color={
              colorScheme === 'dark'
                ? lighten(theme.colors.gray[9], 0.6)
                : lighten(theme.colors.gray[6], 0.8)
            }
            aria-label="file"
          />
        </Box>
      </Popover.Target>
      <Popover.Dropdown>
        <CommentPanel id={id} rowId={rowId} />
      </Popover.Dropdown>
    </Popover>
  );
};

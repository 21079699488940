import { iCodeDetail } from '../components/Milestone/models';
import { STATUS_MAP } from '../constants/constants';

// getStatus returns the mapped status for a given string
// pending is returned for invalid strings right now
export const getStatus = (milestoneStatus: string) => {
  let newStatus = 'nodata';
  if (!milestoneStatus) {
    return 'null';
  }
  let statusParts = milestoneStatus.split(":")

  if(statusParts.length > 2){
    milestoneStatus = statusParts.slice(-2)[0]
  }
  STATUS_MAP.forEach((val: any, status: any) => {
    if (val.includes(milestoneStatus.toLowerCase())) {
      newStatus = status;
    }
  });
  return newStatus;
};

export const getCodeDetails = (codes: iCodeDetail[]) => {
  const codeDetails = { code: '', detail: '', data: '' };
  if (codes && codes?.length > 0){
    codeDetails.code = codes[0]!.code!;
    if (codes[0]?.detail ) {
      codeDetails.detail = codes[0]!.detail!;
    }
    if (codes[0]?.data ) {
      codeDetails.data = codes[0]!.data!;
    }
  }
  return codeDetails;
};

// getRandomStatus returns a random status for testing
export const getRandomStatus = () => {
  const stats = ['error', 'failure', 'processing', 'pending', 'completed'];
  return stats[Math.floor(Math.random() * 5)];
};

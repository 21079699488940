import { useEffect, useRef } from 'react';
import { Box, Button, Grid, Flex, useComputedColorScheme } from '@mantine/core';
import { AgGridContainer } from '../AgGridContainer';
import { AgGridReact } from 'ag-grid-react';
import { ContainerComponent } from '../ContainerComponent';
import { StatusCodeDictonaryGridOptions } from './_gridOptions';
// import { StatusCodesService } from '../../services/statuscodes/statusCodesService';
import { statusCodeDictionaryColumnDefs, scdColumnDefsFullGrid } from './_colDefs';
import { initialStatusCode, StatusCode } from './models';
import { useStatusCodeStore } from './store';
import { StatusCodeForm } from './StatusCodeForm';
import { CellClickedEvent } from 'ag-grid-community';
import { SCDHeaderBar } from './_headerbar';
import { useFootprintStore } from '../../containers/Footprints/store';

export const StatusCodeDictionary = () => {
  const gridRef = useRef<any>();
  // const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');
  const gridCSS =
    colorScheme === 'dark' ? 'ag-theme-alpine-dark' : 'ag-theme-alpine';
  const gridHeight = 700;
  const gridWidth = '100%';
  const FetchStatusCodes = useStatusCodeStore((state) => state.FetchStatusCodes);
  const { statusCode, statusCodes, searchStatusCode } = useStatusCodeStore();
  const { statusCodeCount, SetStatusCodeCount } = useStatusCodeStore();
  const includeDepreciated = useStatusCodeStore((state) => state.includeDepreciated);
  const { fullGrid, gridAPI } = useStatusCodeStore();
  const SetStatusCode = useStatusCodeStore((state) => state.SetStatusCode);
  const SetFootprints = useStatusCodeStore((state) => state.SetFootprints);
  const title = 'Status Code Dictionary (' + statusCodeCount + ')';
  const LoadFootPrints = useFootprintStore.getState().LoadFootprints;

  const onCellClicked = (props: CellClickedEvent) => {
    let sc = props.data as StatusCode;
    SetStatusCode(sc);
    SetFootprints(sc.footprints);
  };

  const gridOptions = { ...StatusCodeDictonaryGridOptions };
  gridOptions.columnDefs = statusCodeDictionaryColumnDefs;
  gridOptions.onCellClicked = onCellClicked;
  gridOptions.onCellDoubleClicked = onCellClicked;

  // load StatusCodes
  useEffect(() => {
    (async () => {
      try {
        await FetchStatusCodes();
      } catch (err) {
        console.error('Error Loading StatusCodes: ', err);
      }
      try {
        await LoadFootPrints();
      } catch (err) {
        console.error('Error Loading Footprints: ', err);
      }
    })();
  }, []);

  useEffect(() => {
    if (gridAPI === undefined) {
      return;
    }
    let coldefs = fullGrid ? scdColumnDefsFullGrid : statusCodeDictionaryColumnDefs;
    gridAPI.setColumnDefs(coldefs);
    gridAPI.setSideBarVisible(fullGrid);
    gridAPI.sizeColumnsToFit();
  }, [fullGrid]);

  // Search / Filter StatusCodes
  useEffect(() => {
    let filtered = statusCodes;
    if (!includeDepreciated) {
      filtered = filtered.filter((f) => {
        return !f.deprecated;
      });
    }
    if (searchStatusCode && searchStatusCode !== '') {
      filtered = filtered.filter((f) => {
        if (f.statusCode.includes(searchStatusCode)) {
          return true;
        }
        return false;
      });
      gridRef?.current?.api?.setRowData(filtered);
      SetStatusCodeCount(filtered.length);
      return;
    }
    if (filtered.length > 0) {
      gridRef?.current?.api?.setRowData(filtered);
      SetStatusCodeCount(filtered.length);
    }
    if (statusCode.statusCode !== '') {
      gridRef.current?.api?.forEachNode((node: any) => {
        if (node.data.statusCode === statusCode.statusCode) {
          node.setSelected(true);
        }
      });
    }
  }, [statusCode, statusCodes, searchStatusCode, includeDepreciated]);

  return (
    <Box style={{ width: '100%' }}>
      <ContainerComponent
        testid="StatusCodeDictionary"
        title={title}
        rightSection={<SCDHeaderBar />}
      >
        <Grid>
          <Grid.Col span={fullGrid ? 12 : 4}>
            <AgGridContainer>
              <div
                className={gridCSS}
                style={{ height: gridHeight, width: gridWidth || '700px' }}
              >
                <AgGridReact ref={gridRef} gridOptions={gridOptions} />
              </div>
            </AgGridContainer>
          </Grid.Col>
          {!fullGrid && (
            <Grid.Col span={8}>
              <StatusCodeForm />
              {statusCode.statusCode === '' && (
                <Flex
                  style={{ height: '100%' }}
                  justify="flex-end"
                  align="flex-end"
                  direction="row"
                  pb={16}
                  pr={16}
                >
                  <Button
                    color="blue"
                    onClick={() => {
                      let i = {} as StatusCode;
                      i = Object.assign({}, i, initialStatusCode);
                      i.statusCode = 'urn:';
                      SetStatusCode(i);
                    }}
                  >
                    New
                  </Button>
                </Flex>
              )}
            </Grid.Col>
          )}
        </Grid>
      </ContainerComponent>
    </Box>
  );
};

import useAxios from '../../services/axios/axios';
import {
  constructOfferingObject,
  constructPartialOfferingObject
} from './fetchOfferingRowData';
import { iSearch } from '../../containers/Search/models';
import { useConfigStore } from '../../app/store';
import { getStatusCodeString } from '../../utils/utilities';
import { useAppConfigManager } from '../../components/AppConfigs';

export const fetchOfferingStatusCount = async (startDate: Date | null, endDate: Date | null, searchState: iSearch, milestoneName: string) => {
  const { post } = useAxios();
  const baseURL = useConfigStore.getState().config.baseApiGatewayUrl + '/graphql/search';

  const query = constructQuery(startDate, endDate, searchState, milestoneName);

  const schema = {
    query: query
  };

  const result = await post({
    url: baseURL,
    data: JSON.stringify(schema)
  });

  if (
    result &&
    result.data &&
    result.data.statusCount &&
    result.data.statusCount.records
  ) {
    return result.data.statusCount.records;
  }
  return 0;
};

export const constructQuery = (startDate: Date | null, endDate: Date | null, searchState: iSearch, milestoneName: string) => {
  const queryFilters = constructFilters(startDate, endDate, searchState, milestoneName);
  const query = `query {
          statusCount(
            ${queryFilters}
          ) {
            records {
              status
              count
            }
          }
        }`;

  return query;
};

const constructFilters = (startDate: Date | null, endDate: Date | null, filters: any, milestoneName: string) => {
  const milestoneStatuses =
    filters.MilestoneStatus?.length > 0
      ? `[${filters.MilestoneStatus?.map((status: any) => `"${status}"`)}]`
      : '[]';
  const milestones =
    filters.Milestone?.length > 0
      ? `[${filters.Milestone?.map((status: any) => `"${status}"`)}]`
      : '[]';
  const milestoneString = `milestones:{
      name: ${milestones},
      status: ${milestoneStatuses}
    }`;

  formattedMilestoneNames.forEach((n) => {
    if (milestoneName === n.mName) milestoneName = n.searchName;
  });

  let partialSearchInput: string = '';
  if (filters.SearchInput) {
    let searchInputs;
    if (filters.SearchInputField === 'keyword' ||
    filters.SearchInputField === 'playlistSearch') {
      let copy = filters.SearchInput;
      let newInput = copy.replaceAll('"', '\\"');
      searchInputs = newInput;
    } else {
      searchInputs = filters.SearchInput.split(' ');
      if (searchInputs.length > 1) {
        searchInputs = searchInputs.join('","');
      }
    }
    partialSearchInput = searchInputs.toString();
  }

  let csId = ''
  if (filters.SearchInputField && filters.SearchInputField === 'csId') {
    let input = '';
    let searchInputs = filters.SearchInput.split(' ');
    if (searchInputs.length > 1) {
      input = searchInputs.join('","');
    } else {
      input = searchInputs[0];
    }
    csId =
    `
    csId: {
      id: ["${input}"]
    }
    `
  }

  let isDeleted = ''
  if (filters.DeletedAssets === 'notDeleted'){
    isDeleted = 'isDeleted: false'
  } else if (filters.DeletedAssets === 'deleted'){
    isDeleted = 'isDeleted: true'
  }
  
  let fulfilledMediaMarkerCount = '';
  if (filters.AdMarkers === 'true') {
    fulfilledMediaMarkerCount = `
    fulfilledMediaMarkerCount: {
      value: 1
      operation: gte
    }
    `;
  } else if (filters.AdMarkers === 'false') {
    fulfilledMediaMarkerCount = `
    fulfilledMediaMarkerCount: {
      value: 1
      operation: lt
    }
    `;
  }
  let contentRequests='';
  if (filters.MidRollAdsAllowed === 'true') {
    contentRequests = `
    contentRequests: {
      indicators: {
        midRollAdsAllowedIndicator: true
      }
    }
    `;
  } else if (filters.MidRollAdsAllowed === 'false') {
    contentRequests = `
    contentRequests: {
      indicators: {
        midRollAdsAllowedIndicator: false
      }
    }
    `;
  }

  const offeringObject = constructOfferingObject(startDate, endDate, filters);
  let partialObject = '';
  if (
    (partialSearchInput && filters.SearchInputField === 'keyword') ||
    filters.SearchInputField === 'playlistSearch'
  ) {
    partialObject = constructPartialOfferingObject(partialSearchInput);
  }
  
  let auditObject = `audit:{}`
  if (filters.DateField === 'lastUpdated' && filters.dateEnabled) {
    auditObject = `audit:{
            updatedAtFrom:"${startDate!.toISOString()}",
            updatedAtTo:"${endDate!.toISOString()}"
          }`;
  }

  let tasks='';
  if(contentRequests!=''||fulfilledMediaMarkerCount!='')
    tasks=`tasks : { 
            details: {${contentRequests}${fulfilledMediaMarkerCount}} 
          }`;
  const taskValueMapper = useAppConfigManager.getState().taskValueMapper
  const statusCodeString = getStatusCodeString(filters.StatusCodes, taskValueMapper)

  let tier1Tag = ''
  if (filters.Tier1){
    tier1Tag = 
    `tags:[
      "tier1"
    ]`
  }

  const queryFilters = `filters: {
        ${auditObject}
        ${milestoneString}
        ${statusCodeString}
        ${offeringObject}
        ${partialObject}
        ${csId}
        ${isDeleted}
        ${tasks}
        ${tier1Tag}
        byMilestone: "${milestoneName}"
    }`;
  return queryFilters;
};

export const formattedMilestoneNames = [
  { searchName: 'offering-footprint', mName: 'Overall Status' },
  { searchName: 'Prep|Video|ccReady', mName: 'Prep|Video|CC Ready' },
  { searchName: 'Prep|Video|csDelivery', mName: 'Prep|Video|CS Delivery' },
  { searchName: 'Prep|Video|masterLookup', mName: 'Prep|Video|Master Lookup' },
  { searchName: 'Prep|Video|avodGating', mName: 'Prep|Video|AVOD Gating' },
  { searchName: 'Prep|Video|pickpackRules', mName: 'Prep|Video|Pick/Pack Rules' }
];

import { useEffect, useState } from 'react';
import { fetchTimelineHistory } from '../../api/fetchTimelineHistory';
import { useFootprintStore } from '../../containers/Footprints/store';
import { useDetailsStore } from '../../containers/Views/store';
import { iDataPanelProcessProps } from '../DataPanels/models';
import { InfoPanel } from './InfoPanel';
import {
  Center,
  CloseButton,
  Group,
  Loader,
  MultiSelect,
  Text,
  useComputedColorScheme,
  useMantineTheme
} from '@mantine/core';
import { Button } from '../../components/Button';
import { FilterTimelineTasks } from './FilterTimelineTasks';
import { useViewportSize } from '@mantine/hooks';
import { CascaderStatusCodeTimeline } from './Cascader/CascaderStatusCodeTimeline';
import { useTimelineStore } from './store/store';
import { useUserPrefsStore } from '../../app/store';
import classes from '../../containers/SearchBar/SearchBar.module.css';
import { Filter } from 'tabler-icons-react';

export const TimelineData = (props: iDataPanelProcessProps) => {
  const activeFootprint = useFootprintStore.getState().active.id;
  const details = useDetailsStore.getState();
  const [sortedData, setSortedData] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<any>([]);
  const taskFilters = useTimelineStore((state) => state.taskFilters);
  const SetTaskFilters = useTimelineStore((state) => state.SetTaskFilters);
  const isStatusCode = useTimelineStore((state) => state.isStatusCode);
  const SetIsStatusCode = useTimelineStore((state) => state.SetIsStatusCode);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [filterCount, setFilterCount] = useState<number>(0);

  const [isTaskName, setIsTaskName] = useState<boolean>(false);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const taskNames = calculateTaskNames(props.data, props.statusObject);
  const { height } = useViewportSize();
  const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');

  const fetchTaskHistoryRowData = async () => {
    setDataLoading(true);
    let footprintId = '';
    let footprintType = '';
    // Add additional footprints as needed
    let fp = props.footprint ? props.footprint : activeFootprint;
    switch (fp) {
      case 'footprint-offering':
        footprintId = details?.offering?.offering?.id;
        footprintType = 'ondemand';
        break;
      case 'footprint-linear':
        footprintId = details?.schedule?.id;
        footprintType = 'linear';
        break;
      case 'footprint-acquire':
        footprintId = details?.acquire?.id;
        footprintType = 'acquisition';
        break;
      case 'footprint-asset':
        footprintId = details?.asset?.id;
        footprintType = 'offeringsAsset';
        break;
      case 'footprint-crossplatform':
        footprintId = details?.crossplatform?.offering.id;
        footprintType = 'affiliateofferings';
        break;
      default:
        footprintId = details?.offering?.offering?.id;
        footprintType = 'ondemand';
        break;
    }

    let result = await fetchTimelineHistory(
      taskNames,
      footprintId,
      footprintType,
      props.statusObject
    );
    if (!result) {
      setSortedData([]);
      return;
    }

    const removedNulls = result.rowData.filter((d: any) => d !== null);
    const sortedData =
      removedNulls.length > 0
        ? removedNulls.sort(function (a: any, b: any) {
            return b.audit.generatedAt.localeCompare(a.audit.generatedAt);
          })
        : [];
    setSortedData(sortedData);
    setRecordCount(sortedData.length);
    setFilterCount(sortedData.length);
    setFilteredData(sortedData);
    const userFilterPrefs = useUserPrefsStore.getState().detailTaskFilters;
    const footprintPref = userFilterPrefs?.find((i) => i.footprint === activeFootprint);
    if (footprintPref) {
      if (footprintPref.statusCode) {
        if (footprintPref.filter.length > 0) {
          SetIsStatusCode(true);
          SetTaskFilters(footprintPref.filter);
        }
      } else {
        if (footprintPref.filter.length > 0) {
          setIsTaskName(true);
          SetTaskFilters(footprintPref.filter);
        }
      }
    }
    setDataLoading(false);
  };

  useEffect(() => {
    fetchTaskHistoryRowData();
  }, []);

  useEffect(() => {
    if (sortedData) {
      const filterProps = {
        data: sortedData,
        filter: taskFilters,
        isTaskName,
        isStatusCode,
        milestoneData: props.data,
        statusObject: props.statusObject
      };
      const filteredData = FilterTimelineTasks(filterProps);
      setFilteredData(filteredData);
      setFilterCount(filteredData.length);
    }
  }, [taskFilters, sortedData]);

  const clearFilters = () => {
    SetTaskFilters([]);
    SetIsStatusCode(false);
    setIsTaskName(false);
  };

  let rowCountButton =
    filterCount < recordCount ? (
      <Group>
        <Button
          noIcon
          testid="clear-all-grid-filters"
          color={theme.colors.blue[6]}
          size="xs"
          variant="subtle"
          leftSection={<Filter />}
          onClick={clearFilters}
          tooltip={{
            label: 'Clear All Grid Filters',
            position: 'top',
            color: colorScheme === 'dark' ? 'gray' : 'black'
          }}
        >
          Rows:&nbsp;{' '}
          <b>
            {filterCount}/{recordCount}
          </b>
        </Button>
      </Group>
    ) : (
      <Button noIcon testid="grid-filters" color="gray" size="xs" variant="subtle">
        Rows:&nbsp; <b>{recordCount}</b>
      </Button>
    );

  return (
    <div style={{
      color: colorScheme === 'dark' ? '#C1C2C5' : 'black'
    }}>
      {dataLoading && (
        <Center>
          <Group>
            <Loader size="sm" variant="dots" />
            Loading Timeline Data…
          </Group>
        </Center>
      )}
      {!dataLoading && (
        <div>
          <div className="filter-bar">
            <div className="filter-bar">
              <CascaderStatusCodeTimeline className="task-filter" disabled={isTaskName} />
              <Text> OR </Text>
              <MultiSelect
                disabled={isStatusCode}
                rightSection={
                  <CloseButton
                    onMouseDown={() => {
                      SetTaskFilters([]);
                      setIsTaskName(false);
                    }}
                    variant="transparent"
                    size={19}
                    iconSize={12}
                  />
                }
                classNames={{
                  input: classes.multiselectInput
                }}
                style={{ borderColor: theme.colors.gray[4] }}
                clearable
                onChange={(v) => {
                  SetTaskFilters(v);
                  setIsTaskName(v.length > 0);
                }}
                value={taskFilters ? (isTaskName ? taskFilters : []) : []}
                data={taskNames.sort()}
                label="Filter by Task Name"
                placeholder="Select Any"
                size="xs"
                className="task-filter"
                comboboxProps={{ zIndex: 10000 }}
              />
            </div>
            {rowCountButton}
          </div>
          <div
            style={{
              overflow: 'auto',
              maxHeight: height - 301,
              // backgroundColor: colorScheme === 'light' ? '#f9f9f9' : '#252525'
              backgroundColor: colorScheme === 'dark' ? '#25262B' :'#f9f9f9',
              border: `1px solid ${colorScheme === 'dark' ? '#26282B' : '#E3E3E3'}` 
            }}
          >
            {filteredData?.map((i: any) => {
              const milestoneName = calculateMilestoneFromTask(
                props.data,
                props.statusObject,
                i.type
              );
              return <InfoPanel item={i} name={milestoneName} />;
            })}
          </div>
        </div>
      )}
    </div>
  );
};

const calculateTaskNames = (data: any, statusObject: boolean) => {
  let taskNames = [];
  if (statusObject) {
    const tasks = Object.keys(data.status).filter((i) => i.split('|').length === 3);

    taskNames = tasks.map((i) => {
      let taskName = i.split('|')[2].toLocaleLowerCase();
      if (taskName === 'metabmmvalidate' || taskName === 'metabmmjobmonitor') {
        taskName = 'metacontent' + taskName.slice(4);
      }
      return taskName;
    });
  } else {
    taskNames = data.milestones
      // .filter((milestone: { type: string; })=> milestone.type === 'Task')
      .filter((milestone: { type: string; name: string }) => milestone.type === 'Task')
      .map((milestone: { name: string }) => {
        let taskName = milestone.name.split('|')[2].toLocaleLowerCase();
        if (taskName === 'metabmmvalidate' || taskName === 'metabmmjobmonitor') {
          taskName = 'metacontent' + taskName.slice(4);
        }
        return taskName;
      });
  }
  return taskNames?.filter(
    (value: any, index: any, self: string | any[]) => self.indexOf(value) === index
  );
};

export const calculateMilestoneFromTask = (
  data: any,
  statusObject: boolean,
  taskName: string
) => {
  let milestoneName = '';
  if (statusObject) {
    const tasks = Object.keys(data.status).filter((i) => i.split('|').length === 3);

    const name = tasks.find((i) => {
      return i.toLocaleLowerCase().includes(taskName);
    });

    if (name) {
      milestoneName = name;
    }
  } else {
    const milestone = data.milestones
      .filter((milestone: { type: string; name: string }) => milestone.type === 'Task')
      .find((milestone: { name: string }) => {
        if (taskName === 'metabmmvalidate' || taskName === 'metabmmjobmonitor') {
          taskName = 'metacontent' + taskName.slice(4);
        }
        return milestone.name.toLocaleLowerCase().includes(taskName);
      });

    if (milestone?.name) {
      milestoneName = milestone.name;
    }
  }
  return milestoneName;
};

import { Accordion, AccordionProps } from '@mantine/core';
import { useState } from 'react';

interface iDetailAccordionProps extends AccordionProps<true> {
  defaultValue: string[];
}

export const DetailAccordion = (props: iDetailAccordionProps) => {
  const [items, setItems] = useState<string[]>([]);

  const onChange = (evt: any) => {
    setItems(evt);
  };

  return (
    <>
      <Accordion multiple defaultValue={items} onChange={onChange} chevronPosition="left"
        classNames={{
          label: 'accordionLabel',
        }}
      >
        {props.children}
      </Accordion>
    </>
  );
};

import { Title as MTitle, TitleProps } from '@mantine/core';
import {useTID} from '../../helpers/useTestId'

interface ITitleProps extends TitleProps {
  testid: string;
}

export const Title = (props: ITitleProps) => {
  const TID = useTID('Title', props.testid || 'undefined')

  return (
    <MTitle {...TID} {...props}>
      {props.children}
    </MTitle>
  );
};

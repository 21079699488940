import { Grid, Select, Text, useComputedColorScheme, darken, lighten } from '@mantine/core';
import { MultiSelect } from '../../components/MultiSelect';
import { Switch } from '../../components/Switch';
import { iSearch } from '../Search/models';
import { useSearchStore } from '../Search/store';
import { useSearchBarConfigureStore } from '../SearchBarConfigure/store';
import { useTID } from '../../helpers/useTestId';
import { useFootprintStore } from '../Footprints/store';
import { useAppConfigManager } from '../../components/AppConfigs/store';
import { globalColors as colors } from '../../theme/globalColors';
import { CascaderNetwork } from '../../components/CascaderNetwork/CascaderNetwork';
import { CascaderStatusCode } from '../../components/CascaderStatusCode/CascaderStatusCode';

export const SearchOptionsLinear = (props: { search: iSearch }) => {
  const UpdateSearchInput = useSearchStore((state) => state.UpdateSearchInput);
  const { search } = props;
  const configure = useSearchBarConfigureStore((state) => state);
  const GetDropDown = useAppConfigManager((state) => state.GetDropDown);
  // const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');

  const handleUpdateViewSearch = (value: any, field: string) => {
    UpdateSearchInput({ field: field, value: value! });
  };

  const activeFootprint = useFootprintStore.getState().active.id;
  const milestones = useAppConfigManager((state) =>
    state.GetDropDown('milestones:' + activeFootprint)
  );

  function showSelectMilestone() {
    if (configure.showMilestoneSelect) {
      const TID = useTID('MultiSelect', 'Milestones');
      return (
        <MultiSelect
          disabled={search.StatusCodes?.length > 0}
          {...TID}
          clearable
          onChange={(v) => handleUpdateViewSearch(v, 'Milestone')}
          value={search.Milestone?.length > 0 ? search.Milestone : []}
          data={milestones}
          label="Milestone"
          placeholder="Select Any"
          size="xs"
          inputPropName={'Milestone'}
          style={{
            // '.mantine-Input-input': {
              backgroundColor:
                colorScheme === 'dark'
                  ? darken(colors.wbdblue[9], 0.4)
                  : colors.wbdblue[0],
              borderColor:
                colorScheme === 'dark'
                  ? lighten(colors.wbdblue[9], 0.15)
                  : '#B8D2FD'
            // }
          }}
        />
      );
    }
    return <></>;
  }

  function showCascaderStatusCode() {
    if (configure.showCascaderStatusCode) {
      return (
        <>
          <Text>or</Text>
          <CascaderStatusCode
            disabled={search.Milestone?.length > 0 || search.MilestoneStatus?.length > 0}
          />
        </>
      );
    }
    return <></>;
  }

  function showSelectMilestoneStatus() {
    if (configure.showMilestoneStatusSelect) {
      const TID = useTID('MultiSelect', 'MilestoneStatus');
      return (
        <MultiSelect
          disabled={search.StatusCodes?.length > 0}
          {...TID}
          clearable
          onChange={(v) => handleUpdateViewSearch(v, 'MilestoneStatus')}
          value={search.MilestoneStatus?.length > 0 ? search.MilestoneStatus : []}
          data={GetDropDown('milestoneStatuses')}
          label="Milestone Status"
          placeholder="Select Any"
          size="xs"
          inputPropName={'MilestoneStatus'}
          style={{
            // '.mantine-Input-input': {
              backgroundColor:
                colorScheme === 'dark'
                  ? darken(colors.wbdblue[9], 0.4)
                  : colors.wbdblue[0],
              borderColor:
                colorScheme === 'dark'
                  ? lighten(colors.wbdblue[9], 0.15)
                  : '#B8D2FD'
            // }
          }}
        />
      );
    }
    return <></>;
  }

  function showSelectScheduleType() {
    if (configure.showScheduleType) {
      const TID = useTID('MultiSelect', 'ScheduleType');
      return (
        <Select
          {...TID}
          onChange={(v) => handleUpdateViewSearch(v, 'ScheduleType')}
          value={search.ScheduleType ? search.ScheduleType : ''}
          data={GetDropDown('scheduleTypes')}
          label="Schedule Type"
          placeholder="Select Any"
          size="xs"
          classNames={{
            // item: classes.item,
            input: colorScheme === 'dark' ? 'inputDark' : 'input',
            label: 'item'
          }}
          style={{
            // '.mantine-Input-input': {
              backgroundColor:
                colorScheme === 'dark'
                  ? darken(colors.wbdblue[9], 0.4)
                  : colors.wbdblue[0],
              borderColor:
                colorScheme === 'dark'
                  ? lighten(colors.wbdblue[9], 0.15)
                  : '#B8D2FD'
            // }
          }}
        />
      );
    }
    return <></>;
  }

  function showLinearExpired() {
    if (configure.showLinearExpired) {
      return (
        <div style={{ paddingTop: '3px' }}>
          <Text style={{ paddingBottom: '4px' }} size={'xs'}>
            Show Expired:
          </Text>
          <Switch
            checked={search?.LinearExpired}
            // label={`${search?.ExpiredAssets ? '' : 'Do NOT '}Show Expired Assets.`}
            testid="linear-expired"
            onChange={(v) =>
              handleUpdateViewSearch(!search?.LinearExpired, 'LinearExpired')
            }
          />
        </div>
      );
    }
    return <></>;
  }

  // function showSuppressRepeats() {
  //   if (configure.showSuppressRepeats) {
  //     return (
  //       <div style={{ position: 'relative' }}>
  //         <Text style={{ position: 'absolute', top: '-18px' }} size={'xs'}>
  //           Suppress Repeats:
  //         </Text>
  //         <Switch
  //           checked={search?.SuppressRepeats}
  //           testid="suppress-repeats"
  //           onChange={(v) =>
  //             handleUpdateViewSearch(!search?.SuppressRepeats, 'SuppressRepeats')
  //           }
  //         />
  //       </div>
  //     );
  //   }
  //   return <></>;
  // }

  function showSuppressRepeats() {
    if (configure.showSuppressRepeats) {
      const TID = useTID('MultiSelect', 'SuppressRepeats');
      return (
        <Select
          {...TID}
          clearable
          onChange={(v) => handleUpdateViewSearch(v, 'SuppressRepeats')}
          value={search.SuppressRepeats !=="" ? search.SuppressRepeats : null}
          data={GetDropDown('suppressRepeats')}
          label="Suppress Repeats"
          placeholder="Select Any"
          size="xs"
          classNames={{
            // item: classes.item,
            input: colorScheme === 'dark' ? 'inputDark' : 'input',
            label: 'item'
          }}
          style={{
            // '.mantine-Input-input': {
              backgroundColor:
                colorScheme === 'dark'
                  ? darken(colors.wbdblue[9], 0.4)
                  : colors.wbdblue[0],
              borderColor:
                colorScheme === 'dark'
                  ? lighten(colors.wbdblue[9], 0.15)
                  : '#B8D2FD'
            // }
          }}
        />
      );
    }
    return <></>;
  }

  function showSelectNetwork() {
    if (configure.showNetworkSelect) {
      return (
        <>
          {/*<MultiSelect
            inputPropName={'Network'}
            {...TID}
            onChange={(v) => handleUpdateViewSearch(v, 'Network')}
            value={search.Network ? search.Network : []}
            data={GetDropDown('networks')}
            label="Network"
            placeholder="Select Any"
            size="xs"
            classNames={{
              item: classes.item,
              input: classes.input,
              label: classes.item
            }}
            sx={{
              '.mantine-Input-input': {
                backgroundColor:
                  theme.colorScheme === 'dark'
                    ? theme.fn.darken(colors.wbdblue[9], 0.4)
                    : colors.wbdblue[0],
                borderColor:
                  theme.colorScheme === 'dark'
                    ? theme.fn.lighten(colors.wbdblue[9], 0.15)
                    : '#B8D2FD'
              }
            }}
          /> */}
          <CascaderNetwork />
        </>
      );
    }
    return <></>;
  }

  function showSelectFeed() {
    if (configure.showFeedSelect) {
      const TID = useTID('MultiSelect', 'Feed');
      return (
        <Select
          {...TID}
          onChange={(v) => handleUpdateViewSearch(v, 'Feed')}
          value={search.Feed ? search.Feed : ''}
          data={GetDropDown('feeds')}
          label="Feed"
          placeholder="Select Any"
          size="xs"
          classNames={{
            // item: classes.item,
            input: colorScheme === 'dark' ? 'inputDark' : 'input',
            label: 'item'
          }}
          style={{
            // '.mantine-Input-input': {
              backgroundColor:
                colorScheme === 'dark'
                  ? darken(colors.wbdblue[9], 0.4)
                  : colors.wbdblue[0],
              borderColor:
                colorScheme === 'dark'
                  ? lighten(colors.wbdblue[9], 0.15)
                  : '#B8D2FD'
            // }
          }}
        />
      );
    }
    return <></>;
  }

  function showSelectContentType() {
    if (configure.showContentType) {
      const TID = useTID('MultiSelect', 'Content Type');
      return (
        <MultiSelect
          {...TID}
          onChange={(v) => handleUpdateViewSearch(v, 'ContentType')}
          value={search.ContentType ? search.ContentType : []}
          data={GetDropDown('contentTypes')}
          label="Content Type"
          inputPropName={'ContentType'}
          placeholder="Select Any"
          size="xs"
          classNames={{
            // item: classes.item,
            input: colorScheme === 'dark' ? 'inputDark' : 'input',
            label: 'item'
          }}
          style={{
            // '.mantine-Input-input': {
              backgroundColor:
                colorScheme === 'dark'
                  ? darken(colors.wbdblue[9], 0.4)
                  : colors.wbdblue[0],
              borderColor:
                colorScheme === 'dark'
                  ? lighten(colors.wbdblue[9], 0.15)
                  : '#B8D2FD'
            // }
          }}
        />
      );
    }
    return <></>;
  }

  function showIsLive() {
    if (configure.showIsLive) {
      const TID = useTID('MultiSelect', 'IsLive');
      return (
        <Select
          {...TID}
          onChange={(v) => handleUpdateViewSearch(v, 'IsLive')}
          value={search.IsLive ? search.IsLive : ''}
          data={GetDropDown('isLive')}
          label="Is Live"
          placeholder="Select Any"
          size="xs"
          classNames={{
            // item: classes.item,
            input: colorScheme === 'dark' ? 'inputDark' : 'input',
            label: 'item'
          }}
          style={{
            // '.mantine-Input-input': {
              backgroundColor:
                colorScheme === 'dark'
                  ? darken(colors.wbdblue[9], 0.4)
                  : colors.wbdblue[0],
              borderColor:
                colorScheme === 'dark'
                  ? lighten(colors.wbdblue[9], 0.15)
                  : '#B8D2FD'
            // }
          }}
        />
      );
    }
    return <></>;
  }

  return (
    <Grid columns={25}>
      <Grid.Col span={1}></Grid.Col>
      <Grid.Col span={4}>{showSelectContentType()}</Grid.Col>
      <Grid.Col span={4}>
        {showSelectNetwork()}
        {showSelectFeed()}
      </Grid.Col>
      <Grid.Col span={4}>
        {showSelectMilestone()}
        {showSelectMilestoneStatus()}
        {showCascaderStatusCode()}
      </Grid.Col>
      <Grid.Col span={4}>
        {showSuppressRepeats()}
        {showSelectScheduleType()}
      </Grid.Col>
      <Grid.Col span={4}>
        {showIsLive()}
        {showLinearExpired()}
      </Grid.Col>
    </Grid>
  );
};

import { useConfigStore } from '../app/store';
import useAxios from '../services/axios/axios';

export const fetchServerTaskHistory = async (
  data: any,
  footprintId: string,
  footprintType: string,
  type: string,
  field: string,
  statusObject: boolean,
  task?: string
) => {
  const { post } = useAxios();
  const baseURL = useConfigStore.getState().config.baseApiGatewayUrl + '/graphql/search';

  if (statusObject) {
    if (!data.status) {
      let result = { rowData: [], rowCount: 0 };
      return result;
    }
  } else {
    if (!data.milestones) {
      let result = { rowData: [], rowCount: 0 };
      return result;
    }
  }
  // TODO Check count if too many stop
  let eventPage = 1;

  let combinedRecords: any[] = [];
  let fetchingData = true;
  let totalCount;
  while (fetchingData) {
    const query = constructQuery(
      eventPage,
      data,
      footprintId,
      footprintType,
      type,
      field,
      statusObject,
      task
    );
    if (!query) return null;
    const schema = {
      query: query
    };
    const eventData = await post({
      url: baseURL,
      data: JSON.stringify(schema)
    });
    if (!eventData) return { rowData: [], rowCount: 0 };
    if (eventData.data.history.records){
      if (eventData.data.history.records.length === 0){
        fetchingData = false;
      }
    }
    totalCount = eventData.data.history.count;
    combinedRecords = combinedRecords.concat(eventData.data.history.records);
    if (combinedRecords.length >= totalCount) fetchingData = false;
    eventPage += 1;
  }

  const allRows = combinedRecords;
  const result = { rowData: allRows, rowCount: totalCount };
  return result;
};

const constructQuery = (
  eventPage: number,
  data: any,
  footprintId: string,
  footprintType: string,
  type: string,
  field: string,
  statusObject: boolean,
  task?: string
) => {
  const queryFilters = constructFilters(
    eventPage,
    data,
    footprintId,
    footprintType,
    type,
    field,
    statusObject,
    task
  );
  if (!queryFilters) return null;
  const query = `query {
            history(
                ${queryFilters}
            ) {
                records {
                    type
                    status {
                      type
                      codes {
                        code
                        detail
                      }
                    }
                    eventSource
                    eventMetaId
                    audit {
                      createdAt
                      generatedAt
                      updatedAt
                    }
                    details{
                      correlationMetadata {
                        id
                        idNamespace
                      }
                      trackingIds {
                        id
                        idNamespace
                      }
                      fulfillmentType
                      dynamicRangeIndicator
                    }
                  }
                count
            }
          }`;

  return query;
};

const constructFilters = (
  eventPage: number,
  data: any,
  footprintId: string,
  footprintType: string,
  type: string,
  field: string,
  statusObject: boolean,
  task?: string
) => {
  let taskNames
  if (statusObject) {
    const tasks = Object.keys(data.status).filter(
      (i) => i.split('|')[0] === field && i.split('|')[1] === type
    );
  
    taskNames = tasks
      .filter((i) => i.split('|').length === 3)
      .map((i) => {
        let taskName = i.split('|')[2].toLocaleLowerCase();
        if (taskName === 'metabmmvalidate' || taskName === 'metabmmjobmonitor') {
          taskName = 'metacontent' + taskName.slice(4);
        }
        return taskName;
      });
  } else {
    taskNames = data.milestones
      // .filter((milestone: { type: string; })=> milestone.type === 'Task')
      .filter(
        (milestone: { type: string; name: string }) =>
          milestone.type === 'Task' &&
          milestone.name.split('|')[1] === type &&
          milestone.name.split('|')[0] === field
      )
      .map((milestone: { name: string }) => {
        let taskName = milestone.name.split('|')[2].toLocaleLowerCase();
        if (taskName === 'metabmmvalidate' || taskName === 'metabmmjobmonitor') {
          taskName = 'metacontent' + taskName.slice(4);
        }
        return taskName;
      });
  }
  if (taskNames.length > 0) {
    if (task) {
      taskNames = taskNames.filter(
        (milestone: string) => milestone === task
      );
    }
    taskNames = `task:${JSON.stringify(taskNames)}`;
  } else {
    return null;
  }

  // TODO Add date
  const queryFilters = `filters: {
        id:"${footprintId}"
        type:${footprintType}
        ${taskNames}
        page:${eventPage}
        limit:1000
    }`;
  return queryFilters;
};

import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { iNavBarNested, initialNavBarNestedValues } from './models';

export const useNavBarNestedStore = create<iNavBarNested>()(
  devtools((set, get) => ({
    ...initialNavBarNestedValues,
    SetActiveItem: (newItem: string) => {
      set({ ActiveItem: newItem });
    },
    SetActiveLink: (newLink: string) => {
      set({ ActiveLink: newLink });
    }
  }))
);

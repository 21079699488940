export interface iNavBar {
  CloseOnSelect: boolean;
  Open: boolean;
  ToggleCloseOnSelect: () => void;
  ToggleOpen: () => void;
  SetOpen: (newState:boolean) => void;
}

export const initialNavBarValues: iNavBar = {
  CloseOnSelect: true,
  Open: false,
  ToggleCloseOnSelect: () => {},
  ToggleOpen: () => {},
  SetOpen: (newState:boolean) => {}
}

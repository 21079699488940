import { ScrollArea, Box } from '@mantine/core';
import { iLinksGroupProps, LinksGroup } from './NavbarLinksGroup';
// import { useViewportSize } from '@mantine/hooks';
// import {useTID} from '../../helpers/useTestId'
// import useStyles from './NavbarNestedStyles';
import classes from './NavbarNested.module.css';

interface INavbarNested {
  links: iLinksGroupProps[];
  testid: string;
}

export function NavbarNested(props: INavbarNested) {
  // const { classes } = useStyles();
  const links = props.links.map((item) => <LinksGroup {...item} key={item.label} />);
  // const { height } = useViewportSize();
  // const h = height - 90;
  // const TID = useTID('NavbarNested', props.testid)

  return (
    <Box className={classes.navbar}>
      <Box mt={-24} className={classes.links} component={ScrollArea}>
        <div className={classes.linksInner}>{links}</div>
      </Box>
    </Box>
  );
}

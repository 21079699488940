import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { iListSortable, initialListSortableValues } from './models';

export const useListSortableStore = create<iListSortable>()(
  devtools((set, get) => ({
    ...initialListSortableValues,
    SetList: (newList: string[]) => {
      set({ List: newList })
    },
    SetIsEditing: (newState: string) => {
      set({ IsEditing: newState })
    },
    SetDraggable: (newState: boolean) => {
      set({ Draggable: newState })
    },
    ToggleDraggable: () => {
      set({ Draggable: !get().Draggable })
    }
  }))
);

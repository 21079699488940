import {
  Box,
  Button,
  Group,
  Radio,
  Text,
  TextInput,
  Tooltip,
  useComputedColorScheme,
  useMantineTheme
} from '@mantine/core';
import { ItemInterface, ReactSortable } from 'react-sortablejs';
import { Bell, DragDrop, Edit, Star } from 'tabler-icons-react';
import { iSelectItem } from './models';
// import useStyles from './ListSortableStyles';
import classes from './ListSortable.module.css';
import { useRef, useState } from 'react';
import { SavedViewsRemove } from '../../containers/SavedViews/SavedViewsRemove';
import { useSavedViewsStore } from '../../containers/SavedViews/store';
import { useFootprintStore } from '../../containers/Footprints/store';
import { ViewsService } from '../../services/views/viewsService';
import { useListSortableStore } from './store';
import { useUserPrefsStore } from '../../app/store';
import { View } from '../../containers/Views/stores/grid/models';
import { useDeepCompareEffect } from '../../utils/utilities';
import _ from 'lodash';

export interface ItemType {
  id: number;
  label: string;
  value: string;
  group: string;
  footprint: string;
}

interface IListSortableProps {
  draggableDefault?: boolean;
  list: iSelectItem[];
  setList?: any;
  removeable?: boolean;
  editable?: boolean;
  defaultable?: boolean;
}


export const ListSortable = (props: IListSortableProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const personalViews = useSavedViewsStore((state) => state.personal);
  const SaveView = useSavedViewsStore((state) => state.SaveActiveView);
  const SaveNotification = useSavedViewsStore((state) => state.SaveNotification);
  const theme = useMantineTheme();
  const { draggableDefault, list, setList, removeable, editable, defaultable } = props;
  const checked = useSavedViewsStore((state) => state.checked);
  const GetViews = useSavedViewsStore((state) => state.GetViews);
  const edit = useListSortableStore((state) => state.IsEditing);
  const setEdit = useListSortableStore((state) => state.SetIsEditing);
  const draggable = useSavedViewsStore((state) => state.isDraggable);
  const setdraggable = useListSortableStore((state) => state.SetDraggable);
  const activeFootprint = useFootprintStore((state) => state.active.id);
  const setDefaultViews = useUserPrefsStore((state) => state.SetDefaultViews);
  const defaultVw = useUserPrefsStore().defaultViews;

  const [loadingSave, setLoadingSave] = useState(false);
  // const { classes } = useStyles(draggable);
  const colorScheme = useComputedColorScheme('light');
  if (draggableDefault) {
    setdraggable(draggableDefault);
  }

  useDeepCompareEffect( () => {
     // load StatusCodes
    (async () => {
      try {
        await ViewsService.getPersonalViews()
      } catch (err) {
        console.error('Error Loading StatusCodes: ', err);
      }
    })();
  }, [personalViews]);
  
  const getView = (id: string, views: View[]) => {
    return views.find((item) => item.id === id);
  };
  const handleDefault = (item: iSelectItem) => {
    (async () => {
      const newDefaultview = { footprint: item.footprint, default: item.id}      
      setDefaultViews(newDefaultview);
      await ViewsService.defaultView(item);
    })();
  };

  const handleNotificationActivate = (item: iSelectItem) => {
    (async () => {
      const foundView = personalViews.find((v) => v.id === item.id);
      if (foundView) {
        const notification = _.cloneDeep(foundView.notification);
        if(notification){
          notification.active = !notification.active
          await SaveNotification(notification);
        }
      }
    })();
  };

  const saveView = async (id: string, newName: string) => {
    const view = personalViews.find((v) => v.id === id);
    setLoadingSave(true);
    if (view) {
      view.label = newName;
      await SaveView(view);
      await GetViews();
    }
    setLoadingSave(false);
    setEdit('');
  };

  function ShowItems() {
    // const views = useSavedViewsStore((state) => [...state.global, ...state.personal]);
    let defaultView: any = { id: -1 };
    if(defaultVw) {
      const defaultViewList = defaultVw.filter(
        (v) => v.default && v.footprint === activeFootprint
      );
      const hasDefaultView = defaultViewList.length > 0;
      if (hasDefaultView) {
        defaultView = defaultViewList[0];
      } else {
        defaultView = { id: -1 };
      }
    }

    return list
      .filter((v: iSelectItem) => v.footprint!.includes(activeFootprint))
      .map((item: iSelectItem) => (
        <Box
          style={{
            backgroundColor:
              colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.titan[2],
            borderColor: colorScheme === 'dark' ? theme.colors.gray[7] : '',
            color: colorScheme === 'dark' ? theme.colors.gray[1] : '',
            cursor: draggable ? 'move' : 'default'
          }}
          className={classes.listSortable}
          key={item.id}
        >
          <Group justify="space-between">
            <Text size="xs">{item.label}</Text>
            {edit !== item.id && (
              <Group align="center" gap="xs">
                {draggable && <DragDrop size={14} strokeWidth={1.5} />}
                {editable && (
                  <Edit
                    size={24}
                    strokeWidth={1.5}
                    style={{ cursor: 'pointer' }}
                    onClick={() => setEdit(item.id!)}
                  />
                )}
                        <Tooltip label={getView(item.id, personalViews)?.notification ? "Enable or Disable this view's notification" : "Configure this notification in order to set it as active"}>
                    <Group>
                    <Bell
                        size={24}
                        onClick={() => handleNotificationActivate(item)}
                        strokeWidth={1.5}
                        fill={
                          getView(item.id, personalViews)?.notification?.active
                            ? theme.colors.blue[3]
                            : theme.colors.gray[3]
                        }
                        style={
                          getView(item.id, personalViews)?.notification?.active
                            ? { color: theme.colors.blue[8], cursor: 'pointer' }
                            : { color: theme.colors.gray[5], cursor: 'pointer' }
                        }
                      />
                    </Group>
                  </Tooltip>
                {defaultable && (
                  <Tooltip label="Set as the default view">
                    <Group>
                      <Star
                        size={24}
                        onClick={() => handleDefault(item)}
                        strokeWidth={1.5}
                        fill={
                          item.id === defaultView!.default
                            ? theme.colors.blue[3]
                            : theme.colors.gray[3]
                        }
                        style={
                          item.id === defaultView!.default
                            ? { color: theme.colors.blue[8], cursor: 'pointer' }
                            : { color: theme.colors.gray[5], cursor: 'pointer' }
                        }
                      />
                    </Group>
                  </Tooltip>
                )}
                {removeable && (
                  <SavedViewsRemove label={item.label} id={item.id}></SavedViewsRemove>
                )}
              </Group>
            )}
            {edit === item.id && (
              <Group>
                <TextInput disabled={loadingSave} ref={ref} defaultValue={item.label} />
                <Button
                  loading={loadingSave}
                  onClick={() => saveView(item.id!, ref!.current!.value)}
                >
                  Save
                </Button>
              </Group>
            )}
          </Group>
        </Box>
      ));
  }
  return (
    <Box>
      <Radio.Group value={checked.value}>
        <ReactSortable
          animation={250}
          chosenClass={classes.chosen}
          disabled={!draggable}
          ghostClass={classes.ghost}
          list={list as ItemInterface[]}
          setList={setList}
          swap
        >
          {ShowItems()}
        </ReactSortable>
      </Radio.Group>
    </Box>
  );
};

import { useCallback, useEffect, useMemo } from 'react';
import {
  Center,
  Group,
  Stack,
  Text,
  useComputedColorScheme,
  useMantineTheme
} from '@mantine/core';
import { iSearch } from '../Search/models';
import { SynopsisBadge } from './SynopsisBadge';
import { useFormContext } from '../SearchBar/_form-context';
import { useSearchStore } from '../Search/store';
import _ from 'lodash';
import { CascaderMarket } from '../../components/CascaderMarket/CascaderMarket';
import { CascaderNetwork } from '../../components/CascaderNetwork/CascaderNetwork';
import { CascaderStatusCode } from '../../components/CascaderStatusCode/CascaderStatusCode';
import { CascaderDestination } from '../../components/CascaderDestination/CascaderDestination';

export const SearchSynopsis = (props: { synopsisOrder: string[] }) => {
  const form = useFormContext();
  const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');
  const activeSearch = useSearchStore((state) => state.search);
  const { UpdateSearchInput } = useSearchStore();
  const {
    Milestone,
    OfferingType,
    ContentSource,
    EntityType,
    MilestoneStatus,
    ContentType,
    ContentTypeAcquire,
    Distributor
  } = activeSearch;

  const handleUpdateViewSearch = (value: any, field: string) => {
    UpdateSearchInput({ field: field, value: value! });
  };

  const removeSearchElement = useCallback(
    (label: string, subBadge?: string) => {
      switch (label as keyof iSearch) {
        case 'ContentType':
          const newsearchContentType = ContentType.filter(
            (item: string) => item !== subBadge
          );
          handleUpdateViewSearch(newsearchContentType, 'ContentType');
          break;
        case 'Milestone':
          const newMilestone = Milestone.filter((item: string) => item !== subBadge);
          handleUpdateViewSearch(newMilestone, 'Milestone');
          break;
        case 'MilestoneStatus':
          const newMilestoneStatus = MilestoneStatus.filter(
            (item: string) => item !== subBadge
          );
          handleUpdateViewSearch(newMilestoneStatus, 'MilestoneStatus');
          break;
        case 'OfferingType':
          const newOfferingType = OfferingType.filter(
            (item: string) => item !== subBadge
          );
          handleUpdateViewSearch(newOfferingType, 'OfferingType');
          break;
        case 'ContentSource':
          const newContentSource = ContentSource.filter(
            (item: string) => item !== subBadge
          );
          handleUpdateViewSearch(newContentSource, 'ContentSource');
          break;
        case 'EntityType':
          const newEntityType = EntityType.filter((item: string) => item !== subBadge);
          handleUpdateViewSearch(newEntityType, 'EntityType');
          break;
        case 'Footprint':
          form.setValues({ Footprint: '' });
          break;
        case 'Feed':
          handleUpdateViewSearch('', 'Feed');
          break;
        case 'ContentType':
          const newContentType = ContentType.filter((item: string) => item !== subBadge);
          handleUpdateViewSearch(newContentType, 'ContentType');
          break;
        case 'SuppressRepeats':
          handleUpdateViewSearch('', 'SuppressRepeats');
          break;
        case 'IsLive':
          handleUpdateViewSearch('', 'IsLive');
          break;
        case 'ScheduleType':
          handleUpdateViewSearch('', 'ScheduleType');
          break;
        case 'AdMarkers':
          handleUpdateViewSearch('', 'AdMarkers');
          break;
        case 'MidRollAdsAllowed':
          handleUpdateViewSearch('', 'MidRollAdsAllowed');
          break;
        case 'ContentTypeAcquire':
          const newContentTypeAcquire = ContentTypeAcquire.filter(
            (item: string) => item !== subBadge
          );
          handleUpdateViewSearch(newContentTypeAcquire, 'ContentTypeAcquire');
          break;
        case 'Distributor':
          const newDistributor = Distributor.filter((item: string) => item !== subBadge);
          handleUpdateViewSearch(newDistributor, 'Distributor');
      }
    },
    [form]
  );

  const computeSearch = () => {
    // Ordered here so old view state in app config dont need to be updated to change order
    return props.synopsisOrder.map((k) => {
      if (
        (Array.isArray(activeSearch[k as keyof iSearch]) &&
          /* @ts-ignore We verify this key is an array in the previous condition*/
          activeSearch[k as keyof iSearch]!.length === 0) ||
        activeSearch[k as keyof iSearch] === '' ||
        activeSearch[k as keyof iSearch] === null
      ) {
        return null;
      } else if (k === 'Market') {
        return <CascaderMarket key="cascadermarketsynopsis" noOpen={true} />;
      } else if (k === 'Network') {
        return <CascaderNetwork key="cascadernetworksynopsis" noOpen={true} />;
      } else if (k === 'StatusCodes') {
        return <CascaderStatusCode key="cascaderStatusCodessynopsis" noOpen={true} />;
      } else if (k === 'Destinations') {
        return <CascaderDestination key="cascaderdestinationsynopsis" noOpen={true} />;
      }
      return (
        <SynopsisBadge
          key={k}
          removeSearchElement={removeSearchElement}
          badgeElement={k}
        />
      );
    });
  };

  const memoSearch = useMemo(() => computeSearch(), [activeSearch]);

  useEffect(() => {
    return () => {
      computeSearch();
    };
  }, [memoSearch]);

  if (_.isEmpty(_.compact(memoSearch))) {
    return <></>;
  }

  return (
    <Center>
      <Stack align="left" style={{ flex: 1 }}>
        <Group>
          <Text
            size="xs"
            c={colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[8]}
          >
            <Group>{memoSearch}</Group>
          </Text>
        </Group>
      </Stack>
    </Center>
  );
};

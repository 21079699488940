import { useConfigStore } from '../../app/store';
import useAxios from '../../services/axios/axios';
import { iSearch } from '../../containers/Search/models';
import { useSearchStore } from '../../containers/Search/store';
import { getStatusCodeString } from '../../utils/utilities';
import { useAppConfigManager } from '../../components/AppConfigs';

export const fetchAcquireRowData = async (
  startDate: Date | null,
  endDate: Date | null,
  searchState: iSearch,
  limit: number,
  page: number
) => {
  const { post } = useAxios();
  const baseURL = useConfigStore.getState().config.baseApiGatewayUrl + '/graphql/search';

  const query = await constructQuery(startDate, endDate, searchState, limit, page);
  const schema = {
    query: query
  };

  const eventData = await post({
    url: baseURL,
    data: JSON.stringify(schema)
  });
  if (!eventData) {
    return { rowData: [], rowCount: 0 };
  } else {
    let totalCount = eventData?.data?.acquire?.count;
    return { rowData: eventData?.data?.acquire?.records, rowCount: totalCount };
  }
};

const constructQuery = async (
  startDate: Date | null,
  endDate: Date | null,
  searchState: iSearch,
  limit: number,
  page: number
) => {
  const queryFilters = constructFilters(startDate, endDate, searchState, limit, page);
  let queryReturn = await useSearchStore
    .getState()
    .queries.find((q) => q.id.includes('footprint-acquire'));
  if (!queryReturn) {
    const LoadAPI = useSearchStore.getState().LoadAPI;
    await LoadAPI();
    queryReturn = await useSearchStore
      .getState()
      .queries.find((q) => q.id.includes('footprint-acquire'));
  }
  const query = `query {
            acquire(
              ${queryFilters}
            ) {
              ${queryReturn?.query}
              count
            }
          }`;
  return query;
};

const constructFilters = (
  startDate: Date | null,
  endDate: Date | null,
  filters: iSearch,
  limit: number,
  page: number
) => {
  const milestoneStatuses =
    filters.MilestoneStatus?.length > 0
      ? `[${filters.MilestoneStatus?.map((status: any) => `"${status}"`)}]`
      : '[]';
  const milestones =
    filters.Milestone?.length > 0
      ? `[${filters.Milestone?.map((status: any) => `"${status}"`)}]`
      : '[]';
  const milestoneString = `milestones:{
      name: ${milestones},
      status: ${milestoneStatuses}
    }`;

  let partialSearchInput: string = '';
  if (filters.SearchInput) {
    let searchInputs;
    if (filters.SearchInputField === 'keyword') {
      let copy = filters.SearchInput;
      let newInput = copy.replaceAll('"', '\\"');
      searchInputs = newInput;
    } else {
      searchInputs = filters.SearchInput.split(' ');
      if (searchInputs.length > 1) {
        searchInputs = searchInputs.join('","');
      }
    }
    partialSearchInput = searchInputs.toString();
  }

  // let csId = '';
  // if (filters.SearchInputField && filters.SearchInputField === 'csId') {
  //   let input = '';
  //   let searchInputs = filters.SearchInput.split(' ');
  //   if (searchInputs.length > 1) {
  //     input = searchInputs.join('","');
  //   } else {
  //     input = searchInputs[0];
  //   }
  //   csId = `
  //   csId: {
  //     id: ["${input}"]
  //   }
  //   `;
  // }

  let idPagination;
  idPagination = `page: ${page}`;

  const acquireObject = constructAcquireObject(startDate, endDate, filters);
  let partialObject = '';
  if (partialSearchInput && filters.SearchInputField === 'keyword') {
    partialObject = constructPartialAcquireObject(partialSearchInput);
  }

  let auditObject = `audit:{}`;
  if (filters.DateField === 'lastUpdated' && filters.dateEnabled) {
    auditObject = `audit:{
            updatedAtFrom:"${startDate!.toISOString()}",
            updatedAtTo:"${endDate!.toISOString()}"
          }`;
  }

  let id = '';
  if (filters.SearchInputField && filters.SearchInputField !== 'keyword') {
    let input = '';
    let searchInputs = filters.SearchInput.split(' ');
    if (searchInputs.length > 1) {
      input = searchInputs.join('","');
    } else {
      input = searchInputs[0];
    }

    if (filters.SearchInputField === 'acquireId') {
      id = `id: ["${input}"]`;
    }
  }
  const taskValueMapper = useAppConfigManager.getState().taskValueMapper;
  const statusCodeString = getStatusCodeString(filters.StatusCodes, taskValueMapper);
  const queryFilters = `filters: {
        ${auditObject}
        ${id}
        ${milestoneString}
        ${statusCodeString}
        ${partialObject}
        ${acquireObject}
        limit: ${limit}
        ${idPagination}
    }`;
  return queryFilters;
};

export const constructAcquireObject = (
  startDate: Date | null,
  endDate: Date | null,
  filters: iSearch
) => {
  let acquireObject: any = {};

  if (filters.DateField === 'createdAt' && filters.dateEnabled) {
    acquireObject.createdAt = `audit:{
            createdFrom:"${startDate!.toISOString()}",
            createdTo:"${endDate!.toISOString()}",
          }`;
  }

  const excludedIds = [
    'keyword',
    'acquireId',
    'csId',
  ] 
  
  const ids = useAppConfigManager
    ?.getState()
    ?.GetSearchInputs('footprint-acquire')
    ?.map((id) => id.value)
    .filter(i=>!excludedIds.includes(i));

  if (filters.SearchInputField && filters.SearchInputField !== 'keyword') {
    let input = '';
    let searchInputs = filters.SearchInput.split(' ');
    if (searchInputs.length > 1) {
      input = searchInputs.join('","');
    } else {
      input = searchInputs[0];
    }

    if (ids.includes(filters.SearchInputField)) {
      acquireObject[
        filters.SearchInputField
      ] = `${filters.SearchInputField}:{id: ["${input}"]}`;
    }
    // Uncomment if id moved to asset level
    // else if (filters.SearchInputField === 'acquireId') {
    // acquireObject.id = `id: ["${input}"]`;
    // }
  }

  if (filters.ContentTypeAcquire) {
    if (filters.ContentTypeAcquire.length > 0) {
      const filterString = `contentType:[${filters.ContentTypeAcquire.map(
        (i: any) => `"${i}"`
      )}]`;
      acquireObject.type = filterString;
    }
  }

  if (Object.keys(acquireObject).length > 0) {
    let acquireString = '';
    Object.keys(acquireObject).forEach((k: string) => {
      acquireString = acquireString + '\n' + acquireObject[k];
    });
    return `asset: {
      ${acquireString}
    }`;
  }
  return '';
};

export const constructPartialAcquireObject = (searchInput: string) => {
  const partialTitles = ['titles', 'seriesTitles', 'seasonTitles'];

  const excludedIds = [
    'keyword',
    'acquireId',
    'csId',
  ] 

  const ids = useAppConfigManager
    ?.getState()
    ?.GetSearchInputs('footprint-acquire')
    ?.map((id) => id.value)
    .filter(i=>!excludedIds.includes(i));

  let partialObject: any = {};

  partialTitles.map((t: string) => {
    const partialTitle = `
      ${t}:{
        full:["${searchInput}"]
        type:["main-title", "internal-wm-name", "distribution-title"],
        language:"en-US"
      }`;
    partialObject[t as keyof typeof partialObject] = partialTitle;
  });

  partialObject.title = `title:["${searchInput}"]`;

  if (searchInput.length > 4 || !searchInput.split('').includes(' ')) {
    ids.map((i: string) => {
      partialObject[i as keyof typeof partialObject] = `\n${i}:{id: ["${searchInput}"]}`;
    });
  }

  // acquisitionId at root level
  let acquisitionId = '';
  if (searchInput.length > 4 || !searchInput.split('').includes(' ')) {
    acquisitionId = `id: ["${searchInput}"]`;
  }

  if (Object.keys(partialObject).length > 0) {
    let offeringString = '';
    Object.keys(partialObject).forEach((k: string) => {
      offeringString = offeringString + partialObject[k];
    });
    return `
      partialSearch:{
        asset:{
          ${offeringString}
        }
        ${acquisitionId}
      }
    `;
  }
  return '';
};

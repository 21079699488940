import {
  Box,
  CloseButton,
  Grid,
  Group,
  Stack,
  Text,
  useMantineTheme,
  Tooltip,
  Anchor,
  CopyButton,
} from '@mantine/core';
import { Button } from '../../../../components/Button';
import { AssetDetail, findTitle } from './assetDetail';
import { Details as DetailsOverlay } from '../../../Details/Details';
import { ErrorBoundary } from 'react-error-boundary';
import { ExternalLink, Link, Recycle } from 'tabler-icons-react';
import { useEffect, useState } from 'react';
import useAxios from '../../../../services/axios/axios';
import { hasOKTAGroup } from '../../../../utils/oktaGroups';
import { useConfigStore } from '../../../../app/store';
import { useDetailsStore } from '../../store';
import { useElementSize } from '@mantine/hooks';
import ViewsDetail from '../ViewsDetail';

const ErrorFallback = (props: any) => {
  return (
    <div role="alert">
      <p>Error on DetailsModal:</p>
      <pre>{props.error.TypeError}</pre>
    </div>
  );
};

const myErrorHandler = (error: Error, info: { componentStack: string }) => {
  console.log('error', error, 'info', info);
  // Do something with the error
  // E.g. log to an error logging client here
};

/* The DetailsModal component holds detail-related components for a view's
individual row items */
export const DetailsModalAsset= () => {
  const details = useDetailsStore((state) => state.asset)
  const theme = useMantineTheme();

  const [refreshLoading, setRefreshLoading] = useState(false);
  const config = useConfigStore((state) => state.config);
  const { get } = useAxios();

  const tabFields = ['Metadata', 'Image', 'Video']
  const { activeTab, SetActiveTab, SetDetailsVisible } = useDetailsStore();

  useEffect(() => {
    if (!tabFields.includes(activeTab as string)) SetActiveTab('Timeline');
  }, []);

  let contentNames = '';
  const contentClass = details.type
  const entityType = details.entityType
  if (contentClass && entityType) {
    contentNames = `${
      contentClass.split('_').slice(-1)[0].charAt(0).toUpperCase() +
      contentClass.split('_').slice(-1)[0].slice(1)
    }/${
      entityType?.charAt(0).toUpperCase() +
      entityType?.slice(1)
    }`;
  } else if (contentClass && !entityType) {
    contentNames =
      contentClass.split('_').slice(-1)[0].charAt(0).toUpperCase() +
      contentClass.split('_').slice(-1)[0].slice(1);
  } else if (!contentClass && entityType) {
    contentNames =
      entityType?.charAt(0).toUpperCase() +
      entityType?.slice(1);
  }

  const { ref, width } = useElementSize();

  const refresh = () => {
    setRefreshLoading(true);
    const baseUrl =
      config.baseApiGatewayUrl +
      '/rest/refresh/v1?id=' +
      details.id +
      '&publish=true&type=offeringsAsset';
    get({ url: baseUrl });
    setTimeout(() => {
      setRefreshLoading(false);
    }, 2000);
  };

  const isAdmin = hasOKTAGroup(config.environment, config.oktaAdminGroup);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={myErrorHandler}>
      <DetailsOverlay top={40} left={40} bottom={80} right={40}>
        <Grid columns={24}>
          <Grid.Col span={7} style={{ overflowY: 'auto' }}>
            <Group>
              <Stack style={{ marginTop: 12, width: '100%' }}>
                <Group ref={ref} justify="space-between" style={{ width: '100%' }}>
                  <Text
                    size="xl"
                    style={{
                      marginLeft: 24,
                      marginBottom: -32,
                      marginTop: -16
                    }}
                  >
                    {details?.titles?.length > 0 ? (
                      findTitle(details.titles, true, width)
                    ) : (
                      <>--No Title--</>
                    )}
                  </Text>
                  {isAdmin && (
                    <Button
                      testid='refresh-details-asset'
                      size="xs"
                      onClick={refresh}
                      loading={refreshLoading}
                      justify="center"
                      variant="subtle"
                      style={{
                        marginLeft: 24,
                        marginBottom: -32,
                        marginTop: -16,
                        width: 40
                      }}
                      leftSection={
                        !refreshLoading ? (
                          <Recycle style={{ marginRight: -12, color: theme.colors.blue[6] }} />
                        ) : (
                          <></>
                        )
                      }
                      tooltip={{
                        label:'Refresh',
                        position: 'top',
                      }}
                    />
                  )}
                </Group>
                <Box
                  style={{
                    borderBottom: `1px solid ${theme.colors.gray[8]}`,
                    display: 'flex',
                    fontSize: '1.3em',
                    height: '35px',
                    justifyContent: 'start',
                    marginLeft: 20,
                    padding: '0 5px'
                  }}
                >
                  <Group justify="flex-start" style={{ width: '100%' }}>
                    <Text
                      style={{
                        marginTop: 10
                      }}
                      size="sm"
                    >
                      {contentNames}
                    </Text>
                  </Group>
                </Box>
              </Stack>
            </Group>
            <AssetDetail details={details}/>
          </Grid.Col>
          <Grid.Col span={17} >
            <Tooltip zIndex={9999} label={'Open In New Tab'} position="bottom">
              <Anchor 
                target="_blank"
                href={`${window.location.origin}/offeringAssets/${details?.offeringId}`}
                style={{ color: theme.colors.blue[6] ,position: 'relative',top: '7px',left: '92%'}}
              >
                <ExternalLink 
                  size={20} style={{marginLeft: -20}}
                />

              </Anchor>
            </Tooltip>
            <CopyButton 
              value={`${window.location.origin}/views/offeringAssets/keyword/${details?.offeringId}?detailOpen=true`}
            >
              {({ copied, copy }) => (
                  <Button
                    testid='copy-link'
                    variant="subtle"
                    leftSection={<Link size={20} />}
                    style={{ position: 'absolute', top: 19, right: 39, background: 'transparent' }}
                    color={copied ? 'teal' : theme.colors.blue[6]}
                    onClick={copy}
                    size='xs'
                    tooltip={{
                      label:'Copy Link',
                      position: 'left'
                    }}
                  ></Button>
              )}
            </CopyButton>
            <CloseButton
              iconSize={20}
              onClick={() => SetDetailsVisible(false)}
              size="md"
              style={{ position: 'absolute', top: 21, right: 19, }}
              title="Close Details"
            />
            <Stack style={{ height: '100%' }}>
              {details && <ViewsDetail statusObject={true} data={details} parents={tabFields} children={['Prep', 'Fulfill', 'Delivery', 'Receipt', 'Staging']}/>}
            </Stack>
          </Grid.Col>
        </Grid>
      </DetailsOverlay>
    </ErrorBoundary>
  );
};

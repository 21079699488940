import {
  Box,
  Popover,
  Stack,
  Text,
  Tooltip,
  useComputedColorScheme,
  lighten,
  useMantineTheme
} from '@mantine/core';
import { Milestone } from '../../components/Milestone';
import {
  Braces,
  CalendarEvent,
  Camera,
  ChevronDown,
  ChevronRight,
  FileOff,
  ListCheck,
  Package,
  Receipt,
  TruckDelivery,
  Video
} from 'tabler-icons-react';
import { StatusIcon } from '../../components';
import { NullTitleTooltip } from '../../components/NullTitleTooltip';
import { iMilestone } from '../../components/Milestone/models';
import { ICellRendererParams } from 'ag-grid-community';
import { useRef, useState } from 'react';
import { CommentsButton } from '../Comments';
import { getCodeDetails } from '../../utils/getStatus';
import { ValueTooltip } from '../../components/ValueTooltip';
import dayjs from 'dayjs';
import { getMilestoneByNameAndType } from './_columnDefs/offering/offeringValueGetters';

const Capitalize = (props: any) => {
  if (props.value) {
    return props.value.charAt(0).toUpperCase() + props.value.slice(1);
  }
  return null;
};

const AllCaps = (props: any) => {
  if (props.value) {
    return props.value?.toUpperCase();
  }
  return null;
};

export const HeaderTitleText = (props: any) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {props.colDef.headerName}
    </div>
  );
};

const TruncateUUID = (props: any) => {
  if (props.column.actualWidth >= 400) return props.value;
  const uuid = props.value;
  const delimeter = Math.floor(props.column.actualWidth / 20);
  if (
    uuid === undefined ||
    uuid === '' ||
    uuid === null ||
    uuid.length < Math.floor(props.column.actualWidth / 20) * 2
  )
    return props.value;
  return (
    uuid.substring(0, delimeter) +
    ' ... ' +
    uuid.substring(uuid.length - delimeter, uuid.length)
  );
};

export const hasCommentsGetter = (props: any) => {
  const { path } = props;
  const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');
  const ref = useRef<any>();

  // necessary because when path passes as 'schedule.editId.id' cant simply index props.data by that string
  // schedule.editId = null
  let data: any = {};
  let id = '';
  const idPath = path.split('.');
  if (idPath.length > 1) {
    data = props.data[idPath[0]];
    for (let i = 1; i < idPath.length; i++) {
      if (data) {
        if (i === idPath.length - 1) {
          id = data[idPath[i]];
        } else {
          data = data[idPath[i]];
        }
      }
    }
  } else {
    id = props.data[path as keyof typeof props.data];
  }
  if (!id) {
    return (
      <Popover
        position="bottom"
        withArrow
        shadow="md"
        withinPortal
        classNames={{
          arrow: 'popoverArrow',
          dropdown: 'popoverDropdownBelow'
        }}
      >
        <Popover.Target>
          <Box ref={ref} style={{ display: 'flex', justifyContent: 'center' }}>
            <FileOff
              size={22}
              strokeWidth={2}
              color={
                colorScheme === 'dark'
                  ? lighten(theme.colors.gray[9], 0.6)
                  : lighten(theme.colors.gray[6], 0.8)
              }
              aria-label="fileOff"
            />
          </Box>
        </Popover.Target>
        <Popover.Dropdown>
          <Stack>
            <Text size="xs">There is no EditID associated with this segment.</Text>
            <Text size="xs"> Please correct this in order to use comments.</Text>
          </Stack>
        </Popover.Dropdown>
      </Popover>
    );
  }
  return CommentsButton(props.value, id, props.node.id);
};

export const MilestoneCellRenderer = (props: any) => {
  const { data, field, activity } = props;
  if (!data) {
    return <></>;
  }
  if (!data.milestones) {
    data.milestones = [];
  }
  const { name } = getMilestoneByNameAndType(props?.colDef);
  const status: iMilestone = data.milestones!.find(
    (element: { name: string }) => element.name === name
  );
  let badge = '';
  if (status?.codes !== undefined) {
    badge = getCodeDetails(status.codes).code;
  }
  if (status?.status) {
    let statusName: string = status.status;
    if (status.codes && status.codes.length > 0) {
      statusName = status.codes[0].code;
    }
    return (
      <Milestone
        key={'single' + name + data.offering?.id}
        column={`${props.colDef.headerName}`}
        status={statusName}
        codes={status.codes}
        data={props.data}
        type={activity}
        field={field}
        colDef={props.colDef}
        badge={badge}
      />
    );
  } else {
    return <StatusIcon key={name + data.offering?.id} status={'null'} />;
  }
};

export const MultiIconHeader = (props: any) => {
  const { displayName } = props;
  // const headerName = String(props.colDef.headerName).split(' ').join('');
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>{displayName}</div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Camera />
        <Braces />
        <Video />
      </div>
    </>
  );
};

export const MultiIconHeaderPFDR = (props: any) => {
  const { displayName, activityCategories = ['Offering', 'Metadata', 'Image', 'Video'] } =
    props;
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>{displayName}</div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {activityCategories.includes('Offering') && (
          <Tooltip offset={-12} label="Offering">
            <div>
              <CalendarEvent />
            </div>
          </Tooltip>
        )}
        {activityCategories.includes('Metadata') && (
          <Tooltip offset={-12} label="Meta">
            <div>
              <Braces />
            </div>
          </Tooltip>
        )}
        {activityCategories.includes('Image') && (
          <Tooltip offset={-12} label="Image">
            <div>
              <Camera />
            </div>
          </Tooltip>
        )}
        {activityCategories.includes('Video') && (
          <Tooltip offset={-12} label="Video">
            <div>
              <Video />
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

const MultiMilestoneCellRenderer = (props: any, path: string) => {
  const { data } = props;
  if (!data) {
    return <></>;
  }
  // const offeringStatus = data.milestones.delivery.submilestone.images.status || 'inactive';
  const imagesStatus = data.milestones.delivery.submilestone.images.status || 'inactive';
  const metaStatus = data.milestones.delivery.submilestone.meta.status || 'inactive';
  const videoStatus = data.milestones.delivery.submilestone.video.status || 'inactive';
  const headerName = String(props.colDef.headerName).split(' ').join('');
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Milestone
        column={headerName}
        status={imagesStatus}
        codes={data.milestones.delivery.submilestone.images.codes}
        data={props.data}
        colDef={props.colDef}
        type="image"
      />
      <Milestone
        column={headerName}
        status={metaStatus}
        codes={data.milestones.delivery.submilestone.meta.codes}
        data={props.data}
        colDef={props.colDef}
        type="meta"
      />
      <Milestone
        column={headerName}
        status={videoStatus}
        codes={data.milestones.delivery.submilestone.video.codes}
        data={props.data}
        colDef={props.colDef}
        type="video"
      />
    </div>
  );
};

const flexed = { display: 'flex', justifyContent: 'center' };

const MultiMilestoneCellRendererPFDR = (props: any) => {
  const {
    data,
    field,
    activityCategories = ['Offering', 'Metadata', 'Image', 'Video']
  } = props;
  // TODO load db footprint sometime before grid, views.tsx most likely
  // look at db footprint
  // if field ! in footprint, return <></>
  if (!data) {
    return <></>;
  }
  if (!data.milestones) {
    data.milestones = [];
  }
  return (
    <div style={Object.assign({}, flexed, { padding: 0 })}>
      {activityCategories.map((activity: any) => {
        const status = data.milestones!.find(
          (element: { name: string }) => element.name === `${field}|${activity}`
        );
        let badge = '';
        if (status?.codes !== undefined) {
          badge = getCodeDetails(status.codes).code;
        }
        if (status?.status) {
          return (
            <Milestone
              key={field + activity + data.offering?.id}
              column={`${props.colDef.headerName}`}
              status={status.status}
              codes={status.codes}
              data={props.data}
              type={activity}
              field={field}
              colDef={props.colDef}
              badge={badge}
            />
          );
        } else {
          return (
            <StatusIcon key={field + activity + data.offering?.id} status={'null'} />
          );
        }
      })}
    </div>
  );
};

const SeriesNameRenderer = (props: any) => {
  if (!props.data) {
    return <></>;
  }
  let name = props.data.program.seriesName;
  if (!name) {
    return <>NA</>;
  }
  return <>{name}</>;
};

const TerritoryRenderer = (props: any) => {
  if (!props.data) {
    return <></>;
  }
  let name = props.data.offering.territory;
  if (!name) {
    return <>NA</>;
  }
  return <>{name}</>;
};

const OfferingTypeFormatter = (props: any) => {
  const type: string = props.data.offering.type;
  if (!type) {
    return <>NA</>;
  }
  let formattedType = type.split('_').slice(-1);
  const capitalType =
    formattedType[0].charAt(0).toUpperCase() + formattedType[0].slice(1);
  return <>{capitalType}</>;
};

const OfferingDateFormatter = (props: any) => {
  const date = props.value;
  let formattedDate = dayjs(date).format('MMM DD, YYYY (hh:mm a | [EST])');

  if (!formattedDate) {
    return <>NA</>;
  }
  return <>{formattedDate}</>;
};

const FootprintStatus = (props: any) => {
  const { data, footprint } = props;
  if (!data) {
    return <></>;
  }
  const footprintStatus = data.milestones?.find(
    (element: { name: string }) => element.name === footprint
  );
  let status = footprintStatus ? footprintStatus : null;
  if (status?.status) {
    return (
      <Milestone
        key={'OverallStatus-' + data.offering?.id}
        column={`${props.colDef.headerName}`}
        status={status.status}
        codes={status.codes}
        data={props.data}
        type={''}
        colDef={props.colDef}
        field={'Overall Status'}
      />
    );
  } else {
    return <StatusIcon key={'OverallStatus-' + data.offering?.id} status={'null'} />;
  }
};

const FootprintStatusObject = (props: any) => {
  const { data, footprint } = props;
  if (!data || !data.status) {
    return <></>;
  }
  const footprintStatus = data.status[footprint];
  let status = footprintStatus ? footprintStatus : null;
  if (status?.status) {
    return (
      <Milestone
        key={'OverallStatus-' + data.offering?.id}
        column={`${props.colDef.headerName}`}
        status={status.status}
        codes={status.codes}
        data={props.data}
        colDef={props.colDef}
        type={''}
        field={'Overall Status'}
      />
    );
  } else {
    return <StatusIcon key={'OverallStatus-' + data.offering?.id} status={'null'} />;
  }
};

const RelatedAcquisitionStatus = (props: any) => {
  const { data } = props;
  if (!data) {
    return <></>;
  }
  const footprintStatus = data?.related?.acquisition;
  let status;
  if (footprintStatus) {
    if (footprintStatus.length > 0) {
      status = footprintStatus[0].status?.['acquire-footprint'];
    }
  }
  if (status?.status) {
    return (
      <Milestone
        key={'AcquisitionOverallStatus-' + data.id}
        column={`${props.colDef.headerName}`}
        status={status.status}
        codes={status.codes}
        data={props.data}
        colDef={props.colDef}
        type={''}
        field={''}
      />
    );
  } else {
    return <StatusIcon key={'OverallStatus-' + data.offering?.id} status={'null'} />;
  }
};

const TitleRenderer = (props: any) => {
  if (!props.value) {
    return <></>;
  }
  if (props.value === '--No Title--') {
    return <NullTitleTooltip />;
 } else {
      return (
        <div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
          <ValueTooltip value={props.value}/>
        </div>
      );
  }
};

const ExpandableRowRenderer = (props: ICellRendererParams) => {
  const [opened, setOpened] = useState(props.node.expanded);
  // const SetActiveRowIndex = useGridStore((state) => state.SetActiveRowIndex);

  const onClick = () => {
    if (!props.node.expanded) {
      // SetActiveRowIndex(Number(props.node.id));
      props.node.setExpanded(!props.node.expanded);
      setOpened(true);
    } else {
      props.node.setExpanded(!props.node.expanded);
      setOpened(false);
    }
  };
  return (
    <>
      {(Number(props.value) > 0 || (props?.data?.id)) && (
        <>
          {!opened ? (
            <ChevronRight style={{ cursor: 'pointer' }} size={16} onClick={onClick} />
          ) : (
            <ChevronDown style={{ cursor: 'pointer' }} size={16} onClick={onClick} />
          )}
        </>
      )}
      {props.value}
    </>
  );
};

const PlaylistRowRenderer = (props: ICellRendererParams) => {
  const [opened, setOpened] = useState(props.node.expanded);
  // const SetActiveRowIndex = useGridStore((state) => state.SetActiveRowIndex);

  const onClick = () => {
    if (!props.node.expanded) {
      // SetActiveRowIndex(Number(props.node.id));
      props.node.setExpanded(!props.node.expanded);
      setOpened(true);
    } else {
      props.node.setExpanded(!props.node.expanded);
      setOpened(false);
    }
  };
  return (
    <>
      { Number(props.value) > 0 && (
        <>
          {!opened ? (
            <ChevronRight style={{ cursor: 'pointer' }} size={16} onClick={onClick} />
          ) : (
            <ChevronDown style={{ cursor: 'pointer' }} size={16} onClick={onClick} />
          )}
        </>
      )}
      {props.value}
    </>
  );
};

const ValueTooltipRenderer = (props: any) => {
  if (!props.value) {
    return <></>;
  }
  return (
    <div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
      <ValueTooltip value={props.value}/>
    </div>);
};

const MultiMilestoneCellRendererObjectStatus = (props: any) => {
  const {
    data,
    parent,
    children = ['Prep', 'Fulfill', 'Delivery', 'Receipt', 'Staging']
  } = props;

  if (!data) {
    return <></>;
  }
  if (!data.status) {
    data.status = [];
  }

  return (
    <div style={Object.assign({}, flexed, { padding: 0 })}>
      {children.map((child: any) => {
        const status = data.status[`${parent}|${child}`];
        // let badge = '';
        // if (status?.codes !== undefined) {
        //   badge = getCodeDetails(status.codes).code;
        // }
        if (status?.status) {
          return (
            <Milestone
              key={parent + child + data.id}
              column={`${props.colDef.headerName}`}
              status={status.status}
              codes={status.codes}
              data={props.data}
              field={parent}
              colDef={props.colDef}
              type={child}
              // badge={badge}
            />
          );
        } else {
          return <StatusIcon key={parent + child + data.id} status={'null'} />;
        }
      })}
    </div>
  );
};

export const MilestoneCellRendererObjectStatus = (props: any) => {
  const { data, child, parent } = props;
  if (!data) {
    return <></>;
  }
  if (!data.status) {
    data.status = {};
  }
  const status = child ? data.status[`${parent}|${child}`] : data.status[parent];

  // let badge = '';
  // if (status?.codes !== undefined) {
  //   badge = getCodeDetails(status.codes).code;
  // }
  if (status?.status) {
    let statusName: string = status.status;
    if (status.codes && status.codes.length > 0) {
      statusName = status.codes[0].code;
    }
    return (
      <Milestone
        key={'single' + `${parent}|${child}` + data.offering?.id}
        column={`${props.colDef.headerName}`}
        status={statusName}
        codes={status.codes}
        data={props.data}
        type={child}
        colDef={props.colDef}
        field={parent}
        // badge={badge}
      />
    );
  } else {
    return <StatusIcon key={`${parent}|${child}` + data.offering?.id} status={'null'} />;
  }
};

export const MultiIconHeaderObjectStatus = (props: any) => {
  const {
    displayName,
    processCategories = ['Prep', 'Fulfill', 'Delivery', 'Receipt', 'Staging']
  } = props;
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>{displayName}</div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {processCategories.includes('Prep') && (
          <Tooltip offset={-12} label="Prep">
            <div>
              <ListCheck />
            </div>
          </Tooltip>
        )}
        {processCategories.includes('Fulfill') && (
          <Tooltip offset={-12} label="Fulfill">
            <div>
              <Package />
            </div>
          </Tooltip>
        )}
        {processCategories.includes('Delivery') && (
          <Tooltip offset={-12} label="Delivery">
            <div>
              <TruckDelivery />
            </div>
          </Tooltip>
        )}
        {processCategories.includes('Receipt') && (
          <Tooltip offset={-12} label="Receipt">
            <div>
              <Receipt />
            </div>
          </Tooltip>
        )}
        {processCategories.includes('Staging') && (
          <Tooltip offset={-12} label="Staging">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M4 15h2a2 2 0 0 1 2 2v2h1v-2a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2h1v-2a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2h1v3H1v-3h1v-2a2 2 0 0 1 2-2m7-8l4 3l-4 3zM4 2h16a2 2 0 0 1 2 2v9.54a3.89 3.89 0 0 0-2-.54V4H4v9c-.73 0-1.41.19-2 .54V4a2 2 0 0 1 2-2"
                ></path>
              </svg>
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export const components = {
  headerTitleText: HeaderTitleText,
  truncateUUID: TruncateUUID,
  milestoneCellRenderer: MilestoneCellRenderer,
  multiIconHeader: MultiIconHeader,
  multiIconHeaderPFDR: MultiIconHeaderPFDR,
  seriesNameRenderer: SeriesNameRenderer,
  territoryRenderer: TerritoryRenderer,
  multiMilestoneCellRenderer: MultiMilestoneCellRenderer,
  multiMilestoneCellRendererPFDR: MultiMilestoneCellRendererPFDR,
  offeringTypeFormatter: OfferingTypeFormatter,
  offeringDateFormatter: OfferingDateFormatter,
  footprintStatus: FootprintStatus,
  titleRenderer: TitleRenderer,
  playlistEntriesRenderer: PlaylistRowRenderer,
  componentsRenderer: ExpandableRowRenderer,
  hasCommentsGetter: hasCommentsGetter,
  capitalize: Capitalize,
  relatedAcquisitionStatus: RelatedAcquisitionStatus,
  allCaps: AllCaps,
  valueTooltipRenderer: ValueTooltipRenderer,
  multiMilestoneCellRendererObjectStatus: MultiMilestoneCellRendererObjectStatus,
  milestoneCellRendererObjectStatus: MilestoneCellRendererObjectStatus,
  multiIconHeaderObjectStatus: MultiIconHeaderObjectStatus,
  footprintStatusObject: FootprintStatusObject
};
import { Select, MantineStyleProp, useComputedColorScheme } from '@mantine/core';
import { useFootprintStore } from './store';
import { iFootprint } from './models';
import classes from './FootprintSelect.module.css';
type FootprintSelectProps = {
  changeHandler?: Function;
  value: string;
  style?: MantineStyleProp;
  label?: string | undefined;
  disabled?: boolean;
};
export const FootprintSelect = (props: FootprintSelectProps) => {
  const { changeHandler, value, style, label, disabled } = props;
  const colorScheme = useComputedColorScheme('light');
  const footprints = useFootprintStore((state) => state.footprints);

  function getFootprintsList(footprint: iFootprint) {
    return footprint.label;
  }

  return (
    <Select
      styles={{
        input: {
          backgroundColor: colorScheme === 'dark' ? '#25262b': 'var(--mantine-color-wbdblue-0)', 
          borderColor: colorScheme === 'dark' ?'#68686E':'#b8d1ff'
        }
      }}
      classNames={{
        input: colorScheme === 'dark' ? 'inputDark' : classes.input,
        wrapper: classes.wrapper,
      }}
      onChange={(v: string | null) => {
        const fp = footprints.find((f) => f.label === v);
        if (fp && changeHandler) {
          changeHandler(fp.id);
        } else {
          console.error("Couldn't find selected footprint.");
        }
      }}
      disabled={disabled}
      label={label}
      data={footprints.map(getFootprintsList)}
      value={value}
      placeholder="Select Footprint"
      size="xs"
      style={style}
      allowDeselect= {false}
    />
  );
};

import { create } from 'zustand';
import { HUDService } from '../../../services/hud/hudService';
import {
  defaultLayout,
  iHUDFilterView,
  iHUDState,
  iPersonalHUD,
  initialHUDState
} from './models';
import { makePersonalHUDLayout } from './helpers';
import { v4 as uuidv4 } from 'uuid';

export const useHUDStore = create<iHUDState>()((set, get) => ({
  ...initialHUDState,
  SetBreakpoint: (value: string) => {
    set({ breakpoint: value });
  },
  GetPersonalHUD: () => {
    const personalHUD = HUDService.getPersonalHUD();
    personalHUD.then(async (pHUD) => {
      let newHud: iPersonalHUD = {
        id: '',
        type: '',
        widgets: [],
        layout: defaultLayout
      };
      if (pHUD.length > 0) {
        newHud = pHUD[0];
      }
      if (window.location.href.split('/').length > 4) {
        const widgetId = window.location.href.split('/')?.[4];
        const widget = await HUDService.getWidgetByID(widgetId);
        if (widget?.[0]) {
          let newWidget = widget[0];
          newWidget.i = uuidv4();
          newHud.widgets.push(newWidget);
          get().SavePersonalHUD(newHud);
        }
      }
      get().SetPersonalHUD(newHud);
    });
  },
  SetPersonalHUD: async (personalHUD: iPersonalHUD) => {
    const gridComponents = await makePersonalHUDLayout(personalHUD);
    set({ gridComponents: gridComponents });
    set({ personalHUD: personalHUD });
  },
  SavePersonalHUD: (personalHUD: iPersonalHUD) => {
    let result = HUDService.upsertHUD(personalHUD.id, personalHUD);
    get().SetPersonalHUD(personalHUD);
    result.then(() => {
      if (window.location.href.split('/').length > 4) set({ navigate: true });
    });
  },
  SetOnLoad: (value: boolean) => {
    set({ onLoad: value });
  },
  SetNavigate: (value: boolean) => {
    set({ navigate: value });
  },
  SetHUDFilterView: (value: iHUDFilterView) => {
    set({ hudFilterView: value });
  },
  ToggleOnDragHUD: () => {
    set({ onDragHUD: !get().onDragHUD });
  },
  SetOnDragHUD: (newState: boolean) => {
    set({ onDragHUD: newState });
  },
  SetWidgetShareURL: (url: string) => {
    set({ widgetShareURL: url });
  }
}));

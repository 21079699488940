import { CopyButton, Divider, Flex, Title } from '@mantine/core';
import { iWidget } from '../state/models';
import { Button } from '../../Button';
import { useHUDStore } from '../state';

export const ShareWidgetBox = ({
  widget,
  closeFunc
}: {
  widget: iWidget;
  closeFunc: any;
}) => {
  const sharedWidgetUrl = useHUDStore((state) => state.widgetShareURL)

  return (
    <>
      <Title order={5} c="gray">
        Share Current Widget
      </Title>
      <Divider></Divider>
      <Flex style={{ marginTop: '10px' }} justify="center" gap="md">
        <CopyButton value={sharedWidgetUrl}>
          {({ copied, copy }) => (
            <Button
              testid="widgetShareCopy"
              base="add"
              color={copied ? 'teal' : 'blue'}
              onClick={copy}
            >
              {copied ? 'Copied URL' : 'Copy URL'}
            </Button>
          )}
        </CopyButton>
        <Button
          testid="widgetShareCancel"
          base="cancel"
          onClick={() => closeFunc.close()}
        />
      </Flex>
    </>
  );
};

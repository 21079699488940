import {
  Box,
  Flex,
  InputLabel,
  Radio,
  Switch,
  Text,
  TextInput,
  Title
} from '@mantine/core';
import { Button, Checkbox, ContainerComponent } from '../../components';
import { DayVal } from '../../components/Notification/models';
import { useNotificationStore } from '../../components/Notification/store';
import { useSavedViewsStore } from '../../containers/SavedViews/store';
import { useEffect } from 'react';
import _ from 'lodash';
import { useConfigStore } from '../../app/store';

export const NotificationForm = (props: { close: Function }) => {
  const { updateValue, currentNotification, editing } = useNotificationStore();
  const { SaveNotification } = useSavedViewsStore();
  const handleScheduleCheck = (e: any) => {
    const nativeEvent = e.nativeEvent.target;
    const label = nativeEvent.value;
    const copy = [...currentNotification.schedule];
    copy.forEach((item: DayVal) => {
      if (item.label === label) {
        item.checked = nativeEvent.checked;
      }
    });
    updateValue({ schedule: copy });
  };
  const userEmail = useConfigStore((state) => state.userData.email);

  useEffect(() => {
    updateValue({ destination: userEmail });
  }, []);

  const changeHandler = (x: any, field: string) => {
    updateValue({ [field]: x });
  };

  const { SetNotificationModalOpen } = useSavedViewsStore();
  return (
    <ContainerComponent
      style={{
        zIndex: 999
      }}
      testid="Notifications"
      title={'Notifications'}
      rightSection={<></>}
    >
      <Box style={{ marginBottom: 20 }}>
        <Title order={3}>{currentNotification.view}</Title>
        <InputLabel style={{ display: 'flex' }}>
          Status:
          <Box style={{ display: 'flex', marginLeft: 12 }}>
            <Switch
              checked={currentNotification.active}
              onChange={(x) => {
                changeHandler(x.currentTarget.checked, 'active');
              }}
            />
            <Text ml={12}>{currentNotification.active ? 'Active' : 'Inactive'}</Text>
          </Box>
        </InputLabel>
      </Box>
      <Flex>
        {currentNotification.schedule.map((item) => {
          return (
            <Checkbox
              onChange={(e) => handleScheduleCheck(e)}
              testid="schedule"
              size="xs"
              mb={12}
              label={
                <Text size="sm" style={{ marginTop: -3, marginRight: 3 }}>
                  {item.label}
                </Text>
              }
              value={item.label}
              checked={item.checked}
              key={item.key}
              style={{ marginTop: -8, marginBottom: -8, marginRight: 16 }}
            />
          );
        })}
      </Flex>
      <Radio.Group
        value={currentNotification.timeInterval}
        onChange={(x) => changeHandler(x, 'timeInterval')}
        label="Select your time interval in hours"
        required
      >
        {['1', '4', '8', '12', '24'].map((item) => {
          return <Radio style={{ marginBottom: 5 }} value={item} label={item} />;
        })}
      </Radio.Group>
      <InputLabel>
        Destination:
        <TextInput
          disabled // Add OKTA Group admin control here for adding different distro/email destination for a notification
          error={!userEmail && 'Field is required'}
          value={currentNotification.destination}
          onChange={(x) => {
            changeHandler(x.currentTarget.value, 'destination');
          }}
          description="Email address to send to"
          placeholder="Email"
        />
      </InputLabel>
      <Box>
        <Button
          mt={12}
          noIcon
          testid="notificationsButton"
          onClick={async () => {
            SaveNotification(currentNotification);
            SetNotificationModalOpen(false);
            props.close();
          }}
        >
          {editing ? 'Update' : 'Create'} Notification
        </Button>
      </Box>
    </ContainerComponent>
  );
};

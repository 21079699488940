import { Box, ScrollArea } from '@mantine/core';
import { CommentRow } from './CommentRow';
import { iComment } from './models';
import { useEffect } from 'react';
import { useCommentStore } from './store';

export const CommentList = (props: { id: string; rowId: string }) => {
  const { comments, GetComments } = useCommentStore();
  useEffect(() => {
    (async () => {
      GetComments(props.id, props.rowId);
    })();
  }, [comments.length]);

  function getList(comment: iComment, idx: number) {
    return <CommentRow key={idx} data={{ ...comment }} rowId={props.rowId} />;
  }

  return (
    <Box>
      <ScrollArea
        offsetScrollbars
        type="hover"
        style={{
          borderRadius: 6,
          padding: 16,
          maxHeight: 200,
          overflowY: 'auto'
        }}
      >
        {comments.map(getList)}
      </ScrollArea>
    </Box>
  );
};

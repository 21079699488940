export interface iSearchBarConfigureState {
    searchBarConfigureModalOpen: boolean
    showPlatformSelect: boolean
    showPartnerSelect: boolean
    showContentSourceSelect: boolean
    showOfferingTypesSelect: boolean
    showEntityTypeSelect: boolean
    showMilestoneSelect: boolean
    showMilestoneStatusSelect: boolean
    showCascaderStatusCode: boolean
    showNetworkSelect: boolean
    showFeedSelect: boolean
    showScheduleType: boolean
    showSuppressRepeats: boolean
    showContentType: boolean
    showDeletedAssetsSwitch: boolean
    showExpiredAssetsSwitch: boolean
    showLinearExpired: boolean
    showAdMarkersSwitch: boolean
    showMidRollAdsAllowedSwitch: boolean
    showIsLive: boolean
    showContentTypeAcquireSelect: boolean
    showDistributorSelect: boolean
    ToggleSearchBarConfigureModalOpen: () => void;
    TogglePlatformSelect: () => void;
    TogglePartnerSelect: () => void;
    ToggleContentSourceSelect: () => void;
    ToggleOfferingTypesSelect: () => void;
    ToggleEntityTypeSelect: () => void;
    ToggleMilestoneSelect: () => void;
    ToggleMilestoneStatusSelect: () => void;
    ToggleDeletedAssetsSelect: () => void;
    ToggleExpiredAssetsSelect: () => void;
}

export const initialSearchBarConfigureValues: iSearchBarConfigureState = {
    searchBarConfigureModalOpen: false,
    showPlatformSelect: true,
    showPartnerSelect: true,
    showContentSourceSelect: true,
    showOfferingTypesSelect: true,
    showEntityTypeSelect: true,
    showMilestoneSelect: true,
    showMilestoneStatusSelect: true,
    showCascaderStatusCode: true,
    showDeletedAssetsSwitch: true,
    showExpiredAssetsSwitch: true,
    showNetworkSelect: true,
    showFeedSelect: true,
    showScheduleType: true,
    showContentType: true,
    showSuppressRepeats: true,
    // content type is for linear and different from content class
    showLinearExpired: true,
    showAdMarkersSwitch: true,
    showMidRollAdsAllowedSwitch: true,
    showIsLive: true,
    showContentTypeAcquireSelect: true,
    showDistributorSelect: true,
    ToggleSearchBarConfigureModalOpen: () => {},
    TogglePlatformSelect: () => {},
    TogglePartnerSelect: () => {},
    ToggleContentSourceSelect: () => {},
    ToggleOfferingTypesSelect: () => {},
    ToggleEntityTypeSelect: () => {},
    ToggleMilestoneSelect: () => {},
    ToggleMilestoneStatusSelect: () => {},
    ToggleDeletedAssetsSelect: () => {},
    ToggleExpiredAssetsSelect: () => {}
}
import { Box, Divider, Drawer, Group, Popover, Space, Stack, Text, useComputedColorScheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Settings } from 'tabler-icons-react';
import { Button } from '../Button';
import { Switch } from '../Switch';
import { useNavBarStore } from '../Navbar/store';
import { Tooltip } from '../Tooltip';
import { useTID } from '../../helpers/useTestId';
import { useRef, useState } from 'react';
import { useUserPrefsStore } from '../../app/store';
import { useMultiWindowCommsStore } from '../../containers/MultiWindowComms/store';
import classes from './DrawerSettings.module.css';

interface IDrawerSettings {
  testid: string;
}

export const DrawerSettings = (props: IDrawerSettings) => {
  const colorScheme = useComputedColorScheme('light');
  const dark = colorScheme === 'dark';
  const [opened, handlers] = useDisclosure(false);
  const showTooltips = useUserPrefsStore((state) => state.enableTooltips);
  const toggleTooltips = useUserPrefsStore((state) => state.SetEnableTooltips);
  const navCloseOnSelect = useNavBarStore((state) => state.CloseOnSelect);
  const ToggleCloseOnSelect = useNavBarStore((state) => state.ToggleCloseOnSelect);

  const enableMultiWindowComms = useMultiWindowCommsStore((state) => state.Enabled);
  const toggleEnableMultiWindowComms = useMultiWindowCommsStore(
    (state) => state.ToggleEnabled
  );

  const [multiCommsOpened, multiCommHandlers] = useDisclosure(false);
  const [sendingPersonalSettings, set_sendingPersonalSettings] = useState(false);

  const TID = useTID('DrawerSettings', props.testid);
  const ref = useRef<any>();

  return (
    <>
      <Drawer
        closeOnClickOutside
        closeOnEscape
        onClose={() => handlers.close()}
        opened={opened}
        padding="xl"
        position="right"
        title="Settings"
        size={300}
        withCloseButton={false}
      >
        <Space h="xl" />
        <Stack>
          <Switch
            checked={navCloseOnSelect}
            label={`${
              navCloseOnSelect ? '' : 'Do NOT '
            }Close Navbar when clicking a selection.`}
            testid="close-navbar"
            onChange={() => {
              ToggleCloseOnSelect();
            }}
          />
        </Stack>
        <Space h="xl" />
        <Stack>
          <Switch
            checked={showTooltips}
            label={`${showTooltips ? '' : 'Do NOT '}Show Tooltips.`}
            testid="show-tooltips"
            onChange={async () => {
              set_sendingPersonalSettings(true);
              !sendingPersonalSettings && (await toggleTooltips());
              set_sendingPersonalSettings(false);
            }}
          />
        </Stack>
        <Space h="xl" />
        <Stack>
          <Popover
            position="left"
            withinPortal={true}
            opened={multiCommsOpened}
            withArrow
            classNames={{
              arrow: classes.arrow,
              dropdown: classes.dropdown
            }}
          >
            <Popover.Target>
              <Box
                onMouseEnter={multiCommHandlers.open}
                onMouseLeave={multiCommHandlers.close}
              >
                <Switch
                  checked={enableMultiWindowComms}
                  label="Enable Multi-Windows Communications"
                  testid="enable-multi-comms"
                  onChange={() => {
                    toggleEnableMultiWindowComms();
                  }}
                />
              </Box>
            </Popover.Target>
            <Popover.Dropdown>
              <Box style={{ maxWidth: 400 }}>
                <Text style={{ fontWeight:650 }}>Multi Window Communications</Text>
                <Divider my={12} size="xs" />
                <Text>
                  When in the HUD and you have a separate DiSCO Views tab open, this
                  allows HUD clicks/navigation to control the search results shown on the
                  other tab. This is useful in multiple-monitor setups where there is a
                  monitor dedicated to the HUD.
                </Text>
              </Box>
            </Popover.Dropdown>
          </Popover>
        </Stack>
        <Space h="xl" />
        <Group justify="flex-end">
          <Button
            base="cancel"
            testid="DrawerSettingsCancel"
            onClick={() => {
              handlers.close();
            }}
          >
            Close
          </Button>
        </Group>
      </Drawer>

      <Group>
        <Tooltip label={`Toggle Settings panel`} withArrow>
          <div ref={ref}>
            <Button
              className={`${!dark && 'themebtn-bg-color'}`}
              {...TID}
              testid="DrawerSettingsToggle"
              variant="subtle"
              style={(theme) => ({
                color: opened ? theme.colors.blue[5] : 'gray'
              })}
              onClick={() => handlers.open()}
              mr="xl"
              leftSection={<Settings style={{ margin: -8, marginRight:-10, marginLeft: -2}}/>}
            />
          </div>
        </Tooltip>
      </Group>
    </>
  );
};

export default DrawerSettings;

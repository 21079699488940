import { getAxiosConfig } from '../axios/config';
import axios from 'axios';

export const APIService = {
  getQueryReturns: async (baseURL: string) => {
    const { headers } = getAxiosConfig();
    const res = await axios.get(baseURL + '/rest/appconfigs/v1?type=queryReturn:', { headers })

    return res.data;
  }
};

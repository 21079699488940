export interface iConfiguration {
  oidc: {
    clientId: string;
    issuer: string;
  };
  baseApiGatewayUrl: string;
  showTerritory: boolean;
  environment: string;
  oktaAdminGroup: string;
  oktaPowerUserGroup: string;
  oktaViewsGroup: string;
  couiEnvironment: string;
}

export const initialConfiguration: iConfiguration = {
  oidc: {
    clientId: '',
    issuer: ''
  },
  baseApiGatewayUrl: '',
  showTerritory: false,
  environment: '',
  oktaAdminGroup: '',
  oktaPowerUserGroup: '',
  oktaViewsGroup: '',
  couiEnvironment: ''
};

export interface iUserData {
  name?: string;
  surName?: string;
  email?: string;
  isAdmin?: boolean;
  uid?: string;
  groups: any // This would be set to a string[], but okta's type doesn't allow for this
}

export const initialUserData: iUserData = {
  name: undefined,
  surName: undefined,
  email: undefined,
  isAdmin: false,
  uid: undefined,
  groups: []
};

export interface iDefaultViews {
  footprint: string;
  default: string;
}
export interface iDetailTaskFilter {
  footprint: string;
  statusCode: boolean;
  filter: any[];
}
export interface iUserPrefs {
  selectedTheme: string;
  enableTooltips: boolean;
  type: string;
  id: string;
  defaultFootprint: string;
  detailTaskFilters: iDetailTaskFilter[];
  groups: string[],
  defaultViews: iDefaultViews[];
  Load: () => void;
  SetSelectedTheme: (theme: string) => void;
  SetUserPreferencesId: (id: string) => void;
  SetEnableTooltips: () => void;
  SetDefaultFootprint: (id: string) => void;
  SetDetailTaskFilters: (filter: iDetailTaskFilter) => void;
  UpdateGroups: (group: string) => void;
  SetDefaultViews: (view?: any) => void;
}

export interface iConfig {
  config: iConfiguration;
  userData: iUserData;
  layoutEditable: boolean;
  isConfigLoading: boolean;
  fetching: boolean;
  Load: () => Promise<void>;
  SetConfig: (newConfig: iConfiguration) => void;
  SetUserData: (newUserData: iUserData) => void;
  ToggleLoading: () => void;
  ToggleLayoutEditable: () => void;
}

export const initialConfig = {
  config: initialConfiguration,
  userData: initialUserData,
  layoutEditable: false,
  isConfigLoading: true,
  fetching: false,
  Load: () => {},
  SetConfig: () => {},
  SetUserData: () => {},
  ToggleLoading: () => {},
  ToggleLayoutEditable: () => {}
};

export const initialUserPreferencesState: iUserPrefs = {
  selectedTheme: 'light',
  enableTooltips: false,
  type: '',
  id: '',
  defaultFootprint: 'footprint-offering',
  detailTaskFilters: [
    {
      footprint: 'footprint-offering',
      statusCode: true,
      filter: [
        ['footprint-offering_prep'],
        ['footprint-offering_fulfill'],
        ['footprint-offering_delivery']
      ]
    },
    {
      footprint: 'footprint-linear',
      statusCode: true,
      filter: [
        ['footprint-linear_prep'],
        ['footprint-linear_fulfill'],
        ['footprint-linear_delivery']
      ]
    },
    {
      footprint: 'footprint-acquire',
      statusCode: true,
      filter: [['footprint-acquire_prep'], ['footprint-acquire_transform']]
    },
    {
      footprint: 'footprint-crossplatform',
      statusCode: true,
      filter: [
        ['footprint-crossplatform_prep'],
        ['footprint-crossplatform_fulfill'],
        ['footprint-crossplatform_delivery']
      ]
    }
  ],
  groups: [],
  defaultViews: [],
  Load: () => {},
  SetSelectedTheme: () => {},
  SetUserPreferencesId: () => {},
  SetEnableTooltips: () => {},
  SetDefaultFootprint: () => {},
  SetDetailTaskFilters: () => {},
  UpdateGroups: () => {},
  SetDefaultViews: () => {}

};

export enum ColorTheme {
  light = 'light',
  dark = 'dark'
}

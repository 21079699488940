import { Table } from "@mantine/core";
import { DetailAccordionItem } from "../../../components/DetailAccordion";
import { capitalize } from "../../../utils/utilities";

export const showMezzAccordionItems = (k: any, width: number) => {
  const asset = k.eventSource === "bms" ? k.details.assets[0] : k.details.asset;
  let codeContent;
  if (k.status?.type === 'error' || k.status?.type === 'failure') {
    let code = k.status.codes[0];
    if (code.detail) {
      codeContent = (
        <>
          <Table.Tr>
            <Table.Td style={{ textAlign: 'left', width: 90 }}>Status Code: </Table.Td>
            <Table.Td>{code.code}</Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td style={{ textAlign: 'left', width: 90 }}>Code Detail: </Table.Td>
            <Table.Td>{code.detail}</Table.Td>
          </Table.Tr>
        </>
      );
    } else {
      codeContent = (
        <Table.Tr>
          <Table.Td style={{ textAlign: 'left', width: 90 }}>Status Code: </Table.Td>
          <Table.Td>{code.code}</Table.Td>
        </Table.Tr>
      );
    }
  }
  let bmsAssetContent
  if (k.eventSource === "bms" && asset){
    let code = asset.status.codes[0];
    if (code.detail) {
      bmsAssetContent = (
        <>
          <Table.Tr>
            <Table.Td style={{ textAlign: 'left', width: 90 }}>Status: </Table.Td>
            <Table.Td>{capitalize(asset.status?.type.toLowerCase())}</Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td style={{ textAlign: 'left', width: 90 }}>Status Code: </Table.Td>
            <Table.Td>{code.code}</Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td style={{ textAlign: 'left', width: 90 }}>Code Detail: </Table.Td>
            <Table.Td>{code.detail}</Table.Td>
          </Table.Tr>
        </>
      );
    } else if (asset.details){
      bmsAssetContent = (
        <>
          <Table.Tr>
            <Table.Td style={{ textAlign: 'left', width: 90 }}>Status: </Table.Td>
            <Table.Td>{capitalize(asset.status?.type.toLowerCase())}</Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td style={{ textAlign: 'left', width: 90 }}>Status Code: </Table.Td>
            <Table.Td>{code.code}</Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td style={{ textAlign: 'left', width: 90 }}>Code Detail: </Table.Td>
            <Table.Td>{asset.details}</Table.Td>
          </Table.Tr>
        </>
      );
    } else {
      bmsAssetContent = (
        <>
          <Table.Tr>
            <Table.Td style={{ textAlign: 'left', width: 90 }}>Status: </Table.Td>
            <Table.Td>{capitalize(asset.status?.type.toLowerCase())}</Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td style={{ textAlign: 'left', width: 90 }}>Status Code: </Table.Td>
            <Table.Td>{code.code}</Table.Td>
          </Table.Tr>
        </>
      );
    }
  }
  return (
    <DetailAccordionItem
      testid={`DetailAccordionItem-${k.id}`}
      name={`item-${k.id}`}
      key={`item-${k.id}`}
      title={`${k.id}`}
      styles={{
        borderBottom: 0,
        marginLeft: -6,
        width: width - 10,
        minWidth: 350
      }}
      status={k.status}
    >
      <Table
        striped={false}
        // fontSize="xs"
        highlightOnHover={true}
        style={{ width: '100%', margin: -16, marginTop: -32, borderBottom: 0 }}
      >
        <Table.Thead>
          <Table.Tr>
            <Table.Td>&nbsp;</Table.Td>
            <Table.Td>&nbsp;</Table.Td>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {!asset && (
            <>
              <Table.Tr>
                <Table.Td style={{ textAlign: 'left', width: 90 }}>Asset: </Table.Td>
                <Table.Td>No Data</Table.Td>
              </Table.Tr>
            </>
          )}
          {(asset && k.eventSource === "esp")&& (
            <>
              <Table.Tr>
                <Table.Td style={{ textAlign: 'left', width: 90 }}>Source: </Table.Td>
                <Table.Td>{k.eventSource}</Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td style={{ textAlign: 'left', width: 90 }}>ID: </Table.Td>
                <Table.Td>{asset.id}</Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td style={{ textAlign: 'left', width: 90 }}>Details: </Table.Td>
                <Table.Td>{asset.details}</Table.Td>
              </Table.Tr>
            </>
          )}
          {(asset && k.eventSource === "bms")&& (
            <>
              <Table.Tr>
                <Table.Td style={{ textAlign: 'left', width: 90 }}>Source: </Table.Td>
                <Table.Td>{k.eventSource}</Table.Td>
              </Table.Tr>
              {bmsAssetContent}
            </>
          )}
          {k.status && (
            <Table.Tr>
              <Table.Td style={{ textAlign: 'left', width: 90 }}>Status: </Table.Td>
              <Table.Td>{k.status?.type}</Table.Td>
            </Table.Tr>
          )}
          {codeContent}
        </Table.Tbody>
      </Table>
    </DetailAccordionItem>
  );
};
import { ValueGetterParams } from 'ag-grid-community';
import { useStatusCodeStore } from '../../../../components/StatusCodeDictionary/store';
import { formattedDate } from '../../../../helpers';
import { useAppConfigManager } from '../../../../components/AppConfigs';
import dayjs from 'dayjs';
import { getPseComplianceStatus } from '../../../../utils/utilities';
import { getRatings } from '../../../../utils/utilities';
import { ColDef } from 'ag-grid-community';
import {findManifestationTask} from '../columnsHelpers/globalValueGetters';

export type BrandType = { label: string; primary: boolean };

export const footprintStatusGetter = (params: ValueGetterParams) => {
  const GetStatusCode = useStatusCodeStore.getState().GetStatusCode;
  const footprintStatus = params.data.milestones?.find(
    (element: { name: string }) => element.name === 'offering-footprint'
  );
  let status = footprintStatus ? footprintStatus.status : null;
  if (status !== null) status = status.charAt(0).toUpperCase() + status.slice(1);
  if (status !== null) status = status.charAt(0).toUpperCase() + status.slice(1);
  if (footprintStatus?.codes && footprintStatus?.codes.length > 0) {
    let sc = GetStatusCode(footprintStatus.codes[0].code);
    if (sc.status && sc.badgeText !== '') {
      status =
        sc.status.charAt(0).toUpperCase() + sc.status.slice(1) + ' - ' + sc.badgeText;
    }
  }
  return status;
};

export const createdDateGetter = (params: ValueGetterParams) => {
  let date = params.data.audit.createdAt;
  return formattedDate(date);
};

export const updatedDateGetter = (params: ValueGetterParams) => {
  let date = params.data.audit.updatedAt;
  return formattedDate(date);
};

export const globalReleaseDateGetter = (params: ValueGetterParams) => {
  let date = params?.data?.offering?.globalReleaseDate;
  if (date) {
    return dayjs(date).format('MMM DD, YYYY (hh:mm a | [EST])');
  }
  return null;
};

export const getMilestoneByNameAndType = (colDef: ColDef) => {
  let type = '';
  let name = '';
  if (colDef?.cellRendererParams?.task) {
    type = 'Task';
    name = `${colDef?.cellRendererParams?.field}|${colDef?.cellRendererParams?.activity}|${colDef?.cellRendererParams?.task}`;
  } else if (colDef?.cellRendererParams?.activity) {
    type = 'Activity';
    name = `${colDef?.cellRendererParams?.field}|${colDef?.cellRendererParams?.activity}`;
  } else {
    type = 'Process';
    name = colDef?.cellRendererParams?.field;
  }
  return { type, name };
};

export const getMilestone = (params: ValueGetterParams) => {
  const { type, name } = getMilestoneByNameAndType(params?.colDef);
  return params.data.milestones?.find(
    (element: { type: string; name: string }) =>
      element.type === type && element.name === name
  );
};

export const milestoneStatusGetter = (params: ValueGetterParams) => {
  const GetStatusCode = useStatusCodeStore.getState().GetStatusCode;
  const milestone = getMilestone(params);
  let status = milestone ? milestone.status : null;
  if (status === 'na') status = 'N/A';
  if (status !== null) status = status.charAt(0).toUpperCase() + status.slice(1);
  if (milestone?.codes && milestone?.codes.length > 0) {
    let sc = GetStatusCode(milestone.codes[0].code);
    if (sc.status && sc.badgeText !== '') {
      status =
        sc.status.charAt(0).toUpperCase() + sc.status.slice(1) + ' - ' + sc.badgeText;
    }
  }
  return status;
};

export const milestoneDateGetter = (params: ValueGetterParams) => {
  const milestone = getMilestone(params);

  let date = milestone?.audit?.updatedAt || null;
  if (!date) return null;
  return formattedDate(date);
};

export const milestoneDetailGetter = (params: ValueGetterParams) => {
  const milestone = getMilestone(params);

  if (milestone && milestone?.codes !== null && milestone.codes.length > 0) {
    const codes = milestone.codes.find((element: any) => 'detail' in element);
    let detail = codes?.detail;
    if (!detail || detail === codes.data) {
      const GetStatusCode = useStatusCodeStore.getState().GetStatusCode;
      let sc = GetStatusCode(codes.code);
      if (sc) {
        detail = sc.details;
      }
    }
    if (detail && codes.data) {
      return `${detail} - ${codes.data}`;
    } else if (detail) {
      return detail;
    } else if (codes.data) {
      return codes.data;
    }
  }
  return null;
};

export const milestoneCodeGetter = (params: ValueGetterParams) => {
  const milestone = getMilestone(params);

  if (milestone && milestone?.codes !== null && milestone.codes.length > 0) {
    const codes = milestone.codes.find((element: any) => 'code' in element);
    if (codes.code) {
      return codes.code;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

function truncateUUID(uuid: string) {
  return uuid.substring(0, 4) + ' ... ' + uuid.substring(uuid.length - 4, uuid.length);
}

export const startDateFormatter = (props: any) => {
  if (props.data.offering) {
    const date = props.data.offering.startDate;
    return formattedDate(date);
  }
  return '';
};

export const endDateFormatter = (props: any) => {
  const date = props.data.offering.endDate;
  return formattedDate(date);
};

export const titleGetter = (params: ValueGetterParams) => {
  let title = '';
  const titles = params.data.offering.titles;
  if (!titles) return '--No Title--';
  if (titles.length > 0) {
    const distribution = titles.find(
      (title: { type: string }) => title.type === 'distribution-title'
    );
    title = distribution ? distribution.full : null;
    if (!title) {
      const main = titles.find((title: { type: string }) => title.type === 'main-title');
      title = main ? main.full : null;
    }
    if (!title) {
      const cat = titles.find((title: { type: string }) => title.type === 'cat-title');
      title = cat ? cat.full : '';
    }
    if (!title) {
      const fallback = titles.find(
        (title: { type: string }) => title.type === 'internal-wm-name'
      );
      title = fallback ? fallback.full : '--No Title--';
    }
  }
  if (!title) title = '--No Title--';
  return title;
};

export const seriesTitleGetter = (params: ValueGetterParams) => {
  let title = '';
  const titles = params.data.offering.seriesTitles;
  if (!titles) return title;
  if (titles.length > 0) {
    const distribution = titles.find(
      (title: { type: string }) => title.type === 'distribution-title'
    );
    title = distribution ? distribution.full : null;
    if (!title) {
      const main = titles.find((title: { type: string }) => title.type === 'main-title');
      title = main ? main.full : null;
    }
    if (!title) {
      const cat = titles.find((title: { type: string }) => title.type === 'cat-title');
      title = cat ? cat.full : '';
    }
    if (!title) {
      const fallback = titles.find(
        (title: { type: string }) => title.type === 'internal-wm-name'
      );
      title = fallback ? fallback.full : '';
    }
  }
  return title;
};

export const seasonTitleGetter = (params: ValueGetterParams) => {
  let title = '';
  const titles = params.data.offering.seasonTitles;
  if (!titles) return title;
  if (titles.length > 0) {
    const distribution = titles.find(
      (title: { type: string }) => title.type === 'distribution-title'
    );
    title = distribution ? distribution.full : null;
    if (!title) {
      const main = titles.find((title: { type: string }) => title.type === 'main-title');
      title = main ? main.full : null;
    }
    if (!title) {
      const cat = titles.find((title: { type: string }) => title.type === 'cat-title');
      title = cat ? cat.full : '';
    }
    if (!title) {
      const fallback = titles.find(
        (title: { type: string }) => title.type === 'internal-wm-name'
      );
      title = fallback ? fallback.full : '';
    }
  }
  return title;
};

export const editIDGetter = (params: ValueGetterParams) => {
  if (
    !params.data.offering.editId ||
    !params.data.offering.editId.id ||
    params.data.offering.editId.id === null
  ) {
    return '';
  }
  return truncateUUID(params.data.offering.editId.id);
};

export const contentIDGetter = (params: ValueGetterParams) => {
  return truncateUUID(params.data.offering.contentId.id);
};

export const offeringIDGetter = (params: ValueGetterParams) => {
  return truncateUUID(params.data.offering.id);
};
export const offeringTypeGetter = (params: ValueGetterParams) => {
  let capitalType;
  if (params.data.offering.type) {
    let formattedType = params.data.offering.type.split('_').slice(-1);
    capitalType = formattedType[0].charAt(0).toUpperCase() + formattedType[0].slice(1);
  } else {
    capitalType = null;
  }
  return capitalType;
};

export const entityTypeGetter = (params: ValueGetterParams) => {
  let capitalType;
  if (params.data.offering.entityType) {
    capitalType =
      params?.data?.offering?.entityType?.charAt(0)?.toUpperCase() +
      params?.data?.offering?.entityType?.slice(1);
  } else {
    capitalType = null;
  }
  return capitalType;
};

export const offeringContentSourceGetter = (params: ValueGetterParams) => {
  let source = '';
  if (params.data.offering.transcodingSystem) {
    switch (params.data.offering.transcodingSystem) {
      case 'esp':
        source = 'WarnerMedia';
        break;
      case 'offramp':
        source = 'Discovery';
        break;
      default:
        source = 'N/A';
    }
  }
  return source;
};

export const partnerGetter = (params: ValueGetterParams) => {
  let partner = '';
  if (!params.data.offering.partnerId) {
    return '';
  }
  if (params.data.offering.partnerId.id) {
    switch (params.data.offering.partnerId.id) {
      case 'c01c1c07-e7bf-4cee-be6b-87092a30d41c':
        partner = 'Max';
        break;
      case '2195c719-d08d-49e2-b568-234ec46414b5':
        partner = 'Max';
        break;
      case '9646297a-acd4-4884-8977-d56b280a0c4a':
        partner = 'Max';
        break;
      case 'f6bc2fc8-8191-4920-8e9f-99ce4b0ff057':
        partner = 'Max';
        break;
      case '65e74175-2142-4e5a-a85f-65f4ed69f570':
        partner = 'Max';
        break;
      case '155ecceb-bb5d-4cd3-b106-183686d5a2d4':
        partner = 'Max';
        break;
      case 'bdb0b66c-4cb4-49e0-980d-a19145cecf80':
        partner = 'Max';
        break;
      case '22352a43-1948-4ea4-8daa-9bd00749fc5c':
        partner = 'Max';
        break;
      case '4a7ad113-8fda-4939-bda8-a9474a912805':
        partner = 'Amazon PVC';
        break;
      case 'd5c3de78-11ca-4ff3-be8f-d1855e2373ed':
        partner = 'YouTube';
        break;
      case 'ac176604-f0c4-4cf3-b06f-bf9e8aea3ffe':
        partner = 'Telefonica';
        break;
      case 'd646434d-bcf9-4e40-88fb-c5ca52d8ccf0':
        partner = 'Bleacher Report';
        break;
      case 'dd4b242c-7f40-4628-bb79-8e69e5f98fad':
        partner = 'Canal+';
        break;
      default:
        partner = '';
    }
  }
  return partner;
};

export const marketGetter = (params: ValueGetterParams) => {
  if (!params.data.offering.marketId) {
    return '';
  }
  if (params.data.offering.marketId.id) {
    const markets = useAppConfigManager.getState().queryMarkets;
    const market = markets.find((m) => m.valueList === params.data.offering.marketId.id);
    if (market) {
      return market.label;
    } else {
      return `Market ID: ${params.data.offering.marketId.id} label not found`;
    }
  }
};

export const playlistEntriesGetter = (params: ValueGetterParams) => {
  if (params.data.offering?.entries) {
    return params.data.offering.entries.length.toLocaleString();
  }
  return null;
};

export const adMarkerGetter = (params: any) => {
  const tasks = params.data ? params.data.tasks : params.tasks;
  if (tasks) {
    if (tasks?.length > 0) {
      const task = findManifestationTask(tasks, 'mezzcreation', 'fulfillment-workflow');
      if (task) {
        return task.details?.fulfilledMediaMarkerCount;
      }
    }
  }
  return null;
};

export const latestCommentGetter = (params: ValueGetterParams) => {
  const comment = params.data.latestComment;
  if (comment) {
    return `${comment.comment} | ${comment.audit?.createdBy} | ${formattedDate(
      comment.audit?.createdAt
    )}`;
  }
  return '';
};

export const primaryBrandLabels = (params: ValueGetterParams) => {
  const brands: BrandType[] = params?.data?.offering?.brands;
  return brands
    ?.filter((brand) => brand.primary)
    ?.map((brand) => brand.label)
    ?.join(', ');
};

export const secondaryBrandLabels = (params: ValueGetterParams) => {
  const brands: BrandType[] = params?.data?.offering?.brands;
  return brands
    ?.filter((brand) => !brand.primary)
    ?.map((brand) => brand.label)
    ?.join(', ');
};

const findPSEComplianceTask = (tasks: any[]) => {
  const task = tasks.find((t: { type: string; eventSource: string }) => {
    return t.type === 'mezzcreation';
  });
  return task;
};

export const complianceGetter = (params: any) => {
  const tasks = params.data ? params.data.tasks : params.tasks;
  if (!tasks) return 'N/A';
  if (tasks) {
    if (tasks?.length > 0) {
      const task = findPSEComplianceTask(tasks);
      if (task) {
        return getPseComplianceStatus(task);
      }
    } else {
      return 'N/A';
    }
  }
  return 'N/A';
};
const dynamicRangeGetter = (task: any, range: string) => {
  let fulfilledVideoComponents;
  if (task?.details?.fulfilledVideoComponents?.length > 0) {
    fulfilledVideoComponents = task.details?.fulfilledVideoComponents.find(
      (i: { dynamicRange: string }) =>
        i.dynamicRange && i.dynamicRange.toUpperCase().includes(range)
    );
    return fulfilledVideoComponents;
  }
};
export const hdrFrameSizeGetter = (params: any) => {
  let fulfilledVideoComponents;
  const tasks = params.data ? params.data.tasks : params.tasks;
  if (tasks?.length > 0) {
    const task = findManifestationTask(tasks, 'mezzcreation', 'fulfillment-workflow');
    fulfilledVideoComponents = dynamicRangeGetter(task, 'HDR');
    if (fulfilledVideoComponents?.frameSize)
      return `${fulfilledVideoComponents?.frameSize?.width} x ${fulfilledVideoComponents?.frameSize?.height}`;
    else return '';
  }
  return '';
};
export const sdrFrameSizeGetter = (params: any) => {
  let fulfilledVideoComponents;
  const tasks = params.data ? params.data.tasks : params.tasks;
  if (tasks?.length > 0) {
    const task = findManifestationTask(tasks, 'mezzcreation', 'fulfillment-workflow');
    fulfilledVideoComponents = dynamicRangeGetter(task, 'SDR');
    if (fulfilledVideoComponents?.frameSize)
      return `${fulfilledVideoComponents?.frameSize?.width} x ${fulfilledVideoComponents?.frameSize?.height}`;
    else return '';
  }
  return '';
};
export const hdrPictureAspectRatioGetter = (params: any) => {
  let fulfilledVideoComponents;
  const tasks = params.data ? params.data.tasks : params.tasks;
  if (tasks?.length > 0) {
    const task = findManifestationTask(tasks, 'mezzcreation', 'fulfillment-workflow');
    fulfilledVideoComponents = dynamicRangeGetter(task, 'HDR');
    if (fulfilledVideoComponents?.pictureAspectRatio)
      return `${fulfilledVideoComponents?.pictureAspectRatio}`;
    else return '';
  }
  return '';
};
export const sdrPictureAspectRatioGetter = (params: any) => {
  let fulfilledVideoComponents;
  const tasks = params.data ? params.data.tasks : params.tasks;
  if (tasks?.length > 0) {
    const task = findManifestationTask(tasks, 'mezzcreation', 'fulfillment-workflow');
    fulfilledVideoComponents = dynamicRangeGetter(task, 'SDR');
    if (fulfilledVideoComponents?.pictureAspectRatio)
      return `${fulfilledVideoComponents?.pictureAspectRatio}`;
    else return '';
  }
  return '';
};
export const hdrScanningFormatGetter = (params: any) => {
  let fulfilledVideoComponents;
  const tasks = params.data ? params.data.tasks : params.tasks;
  if (tasks?.length > 0) {
    const task = findManifestationTask(tasks, 'mezzcreation', 'fulfillment-workflow');
    fulfilledVideoComponents = dynamicRangeGetter(task, 'HDR');
    if (fulfilledVideoComponents?.scanningFormat)
      return `${fulfilledVideoComponents?.scanningFormat
        ?.split(':')
        .slice(-1)[0]
        .toUpperCase()}`;
    else return '';
  }
  return '';
};
export const sdrScanningFormatGetter = (params: any) => {
  let fulfilledVideoComponents;
  const tasks = params.data ? params.data.tasks : params.tasks;
  if (tasks?.length > 0) {
    const task = findManifestationTask(tasks, 'mezzcreation', 'fulfillment-workflow');
    fulfilledVideoComponents = dynamicRangeGetter(task, 'SDR');
    if (fulfilledVideoComponents?.scanningFormat)
      return `${fulfilledVideoComponents?.scanningFormat
        ?.split(':')
        .slice(-1)[0]
        .toUpperCase()}`;
    else return '';
  }
  return '';
};

export const ratingsGetter = (params: any) => {
  const offering = params.data ? params.data.offering : params.offering;
  if (offering?.ratings?.length > 0) {
    return getRatings(offering.ratings);
  }
  return null;
};

export const fetchMVPLanguages = (params: ValueGetterParams) => {
  let mvpDetail= milestoneDetailGetter(params);
  const key = params?.colDef?.cellRendererParams?.key;
  if(mvpDetail?.length>0){
    if(mvpDetail.startsWith('MVP'))
      mvpDetail = mvpDetail.substring(mvpDetail.indexOf('-')+1).trim();
    const languages= mvpDetail.split('|')
    for(const lang of languages){
      if(lang.includes(key)){
        const [_, value]= lang.split(':');
        return value.trim();
      }
    }
  }
  return '';
}
import { create } from 'zustand';
import { initialSearchBarConfigureValues, iSearchBarConfigureState } from './models';

export const useSearchBarConfigureStore = create<iSearchBarConfigureState>()(
    (set, get) => ({
        ...initialSearchBarConfigureValues,
        ToggleSearchBarConfigureModalOpen: () => {
            set({ searchBarConfigureModalOpen: !get().searchBarConfigureModalOpen });
        },
        TogglePlatformSelect: () => {
            set({ showPartnerSelect: !get().showPartnerSelect });
        },
        TogglePartnerSelect: () => {
            set({ showPlatformSelect: !get().showPlatformSelect });
        },
        ToggleContentSourceSelect: () => {
            set({ showContentSourceSelect: !get().showContentSourceSelect });
        },
        ToggleOfferingTypesSelect: () => {
            set({ showOfferingTypesSelect: !get().showOfferingTypesSelect });
        },
        ToggleEntityTypeSelect: () => {
            set({ showEntityTypeSelect: !get().showEntityTypeSelect });
        },
        ToggleMilestoneSelect: () => {
            set({ showMilestoneSelect: !get().showMilestoneSelect });
        },
        ToggleMilestoneStatusSelect: () => {
            set({ showMilestoneStatusSelect: !get().showMilestoneStatusSelect });
        },
        ToggleDeletedAssetsSelect: () => {
            set({ showDeletedAssetsSwitch: !get().showDeletedAssetsSwitch });
        },
        ToggleExpiredAssetsSelect: () => {
            set({ showExpiredAssetsSwitch: !get().showExpiredAssetsSwitch });
        },
    })
);
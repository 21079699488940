import { useEffect, useState } from 'react';
import { ColDef, GridOptions, GroupCellRendererParams } from 'ag-grid-community';
import { ComponentDetailsRendererGrid } from './_componentDetailsRendererGrid';
import useAxios from '../../services/axios/axios';
import { useConfigStore } from '../../app/store';
import { SetLinearValueGetters } from './_columnDefs/linear/linearColumnDefs';
import _ from 'lodash';
import { useAppConfigManager } from '../../components/AppConfigs';
import { columnState as componentColumnState } from './_componentColumnState';
import {
  componentDetailGetter,
  componentStatusGetter
} from './_columnDefs/linear/linearValueGetters';
export const ExpandableComponentsRenderer = (
  params: GroupCellRendererParams,
  gridOptions: GridOptions
) => {
  const [components, setComponents] = useState([]);
  const [columnState, setColumnState] = useState([] as any); // TODO: Update 'any' type
  const componentValueMapper = useAppConfigManager.getState().componentValueMapper;

  async function fetchData() {
    const { get } = useAxios();

    const baseURL =
      useConfigStore.getState().config.baseApiGatewayUrl +
      `/rest/v1/schedules/componentView/${params.data.id}`;

    const data = await get({
      url: baseURL
    });
    if (data && data.length > 0) gridOptions.columnDefs = createColumnDefs(data);
    gridOptions.columnDefs = _.uniqBy(gridOptions.columnDefs, 'field');
    setComponents(data);
    setColumnState(componentColumnState);
  }

  useEffect(() => {
    fetchData();
  }, []);

  let content = (
    <ComponentDetailsRendererGrid
      gridOptions={gridOptions}
      data={components}
      columnState={columnState}
      pinned=""
    />
  );

  type ComponentValueMapping = {
    headerName: string;
    field: string;
    cellRendererParams: any;
    valueGetter?: string;
  };

  const createColumnDefs = (data: any) => {
    let columnDefinitions: any = [
      {
        field: 'id',
        headerName: 'Component ID',
        sortable: true,
        suppressMenu: false,
        sortingOrder: ['asc', 'desc', null],
        wrapHeaderText: true,
        filterParams: {
          buttons: ['reset']
        },
        cellStyle: {
          justifyContent: 'center'
        },
        resizable: true
      }
    ];
    data.forEach((element: any) => {
      Object.keys(element.details.component).forEach((key: any) => {
        const mColDef: ComponentValueMapping =
          componentValueMapper[key.toLowerCase() as keyof typeof componentValueMapper];
        const columnField = mColDef.field;
        const columnHeader = mColDef.headerName;
        const columnValueGetter = mColDef.valueGetter;
        const cellRendererParams = mColDef.cellRendererParams;
        const columnDefinition: ColDef = {
          field: `${columnField}`,
          headerName: `${columnHeader}`,
          cellRendererParams,
          sortable: true,
          suppressMenu: false,
          sortingOrder: ['asc', 'desc', null],
          wrapHeaderText: true,
          filterParams: {
            buttons: ['reset']
          },
          cellStyle: {
            justifyContent: 'center'
          },
          resizable: true
        };
        if (columnValueGetter) {
          columnDefinition.valueGetter = columnValueGetter;
        }
        columnDefinitions.push(columnDefinition);
      });
    });
    // Status Columns
    columnDefinitions.push({
      field: 'status.scheduled.status',
      valueGetter: componentStatusGetter,
      cellRendererParams: { field: 'scheduled' },
      headerName: `Scheduled Status`,
      sortable: true,
      suppressMenu: false,
      sortingOrder: ['asc', 'desc', null],
      wrapHeaderText: true,
      filterParams: {
        buttons: ['reset']
      },
      cellStyle: {
        justifyContent: 'center'
      },
      resizable: true
    });
    columnDefinitions.push({
      field: 'status.delivered.status',
      valueGetter: componentStatusGetter,
      cellRendererParams: { field: 'delivered' },
      headerName: `Delivery Status`,
      sortable: true,
      suppressMenu: false,
      sortingOrder: ['asc', 'desc', null],
      wrapHeaderText: true,
      filterParams: {
        buttons: ['reset']
      },
      cellStyle: {
        justifyContent: 'center'
      },
      resizable: true
    });
    columnDefinitions.push({
      field: 'status.received.status',
      valueGetter: componentStatusGetter,
      cellRendererParams: { field: 'received' },
      headerName: `Receipt Status`,
      sortable: true,
      suppressMenu: false,
      sortingOrder: ['asc', 'desc', null],
      wrapHeaderText: true,
      filterParams: {
        buttons: ['reset']
      },
      cellStyle: {
        justifyContent: 'center'
      },
      resizable: true
    });
    columnDefinitions.push({
      field: 'component.detail',
      valueGetter: componentDetailGetter,
      cellRendererParams: {
        field: 'componentType'
      },
      headerName: `Component Detail`,
      sortable: true,
      suppressMenu: false,
      sortingOrder: ['asc', 'desc', null],
      wrapHeaderText: true,
      filterParams: {
        buttons: ['reset']
      },
      cellStyle: {
        justifyContent: 'center'
      },
      resizable: true
    });
    SetLinearValueGetters(columnDefinitions);
    return columnDefinitions;
  };

  if (params.pinned === 'left') {
    content = <></>;
  } else if (params.pinned === 'right') {
    content = <></>;
  }
  return <>{components.length > 0 ? content : <></>}</>;
};

export interface iViewState {
  grid: any; //
  navigate: boolean;
  SetChartActive: (payload: boolean) => Promise<void>;
  SetGrid: (payload: any) => void;
  SetNavigate: (value: boolean) => void;
}

export const initialViewState: iViewState = {
  grid: {},
  navigate: false,
  SetChartActive: async (payload: boolean) => {},
  SetGrid: (payload: any) => {},
  SetNavigate: (value: boolean) => {},
};

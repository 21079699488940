import { Accordion, Group, Text, useComputedColorScheme, useMantineTheme } from '@mantine/core';
// import { AccordionProviderProps } from '@mantine/core/lib/Accordion/AccordionProvider';
import { ChevronRight } from 'tabler-icons-react';
import { CSSProperties } from 'react';
import {useTID} from '../../helpers/useTestId'
interface iDetailAccordionItemProps {
  name: string;
  title: string;
  title2?: string;
  delivered?: boolean;
  styles: CSSProperties;
  status?: iDetailFactsStatus;
  testid: string;
  children: React.ReactNode;
}

interface iDetailFactsStatus {
  codes: iDetailFactsStatusCode[];
  type: string;
}
interface iDetailFactsStatusCode {
  code: string;
  detail: string;
}

export const DetailAccordionItem = (props: iDetailAccordionItemProps) => {
  const TID = useTID('DetailAccordionItem', props.testid);
  const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');

  function border() {
    return `1px solid ${
      colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[4]
    }`;
  }

  let titles = [
    'Images Delivered',
    'Meta Delivered',
    'Mezz Delivered',
    'Offering Delivered',
    'Latest Manifestations'
  ];

  function backgroundColor() {
    // if (props.status) {
    //   const included = ['error', 'failure'];
    //   const status = included.includes(props.status?.type)
    //     ? props.status?.type
    //     : 'pending';
    //   if (status === 'pending') {
    //     return colorScheme === 'dark'
    //     ? darken(theme.colors.gray[8], 0.3)
    //     : lighten(theme.colors.gray[3], 0.8)
    //   } 
    //   return colorScheme === 'dark'
    //     ? darken(statusColor[status], 0.5)
    //     : lighten(statusColor[status], 0.8);
    // } else if (!titles.includes(props.title)){
    //   return colorScheme === 'dark'
    //     ? darken(theme.colors.gray[8], 0.3)
    //     : lighten(theme.colors.gray[3], 0.8)
    // }
    return colorScheme === 'dark'
        ? '#25262B'
        : '#f9f9f9'
  }

  const size = titles.includes(props.title) ? 'sm' : 'xs';
  return (
    <Accordion.Item value={props.name} style={props.styles} {...TID}>
      <Accordion.Control
        chevron={
          <ChevronRight size={16} strokeWidth={2} color={colorScheme === 'dark' ? 'white' : 'gray'} />
        }
        style={{
          backgroundColor: backgroundColor(),
          border: border(),
          borderRadius: 6,
          color: colorScheme === 'dark' ? 'white' : 'gray',
          padding: 6
        }}
      >
        <Group justify="space-between">
          <Text
            size={size}
            style={{
              color: colorScheme === 'dark' ? 'white' : 'gray'
            }}
          >
            {props.title}
          </Text>
          {props.title2 && (
            <Text
              size={size}
              style={{
                color: colorScheme === 'dark' ? 'white' : 'gray'
              }}
            >
              {props.title2}
            </Text>
          )}
        </Group>
      </Accordion.Control>
      <Accordion.Panel style={{ overflow: 'auto' }}>{props.children}</Accordion.Panel>
    </Accordion.Item>
  );
};

import { Cascader } from '../Cascader/Cascader';
import { useAppConfigManager } from '../AppConfigs';
import { useSearchStore } from '../../containers/Search/store';

export const CascaderDestination = (props: any) => {
  const { Destinations } = useSearchStore((state) => state.search);
  const options = useAppConfigManager((state) => state.GetDropDown('destinations'));

  return (
    <Cascader
      id="cascaderDestinations"
      data={Destinations}
      field="Destinations"
      name="Destination"
      noOpen={props.noOpen}
      options={options}
    />
  );
};

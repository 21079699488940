import { ColDef } from 'ag-grid-community';
export interface iFootprint {
  id: string;
  columnDefs: ColDef[];
  searchOptions: string[];
  type: string;
  value: string;
  label: string;
  default: boolean;
}
export interface iFootprintState {
  footprints: iFootprint[];
  active: iFootprint;
  LoadFootprints: () => void;
  SetActiveFootprint: (id: string | null, onLoad?: boolean) => void;
}

export const initialFootprintValues: iFootprintState = {
  footprints: [] as iFootprint[],
  active: {
    id: 'footprint-loading',
    columnDefs: [],
    searchOptions: [],
    type: '',
    value: 'Loading...',
    label: 'Loading...',
    default: false
  } as iFootprint,
  LoadFootprints: () => undefined,
  SetActiveFootprint: (id: string | null, onLoad?: boolean) => undefined
};

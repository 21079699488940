import { getAxiosConfig } from './../axios/config';
import axios from 'axios';
import { StatusCode } from '../../components/StatusCodeDictionary/models';
import { useConfigStore } from '../../app/store';

const AppConfigType = "statusCodes";

export const StatusCodesService = {

  getStatusCodes: async (): Promise<StatusCode[]> => {
    const baseURL = useConfigStore.getState().config.baseApiGatewayUrl;
    const { headers } = getAxiosConfig();
    const res = await axios.get(baseURL + '/rest/appconfigs/v1?type=statusCodes', {
      headers
    });

    return res.data;
  },

  upsertStatusCodes: async (statusCode: StatusCode): Promise<StatusCode> => {
    const baseURL = useConfigStore.getState().config.baseApiGatewayUrl;

    // Add Id, if it does not already have one.
    if(statusCode.id == undefined || statusCode.id.length == 0) {
      statusCode.id = statusCode.statusCode;
    }

    if(statusCode._id) {
      delete statusCode._id;
    }

    // Set App.config schema type
    statusCode.type = AppConfigType;

    const { headers } = getAxiosConfig();
    const res = await axios.put(
      baseURL + `/rest/appconfigs/v1/` + statusCode.id,
      statusCode,
      {
        headers
      }
    );
    return res.data;
  }
};

import { Cascader } from '../Cascader/Cascader';
import { useAppConfigManager } from '../AppConfigs';
import { useSearchStore } from '../../containers/Search/store';
import { useFootprintStore } from '../../containers/Footprints/store';

export const CascaderMarket = (props: any) => {
  const { Market } = useSearchStore((state) => state.search);
  const footprint = useFootprintStore.getState().active.id;
  const options = useAppConfigManager((state) => state.GetDropDown('markets'));

  const filterOptions = () => {
    if (options) {
      const filteredOptions = options.filter(i => i.footprint?.includes(footprint))
      if (filteredOptions.length > 0) {
        filteredOptions.forEach(op => {
          if (op.children) {
            op.children = op.children.filter(i => i.footprint?.includes(footprint))
            if (op.children.length > 0) {
              op.children.forEach(child => {
                if (child.children) {
                  child.children = child.children.filter(i => i.footprint?.includes(footprint))
                }
              })
            }
          }
        })
      }
      return filteredOptions
    }
    return []
  }

  return (
    <Cascader
      id="cascaderMarkets"
      data={Market}
      field="Market"
      name="Market"
      noOpen={props.noOpen}
      options={filterOptions()}
    />
  );
};

import useAxios from '../../services/axios/axios';
import {
  constructAssetObject,
  constructMilestoneObject,
  constructPartialAssetObject
} from './fetchAssetRowData';
import { iSearch } from '../../containers/Search/models';
import { useConfigStore } from '../../app/store';
import { useAppConfigManager } from '../../components/AppConfigs';
import { getStatusCodeString } from '../../utils/utilities';

export const fetchAssetStatusCount = async (startDate: Date | null, endDate: Date | null, searchState: iSearch, milestoneName: string) => {
  const { post } = useAxios();
  const baseURL = useConfigStore.getState().config.baseApiGatewayUrl + '/graphql/search';

  const query = constructQuery(startDate, endDate, searchState, milestoneName);
  if (!query) {
    return 0
  }

  const schema = {
    query: query
  };

  const result = await post({
    url: baseURL,
    data: JSON.stringify(schema)
  });

  if (
    result &&
    result.data &&
    result.data.offeringAssetStatusCount &&
    result.data.offeringAssetStatusCount.records
  ) {
    return result.data.offeringAssetStatusCount.records;
  }
  return 0;
};

export const constructQuery = (startDate: Date | null, endDate: Date | null, searchState: iSearch, milestoneName: string) => {
  const queryFilters = constructFilters(startDate, endDate, searchState, milestoneName);
  if (!queryFilters) {
    return ''
  }

  const query = `query {
          offeringAssetStatusCount(
            ${queryFilters}
          ) {
            records {
              status
              count
            }
          }
        }`;

  return query;
};

const constructFilters = (startDate: Date | null, endDate: Date | null, filters: any, milestoneName: string) => {
  //TODO investigate further
  if (milestoneName === 'offeringsAsset_footprint') {
    milestoneName = "offeringsAsset-footprint"
  }
  const milestoneString = constructMilestoneObject(filters.Milestone, filters.MilestoneStatus)

  let partialSearchInput: string = '';
  if (filters.SearchInput) {
    let searchInputs;
    if (filters.SearchInputField === 'keyword') {
      let copy = filters.SearchInput;
      let newInput = copy.replaceAll('"', '\\"');
      searchInputs = newInput;
    } else {
      searchInputs = filters.SearchInput.split(' ');
      if (searchInputs.length > 1) {
        searchInputs = searchInputs.join('","');
      }
    }
    partialSearchInput = searchInputs.toString();
  }
  
  let tasks = ''
  if (filters.AdMarkers === 'true'){
    tasks = `
    tasks:{
      details:{
        fulfilledMediaMarkerCount: {
          value: 1
          operation: gte
        }
      }
    }
    `
  } 
  else if (filters.AdMarkers === 'false'){
    tasks = `
    tasks:{
      details:{
        fulfilledMediaMarkerCount: {
          value: 1
          operation: lt
        }
      }
    }
    `
  }

  const assetObject = constructAssetObject(startDate, endDate, filters);
  let partialObject = '';
  if (partialSearchInput && filters.SearchInputField === 'keyword') {
    partialObject = constructPartialAssetObject(partialSearchInput);
  }

  const taskValueMapper = useAppConfigManager.getState().taskValueMapper
  const statusCodeString = getStatusCodeString(filters.StatusCodes, taskValueMapper)

  if (milestoneName === 'offeringAsset-footprint' || milestoneName === 'offeringAsset_footprint') {
    // Backend team added an 's' to the footprint milestone name for this api instead of just "api name"-footprint
    milestoneName = 'offeringsAsset-footprint'
  }

  if (!milestoneString && !assetObject && !tasks && !statusCodeString) {
    return ''
  }

  const queryFilters = `filters: {
        ${statusCodeString}
        ${milestoneString}
        ${assetObject}
        ${partialObject}
        ${tasks}
        byMilestone: "${milestoneName}"
    }`;
  return queryFilters;
};

import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { persist } from 'zustand/middleware';
import { iAsideSettings, initialAsideSettingsValues } from './models';

export const useAsideSettingsStore = create<iAsideSettings>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialAsideSettingsValues,
        SetShow: (newState: string) => {
          set({ Show: newState });
        }
      }),
      {
        name: 'disco-aside-settings', // unique name
        // storage: createJSONStorage(() => sessionStorage) // (optional) by default, 'localStorage' is used
      }
    )
  )
);

import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import {
  StatusCode,
  StatusCodeStore,
  StatusCodeFootprint,
  initialStatusCode,
  initialStatusCodeFootprint
} from './models';
import { StatusCodesService } from '../../services/statuscodes/statusCodesService';
import { GridApi } from 'ag-grid-community';
import { Option, StatusHierachy } from './StatusHierarchy';
import { AppConfigsService } from '../../services/appConfigs/appConfigsService';

export const useStatusCodeStore = create<StatusCodeStore>()(
  devtools((set, get) => ({
    statusCode: initialStatusCode,
    statusCodeCount: 0,
    statusCodes: [],
    searchStatusCode: '',
    includeDepreciated: false,
    formIsEditing: false,
    footprints: [] as StatusCodeFootprint[],
    footprintIndex: -1,
    footprintIsDirty: false,
    fullGrid: false,
    gridAPI: undefined,
    hierarchy: [] as Option[],
    DeleteFootprint: () => {
      let fp = get().footprints;
      let idx = get().footprintIndex;
      fp.splice(idx, 1);
      set({ footprints: fp });
      set({ footprintIsDirty: true });
    },
    FetchStatusCodes: async () => {
      await StatusCodesService.getStatusCodes().then(async (values) => {
        set({ statusCodes: values });
      });
    },
    GetFootprintRow: (index: number) => {
      let fps = get().footprints;
      let result = initialStatusCodeFootprint;
      if (index > -1) {
        result = fps[index];
      }
      return result;
    },
    GetHierarchy: () => {
      const hierarchy = StatusHierachy();
      try {
        AppConfigsService.upsertAppConfig({
          id: 'dropdown:statusHierarchy',
          type: 'dropdown:statusHierarchy',
          data: hierarchy
        });
      } catch (err) {}
      return hierarchy;
    },
    GetStatusCode: (urn: string) => {
      let sc = get().statusCodes;
      sc = sc.filter((f) => {
        return f.statusCode === urn;
      });
      if (sc.length > 0) {
        return sc[0];
      }
      return {} as StatusCode;
    },
    ResetFootprints: () => {
      let sc = get().statusCode;
      set({ footprints: sc.footprints });
    },
    SetGridAPI: (grid: GridApi) => {
      set({ gridAPI: grid });
    },
    SetFootprint: (newFootprint: StatusCodeFootprint) => {
      let fp = get().footprints;
      let index = get().footprintIndex;
      if (index < 0) {
        // Push a new footprint entry
        fp.push(newFootprint);
      } else {
        // Update a footprint entry
        fp = Object.assign(
          fp,
          fp.map((f, idx) => (idx === index ? newFootprint : f))
        );
      }
      set({ footprints: fp });
      set({ footprintIndex : -1 })
      set({ footprintIsDirty: true });
    },
    SetFootprints: (newFootprints: StatusCodeFootprint[]) => {
      set({ footprints: newFootprints });
    },
    SetFootprintIsDirty: (explicit: boolean) => {
      set({ footprintIsDirty: explicit });
    },
    SetFootprintsIndex: (newFootprintIndex: number) => {
      set({ footprintIndex: newFootprintIndex });
    },
    SetIncludeDepreciated: (explicit?: boolean) => {
      if (explicit !== undefined) {
        set({ includeDepreciated: explicit });
      } else {
        set({ includeDepreciated: !get().includeDepreciated });
      }
    },
    SetStatusCode: (newStatusCode: StatusCode) => {
      if (newStatusCode.overridden) {
        set({ statusCode: newStatusCode });
        return;
      }
      set({ footprints: newStatusCode.footprints });
      let newSC = Object.assign({}, initialStatusCode, newStatusCode);
      let parts = newSC.statusCode.split(':');
      if (newSC.appService === '') {
        newSC.appService = parts[1];
      }
      if (newSC.appName === '') {
        newSC.appName = parts[2];
      }
      if (newSC.appFunction === '') {
        newSC.appFunction = parts[3];
      }
      if (newSC.status === '') {
        newSC.status = parts[4];
      }
      if (newSC.statusNumber === '0000') {
        newSC.statusNumber = parts[5];
      }
      set({ statusCode: newSC });
      set({ footprintIsDirty: false });
    },
    SetStatusCodeCount: (count: number) => {
      set({ statusCodeCount: count });
    },
    SetSearchStatusCode: (newSearchStatusCode: string) => {
      set({ searchStatusCode: newSearchStatusCode });
    },
    ToggleFormIsEditing: (explicit?: boolean) => {
      if (explicit !== undefined) {
        set({ formIsEditing: explicit });
      } else {
        set({ formIsEditing: !get().formIsEditing });
      }
    },
    ToggleFullGrid: (explicit?: boolean) => {
      if (explicit !== undefined) {
        set({ fullGrid: explicit });
      } else {
        set({ fullGrid: !get().fullGrid });
      }
    },
    UpdateStatusCode: (newStatusCode: StatusCode) => {
      set({ statusCode: newStatusCode });
      set({
        statusCodes: get().statusCodes.map((item) =>
          item.id === newStatusCode.id ? newStatusCode : item
        )
      });
    }
  }))
);

import { Box, Group, Text } from '@mantine/core';
import { DataPanelAccordion } from '../../DataPanelAccordion';
import { iDataPanelProcessProps } from '../models';
import { NoDataToDisplay } from '../../MilestoneDataPanels/NoDataToDisplay';
import { DetailHistoryColumnDefs, DetailHistoryColumnDefsVideo } from './historyColumnDefs';
import { useDataPanelAccordionStore } from '../../DataPanelAccordion/store';
import { DetailGridLatest } from './_detailGridLatest';
import dayjs from 'dayjs';

export const ProcessTaskGrids = (props: iDataPanelProcessProps) => {
  const { data, children, width, parent, footprint, statusObject } = props;

  const defaultItem = useDataPanelAccordionStore.getState().DefaultAccordionItem
  let status
  if (statusObject) {
    status = data.status[parent]
  } else {
    status = data.milestones?.find(
      (milestone: { type: string; name: string }) =>
        milestone.type === 'Process' && milestone.name === parent
    );
  }
  let videoColumnDefs = DetailHistoryColumnDefs
  if (parent === 'Prep' || parent === 'Fulfill'){
    videoColumnDefs = DetailHistoryColumnDefsVideo
  }
  let formattedDate
  if (status?.audit){
    formattedDate = dayjs(status.audit.updatedAt).format('MMM DD, YYYY (hh:mm a | [EST])');
  }
  return (
    <Box>
      {data === null && <NoDataToDisplay />}
      {status?.audit && (
        <Group
          style={{paddingLeft:6, paddingBottom:10}}
        >
          <Text>
            Process Updated:
          </Text>
          <Text
            fw={700}
          >
            {formattedDate}
          </Text>
        </Group>
      )}
      <DataPanelAccordion defaultValue={defaultItem}>
        {children?.includes('Offering') && (
          <DetailGridLatest
            data={data}
            width={width}
            child="Offering"
            parent={parent}
            coldefs={DetailHistoryColumnDefs}
            footprint={footprint ? footprint : ''}
            statusObject={statusObject}
          />
        )}
        {children?.includes('Metadata') && (
          <DetailGridLatest
            data={data}
            width={width}
            child="Metadata"
            parent={parent}
            coldefs={DetailHistoryColumnDefs}
            footprint={footprint ? footprint : ''}
            statusObject={statusObject}
          />
        )}
        {children?.includes('Image') && (
          <DetailGridLatest
            data={data}
            width={width}
            child="Image"
            parent={parent}
            coldefs={DetailHistoryColumnDefs}
            footprint={footprint ? footprint : ''}
            statusObject={statusObject}
          />
        )}
        {children?.includes('Video') && (
          <DetailGridLatest
            data={data}
            width={width}
            child="Video"
            parent={parent}
            coldefs={videoColumnDefs}
            footprint={footprint ? footprint : ''}
            statusObject={statusObject}
          />
        )}
        {children?.includes('Asset') && (
          <DetailGridLatest
            data={data}
            width={width}
            child="Asset"
            parent={parent}
            coldefs={DetailHistoryColumnDefs}
            footprint={footprint ? footprint : ''}
            statusObject={statusObject}
          />
        )}
        {children?.includes('Prep') && (
          <DetailGridLatest
            data={data}
            width={width}
            child="Prep"
            parent={parent}
            coldefs={DetailHistoryColumnDefs}
            footprint={footprint ? footprint : ''}
            statusObject={statusObject}
          />
        )}
        {children?.includes('Fulfill') && (
          <DetailGridLatest
            data={data}
            width={width}
            child="Fulfill"
            parent={parent}
            coldefs={DetailHistoryColumnDefs}
            footprint={footprint ? footprint : ''}
            statusObject={statusObject}
          />
        )}
        {children?.includes('Delivery') && (
          <DetailGridLatest
            data={data}
            width={width}
            child="Delivery"
            parent={parent}
            coldefs={DetailHistoryColumnDefs}
            footprint={footprint ? footprint : ''}
            statusObject={statusObject}
          />
        )}
        {children?.includes('Receipt') && (
          <DetailGridLatest
            data={data}
            width={width}
            child="Receipt"
            parent={parent}
            coldefs={DetailHistoryColumnDefs}
            footprint={footprint ? footprint : ''}
            statusObject={statusObject}
          />
        )}
        {children?.includes('Staging') && (
          <DetailGridLatest
            data={data}
            width={width}
            child="Staging"
            parent={parent}
            coldefs={DetailHistoryColumnDefs}
            footprint={footprint ? footprint : ''}
            statusObject={statusObject}
          />
        )}
      </DataPanelAccordion>
    </Box>
  );
};

import { Option as OptionMarket } from '../CascaderMarket/_markets';
import { Option as OptionNetwork } from '../CascaderNetwork/_networks';
import { Option as OptionStatusCode } from '../CascaderStatusCode/_statusCodes';
import {
  iFieldMapping,
  iSelectDataSource,
  iSelectSearchInputsSource
} from '../ListSortable/models';

export interface iAppConfig {
  id: string;
  type: string;
  footprint?: string[];
  data: any;
}

export type TaskValueMapper = {
  offeringplanready: string;
  metadatafulfillready: string;
  imagefulfillready: string;
  pickpackrules: string;
  csdelivery: string;
  ccready: string;
  offeringdvready: string;
  mezzcreation: string;
  offeringdelivery: string;
  metadelivery: string;
  imagedelivery: string;
  mezzdelivery: string;
  offeringbmmvalidate: string;
  metacontentbmmvalidate: string;
  metaeditbmmvalidate: string;
  mezzgtpvalidate: string;
  offeringbmmjobmonitor: string;
  metacontentbmmjobmonitor: string;
  metaeditbmmjobmonitor: string;
  mezzgtpjobmonitor: string;
  acquireprep: string;
  acquiretransform: string;
  mediadelivery: string;
  publish: string;
};

export type ComponentValueMapper = {
  type: any;
  language: any;
  soundfieldgroupgype: any;
  textusagetype: any;
  componenttype: any;
  audiocontentkind: any;
  writtenlanguage: any;
  spokenlanguage: any;
};

export const initialAppConfigValues: iAppConfig = {
  id: '',
  type: '',
  data: {}
};

export type VariableObject = {
  [key: string]: iSelectDataSource[];
};

export type FieldMappings = {
  [key: string]: iFieldMapping[];
};

export interface iAppConfigManager {
  dropdowns: VariableObject;
  fieldMappings: FieldMappings;
  taskValueMapper: TaskValueMapper;
  componentValueMapper: ComponentValueMapper;
  isLoaded: boolean;
  queryMarkets: OptionMarket[];
  queryNetworks: OptionNetwork[];
  queryDestinations: OptionNetwork[]; // works the same
  queryStatusCodes: OptionStatusCode[];
  GetConfig: (type: string) => void;
  GetDropDown: (name: string, footprint?: string) => iSelectDataSource[];
  GetSearchInputs: (footprint?: string) => iSelectSearchInputsSource[];
  GetFieldMap: (name: string) => iFieldMapping[];
  ValueMapper: (dropdown: string, value: string) => string;
  ToggleIsLoading: (explicit?: boolean) => void; //
}

export const initialAppConfigManagerValues: iAppConfigManager = {
  dropdowns: {} as VariableObject,
  fieldMappings: {} as FieldMappings,
  taskValueMapper: {} as TaskValueMapper,
  componentValueMapper: {} as ComponentValueMapper,
  isLoaded: false,
  queryMarkets: [],
  queryNetworks: [],
  queryDestinations: [],
  queryStatusCodes: [],
  GetConfig: (type: string) => {},
  GetDropDown: (name: string, footprint?: string) => [] as iSelectDataSource[],
  GetSearchInputs: (footprint?: string) => [] as iSelectSearchInputsSource[],
  GetFieldMap: (name: string) => [] as iFieldMapping[],
  ValueMapper: (dropdown: string, value: string) => '',
  ToggleIsLoading: (explicit?: boolean) => {}
};

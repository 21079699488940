import { initialStatusCode, StatusCode } from './models';
import { useForm } from '@mantine/form';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Group,
  ScrollArea,
  Select,
  Text,
  Textarea,
  TextInput,
  // useMantineTheme,
  useComputedColorScheme,
  darken,
  lighten
} from '@mantine/core';
import { useEffect } from 'react';
import { StatusIcon, statusNames } from '../Status';
import { StatusCodesService } from '../../services/statuscodes/statusCodesService';
import { useStatusCodeStore } from './store';
// import useStyles from '../../theme/globalStyles';
import { globalColors as colors } from '../../theme';
import { SCDFootprints } from './_scdFootprints';

const httpRegex =
  /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/i;

export const StatusCodeForm = () => {
  const statusCode = useStatusCodeStore((state) => state.statusCode);
  const footprints = useStatusCodeStore((state) => state.footprints);
  const footprintIsDirty = useStatusCodeStore((state) => state.footprintIsDirty);
  const ResetFootprints = useStatusCodeStore((state) => state.ResetFootprints);
  const SetFootprintIsDirty = useStatusCodeStore((state) => state.SetFootprintIsDirty);
  const SetStatusCode = useStatusCodeStore((state) => state.SetStatusCode);
  const UpdateStatusCode = useStatusCodeStore((state) => state.UpdateStatusCode);
  // const { classes } = useStyles();
  // const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');

  const form = useForm({
    initialValues: statusCode,
    validate: {
      appService: (value) =>
        value.trim().length > 2 ? null : 'App Service at least 3 characters',
      statusCode: (value) => {
        const cleanValue = value.trim();
        if (cleanValue.length < 12) return 'Status Code URN too small';
        // TODO prevents editting as written, needs to compare to existing, but not selected one
        // if (statusCodes.some((item) => item.statusCode === cleanValue)) {
        //   return 'Status Code must be unique.';
        // }
      },
      details: (value) => (value.trim().length > 4 ? null : 'Details too small'),
      supportLink: (value) => {
        if (!form.values.enableBPAlert) return null;
        return httpRegex.test(value) ? null : 'Invalid http link';
      }
    }
  });

  useEffect(() => {
    form.setValues(statusCode);
    form.resetDirty(statusCode);
  }, [statusCode]);

  function onReset() {
    form.setValues(statusCode);
    form.resetDirty(statusCode);
    ResetFootprints();
    SetFootprintIsDirty(false);
  }

  function onCancel() {
    SetStatusCode(initialStatusCode);
  }

  function isDirty() {
    if (form.isDirty() || footprintIsDirty) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    form.setValues({ footprints: footprints });
  }, [footprints]);

  function trimInputTextValues(values: StatusCode) {
    values.appService = values.appService.trim();
    values.details = values.details.trim();
    values.statusCode = values.statusCode.trim();
    values.description = values.description.trim();
  }

function conCatStatusCode(values: StatusCode) {
  if(!values.overridden) {
    values.statusCode = `urn:${values.appService}:${values.appName}:${values.appFunction}:${values.status}:${values.statusNumber}`;
  }
}

  if (statusCode.statusCode === '') {
    return <></>;
  }

  return (
    <Box style={{ height: '100%' }}>
      <form
        onSubmit={form.onSubmit((values: any) => {
          // Trim string values, before saving
          trimInputTextValues(values);
          conCatStatusCode(values);
          delete values.footprintsIsDirty;
          StatusCodesService.upsertStatusCodes(values)
            .then((values) => {
              UpdateStatusCode(values);
              onReset();
              SetFootprintIsDirty(false);
            })
            .catch((err) => {
              console.error(
                `Failed to save statuscode to database. Details: ${err.stack}`
              );
            });
        })}
      >
        <ScrollArea style={{ maxHeight: 640 }} h={640} type="auto">
          <Group
            justify="space-between"
            style={{ 
              maxWidth: '98%', 
              backgroundColor: colorScheme === 'dark'
                ? darken(colors.wbdblue[9], 0.4)
                : lighten(colors.wbdblue[1], 0.5),
              border: '1px solid',
              borderColor:
                colorScheme === 'dark'
                  ? darken(colors.wbdblue[6], 0.7)
                  : colors.titan[5],
              borderRadius: 'var(--mantine-radius-md)',
              padding: 8,
              // margin: -4,
              height: '100%' 
            }}
            mb={12}
            // className={classes.baseBox}
          >
            <Group>
              <Box
                style={{
                  background:
                    colorScheme === 'dark' ? colors.wbdblue[9] : '#E6EFFF',
                  borderRadius: 6,
                  paddingLeft: 4,
                  paddingRight: 4,
                  paddingTop: 2
                }}
              >
                <StatusIcon status={form.values.statusCode} />
              </Box>
              { !form.values.overridden && (
                <Text fw={950} style={{ fontSize: 18 }}>
                  {form.values.statusCode}
                </Text>
                )}
              { form.values.overridden && (
                <Group>
                <TextInput
                  placeholder="the Status Code"
                  {...form.getInputProps('statusCode')}
                  size="sm"
                  mb={12}
                  style={{ minWidth: '400px', marginBottom: -6}}
                />
                </Group>
              )}
            </Group>
            <Group>
              <Checkbox
                size="xs"
                mb={12}
                label="Override"
                checked={form.values.overridden}
                {...form.getInputProps('overridden')}
                style={{marginTop:-8, marginBottom:-8, marginRight: 16}}
              />
              <Checkbox
                size="xs"
                mb={12}
                label="Deprecated"
                checked={form.values.deprecated}
                {...form.getInputProps('deprecated')}
                style={{marginTop:-8, marginBottom:-8}}
              />
            </Group>
          </Group>
          <Group justify="space-between" style={{ maxWidth: '98%' }}>
            <TextInput
              withAsterisk
              label="App Service"
              placeholder="the App Service"
              {...form.getInputProps('appService')}
              size="sm"
              mb={12}
              style={{ width: '12%' }}
            />
            <TextInput
              withAsterisk
              label="App Name"
              placeholder="the App Name"
              {...form.getInputProps('appName')}
              size="sm"
              mb={12}
              style={{ width: '12%' }}
            />
            <TextInput
              withAsterisk
              label="App Function"
              placeholder="the App Function"
              {...form.getInputProps('appFunction')}
              size="sm"
              mb={12}
              style={{ width: '22%' }}
            />
            <Select
              label="Status"
              data={statusNames}
              withAsterisk
              // rightSection={<StatusIcon status={getStatusName()} />}
              placeholder="the milestone status"
              {...form.getInputProps('status')}
              size="sm"
              style={{ width: '22%' }}
              mb={12}
            />
            <TextInput
              withAsterisk
              label="Number"
              placeholder="the statuscode number"
              {...form.getInputProps('statusNumber')}
              size="sm"
              mb={12}
              style={{ width: '12%' }}
            />
          </Group>
          <Group justify="space-between" style={{ maxWidth: '98%' }}>
            {SCDFootprints(form.values.footprints)}
          </Group>

          <Group justify="space-between" style={{ maxWidth: '98%' }}>
            <TextInput
              label="Badge Color"
              placeholder="badge Color"
              {...form.getInputProps('badgeColor')}
              size="sm"
              mb={12}
              style={{ width: '30%' }}
            />
            <TextInput
              label="Badge Text Color"
              placeholder="badge Text Color"
              {...form.getInputProps('badgeTextColor')}
              size="sm"
              mb={12}
              style={{ width: '30%' }}
            />
            <TextInput
              label="Badge Text"
              placeholder="badge Text"
              {...form.getInputProps('badgeText')}
              size="sm"
              mb={12}
              style={{ width: '30%' }}
            />
          </Group>
          <Group justify="space-between" style={{ maxWidth: '98%' }}>
            <Textarea
              withAsterisk
              label="Details"
              placeholder="the human readable details of the status code"
              {...form.getInputProps('details')}
              size="sm"
              mb={12}
              style={{ width: '100%' }}
            />
          </Group>
          <Group style={{ maxWidth: '98%' }}>
            <Textarea
              label="Description"
              placeholder="the additional description of the status code"
              {...form.getInputProps('description')}
              size="sm"
              mb={12}
              style={{ width: '100%' }}
            />
          </Group>
          <Divider
            size="sm"
            mb={12}
            label="Notification Settings"
            labelPosition="center"
          />
          <Checkbox
            size="sm"
            mb={12}
            label="Enable BigPanda Alert"
            checked={form.values.enableBPAlert}
            {...form.getInputProps('enableBPAlert')}
          />
          <Group justify="space-between" style={{ maxWidth: '98%' }}>
            <TextInput
              label="Support Link"
              placeholder="Support Link to use in Big Panda."
              {...form.getInputProps('supportLink')}
              size="sm"
              mb={12}
              style={{ width: '100%' }}
            />
          </Group>
        </ScrollArea>
        <Group justify="space-between" style={{ maxWidth: '98%' }}>
          <Button onClick={() => onCancel()} color="gray">
            Cancel
          </Button>
          <Group mt={12}>
            <Button onClick={onReset} color="red" disabled={!isDirty()}>
              Reset
            </Button>
            <Button type="submit" color="green" disabled={!isDirty()}>
              Update
            </Button>
          </Group>
        </Group>
      </form>
    </Box>
  );
};

import { forwardRef } from 'react';
import {
  CircleCheck,
  Clock,
  Clock2,
  RefreshDot, // ArrowRight RefreshDot  CircleHalf2  Disc  DotsCircleHorizontal  Gauge  Gps  History
  Archive,
  AlertTriangle,
  AlertOctagon,
  History,
  CircleOff,
  CircleDotted,
  DiscountCheck,
  Minus,
  CircleDashed,
  Ban
} from 'tabler-icons-react';
import { StatusColor } from './StatusColor';
// import { StatusNames, statusNames } from './index';
import { useTID } from '../../helpers/useTestId';
import { Box, Group, useMantineTheme, Text, useComputedColorScheme, lighten, darken } from '@mantine/core';
import { useStatusCodeStore } from '../StatusCodeDictionary/store';
import { GetThemedStatusColor } from './GetThemedStatusColor';

export interface iStatusIconProps {
  status: string;
  color?: string;
  size?: number;
  white?: boolean;
  dark?: boolean;
  testid?: string;
  disabled?: boolean;
  // badgeColor?: string; //     color of a badge
  // badgeText?: string; //      a badge's text
  // badgeTextColor?: string; // color of a badge's text
  // badge?: string; //          future use, named badge styles
}

export const StatusIcon = forwardRef<HTMLDivElement, iStatusIconProps>((props, ref) => {
  if (props.status === '') {
    return <></>;
  }

  // use statusCode URN if possible
  const statusCodes = useStatusCodeStore((state) => state.statusCodes);
  let statusName = props.status;
  const scParts = props.status.split(':');
  if (scParts.length > 2) {
    statusName = scParts[scParts.length - 2];
  }
  const statusCode = statusCodes.find((sc) => sc.statusCode === props.status);

  const { dark } = props;
  const statusColors = StatusColor();
  const TID = useTID('StatusIcon', props.testid || 'undefined');
  const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');
  const getIconColor = () => {
    if (props.white) {
      return '#fff';
    }
    if (!statusColors[statusName]){
      return '#ff3322'
    }
    // If Dark and Disabled
    if (colorScheme === 'dark' && dark && props.disabled) {
      return theme.colors.dark[6];
      // if dark and not disabled
    } else if (colorScheme === 'dark' && dark ) {
      return darken(statusColors[statusName], 0.4);
      // if light and disabled
    } else if (props.disabled) {
      return theme.colors.gray[5];
      // if light and not disabled
    } else {
      return lighten(statusColors[statusName], 0.25);
    }
  };

  const status: Record<string, React.FC> = {
    completed: CircleCheck,
    error: AlertOctagon,
    expired: Archive,
    failure: AlertTriangle,
    pending: Clock,
    waiting: Clock2,
    processing: RefreshDot,
    skipped: DiscountCheck,
    queued: History,
    inactive: CircleOff,
    deleted: CircleDotted,
    null: Minus,
    nodata: CircleDashed,
    na: Ban
  };

  const statusNameCheck = status[statusName] ? status[statusName] : status['na']
  const Icon: React.FC<iStatusIconProps> = statusNameCheck;
  const Color: string = getIconColor();
  const Size: number = props.size || props.status === 'inactive' ? 24 : 24;
  // let { badge, badgeColor, badgeTextColor, badgeText } = props;
  let badgeColor = '';
  let badgeTextColor = '';
  let badgeText = '';
  if (statusCode !== undefined) {
    // badge = statusCode.statusCode;
    badgeColor = GetThemedStatusColor(statusCode.badgeColor);
    badgeTextColor = GetThemedStatusColor(statusCode.badgeTextColor);
    badgeText = statusCode.badgeText;
  }
  if (badgeColor && badgeColor !== '') {
    return (
      <Group>
        <Icon {...TID} status={props.status} color={Color} size={Size} />
        {badgeText && (
          <Box
            style={{
              marginTop: -13,
              marginLeft: 11,
              borderRadius: 6,
              backgroundColor: badgeColor,
              fontSize: 9,
              paddingLeft: 3,
              paddingRight: 3,
              color: badgeTextColor,
              maxHeight: 16,
              textAlign: 'center',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              position: 'absolute',
              minWidth: '18px'
            }}
          >
            <Text style={{fontSize: 'inherit'}}>{badgeText}</Text>
          </Box>
        )}
      </Group>
    );
  }
  // if (props.badgeColor && props.badgeColor !== '') {
  //   return (
  //     <Group>
  //       <Icon {...TID} status={props.status} color={Color} size={Size} />
  //       <Box
  //         style={{
  //           marginTop: -10,
  //           marginLeft: -24,
  //           borderRadius: 6,
  //           backgroundColor: props.badgeColor,
  //           fontSize: 9,
  //           fontWeight: 750,
  //           paddingLeft: 4,
  //           paddingRight: 4,
  //           color: props.badgeTextColor
  //         }}
  //       >
  //         {props.badgeText}
  //       </Box>
  //     </Group>
  //   );
  // }
  return <Icon {...TID} status={props.status} color={Color} size={Size} />;
});

import { Grid, Text, useComputedColorScheme, lighten, darken } from '@mantine/core';
import { MultiSelect } from '../../components/MultiSelect';
import { iSearch } from '../Search/models';
import { useSearchStore } from '../Search/store';
import { useSearchBarConfigureStore } from '../SearchBarConfigure/store';
import { useTID } from '../../helpers/useTestId';
import { useFootprintStore } from '../Footprints/store';
import { useAppConfigManager } from '../../components/AppConfigs/store';
import { globalColors as colors } from '../../theme/globalColors';
import { CascaderStatusCode } from '../../components/CascaderStatusCode/CascaderStatusCode';

export const SearchOptionsAcquire = (props: { search: iSearch }) => {
  const UpdateSearchInput = useSearchStore((state) => state.UpdateSearchInput);
  const { search } = props;
  const configure = useSearchBarConfigureStore((state) => state);
  const GetDropDown = useAppConfigManager((state) => state.GetDropDown);
  // const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');
  // const { classes } = useStyles();

  const handleUpdateViewSearch = (value: any, field: string) => {
    UpdateSearchInput({ field: field, value: value! });
  };

  const activeFootprint = useFootprintStore.getState().active.id;
  const milestones = useAppConfigManager((state) =>
    state.GetDropDown('milestones:' + activeFootprint)
  );

  function showSelectMilestone() {
    if (configure.showMilestoneSelect) {
      const TID = useTID('MultiSelect', 'Milestones');
      return (
        <MultiSelect
          {...TID}
          disabled={search.StatusCodes?.length > 0}
          clearable
          onChange={(v) => handleUpdateViewSearch(v, 'Milestone')}
          value={search.Milestone?.length > 0 ? search.Milestone : []}
          data={milestones}
          label="Milestone"
          placeholder="Select Any"
          size="xs"
          inputPropName={'Milestone'}
          classNames={{
            input: colorScheme === 'dark' ? 'inputDark' : 'input',
          }}
          style={{
            // '.mantine-Input-input': {
              backgroundColor:
                colorScheme === 'dark'
                  ? darken(colors.wbdblue[9], 0.4)
                  : colors.wbdblue[0],
              borderColor:
                colorScheme === 'dark'
                  ? lighten(colors.wbdblue[9], 0.15)
                  : '#B8D2FD'
            // }
          }}
        />
      );
    }
    return <></>;
  }

  function showSelectMilestoneStatus() {
    if (configure.showMilestoneStatusSelect) {
      const TID = useTID('MultiSelect', 'MilestoneStatus');
      return (
        <MultiSelect
          disabled={search.StatusCodes?.length > 0}
          {...TID}
          clearable
          onChange={(v) => handleUpdateViewSearch(v, 'MilestoneStatus')}
          value={search.MilestoneStatus?.length > 0 ? search.MilestoneStatus : []}
          data={GetDropDown('milestoneStatuses')}
          label="Milestone Status"
          placeholder="Select Any"
          size="xs"
          inputPropName={'MilestoneStatus'}
          classNames={{
            input: colorScheme === 'dark' ? 'inputDark' : 'input',
          }}
          style={{
            // '.mantine-Input-input': {
              backgroundColor:
                colorScheme === 'dark'
                  ? darken(colors.wbdblue[9], 0.4)
                  : colors.wbdblue[0],
              borderColor:
                colorScheme === 'dark'
                  ? lighten(colors.wbdblue[9], 0.15)
                  : '#B8D2FD'
            // }
          }}
        />
      );
    }
    return <></>;
  }
  function showCascaderStatusCode() {
    if (configure.showCascaderStatusCode) {
      return (
        <>
          <Text>or</Text>
          <CascaderStatusCode
            disabled={search.Milestone?.length > 0 || search.MilestoneStatus?.length > 0}
          />
        </>
      );
    }
    return <></>;
  }

  function showSelectContentType() {
    if (configure.showContentTypeAcquireSelect) {
      const TID = useTID('MultiSelect', 'Content Type Acquire');
      return (
        <MultiSelect
          {...TID}
          onChange={(v) => handleUpdateViewSearch(v, 'ContentTypeAcquire')}
          value={search.ContentTypeAcquire ? search.ContentTypeAcquire : []}
          data={GetDropDown('contentTypesAcquire')}
          label="Content Type"
          inputPropName={'ContentTypeAcquire'}
          placeholder="Select Any"
          size="xs"
          classNames={{
            // item: classes.item,
            input: colorScheme === 'dark' ? 'inputDark' : 'input',
            label: 'item'
          }}
          style={{
            // '.mantine-Input-input': {
              backgroundColor:
                colorScheme === 'dark'
                  ? darken(colors.wbdblue[9], 0.4)
                  : colors.wbdblue[0],
              borderColor:
                colorScheme === 'dark'
                  ? lighten(colors.wbdblue[9], 0.15)
                  : '#B8D2FD'
            // }
          }}
        />
      );
    }
    return <></>;
  }

  // function showSelectDistributor() {
  //   if (configure.showDistributorSelect) {
  //     const TID = useTID('MultiSelect', 'Distributor');
  //     return (
  //       <MultiSelect
  //         {...TID}
  //         onChange={(v) => handleUpdateViewSearch(v, 'Distributor')}
  //         value={search.Distributor ? search.Distributor : []}
  //         data={GetDropDown('distributors')}
  //         label="Distributor"
  //         inputPropName={'Distributor'}
  //         placeholder="Select Any"
  //         size="xs"
  //         classNames={{
  //           item: classes.item,
  //           input: classes.input,
  //           label: classes.item
  //         }}
  //         sx={{
  //           '.mantine-Input-input': {
  //             backgroundColor:
  //               theme.colorScheme === 'dark'
  //                 ? theme.fn.darken(colors.wbdblue[9], 0.4)
  //                 : colors.wbdblue[0],
  //             borderColor:
  //               theme.colorScheme === 'dark'
  //                 ? theme.fn.lighten(colors.wbdblue[9], 0.15)
  //                 : '#B8D2FD'
  //           }
  //         }}
  //       />
  //     );
  //   }
  //   return <></>;
  // }

  return (
    <Grid columns={25}>
      <Grid.Col span={1}></Grid.Col>
      <Grid.Col span={4}>
        {showSelectContentType()}
        {/* {showSelectDistributor()} */}
      </Grid.Col>
      <Grid.Col span={4}>
        {showSelectMilestone()}
        {showSelectMilestoneStatus()}
        {showCascaderStatusCode()}
      </Grid.Col>
    </Grid>
  );
};

import { Accordion, Text, useMantineTheme, Group, useComputedColorScheme } from '@mantine/core';
// import { AccordionProviderProps } from '@mantine/core/lib/Accordion/AccordionProvider';
import { ChevronRight, ChevronUp } from 'tabler-icons-react';
import { useInformationAccordionStore } from './store';

interface iInformationAccordionItemProps {
  name: string;
  title: string;
  icons?: JSX.Element[] | boolean;
  children: any;
}

export const InformationAccordionItem = (props: iInformationAccordionItemProps) => {
  const theme = useMantineTheme();
  const items = useInformationAccordionStore((state) => state.Items)
  const colorScheme = useComputedColorScheme('light');
  function border() {
    if (items.includes(props.name)) {
      return `1px solid ${
        colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[4]
      }`;
    }
    return `1px solid ${
      colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[4]
    }`;
  }

  function color(items: string[], name: string) {
    // future, if we want different colors for active/non-active
    // if (items.includes(name)) {
    //   return theme.colorScheme === 'dark' ? 'white' : 'gray';
    // }
    return colorScheme === 'dark' ? 'white' : 'gray';
  }

  return (
    <Accordion.Item key={props.name} value={props.name} style={{borderBottom: 0}}>
      <Accordion.Control
        chevron={
          items.includes(props.name) ? (
            <ChevronUp
              size={16}
              strokeWidth={2}
              color={colorScheme === 'dark' ? 'white' : 'gray'}
            />
          ) : (
            <ChevronRight size={16} strokeWidth={2} color={color(items, props.name)} />
          )
        }
        style={{
          backgroundColor:
            colorScheme === 'dark' ? '#25262B' : '#f9f9f9',
          border: border(),
          borderRadius: 6,
          color: color(items, props.name),
          padding: 6,
          height: 43
        }}
      >
        <Group justify="space-between">
          <Text
            size="sm"
            style={{
              color: color(items, props.name)
            }}
          >
            {props.title}
          </Text>
          {props.icons ? <Group>{props.icons}</Group> : false}
        </Group>
      </Accordion.Control>
      <Accordion.Panel>{props.children}</Accordion.Panel>
    </Accordion.Item>
  );
};

import { Anchor, Box, Popover, ScrollArea, Stack, Table, Text } from '@mantine/core';
import { InformationAccordion, InformationAccordionItem } from '../../../../components';
import { getRatings } from '../../../../utils/utilities';
import _ from 'lodash';
import { useElementSize, useViewportSize } from '@mantine/hooks';
import { useDetailsStore } from '../../store';
import { findTitle } from '../offering/offeringsDetail';
import { useConfigStore } from '../../../../app/store';
import { acquireModalTitle } from './_detailsModalAcquire';
import { useAppConfigManager } from '../../../../components/AppConfigs';
import dayjs from 'dayjs';
import { iManifestationDetailsParams } from '../../models';
import { useEffect, useState } from 'react';
import {
  fetchManifestations,
  latestManifestationAccordion
} from '../_showManifestationAccordionItems';
import {
  fulfilledAudioComponentsGetter,
  fulfilledTimedTextComponentsGetter,
  fulfilledMarkersGetter,
  fulfilledAnnotationslabelsGetter,
  fulfilledMediaDurationGetter,
  dynamicRangeIndicatorGetter
} from '../../_columnDefs/columnsHelpers/fulfilledComponentsGetters';
import { DetailAccordion } from '../../../../components/DetailAccordion';
import { taskCIDGetter } from '../../_columnDefs/columnsHelpers/globalValueGetters';
import { CodeHighlight } from '@mantine/code-highlight';
import { fetchJSONID } from '../../../../api/fetchJSONID';

export const AcquireDetail = (props: any) => {
  const config = useConfigStore((state) => state.config);
  const [manifestations, setManifestations] = useState<any>({});
  const [JsonAsset, setJsonAsset] = useState([]);

  const details = props.details
    ? props.details
    : useDetailsStore((state) => state.acquire);
  const { tasks, asset, audit, id } = details;

  useEffect(() => {
    fetchJsonId();
    fetchManifestations(tasks, setManifestations);
  }, []);
  
  async function fetchJsonId() {
    const baseUrl = config.baseApiGatewayUrl + '/graphql/search';
    let result = await fetchJSONID(baseUrl, asset.eventMetaId, 'acquisition');
    setJsonAsset(result?.data?.event?.records);
  }

  const idFields = useAppConfigManager
    ?.getState()
    ?.GetSearchInputs('footprint-acquire')
    ?.map((field) => ({
      label: field.label,
      value: field.footprint
        ?.find((fp) => fp.footprint === 'footprint-acquire')
        ?.path?.replace(/asset./g, '')
    }));

  const showDetail = (d: any) => {
    if (d === undefined) {
      return;
    }
    const path = d?.value?.split('.');
    let value = null;
    switch (d.label) {
      case 'Acquisition Id':
        if (id) value = id;
        break;
      case 'CID':
        value = taskCIDGetter(tasks, 'manifestationregistration');
        break;
      default:
        if (!asset?.[path?.[0]]) return;
        let pathValue = asset;
        path.forEach((e: string) => {
          pathValue = pathValue[e];
        });
        if (typeof pathValue !== 'string') return;
        value = pathValue;
    }
    if (!value) return;
    const piEnv = config.environment === 'prod' ? '' : `${config.environment}.`;
    switch (d.label) {
      case 'Acquisition Id':
        return (
          <Popover
            key={value + 'Popover'}
            withArrow
            shadow="md"
            width={800}
            position="right"
            classNames={{
              arrow: 'popoverArrow',
              dropdown: 'popoverDropdownBelow'
            }}
            zIndex={9999}
          >
            <Popover.Target>
              <Table.Tr key={value}>
                <Table.Td style={{ textAlign: 'left' }}>{d.label}</Table.Td>
                <Table.Td>
                  <Anchor type="button">{value}</Anchor>
                </Table.Td>
              </Table.Tr>
            </Popover.Target>
            <Popover.Dropdown>
              <ScrollArea h={425}>
                <Text fw={700}>JSON</Text>
                <CodeHighlight
                  code={JSON.stringify(JsonAsset, null, 2)}
                  language="tsx"
                ></CodeHighlight>
              </ScrollArea>
            </Popover.Dropdown>
          </Popover>
        );
        break;
      case 'Edit Id':
        if (
          asset.contentType?.includes('promo') ||
          asset.contentType?.includes('program')
        ) {
          return (
            <Popover
              key={value + 'Popover'}
              position="bottom"
              withArrow
              shadow="md"
              classNames={{
                arrow: 'popoverArrow',
                dropdown: 'popoverDropdownBelow'
              }}
              zIndex={9999}
            >
              <Popover.Target>
                <Table.Tr key={value}>
                  <Table.Td style={{ textAlign: 'left' }}>{d.label}</Table.Td>
                  <Table.Td>
                    <Anchor type="button">{value}</Anchor>
                  </Table.Td>
                </Table.Tr>
              </Popover.Target>
              <Popover.Dropdown>
                <Stack align={'center'}>
                  <Anchor
                    target="_blank"
                    href={`https://apps.${piEnv}dsuite.tech/prometheus/api/v4/canonical/asset/${value}`}
                  >
                    PI
                  </Anchor>
                </Stack>
              </Popover.Dropdown>
            </Popover>
          );
        }
        break;
      case 'Series Id':
        return (
          <Popover
            key={value + 'Popover'}
            position="bottom"
            withArrow
            shadow="md"
            classNames={{
              arrow: 'popoverArrow',
              dropdown: 'popoverDropdownBelow'
            }}
            zIndex={9999}
          >
            <Popover.Target>
              <Table.Tr key={value}>
                <Table.Td style={{ textAlign: 'left' }}>{d.label}</Table.Td>
                <Table.Td>
                  <Anchor type="button">{value}</Anchor>
                </Table.Td>
              </Table.Tr>
            </Popover.Target>
            <Popover.Dropdown>
              <Stack align={'center'}>
                {asset?.seriesId?.id && (
                  <Anchor
                    target="_blank"
                    href={`https://studio.${config.couiEnvironment}.api.discomax.com/content-visualization/series/${value}`}
                  >
                    CV
                  </Anchor>
                )}
              </Stack>
            </Popover.Dropdown>
          </Popover>
        );
        break;
      case 'Season Id':
        return (
          <Popover
            key={value + 'Popover'}
            position="bottom"
            withArrow
            shadow="md"
            classNames={{
              arrow: 'popoverArrow',
              dropdown: 'popoverDropdownBelow'
            }}
            zIndex={9999}
          >
            <Popover.Target>
              <Table.Tr key={value}>
                <Table.Td style={{ textAlign: 'left' }}>{d.label}</Table.Td>
                <Table.Td>
                  <Anchor type="button">{value}</Anchor>
                </Table.Td>
              </Table.Tr>
            </Popover.Target>
            <Popover.Dropdown>
              <Stack align={'center'}>
                {asset?.seriesId?.id && (
                  <Anchor
                    target="_blank"
                    href={`https://studio.${config.couiEnvironment}.api.discomax.com/content-visualization/series/${asset?.seriesId?.id}/seasons/${value}`}
                  >
                    CV
                  </Anchor>
                )}
              </Stack>
            </Popover.Dropdown>
          </Popover>
        );
        break;
      default:
        return (
          <Table.Tr key={d.label + value}>
            <Table.Td style={{ textAlign: 'left' }}>{d.label}</Table.Td>
            <Table.Td>{value}</Table.Td>
          </Table.Tr>
        );
    }
  };

  const manifestationDetails = (m: string) => {
    const params: iManifestationDetailsParams = {
      tasks
    };
    switch (m) {
      case 'fulfilledAudioComponents':
        return fulfilledAudioComponentsGetter(params);
      case 'fulfilledTimedTextComponents':
        return fulfilledTimedTextComponentsGetter(params);
      case 'fulfilledMarkers':
        return fulfilledMarkersGetter(params);
      case 'fulfilledAnnotationsLabels':
        return fulfilledAnnotationslabelsGetter(params);
      case 'fulfilledMediaDuration':
        return fulfilledMediaDurationGetter(params);
      case 'dynamicRangeIndicator':
        return dynamicRangeIndicatorGetter(params);
      default:
        return null;
    }
  };

  const shownIDs = idFields.map(showDetail);

  const { ref, width } = useElementSize();

  const { height } = useViewportSize();

  if (asset === undefined) return <></>;

  const contentTypeGetter = (contentType: string) => {
    let capitalContentType = '';
    if (contentType) {
      const contentTypes = contentType.split(':');
      let ct = contentTypes[contentTypes.length - 1];
      if (ct === 'ad') {
        ct = 'commercial';
      }
      capitalContentType = ct.charAt(0).toUpperCase() + ct.slice(1);
    }
    return capitalContentType;
  };

  return (
    <Box
      ref={ref}
      style={{ margin: 8, marginLeft: 24, overflow: 'auto', maxHeight: height - 300 }}
    >
      <InformationAccordion
        defaultValue={['item-1', 'item-2']}
        chevronPosition="left"
        multiple={true}
        style={{ minWidth: 350, width: width }}
      >
        <InformationAccordionItem name="item-1" title="General Info">
          <Table
            highlightOnHover
            style={{ fontSize: 'xs', width: '110%', margin: -16, marginTop: -43 }}
          >
            <Table.Thead>
              <Table.Tr>
                <Table.Td>&nbsp;</Table.Td>
                <Table.Td>&nbsp;</Table.Td>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              <Table.Tr>
                <Table.Td style={{ textAlign: 'left' }}>Title</Table.Td>
                <Table.Td>{acquireModalTitle(details)}</Table.Td>
              </Table.Tr>
              {asset.contentType && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Content Type</Table.Td>
                  <Table.Td>{contentTypeGetter(asset.contentType)}</Table.Td>
                </Table.Tr>
              )}
              {asset.entityType && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Entity Type</Table.Td>
                  <Table.Td>
                    {asset.entityType?.charAt(0).toUpperCase() +
                      asset.entityType?.slice(1)}
                  </Table.Td>
                </Table.Tr>
              )}
              {asset?.seriesTitles?.length > 0 && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Series Title</Table.Td>
                  <Table.Td>{findTitle(asset.seriesTitles)}</Table.Td>
                </Table.Tr>
              )}
              {asset?.seasonTitles?.length > 0 && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Season Title</Table.Td>
                  <Table.Td>{findTitle(asset.seriesTitles)}</Table.Td>
                </Table.Tr>
              )}
              {asset.seasonNumber && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Season #</Table.Td>
                  <Table.Td>{asset.seasonNumber}</Table.Td>
                </Table.Tr>
              )}
              {asset.episodeNumberInSeason && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Episode #</Table.Td>
                  <Table.Td>{asset.episodeNumberInSeason}</Table.Td>
                </Table.Tr>
              )}
              {asset.controllingNetwork && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Controlling Network</Table.Td>
                  <Table.Td>{asset.controllingNetwork}</Table.Td>
                </Table.Tr>
              )}
              {asset?.ratings?.length > 0 && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Ratings </Table.Td>
                  <Table.Td>{getRatings(asset.ratings)}</Table.Td>
                </Table.Tr>
              )}
              <Table.Tr>
                <Table.Td style={{ textAlign: 'left' }}>Date Received(EST)</Table.Td>
                <Table.Td>
                  {audit?.createdAt ? (
                    dayjs(audit.createdAt).format('MMM DD, YYYY (hh:mm a | [EST])')
                  ) : (
                    <>N/A</>
                  )}
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td style={{ textAlign: 'left' }}>Last Updated(EST)</Table.Td>
                <Table.Td>
                  {audit?.updatedAt ? (
                    dayjs(audit.updatedAt).format('MMM DD, YYYY (hh:mm a | [EST])')
                  ) : (
                    <>N/A</>
                  )}
                </Table.Td>
              </Table.Tr>
            </Table.Tbody>
          </Table>
        </InformationAccordionItem>
        {!_.isEmpty(_.compact(shownIDs)) && (
          <InformationAccordionItem name="item-2" title="IDs">
            <Table
              highlightOnHover
              style={{ fontSize: 'xs', width: '110%', margin: -16, marginTop: -42 }}
            >
              <Table.Thead>
                <Table.Tr>
                  <Table.Td>&nbsp;</Table.Td>
                  <Table.Td>&nbsp;</Table.Td>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{shownIDs}</Table.Tbody>
            </Table>
          </InformationAccordionItem>
        )}
      </InformationAccordion>
      <DetailAccordion
        defaultValue={[]}
        chevronPosition="left"
        multiple={true}
        style={{ minWidth: 350, overflow: 'auto' }}
      >
        {latestManifestationAccordion(manifestations, manifestationDetails, width)}
      </DetailAccordion>
    </Box>
  );
};

export default AcquireDetail;

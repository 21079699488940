import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import {
  iConfig,
  initialConfig,
  iConfiguration,
  iUserData,
  iUserPrefs,
  initialUserPreferencesState,
  iDetailTaskFilter,
  iDefaultViews
} from './models';
import { userPreferencesService } from '../services/preferences/userPreferencesService';
import { useFootprintStore } from '../containers/Footprints/store';
import _ from 'lodash';

export const useConfigStore = create<iConfig>()((set, get) => ({
  ...initialConfig,
  Load: async () => {
    set({ fetching: true });
    const response = await fetch('/settings.json');
    response.json().then((newConfig) => {
      set({ config: newConfig });
      get().ToggleLoading();
    });
    return;
  },
  SetConfig: (newConfig: iConfiguration) => {
    set({ config: newConfig });
  },
  SetUserData: (newUserData: iUserData) => {
    set({ userData: newUserData });
  },
  ToggleLoading: () => set({ isConfigLoading: !get().isConfigLoading }),
  ToggleLayoutEditable: () => set({ layoutEditable: !get().layoutEditable })
}));

export const useUserPrefsStore = create<iUserPrefs>()(
  devtools(
    persist(
      (set, get, state) => ({
        ...initialUserPreferencesState,
        SetSelectedTheme: async (selectedTheme: string) => {
          let mySettings = await userPreferencesService.getPersonalSettings();
          if (mySettings.selectedTheme) {
            mySettings.selectedTheme = selectedTheme;
          } else {
            mySettings.selectedTheme = 'light';
          }
          await userPreferencesService.upsertSettings(mySettings, mySettings.id);
          set({ selectedTheme });
        },
        SetUserPreferencesId: async (id: string) => {
          set({ id });
        },
        SetEnableTooltips: async () => {
          let mySettings = await userPreferencesService.getPersonalSettings();
          mySettings.enableTooltips = !mySettings.enableTooltips;
          mySettings = await userPreferencesService.upsertSettings(
            mySettings,
            mySettings.id
          );
          set({ enableTooltips: mySettings.enableTooltips });
        },
        UpdateGroups: async (group: string) => {
          let mySettings = await userPreferencesService.getPersonalSettings();
          if (!mySettings.groups) {
            mySettings.groups = [];
          }
          if (mySettings.groups.includes(group)) {
            mySettings.groups = mySettings.groups.filter((g: string) => g !== group);
          } else {
            mySettings.groups = _.uniq([...mySettings.groups, group]).filter((g) => g);
          }
          mySettings = await userPreferencesService.upsertSettings(
            mySettings,
            mySettings.id
          );
          set({ groups: mySettings.groups });
        },
        Load: async () => {
          let response = await userPreferencesService.getPersonalSettings();
          if (response.detailTaskFilters) {
            if (!Array.isArray(response.detailTaskFilters)) {
              delete response.detailTaskFilters;
              await userPreferencesService.upsertSettings(response, response.id);
              response.detailTaskFilters = initialUserPreferencesState.detailTaskFilters;
            }
            if ( response.detailTaskFilters) {
              if ( response.detailTaskFilters.length > 0) {
                response.detailTaskFilters.forEach((i: { filter: string[] | string[][]; statusCode: boolean; })=> {
                  if (i.filter.length > 0) {
                    if (Array.isArray(i.filter[0])) {
                      i.statusCode = true
                    } else {
                      i.statusCode = false
                    }
                  } else {
                    i.statusCode = false
                  }
                })
              }
            }
          }
          set({
            selectedTheme: response.selectedTheme,
            enableTooltips: response.enableTooltips,
            type: response.type,
            id: response.id,
            defaultFootprint: response.defaultFootprint,
            detailTaskFilters: response.detailTaskFilters,
            groups: response.groups,
            defaultViews: response.defaultViews
          });
        },
        SetDefaultFootprint: async (id: string) => {
          let mySettings = await userPreferencesService.getPersonalSettings();
          if (mySettings.defaultFootprint) {
            mySettings.defaultFootprint = id;
          } else {
            mySettings.defaultFootprint = 'footprint-offering';
          }
          await userPreferencesService.upsertSettings(mySettings, mySettings.id);
          set({ defaultFootprint: id });
        },
        SetDefaultViews: async (view?: any) => {
          if (view) {
            let mySettings = await userPreferencesService.getPersonalSettings();
            const defaultView: iDefaultViews = {
              footprint: view.footprint,
              default: view.default
            };
            const activeFootprint = useFootprintStore.getState().active.id
            const activeViewactiveView = mySettings.defaultViews.find((i: any)=> i.footprint === activeFootprint);
            if(activeViewactiveView) {
              const index = mySettings.defaultViews.findIndex((i:any) => i.footprint === activeFootprint);
              if(mySettings.defaultViews[index].default !== view.default) {
                mySettings.defaultViews[index].default = view.default
              } else {
                mySettings.defaultViews[index].default = ''
              }
            } else {
              mySettings.defaultViews = [...mySettings.defaultViews,...[defaultView]]
            }
            await userPreferencesService.upsertSettings(mySettings, mySettings.id);
            get().Load();
          }
        },
        SetDetailTaskFilters: async (filter: iDetailTaskFilter) => {
          let mySettings = get();
          const activeFootprint = useFootprintStore.getState().active.id;
          if (
            mySettings.detailTaskFilters &&
            Array.isArray(mySettings.detailTaskFilters)
          ) {
            const newPrefs = mySettings.detailTaskFilters.map((i: iDetailTaskFilter) => {
              if (i.footprint === activeFootprint) {
                return filter;
              } else {
                return i;
              }
            });
            mySettings.detailTaskFilters = newPrefs;
          } else {
            mySettings.detailTaskFilters = [filter];
          }
          await userPreferencesService.upsertSettings(mySettings, mySettings.id);
          set({ detailTaskFilters: mySettings.detailTaskFilters });
        }
      }),
      {
        name: 'userPrefs'
      }
    )
  )
);

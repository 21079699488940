import { GridOptions, GridReadyEvent } from 'ag-grid-community';
import { useStatusCodeStore } from './store';

const onGridReady = (params: GridReadyEvent) => {
  const SetGridAPI = useStatusCodeStore.getState().SetGridAPI;
  params.api.sizeColumnsToFit();
  // params.api.closeToolPanel();
  SetGridAPI(params.api);
};

export const StatusCodeDictonaryGridOptions: GridOptions = {
  headerHeight: 32,
  masterDetail: true,
  onGridReady: onGridReady,
  rowSelection: 'single',
  rowMultiSelectWithClick: true,
  suppressCellFocus: true,
  defaultColDef: {
    filter: true,
    sortable: true,
    resizable: true
  },
  sideBar: {
    hiddenByDefault: true,
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivotMode: true
        }
      },
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel'
      }
    ]
  }
};

import { Box, Button, Group, NumberInput, ScrollArea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect} from 'react';
import { BigPandaConfigService } from '../../services/bigpanda/bigPandaConfigService';
import { useConfigStore } from '../../app/store';
import { BigPandaConfig } from './models';
import { useStatusCodeStore } from './store';

export const BigPandaSettingsForm = () => {
  const baseURL = useConfigStore.getState().config.baseApiGatewayUrl;
  const bigPandaConfig = useStatusCodeStore((state) => state.config);
  const setBigPandaConfig = useStatusCodeStore((state) => state.SetConfig);

  const form = useForm({
    initialValues: bigPandaConfig,
    validate: {
      windowSizeInSecs: (value: any) => {
        let returnValue = null;
        if (value < 300)
          returnValue = 'Sample size must be greater than or equal to 300 seconds.';
        else if (value < form.values.calcIntervalInSecs)
          returnValue = 'Sample size can not be less than Calculation interval.';
        return returnValue;
      },
      calcIntervalInSecs: (value: any) => {
        let returnValue = null;
        if (value < 10)
          returnValue =
            'Calculation interval must be greater than or equal to 10 seconds.';
        else if (value >= form.values.windowSizeInSecs)
          returnValue = 'Interval can not be greater or equal to Sample size.';
        return returnValue;
      },
      stddevThreshold: (value: any) =>
        value > 0 ? null : 'Standard Deviation must be greater than zero.'
    }
  });

  useEffect(
    () => {
      // Do not reload the configuration if it has already been loaded.
      if (!bigPandaConfig || !bigPandaConfig.id) {
        BigPandaConfigService.getConfig(baseURL).then((config) => {
          setBigPandaConfig(config);
          form.setValues(config);
          form.resetDirty(config);
        });
      }
    },
    // Refresh on field changed
    [bigPandaConfig]
  );

  function render() {
    return (
      <Box>
        <form
          onSubmit={form.onSubmit((values) => {
              // Save current configuration to db and refresh config on successful saving 
              // the config.  Otherwise, catch error and display to console.  
              BigPandaConfigService.upsertConfig(baseURL, values)
              .then(() => setBigPandaConfig({} as BigPandaConfig)) 
              .catch((err) => {
                console.error(
                  `Failed to save statuscode to database. Details: ${err.stack}`
                );
              });
          })}
        >
          <ScrollArea style={{ maxHeight: 600 }} h={600} type="auto">
            <NumberInput
              label="Sample size in seconds."
              placeholder="the sample size."
              {...form.getInputProps('windowSizeInSecs')}
              size="sm"
              defaultValue={form.values.windowSizeInSecs}
              mb={12}
              style={{ width: '15em' }}
              max={43200} // 12 hrs.
              min={300} // 5 mins
            />
            <NumberInput
              label="Calculation interval in seconds."
              placeholder="calculation interval"
              {...form.getInputProps('calcIntervalInSecs')}
              defaultValue={form.values.calcIntervalInSecs}
              size="sm"
              mb={12}
              style={{ width: '15em' }}
              max={3600} // 1 hrs.
              min={10}
            />
            <NumberInput
              label="Standard deviation threshold"
              placeholder="Standard deviation offset from mean."
              {...form.getInputProps('stddevThreshold')}
              defaultValue={form.values.stddevThreshold}
              size="sm"
              mb={12}
              style={{ width: '15em' }}
              max={10}
              min={1}
            />
          </ScrollArea>
          <Group justify="space-between">
            <Group justify="flex-end" mt={12}>
              <Button
                onClick={() => {
                  form.setValues(bigPandaConfig);
                  form.resetDirty(bigPandaConfig);
                }}
                color="red"
                disabled={!form.isDirty()}
              >
                Reset
              </Button>
              <Button type="submit" color="green" disabled={!form.isDirty()}>
                Save
              </Button>
            </Group>
          </Group>
        </form>
      </Box>
    );
  }

  return render();
};

import {
  Box,
  Grid,
  Group,
  Button,
  Stack,
  Text,
  useMantineTheme,
  Tooltip
} from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { useConfigStore } from '../../../../app/store';
import useAxios from '../../../../services/axios/axios';
import { useEffect, useState } from 'react';
import { hasOKTAGroup } from '../../../../utils/oktaGroups';
import { ErrorBoundary } from 'react-error-boundary';
import { Recycle } from 'tabler-icons-react';
import ViewsDetail from '../ViewsDetail';
import AssetDetail from '../asset/assetDetail';
import { useDetailsStore } from '../../store';
// import { globalColors } from '../../../../theme/globalColors';

const ErrorFallback = (props: any) => {
  return (
    <div role="alert">
      <p>Error on DetailsModal:</p>
      <pre>{props.error.TypeError}</pre>
    </div>
  );
};

const myErrorHandler = (error: Error, info: { componentStack: string }) => {
  console.log('error', error, 'info', info);
  // Do something with the error
  // E.g. log to an error logging client here
};

/* The DetailsModal component holds detail-related components for a view's
individual row items */
// TODO fix typing of props if possible
export const DetailsModalSoloAsset = (props: any) => {
  const details = props.details;
  const theme = useMantineTheme();
  const [refreshLoading, setRefreshLoading] = useState(false);
  const config = useConfigStore((state) => state.config);
  const { get } = useAxios();

  const tabFields = ['Metadata', 'Image', 'Video'];
  // This is so if the active tab is set to "Staging" on the offering footprint,
  // this footprint will default to something it includes
  const { activeTab, SetActiveTab } = useDetailsStore();
  useEffect(() => {
    if (!tabFields.includes(activeTab as string)) SetActiveTab('Timeline');
  }, []);

  let contentNames = '';
  const contentClass = details.type;
  const entityType = details.entityType;
  if (contentClass && entityType) {
    contentNames = `${
      contentClass.split('_').slice(-1)[0].charAt(0).toUpperCase() +
      contentClass.split('_').slice(-1)[0].slice(1)
    }/${entityType?.charAt(0).toUpperCase() + entityType?.slice(1)}`;
  } else if (contentClass && !entityType) {
    contentNames =
      contentClass.split('_').slice(-1)[0].charAt(0).toUpperCase() +
      contentClass.split('_').slice(-1)[0].slice(1);
  } else if (!contentClass && entityType) {
    contentNames = entityType?.charAt(0).toUpperCase() + entityType?.slice(1);
  }
  interface Title {
    full: string;
    type: string;
    language: string;
  }

  const { ref, width } = useElementSize();

  const TruncateTitle = (title: any) => {
    if (width === 0) return '';
    const truncateSize = Math.floor(width / 100) - 1;
    if (title.length >= truncateSize * 10) {
      return title.split('', truncateSize * 10).join('') + '...';
    }
    return title;
  };

  const findTitle = (titles: Title[]) => {
    let title;
    if (!titles) return '--No Title--';
    if (titles.length > 0) {
      const distribution = titles.find(
        (title: { type: string }) => title.type === 'distribution-title'
      );
      title = distribution ? distribution.full : null;
      if (!title) {
        const main = titles.find(
          (title: { type: string }) => title.type === 'main-title'
        );
        title = main ? main.full : null;
      }
      if (!title) {
        const fallback = titles.find(
          (title: { type: string }) => title.type === 'internal-wm-name'
        );
        title = fallback ? fallback.full : '--No Title--';
      }
    }
    if (!title) title = '--No Title--';
    return TruncateTitle(title);
  };

  const refresh = () => {
    setRefreshLoading(true);
    const baseUrl =
      config.baseApiGatewayUrl +
      '/rest/refresh/v1?id=' +
      details.id +
      '&publish=true&type=offeringsAsset';
    get({ url: baseUrl });
    setTimeout(() => {
      setRefreshLoading(false);
    }, 2000);
  };

  const isAdmin = hasOKTAGroup(config.environment, config.oktaAdminGroup);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={myErrorHandler}>
      {/* <Box
          sx={{
            backgroundColor:
              theme.colorScheme === 'dark'
                ? theme.fn.darken(globalColors.wbdblue[9], 0.4)
                : theme.fn.lighten(globalColors.wbdblue[1], 0.5),
          }}
      > */}
      <Grid columns={24}>
        <Grid.Col span={7} style={{ overflowY: 'auto' }}>
          <Group>
            <Stack style={{ marginTop: 12, width: '100%' }}>
              <Group ref={ref} justify="space-between" style={{ width: '100%' }}>
                <Text
                  size="xl"
                  style={{
                    marginLeft: 24,
                    marginBottom: -32,
                    marginTop: -16
                  }}
                >
                  {details?.titles?.length > 0 ? (
                    findTitle(details.titles)
                  ) : (
                    <>--No Title--</>
                  )}
                </Text>
                {isAdmin && (
                  <Tooltip label="Refresh" zIndex={9999}>
                    <Button
                      size="xs"
                      onClick={refresh}
                      loading={refreshLoading}
                      variant="subtle"
                      style={{
                        marginLeft: 24,
                        marginBottom: -32,
                        marginTop: -16,
                        width: 40
                      }}
                      leftSection={
                        !refreshLoading ? <Recycle style={{ marginRight: -12, color: theme.colors.blue[6] }} /> : <></>
                      }
                    />
                  </Tooltip>
                )}
              </Group>
              <Box
                style={{
                  borderBottom: `1px solid ${theme.colors.gray[8]}`,
                  display: 'flex',
                  fontSize: '1.3em',
                  height: '35px',
                  justifyContent: 'start',
                  marginLeft: 20,
                  padding: '0 5px'
                }}
              >
                <Group justify="space-between" style={{ width: '100%' }}>
                  <Text
                    style={{
                      marginTop: 10
                    }}
                    size="sm"
                  >
                    {contentNames}
                  </Text>
                </Group>
              </Box>
            </Stack>
          </Group>
          <AssetDetail details={details} />
        </Grid.Col>
        <Grid.Col span={17}>
          <Stack style={{ height: '100%' }}>
            {details && (
              <ViewsDetail
                data={details}
                statusObject={true}
                children={['Prep', 'Fulfill', 'Delivery', 'Receipt', 'Staging']}
                parents={['Metadata', 'Image', 'Video']}
                footprint="footprint-asset"
              />
            )}
          </Stack>
        </Grid.Col>
      </Grid>
      {/* </Box> */}
    </ErrorBoundary>
  );
};

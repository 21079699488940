import { Modal, Text } from '@mantine/core';
import { Flex } from 'antd';
import { useAdminModuleStore } from '../store';
import { iItemType } from '../../../components/ListSortable/models';
import { Button } from '../../../components';

type DeleteItemConfirmationModalType = {
  openDeleteModal: boolean;
  set_openDeleteModal: (open: boolean) => void;
  SendData: (list: iItemType[]) => void;
};

export const DeleteItemConfirmationModal = (props: DeleteItemConfirmationModalType) => {
  const { openDeleteModal, set_openDeleteModal, SendData } = props;
  const { Item } = useAdminModuleStore((state) => state.DropDown);
  const { SetItem, DropdownList, SetDropdownList } = useAdminModuleStore();

  const deleteItem = async () => {
    let updatedList = DropdownList.filter((ddVal: iItemType) => ddVal.id !== Item.id);
    SendData(updatedList);
    SetItem({} as iItemType);
    SetDropdownList(updatedList);
    set_openDeleteModal(false);
  };

  return (
    <Modal
      opened={openDeleteModal}
      onClose={() => set_openDeleteModal(false)}
      title="Delete item"
      centered
    >
      <>
        <Text>{`Would you like to delete the dropdown item: "${Item.label}".`}</Text>
        <Flex style={{ marginTop: '10px' }} justify="center" gap="md">
          <Button
            testid="confirm-to-delete-dropdown-item"
            noIcon
            color="red"
            onClick={deleteItem}
          >
            Yes, delete it
          </Button>
          <Button
            testid="cancel-to-delete-dropdown-item"
            style={{ margin: '0 0 0 10px' }}
            noIcon
            onClick={() => set_openDeleteModal(false)}
          >
            Cancel
          </Button>
        </Flex>
      </>
    </Modal>
  );
};

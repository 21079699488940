export const comparator = (valueA: any, valueB: any) => {
  let newA = valueA === null ? '-1' : valueA;
  let newB = valueB === null ? '-1' : valueB;
  if (newA == newB) return 0;
  return newA.localeCompare(newB, undefined, {
    numeric: true
  });
};

export const dateComparator = (valueA: any, valueB: any) => {
  let newA = Date.parse(valueA)
  let newB = Date.parse(valueB)
  if (newA == newB) return 0;
  if (newA < newB) return -1
  return 1
};
import { Box } from '@mantine/core';
import { DataPanelAccordion } from '../../DataPanelAccordion';
import { iDataPanelProps } from '../models';
import { NoDataToDisplay } from '../../MilestoneDataPanels/NoDataToDisplay';
import { PFDRMilestoneColumnDefs } from './historyColumnDefs';
import { DataPanelGrid } from './_dataPanelGrid';

export const PFDR = (props: iDataPanelProps) => {
  const { data, types, width, status } = props;
  let type = types ? types[0] : '';
  return (
    <Box>
      {data === null && <NoDataToDisplay />}
      <DataPanelAccordion defaultValue={type}>
        {types?.includes('Offering') && (
          <DataPanelGrid
            data={data}
            status={status}
            width={width}
            label="Offering"
            coldefs={PFDRMilestoneColumnDefs}
          />
        )}
        {types?.includes('Metadata') && (
          <DataPanelGrid
            data={data}
            status={status}
            width={width}
            label="Metadata"
            coldefs={PFDRMilestoneColumnDefs}
          />
        )}
        {types?.includes('Image') && (
          <DataPanelGrid
            data={data}
            status={status}
            width={width}
            label="Image"
            coldefs={PFDRMilestoneColumnDefs}
          />
        )}
        {types?.includes('Video') && (
          <DataPanelGrid
            data={data}
            status={status}
            width={width}
            label="Video"
            coldefs={PFDRMilestoneColumnDefs}
          />
        )}
        {types?.includes('Asset') && (
          <DataPanelGrid
            data={data}
            status={status}
            width={width}
            label="Asset"
            coldefs={PFDRMilestoneColumnDefs}
          />
        )}
        {types?.includes('Prep') && (
          <DataPanelGrid
            data={data}
            status={status}
            width={width}
            label="Prep"
            coldefs={PFDRMilestoneColumnDefs}
          />
        )}
        {types?.includes('Fulfill') && (
          <DataPanelGrid
            data={data}
            status={status}
            width={width}
            label="Fulfill"
            coldefs={PFDRMilestoneColumnDefs}
          />
        )}
        {types?.includes('Delivery') && (
          <DataPanelGrid
            data={data}
            status={status}
            width={width}
            label="Delivery"
            coldefs={PFDRMilestoneColumnDefs}
          />
        )}
        {types?.includes('Receipt') && (
          <DataPanelGrid
            data={data}
            status={status}
            width={width}
            label="Receipt"
            coldefs={PFDRMilestoneColumnDefs}
          />
        )}
        {types?.includes('Staging') && (
          <DataPanelGrid
            data={data}
            status={status}
            width={width}
            label="Staging"
            coldefs={PFDRMilestoneColumnDefs}
          />
        )}
      </DataPanelAccordion>
    </Box>
  );
};

export const OverallStatus = () => {
  return <></>
}

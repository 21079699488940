
export type BigPandaConfig = {
  id: string,
  type: string,
  windowSizeInSecs: number,
  calcIntervalInSecs: number,
  stddevThreshold: number,
  lastUpdated: Date, 
  lastUpdatedBy: string
}

export const initialBigPandaConfig: BigPandaConfig = {
  id: '',
  type: '',
  windowSizeInSecs: 3600,
  calcIntervalInSecs: 300,
  stddevThreshold: 2,
  lastUpdated: new Date(), 
  lastUpdatedBy: ''
};


export interface BigPandaConfigStore {
  config: BigPandaConfig;
  isRefreshConfig: boolean
  ToggleConfigRefresh: () => void;
  SetConfig: (config: BigPandaConfig) => void;
}

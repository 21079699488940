import {
  Group,
  // Footer as MantineFooter,
  Image,
  Space,
  Text,
  useComputedColorScheme,
  Box,
  useMantineTheme
} from '@mantine/core';
// import useStyles from './FooterStyles';
// import classes from './Footer.module.css';
import { globalColors } from '../../theme/globalColors';
import { useTID } from '../../helpers/useTestId';
import packagejson from '../../../package.json';
import dayjs from 'dayjs';
{
  packagejson.version;
}
interface IFooterProps {
  testid: string;
}

export const Footer = (props: IFooterProps) => {
  // const { classes } = useStyles();
  const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');
  const TID = useTID('Footer', props.testid);
  const year = dayjs().year();

  return (
    <Box
      p="xs"
      {...TID}
      style={{
        height: '60px',
        backgroundColor: colorScheme === 'dark' ? '#001C4C' : '#001C4C',
        color:
          colorScheme === 'dark'
            ? '#ddd' // themeColors.wbdTextLight1
            : '#fff', // themeColors.wbdTextDark1,
        borderTop: `1px solid ${
          colorScheme === 'dark' ? theme.colors.dark[5] : globalColors.titan[6]
        }`
      }}
    >
      <Group justify="space-between">
        <Image
          src={colorScheme === 'dark' ? '/wb-logo-footer.svg' : '/wb-logo-footer.svg'}
          width={30}
          height={32.5}
        />
        <Space w="xl" />
        <Text size="xs" c="#fff">
          Warner Bros. Discovery, Inc. DiSCO v{packagejson.version}
          <br />
          Copyright &copy; {year} Warner Bros. Discovery, Inc. All rights reserved.
        </Text>
      </Group>
    </Box>
  );
};

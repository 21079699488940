import { useFootprintStore } from "../../../containers/Footprints/store";
import { useAppConfigManager } from "../../AppConfigs";
import { useTimelineStore } from "../store/store";
import { CascaderTimeline } from "./CascaderTimeline";

export const CascaderStatusCodeTimeline = (props: any) => {
  const activeFootprint = useFootprintStore((state) => state.active);
  const options = useAppConfigManager((state) => state.GetDropDown('statusHierarchy'));
  let footprintOptions = options.find((o) => o.value === activeFootprint.id) || options;
  const taskFilters = useTimelineStore((state) => state.taskFilters)
  const isStatusCode = useTimelineStore((state) => state.isStatusCode);
  
  return (
    <CascaderTimeline
      disabled={props.disabled}
      id="cascaderStatusCodeTimeline"
      data={taskFilters ? isStatusCode ? taskFilters : [] : []}
      name="Filter by Status Codes"
      options={'children' in footprintOptions! ? footprintOptions.children : []}
    />
  );
};

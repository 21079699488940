import { ValueGetterParams } from 'ag-grid-community';
import { formattedDate } from '../../../../helpers';
import { useAppConfigManager } from '../../../../components/AppConfigs';
import { dateComparator } from '../comparators';
import { useStatusCodeStore } from '../../../../components/StatusCodeDictionary/store';
import { useSearchStore } from '../../../../containers/Search/store';
import { findManifestationTask } from '../columnsHelpers/globalValueGetters';

import dayjs from 'dayjs';
import { iTasks } from '../../stores/details/models';
import { getPseComplianceStatus, getRatings } from '../../../../utils/utilities';
export type BrandType = { label: string; primary: boolean };

export const footprintStatusGetter = (params: ValueGetterParams) => {
  const GetStatusCode = useStatusCodeStore.getState().GetStatusCode;
  let name = 'offeringsAsset-footprint';
  let status = params.data.status ? params.data.status[name] : null;
  if (status !== null)
    status = status.status.charAt(0).toUpperCase() + status.status.slice(1);
  if (status?.codes && status?.codes.length > 0) {
    let sc = GetStatusCode(status.codes[0].code);
    if (sc.status && sc.badgeText !== '') {
      status =
        sc.status.charAt(0).toUpperCase() + sc.status.slice(1) + ' - ' + sc.badgeText;
    }
  }
  return status;
};

export const milestoneStatusGetter = (params: ValueGetterParams) => {
  const GetStatusCode = useStatusCodeStore.getState().GetStatusCode;
  let name = params.colDef.cellRendererParams.child
    ? params.colDef.cellRendererParams.parent +
      '|' +
      params.colDef.cellRendererParams.child
    : params.colDef.cellRendererParams.parent;
  const milestone = params.data.status ? params.data.status[name] : null;
  let status = milestone ? milestone.status : null;
  if (status === 'na') status = 'N/A';
  if (status !== null) status = status.charAt(0).toUpperCase() + status.slice(1);
  if (milestone?.codes && milestone?.codes.length > 0) {
    let sc = GetStatusCode(milestone.codes[0].code);
    if (sc.status && sc.badgeText !== '') {
      status =
        sc.status.charAt(0).toUpperCase() + sc.status.slice(1) + ' - ' + sc.badgeText;
    }
  }
  return status;
};

export const globalReleaseDateGetter = (params: ValueGetterParams) => {
  let date = params?.data?.globalReleaseDate;
  if (date) {
    return dayjs(date).format('MMM DD, YYYY (hh:mm a | [EST])');
  }
  return null;
};

export const milestoneDateGetter = (params: ValueGetterParams) => {
  let name = params.colDef.cellRendererParams.child
    ? params.colDef.cellRendererParams.parent +
      '|' +
      params.colDef.cellRendererParams.child
    : params.colDef.cellRendererParams.parent;
  const milestone = params.data.status ? params.data.status[name] : null;
  let date = milestone?.audit?.updatedAt ? milestone.audit.updatedAt : null;

  if (!date) {
    return null;
  }
  return formattedDate(date);
};

export const milestoneDetailGetter = (params: ValueGetterParams) => {
  let name = params.colDef.cellRendererParams.child
    ? params.colDef.cellRendererParams.parent +
      '|' +
      params.colDef.cellRendererParams.child
    : params.colDef.cellRendererParams.parent;
  const milestone = params.data.status ? params.data.status[name] : null;

  if (milestone && milestone?.codes !== null && milestone.codes.length > 0) {
    const codes = milestone.codes.find((element: any) => 'detail' in element);
    let detail = codes?.detail
    if (!codes?.detail && codes) {
      const GetStatusCode = useStatusCodeStore.getState().GetStatusCode;
      let sc = GetStatusCode(codes?.code);
      if (sc) {
        detail = sc.details;
      }
    }
    if (detail && codes?.data) {
      return `${detail} - ${codes?.data}`;
    } else if (detail) {
      return detail;
    } else if (codes?.data) {
      return codes?.data;
    }
  }
  return null;
};

export const milestoneCodeGetter = (params: ValueGetterParams) => {
  let name = params.colDef.cellRendererParams.child
    ? params.colDef.cellRendererParams.parent +
      '|' +
      params.colDef.cellRendererParams.child
    : params.colDef.cellRendererParams.parent;
  const milestone = params.data.status ? params.data.status[name] : null;

  if (milestone && milestone?.codes !== null && milestone.codes.length > 0) {
    const codes = milestone.codes.find((element: any) => 'code' in element);
    if (codes.code) {
      return codes.code;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

function truncateUUID(uuid: string) {
  return uuid.substring(0, 4) + ' ... ' + uuid.substring(uuid.length - 4, uuid.length);
}

export const startDateFormatter = (params: ValueGetterParams) => {
  const offerings = params?.data?.offerings;
  if (offerings) {
    if (offerings.length > 0) {
      const firstDate = offerings
        .filter((i: { startDate: string }) => i.startDate)
        .filter(
          (value: { startDate: string }, index: any, self: { startDate: string }[]) =>
            self.findIndex(
              (v: { startDate: string }) => v.startDate === value.startDate
            ) === index
        )
        .map((i: { startDate: string }) => {
          const date = i.startDate;
          return formattedDate(date);
        })
        .sort(dateComparator);
      if (firstDate.length > 0) {
        return firstDate[0];
      }
    }
  }
  return '';
};

export const endDateFormatter = (props: any) => {
  const { startDate, endDate, search } = useSearchStore.getState();
  const offerings = props.data.offerings;
  if (offerings) {
    if (search?.DateField === 'endDate') {
      if (offerings.length > 0) {
        if (startDate && endDate) {
          const firstDate = offerings
            .filter((i: { endDate: string }) => i.endDate)
            .filter(
              (value: { endDate: string }) =>
                new Date(value.endDate) >= startDate && new Date(value.endDate) <= endDate
            )
            .map((i: { endDate: string }) => i.endDate);
          return firstDate.length > 0 ? formattedDate(firstDate[0]) : '';
        }
      }
    } else {
      if (offerings.length > 0) {
        const firstDate = offerings
          .filter((i: { endDate: string }) => i.endDate)
          .filter(
            (value: { endDate: string }, index: any, self: { endDate: string }[]) =>
              self.findIndex((v: { endDate: string }) => v.endDate === value.endDate) ===
              index
          )
          .map((i: { endDate: string }) => {
            const date = i.endDate;
            return formattedDate(date);
          })
          .sort(dateComparator);
        if (firstDate.length > 0) {
          return firstDate[0];
        }
      }
    }
  }
  return '';
};

export const titleGetter = (params: ValueGetterParams) => {
  let title = '';
  const titles = params.data.titles;
  if (!titles) return '--No Title--';
  if (titles.length > 0) {
    const distribution = titles.find(
      (title: { type: string }) => title.type === 'distribution-title'
    );
    title = distribution ? distribution.full : null;
    if (!title) {
      const main = titles.find((title: { type: string }) => title.type === 'main-title');
      title = main ? main.full : null;
    }
    if (!title) {
      const cat = titles.find((title: { type: string }) => title.type === 'cat-title');
      title = cat ? cat.full : '';
    }
    if (!title) {
      const fallback = titles.find(
        (title: { type: string }) => title.type === 'internal-wm-name'
      );
      title = fallback ? fallback.full : '--No Title--';
    }
  }
  if (!title) title = '--No Title--';
  return title;
};

export const seriesTitleGetter = (params: ValueGetterParams) => {
  let title = '';
  const titles = params.data.seriesTitles;
  if (!titles) return title;
  if (titles.length > 0) {
    const distribution = titles.find(
      (title: { type: string }) => title.type === 'distribution-title'
    );
    title = distribution ? distribution.full : null;
    if (!title) {
      const main = titles.find((title: { type: string }) => title.type === 'main-title');
      title = main ? main.full : null;
    }
    if (!title) {
      const cat = titles.find((title: { type: string }) => title.type === 'cat-title');
      title = cat ? cat.full : '';
    }
    if (!title) {
      const fallback = titles.find(
        (title: { type: string }) => title.type === 'internal-wm-name'
      );
      title = fallback ? fallback.full : '';
    }
  }
  return title;
};

export const seasonTitleGetter = (params: ValueGetterParams) => {
  let title = '';
  const titles = params.data.seasonTitles;
  if (!titles) return title;
  if (titles.length > 0) {
    const distribution = titles.find(
      (title: { type: string }) => title.type === 'distribution-title'
    );
    title = distribution ? distribution.full : null;
    if (!title) {
      const main = titles.find((title: { type: string }) => title.type === 'main-title');
      title = main ? main.full : null;
    }
    if (!title) {
      const cat = titles.find((title: { type: string }) => title.type === 'cat-title');
      title = cat ? cat.full : '';
    }
    if (!title) {
      const fallback = titles.find(
        (title: { type: string }) => title.type === 'internal-wm-name'
      );
      title = fallback ? fallback.full : '';
    }
  }
  return title;
};

export const editIDGetter = (params: ValueGetterParams) => {
  if (!params.data.editId || !params.data.editId.id || params.data.editId.id === null) {
    return '';
  }
  return truncateUUID(params.data.editId.id);
};

export const contentIDGetter = (params: ValueGetterParams) => {
  return truncateUUID(params.data.contentId.id);
};

export const offeringTypeGetter = (params: ValueGetterParams) => {
  let capitalType;
  if (params.data.type) {
    let formattedType = params.data.type.split('_').slice(-1);
    capitalType = formattedType[0].charAt(0).toUpperCase() + formattedType[0].slice(1);
  } else {
    capitalType = null;
  }
  return capitalType;
};

export const entityTypeGetter = (params: ValueGetterParams) => {
  let capitalType;
  if (params.data.entityType) {
    capitalType =
      params?.data?.entityType?.charAt(0)?.toUpperCase() +
      params?.data?.entityType?.slice(1);
  } else {
    capitalType = null;
  }
  return capitalType;
};

export const partnerGetter = (params: ValueGetterParams) => {
  let partner = '';
  if (!params.data.partnerId) {
    return '';
  }
  if (params.data.partnerId.id) {
    switch (params.data.partnerId.id) {
      case 'c01c1c07-e7bf-4cee-be6b-87092a30d41c':
        partner = 'Max';
        break;
      case '2195c719-d08d-49e2-b568-234ec46414b5':
        partner = 'Max';
        break;
      case '9646297a-acd4-4884-8977-d56b280a0c4a':
        partner = 'Max';
        break;
      case 'f6bc2fc8-8191-4920-8e9f-99ce4b0ff057':
        partner = 'Max';
        break;
      case '65e74175-2142-4e5a-a85f-65f4ed69f570':
        partner = 'Max';
        break;
      case '155ecceb-bb5d-4cd3-b106-183686d5a2d4':
        partner = 'Max';
        break;
      case 'bdb0b66c-4cb4-49e0-980d-a19145cecf80':
        partner = 'Max';
        break;
      case '22352a43-1948-4ea4-8daa-9bd00749fc5c':
        partner = 'Max';
        break;
      case '4a7ad113-8fda-4939-bda8-a9474a912805':
        partner = 'Amazon PVC';
        break;
      case 'd5c3de78-11ca-4ff3-be8f-d1855e2373ed':
        partner = 'YouTube';
        break;
      case 'ac176604-f0c4-4cf3-b06f-bf9e8aea3ffe':
        partner = 'Telefonica';
        break;
      case 'd646434d-bcf9-4e40-88fb-c5ca52d8ccf0':
        partner = 'Bleacher Report';
        break;
      case 'dd4b242c-7f40-4628-bb79-8e69e5f98fad':
        partner = 'Canal+';
        break;
      default:
        partner = '';
    }
  }
  return partner;
};

export const marketGetter = (params: ValueGetterParams) => {
  const offerings = params?.data?.offerings;
  const markets = useAppConfigManager.getState().queryMarkets;
  if (offerings) {
    if (offerings.length > 0) {
      return offerings
        .filter((i: { marketId: { id: string } }) => i.marketId)
        ?.filter(
          (value: any, index: any, self: string | any[]) => self.indexOf(value) === index
        )
        .map((i: { marketId: { id: string } }) => {
          const market = markets.find((m) => m.valueList === i.marketId.id);
          if (market) {
            return market.label;
          } else {
            return `ID: ${i.marketId.id} not found`;
          }
        })
        ?.filter(
          (value: any, index: any, self: string | any[]) => self.indexOf(value) === index
        )
        .sort()
        .join(', ');
    }
  }
};

export const adMarkerGetter = (params: any) => {
  const tasks = params.data ? params.data.tasks : params.tasks;
  if (tasks) {
    if (tasks?.length > 0) {
      const task = findManifestationTask(tasks, 'mezzcreation', 'fulfillment-workflow');
      if (task) {
        return task.details?.fulfilledMediaMarkerCount;
      }
    }
  }
  return null;
};

export const latestCommentGetter = (params: ValueGetterParams) => {
  const comment = params.data.latestComment;
  if (comment) {
    return `${comment.comment} | ${comment.audit?.createdBy} | ${formattedDate(
      comment.audit?.createdAt
    )}`;
  }
  return '';
};

export const primaryBrandLabels = (params: ValueGetterParams) => {
  const offerings = params?.data?.offerings;
  if (offerings) {
    if (offerings.length > 0) {
      return offerings
        .map((i: { brands: BrandType[] }) => {
          if (i.brands) {
            if (i.brands.length > 0) {
              return i.brands
                .filter((brand) => brand.primary)
                ?.filter((value, index, self) => self.indexOf(value) === index)
                ?.map((brand) => brand.label)
                ?.join(', ');
            }
          }
      })
      ?.filter((value: any, index: any, self: string | any[]) => value && self.indexOf(value) === index)
      .join(', ')
    }
  }
};

export const secondaryBrandLabels = (params: ValueGetterParams) => {
  const offerings = params?.data?.offerings;
  if (offerings) {
    if (offerings.length > 0) {
      return offerings
        .map((i: { brands: BrandType[] }) => {
          if (i.brands) {
            if (i.brands.length > 0) {
              return i.brands
                .filter((brand) => !brand.primary)
                ?.filter((value, index, self) => self.indexOf(value) === index)
                ?.map((brand) => brand.label)
                ?.join(', ');
            }
          }
      })
      ?.filter((value: any, index: any, self: string | any[]) => value && self.indexOf(value) === index)
      ?.join(', ');
    }
  }
};

const dynamicRangeGetter = (task: any, range: string) => {
  let fulfilledVideoComponents;
  if (task?.details?.fulfilledVideoComponents?.length > 0) {
    fulfilledVideoComponents = task.details?.fulfilledVideoComponents.find(
      (i: { dynamicRange: string }) =>
        i.dynamicRange && i.dynamicRange.toUpperCase().includes(range)
    );
    return fulfilledVideoComponents;
  }
};
export const hdrFrameSizeGetter = (params: any) => {
  let fulfilledVideoComponents;
  const tasks = params.data ? params.data.tasks : params.tasks;
  if (tasks?.length > 0) {
    const task = findManifestationTask(tasks, 'mezzcreation', 'fulfillment-workflow');
    fulfilledVideoComponents = dynamicRangeGetter(task, 'HDR');
    if (fulfilledVideoComponents?.frameSize)
      return `${fulfilledVideoComponents?.frameSize?.width} x ${fulfilledVideoComponents?.frameSize?.height}`;
    else return '';
  }
  return '';
};
export const sdrFrameSizeGetter = (params: any) => {
  let fulfilledVideoComponents;
  const tasks = params.data ? params.data.tasks : params.tasks;
  if (tasks?.length > 0) {
    const task = findManifestationTask(tasks, 'mezzcreation', 'fulfillment-workflow');
    fulfilledVideoComponents = dynamicRangeGetter(task, 'SDR');
    if (fulfilledVideoComponents?.frameSize)
      return `${fulfilledVideoComponents?.frameSize?.width} x ${fulfilledVideoComponents?.frameSize?.height}`;
    else return '';
  }
  return '';
};
export const hdrPictureAspectRatioGetter = (params: any) => {
  let fulfilledVideoComponents;
  const tasks = params.data ? params.data.tasks : params.tasks;
  if (tasks?.length > 0) {
    const task = findManifestationTask(tasks, 'mezzcreation', 'fulfillment-workflow');
    fulfilledVideoComponents = dynamicRangeGetter(task, 'HDR');
    if (fulfilledVideoComponents?.pictureAspectRatio)
      return `${fulfilledVideoComponents?.pictureAspectRatio}`;
    else return '';
  }
  return '';
};
export const sdrPictureAspectRatioGetter = (params: any) => {
  let fulfilledVideoComponents;
  const tasks = params.data ? params.data.tasks : params.tasks;
  if (tasks?.length > 0) {
    const task = findManifestationTask(tasks, 'mezzcreation', 'fulfillment-workflow');
    fulfilledVideoComponents = dynamicRangeGetter(task, 'SDR');
    if (fulfilledVideoComponents?.pictureAspectRatio)
      return `${fulfilledVideoComponents?.pictureAspectRatio}`;
    else return '';
  }
  return '';
};
export const hdrScanningFormatGetter = (params: any) => {
  let fulfilledVideoComponents;
  const tasks = params.data ? params.data.tasks : params.tasks;
  if (tasks?.length > 0) {
    const task = findManifestationTask(tasks, 'mezzcreation', 'fulfillment-workflow');
    fulfilledVideoComponents = dynamicRangeGetter(task, 'HDR');
    if (fulfilledVideoComponents?.scanningFormat)
      return `${fulfilledVideoComponents?.scanningFormat
        ?.split(':')
        .slice(-1)[0]
        .toUpperCase()}`;
    else return '';
  }
  return '';
};
export const sdrScanningFormatGetter = (params: any) => {
  let fulfilledVideoComponents;
  const tasks = params.data ? params.data.tasks : params.tasks;
  if (tasks?.length > 0) {
    const task = findManifestationTask(tasks, 'mezzcreation', 'fulfillment-workflow');
    fulfilledVideoComponents = dynamicRangeGetter(task, 'SDR');
    if (fulfilledVideoComponents?.scanningFormat)
      return `${fulfilledVideoComponents?.scanningFormat
        ?.split(':')
        .slice(-1)[0]
        .toUpperCase()}`;
    else return '';
  }
  return '';
};

export const complianceGetter = (params: any) => {
  const tasks = params.data ? params.data.tasks : params.tasks;
  if(!tasks) 
    return 'N/A';
  if (tasks) {
    if (tasks?.length > 0) {
      const task = findManifestationTask(tasks, 'mezzcreation');
      if(task) {  
        return getPseComplianceStatus(task);
      }
    } else {
      return 'N/A';
    }
  }
  return 'N/A';
};

export const taskCIDGetter = (params: ValueGetterParams) => {
  const mezzcreationTask = params?.data?.tasks?.find(
    (task: iTasks) => task.type === 'mezzcreation'
  );
  return mezzcreationTask?.details?.cId?.id;
};
export const ratingsGetter = (params: any) => {
  const offering = params.data ? params.data.offering : params.offering;
  if (offering?.ratings?.length > 0) {
    return getRatings(offering.ratings);
  }
  return null;
}
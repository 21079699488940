import { useConfigStore } from "../app/store";
import useAxios from "../services/axios/axios";


export const fetchFacts = async (offeringId: string) => {
    const { post } = useAxios();

    const baseURL = useConfigStore.getState().config.baseApiGatewayUrl + '/graphql/search'
    const query = constructQuery(offeringId)

    const schema = {
        "query":query
    }
    
    const eventData = await post({
        url: baseURL,
        data: JSON.stringify(schema)
    });
    if (!eventData) return []

    return eventData.data.facts.records;
};

const constructQuery = (offeringId: string) => {
    const query = 
        `query {
          facts(
              filters: {
                  id: "${offeringId}"
                  tasks: ["imagedelivery","offeringdelivery","metadelivery","mezzdelivery"]
                  page: 1
                  limit: 1000
              }
          ) {
              records{
                  id
                  idNamespace
                  type
                  eventSource
                  eventType
                  audit {
                      updatedAt
                      createdAt
                      generatedAt
                  }
                  details
                  isOverall
                  payloadId
                  status {
                      type
                      codes {
                          code
                          detail
                      }
                  }
              }
          }
      }`

    return query
}
import { Box, Center, Image, Space, Stack, Text } from '@mantine/core';

export default function NotFoundPage() {
  return (
    <Box>
      <Image src="/DOT.png" fit="contain" height={180} />
      <Space h="lg" />
      <Center>
        <Stack align="center">
          <Text>
            Dot looked all around,
            <br /> but all she found was:
          </Text>
          <Text size="lg" fw={750}>
            404 Not Found
          </Text>
        </Stack>
      </Center>
    </Box>
  );
}

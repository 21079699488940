import { ActionIcon, Group, Space, Switch, Tooltip, useComputedColorScheme} from '@mantine/core';
import SearchStatusCodes from './_search';
import { useStatusCodeStore } from './store';
import { Hierarchy3 } from 'tabler-icons-react';

export const SCDHeaderBar = () => {
  const { includeDepreciated, SetIncludeDepreciated } = useStatusCodeStore();
  const { fullGrid, ToggleFullGrid } = useStatusCodeStore();
  const ShowLabel = (includeDepreciated ? 'Show ' : 'Hide ') + 'Deprecated';
  const ShowFullGridLabel = (fullGrid ? 'Show ' : 'Hide ') + 'Full Grid';
  const GetHierachy = useStatusCodeStore((state) => state.GetHierarchy);
  const colorScheme= useComputedColorScheme('light');
  return (
    <Group>
      <Tooltip label="Status Hierarchy">
        <ActionIcon onClick={GetHierachy} 
          variant='subtle' 
          color= {colorScheme==='light'? '#868E96': 'white'}
          style={{ marginTop: -16, marginRight: 24 }}
        >
          <Hierarchy3 />
        </ActionIcon>
      </Tooltip>
      <Switch
        checked={fullGrid}
        onChange={() => ToggleFullGrid()}
        label={ShowFullGridLabel}
        mt={-16}
      />
      <Space h="lg" />
      <Switch
        checked={includeDepreciated}
        onChange={() => SetIncludeDepreciated()}
        label={ShowLabel}
        mt={-16}
      />
      <Space h="lg" />
      <SearchStatusCodes style={{ border: '1px solid red' }} />
    </Group>
  );
};

import { Box, Center, Image, Space, Stack, Text } from '@mantine/core';

export default function NotAuthorized() {
  return (
    <Box>
      <Image src="/DOT.png" fit="contain" height={180} />
      <Space h="lg" />
      <Center>
        <Stack align="center">
          <Text>
            Dot looked all around, but she can't find a permissions slip for you!
          </Text>
          <Text size="lg" ta="center">
            We are currently conducting a Pilot program.
            <br />
            If you feel like you should have access during this pilot,
            <br />
            please contact{' '}
            <a href="mailto:gabe.pasillas@warnermedia.com?subject=DiSCOv5 Pilot access">
              Gabe Pasillas
            </a>
          </Text>
          <Space h="xl" />
          <Text>
            Dot is the mascot of the <b>D</b>istribution <b>O</b>bservability and <b>T</b>
            racking team.
          </Text>
        </Stack>
      </Center>
    </Box>
  );
}

import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { iDataPanelAccordion, initialDataPanelAccordionValues } from './models';

export const useDataPanelAccordionStore = create<iDataPanelAccordion>()(
  devtools((set, get) => ({
    ...initialDataPanelAccordionValues,
    SetItems: (newItem: string | null) => {
      set({ Items: newItem });
    },
    SetDefaultItem: (newItem: string | null) => {
      set({ DefaultAccordionItem: newItem });
    }
  }))
);

import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { BigPandaConfig, BigPandaConfigStore, initialBigPandaConfig } from './models';

export const useStatusCodeStore = create<BigPandaConfigStore>()(
  devtools((set, get) => ({
    config: initialBigPandaConfig,
    isRefreshConfig: false,
    ToggleConfigRefresh: () => {
      set({ isRefreshConfig: !get().isRefreshConfig });
    },
    SetConfig: (config: BigPandaConfig) => set({ config: config }),
  })));

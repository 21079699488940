import { Cascader } from '../Cascader/Cascader';
import { useAppConfigManager } from '../AppConfigs';
import { useFootprintStore } from '../../containers/Footprints/store';
import { useSearchStore } from '../../containers/Search/store';

export const CascaderStatusCode = (props: any) => {
  const activeFootprint = useFootprintStore((state) => state.active);
  const StatusCodes = useSearchStore((state) => state.search.StatusCodes);
  const options = useAppConfigManager((state) => state.GetDropDown('statusHierarchy'));
  let footprintOptions = options.find((o) => o.value === activeFootprint.id) || options;
  return (
    <Cascader
      disabled={props.disabled}
      id="cascaderStatusCode"
      data={StatusCodes}
      field="StatusCodes"
      name="Status Codes"
      noOpen={props.noOpen}
      options={'children' in footprintOptions! ? footprintOptions.children : []}
    />
  );
};

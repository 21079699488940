import { Box, Group, Text, useComputedColorScheme } from '@mantine/core';
import { ItemInterface, ReactSortable } from 'react-sortablejs';
import { DragDrop, Edit } from 'tabler-icons-react';
import { iItemType } from '../../components/ListSortable/models';
import { useAdminModuleStore } from './store';
import classes from './ListAdminSortable.module.css';

type AdminListSortablePropsType = {
  reloadGrid: () => void;
  loading: boolean;
  set_loading: (loading: boolean) => void;
};

export const AdminListSortable = (props: AdminListSortablePropsType) => {
  const { reloadGrid } = props;
  const { SetDropdownList, DropdownList, SetItem, SetHierarchyPath, hierarchyPath } =
    useAdminModuleStore();
  const colorScheme= useComputedColorScheme('light');
  const navigateInHierarchy = (item: iItemType) => {
    SetHierarchyPath([...hierarchyPath, { value: item.value, label: item.label }]);
    if (item.children) {
      SetDropdownList(item.children);
      SetItem({} as iItemType);
      reloadGrid();
    }
  };

  function ShowItems() {
    return DropdownList?.map((item: iItemType) => (
      <Box className={classes.listSortable} key={item.id}>
        <Group justify="space-between">
          <Group style={{ 
                width: '100%' , 
                backgroundColor: colorScheme==='dark'? '#5C5F66':'#F7F9FA',
                height: 30,
                padding: '6px 6px 6px 12px'
              }} 
                justify="space-between">
            <Group>
              <DragDrop size={18} strokeWidth={1.5} />
              <Text
                style={{ cursor: !!item?.children ? 'pointer' : 'move', width: '215px' }}
                onClick={() => !!item?.children && navigateInHierarchy(item)}
                size="xs"
              >
                {item.label}
              </Text>
            </Group>
            <Group>
              <Edit
                size={18}
                strokeWidth={1.5}
                style={{ cursor: 'pointer' }}
                onClick={() => SetItem(item)}
              />
            </Group>
          </Group>
        </Group>
      </Box>
    ));
  }
  return (
    <Box>
      <ReactSortable
        animation={250}
        chosenClass={classes.chosen}
        ghostClass={classes.ghost}
        list={DropdownList as (ItemInterface & iItemType)[]}
        setList={(DropdownList: (ItemInterface & iItemType)[]) =>
          SetDropdownList(DropdownList)
        }
        swap
      >
        {ShowItems()}
      </ReactSortable>
    </Box>
  );
};

import { useState } from 'react';
import { Box, Divider, Flex, Grid, Modal, ScrollArea, Text } from '@mantine/core';
import { ContainerComponent } from '../ContainerComponent';
import { useNotificationStore } from './store';
import { Notification, initialNotification } from './models';
import { Button } from '../Button';
import { NotificationForm } from './NotificationForm';
import { FlexedText } from './_flexedtext';
import _ from 'lodash';
import { useDeepCompareEffect } from '../../utils/utilities';
import { useSavedViewsStore } from '../../containers/SavedViews/store';

export const Notifications = () => {
  const {
    getNotifications,
    notifications,
    resetValues,
    setCurrentNotification,
    setEditing
  } = useNotificationStore();

  const { SetNotificationModalOpen } = useSavedViewsStore();

  // load Notifications
  useDeepCompareEffect(() => {
    (async () => {
      try {
        await getNotifications();
      } catch (err) {
        console.error('Error Loading Notifications: ', err);
      }
    })();
  }, [notifications]);
  const [opened, setOpened] = useState(false);
  return (
    <ContainerComponent testid="Notifications" title={'Notifications'}>
      <Modal
        zIndex={301}
        size="calc(100vw / 2)"
        title={<h2>Update Notification</h2>}
        opened={opened}
        onClose={() => {
          setOpened(false);
          SetNotificationModalOpen(false);
          setCurrentNotification(initialNotification);
          resetValues();
        }}
      >
        <NotificationForm close={() => setOpened(false)} />
      </Modal>
      <Grid>
        <Grid.Col span={12}>
          <ScrollArea style={{ height: 'calc(100vh - 200px)' }}>
            {notifications?.map((notification: Notification) => {
              return (
                <>
                  {notification.active ? (
                    <Box key={notification?.view}>
                      <h3>{notification?.view}</h3>
                      <Flex>
                        <h4 style={{ margin: 0, marginRight: 3 }}>Days:</h4>
                        {notification?.schedule.map((day: any, i: number) => {
                          if (day.checked) {
                            if (i === notification?.schedule.length - 1) {
                              return <Text key={day.label}>{day.label} </Text>;
                            }
                            return <Text key={day.label}>{day.label}, </Text>;
                          }
                        })}
                      </Flex>
                      <FlexedText
                        text={`${notification?.timeInterval} hour(s)`}
                        label="Every"
                      />
                      <FlexedText
                        text={notification?.deliveryMethod}
                        label="Delivery Method"
                      />
                      <FlexedText text={notification?.destination} label="Destination" />
                      <FlexedText
                        text={notification?.active ? 'Active' : 'Inactive'}
                        label="Status"
                      />
                      <Button
                        mt={16}
                        noIcon
                        testid="NotificationsEdit"
                        onClick={() => {
                          setOpened(true);
                          setEditing(true);
                          SetNotificationModalOpen(true);
                          setCurrentNotification(notification);
                        }}
                      >
                        Edit
                      </Button>
                    </Box>
                  ) : (
                    <Box key={notification?.view}>
                      <h3>{notification?.view}</h3>
                      <Button
                        noIcon
                        testid="NotificationsEnable"
                        onClick={() => {
                          setOpened(true);
                          setEditing(true);
                          SetNotificationModalOpen(true);
                          setCurrentNotification(notification);
                        }}
                      >
                        Enable
                      </Button>
                    </Box>
                  )}

                  <Divider my="md" />
                </>
              );
            })}
          </ScrollArea>
        </Grid.Col>
      </Grid>
    </ContainerComponent>
  );
};

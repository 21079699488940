export function hasOKTAGroup(env: string, groupName: string) {
  if (env === 'prod'){
    const OKTAStr: string = localStorage!.getItem('okta-token-storage')!;
    if (OKTAStr === null) return false;
  
    const OKTA = JSON.parse(OKTAStr);
    if (!OKTA.idToken) return false;
  
    const claims = OKTA.idToken?.claims['ops.lasso.groups'];
    return claims.includes(groupName);
  }
  return true;
}

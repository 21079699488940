import { Box,Title, useMantineTheme } from '@mantine/core';
import { SavedViewsList } from './SavedViewsList';
import { FootprintSelect } from '../Footprints/FootprintSelect';
import { useFootprintStore } from '../Footprints/store';
import { useUserPrefsStore } from '../../app/store';

export const SavedViewsContent = () => {
  const theme = useMantineTheme();
  let defaultFootprint = useUserPrefsStore(state => state.defaultFootprint);
  const SetDefaultFootprint = useUserPrefsStore(state => state.SetDefaultFootprint);
  const hasDefault = useFootprintStore((state) =>
  state.footprints.find((f) => f.id === defaultFootprint)
)
  if (hasDefault){
    defaultFootprint = hasDefault.label
  } else {
    defaultFootprint = 'On Demand';
  }


  return (
    <Box>
      <Box style={{ textAlign: 'left', display: 'flex', justifyContent: 'space-between', paddingLeft: 16, paddingRight: 16 }}>
          <Title size={'h4'} order={5}>Saved Views</Title>
          <FootprintSelect
            label={'Default Footprint'}
            value={defaultFootprint}
            changeHandler={(v: string) => {
              SetDefaultFootprint(v);
            }}
          />
      </Box>
      <Box style={{ padding: 16 }}>
        <Box
          style={{
            border: `1px ${theme.colors.gray[6]} solid`,
            borderRadius: 4,
            padding: 4
          }}
        >
          <SavedViewsList />
        </Box>
      </Box>
    </Box>
  );
};

import {
  Button,
  Group,
  Modal,
  Select,
  Stack,
  TextInput,
  useComputedColorScheme,
  // useMantineTheme
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useStatusCodeStore } from './store';
import { useEffect } from 'react';
import { StatusCodeFootprint, initialStatusCodeFootprint } from './models';
import { useFootprintStore } from '../../containers/Footprints/store';
import { iFootprint } from '../../containers/Footprints/models';

export const FootprintsForm = () => {
  const DeleteFootprint = useStatusCodeStore.getState().DeleteFootprint;
  const SetFootprint = useStatusCodeStore.getState().SetFootprint;
  // const SetFootprintsIndex = useStatusCodeStore.getState().SetFootprintsIndex;
  const ToggleIsEditing = useStatusCodeStore.getState().ToggleFormIsEditing;
  const GetFootprint = useStatusCodeStore.getState().GetFootprintRow;
  const footprintIndex = useStatusCodeStore.getState().footprintIndex;
  const isEditing = useStatusCodeStore.getState().formIsEditing;
  const footprints = useFootprintStore((state) => state.footprints);
  // const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');
  let fp = {
    footprint: '',
    process: '',
    activity: '',
    task: ''
  } as StatusCodeFootprint;

  useEffect(() => {
    if (footprintIndex === -1) {
      fp = initialStatusCodeFootprint;
      return;
    }
    fp = GetFootprint(footprintIndex);
    if(fp === undefined) {
      return
    }
    form.values.footprint = fp.footprint;
    form.values.process = fp.process;
    form.values.activity = fp.activity;
    form.values.task = fp.task;
    form.reset();
  }, [isEditing, footprintIndex]);

  function onSubmit() {
    SetFootprint(form.values);
    ToggleIsEditing(false);
    form.reset()
  }

  function onCancelClick() {
    ToggleIsEditing(false);
  }

  function onDeleteClick() {
    DeleteFootprint();
    ToggleIsEditing(false);
  }

  const onFootprintChange = (target: any) => {
    // const { value } = target;
    form.setFieldValue('footprint', target);
    // ???
    // SetFootprintsIndex(target);
  };

  const form = useForm({
    initialValues: fp
  });

  function getFootprintsList(footprint: iFootprint) {
    return ({
      value: footprint.id,
      label: footprint.label,
      filter: footprint.id
    })
  }

  return (
    <Modal
      opened={isEditing}
      onClose={() => ToggleIsEditing(false)}
      withCloseButton={false}
      size="calc(100vw / 2)"
      centered
      keepMounted
      withinPortal
    >
      <Stack
        style={{
          backgroundColor: colorScheme === 'dark' ? '#00122d' : '#cce0fd',
          border: `1px solid ${colorScheme === 'dark' ? '#373a40' : '#ced4da'}`,
          borderRadius: 12,
          padding: 12,
          margin: -12
        }}
      >
        <form
        >
          <Group justify="space-between" style={{ maxWidth: '98%' }}>
            <Select
              data={footprints.map(getFootprintsList)}
              withAsterisk
              label="Footprint"
              placeholder="Footprint selection"
              {...form.getInputProps('footprint')}
              onChange={onFootprintChange}
              size="sm"
              mb={12}
              style={{ width: '23%' }}
              // withinPortal
            />
            <TextInput
              label="Process"
              withAsterisk
              placeholder="the process"
              {...form.getInputProps('process')}
              size="sm"
              mb={12}
              style={{ width: '23%' }}
            />
            <TextInput
              label="Activity"
              withAsterisk
              placeholder="milestone activity"
              {...form.getInputProps('activity')}
              size="sm"
              mb={12}
              style={{ width: '23%' }}
            />
            <TextInput
              label="Task"
              withAsterisk
              placeholder="milestone task"
              {...form.getInputProps('task')}
              size="sm"
              mb={12}
              style={{ width: '23%' }}
            />
          </Group>
          <Group justify="space-between">
            <Button color="blue" onClick={onDeleteClick}>
              Delete
            </Button>
            <Group justify="flex-end">
              <Button color="red" onClick={onCancelClick}>
                Cancel
              </Button>
              <Button color="green" onClick={onSubmit}>
                Update
              </Button>
            </Group>
          </Group>
        </form>
      </Stack>
    </Modal>
  );
};

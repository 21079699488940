// import { Card, Group, Text, useMantineTheme } from '@mantine/core';
// import { AgGridReact } from 'ag-grid-react';
import { useComputedColorScheme, useMantineTheme, getGradient } from '@mantine/core';
import { ApplyColumnStateParams, ColumnState, FirstDataRenderedEvent, GridOptions } from 'ag-grid-community';
import { useRef } from 'react';
import { AgGridContainer } from '../../components';
import { AgGridReact } from 'ag-grid-react';
import { useDetailsStore } from './store';
import { useDataPanelAccordionStore } from '../../components/DataPanelAccordion/store';

interface iDataPanelGridProps {
  // data is an array of the column defs objects which change constantly
  data: any[];
  gridOptions: GridOptions
  columnState: ColumnState[]
  pinned: string
}

const DetailCellRendererGridOptions: GridOptions = {
  // detailRowHeight: 100,
  headerHeight: 50,
  // masterDetail: true,
  // onGridReady: onGridReady,
  rowHeight: 30,
  // domLayout: 'autoHeight',
  rowSelection: 'single',
  suppressCellFocus: true,
  defaultColDef: {
    filter: true,
    sortable: true,
    resizable: true,
    hide: true
  }
};

export const DetailCellRendererGrid = ({
  data,
  gridOptions,
  columnState,
  pinned
}: iDataPanelGridProps) => {
  const gridRef = useRef<AgGridReact>(null);
  const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light')
  const details = useDetailsStore();
  const SetDetails = useDetailsStore((state) => state.SetDetails);
  const setPreModalState = useDetailsStore((state) => state.SetPreModalState);
  const SetDefaultItem = useDataPanelAccordionStore((state) => state.SetDefaultItem);
  const setDetailsVisible = useDetailsStore((state) => state.SetDetailsVisible);

  const gridCSS =
    colorScheme === 'dark' ? 'ag-theme-alpine-dark' : 'ag-theme-alpine';
  let newGridOptions = Object.assign({}, DetailCellRendererGridOptions);
  newGridOptions.columnDefs = gridOptions.columnDefs
  delete newGridOptions.rowClassRules
  newGridOptions.components = gridOptions.components
  const onFirstDataRendered = (event: FirstDataRenderedEvent) =>{
    if (columnState){
      newGridOptions.columnApi?.applyColumnState({
        state: columnState,
        applyOrder: true
      })
    }
    // newGridOptions.alwaysShowHorizontalScroll = true

    newGridOptions.api?.refreshCells()
  }

  newGridOptions.onFirstDataRendered = onFirstDataRendered

  const onCellDoubleClicked = (params: any) => {
    let newDetails = Object.assign({}, details);
    if (Object.keys(params.data).includes('offering')) {
      newDetails = Object.assign({}, details, {
        offering: params.data,
        headerName: params.colDef.headerName
      });
      SetDetails(newDetails);
    }
    const previousFilterState = gridOptions.api?.getFilterModel()
    const previousColumnState = gridOptions.columnApi?.getColumnState()
    if (previousFilterState && previousColumnState){
      setPreModalState({
        filters: previousFilterState,
        columns: previousColumnState
      });
    }
    SetDefaultItem('Video');
    setDetailsVisible(true);
  };
  newGridOptions.onCellDoubleClicked = onCellDoubleClicked
  
  const checkGridLayoutModified = (e: any) => {
    if (e.type === 'columnResized' && e.finished && e.column) {
      const previousColumnState = gridRef?.current?.columnApi?.getColumnState();
      const updatedColumnState = previousColumnState!.map((c: any) => {
        if (c.colId === e.column.colId) {
          c.width = e.column.actualWidth;
        }
        return c;
      });
      gridRef?.current?.columnApi?.applyColumnState(updatedColumnState as ApplyColumnStateParams);
      const params = {
        force: true
      };
      gridRef?.current?.api?.refreshCells(params);
    }
  };
  newGridOptions.onColumnResized = checkGridLayoutModified
  
  return (
    <AgGridContainer>
      <div
        className={gridCSS}
        style={{
          height: 198,
          width: '100%',
          // paddingTop: 3,
          // paddingBottom: 3,
          // paddingLeft: pinned === 'left' ? 3 : 0,
          // paddingRight: pinned === 'right' ? 3 : 0,
          padding: 3,
          backgroundImage: colorScheme === 'dark' ?
          getGradient({ from: 'yellow', to: 'blue', deg: 45 }, theme) :
          getGradient({ from: 'blue', to: 'yellow', deg: 45 }, theme)
        }}
      >
        <AgGridReact
          ref={gridRef}
          gridOptions={newGridOptions}
          rowData={data}
        />
      </div>
    </AgGridContainer>
  );

  return <></>
};

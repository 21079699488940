import { iTasks } from '../../stores/details/models';

export const findManifestationTask = (
  tasks: iTasks[],
  type: string,
  eventSource?: string
): any => {
  const task = tasks.find((t) => {
    if (!eventSource) return t.type === type;
    return t.type === type && t.eventSource === eventSource;
  });
  return task;
};

export const taskCIDGetter = (tasks: iTasks[], type: string) => {
  const mezzcreationTask = tasks?.find((task) => task.type === type);
  return mezzcreationTask?.details?.cId?.id;
};

export const AudioComponentsGetter = (
  tasks: iTasks[],
  detailComponent: string,
  type: string,
  eventSource?: string
) => {
  if (tasks?.length > 0) {
    const task = findManifestationTask(tasks, type, eventSource);
    console.log(task)
    return (
      task?.details?.[detailComponent]
        ?.map(
          (i: {
            spokenLanguage: string;
            soundFieldGroupType: string;
            audioContentKind: string;
          }) => {
            const audioContentKind = i?.audioContentKind
              ?.split(':')
              ?.slice(-1)[0]
              ?.toUpperCase();
            const soundFieldGroupType = i?.soundFieldGroupType
              ?.split(':')
              ?.slice(-1)[0]
              ?.toUpperCase();
            return `${i?.spokenLanguage} (${audioContentKind}/${soundFieldGroupType})`;
          }
        )
        ?.sort()
        ?.join(', ') || null
    );
  }
  return null;
};

export const TimedTextComponentsGetter = (
  tasks: iTasks[],
  detailComponent: string,
  type: string,
  eventSource?: string
) => {
  if (tasks?.length > 0) {
    const task = findManifestationTask(tasks, type, eventSource);
    return (
      task?.details?.[detailComponent]
        ?.map((i: { writtenLanguage: string; textUsageType: string }) => {
          return `${i?.writtenLanguage} (${i?.textUsageType
            ?.split(':')
            ?.slice(-1)[0]
            ?.toUpperCase()})`;
        })
        ?.sort()
        ?.join(', ') || null
    );
  }
  return null;
};

export const MarkersGetter = (
  tasks: iTasks[],
  detailComponent: string,
  type: string,
  eventSource?: string
) => {
  if (tasks?.length > 0) {
    const task = findManifestationTask(tasks, type, eventSource);
    const fulfilledMarkersCount = task?.details?.[detailComponent]?.reduce(
      (acc: { [x: string]: any }, o: { label: string }) => (
        (acc[o.label] = (acc[o.label] || 0) + 1), acc
      ),
      {}
    );
    return (
      Object.entries(fulfilledMarkersCount || {})
        ?.map(([k, v]) => {
          if (k.split(':').slice(-1)[0] === 'fixed-point-candidate-insertion') {
            v = task?.details?.[detailComponent];
          }
          if (v === 1) {
            return `${k.split(':').slice(-1)[0].toUpperCase()}`;
          }
          return `${k.split(':').slice(-1)[0].toUpperCase()} (${v})`;
        })
        ?.sort()
        ?.join(', ') || null
    );
  }

  return null;
};

export const AnnotationslabelsGetter = (
  tasks: iTasks[],
  detailComponent: string,
  type: string,
  eventSource?: string
) => {
  if (tasks?.length > 0) {
    const task = findManifestationTask(tasks, type, eventSource);
    const fulfilledAnnotationsLabels = task?.details?.[detailComponent]?.reduce(
      (acc: { [x: string]: any }, o: { label: string }) => (
        (acc[o.label] = (acc[o.label] || 0) + 1), acc
      ),
      {}
    );
      return (
        Object.entries(fulfilledAnnotationsLabels || {})
          ?.map(([k, v]) => {
            if (v === 1) {
              return `${k.split(':').slice(-1)[0].toUpperCase()}`;
            }
            return `${k.split(':').slice(-1)[0].toUpperCase()} (${v})`;
          })
          ?.sort()
          ?.join(', ') || null
      );
    }
  
  return null;
};

export const MediaDurationGetter = (
  tasks: iTasks[],
  detailComponent: string,
  type: string,
  eventSource?: string
) => {
  if (tasks?.length > 0) {
    const task = findManifestationTask(tasks, type, eventSource);
    return task?.details?.[detailComponent]?.durationTimecode || null;
  }
  return null;
};

export const dynamicRangeIndicator = (
  tasks: iTasks[],
  detailComponent: string,
  type: string,
  eventSource?: string
) => {
  if (tasks?.length > 0) {
    const task = findManifestationTask(tasks, type, eventSource);
    return (
      task?.details?.[detailComponent]
        ?.map((i: { dynamicRange: string }) => {
          if (i.dynamicRange) {
            return i.dynamicRange.split(':').slice(-1)[0].toUpperCase();
          } else {
            return '';
          }
        })
        ?.sort()
        ?.join(', ') || null
    );
  }
  return null;
};

import { create } from 'zustand';

export interface iPanel {
  panelState: string;
  SetPanelState: (newPanelStatus: string) => void;
}

export const usePanelStateStore = create<iPanel>()(
  (set, get) => ({
    panelState: 'CLOSED',
    SetPanelState: (newPanelStatus: string) => set({ panelState: newPanelStatus })
  })
);

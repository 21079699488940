import { Responsive, WidthProvider } from 'react-grid-layout';
import { useHUDStore } from './state';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { iLayoutItem } from './state/models';
import { useEffect, useState } from 'react';
import { Anchor, Box, Center, Divider, Flex, ScrollArea, Text, Title } from '@mantine/core';
import classes from './Hud.module.css';
import { useNavigate } from 'react-router';

const ResponsiveGridLayout = WidthProvider(Responsive);

export interface iHUDGridProps {
  name?: string;
}

export function HudGrid() {
  const HUDStore = useHUDStore();
  const onDrag = useHUDStore((state) => state.onDragHUD);
  const SetOnDrag = useHUDStore((state) => state.SetOnDragHUD)
  const navigate = useNavigate();

  const [userLayouts, setUserLayouts] = useState(
    JSON.parse(JSON.stringify(HUDStore.personalHUD.layout))
  );

  const breakpoints = { lg: 1400, md: 996, sm: 800, xs: 600 };
  const calculateCurrentBreakpoint = () => {
    if (window.innerWidth >= breakpoints.lg) {
      return 'lg';
    } else if (window.innerWidth >= breakpoints.md) {
      return 'md';
    } else if (window.innerWidth >= breakpoints.sm) {
      return 'sm';
    } else {
      return 'xs';
    }
  };
  const [oldBreakpoint, setOldBreakpoint] = useState(calculateCurrentBreakpoint());

  useEffect(() => {
    setUserLayouts(HUDStore.personalHUD.layout);
  }, [HUDStore.personalHUD]);

  const configureLayouts = (layouts: any) => {
    // copyHUD is what comes from the User DB, copyLayout is the "changed" layout from react-grid-layout
    let copyHUD = Object.assign({}, HUDStore.personalHUD);
    let copyLayout = Object.assign({}, layouts);
    // Loop through the "changes" first
    for (const bp in copyLayout) {
      if (copyLayout[bp].length > 0) {
        let nl = [] as iLayoutItem[];
        copyLayout[bp].forEach((item: iLayoutItem) => {
          let copyItem = Object.assign({}, item);
          // Finds Item in changed layout, checks personal layout to see if it has a corresponding item
          let widget = HUDStore.personalHUD.layout[
            bp as keyof typeof HUDStore.personalHUD.layout
          ].find((w: { i: string }) => w.i === copyItem.i);
          if (widget) {
            // onLoad and copyItem.h === 1 needed because when the grid renders/ if you change screen sizes it sets all
            // widgets to h=1, w=1, x=0, y=0. This should prevent that. Basically if the "change" is on load,
            // it will look at the layout from your DB Hud and not the incoming "change"
            if (HUDStore.onLoad || copyItem.h === 1) {
              copyItem.h = widget.h;
              copyItem.w = widget.w;
              copyItem.x = widget.x;
              copyItem.y = widget.y;
              HUDStore.SetOnLoad(false);
            }
          } else {
            // Same as above, just you dont have a layout in your DB Hud so it set's the default h and w
            if (HUDStore.onLoad) {
              copyItem.h = 13;
              copyItem.w = 3;
              copyItem.x = 0;
              copyItem.y = 0;
            }
            HUDStore.SetOnLoad(false);
          }
          // When changing screen sizes, you would get the above issue as well, however the current breakpoint would be "incorrect"
          // There would be no layouts in the current breakpoint, so it would check you previous breakpoint to just pull h and w sizes from there
          if (copyItem.h <= 1) {
            let widget = copyLayout[oldBreakpoint as keyof typeof copyLayout].find(
              (w: { i: string }) => w.i === copyItem.i
            );
            if (widget) {
              copyItem.h = widget.h;
              copyItem.w = widget.w;
            }
          }
          if (copyItem.h === 1) {
            // If all else fails, just set the detail size
            copyItem.h = 13;
            copyItem.w = 3;
          }
          // Set min and max h and w for resizing
          copyItem.minH = 13;
          copyItem.maxH = 26;
          copyItem.minW = 3;
          copyItem.maxW = 6;
          nl.push(copyItem);
        });
        copyLayout[bp] = nl;
      }
    }
    // makes sure that gaps are filled in the copy layout to match DB Hud
    for (const bp in copyLayout) {
      if (
        copyLayout[bp].length === 0 &&
        copyHUD.layout[bp as keyof typeof copyHUD.layout].length > 0
      ) {
        copyLayout[bp] = copyHUD.layout[bp as keyof typeof copyHUD.layout];
      }
    }
    copyHUD.layout = copyLayout;
    return copyHUD;
  };

  const onLayoutChange = (layout: any, layouts: any) => {
    const configuredHUD = configureLayouts(layouts);
    if (configuredHUD.id) {
      HUDStore.SavePersonalHUD(configuredHUD);
    }
  };

  let cursor = onDrag ? 'grabbing' : 'grab';

  return (
    <ResponsiveGridLayout
      layouts={userLayouts}
      margin={[15, 5]}
      containerPadding={[25, 15]}
      onLayoutChange={(layout, layouts) => onLayoutChange(layout, layouts)}
      breakpoints={breakpoints}
      onBreakpointChange={(bp: 'lg' | 'md' | 'sm') => {
        setOldBreakpoint(HUDStore.breakpoint);
        HUDStore.SetBreakpoint(bp);
      }}
      cols={{ lg: 15, md: 12, sm: 9, xs: 6 }}
      rowHeight={25}
      isResizable={HUDStore.gridComponents.length === 0 ? false : true}
      isDraggable={true}
      draggableHandle=".drag-handle"
      onDrag={() => SetOnDrag(true)}
      onDragStop={() => SetOnDrag(false)}
    >
      {HUDStore.gridComponents.length > 0 && HUDStore.gridComponents.map((item) => {
        return <div key={item.key}>{item.component}</div>;
      })}
      {HUDStore.gridComponents.length === 0 && (
        <div key={'placeholder'}>
          <Box className={classes.detail}>
            <Flex justify={'space-between'}>
              <Center className="drag-handle" style={{ width: '100%', cursor: cursor }}>
                <Title order={5} ml={6} mb={12} style={{ cursor: cursor, textAlign: 'center' }}>
                  Welcome to the DiSCO HUD
                </Title>
              </Center>
            </Flex>
            <Divider mt={-8} mb={12} />
            <Box style={{ height: '85%' }}>
              <ScrollArea h={'100%'}>
                <Text size={'md'} ta="center">
                  You currently have no saved Widgets on your Personal HUD.
                </Text>
                &nbsp;
                <Text size={'md'} ta="center">
                  If you would like to add Widgets to your HUD, click&nbsp;
                  <Anchor onClick={()=>navigate('/views')}>
                    HERE
                  </Anchor>
                  &nbsp;to visit the DiSCO Views page.
                </Text>
                &nbsp;
                <Text size={'md'} ta="center">
                  From there, make sure your left side Status Chart is visible, then click the "Add Widget To HUD" button to get started.
                </Text>
              </ScrollArea>
            </Box>
          </Box>
        </div>
      )}
    </ResponsiveGridLayout>
  );
}

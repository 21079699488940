import { useConfigStore } from "../app/store";
import useAxios from "../services/axios/axios";


export const fetchImageCounts = async (
  id: string
) => {
  const { post } = useAxios();
  const baseURL = useConfigStore.getState().config.baseApiGatewayUrl + '/graphql/search'

  let eventPage = 1;

  let combinedRecords: any[] = [];
  let fetchingData = true;
  let totalCount;
  while (fetchingData) {
    const query = constructQuery(eventPage, id);
    if (!query) return null;
    const schema = {
      query: query
    };

    const eventData = await post({
      url: baseURL,
      data: JSON.stringify(schema)
    });
    if (!eventData) return []
    totalCount = eventData.data.history.count;
    combinedRecords = combinedRecords.concat(eventData.data.history.records);
    if (combinedRecords.length >= totalCount) fetchingData = false;
    eventPage += 1;
  }

  return combinedRecords
};

const constructQuery = (eventPage: number, id: string) => {
  const queryFilters = constructFilters(eventPage, id);
  if (!queryFilters) return null;
  const query = `query {
            history(
                ${queryFilters}
            ) {
                records { 
                    audit {
                      generatedAt
                    }
                    required
                    delivered
                }
                count
            }
          }`;

  return query;
};

const constructFilters = (eventPage: number, id: string) => {

  // TODO Add date
  const queryFilters = `filters: {
        id:"${id}"
        task:["imagedelivery"]
        page:${eventPage}
        limit:1000
    }`;
  return queryFilters;
};

import { Center, Group, useComputedColorScheme, useMantineTheme } from '@mantine/core';
import { Button } from '../../components/Button';
import { ChartPie, ChartPieOff } from 'tabler-icons-react';
import { useSavedViewsStore } from '../SavedViews/store';

export const ChartVisibilty = () => {
  const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');
  const SetChartActive = useSavedViewsStore((state) => state.SetChartActive);
  const chartActive = useSavedViewsStore((state) => state.active.chartActive);
  const icon = chartActive ? (
    <ChartPie style={{ marginRight: -12 }} />
  ) : (
    <ChartPieOff style={{ marginRight: -12 }} />
  );
  return (
    <Center>
      <Group>
        <Button
          testid="hide-show-status-chart"
          size="xs"
          onClick={() => SetChartActive(!chartActive)}
          variant="subtle"
          style={{
            color: chartActive
              ? colorScheme === 'dark'
                ? theme.colors.wbdblue[6]
                : theme.colors.wbdblue[4]
              : colorScheme === 'dark'
              ? theme.colors.dark[4]
              : 'gray'
          }}
          leftSection={icon}
          tooltip={{
            label: `${chartActive ? 'Hide' : 'Show'} Status Chart`,
            position: 'top',
            color: colorScheme === 'dark' ? 'gray' : 'black'
          }}
        />
      </Group>
    </Center>
  );
};

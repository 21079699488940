import dayjs from 'dayjs';
import {
  View,
  initialActiveGridState
} from '../containers/Views/stores/grid/models';
// import { getStatus } from './getStatus';
import moment from 'moment';
import { iWidget } from '../components/Hud/state/models';
// import { iSearch } from '../containers/SavedViews/models';
import { v4 as uuidv4 } from 'uuid';
import { useSavedViewsStore } from '../containers/SavedViews/store';
import { useConfigStore } from '../app/store';
import { useAppConfigManager } from '../components/AppConfigs';
import { HUDService } from '../services/hud/hudService';
import { EffectCallback, useEffect, useRef } from 'react';
import _ from 'lodash';

// import { Asset, EventHistory } from './../components/DataPanels/models';

// type A2 = 'offeringReceipt' |
//   'contentSubscriptionRequest' |
//   'contentAvailabilityMessage' |
//   'encodeQc' |
//   'transcode' |
//   'deliveryFulfillment' |
//   'videos' |
//   'meta' |
//   'images' |
//   'qmAvailable' |
//   'mezzFulfilment' |
//   'fetchVersion' |
//   'fetchTitle' |
//   'captionConvert' |
//   'renderMezz' |
//   'transferMedia' |
//   'transferMeta' |
//   'endPointReceiptVideo' |
//   'endPointReceiptImage' |
//   'endPointReceiptMeta' |
//   'avodGating';

// export const sortAssetDataByDate = (data: any, field: string) => data.map((obj:any) => {
//   let objCopy = Object.assign({}, obj)
//   if (objCopy.status?.type) {
//     let s = getStatus(objCopy.status.type);
//     objCopy.status = s[0].toUpperCase() + s.substring(1);
//   }
//   return { ...obj, processedAt: new Date(obj.processedAt) };
// }).sort((objA:any, objB:any) => objB.processedAt.getTime() - objA.processedAt.getTime(),);

export const dateFormatter = (params: any) => {
  return moment(params.value).format('MMM DD, YYYY (hh:mm A | EST)');
};

export const capitalize = (str: string) => {
  if (!str) {
    return str;
  }
  return str[0].toUpperCase() + str.substring(1, str.length);
};

export function separateByCapital(input: string): string {
  let spacedText = input.replace(/([A-Z])/g, ' $1');
  spacedText = spacedText.charAt(0).toUpperCase() + spacedText.slice(1);
  return spacedText.trim();
}

export const sortByPosition = (a: any, b: any) => a.position - b.position;

export const createViewsList = (views: View[], group: string) => {
  return views.sort(sortByPosition).map((v: any) => {
    return {
      id: v.id,
      value: v.id,
      label: v.label,
      footprint: v.footprint,
      role: v.role
    };
  });
};

export const createViewsGroupList = (views: View[], group: string) => {
  const groupItems = views.sort(sortByPosition).map((v: any) => {
    return {
      id: v.id,
      value: v.id,
      label: v.label,
      footprint: v.footprint,
      role: v.role
    };
  });

  return {
    group: group,
    items: groupItems
  };
};
export const isValidDate = (d: any) => {
  if (Object.prototype.toString.call(d) === '[object Date]') {
    // it is a date
    if (isNaN(d)) {
      // d.getTime() or d.valueOf() will also work
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export function createRelativeDateRangeString(
  startDate: Date | null,
  endDate: Date | null
) {
  let today = dayjs().startOf('day');
  let start = dayjs(startDate).startOf('day');
  let end = dayjs(endDate).endOf('day');
  let before = 0;
  before = start.diff(today, 'day');
  let after = 0;
  after = end.diff(today, 'day');
  if (end.isBefore(today, 'day')) {
    after--;
  }
  return {
    startDateTime: (before > 0 ? '+' : '') + (before !== 0 ? before : '0'),
    endDateTime: (after > 0 ? '+' : '') + after
  };
}

export function deriveDateFromRelativeString(
  dateDifference: string,
  isEnd: boolean,
  time?: string
) {
  let today = dayjs().startOf('day');
  const defaultHours = isEnd ? 23 : 0;
  const defaultMinutes = isEnd ? 59 : 0;
  const hours = time ? parseInt(time.split(':')[0]) : defaultHours;
  const minutes = time ? parseInt(time.split(':')[1]) : defaultMinutes;
  if (isEnd) {
    const date = dayjs(
      today
        .add(parseInt(dateDifference), 'day')
        .endOf('day')
        .toDate()
        .setHours(hours, minutes)
    ).toDate();
    return date;
  }
  const date = dayjs(
    today
      .add(parseInt(dateDifference), 'day')
      .startOf('day')
      .toDate()
      .setHours(hours, minutes)
  ).toDate();
  return date;
}

// Filters are ANY in ag-grid
export const createSharedSearchURL = async (viewObject: View) => {
  const saveView = useSavedViewsStore.getState().SaveActiveView;
  const email = useConfigStore.getState().userData.email;

  viewObject.id = uuidv4(); // generate id here, future proofing
  viewObject.timestamp = dayjs().utc().valueOf();
  viewObject.type = 'shared:' + email;
  let id = await saveView(viewObject);
  if (id === undefined) {
    // TODO Handle Save ERROR
    console.log('ERROR!  Failed to save view:', viewObject);
  }
  return `http://${window.location.host}/views/shared/${id}`;
};

export const createSharedWidgetURL = async (widget: iWidget) => {
  const sharedWidget = await HUDService.shareWidget(widget);
  return `http://${window.location.host}/hud/${sharedWidget.id}`;
};

export const getObjFromSharedSearchURL = async (url: string) => {
  const getView = useSavedViewsStore.getState().GetViewByID;
  const parts = url.split('/');
  if (parts.length > 3) {
    if (parts.length > 4) {
      if (parts[5] === 'keyword') {
        // do keyword search
        const newView: View = initialActiveGridState;
        const keyword = parts[6].split('?')[0];
        newView.search.SearchInput = keyword;
        return newView;
      }
    }
    if (parts[4] === 'shared') {
      // do new (DB based) shared search
      const result = getView(parts[5]);
      return await result.then((r) => {
        return r;
      });
    }
  }
  // do legacy (base64 based) shared search
  const base64Encoded = url.split('/')[4];
  const json = atob(base64Encoded);
  const obj = JSON.parse(json);
  return obj;
};

export const mapUrlFootprint = (urlFootprint: string) => {
  const footprints = {
    offerings: 'footprint-offering',
    segments: 'footprint-linear',
    assets: 'footprint-acquire',
    offeringAssets: 'footprint-asset',
    crossplatform: 'footprint-crossplatform'
  };
  const footprint = footprints[urlFootprint as keyof typeof footprints];
  if (footprint) return footprint;
  return null;
};

export const getStatusCodeString = (statusCodeFilter: any, valueMapper: any) => {
  let statusCodeString = '';
  const { queryStatusCodes } = useAppConfigManager.getState();
  if (statusCodeFilter?.length > 0) {
    let mappedStatusCodes: any = {};
    statusCodeFilter.forEach((f: any) => {
      const found = queryStatusCodes.find((qsc: any) => {
        if (qsc.value === `${f[f.length - 1]}`) {
          return qsc.valueList;
        }
      });
      if (found) {
        found.valueList.forEach((listEntry: string) => {
          let key = '';
          listEntry.split('_').forEach((element: string, i: number) => {
            if (i === 0 || i === 1) {
              key += `${capitalize(element)}_`;
            } else if (i === 2) {
              key += `${valueMapper[element as keyof typeof valueMapper]}`;
            } else if (i === 4) {
              if (mappedStatusCodes[key]) {
                mappedStatusCodes[key].codes.code.push(element);
              } else {
                mappedStatusCodes[key] = {
                  codes: {
                    code: [element]
                  }
                };
              }
            }
          });
        });
      } else {
        console.error('not found', f[f.length - 1]);
      }
    });

    statusCodeString = `status: {
      ${Object.entries(mappedStatusCodes)!.map((msc: any[]) => {
        return `${msc[0]}: {
        codes: {
          code: [${msc[1].codes.code.map((s: string) => `\"${s}\"`)}]
        }
      },`;
      })}
    }`;
  }
  return statusCodeString;
}

export const getPseComplianceStatus = (task: any) => {
  if (task.details?.fulfilledVideoComponents) {
    if (task.details?.fulfilledVideoComponents.length > 0) {
      const dyRange = task.details?.fulfilledVideoComponents.map(
        (i: { dynamicRange: string; compliance: any }) => {
          if (i.dynamicRange) {
            return i.dynamicRange?.split(':').slice(-1)[0].toUpperCase();
          } else {
            return 'N/A';
          }
        }
      );
      if (dyRange.length > 1) {
        if (dyRange.includes('SDR') && dyRange.includes('HDR')) {
          const complianceObj = { hdrStatus: '', sdrStatus: '' };
          let noCompliance = 'N/A';
          task.details?.fulfilledVideoComponents.forEach(
            (i: { dynamicRange: string; compliance: any }) => {
              if (i.compliance) {
                const status = i.compliance[0]?.status;
                if (status) {
                  if (i.dynamicRange?.split(':').slice(-1)[0] === 'hdr') {
                    complianceObj.hdrStatus = status;
                  } else {
                    complianceObj.sdrStatus = status;
                  }
                }
              } else {
                noCompliance = 'N/A';
              }
            }
          );
          if (complianceObj.sdrStatus === 'fail' && complianceObj.hdrStatus === 'fail') {
            return `Fail (SDR,HDR)`;
          } else if (
            complianceObj.sdrStatus === 'pass' &&
            complianceObj.hdrStatus === 'pass'
          ) {
            return `Pass (SDR,HDR)`;
          } else if (
            complianceObj.sdrStatus === 'pass' &&
            complianceObj.hdrStatus === 'fail'
          ) {
            return `Pass (SDR), Fail (HDR)`;
          } else if (
            complianceObj.sdrStatus === 'fail' &&
            complianceObj.hdrStatus === 'pass'
          ) {
            return `Pass (HDR), Fail (SDR)`;
          } else if (
            complianceObj.sdrStatus === 'pass with caution' &&
            complianceObj.hdrStatus === 'pass with caution'
          ) {
            return `Pass with Caution (HDR,SDR)`;
          } else if (
            complianceObj.sdrStatus === 'fail' &&
            complianceObj.hdrStatus === 'pass with caution'
          ) {
            return `Pass with Caution (HDR), Fail (SDR)`;
          }else if (
            complianceObj.sdrStatus === 'pass' &&
            complianceObj.hdrStatus === 'pass with caution'
          ) {
            return `Pass with Caution (HDR), Pass (SDR)`;
          } else if (complianceObj.sdrStatus === 'fail' && complianceObj.hdrStatus === '') {
            return `Fail (SDR)`;
          } else if (complianceObj.hdrStatus === 'fail' && complianceObj.sdrStatus === '') {
            return `Fail (HDR)`;
          } else if (complianceObj.sdrStatus === 'pass' && complianceObj.hdrStatus === '') {
            return `Pass (SDR)`;
          } else if (complianceObj.sdrStatus === '' && complianceObj.hdrStatus === 'pass') {
            return `Pass (HDR)`;
          } else if (complianceObj.sdrStatus === 'pass with caution') {
            return `Pass with Caution (SDR)`;
          } else {
            return noCompliance;
          }
        } else {
          return 'N/A';
        }
      }
      if (dyRange.length === 1) {
        if (dyRange.includes('SDR')) {
          return task.details?.fulfilledVideoComponents.map(
            (i: { dynamicRange: string; compliance: any }) => {
              if (i.compliance) {
                const status = i.compliance[0]?.status;
                if (status) {
                  return status === 'fail'
                    ? `Fail (SDR)`
                    : status === 'pass'
                    ? `Pass (SDR)`
                    : 'Pass with Caution (SDR)';
                } else {
                  return 'N/A';
                }
              } else {
                return 'N/A';
              }
            }
          );
        } else {
          return task.details?.fulfilledVideoComponents.map(
            (i: { dynamicRange: string; compliance: any }) => {
              if (i.compliance) {
                const status = i.compliance[0]?.status;
                if (status) {
                  return status === 'fail'
                    ? `Fail (HDR)`
                    : status === 'pass'
                    ? `Pass (HDR)`
                    : 'Pass with Caution (HDR)';
                } else {
                  return 'N/A';
                }
              } else {
                return 'N/A';
              }
            }
          );
        }
      }
    }
  } else {
    return 'N/A';
  }
};

function deepCompareEquals(a: any, b: any){
  return _.isEqual(a, b);
}

function useDeepCompareMemoize(value: any) {
  const ref = useRef()
  // it can be done by using useMemo as well
  // but useRef is rather cleaner and easier

  if (!deepCompareEquals(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}

export function useDeepCompareEffect(callback: EffectCallback, dependencies: any) {
  useEffect(
    callback,
    dependencies.map(useDeepCompareMemoize)
  )
}

export function getRatings(ratings: any[]) {
  return ratings.map((rating: any) => {
    const ratingAuthority = rating.ratingAuthority;
    const ratingClassifier = rating.classifier;
    const ratingDescriptor = rating.contentDescriptors.length > 0 ?  rating.contentDescriptors.map((desc:any)=>desc).join('|') : null
    if(ratingDescriptor) {
      return `${ratingAuthority} (${ratingClassifier}, ${ratingDescriptor})`
    }
    return `${ratingAuthority} (${ratingClassifier})`
  }).join(', ')
}

import { List, PlayerPlay, Download } from 'tabler-icons-react';
import { useMantineTheme, Tooltip, useComputedColorScheme } from '@mantine/core';

export const showGeneralInfoIcons = (actions: any) => {
  //Icons must be wrapped inside a valid component for the hover action to work well
  const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');
  let icons: JSX.Element[] = [];

  const getIconsStyle = (allowed: boolean) => {
    return {
      color: allowed
        ? theme.colors.blue[5]
        : colorScheme === 'dark'
        ? theme.colors.gray[5]
        : theme.colors.gray[7]
    };
  };

  if (actions && Object.keys(actions).length > 0) {
    icons = [
      <Tooltip zIndex={9999} label={actions?.list?.allowed === true ? 'Listable' : 'Not Listable'}>
        <div>
          <List style={getIconsStyle(actions?.list?.allowed)} />
        </div>
      </Tooltip>,
      <Tooltip zIndex={9999} label={actions?.play?.allowed === true ? 'Playable' : 'Not Playable'}>
        <div>
          <PlayerPlay style={getIconsStyle(actions?.play?.allowed)} />
        </div>
      </Tooltip>,
      <Tooltip zIndex={9999}
        label={actions?.download?.allowed === true ? 'Downloadable' : 'Not Downloadable'}
      >
        <div>
          <Download style={getIconsStyle(actions?.download?.allowed)} />
        </div>
      </Tooltip>
    ];
  }

  return icons;
};

import { Anchor, Box, Text, Popover, ScrollArea, Table, Stack } from '@mantine/core';
import { CodeHighlight } from '@mantine/code-highlight';

import { InformationAccordion, InformationAccordionItem } from '../../../../components';
import { getRatings } from '../../../../utils/utilities';
import _ from 'lodash';
import { useElementSize, useViewportSize } from '@mantine/hooks';
import { useDetailsStore } from '../../store';
import { useEffect, useState } from 'react';
import { fetchCSData } from '../../../../api/fetchCSData';
import { useConfigStore } from '../../../../app/store';
import { useAppConfigManager } from '../../../../components/AppConfigs/store';
import { Option } from '../../../../components/CascaderNetwork/_networks';
import { fetchJSONID } from '../../../../api/fetchJSONID';
import dayjs from 'dayjs';
import {
  latestManifestationAccordion,
  fetchManifestations
} from '../_showManifestationAccordionItems';
import { iManifestationDetailsParams } from '../../models';
import { DetailAccordion } from '../../../../components/DetailAccordion';
import {
  fulfilledAudioComponentsGetter,
  fulfilledTimedTextComponentsGetter,
  fulfilledMarkersGetter,
  fulfilledAnnotationslabelsGetter,
  fulfilledMediaDurationGetter,
  dynamicRangeIndicatorGetter
} from '../../_columnDefs/columnsHelpers/fulfilledComponentsGetters';
import {
  taskCIDGetter,
} from '../../_columnDefs/columnsHelpers/globalValueGetters';

export const LinearDetail = (props: any) => {
  const details = props.details
    ? props.details
    : useDetailsStore((state) => state.schedule);
  const { tasks, schedule, audit, csId, id, related } = details;
  const config = useConfigStore((state) => state.config);
  const networks = useAppConfigManager.getState().queryNetworks;

  const [csData, setCsData] = useState<any>({});
  const [JsonSchedule, setJsonSchedule] = useState([]);
  const [manifestations, setManifestations] = useState<any>({});

  async function fetchCS() {
    const baseUrl = config.baseApiGatewayUrl + '/graphql/search';
    let result = await fetchCSData(baseUrl, id, 'linear');
    if (result) setCsData(result);
  }

  async function fetchJsonSch() {
    const baseUrl = config.baseApiGatewayUrl + '/graphql/search';
    let result = await fetchJSONID(baseUrl, schedule.eventMetaId, 'linear');
    setJsonSchedule(result?.data?.event?.records);
  }

  useEffect(() => {
    fetchCS();
    fetchJsonSch();
    fetchManifestations(tasks, setManifestations);
  }, []);

  const idFields = useAppConfigManager
    ?.getState()
    ?.GetSearchInputs('footprint-linear')
    ?.map((field) => ({
      label: field.label,
      value: field.footprint
        ?.find((fp) => fp.footprint === 'footprint-linear')
        ?.path?.replace(/schedule./g, '')
    }));

  const piEnv = config.environment === 'prod' ? '' : `${config.environment}.`;

  const showDetail = (d: any) => {
    if (d === undefined) {
      return;
    }
    const path = d?.value?.split('.');
    let value = null;
    switch (d.label) {
      case 'Segment Id':
        if (id) value = id;
        break;
      case 'CSID':
        if (csId?.id) value = csId?.id;
        break;
      case 'CID':
        value = taskCIDGetter(tasks, 'mezzcreation');
        break;
      default:
        if (!schedule?.[path?.[0]]) return;
        let pathValue = schedule;
        path.forEach((e: string) => {
          pathValue = pathValue[e];
        });
        if (typeof pathValue !== 'string') return;
        value = pathValue;
    }
    if (!value) return;
    switch (d.label) {
      case 'Edit Id':
        const piId = schedule.segment?.alternateIds.find(
          (i: { namespace: string }) =>
            i.namespace === 'urn:wbd:identifier:program-inventory:id'
        );
        if (piId) {
          return (
            <Popover
              key={piId.id + 'Popover'}
              position="bottom"
              withArrow
              shadow="md"
              classNames={{
                arrow: 'popoverArrow',
                dropdown: 'popoverDropdownBelow'
              }}
              zIndex={9999}
            >
              <Popover.Target>
                <Table.Tr key={piId.id}>
                  <Table.Td style={{ textAlign: 'left' }}>{d.label}</Table.Td>
                  <Table.Td>
                    <Anchor type="button">{piId.id}</Anchor>
                  </Table.Td>
                </Table.Tr>
              </Popover.Target>
              <Popover.Dropdown>
                <Stack align={'center'}>
                  <Anchor
                    target="_blank"
                    href={`https://apps.${piEnv}dsuite.tech/prometheus/api/v4/canonical/asset/${piId.id}`}
                  >
                    PI
                  </Anchor>
                </Stack>
              </Popover.Dropdown>
            </Popover>
          );
        }
        break;
      case 'Season Id':
        return (
          <Popover
            key={value + 'SeasonPopover'}
            position="bottom"
            withArrow
            shadow="md"
            classNames={{
              arrow: 'popoverArrow',
              dropdown: 'popoverDropdownBelow'
            }}
            zIndex={9999}
          >
            <Popover.Target>
              <Table.Tr key={value + 'Season'}>
                <Table.Td style={{ textAlign: 'left' }}>{d.label}</Table.Td>
                <Table.Td>
                  <Anchor type="button">{value}</Anchor>
                </Table.Td>
              </Table.Tr>
            </Popover.Target>
            <Popover.Dropdown>
              <Stack align={'center'}>
                {schedule?.seriesId?.id && (
                  <Anchor
                    target="_blank"
                    href={`https://studio.${config.couiEnvironment}.api.discomax.com/content-visualization/series/${schedule?.seriesId?.id}/seasons/${value}`}
                  >
                    CV
                  </Anchor>
                )}
              </Stack>
            </Popover.Dropdown>
          </Popover>
        );
      case 'Series Id':
        return (
          <Popover
            key={value + 'SeriesPopover'}
            position="bottom"
            withArrow
            shadow="md"
            classNames={{
              arrow: 'popoverArrow',
              dropdown: 'popoverDropdownBelow'
            }}
            zIndex={9999}
          >
            <Popover.Target>
              <Table.Tr key={value + 'Series'}>
                <Table.Td style={{ textAlign: 'left' }}>{d.label}</Table.Td>
                <Table.Td>
                  <Anchor type="button">{value}</Anchor>
                </Table.Td>
              </Table.Tr>
            </Popover.Target>
            <Popover.Dropdown>
              <Stack align={'center'}>
                <Anchor
                  target="_blank"
                  href={`https://studio.${config.couiEnvironment}.api.discomax.com/content-visualization/series/${value}`}
                >
                  CV
                </Anchor>
              </Stack>
            </Popover.Dropdown>
          </Popover>
        );
      case 'CSID':
        return (
          <Popover
            key={value + 'Popover'}
            width={800}
            position="right"
            withArrow
            shadow="md"
            styles={{
              dropdown: {
                marginTop: 0
              }
            }}
            classNames={{
              arrow: 'popoverArrow',
              dropdown: 'popoverDropdown'
            }}
            zIndex={9999}
          >
            <Popover.Target>
              <Table.Tr key={value}>
                <Table.Td style={{ textAlign: 'left' }}>{d.label}</Table.Td>
                <Table.Td>
                  <Anchor type="button">{value}</Anchor>
                </Table.Td>
              </Table.Tr>
            </Popover.Target>
            <Popover.Dropdown>
              <ScrollArea mah={425}>
                <Text fw={700}>Content Subscription JSON</Text>
                <CodeHighlight code={JSON.stringify(csData, null, 2)} language="tsx" />
              </ScrollArea>
            </Popover.Dropdown>
          </Popover>
        );
      case 'Media Id':
        const acquisitionStatus = related.acquisition;
        const contentType = schedule.segment.contentType;
        const acquisitionId = contentType === 'program' ? schedule?.editId?.id : value;
        if (acquisitionStatus && acquisitionStatus.length > 0) {
          return (
            <Popover
              key={value + 'Popover'}
              position="bottom"
              withArrow
              shadow="md"
              classNames={{
                arrow: 'popoverArrow',
                dropdown: 'popoverDropdownBelow'
              }}
              zIndex={9999}
            >
              <Popover.Target>
                <Table.Tr key={value}>
                  <Table.Td style={{ textAlign: 'left' }}>{d.label}</Table.Td>
                  <Table.Td>
                    <Anchor type="button">{value}</Anchor>
                  </Table.Td>
                </Table.Tr>
              </Popover.Target>
              <Popover.Dropdown>
                <Stack align={'center'}>
                  <Anchor
                    target="_blank"
                    href={`${window.location.origin}/views/assets/keyword/${acquisitionId}`}
                  >
                    Acquisitions
                  </Anchor>
                </Stack>
              </Popover.Dropdown>
            </Popover>
          );
        }
        break;
      case 'Schedule':
        return (
          <Popover
            key={value + 'Popover'}
            position="right"
            width={800}
            withArrow
            shadow="md"
            classNames={{
              arrow: 'popoverArrow',
              dropdown: 'popoverDropdownBelow'
            }}
            zIndex={9999}
          >
            <Popover.Target>
              <Table.Tr key={value}>
                <Table.Td style={{ textAlign: 'left' }}>{d.label}</Table.Td>
                <Table.Td>
                  <Anchor type="button">{value}</Anchor>
                </Table.Td>
              </Table.Tr>
            </Popover.Target>
            <Popover.Dropdown>
              <ScrollArea h={425}>
                <Text fw={700}>JSON</Text>
                <CodeHighlight
                  code={JSON.stringify(JsonSchedule, null, 2)}
                  language="tsx"
                ></CodeHighlight>
              </ScrollArea>
            </Popover.Dropdown>
          </Popover>
        );
    }
    return (
      <Table.Tr key={d.label + value}>
        <Table.Td style={{ textAlign: 'left' }}>{d.label}</Table.Td>
        <Table.Td>{value}</Table.Td>
      </Table.Tr>
    );
  };

  const shownIDs = idFields.map(showDetail);

  const networkName = (network: string) => {
    let networkName = '';
    if (network) {
      networks.forEach((c: Option) => {
        if (c.valueList.toLowerCase() === network.toLowerCase()) {
          networkName = c.label;
        }
      });
    }
    return networkName;
  };

  const manifestationDetails = (m: string) => {
    const params: iManifestationDetailsParams = {
      tasks
    };
    switch (m) {
      case 'fulfilledAudioComponents':
        return fulfilledAudioComponentsGetter(params);
      case 'fulfilledTimedTextComponents':
        return fulfilledTimedTextComponentsGetter(params);
      case 'fulfilledMarkers':
        return fulfilledMarkersGetter(params);
      case 'fulfilledAnnotationsLabels':
        return fulfilledAnnotationslabelsGetter(params);
      case 'fulfilledMediaDuration':
        return fulfilledMediaDurationGetter(params);
      case 'dynamicRangeIndicator':
        return dynamicRangeIndicatorGetter(params);
      default:
        return null;
    }
  };

  const { ref, width } = useElementSize();

  const { height } = useViewportSize();

  if (schedule === undefined) return <></>;

  return (
    <Box
      ref={ref}
      style={{ margin: 8, marginLeft: 24, overflow: 'auto', maxHeight: height - 250 }}
    >
      <InformationAccordion
        defaultValue={['item-1', 'item-2']}
        chevronPosition="left"
        multiple={true}
        style={{ minWidth: 350, width: width }}
      >
        <InformationAccordionItem name="item-1" title="General Info">
          <Table
            striped={false}
            highlightOnHover={true}
            style={{ width: '110%', margin: -16, marginTop: -32 }}
          >
            <Table.Thead>
              <Table.Tr>
                <Table.Td>&nbsp;</Table.Td>
                <Table.Td>&nbsp;</Table.Td>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              <Table.Tr>
                <Table.Td style={{ textAlign: 'left' }}>Title</Table.Td>
                <Table.Td>
                  {schedule?.segment?.title ? (
                    schedule?.segment?.title
                  ) : (
                    <>--No Title--</>
                  )}
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td style={{ textAlign: 'left' }}>Content Type</Table.Td>
                <Table.Td>
                  {schedule.segment?.contentType ? (
                    schedule.segment.contentType
                      .split('_')
                      .slice(-1)[0]
                      .charAt(0)
                      .toUpperCase() +
                    schedule.segment.contentType.split('_').slice(-1)[0].slice(1)
                  ) : (
                    <>N/A</>
                  )}
                </Table.Td>
              </Table.Tr>
              {schedule.scheduleType && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Schedule Type</Table.Td>
                  <Table.Td>
                    {schedule.scheduleType.charAt(0).toUpperCase() +
                      schedule.scheduleType.slice(1)}
                  </Table.Td>
                </Table.Tr>
              )}
              {schedule.network && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Network Abbreviation</Table.Td>
                  <Table.Td>{schedule.network}</Table.Td>
                </Table.Tr>
              )}
              {schedule.network && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Network Name</Table.Td>
                  <Table.Td>{networkName(schedule.network)}</Table.Td>
                </Table.Tr>
              )}
              {schedule.feed && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Feed</Table.Td>
                  <Table.Td>
                    {schedule.feed.charAt(0).toUpperCase() + schedule.feed.slice(1)}
                  </Table.Td>
                </Table.Tr>
              )}
              {/* {repeatCount > 0 && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Repeat Count</Table.Td>
                  <Table.Td>{repeatCount}</Table.Td>
                </Table.Tr>
              )} */}
              {schedule.ratings?.length > 0 && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Ratings </Table.Td>
                  <Table.Td>{getRatings(schedule.ratings)}</Table.Td>
                </Table.Tr>
              )}
              <Table.Tr>
                <Table.Td style={{ textAlign: 'left' }}>TX Date</Table.Td>
                <Table.Td>
                  {schedule.startDateTime ? (
                    dayjs(schedule.startDateTime).format('MMM DD, YYYY (hh:mm a | [EST])')
                  ) : (
                    <>N/A</>
                  )}
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td style={{ textAlign: 'left' }}>Segment Date Time(ET)</Table.Td>
                <Table.Td>
                  {schedule.segment?.startDateTime ? (
                    dayjs(schedule.segment?.startDateTime).format(
                      'MMM DD, YYYY (hh:mm a | [EST])'
                    )
                  ) : (
                    <>N/A</>
                  )}
                </Table.Td>
              </Table.Tr>
              {schedule.segment.duration && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Duration</Table.Td>
                  <Table.Td>{schedule.segment.duration}</Table.Td>
                </Table.Tr>
              )}
              {schedule.playlistVersion > 0 && (
                <Table.Tr>
                  <Table.Td style={{ textAlign: 'left' }}>Schedule Version</Table.Td>
                  <Table.Td>{schedule.playlistVersion}</Table.Td>
                </Table.Tr>
              )}
              <Table.Tr>
                <Table.Td style={{ textAlign: 'left' }}>Date Received(EST)</Table.Td>
                <Table.Td>
                  {audit?.createdAt ? (
                    dayjs(audit.createdAt).format('MMM DD, YYYY (hh:mm a | [EST])')
                  ) : (
                    <>N/A</>
                  )}
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td style={{ textAlign: 'left' }}>Last Updated(EST)</Table.Td>
                <Table.Td>
                  {audit?.updatedAt ? (
                    dayjs(audit.updatedAt).format('MMM DD, YYYY (hh:mm a | [EST])')
                  ) : (
                    <>N/A</>
                  )}
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td style={{ textAlign: 'left' }}>Is Live</Table.Td>
                <Table.Td>{schedule?.segment?.isLive ? 'True' : 'False'}</Table.Td>
              </Table.Tr>
            </Table.Tbody>
          </Table>
        </InformationAccordionItem>
        {!_.isEmpty(_.compact(shownIDs)) && (
          <InformationAccordionItem name="item-2" title="IDs">
            <Table
              striped={false}
              // fontSize="xs"
              highlightOnHover={true}
              style={{ width: '110%', margin: -16, marginTop: -32 }}
            >
              <Table.Thead>
                <Table.Tr>
                  <Table.Td>&nbsp;</Table.Td>
                  <Table.Td>&nbsp;</Table.Td>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{shownIDs}</Table.Tbody>
            </Table>
          </InformationAccordionItem>
        )}
      </InformationAccordion>
      <DetailAccordion
        defaultValue={[]}
        chevronPosition="left"
        multiple={true}
        style={{ minWidth: 350, overflow: 'auto' }}
      >
        {latestManifestationAccordion(manifestations, manifestationDetails, width)}
      </DetailAccordion>
    </Box>
  );
};

export default LinearDetail;

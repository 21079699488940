import { Box, Divider, Group, Stack, Switch, useComputedColorScheme, useMantineTheme } from '@mantine/core';
import { Title } from '../../components';
import { useSearchBarConfigureStore } from './store';

export const SearchBarConfigureContent = () => {
  const configure = useSearchBarConfigureStore((state) => state);
  const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');

  return (
    <Box style={{ textAlign: 'left' }}>
      <Title testid="searchBarConfigure Content" order={4}>
        SearchBar Configuration
      </Title>
      <Divider
        mt={8}
        label="Search Criteria Selects"
        labelPosition="left"
        styles={{
          label: {
            color:
              colorScheme === 'dark' ? theme.colors.gray[6] : theme.colors.gray[3]
          }
        }}
      />
      <Stack style={{ paddingTop: 8 }}>
        <Group justify="space-between" style={{ paddingLeft: 32, paddingRight: 32 }}>
          <Switch
            label="Platform"
            checked={configure.showPlatformSelect}
            size="xs"
            styles={{
              track: {
                backgroundColor:
                  colorScheme === 'dark'
                    ? theme.colors.gray[7]
                    : theme.colors.gray[6]
              }
            }}
            onChange={(event) => configure.TogglePlatformSelect()}
          />
          <Switch
            label="Milestone"
            checked={configure.showMilestoneSelect}
            size="xs"
            styles={{
              track: {
                backgroundColor:
                  colorScheme === 'dark'
                    ? theme.colors.gray[7]
                    : theme.colors.gray[6]
              }
            }}
            onChange={(event) => configure.ToggleMilestoneSelect()}
          />
          <Switch
            label="Offering Types"
            checked={configure.showOfferingTypesSelect}
            size="xs"
            styles={{
              track: {
                backgroundColor:
                  colorScheme === 'dark'
                    ? theme.colors.gray[7]
                    : theme.colors.gray[6]
              }
            }}
            onChange={(event) => configure.ToggleOfferingTypesSelect()}
          />
        </Group>
      </Stack>
    </Box>
  );
};

export default SearchBarConfigureContent;

import _ from 'lodash';
import { useStatusCodeStore } from './store';
import { StatusCode } from './models';
import { capitalize } from '../../utils/utilities';

export interface Option {
  value: string | number;
  label?: string;
  children?: Option[];
  disabled?: boolean;
  // ?: This is set to any because value list can be one
  // ? of a few types but is throwing a linting error for .join()
  // ? even after attempting to have string[] as one of those types.
  valueList?: any;
  type?: string;
}

const processOrder = ['prep', 'fulfill', 'delivery', 'receipt', 'staging', 'transform'];

const activityOrder = ['offering', 'metadata', 'image', 'video'];

const statusOrder = [
  'error',
  'failure',
  'processing',
  'pending',
  'queued',
  'completed',
  'skipped',
  'inactive',
  'deleted'
];

export const StatusHierachy = () => {
  const statusCodes = useStatusCodeStore.getState().statusCodes;
  const activeStatusCodes = statusCodes.filter(sc => !sc.deprecated)
  let hierarchy: Option[] = [] as Option[];
  let data: any[] = [];
  for (let x = 0; x < activeStatusCodes.length; x++) {
    activeStatusCodes[x].footprints?.forEach((values) => {
      data.push({
        type: 'footprint,process,activity,task,status,statusCode',
        value: activeStatusCodes[x],
        valueList: `${values.footprint}_${values.process}_${values.activity}_${values.task}_${activeStatusCodes[x].status}_${activeStatusCodes[x].statusCode}`
      });
    });
  }
  hierarchy = data.reduce((tree, item) => {
    const ids = item.valueList.split('_');
    const types = item.type.split(',');
    const staticIds = item.valueList.split('_');
    addChild(ids, item.value, tree, staticIds, types);
    return tree;
  }, []);
  // This generates the correct full set of valueLists for each option.
  // Necessary at API query time.
  // Definitely a way to use some looping here to reduce code, but don't
  // have the time to figure it out.
  hierarchy.forEach((footprintHierarchy: Option) => {
    let footprintCodes: any[] = [];
    footprintHierarchy.children?.forEach((processHierarchy: Option) => {
      let processCodes: any[] = [];
      processHierarchy.children?.forEach((activityHierarchy: Option) => {
        let activityCodes: any[] = [];
        activityHierarchy.children?.forEach((taskHierarchy: Option) => {
          let taskCodes: any[] = [];
          taskHierarchy.children?.forEach((statusHierarchy: Option) => {
            let statusCodes: any[] = [];
            statusHierarchy.children?.forEach((statusCode: Option) => {
              const valueList = statusCode.valueList?.slice(1).join('_');
              footprintCodes.push(valueList);
              processCodes.push(valueList);
              activityCodes.push(valueList);
              taskCodes.push(valueList);
              statusCodes.push(valueList);
              statusCode.valueList = [valueList];
            });
            statusHierarchy.children?.sort((a, b) => {
              const numericalCodeA = Number(a.label?.split(' ')[0]);
              const numericalCodeB = Number(b.label?.split(' ')[0]);
              if (numericalCodeA < numericalCodeB) {
                return -1;
              } else if (numericalCodeA > numericalCodeB) {
                return 1;
              }
              return 0;
            });
            statusHierarchy.valueList = statusCodes;
          });
          taskHierarchy.children = _.sortBy(taskHierarchy.children, function (obj) {
            return _.indexOf(statusOrder, obj.label?.toLowerCase());
          });
          taskHierarchy.valueList = taskCodes;
        });
        activityHierarchy.valueList = activityCodes;
      });
      processHierarchy.children = _.sortBy(processHierarchy.children, function (obj) {
        return _.indexOf(activityOrder, obj.label?.toLowerCase());
      });
      processHierarchy.valueList = processCodes;
    });
    footprintHierarchy.children = _.sortBy(footprintHierarchy.children, function (obj) {
      return _.indexOf(processOrder, obj.label?.toLowerCase());
    });
    footprintHierarchy.valueList = footprintCodes;
  });
  return hierarchy;
};

const getLabel = (type: string, id: string | undefined, statusCode: StatusCode) => {
  switch (type) {
    case 'statusCode':
      return `${statusCode.statusNumber} - ${statusCode.details}`;
    default:
      return capitalize(id!);
  }
};

const extractStatusCodes = (obj: { children?: any[]; valueList: string }) => {
  let str = '';
  obj.children?.forEach((child) => {
    if (child.children) {
      str += extractStatusCodes(child) + ',';
    }
  });
  obj.valueList += str;
  return str;
};

const addChild = (
  ids: string[],
  value: any,
  arr: any[],
  staticIds: any[],
  types: any[]
) => {
  const id = ids.shift();
  const type = types.shift();
  const copy = _.clone(staticIds);
  while (copy[copy.length - 1] !== id) {
    copy.pop();
  }
  let index = arr.findIndex((item) => item.value === copy.join('_'));
  if (index < 0) {
    arr.push({
      value: copy.join('_'),
      label: getLabel(type, id, value),
      valueList: staticIds,
      disabled: false,
      children: []
    });
    index = arr.length - 1;
  }
  if (ids.length > 0) {
    const children = arr[index].children;
    addChild(ids, value, children, staticIds, types);
  } else arr[index].value = copy.join('_');
};

// import { getStatus } from '../../utils/getStatus';

import { ChevronRight } from 'tabler-icons-react';
import './styles/fnd-info-panel.scss';
import { useEffect, useState } from 'react';
import { getStatus } from '../../utils/getStatus';
import dayjs from 'dayjs';
import { StatusIcon } from '../Status';
import { Expandable } from './Expandable';
import { TimelineInfoBlock } from './TimelineInfoBlock';
import { useStatusCodeStore } from '../StatusCodeDictionary/store';
import { useTimelineStore } from './store/store';
import { useComputedColorScheme } from '@mantine/core';

export const InfoPanel = (props: { item: any; name: string }) => {
  const { item, name } = props;
  const [isOpen, setIsOpen] = useState(false);
  const taskFilters = useTimelineStore((state) => state.taskFilters);
  const colorScheme = useComputedColorScheme('light');

  useEffect(() => {
    setIsOpen(false);
  }, [taskFilters]);

  let status = item.status;
  let statusName =
    status?.codes.length > 0
      ? status.codes[0].code
      : status?.type.includes('type_')
      ? status?.type.split('type_')[1]
      : status?.type;
  status?.type ? (statusName = getStatus(statusName)) : (statusName = 'inactive');

  const taskName = name.split('|')[2]
    ? name.split('|')[2].toLocaleLowerCase()
    : item.type;

  let statusDetail = '';
  if (status.codes && status.codes?.length > 0) {
    const codes = status.codes.find((element: any) => 'detail' in element);
    let detail = codes?.detail;
    if (!detail || detail === codes.data) {
      const GetStatusCode = useStatusCodeStore.getState().GetStatusCode;
      let sc = GetStatusCode(codes.code);
      if (sc) {
        statusDetail = sc.details;
      }
    }
    if (detail) {
      statusDetail = detail;
    }
  }

  return (
    <div>
      <div
        className="fnd-info-bar"
        style={{
          borderColor: colorScheme === 'light' ? '#E3E3E3' : '#161820'
        }}
      >
        <div className="fnd-info-bar-left" onClick={() => setIsOpen(!isOpen)}>
          <div className={`${!isOpen ? 'chevron' : 'chevron-down'}`}>
            <ChevronRight />
          </div>
          <div className="fnd-info-bar-item-left">
            <span>{taskName}</span>
            <span className="processBreadcrumb">
              {name.split('|').slice(0, 2).join('|')}
            </span>
          </div>
        </div>
        <div className="fnd-info-bar-middle">
          {item.audit?.generatedAt && (
            <div className="fnd-info-bar-item">
              <span className="fnd-info-bar-item-label">Generated At</span>
              <span>
                {dayjs(item.audit?.generatedAt).format('MMM DD, YYYY (hh:mm a | [EST])')}
              </span>
            </div>
          )}
          {statusDetail && (
            <div className="fnd-info-bar-item">
              <span className="fnd-info-bar-item-label">Detail</span>
              <span>{statusDetail}</span>
            </div>
          )}
          {/* {fulfillmentId && (
            <div className="fnd-info-bar-item">
              <span className='fnd-info-bar-item-label'>Fulfillment ID</span>
              <span>{fulfillmentId.id}</span>
            </div>
          )} */}
          {item.details?.fulfillmentType && (
            <div className="fnd-info-bar-item">
              <span className="fnd-info-bar-item-label">Fulfillment Type</span>
              <span>{item.details?.fulfillmentType}</span>
            </div>
          )}
          {item.details?.dynamicRangeIndicator && (
            <div className="fnd-info-bar-item">
              <span className="fnd-info-bar-item-label">DRI</span>
              <span>{item.details?.dynamicRangeIndicator}</span>
            </div>
          )}
        </div>
        {statusName && (
          <span className="fnd-info-bar-item-right">
            <StatusIcon status={statusName} />
            &nbsp;
            {statusName.charAt(0).toUpperCase() + statusName.slice(1)}
          </span>
        )}
      </div>
      <div
        className="expandable-box"
        style={{
          paddingLeft: '20px',
          paddingRight: '20px',
          backgroundColor: colorScheme === 'light' ? '#e3e3e3' : '#141517'
        }}
      >
        {isOpen && (
          <Expandable>
            <TimelineInfoBlock item={item} />
          </Expandable>
        )}
      </div>
    </div>
  );
};

import { iSearch, initialSearchValues } from '../../../containers/Search/models';

export interface iWidget {
  i: string;
  h: number;
  w: number;
  x: number;
  y: number;
  milestoneName: string;
  customName: string;
  search: iSearch;
  refreshDelay: string;
  footprint: string;
}

export interface sharedWidget extends iWidget {
  id?: string;
  type?: string;
  timestamp?: number;
}

export interface iWidgets {
  [key: string]: iWidget;
}

export interface iGridComponent {
  key: string;
  component: React.ReactNode;
}

export interface iGridComponents {
  [key: string]: React.ReactNode;
}

export interface iLayoutItem {
  i: string;
  h: number;
  w: number;
  x: number;
  y: number;
  milestoneName: string;
  customName: string;
  minW: number;
  maxW: number;
  minH: number;
  maxH: number;
}

export interface iLayout {
  lg: iLayoutItem[];
  md: iLayoutItem[];
  sm: iLayoutItem[];
  xs: iLayoutItem[];
}

export const defaultLayout: iLayout = {
  lg: [] as iLayoutItem[],
  md: [] as iLayoutItem[],
  sm: [] as iLayoutItem[],
  xs: [] as iLayoutItem[]
};

export interface iPersonalHUD {
  id: string;
  type: string;
  widgets: iWidget[];
  layout: iLayout;
}

export interface iHUDFilterView {
  field: string;
  search: iSearch;
  footprint: string;
}

export interface iHUDState {
  breakpoint: string;
  gridComponents: iGridComponent[];
  onLoad: boolean;
  onDragHUD: boolean;
  personalHUD: any;
  navigate: boolean;
  widgetShareURL: string;
  hudFilterView: iHUDFilterView;
  GetPersonalHUD: () => void;
  SavePersonalHUD: (value: iPersonalHUD) => void;
  SetBreakpoint: (value: string) => void;
  SetOnLoad: (value: boolean) => void;
  SetPersonalHUD: (value: iPersonalHUD) => void;
  SetNavigate: (value: boolean) => void;
  SetHUDFilterView: (value: iHUDFilterView) => void;
  ToggleOnDragHUD: () => void;
  SetOnDragHUD: (newState: boolean) => void;
  SetWidgetShareURL: (url: string) => void;
}

export const initialHUDState: iHUDState = {
  breakpoint: 'lg',
  gridComponents: [] as iGridComponent[],
  onLoad: true,
  onDragHUD: false,
  personalHUD: {
    id: '',
    type: '',
    widgets: [],
    layout: {
      lg: [],
      md: [],
      sm: [],
      xs: []
    } as iLayout
  },
  navigate: false,
  widgetShareURL: '',
  hudFilterView: {
    field: '',
    search: initialSearchValues,
    footprint: 'footprint-offering'
  },
  SetBreakpoint: (value: string) => {},
  GetPersonalHUD: () => {},
  SetPersonalHUD: (personalHUD: iPersonalHUD) => {},
  SavePersonalHUD: (personalHUD: iPersonalHUD) => {},
  SetOnLoad: (value: boolean) => {},
  SetNavigate: (value: boolean) => {},
  SetHUDFilterView: (hudFilterView: iHUDFilterView) => {},
  ToggleOnDragHUD: () => {},
  SetOnDragHUD: (newState: boolean) => {},
  SetWidgetShareURL: (url: string) => {}
};

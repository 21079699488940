import { Accordion, AccordionProps } from '@mantine/core';
import { useEffect } from 'react';
import { useDataPanelAccordionStore } from './store';

interface iDataPanelAccordionProps extends AccordionProps<false> {
  defaultValue: string | null;
}

export const DataPanelAccordion = (props: iDataPanelAccordionProps) => {
  const { SetItems, Items } = useDataPanelAccordionStore();

  useEffect(() => {
    SetItems(props.defaultValue);
  }, [props.defaultValue]);

  const onChange = (accordiontStringValue: string | null) => {
    SetItems(accordiontStringValue);
  };


  return (
    <Accordion
      multiple={false}
      value={Items}
      onChange={onChange}
      chevronPosition="right"
      classNames={{
        label: 'accordionLabel'
      }}
    >
      {props.children}
    </Accordion>
  );
};

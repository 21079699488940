import useAxios from '../../services/axios/axios';
import {
  constructCrossPlatformObject,
  constructMilestoneObject,
  constructPartialCrossPlatformObject
} from './fetchCrossPlatformRowData';
import { iSearch } from '../../containers/Search/models';
import { useConfigStore } from '../../app/store';
import { useAppConfigManager } from '../../components/AppConfigs';
import { getStatusCodeString } from '../../utils/utilities';

export const fetchCrossPlatformStatusCount = async (
  startDate: Date | null,
  endDate: Date | null,
  searchState: iSearch,
  milestoneName: string
) => {
  const { post } = useAxios();
  const baseURL = useConfigStore.getState().config.baseApiGatewayUrl + '/graphql/search';
  const query = constructQuery(startDate, endDate, searchState, milestoneName);
  if (!query) {
    return 0;
  }

  const schema = {
    query: query
  };

  const result = await post({
    url: baseURL,
    data: JSON.stringify(schema)
  });

  if (
    result &&
    result.data &&
    result.data.affiliateStatusCount &&
    result.data.affiliateStatusCount.records
  ) {
    return result.data.affiliateStatusCount.records;
  }
  return 0;
};

export const constructQuery = (
  startDate: Date | null,
  endDate: Date | null,
  searchState: iSearch,
  milestoneName: string
) => {
  const queryFilters = constructFilters(startDate, endDate, searchState, milestoneName);
  if (!queryFilters) {
    return '';
  }

  const query = `query {
        affiliateStatusCount(
            ${queryFilters}
          ) {
            records {
              status
              count
            }
          }
        }`;

  return query;
};

const constructFilters = (
  startDate: Date | null,
  endDate: Date | null,
  filters: any,
  milestoneName: string
) => {
  //TODO investigate further
  if (milestoneName === 'affiliateOfferings_footprint') {
    milestoneName = "affiliateOfferings-footprint"
  }
  const milestoneString = constructMilestoneObject(
    filters.Milestone,
    filters.MilestoneStatus
  );

  let partialSearchInput: string = '';
  if (filters.SearchInput) {
    let searchInputs;
    if (filters.SearchInputField === 'keyword') {
      let copy = filters.SearchInput;
      let newInput = copy.replaceAll('"', '\\"');
      searchInputs = newInput;
    } else {
      searchInputs = filters.SearchInput.split(' ');
      if (searchInputs.length > 1) {
        searchInputs = searchInputs.join('","');
      }
    }
    partialSearchInput = searchInputs.toString();
  }

  let csId = '';
  if (filters.SearchInputField && filters.SearchInputField === 'csId') {
    let input = '';
    let searchInputs = filters.SearchInput.split(' ');
    if (searchInputs.length > 1) {
      input = searchInputs.join('","');
    } else {
      input = searchInputs[0];
    }
    csId = `
    csId: {
      id: ["${input}"]
    }
    `;
  }

  const crossPlaftormObject = constructCrossPlatformObject(startDate, endDate, filters);
  let partialObject = '';
  if (partialSearchInput && filters.SearchInputField === 'keyword') {
    partialObject = constructPartialCrossPlatformObject(partialSearchInput);
  }

  const taskValueMapper = useAppConfigManager.getState().taskValueMapper
  const statusCodeString = getStatusCodeString(filters.StatusCodes, taskValueMapper)

  let auditObject = ``;
  if (filters.DateField === 'lastUpdated' && filters.dateEnabled) {
    auditObject = `audit:{
            updatedAtFrom:"${startDate!.toISOString()}",
            updatedAtTo:"${endDate!.toISOString()}"
          }`;
  }

  if (!milestoneString && !crossPlaftormObject && !auditObject && !statusCodeString) {
    return '';
  }

  const queryFilters = `filters: {
        ${statusCodeString}
        ${milestoneString}
        ${crossPlaftormObject}
        ${partialObject}
        ${csId}
        ${auditObject}
        byMilestone: "${milestoneName}"
    }`;
  return queryFilters;
};

import { Box } from '@mantine/core';
import { BigPandaSettingsForm } from './BigPandaConfigForm';
import { ContainerComponent } from '../ContainerComponent';

export const BigPandaSettings = () => {
  return (
    <Box style={{ width: '70rem' }}>
      <ContainerComponent title={'Spike Detection Settings'} testid={'BigPandaSettings'}>
        <BigPandaSettingsForm />
      </ContainerComponent>
    </Box>
  );
};

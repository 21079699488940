import { Grid, Select, CloseButton, useComputedColorScheme, lighten, darken, Tooltip, Text } from '@mantine/core';
import { MultiSelect } from '../../components/MultiSelect';
import { globalColors as colors } from '../../theme/globalColors';
import classes from './SearchBar.module.css';
import { iSearch } from '../Search/models';
import { useSearchStore } from '../Search/store';
import { useSearchBarConfigureStore } from '../SearchBarConfigure/store';
import { useTID } from '../../helpers/useTestId';
// import { ValueComponent } from './_valueComponent';
import { useFootprintStore } from '../Footprints/store';
import { useAppConfigManager } from '../../components/AppConfigs/store';
import { CascaderMarket } from '../../components/CascaderMarket/CascaderMarket';
import { CascaderStatusCode } from '../../components/CascaderStatusCode/CascaderStatusCode';

export const SearchOptionsAsset = (props: { search: iSearch }) => {
  const UpdateSearchInput = useSearchStore((state) => state.UpdateSearchInput);
  const { search } = props;
  const configure = useSearchBarConfigureStore((state) => state);
  // const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');
  const GetDropDown = useAppConfigManager((state) => state.GetDropDown);

  // const { classes } = useStyles();

  const handleUpdateViewSearch = (value: any, field: string) => {
    UpdateSearchInput({ field: field, value: value! });
  };

  const activeFootprint = useFootprintStore.getState().active.id;
  const milestones = useAppConfigManager((state) =>
    state.GetDropDown('milestones:' + activeFootprint)
  );

  const playlistSearchInput = search?.SearchInputField === 'playlistSearch'

  function showSelectOfferingTypes() {
    if (configure.showOfferingTypesSelect) {
      return (
        <div style={{ position: 'relative' }}>
          <Tooltip
            label="Playlist Search requires type: Entry"
            position="top"
            opened={playlistSearchInput ? undefined : false}
            withArrow
          >
            <div>
              <MultiSelect
                {...useTID('MultiSelect', 'OfferingTypes')}
                // valueComponent={ValueComponent}
                rightSection={
                  <CloseButton
                    onMouseDown={() => {
                      handleUpdateViewSearch([], 'OfferingType');
                    }}
                    variant="transparent"
                    size={19}
                    iconSize={12}
                    {...useTID('Button', 'OfferingTypes-clear-button')}
                  />
                }
                clearable
                onChange={(v) => handleUpdateViewSearch(v, 'OfferingType')}
                disabled={playlistSearchInput}
                value={search.OfferingType || null}
                data={GetDropDown('contentClassTypes').filter(i=>i.footprint?.includes("footprint-asset"))}
                label="Content Class"
                placeholder="Select Any"
                size="xs"
                inputPropName={'OfferingType'}
                classNames={{
                  input: colorScheme === 'light' ? 'inputDark' : classes.multiselectInput
                }}
                // style={{
                //   '.mantine-Input-input': {
                //     backgroundColor:
                //       colorScheme === 'dark'
                //         ? darken(colors.wbdblue[9], 0.4)
                //         : colors.wbdblue[0],
                //     borderColor:
                //       colorScheme === 'dark'
                //         ? lighten(colors.wbdblue[9], 0.15)
                //         : '#B8D2FD'
                //   }
                // }}
              />
            </div>
          </Tooltip>
        </div>
      );
    }
    return <></>;
  }

  function showSelectMilestone() {
    if (configure.showMilestoneSelect) {
      return (
        <MultiSelect
          disabled={search.StatusCodes?.length > 0}
          {...useTID('MultiSelect', 'Milestones')}
          rightSection={
            <CloseButton
              onMouseDown={() => {
                handleUpdateViewSearch([], 'Milestone');
              }}
              variant="transparent"
              size={19}
              iconSize={12}
              {...useTID('Button', 'Milestones-clear-button')}
            />
          }
          clearable
          onChange={(v) => handleUpdateViewSearch(v, 'Milestone')}
          value={search.Milestone?.length > 0 ? search.Milestone : []}
          data={milestones}
          label="Milestone"
          placeholder="Select Any"
          size="xs"
          inputPropName={'Milestone'}
          // style={{
          //   '.mantine-Input-input': {
          //     backgroundColor:
          //       colorScheme === 'dark'
          //         ? darken(colors.wbdblue[9], 0.4)
          //         : colors.wbdblue[0],
          //     borderColor:
          //       colorScheme === 'dark'
          //         ? lighten(colors.wbdblue[9], 0.15)
          //         : '#B8D2FD'
          //   }
          // }}
        />
      );
    }
    return <></>;
  }

  function showSelectMilestoneStatus() {
    if (configure.showMilestoneStatusSelect) {
      return (
        <MultiSelect
          disabled={search.StatusCodes?.length > 0}
          {...useTID('MultiSelect', 'MilestoneStatus')}
          // valueComponent={ValueComponent}
          rightSection={
            <CloseButton
              onMouseDown={() => {
                handleUpdateViewSearch([], 'MilestoneStatus');
              }}
              variant="transparent"
              size={19}
              iconSize={12}
              {...useTID('Button', 'MilestoneStatus-clear-button')}
            />
          }
          clearable
          onChange={(v) => handleUpdateViewSearch(v, 'MilestoneStatus')}
          value={search.MilestoneStatus?.length > 0 ? search.MilestoneStatus : []}
          data={GetDropDown('milestoneStatuses')}
          label="Milestone Status"
          placeholder="Select Any"
          size="xs"
          inputPropName={'MilestoneStatus'}
          style={{
            // '.mantine-Input-input': {
              backgroundColor:
                colorScheme === 'dark'
                  ? darken(colors.wbdblue[9], 0.4)
                  : colors.wbdblue[0],
              borderColor:
                colorScheme === 'dark'
                  ? lighten(colors.wbdblue[9], 0.15)
                  : '#B8D2FD'
            // }
          }}
        />
      );
    }
    return <></>;
  }

  function showCascaderStatusCode() {
    if (configure.showCascaderStatusCode) {
      return (
        <>
          <Text>or</Text>
          <CascaderStatusCode
            disabled={search.Milestone?.length > 0 || search.MilestoneStatus?.length > 0}
          />
        </>
      );
    }
    return <></>;
  }

  function showSelectPartner() {
    if (configure.showPartnerSelect) {
      return <CascaderMarket />;
    }
    return <></>;
  }

  function showSelectEntityType() {
    if (configure.showEntityTypeSelect) {
      return (
        <div style={{ position: 'relative' }}>
          <Tooltip
            label="Playlist Search requires type: Entry"
            position="top"
            withArrow
            opened={playlistSearchInput ? undefined : false}
          >
            <div>
              <MultiSelect
                {...useTID('MultiSelect', 'EntityTypes')}
                // valueComponent={ValueComponent}
                rightSection={
                  <CloseButton
                    onMouseDown={() => {
                      handleUpdateViewSearch([], 'EntityType');
                    }}
                    variant="transparent"
                    size={19}
                    iconSize={12}
                    {...useTID('Button', 'EntityTypes-clear-button')}
                  />
                }
                clearable
                onChange={(v) => handleUpdateViewSearch(v, 'EntityType')}
                value={search.EntityType?.length > 0 ? search.EntityType : []}
                disabled={playlistSearchInput}
                data={GetDropDown('entityType').filter(i=>i.footprint?.includes("footprint-asset"))}
                label="Entity Type"
                placeholder="Select Any"
                size="xs"
                inputPropName={'EntityType'}
                style={{
                  // '.mantine-Input-input': {
                  backgroundColor:
                    colorScheme === 'dark'
                      ? darken(colors.wbdblue[9], 0.4)
                      : colors.wbdblue[0],
                  borderColor:
                    colorScheme === 'dark' ? lighten(colors.wbdblue[9], 0.15) : '#B8D2FD'
                  // }
                }}
              />
            </div>
          </Tooltip>
        </div>
      );
    }
    return <></>;
  }

  function showAdMarkers() {
    if (configure.showAdMarkersSwitch) {
      const TID = useTID('MultiSelect', 'AdMarkers');
      return (
        <Select
          {...TID}
          onChange={(v) => handleUpdateViewSearch(v, 'AdMarkers')}
          value={search?.AdMarkers?.length > 0 ? search.AdMarkers : ''}
          data={GetDropDown('adMarkers')}
          label="Ad Markers"
          placeholder="Select Any"
          size="xs"
          classNames={{
            // item: classes.item,
            input: colorScheme === 'dark' ? 'inputDark' : 'inputdd',
            label: classes.item
          }}
          style={{
            // '.mantine-Input-input': {
              backgroundColor:
                colorScheme === 'dark'
                  ? darken(colors.wbdblue[9], 0.4)
                  : colors.wbdblue[0],
              borderColor:
                colorScheme === 'dark'
                  ? lighten(colors.wbdblue[9], 0.15)
                  : '#B8D2FD'
            // }
          }}
        />
      );
    }
    return <></>;
  }

  return (
    <Grid columns={25}>
      <Grid.Col span={1}></Grid.Col>
      <Grid.Col span={4}>
        {showSelectPartner()}
      </Grid.Col>
      <Grid.Col span={4} mt={6}>
        {showSelectOfferingTypes()}
        {showSelectEntityType()}
      </Grid.Col>
      <Grid.Col span={4} mt={6}>
        {showSelectMilestone()}
        {showSelectMilestoneStatus()}
        {showCascaderStatusCode()}
      </Grid.Col>
      <Grid.Col span={4} mt={6}>{showAdMarkers()}</Grid.Col>
    </Grid>
  );
};

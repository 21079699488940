import { ValueGetterParams } from 'ag-grid-community';
import { useStatusCodeStore } from '../../../../components/StatusCodeDictionary/store';
import { formattedDate } from '../../../../helpers';
import { getPseComplianceStatus } from '../../../../utils/utilities';
import { getRatings } from '../../../../utils/utilities';
import { AnnotationslabelsGetter, AudioComponentsGetter, dynamicRangeIndicator, MarkersGetter, MediaDurationGetter, TimedTextComponentsGetter } from '../columnsHelpers/globalValueGetters';


export const fulfilledAudioComponentsGetter = (params: any) =>
  AudioComponentsGetter(
    params.data ? params?.data?.tasks : params?.tasks,
    'audioComponents',
    'manifestationregistration'
  );

export const fulfilledTimedTextComponentsGetter = (params: any) =>
  TimedTextComponentsGetter(
    params.data ? params?.data?.tasks : params?.tasks,
    'timedTextComponents',
    'manifestationregistration'
  );

export const fulfilledMarkersGetter = (params: any) =>
  MarkersGetter(
    params.data ? params?.data?.tasks : params?.tasks,
    'markers',
    'manifestationregistration'
  );

export const fulfilledAnnotationslabelsGetter = (params: any) =>
  AnnotationslabelsGetter(
    params.data ? params?.data?.tasks : params?.tasks,
    'annotationslabels',
    'manifestationregistration'
  );

export const fulfilledMediaDurationGetter = (params: any) =>
  MediaDurationGetter(
    params.data ? params?.data?.tasks : params?.tasks,
    'mediaDuration',
    'manifestationregistration'
  );

export const dynamicRangeIndicatorGetter = (params: any) =>
  dynamicRangeIndicator(
    params.data ? params?.data?.tasks : params?.tasks,
    'videoComponents',
    'manifestationregistration'
  );


export const footprintStatusGetter = (params: ValueGetterParams) => {
  const GetStatusCode = useStatusCodeStore.getState().GetStatusCode;
  const footprintStatus = params?.data?.milestones?.find(
    (element: { name: string }) => element.name === 'acquire-footprint'
  );
  let status = footprintStatus ? footprintStatus.status : null;
  if (status !== null) status = status.charAt(0).toUpperCase() + status.slice(1);
  if (footprintStatus?.codes && footprintStatus?.codes.length > 0) {
    let sc = GetStatusCode(footprintStatus.codes[0].code);
    if (sc.status && sc.badgeText !== '') {
      status =
        sc.status.charAt(0).toUpperCase() + sc.status.slice(1) + ' - ' + sc.badgeText;
    }
  }
  return status;
};

export const milestoneStatusGetter = (params: ValueGetterParams) => {
  const GetStatusCode = useStatusCodeStore.getState().GetStatusCode;
  let type = params.colDef.cellRendererParams.activity ? 'Activity' : 'Process';
  let name = params.colDef.cellRendererParams.activity
    ? `${params.colDef.cellRendererParams.field}|${params.colDef.cellRendererParams.activity}`
    : params.colDef.cellRendererParams.field;
  const milestone = params?.data?.milestones?.find(
    (element: { type: string; name: string }) =>
      element.type === type && element.name === name
  );
  let status = milestone ? milestone.status : null;
  if (status === 'na') status = 'N/A';
  if (status !== null) status = status.charAt(0).toUpperCase() + status.slice(1);
  if (milestone?.codes && milestone?.codes.length > 0) {
    let sc = GetStatusCode(milestone.codes[0].code);
    if (sc.status && sc.badgeText !== '') {
      status =
        sc.status.charAt(0).toUpperCase() + sc.status.slice(1) + ' - ' + sc.badgeText;
    }
  }
  return status;
};

export const milestoneDetailGetter = (params: ValueGetterParams) => {
  let type = params.colDef.cellRendererParams.activity ? 'Activity' : 'Process';
  let name = params.colDef.cellRendererParams.activity
    ? `${params.colDef.cellRendererParams.field}|${params.colDef.cellRendererParams.activity}`
    : params.colDef.cellRendererParams.field;

  const milestone = params.data.milestones?.find(
    (element: { type: string; name: string }) =>
      element.type === type && element.name === name
  );

  if (milestone && milestone?.codes !== null && milestone.codes.length > 0) {
    const codes = milestone.codes.find((element: any) => 'detail' in element);
    let detail = codes?.detail
    if (!codes.detail) {
      const GetStatusCode = useStatusCodeStore.getState().GetStatusCode;
      let sc = GetStatusCode(codes.code);
      if (sc) {
        detail = sc.details
      }
    }
    if (detail && codes.data) {
      return `${detail} - ${codes.data}`;
    } else if (detail) {
      return detail;
    } else if (codes.data) {
      return codes.data
    }
  }
  
  return null;
};

function truncateUUID(uuid: string) {
  return uuid.substring(0, 4) + ' ... ' + uuid.substring(uuid.length - 4, uuid.length);
}

export const contentTypeGetter = (params: ValueGetterParams) => {
  let capitalContentType;
  if (params.data.asset?.contentType) {
    const contentTypes = params.data.asset?.contentType.split(':');
    let contentType = contentTypes[contentTypes.length - 1];
    if (contentType === 'ad') {
      contentType = 'commercial';
    }
    capitalContentType = contentType.charAt(0).toUpperCase() + contentType.slice(1);
  } else {
    capitalContentType = null;
  }
  return capitalContentType;
};

export const scheduleIDGetter = (params: ValueGetterParams) => {
  return truncateUUID(params?.data?.id);
};

export const scheduleTypeGetter = (params: ValueGetterParams) => {
  let capitalScheduleType;
  if (params.data.schedule?.scheduleType) {
    capitalScheduleType =
      params.data.schedule?.scheduleType.charAt(0).toUpperCase() +
      params.data.schedule?.scheduleType.slice(1);
  } else {
    capitalScheduleType = '';
  }
  return capitalScheduleType;
};

export const createdDateGetter = (params: ValueGetterParams) => {
  let date = params?.data?.audit.createdAt;
  return formattedDate(date);
};

export const updatedDateGetter = (params: ValueGetterParams) => {
  let date = params?.data?.audit.updatedAt;
  return formattedDate(date);
};

export const titleGetter = (params: ValueGetterParams) => {
  let title = '';
  const titles = params.data.asset.titles;
  if (!titles) return '--No Title--';
  if (titles.length > 0) {
    const distribution = titles.find(
      (title: { type: string }) => title.type === 'distribution-title'
    );
    title = distribution ? distribution.full : null;
    if (!title) {
      const main = titles.find((title: { type: string }) => title.type === 'main-title');
      title = main ? main.full : null;
    }
    if (!title) {
      const cat = titles.find((title: { type: string }) => title.type === 'cat-title');
      title = cat ? cat.full : '';
    }
    if (!title) {
      const fallback = titles.find(
        (title: { type: string }) => title.type === 'internal-wm-name'
      );
      title = fallback ? fallback.full : '';
    }
    if (!title) {
      const cat = titles.find((title: { type: string }) => title.type === 'cat-title');
      title = cat ? cat.full : '';
    }
  }
  // if (!title) title = '--No Title--';
  return title;
};

export const seriesTitleGetter = (params: ValueGetterParams) => {
  let title = '';
  const titles = params.data.asset.seriesTitles;
  if (!titles) return title;
  if (titles.length > 0) {
    const distribution = titles.find(
      (title: { type: string }) => title.type === 'distribution-title'
    );
    title = distribution ? distribution.full : null;
    if (!title) {
      const main = titles.find((title: { type: string }) => title.type === 'main-title');
      title = main ? main.full : null;
    }
    if (!title) {
      const cat = titles.find((title: { type: string }) => title.type === 'cat-title');
      title = cat ? cat.full : '';
    }
    if (!title) {
      const fallback = titles.find(
        (title: { type: string }) => title.type === 'internal-wm-name'
      );
      title = fallback ? fallback.full : '';
    }
    if (!title) {
      const cat = titles.find((title: { type: string }) => title.type === 'cat-title');
      title = cat ? cat.full : '';
    }
  }
  return title;
};

export const seasonTitleGetter = (params: ValueGetterParams) => {
  let title = '';
  const titles = params.data.asset.seasonTitles;
  if (!titles) return title;
  if (titles.length > 0) {
    const distribution = titles.find(
      (title: { type: string }) => title.type === 'distribution-title'
    );
    title = distribution ? distribution.full : null;
    if (!title) {
      const main = titles.find((title: { type: string }) => title.type === 'main-title');
      title = main ? main.full : null;
    }
    if (!title) {
      const cat = titles.find((title: { type: string }) => title.type === 'cat-title');
      title = cat ? cat.full : '';
    }
    if (!title) {
      const fallback = titles.find(
        (title: { type: string }) => title.type === 'internal-wm-name'
      );
      title = fallback ? fallback.full : '';
    }
    if (!title) {
      const cat = titles.find((title: { type: string }) => title.type === 'cat-title');
      title = cat ? cat.full : '';
    }
  }
  return title;
};

export const entityTypeGetter = (params: ValueGetterParams) => {
  let capitalType;
  if (params.data.asset.entityType) {
    capitalType =
      params?.data?.asset?.entityType?.charAt(0)?.toUpperCase() +
      params?.data?.asset?.entityType?.slice(1);
  } else {
    capitalType = null;
  }
  return capitalType;
};

export const latestCommentGetter = (params: ValueGetterParams) => {
  const comment = params.data.latestComment;
  if (comment) {
    return `${comment.comment} | ${comment.audit?.createdBy} | ${formattedDate(
      comment.audit?.createdAt
    )}`;
  }
  return '';
};

export const milestoneDateGetter = (params: ValueGetterParams) => {
  let type = params.colDef.cellRendererParams.activity ? 'Activity' : 'Process';
  let name = params.colDef.cellRendererParams.activity
    ? `${params.colDef.cellRendererParams.field}|${params.colDef.cellRendererParams.activity}`
    : params.colDef.cellRendererParams.field;
  const milestone = params.data.milestones?.find(
    (element: { type: string; name: string }) =>
      element.type === type && element.name === name
  );
  let date = milestone?.audit?.updatedAt ? milestone.audit.updatedAt : null;

  if (!date) {
    return null;
  }
  return formattedDate(date);
};

export const milestoneCodeGetter = (params: ValueGetterParams) => {
  let type = params.colDef.cellRendererParams.activity ? 'Activity' : 'Process';
  let name = params.colDef.cellRendererParams.activity
    ? `${params.colDef.cellRendererParams.field}|${params.colDef.cellRendererParams.activity}`
    : params.colDef.cellRendererParams.field;

  const milestone = params.data.milestones?.find(
    (element: { type: string; name: string }) =>
      element.type === type && element.name === name
  );

  if (milestone && milestone?.codes !== null && milestone.codes.length > 0) {
    const codes = milestone.codes.find((element: any) => 'code' in element);
    if (codes.code) {
      return codes.code;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

const findPSEComplianceTask = (tasks: any[]) => {
  const task = tasks.find((t: { type: string; eventSource: string }) => {
    return t.type === 'acquireprep' && t.eventSource === 'acquire-workflow'
  });
  return task;
};

export const complianceGetter = (params: any) => {
  const tasks = params.data ? params.data.tasks : params.tasks;
  if(!tasks) 
    return 'N/A';
  if (tasks) {
    if (tasks?.length > 0) {
      const task = findPSEComplianceTask(tasks);
      if(task) {  
        return getPseComplianceStatus(task);
      }
    } else {
      return 'N/A';
    }
  }
  return null;
};

const findUploadedByTask = (tasks: any[], system: string) => {
  const task = tasks.find(
    (t: { type: string, eventSource: string }) => {
      return t.type === 'upload' && t.eventSource === 'delivery_portal'
    }
  );
  return task
}
export const uploadedByGetter = (params: any) => {
  const tasks = params.data ? params.data.tasks : params.tasks
  const system = params.data ? params.data.offering?.transcodingSystem : params?.transcodingSystem
  if (tasks){
    if (tasks?.length > 0) {
      const task = findUploadedByTask(tasks, system)
      if (task) {
        return task.details?.uploadedBy?.email;
      }
    }
  }
  return null;
};

const dynamicRangeGetter = (task: any, range: string) => {
  let videoComponents;
      if (task?.details?.videoComponents?.length > 0) {
        videoComponents = task.details?.videoComponents
        .find((i: { dynamicRange: string})=> i.dynamicRange && i.dynamicRange.toUpperCase().includes(range));
        return videoComponents;
      }
}
const taskGetter=(tasks: any[])=>{
  return tasks.find(
    (t: { type: string, eventSource: string }) => {
      return ( t.type === 'manifestationregistration') && t.eventSource === 'manifestation'
    })
}
export const hdrFrameSizeGetter = (params: any) => {
  let videoComponents;
  const tasks = params.data ? params.data.tasks : params.tasks;
       if (tasks?.length > 0) {
         const task = taskGetter(tasks);
         videoComponents= dynamicRangeGetter(task, 'HDR')
         if(videoComponents?.frameSize)
          return `${videoComponents?.frameSize?.width} x ${videoComponents?.frameSize?.height}`
         else
          return '';  
        }
    return '';
};
export const sdrFrameSizeGetter = (params: any) => {
  let videoComponents;
  const tasks = params.data ? params.data.tasks : params.tasks;
       if (tasks?.length > 0) {
        const task = taskGetter(tasks);
         videoComponents= dynamicRangeGetter(task, 'SDR')
         if(videoComponents?.frameSize)
          return `${videoComponents?.frameSize?.width} x ${videoComponents?.frameSize?.height}`
         else
          return '';      
        }
    return '';
};
export const hdrPictureAspectRatioGetter = (params: any) => {
  let videoComponents;
  const tasks = params.data ? params.data.tasks : params.tasks;
      if (tasks?.length > 0) {
        const task = taskGetter(tasks);
        videoComponents= dynamicRangeGetter(task, 'HDR')
        if(videoComponents?.pictureAspectRatio)
          return `${videoComponents?.pictureAspectRatio}`
        else 
          return ''
       }
   return '';
};
export const sdrPictureAspectRatioGetter = (params: any) => {
  let videoComponents;
  const tasks = params.data ? params.data.tasks : params.tasks;
      if (tasks?.length > 0) {
        const task = taskGetter(tasks);
        videoComponents= dynamicRangeGetter(task, 'SDR')
        if(videoComponents?.pictureAspectRatio)
          return `${videoComponents?.pictureAspectRatio}`
        else 
          return ''
       }
   return '';
};
export const hdrScanningFormatGetter = (params: any) => {
  let videoComponents;
  const tasks = params.data ? params.data.tasks : params.tasks;
      if (tasks?.length > 0) {
        const task = taskGetter(tasks);
        videoComponents= dynamicRangeGetter(task, 'HDR')
        if(videoComponents?.scanningFormat)
          return `${videoComponents?.scanningFormat?.split(':').slice(-1)[0].toUpperCase()}`
        else
          return ''
        }
   return '';
};
export const sdrScanningFormatGetter = (params: any) => {
  let videoComponents;
  const tasks = params.data ? params.data.tasks : params.tasks;
      if (tasks?.length > 0) {
        const task = taskGetter(tasks);
        videoComponents= dynamicRangeGetter(task, 'SDR')
        if(videoComponents?.scanningFormat)
          return `${videoComponents?.scanningFormat?.split(':').slice(-1)[0].toUpperCase()}`
        else
          return ''
       }
   return '';
};

export const ratingsGetter = (params: any) => {
  const asset = params.data ? params.data.asset : params.asset;
  if (asset?.ratings?.length > 0) {
    return getRatings(asset.ratings);
  }
  return null;
}

// @ts-nocheck <-- this gets rid of all of the 'issues' compiling
// the comparator fields as strings and then converting
// them to functions at runtime.
import { ColDef } from 'ag-grid-community';
import {
  entityTypeGetter,
  footprintStatusGetter,
  offeringContentSourceGetter,
  offeringTypeGetter,
  seriesTitleGetter,
  seasonTitleGetter,
  titleGetter,
  partnerGetter,
  milestoneStatusGetter,
  startDateFormatter,
  milestoneDateGetter,
  milestoneCodeGetter,
  adMarkerGetter,
  globalReleaseDateGetter,
  marketGetter,
  primaryBrandLabels,
  milestoneDetailGetter,
  hdrFrameSizeGetter,
  sdrFrameSizeGetter,
  hdrPictureAspectRatioGetter,
  sdrPictureAspectRatioGetter,
  hdrScanningFormatGetter,
  sdrScanningFormatGetter,
  complianceGetter,
  ratingsGetter,
  taskCIDGetter,
  endDateFormatter
} from './assetValueGetters';
import { comparator, dateComparator } from '../comparators';
import { secondaryBrandLabels } from '../offering/offeringValueGetters';
import {
  fulfilledAudioComponentsGetter,
  fulfilledTimedTextComponentsGetter,
  fulfilledMarkersGetter,
  fulfilledAnnotationslabelsGetter,
  fulfilledMediaDurationGetter,
  dynamicRangeIndicatorGetter
} from '../columnsHelpers/fulfilledComponentsGetters';
import {
  activePresManifAudioComponentsGetter,
  activePresManifTimedTextComponentsGetter,
  activePresManifMarkersGetter,
  activePresManifAnnotationslabelsGetter,
  activePresManifMediaDurationGetter,
  activePresManifDynamicRangeIndicatorGetter
} from '../columnsHelpers/activePresManifCompGetters';

export function SetAssetValueGetters(columnDefs: ColDef[]) {
  columnDefs.forEach((cd) => {
    let keys = Object.keys(cd);
    if (keys.includes('valueGetter')) {
      if (typeof cd.valueGetter === 'string') {
        switch (cd.valueGetter) {
          case 'entityTypeGetter':
            cd.valueGetter = entityTypeGetter;
            break;
          case 'footprintStatusGetter':
            cd.valueGetter = footprintStatusGetter;
            break;
          case 'offeringTypeGetter':
            cd.valueGetter = offeringTypeGetter;
            break;
          case 'seriesTitleGetter':
            cd.valueGetter = seriesTitleGetter;
            break;
          case 'seasonTitleGetter':
            cd.valueGetter = seasonTitleGetter;
            break;
          case 'titleGetter':
            cd.valueGetter = titleGetter;
            break;
          case 'partnerGetter':
            cd.valueGetter = partnerGetter;
            break;
          case 'marketGetter':
            cd.valueGetter = marketGetter;
            break;
          case 'startDateFormatter':
            cd.valueGetter = startDateFormatter;
            break;
          case 'endDateFormatter':
            cd.valueGetter = endDateFormatter;
            break;
          case 'milestoneStatusGetter':
            cd.valueGetter = milestoneStatusGetter;
            // let ap = cd.cellRendererParams.activity ? 'Activity' : 'Process';
            // let f = cd.headerName!.split(' ').join('|');
            // cd.valueGetter = function (params) {
            //   return milestoneStatusGetter(params);
            // };
            break;
          case 'milestoneDateGetter':
            cd.valueGetter = milestoneDateGetter;
            break;
          case 'milestoneCodeGetter':
            cd.valueGetter = milestoneCodeGetter;
            break;
          case 'adMarkerGetter':
            cd.valueGetter = adMarkerGetter;
            break;
          case 'globalReleaseDateGetter':
            cd.valueGetter = globalReleaseDateGetter;
            break;
          case 'primaryBrandLabels':
            cd.valueGetter = primaryBrandLabels;
            break;
          case 'secondaryBrandLabels':
            cd.valueGetter = secondaryBrandLabels;
            break;
          case 'milestoneDetailGetter':
            cd.valueGetter = milestoneDetailGetter;
            break;
          case 'fulfilledAudioComponentsGetter':
            cd.valueGetter = fulfilledAudioComponentsGetter;
            break;
          case 'fulfilledTimedTextComponentsGetter':
            cd.valueGetter = fulfilledTimedTextComponentsGetter;
            break;
          case 'fulfilledMarkersGetter':
            cd.valueGetter = fulfilledMarkersGetter;
            break;
          case 'fulfilledAnnotationslabelsGetter':
            cd.valueGetter = fulfilledAnnotationslabelsGetter;
            break;
          case 'fulfilledMediaDurationGetter':
            cd.valueGetter = fulfilledMediaDurationGetter;
            break;
          case 'dynamicRangeIndicatorGetter':
            cd.valueGetter = dynamicRangeIndicatorGetter;
            break;
          case 'activePresManifAudioComponentsGetter':
            cd.valueGetter = activePresManifAudioComponentsGetter;
            break;
          case 'activePresManifTimedTextComponentsGetter':
            cd.valueGetter = activePresManifTimedTextComponentsGetter;
            break;
          case 'activePresManifMarkersGetter':
            cd.valueGetter = activePresManifMarkersGetter;
            break;
          case 'activePresManifAnnotationslabelsGetter':
            cd.valueGetter = activePresManifAnnotationslabelsGetter;
            break;
          case 'activePresManifMediaDurationGetter':
            cd.valueGetter = activePresManifMediaDurationGetter;
            break;
          case 'activePresManifDynamicRangeIndicatorGetter':
            cd.valueGetter = activePresManifDynamicRangeIndicatorGetter;
            break;
          case 'hdrFrameSizeGetter':
            cd.valueGetter = hdrFrameSizeGetter;
            break;
          case 'sdrFrameSizeGetter':
            cd.valueGetter = sdrFrameSizeGetter;
            break;
          case 'hdrPictureAspectRatioGetter':
            cd.valueGetter = hdrPictureAspectRatioGetter;
            break;
          case 'sdrPictureAspectRatioGetter':
            cd.valueGetter = sdrPictureAspectRatioGetter;
            break;
          case 'hdrScanningFormatGetter':
            cd.valueGetter = hdrScanningFormatGetter;
            break;
          case 'sdrScanningFormatGetter':
            cd.valueGetter = sdrScanningFormatGetter;
            break;
          case 'complianceGetter':
            cd.valueGetter = complianceGetter;
            break;
          case 'ratingsGetter':
            cd.valueGetter = ratingsGetter;
            break;
          case 'taskCIDGetter':
            cd.valueGetter = taskCIDGetter;
            break;
          default:
            break;
        }
      }
    }
    if (keys.includes('comparator')) {
      if (typeof cd.comparator === 'string') {
        switch (cd.comparator) {
          case 'comparator':
            cd.comparator = comparator;
          case 'dateComparator':
            cd.comparator = dateComparator;
        }
      }
    }
  });
}

import { Code as MCode, CodeProps, useMantineTheme } from '@mantine/core';
import {useTID} from '../../helpers/useTestId'

interface ICodeProps extends CodeProps {
  testid: string;
}

export const Code = (props: ICodeProps) => {
  const theme = useMantineTheme();
  const TID = useTID('Code', props.testid)

  const style = {
    fontSize: '.75em',
    fontWeight: 650,
    color: theme.colors.blue[8],
    backgroundColor: '#e6ebf2'
  };

  return (
    <MCode style={style} {...props} {...TID}>
      {props.children}
    </MCode>
  );
};

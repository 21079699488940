import {
  AnnotationslabelsGetter,
  AudioComponentsGetter,
  dynamicRangeIndicator,
  MarkersGetter,
  MediaDurationGetter,
  TimedTextComponentsGetter
} from './globalValueGetters';

export const activePresManifAudioComponentsGetter = (params: any) =>
  AudioComponentsGetter(
    params.data ? params?.data?.tasks : params?.tasks,
    'audioComponents',
    'mezzgtpjobmonitor'
  );

export const activePresManifTimedTextComponentsGetter = (params: any) =>
  TimedTextComponentsGetter(
    params.data ? params?.data?.tasks : params?.tasks,
    'timedTextComponents',
    'mezzgtpjobmonitor'
  );

export const activePresManifMarkersGetter = (params: any) =>
  MarkersGetter(
    params.data ? params?.data?.tasks : params?.tasks,
    'markers',
    'mezzgtpjobmonitor'
  );

export const activePresManifAnnotationslabelsGetter = (params: any) =>
  AnnotationslabelsGetter(
    params.data ? params?.data?.tasks : params?.tasks,
    'annotationslabels',
    'mezzgtpjobmonitor'
  );

export const activePresManifMediaDurationGetter = (params: any) =>
  MediaDurationGetter(
    params.data ? params?.data?.tasks : params?.tasks,
    'mediaDuration',
    'mezzgtpjobmonitor'
  );

export const activePresManifDynamicRangeIndicatorGetter = (params: any) =>
  dynamicRangeIndicator(
    params.data ? params?.data?.tasks : params?.tasks,
    'videoComponents',
    'mezzgtpjobmonitor'
  );

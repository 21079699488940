import { create } from 'zustand';
import { iGridStore, initialActiveGridState } from './models';

export const useGridStore = create<iGridStore>()(
  (set, get) => ({
    grid: initialActiveGridState,
    activeRowIndex: -1,
    gridLayoutModified: false,
    SetActiveRowIndex: (newIndex: number) => set({ activeRowIndex: newIndex }),
    SetGridLayoutModified: (newValue?: boolean) => {
      set({ gridLayoutModified: newValue || !get().gridLayoutModified });
    },
  })
);

// sets data for status buttons

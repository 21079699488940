import axios from 'axios';
import { getAxiosConfig } from './../axios/config';
import { useConfigStore } from '../../app/store';
import { useCommentStore } from '../../containers/Comments/store';
import { useFootprintStore } from '../../containers/Footprints/store';
// import { iComment } from '../../containers/Comments/models';

export const CommentsService = {
  getComments: async (id: string) => {
    const baseURL = useConfigStore.getState().config.baseApiGatewayUrl;
    const { headers } = getAxiosConfig();
    const footprintApiName = mapFootprintName()
    const res = await axios.get(baseURL + `/rest/v1/comments/${footprintApiName}/${id}`, {
      headers
    });
    // return res.data.filter(
    //   (c: iComment) => c.audit.createdBy === email || c.audit.createdBy === 'system'
    // );
    return res.data.filter((d: any) => !d.isDeleted);
  },
  createComment: async (comment: string, id: string, rowId: string) => {
    const baseURL = useConfigStore.getState().config.baseApiGatewayUrl;
    const { headers } = getAxiosConfig();
    const footprintApiName = mapFootprintName()
    const res = await axios.post(
      baseURL + `/rest/v1/comments/${footprintApiName}/${id}`,
      { comment },
      { headers }
    );
    useCommentStore.getState().GetComments(id, rowId);
    return res.data;
  },
  deleteComment: async (id: string, commentId: string, rowId: string) => {
    const baseURL = useConfigStore.getState().config.baseApiGatewayUrl;
    const { headers } = getAxiosConfig();
    const footprintApiName = mapFootprintName()
    const res = await axios.delete(
      baseURL + `/rest/v1/comments/${commentId}/${footprintApiName}/${id}`,
      { headers }
    );
    useCommentStore.getState().GetComments(id, rowId);
    return res.data;
  }
};


const mapFootprintName = () => {
  const activeFootprint = useFootprintStore.getState().active.id;
  let footprintApiName = ''
  switch(activeFootprint){
    case 'footprint-offering':
      footprintApiName = 'offerings'
      break
    case 'footprint-linear':
      footprintApiName = 'linear'
      break
    case 'footprint-acquire':
      footprintApiName = 'acquisitions'
      break
    default:
      footprintApiName = 'offerings'
      break
  }
  return footprintApiName
}
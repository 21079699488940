import {
  Container as MContainer,
  ContainerProps,
  Divider,
  Group,
  Text,
  useComputedColorScheme,
  useMantineTheme,
  darken,
  lighten
} from '@mantine/core';
import { useTID } from '../../helpers/useTestId';
import { globalColors as colors } from '../../theme/globalColors';

interface IContainerProps extends ContainerProps {
  title: string;
  rightSection?: JSX.Element;
  testid: string;
}

export const ContainerComponent = ({ title, ...props }: IContainerProps) => {
  const TID = useTID('ContainerComponent', props.testid);
  const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');
  return (
    <MContainer
      className={'baseContainer'}
      {...TID}
      style={{
        maxWidth: '100%',
        backgroundColor:
          colorScheme === 'dark' ? darken(colors.wbdblue[9], 0.4) : '#E6EFFF',
        border: '1px solid',
        borderColor:
          colorScheme === 'dark'
            ? darken(colors.wbdblue[6], 0.3)
            : lighten(colors.wbdblue[3], 0.6)
      }}
    >
      <Group justify="space-between">
        <Text
          size="lg"
          ml={6}
          mb={12}
          c={colorScheme === 'dark' ? '#808088' : theme.colors.titan[9]}
        >
          <b>{title}</b>
        </Text>
        {props.rightSection}
      </Group>
      <Divider
        mt={-8}
        mb={12}
        color={colorScheme === 'dark' ? '#5c5c61' : theme.colors.titan[5]}
      />
      {props.children}
    </MContainer>
  );
};

export default ContainerComponent;

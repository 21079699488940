export interface iTimelineState {
  taskFilters: any[];
  isStatusCode: boolean;
  SetTaskFilters: (newFilters: any[]) => void;
  SetIsStatusCode: (explicit: boolean) => void;
}

export const initialTimelineStateValues: iTimelineState = {
  taskFilters: [],
  isStatusCode: false,
  SetTaskFilters: (newFilters: any[]) => {},
  SetIsStatusCode: () => {}
};

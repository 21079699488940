export interface iSearchBarState {
    searchBarOpen: boolean 
    shareModalOpen: boolean 
    ToggleSearchBarOpen: () => void;
    ToggleShareModalOpen: () => void;
}

export const initialSearchBarValues: iSearchBarState = {
    searchBarOpen: false,
    shareModalOpen: false,
    ToggleSearchBarOpen: () => {},
    ToggleShareModalOpen: () => {},
}
import { Popover, useComputedColorScheme, useMantineTheme } from '@mantine/core';
// import { Button } from '../../components/';
// import { Settings } from 'tabler-icons-react';
import SearchBarConfigureContent from './SearchBarConfigureContent';
import { useSearchBarConfigureStore } from './store';
// import { useHover } from '@mantine/hooks';

export const SearchBarConfigureButton = () => {
  const searchBarModalOpen = useSearchBarConfigureStore((state) => state.searchBarConfigureModalOpen);
  const ToggleSearchBarConfigureModalOpen = useSearchBarConfigureStore((state) => state.ToggleSearchBarConfigureModalOpen);
  // const { ref } = useHover();
  const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');
  return (
    <Popover
      opened={searchBarModalOpen}
      onClose={() => ToggleSearchBarConfigureModalOpen()}
      width={1000}
      position="left"
      withArrow
      arrowOffset={20}
      styles={{
        arrow: {
          border: `1px ${theme.colors.gray[6]} solid`,
          backgroundColor:
            colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[1]
        },
        dropdown: {
          border: `1px ${theme.colors.gray[6]} solid`,
          backgroundColor:
            colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[1]
        }
      }}
    >
      {/* <Popover.Target>
        <div ref={ref}>
          <Button
            testid="SearchBarConfigureButton"
            leftIcon={<Settings strokeWidth={1.5} />}
            color="gray"
            variant={searchBarModalOpen ? 'filled' : 'outline'}
            onClick={() => setsearchBarModalOpen(!searchBarModalOpen)}
            style={{ minWidth: 160 }}
          >
            Configure
          </Button>
        </div>
      </Popover.Target> */}
      <Popover.Dropdown>
        <SearchBarConfigureContent />
      </Popover.Dropdown>
    </Popover>
  );
};

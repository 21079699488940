import { useConfigStore } from '../app/store';
import useAxios from '../services/axios/axios';

export const fetchLatestDeliverables = async (
  data: any,
  footprint: string,
  parent: string,
  child: string,
  statusObject: boolean
) => {
    const { get } = useAxios();
    const baseURL = useConfigStore.getState().config.baseApiGatewayUrl

    let footprintId = '';
    let footprintType = '';
    // Add additional footprints as needed
    switch (footprint) {
      case 'footprint-offering':
        footprintId = data.offering.id;
        footprintType = 'offering';
        break;
      case 'footprint-linear':
        footprintId = data.id;
        footprintType = 'schedule';
        break;
      case 'footprint-acquire':
        footprintId = data.id;
        footprintType = 'acquisition';
        break;
      case 'footprint-asset':
        footprintId = data.id;
        footprintType = 'offeringsAsset';
        break;
      case 'footprint-crossplatform':
        footprintId = data.offering.id;
        footprintType = 'affiliateOfferings';
        break;
      default:
        footprintId = data.offering.id;
        footprintType = 'offering';
        break;
    }

    let taskNames
    if (statusObject) {
      const tasks = Object.keys(data.status).filter(
        (i) => i.split('|')[0] === parent && i.split('|')[1] === child
      );
    
      taskNames = tasks
        .filter((i) => i.split('|').length === 3)
        .map((i) => {
          let taskName = i.split('|')[2].toLocaleLowerCase();
          if (taskName === 'metabmmvalidate' || taskName === 'metabmmjobmonitor') {
            taskName = 'metacontent' + taskName.slice(4);
          }
          return taskName;
        });
    } else {
      taskNames = data.milestones
      .filter(
        (milestone: { type: string; name: string }) =>
          milestone.type === 'Task' &&
          milestone.name.split('|')[1] === child &&
          milestone.name.split('|')[0] === parent
      )
      .map((milestone: { name: string }) => {
        let taskName = milestone.name.split('|')[2].toLocaleLowerCase();
        if (taskName === 'metabmmvalidate' || taskName === 'metabmmjobmonitor'){
          taskName = 'metacontent' + taskName.slice(4)
        }
        return taskName
      });
    }
    const latestDeliverables = await Promise.all(taskNames.map(async (task: any) => {
      const url = `${baseURL}/rest/deliverables/v1/${footprintType}/${footprintId}?task=${task}`
      const deliverableResult = await get({ url: url });
      return deliverableResult
    }))

    if (latestDeliverables.length > 0) {
        return latestDeliverables.flat()
    } else {
        return []
    }
};

import { getAxiosConfig } from '../axios/config';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { iPersonalHUD, iWidget, sharedWidget } from '../../components/Hud/state/models';
import { useConfigStore } from '../../app/store';
import dayjs from 'dayjs';

export const HUDService = {
  getPersonalHUD: async () => {
    const { OKTAConfig, headers } = getAxiosConfig();
    const uid = OKTAConfig.idToken.claims.sub;
    const email = OKTAConfig.idToken.claims.email;
    const emailName = email.split('@')[0];
    // API call for Views data by UserID
    const baseURL = useConfigStore.getState().config.baseApiGatewayUrl
    let res;
    res = await axios.get(baseURL + `/rest/appconfigs/v1?type=hud:${uid}`, {
      headers
    });
    if(res.data.length === 0) {
      console.log('no hud from uid')
      res = await axios.get(baseURL + `/rest/appconfigs/v1?type=hud:${emailName}`, {
        headers
      });
      res.data.forEach(async(view: any) => {
        const id = view.id;
        const viewUpdate =  {
          ...view, 
          type: `hud:${uid}:${emailName}`
        }
        await axios.put(baseURL + `/rest/appconfigs/v1/${id}`, viewUpdate, { headers });
        // return res.data;   

      });
    }
    return res.data;
  },
  upsertHUD: async (id: string, hud: iPersonalHUD) => {
    // const { OKTAConfig } = getAxiosConfig();
    const { OKTAConfig, headers } = getAxiosConfig();
    const uid = OKTAConfig.idToken.claims.sub;
    const email = OKTAConfig.idToken.claims.email;
    const emailName = email.split('@')[0];
    // API call for Views data by UserID
    let copyHud = Object.assign({}, hud);
    copyHud.type = `hud:${uid}:${emailName}`
    if (!id) {
      id = uuidv4();
      copyHud.id = id;
    }
    const baseURL = useConfigStore.getState().config.baseApiGatewayUrl;
    const res = await axios.put(baseURL + `/rest/appconfigs/v1/${id}`, copyHud, {
      headers
    });
    return res.data;
  },
  shareWidget: async (widget: iWidget) => {
    const baseURL = useConfigStore.getState().config.baseApiGatewayUrl;
    const email = useConfigStore.getState().userData.email;
    const { headers } = getAxiosConfig();

    const sharedWidget: sharedWidget = widget;
    sharedWidget.id = uuidv4();
    sharedWidget.timestamp = dayjs().utc().valueOf();
    sharedWidget.type = 'shared:widget:' + email;

    const res = await axios.put(
      baseURL + `/rest/appconfigs/v1/${sharedWidget.id}`,
      sharedWidget,
      { headers }
    );
    return res.data;
  },
  getWidgetByID: async (id: string) => {
    const { headers } = getAxiosConfig();
    const baseURL = useConfigStore.getState().config.baseApiGatewayUrl;
    const res = await axios.get(baseURL + `/rest/appconfigs/v1?id=${id}`, {
      headers
    });
    return res.data;
  },
  deleteHUD: async (id: string) => {
    const { headers } = getAxiosConfig();
    // API call for deleting a view by view ID
    const baseURL = useConfigStore.getState().config.baseApiGatewayUrl;
    const res = await axios.delete(baseURL + `/rest/appconfigs/v1/${id}`, { headers });
    return res.data;
  }
};

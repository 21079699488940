import { Flex, Text } from '@mantine/core';

export const FlexedText = (props: { text: string; label: string }) => {
  const { text, label } = props;
  return (
    <Flex>
      <h4 style={{ margin: 0, marginRight: 3 }}>{label}:</h4>
      <Text> {text}</Text>
    </Flex>
  );
};

import { create } from 'zustand';
import { iFootprint, iFootprintState, initialFootprintValues } from './models';
import { FootprintsService } from '../../services/footprints/footprintsService';
import { useConfigStore, useUserPrefsStore } from '../../app/store';
import { useSavedViewsStore } from '../SavedViews/store';
import { useHUDStore } from '../../components/Hud/state';
import { getObjFromSharedSearchURL } from '../../utils/utilities';
import { mapUrlFootprint } from '../../utils/utilities';
import { hasOKTAGroup } from '../../utils/oktaGroups';

export const useFootprintStore = create<iFootprintState>()((set, get) => ({
  ...initialFootprintValues,
  LoadFootprints: async () => {
    const SetRowData = useSavedViewsStore.getState().SetRowData;
    SetRowData([]);
    const baseApiGatewayUrl = useConfigStore.getState().config.baseApiGatewayUrl;
    const defaultFootprintId = useUserPrefsStore.getState().defaultFootprint;
    let footprints = await FootprintsService.getFootprints(baseApiGatewayUrl);
    const config = useConfigStore.getState().config
    const isAdmin = hasOKTAGroup(config.environment, config.oktaAdminGroup);
    if (!isAdmin) {
      footprints = footprints.filter((i: { id: string; })=>i.id !== 'footprint-asset' && i.id !== 'footprint-crossplatform')
    }
    if (window.location.href.split('/').length > 4) {
      if (window.location.href.split('/')[4] === 'shared') {
        let searchObject: any = await getObjFromSharedSearchURL(window.location.href);
        let defaultFootprint = footprints.find(
          (f: iFootprint) => f.id === searchObject.footprint
        );
        set({ footprints, active: defaultFootprint });
      } else {
        const urlFootprint = mapUrlFootprint(window.location.href.split('/')[4])
        let defaultFootprint = footprints.find(
          (f: iFootprint) => f.id === defaultFootprintId
        );
        if (urlFootprint && (defaultFootprint.id !== urlFootprint)) {
          defaultFootprint = footprints.find(
            (f: iFootprint) => f.id === urlFootprint
          );
        }
        set({ footprints, active: defaultFootprint });
      }
    } else {
      let defaultFootprint = footprints.find(
        (f: iFootprint) => f.id === defaultFootprintId
      );
      // If this user doesn't have a default footprint, set it to footprint-offering (On Demand)
      if (!defaultFootprint) {
        defaultFootprint = footprints.find(
          (f: iFootprint) => f.id === 'footprint-offering'
        );
        await useUserPrefsStore.getState().SetDefaultFootprint('footprint-offering');
      }
      // Check if sending from HUD and override default
      const hudFilterView = useHUDStore.getState().hudFilterView;
      if (hudFilterView.field) {
        defaultFootprint = footprints.find(
          (f: iFootprint) => f.id === hudFilterView.footprint
        );
      }
      set({ footprints, active: defaultFootprint });
    }
  },
  SetActiveFootprint: (id: string | null, onLoad?: boolean) => {
    useSavedViewsStore.setState({ isLoading: true });
    useSavedViewsStore.setState({ isFetching: true });
    const fps = get().footprints;
    const newActive = fps.find((i) => i.id === id);
    const views = [
      ...useSavedViewsStore.getState().global,
      ...useSavedViewsStore.getState().personal
    ];
    const globalDefaultViewForFootprint = views.find((v) => {
      if (v.footprint === newActive?.id && v.id.split('-')[0] === 'default') {
        return v;
      }
    });
    if (newActive!.id! !== get().active.id) {
      set({ active: newActive });
      useSavedViewsStore.getState().SetRowData([]);
      // Change active view if changing footprints to the default view for that footprint
      const personalDefaultViewForFootprint = views.find((v) => {
        if (v.footprint === newActive?.id && v.default) {
          return v;
        }
      });
      if (personalDefaultViewForFootprint) {
        useSavedViewsStore.getState().SetActiveView(personalDefaultViewForFootprint!.id);
      } else {
        useSavedViewsStore.getState().SetActiveView(globalDefaultViewForFootprint!.id);
      }
    } else {
      useSavedViewsStore.getState().SetActiveView(globalDefaultViewForFootprint!.id);
    }
    useSavedViewsStore.setState({ isLoading: false });
    useSavedViewsStore.setState({ isFetching: false });
  }
}));

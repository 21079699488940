import { Center, Grid, Image, Stack, Text } from '@mantine/core';
import { ContainerComponent } from '../../components/ContainerComponent';

export const Team = () => {
  // const theme = useMantineTheme();

  return (
    <ContainerComponent testid="Demo" title="The Team">
      <Stack>
          <Image src="/DOT.png" fit="contain" w={50} style={{ margin: 'auto'}}/>
        <Center>
          <Text>
            The DOT (<b>D</b>istribution <b>O</b>bservability and <b>T</b>racking) Team.
          </Text>
          <br />
        </Center>
        <Center>Mike Palumbo - Senior Technical Director</Center>
        <Grid style={{ width: '100%', marginLeft: 16, marginRight: 48 }}>
          <Grid.Col span={6}>
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <td></td>
                  <td></td>
                </tr>
              </thead>
              <tbody>

              <tr></tr>
              <tr>
                <td colSpan={2}>
                  <Text fw={700} mt={8}>
                    Front End / UI &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{' '}
                  </Text>
                </td>
              </tr>
              <tr>
                <td>Bart Alcorn</td>
                <td>Technical Manager</td>
              </tr>
              <tr>
                <td>Ben Lee</td>
                <td>Senior Software Engineer</td>
              </tr>
              <tr>
                <td>Jordan Rivers</td>
                <td>Software Engineer II</td>
              </tr>
              <tr>
                <td>David Zuluaga</td>
                <td>Developer</td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <Text fw={700} mt={8}>
                    Back End / Data&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{' '}
                  </Text>
                </td>
              </tr>
              <tr>
                <td>Amoudha Parikchithu</td>
                <td>Technical Manager</td>
              </tr>
              <tr>
                <td>Harprit Singh</td>
                <td>Technical Lead</td>
              </tr>
              <tr>
                <td>Hemantha Basavaraju</td>
                <td>Software Engineer</td>
              </tr>
              <tr>
                <td>Janardhan H</td>
                <td>Developer</td>
              </tr>
              <tr>
                <td>Amar Jain </td>
                <td>Developer</td>
              </tr>
              <tr>
                <td>Amit Shaw </td>
                <td>Developer</td>
              </tr>
              <tr>
                <td>Kanupriya Rai </td>
                <td>Developer</td>
              </tr>
              <tr>
                <td>VeeraVenkataSujit Seela </td>
                <td>Developer</td>
              </tr>
              </tbody>
            </table>
          </Grid.Col>
          <Grid.Col span={6}>
            <table>
              <thead>
                <tr>
                  <td></td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
              <tr>
                <td colSpan={2}>
                  <Text fw={700} mt={8}>
                    Architecture &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{' '}
                  </Text>
                </td>
              </tr>
              <tr>
                <td>Shoaib Akhtar</td>
                <td>Technical Manager</td>
              </tr>
                <tr>
                <td colSpan={2}>
                  <Text fw={700} mt={8}>
                    Program&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{' '}
                  </Text>
                </td>
              </tr>
              <tr>
                <td>Gabe Pasillas</td>
                <td>Product Director</td>
              </tr>
              <tr>
                <td>Will Yolen</td>
                <td>Product Manager</td>
              </tr>
              <tr>
                <td>
                  <Text fw={700} mt={8}>
                    Stakeholder Liasons&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{' '}
                  </Text>
                </td>
                <td>
                  <Text fw={700} mt={8}>
                    QA&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{' '}
                  </Text>
                </td>
              </tr>
              <tr>
                <td>Jori Rowe</td>
                <td>Alisha Shah </td>
              </tr>
              <tr>
                <td>James Wolcott</td>
                <td>Amir Sikandar </td>
              </tr>
              <tr>
                <td>
                  <Text fw={700} mt={8}>
                    Support&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{' '}
                  </Text>
                </td>
                <td>Eric Paulding </td>
              </tr>
              <tr>
                <td>Sam George</td>
                <td>Keshav Mishra </td>
              </tr>
              <tr>
                <td>
                  <Text fw={700} mt={8}>
                    Platform Engineering&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{' '}
                  </Text>
                </td>
              </tr>
              <tr>
                <td>Juned Hussain</td>
              </tr>
              <tr>
                <td>Landon Davidson</td>
              </tr>
              <tr>
                <td>Veera Perumal</td>
              </tr>
              <tr>
                <td>Sarath Chandra</td>
              </tr>
              </tbody>
            </table>
          </Grid.Col>
        </Grid>
      </Stack>
    </ContainerComponent>
  );
};
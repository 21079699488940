import { Grid, CloseButton, useComputedColorScheme, lighten, darken, Text } from '@mantine/core';
import { MultiSelect } from '../../components/MultiSelect';
import { globalColors as colors } from '../../theme/globalColors';
import { iSearch } from '../Search/models';
import { useSearchStore } from '../Search/store';
import { useSearchBarConfigureStore } from '../SearchBarConfigure/store';
import { useTID } from '../../helpers/useTestId';
// import { ValueComponent } from './_valueComponent';
import { useFootprintStore } from '../Footprints/store';
import { useAppConfigManager } from '../../components/AppConfigs/store';
import { CascaderDestination } from '../../components/CascaderDestination/CascaderDestination';
import { CascaderStatusCode } from '../../components/CascaderStatusCode/CascaderStatusCode';

export const SearchOptionsCrossPlatform = (props: { search: iSearch }) => {
  const UpdateSearchInput = useSearchStore((state) => state.UpdateSearchInput);
  const { search } = props;
  const configure = useSearchBarConfigureStore((state) => state);
  // const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');
  const GetDropDown = useAppConfigManager((state) => state.GetDropDown);

  // const { classes } = useStyles();

  const handleUpdateViewSearch = (value: any, field: string) => {
    UpdateSearchInput({ field: field, value: value! });
  };

  const activeFootprint = useFootprintStore.getState().active.id;
  const milestones = useAppConfigManager((state) =>
    state.GetDropDown('milestones:' + activeFootprint)
  );

  function showSelectMilestone() {
    if (configure.showMilestoneSelect) {
      return (
        <MultiSelect
          disabled={search.StatusCodes?.length > 0}
          {...useTID('MultiSelect', 'Milestones')}
          rightSection={
            <CloseButton
              onMouseDown={() => {
                handleUpdateViewSearch([], 'Milestone');
              }}
              variant="transparent"
              size={19}
              iconSize={12}
              {...useTID('Button', 'Milestones-clear-button')}
            />
          }
          clearable
          onChange={(v) => handleUpdateViewSearch(v, 'Milestone')}
          value={search.Milestone?.length > 0 ? search.Milestone : []}
          data={milestones}
          label="Milestone"
          placeholder="Select Any"
          size="xs"
          inputPropName={'Milestone'}
          // style={{
          //   '.mantine-Input-input': {
          //     backgroundColor:
          //       colorScheme === 'dark'
          //         ? darken(colors.wbdblue[9], 0.4)
          //         : colors.wbdblue[0],
          //     borderColor:
          //       colorScheme === 'dark'
          //         ? lighten(colors.wbdblue[9], 0.15)
          //         : '#B8D2FD'
          //   }
          // }}
        />
      );
    }
    return <></>;
  }

  function showSelectMilestoneStatus() {
    if (configure.showMilestoneStatusSelect) {
      return (
        <MultiSelect
          disabled={search.StatusCodes?.length > 0}
          {...useTID('MultiSelect', 'MilestoneStatus')}
          // valueComponent={ValueComponent}
          rightSection={
            <CloseButton
              onMouseDown={() => {
                handleUpdateViewSearch([], 'MilestoneStatus');
              }}
              variant="transparent"
              size={19}
              iconSize={12}
              {...useTID('Button', 'MilestoneStatus-clear-button')}
            />
          }
          clearable
          onChange={(v) => handleUpdateViewSearch(v, 'MilestoneStatus')}
          value={search.MilestoneStatus?.length > 0 ? search.MilestoneStatus : []}
          data={GetDropDown('milestoneStatuses')}
          label="Milestone Status"
          placeholder="Select Any"
          size="xs"
          inputPropName={'MilestoneStatus'}
          style={{
            // '.mantine-Input-input': {
              backgroundColor:
                colorScheme === 'dark'
                  ? darken(colors.wbdblue[9], 0.4)
                  : colors.wbdblue[0],
              borderColor:
                colorScheme === 'dark'
                  ? lighten(colors.wbdblue[9], 0.15)
                  : '#B8D2FD'
            // }
          }}
        />
      );
    }
    return <></>;
  }

  function showCascaderStatusCode() {
    if (configure.showCascaderStatusCode) {
      return (
        <>
          <Text>or</Text>
          <CascaderStatusCode
            disabled={search.Milestone?.length > 0 || search.MilestoneStatus?.length > 0}
          />
        </>
      );
    }
    return <></>;
  }



  return (
    <Grid columns={25}>
      <Grid.Col span={1}></Grid.Col>
      <Grid.Col span={4}>
        <CascaderDestination />
      </Grid.Col>
      <Grid.Col span={4} mt={6}>
        {showSelectMilestone()}
        {showSelectMilestoneStatus()}
        {showCascaderStatusCode()}
      </Grid.Col>
    </Grid>
  );
};

import { Box, Grid, Image, Stack, Text, useComputedColorScheme } from '@mantine/core';
import { useEffect, useMemo } from 'react';
// import { BroadcastButton } from '../../components';
import { ContainerComponent } from '../../components/ContainerComponent';
import { usePageMonitor } from '../../components/PageMonitor/PageMonitor';
import { Team } from './Team';
import HowTo from './HowTo';

export function Home() {
  // const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');
  const pagerChannel = useMemo(() => new BroadcastChannel('oldPage'), []);
  const channel = useMemo(() => new BroadcastChannel('hud-sync'), []);
  const [_, handlers] = usePageMonitor();

  useEffect(() => {
    handlers.load('Home');
    channel.addEventListener('message', (event) => {});
    pagerChannel.addEventListener('message', (event) => {
      if (event.data.ping && event.data.ping === 'Home') {
        pagerChannel.postMessage({ pong: 'Home' });
      }
    });
  }, []);

  return (
    <Grid style={{ marginLeft: 5, marginRight: 5 }}>
      <Grid.Col span={4}>
        <Grid>
          <Grid.Col span={12}>
            <ContainerComponent testid="Demo" title="Welcome" style={{ width: '50%' }}>
              <Stack>
                <Image
                  src={
                    colorScheme === 'dark'
                      ? 'DiSCOv5_logo_dark.svg'
                      : 'DiSCOv5_logo.svg'
                  }
                  fit="contain"
                  h={60}
                  // w="auto"
                  // mt={4}
                  w={{ base: 200 }}
                  ta="center"
                  mx="auto"
                />
                <Stack align="center">
                  <Text>
                    <b>Di</b>gital <b>S</b>upply <b>C</b>hain <b>O</b>verview
                  </Text>
                  <Text>DiSCO is the Observability tool for BOLT.</Text>
                </Stack>
              </Stack>
            </ContainerComponent>
          </Grid.Col>
          <Grid.Col span={12} className='howTo'>
            <HowTo />
          </Grid.Col>
        </Grid>
      </Grid.Col>
      <Grid.Col span={8}>
        <Box style={{ width: '100%' }}>
          <Team />
        </Box>
      </Grid.Col>
    </Grid>
  );
}

export default Home;

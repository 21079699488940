import { useStatusCountsStore } from './../StatusCounts/store';
import { create } from 'zustand';
import { iSavedViews, initialSavedViewsValues } from './models';
import { ViewsService } from '../../services/views/viewsService';
import {
  createRelativeDateRangeString,
  // createViewsList,
  deriveDateFromRelativeString,
  getObjFromSharedSearchURL,
  createViewsGroupList,
  createSharedSearchURL
} from '../../utils/utilities';
import { View } from '../Views/stores/grid/models';
import { iSelectItem } from '../../components/ListSortable/models';
import { useHUDStore } from '../../components/Hud/state';
import { useFootprintStore } from '../Footprints/store';
import { iSearch, initialSearchValues } from '../Search/models';
import { hasOKTAGroup } from '../../utils/oktaGroups';
import { maxRecordsToFetch } from '../../constants/constants';
import { useConfigStore } from '../../app/store';
import { useViewStore } from '../Views/store';
import { useSearchStore } from '../Search/store';
import { getStatus } from '../../utils/getStatus';
import { Result } from '../../api/offering/fetchOfferingRowData';
import _ from 'lodash';
import dayjs from 'dayjs';
import { useAppConfigManager } from '../../components/AppConfigs/store';
import { constructQuery as constructQueryOffering } from '../../api/offering/fetchOfferingStatusCount';
import { constructQuery as constructQueryLinear } from '../../api/linear/fetchLinearStatusCount';
import { constructQuery as constructQueryAcquire } from '../../api/acquire/fetchAcquireStatusCount';
import { constructQuery as constructQueryAsset } from '../../api/asset/fetchAssetStatusCount';
import { constructQuery as constructQueryCrossplatform } from '../../api/crossplatform/fetchCrossPlatformStatusCount';
import { Notification } from '../../components/Notification/models';
import { useNotificationStore } from '../../components/Notification/store';

export const useSavedViewsStore = create<iSavedViews>()((set, get) => ({
  ...initialSavedViewsValues,
  // Views State Management End
  GetViewByID: async (id: string) => {
    set({ isFetching: true });
    const viewObject: View[] = await ViewsService.getViewByID(id);
    return viewObject[0];
  },
  GetViews: async () => {
    set({ isFetching: true });
    let updatedState: any = {};
    await Promise.all([
      ViewsService.getPersonalViews(),
      ViewsService.getGlobalViews()
    ]).then(async (values) => {
      let defaultView: any = [];
      const currentFootprint = useFootprintStore.getState().active.id;
      defaultView = [...values[0], ...values[1]].find(
        (v) => v.default && v.footprint === currentFootprint
      );
      if (!defaultView) {
        // TODO make this configurable for footprints
        const footprintSubString = currentFootprint.split('-')[1];
        const defaultId =
          currentFootprint === 'footprint-offering'
            ? 'default-view'
            : 'default-view-' + footprintSubString;
        defaultView = [...values[1]].find((v) => v.id === defaultId);
        const checkedList = createViewsGroupList([defaultView], 'Personal');
        const checkedDefault = checkedList.items.filter((i) => i.id === defaultView.id);
        if (checkedDefault) {
          updatedState = Object.assign({}, updatedState, {
            checked: checkedDefault
          });
        }
      } else {
        const checkedList = createViewsGroupList([defaultView], 'Personal');
        const checkedDefault = checkedList.items.filter((i) => i.id === defaultView.id);
        if (checkedDefault) {
          updatedState = Object.assign({}, updatedState, {
            checked: checkedDefault
          });
        }
      }
      updatedState = Object.assign({}, updatedState, {
        personal: values[0],
        global: values[1],
        globalList: createViewsGroupList(values[1], 'Preset').items,
        list: createViewsGroupList(values[0], 'Personal').items,
        isFetching: false
      });
      updatedState = Object.assign({}, updatedState, { defaultViewId: defaultView.id });
      set(updatedState);
    });
    return updatedState;
  },
  LoadViews: async (onLoad?: boolean) => {
    const views = await get().GetViews();
    await get().SetActiveView(views?.defaultViewId || '', onLoad);
  },
  SetActiveView: async (id: string, onLoad?: boolean) => {
    // TODO: Split up logic handling shared searches, keyword searches, etc.
    const setChartActive = useViewStore.getState().SetChartActive;
    const { SetSearch } = useSearchStore.getState();
    const grid = useViewStore.getState().grid;
    // personal needs to be re-fetched to get any views that were saved post "LoadViews"
    const personal = await ViewsService.getPersonalViews();
    const global = get().global;
    const combined = [...personal, ...global];
    let newActiveView = combined.find((p: View) => p.id === id);
    if (newActiveView) {
      setChartActive(newActiveView.chartActive);
    }
    const specificViewInURL = personal.find((p: View) => {
      return encodeURIComponent(p.label) === window.location.href.split('/')[4];
    });
    if (onLoad && window.location.href.split('/').length > 4) {
      // This case handles shared view as well as views/:id route
      // If this is the first time we're loading and we're in a keyword
      // search page...
      if (specificViewInURL) {
        newActiveView = specificViewInURL;
        SetSearch(newActiveView.search);
      } else {
        let searchObject: any = await getObjFromSharedSearchURL(window.location.href);
        if (onLoad && window.location.href.split('/')[5] === 'keyword') {
          if (searchObject) {
            searchObject.search.dateEnabled = false;
            searchObject.search.DateField = 'none';
            for (let key in searchObject.search) {
              useSearchStore
                .getState()
                .UpdateSearchInput({ field: key, value: searchObject.search[key] });
            }
          }
        } else if (onLoad && window.location.href.split('/')[4] === 'shared') {
          if (searchObject.filters) {
            // This is required due to the application of filters onLoad after results come in
            // within the _grid component
            newActiveView.filters = searchObject.filters;
            // updateFilters to match
            await grid.current?.api?.setFilterModel(searchObject.filters);
          }
          for (let key in searchObject.search) {
            useSearchStore
              .getState()
              .UpdateSearchInput({ field: key, value: searchObject.search[key] });
          }
          let startDate: Date, endDate: Date;
          const { startDateTime, endDateTime } = searchObject.search;
          if (searchObject.search.timeRelative) {
            startDate = deriveDateFromRelativeString(
              searchObject.search.startDate,
              false,
              searchObject.search.startDateTime
            );
            endDate = deriveDateFromRelativeString(
              searchObject.search.endDate,
              true,
              searchObject.search.endDateTime
            );
            useSearchStore.setState({ startDate, endDate, startDateTime, endDateTime });
          } else {
            // TODO: Move parseInt section to a helper function
            startDate = dayjs(
              dayjs(searchObject.search.startDate)
                .toDate()
                .setHours(
                  parseInt(startDateTime.split(':')[0]),
                  parseInt(startDateTime.split(':')[1])
                )
            ).toDate();
            endDate = dayjs(
              dayjs(searchObject.search.endDate)
                .toDate()
                .setHours(
                  parseInt(endDateTime.split(':')[0]),
                  parseInt(endDateTime.split(':')[1])
                )
            ).toDate();
            useSearchStore.setState({ startDate, endDate, startDateTime, endDateTime });
          }
        }
      }
    } else {
      // TODO: This is the same condition as above - routine view change
      if (Object.keys(newActiveView.filters).length > 0) {
        // TODO: This is the same condition as above - empty case
      } else {
        await useViewStore.getState().grid.current?.api?.setFilterModel({});
      }
      const { hudFilterView } = useHUDStore.getState();
      let hudSearch = hudFilterView.field ? hudFilterView.search : null;
      if (hudSearch) {
        SetSearch(hudSearch);
      } else {
        SetSearch(newActiveView.search);
      }
    }
    await grid.current?.columnApi?.applyColumnState({
      state: newActiveView.columnState,
      applyOrder: true
    });
    useSearchStore.getState().ToggleIsSearching(true);
    await grid.current?.api.showLoadingOverlay();
    set({ active: newActiveView });
  },
  SaveActiveView: async (view: View) => {
    const gridCurrentAPI = useViewStore.getState().grid.current?.api;
    const filters = gridCurrentAPI.getFilterModel();
    let updatedView = Object.assign({}, view, { filters });
    const { startDate, endDate, search } = useSearchStore.getState();
    let searchCopy = Object.assign({}, search);
    const startTime = `${startDate?.getHours()}:${startDate?.getMinutes()}`;
    const endTime = `${endDate?.getHours()}:${endDate?.getMinutes()}`;
    if (search.timeRelative) {
      const { startDateTime, endDateTime } = createRelativeDateRangeString(
        startDate,
        endDate
      );
      // TODO: Update atrocious names
      searchCopy = Object.assign({}, searchCopy, {
        startDate: startDateTime,
        startDateTime: startTime,
        endDate: endDateTime,
        endDateTime: endTime
      });
      updatedView = Object.assign({}, updatedView, { search: searchCopy });
    } else {
      searchCopy = Object.assign({}, searchCopy, {
        startDate: startDate?.toISOString(),
        startDateTime: startTime,
        endDate: endDate?.toISOString(),
        endDateTime: endTime
      });
      updatedView = Object.assign({}, updatedView, { search: searchCopy });
    }
    let queryFunc, footprintMilestoneName;
    switch (updatedView.footprint) {
      case 'footprint-offering':
        queryFunc = constructQueryOffering;
        footprintMilestoneName = 'offering-footprint';

        break;
      case 'footprint-linear':
        queryFunc = constructQueryLinear;
        footprintMilestoneName = 'schedule-footprint';

        break;
      case 'footprint-acquire':
        queryFunc = constructQueryAcquire;
        footprintMilestoneName = 'acquire-footprint';

        break;
      case 'footprint-asset':
        queryFunc = constructQueryAsset;
        footprintMilestoneName = 'offeringAsset-footprint';

        break;
      case 'footprint-crossplatform':
        queryFunc = constructQueryCrossplatform;
        footprintMilestoneName = 'affiliateOfferings-footprint';

        break;
      default:
        queryFunc = constructQueryOffering;
        footprintMilestoneName = 'offering-footprint';
    }

    const updatedQuery = queryFunc(startDate, endDate, searchCopy, footprintMilestoneName);
    updatedView = updatedView = Object.assign({}, updatedView, {
      query: updatedQuery
    });
    // if this is a shared search we don't want to try to get the shared URL
    // if we did it would infinitely loop saving here
    if (view.type.split(':')[0] !== 'shared') {
      const sharedSearchURL = await getSharedSearchURL(view);
      updatedView.searchURL = sharedSearchURL;
    }
    ViewsService.upsertView(view.id, updatedView);
    const personal = await ViewsService.getPersonalViews();
    const global = await ViewsService.getGlobalViews();
    set({ global, personal });
    return view.id;
  },
  SaveNotification: async (notification: Notification) => {
    const notificationView = useSavedViewsStore
      .getState()
      .personal.find((v) => v.label === notification.view);
    // The distinction here between current and active is that active is the
    // state of the UI, while current is the state within the backend. This
    // is so the search and other view configrations don't get changed unexpectedly
    if (notificationView) {
      const currentView: View = await useSavedViewsStore
        .getState()
        .GetViewByID(notificationView.id);
      let updatedView: View = await Object.assign({}, currentView, {
        notification
      });
      let queryFunc, footprintMilestoneName;
    switch (updatedView.footprint) {
      case 'footprint-offering':
        queryFunc = constructQueryOffering;
        footprintMilestoneName = 'offering-footprint';

        break;
      case 'footprint-linear':
        queryFunc = constructQueryLinear;
        footprintMilestoneName = 'schedule-footprint';

        break;
      case 'footprint-acquire':
        queryFunc = constructQueryAcquire;
        footprintMilestoneName = 'acquire-footprint';

        break;
      case 'footprint-asset':
        queryFunc = constructQueryAsset;
        footprintMilestoneName = 'offeringAsset-footprint';

        break;
      case 'footprint-crossplatform':
        queryFunc = constructQueryCrossplatform;
        footprintMilestoneName = 'affiliateOfferings-footprint';

        break;
      default:
        queryFunc = constructQueryOffering;
        footprintMilestoneName = 'offering-footprint';
    }

    const updatedQuery = queryFunc(dayjs(updatedView.search.startDate).toDate(), dayjs(updatedView.search.endDate).toDate(), updatedView.search, footprintMilestoneName);
    updatedView = Object.assign({}, updatedView, {
      query: updatedQuery
    });
      await ViewsService.upsertView(updatedView.id, updatedView);
      const personal = await ViewsService.getPersonalViews();
      const global = await ViewsService.getGlobalViews();
      set({ global, personal });
      useNotificationStore.getState().getNotifications();
      return updatedView.id;
    }
    return null;
  },
  SetChartActive: async (payload: boolean) => {
    const updated = Object.assign({}, get().active, { chartActive: payload });
    set({ active: updated });
  },
  SetList: (payload: iSelectItem[]) => {
    const list = _.uniqBy(payload, 'id');
    set({ list });
  },
  SetListComplete: (payload: iSelectItem[]) => {
    const personal = get().personal;
    const positionsUpdatedViews = personal.map((v) => {
      const position = payload.findIndex((p) => p.id === v.id);
      if (position != -1) {
        v.position = position;
      }
      return v;
    });
    set({
      list: payload,
      personal: positionsUpdatedViews
    });
  },
  SetRemoveModal: (id: string) => {
    set({ removeModal: id });
  },
  SetGlobalList: (payload: iSelectItem[]) => {
    set({ globalList: payload });
  },
  SetIsOpen: (newState) => {
    set({ isOpen: newState });
  },
  SetNotificationModalOpen: (payload: boolean) => {
    set({ notificationModalOpen: payload });
  },
  ToggleIsOpen: () => {
    set({ isOpen: !get().isOpen });
  },
  ToggleIsDraggable: () => {
    set({ isDraggable: !get().isDraggable });
  },
  ToggleIsLoading: () => {
    set({ isLoading: !get().isLoading });
  },
  ToggleIsDefault: () => {
    set({ isDefault: !get().isDefault });
  },
  ToggleIsEditable: () => {
    set({ isEditable: !get().isEditable });
  },
  ToggleClickOutside: () => {
    set({ clickOutside: !get().clickOutside });
  },
  // Views State Management End
  FetchGridRowData: async (
    startDate: Date | null,
    endDate: Date | null,
    searchCriteria: iSearch,
    apiName: string,
    fetchServerRowData: (
      startDate: Date | null,
      endDate: Date | null,
      searchState: iSearch,
      limit: number,
      page: number
    ) => Promise<Result>,
    fetchStatusCount: (
      startDate: Date | null,
      endDate: Date | null,
      searchState: iSearch,
      milestoneName: string
    ) => Promise<any>
  ) => {
    const config = useConfigStore.getState().config;
    const grid = useViewStore.getState().grid;
    const hudFilterView = useHUDStore.getState().hudFilterView;
    const SetHUDFilterView = useHUDStore.getState().SetHUDFilterView;
    const chartSearch = useSearchStore.getState().chartSearch;
    const activeFootprint = useFootprintStore.getState().active.id;
    const setFieldStatusChartData =
      useStatusCountsStore.getState().SetFieldStatusChartData;
    // refactors date for chart
    let chartSearchCopy = Object.assign({}, searchCriteria);
    const startTime = `${startDate?.getHours()}:${startDate?.getMinutes()}`;
    const endTime = `${endDate?.getHours()}:${endDate?.getMinutes()}`;
    if (chartSearchCopy.timeRelative) {
      const { startDateTime, endDateTime } = createRelativeDateRangeString(
        startDate,
        endDate
      );
      // TODO: Update atrocious names
      chartSearchCopy = Object.assign({}, chartSearchCopy, {
        startDate: startDateTime,
        startDateTime: startTime,
        endDate: endDateTime,
        endDateTime: endTime
      });
    } else {
      chartSearchCopy = Object.assign({}, chartSearchCopy, {
        startDate: startDate?.toISOString(),
        startDateTime: startTime,
        endDate: endDate?.toISOString(),
        endDateTime: endTime
      });
    }
    useSearchStore.getState().SetChartSearch(chartSearchCopy);
    SetStatusCountData(startDate, endDate, searchCriteria, apiName, fetchStatusCount);
    let rowData: any[] = [];
    let fetchingData = true;
    let totalCount = 0;
    let isPowerUser = hasOKTAGroup(config.environment, config.oktaPowerUserGroup);
    let page = 1;
    let firstCall = await fetchServerRowData(startDate, endDate, searchCriteria, 1, page);
    totalCount = firstCall.rowCount;
    if (!isPowerUser && totalCount > maxRecordsToFetch) {
      alert(
        'Your search has resulted in ' +
          totalCount +
          ' records and is above the ' +
          maxRecordsToFetch +
          ' record limit. Please refine your results to be less than ' +
          maxRecordsToFetch +
          ' records.'
      );
      fetchingData = false;
    }
    else if(isPowerUser && totalCount > maxRecordsToFetch){
      let res = confirm(
        'Your search has resulted in ' +
          totalCount +
          ' records and is above the ' +
          maxRecordsToFetch +
          ' record limit. Click OK if you wish to proceed anyway.'
      );
      if(!res){
        fetchingData = false;
      }
    }
    while (fetchingData && totalCount !== 0) {
      let result = await fetchServerRowData(
        startDate,
        endDate,
        searchCriteria,
        2000,
        page
      );
      if (result?.rowData?.length === 0 || !result?.rowData) {
        fetchingData = false;
      }
      rowData = rowData.concat(result.rowData);
      if (result?.rowData?.length < 2000) {
        fetchingData = false;
      }
      page++;
      if (rowData.length >= totalCount && totalCount !== 0) {
        fetchingData = false;
      }
    }
    useSearchStore.getState().SetRecordCount(rowData.length);
    if (hudFilterView.field) {
      useStatusCountsStore.getState().SetStatusField(hudFilterView.field);
      if (chartSearch) {
        setFieldStatusChartData(fetchStatusCount);
      }
    }
    useSearchStore.getState().ToggleIsSearching(false);
    set({ rowData });
    grid.current?.api.hideOverlay();
    SetHUDFilterView({
      footprint: '',
      field: '',
      search: initialSearchValues
    });
    const notFoundCodes = getNotFoundCodes(searchCriteria, rowData, activeFootprint);
    if (notFoundCodes && notFoundCodes.length > 0) {
      set({ notFoundCodes: notFoundCodes });
    }
  },
  SetRowData: (rowData: any) => {
    set({ rowData });
  },
  UpdateActiveView: (field: string, value: any) => {
    const activeCopy = Object.assign({}, get().active, { [field]: value });
    set({
      active: activeCopy
    });
  }
}));

const SetStatusCountData = async (
  startDate: Date | null,
  endDate: Date | null,
  searchCriteria: any,
  apiName: string,
  fetchStatusCount: (
    startDate: Date | null,
    endDate: Date | null,
    searchState: iSearch,
    milestoneName: string
  ) => Promise<any>
) => {
  const countResult = await fetchStatusCount(
    startDate,
    endDate,
    searchCriteria,
    `${apiName}-footprint`
  );
  const dataCount = {
    error: 0,
    failure: 0,
    processing: 0,
    pending: 0,
    completed: 0
  };
  if (countResult && countResult.length > 0) {
    countResult.forEach((i: any) => {
      const status = getStatus(i.status);
      if (status === 'queued' || status === 'pending') {
        dataCount.pending += i.count;
      } else if (status === 'skipped' || status === 'completed') {
        dataCount.completed += i.count;
      } else if (Object.keys(dataCount).includes(status)) {
        dataCount[status as keyof typeof dataCount] = i.count;
      }
    });
  }

  useStatusCountsStore.getState().SetOfferingStatusCounts(dataCount);
  useStatusCountsStore.getState().SetStatusCounts(dataCount);
};

const getNotFoundCodes = (searchCriteria: iSearch, rowData: any, footprint: string) => {
  if (searchCriteria.SearchInputField !== 'keyword') {
    const searchedCodes = searchCriteria.SearchInput.split(' ');
    const searchInputSelectFields = useAppConfigManager
      .getState()
      .GetFieldMap('ids:' + footprint);
    const searchInputSelectName = searchInputSelectFields.find(
      (i) => i.value === searchCriteria.SearchInputField
    );

    if (!searchInputSelectName) {
      return false;
    }

    const foundCodes = rowData.map((item: any) => {
      let foundCode = _.get(item, searchInputSelectName.path);
      foundCode = foundCode?.toLowerCase().replace(/[ _.]/g, '');
      return foundCode;
    }).join(',')

    const notFoundCodes = searchedCodes.filter((searchedCode: string) => {
      if (
        searchedCode != '' &&
        !foundCodes.includes(searchedCode.toLowerCase().replace(/[ _.]/g, ''))
      ) {
        return true;
      }
    });
    return notFoundCodes.length > 0 ? notFoundCodes : false;
  }
};

const getSharedSearchURL = async (view: View) => {
  const email = useConfigStore.getState().userData.email;
  const gridCurrentAPI = useViewStore.getState().grid?.current?.api;
  let filters: any = gridCurrentAPI.getFilterModel();
  const savedSearch = {
    label: 'Shared Search from <' + email + '>',
    filters,
    type: 'shared:' + email,
    footprint: view.footprint,
    search: view.search
  } as View;
  return await createSharedSearchURL(savedSearch);
};

import { create } from 'zustand';
import { iSearchState, iSearch, initialSearchStateValues } from './models';
import dayjs from 'dayjs';
import {
  createRelativeDateRangeString,
  deriveDateFromRelativeString
} from '../../utils/utilities';
import { useConfigStore } from '../../app/store';
import { APIService } from '../../services/api/apiService';

export const useSearchStore = create<iSearchState>()((set, get) => ({
  ...initialSearchStateValues,
  LoadAPI: async () => {
    const baseApiGatewayUrl = useConfigStore.getState().config.baseApiGatewayUrl;
    const queryReturns = await APIService.getQueryReturns(baseApiGatewayUrl);
    set({ queries: queryReturns });
  },
  SetSearch: (newSearch: iSearch) => {
    const dates: { dateEnabled?: boolean; DateField?: string } = {};
    if (newSearch.SearchInput && !('DateField' in newSearch)) {
      dates.dateEnabled = false;
      dates.DateField = 'none';
    }
    let startDate: Date, endDate: Date;
    const combinedSearch = Object.assign(
      {},
      initialSearchStateValues.search,
      newSearch,
      dates
    );
    set({ search: combinedSearch });
    const { startDateTime, endDateTime } = combinedSearch;
    if (combinedSearch.timeRelative) {
      startDate = deriveDateFromRelativeString(
        combinedSearch.startDate,
        false,
        combinedSearch.startDateTime
      );
      endDate = deriveDateFromRelativeString(
        combinedSearch.endDate,
        true,
        combinedSearch.endDateTime
      );

      set({ startDate, endDate, startDateTime, endDateTime });
    } else {
      // TODO: Move parseInt section to a helper function
      startDate = dayjs(
        dayjs(combinedSearch.startDate)
          .toDate()
          .setHours(
            parseInt(startDateTime.split(':')[0]),
            parseInt(startDateTime.split(':')[1])
          )
      ).toDate();
      endDate = dayjs(
        dayjs(combinedSearch.endDate)
          .toDate()
          .setHours(
            parseInt(endDateTime.split(':')[0]),
            parseInt(endDateTime.split(':')[1])
          )
      ).toDate();
      set({ startDate, endDate, startDateTime, endDateTime });
    }
  },
  SetChartSearch: (newSearch: iSearch) => {
    const dates: { dateEnabled?: boolean; DateField?: string } = {};
    if (newSearch.SearchInput && !('DateField' in newSearch)) {
      dates.dateEnabled = false;
      dates.DateField = 'none';
    }
    const combinedSearch = Object.assign(
      {},
      initialSearchStateValues.search,
      newSearch,
      dates
    );
    set({ chartSearch: combinedSearch });
  },
  SetCurrentRowCount: (currentRowCount: number) => {
    set({ currentRowCount });
  },
  SetRecordCount: (recordCount: number) => {
    set({ recordCount });
  },
  ToggleIsSearching: (explicit?: boolean) => {
    if (explicit !== undefined) {
      set({ isSearching: explicit });
    } else {
      set({ isSearching: !get().isSearching });
    }
  },
  UpdateSearchInput: (payload: { field: string; value: any }) => {
    const activeSearch = get().search;
    const activeSearchCopy = Object.assign({}, activeSearch);
    const field = payload.field as keyof iSearch;
    /* @ts-ignore */
    activeSearchCopy[field] = payload.value;
    set({ search: activeSearchCopy });
  },
  ClearSearchInput: (fp: string, inputs )=> {
    let param= {
        startDate: '0:0',
        endDate: '23:59',
        startDateTime: '0:0',
        endDateTime: '23:59',
        IsLive: ''     
      }
    const combinedSearch = Object.assign(
      {},
      initialSearchStateValues.search,
      param,
      inputs
    );
    set({ search: combinedSearch });
  },
  UpdateStartDate: (date: Date | null) => {
    set({ startDate: date });
  },
  UpdateEndDate: (date: Date | null) => {
    set({ endDate: date });
  },
  ToggleRelativeMode: () => {
    const activeSearch = get().search;
    const search = Object.assign({}, activeSearch);
    search.timeRelative = !search.timeRelative;
    if (search.timeRelative) {
      // Change inner search's startDate and endDate to '+ and -' strings
      const { startDateTime, endDateTime } = createRelativeDateRangeString(
        dayjs(search.startDate).toDate(),
        dayjs(search.endDate).toDate()
      );
      search.startDate = startDateTime;
      search.endDate = endDateTime;
    } else {
      // Change inner search's startDate and endDate to date.ISO strings
      search.startDate = deriveDateFromRelativeString(
        search.startDateTime,
        false
      ).toISOString();
      search.endDate = deriveDateFromRelativeString(
        search.endDateTime,
        true
      ).toISOString();
    }
    set({ search });
  }
}));

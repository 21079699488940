import {
  useMantineTheme,
  Tabs,
  Box,
  useComputedColorScheme,
  lighten,
  darken
} from '@mantine/core';
import { detailModalHeaders } from '../../constants/constants';
import { StatusColor, StatusIcon } from '../../components';
import { getStatus } from '../../utils/getStatus';
import { useElementSize } from '@mantine/hooks';
import { useDetailsStore } from './store';
import { useFootprintStore } from '../Footprints/store';

// ColumnStateParams doesn't expose colId from it's properties (that lives in  the
// the ColumnState interface) So just adding it here for use in this file.
// interface iColumn extends ColumnStateParams {
//   colId: string;
// }
/** DetailGrid is the Detail View Modal-Level Ag-Grid Component */
const DetailGrid = (props: any) => {
  const { data, footprint, children, statusObject, parents } = props;
  const activeTab = useDetailsStore((state) => state.activeTab);
  const setActiveTab = useDetailsStore((state) => state.SetActiveTab);
  const statusColor = StatusColor();
  const theme = useMantineTheme();
  const { ref, width } = useElementSize();
  const colorScheme = useComputedColorScheme('light');
  const activeFootprint = useFootprintStore.getState().active.id;

  const gridCSS = colorScheme === 'dark' ? 'ag-theme-alpine-dark' : 'ag-theme-alpine';

  function HandleOnTabChange(tab: any) {
    setActiveTab(tab);
  }

  const tabsList = parents.map((milestoneHeader: string) => {
    const processName = milestoneHeader.split(' ').join('');
    const headerName =
      detailModalHeaders[processName as keyof typeof detailModalHeaders].headerName;
    let status;
    if (statusObject) {
      status = data.status[headerName];
    } else {
      status = data.milestones?.find(
        (milestone: { type: string; name: string }) =>
          milestone.type === 'Process' && milestone.name === headerName
      );
    }
    status ? (status = getStatus(status.status)) : (status = 'inactive');
    return (
      // <Grid.Col span={1}>
        <Tabs.Tab
          key={milestoneHeader}
          value={milestoneHeader}
          leftSection={<StatusIcon status={status} />}
          style={{
            marginRight: 4,
            height: 50,
            borderTop:
              activeTab === milestoneHeader
                ? `2px solid ${
                    colorScheme === 'dark'
                      ? lighten(statusColor[status], 0.6)
                      : statusColor[status]
                  }`
                : '',
            backgroundColor:
              colorScheme === 'dark'
                ? darken(statusColor[status], 0.4)
                : lighten(statusColor[status], 0.8)
          }}
          color="white"
        >
          <Box
            style={() => ({
              color: colorScheme === 'dark' ? theme.colors.gray[1] : theme.colors.dark
            })}
          >
            {milestoneHeader}
          </Box>
        </Tabs.Tab>
      // </Grid.Col>
    );
  });
  let footprintMilestoneName: string;
  switch (activeFootprint) {
    case 'footprint-offering':
      footprintMilestoneName = 'offering-footprint';
      break;
    case 'footprint-linear':
      footprintMilestoneName = 'schedule-footprint';
      break;
    case 'footprint-acquire':
      footprintMilestoneName = 'acquire-footprint';
      break;
    case 'footprint-asset':
      footprintMilestoneName = 'offeringsAsset-footprint';
      break;
    case 'footprint-crossplatform':
      footprintMilestoneName = 'affiliateOfferings-footprint';
      break;
    default:
      footprintMilestoneName = 'offering-footprint';
      break;
  }
  let timelineStatus;
  if (statusObject) {
    timelineStatus = data.status[footprintMilestoneName];
  } else {
    timelineStatus = data.milestones.find(
      (m: { name: string }) => m.name === footprintMilestoneName
    );
  }
  timelineStatus
    ? (timelineStatus = getStatus(timelineStatus.status))
    : (timelineStatus = 'inactive');
  const timelineTabItem = (
    // <Grid.Col span={1} offset={9 - parents.length}>
      <Tabs.Tab
        key={'timelineTab'}
        value={'Timeline'}
        leftSection={<StatusIcon status={timelineStatus} />}
        style={{
          marginRight: 100,
          height: 50,
          borderTop:
            activeTab === 'Timeline'
              ? `2px solid ${
                  colorScheme === 'dark'
                    ? lighten(statusColor[timelineStatus], 0.6)
                    : statusColor[timelineStatus]
                }`
              : '',
          backgroundColor:
            colorScheme === 'dark'
              ? darken(statusColor[timelineStatus], 0.4)
              : lighten(statusColor[timelineStatus], 0.8)
        }}
        color="white"
      >
        <Box
          style={() => ({
            color: colorScheme === 'dark' ? theme.colors.gray[1] : theme.colors.dark
          })}
        >
          Timeline
        </Box>
      </Tabs.Tab>
    // </Grid.Col>
  );

  const processTasks = () => {
    const processName = activeTab?.split(' ').join('');
    const milestoneHeaderData =
      detailModalHeaders[processName as keyof typeof detailModalHeaders];
    // Sadly Im having to make an exception here for Max Asset Images
    // that is inconsistent with other views to remove Receipt/Staging.
    // Other views all have same children for same parents
    // Will revisit if problem comes up again
    const formattedChildren =
      milestoneHeaderData.headerName === 'Image'
        ? ['Prep', 'Fulfill', 'Delivery']
        : children;
    const milestoneProps = {
      data: data,
      children: formattedChildren,
      parent: milestoneHeaderData.headerName,
      statusObject,
      width: width < 1000 ? '1080' : width.toString(), //  set the width of the grid within the Tab Panel
      headerName: milestoneHeaderData.headerName,
      // milestonesGrids: milestoneHeaderData.milestonesGrids,
      dataPanelSize: { width: '100%' },
      footprint: footprint ? footprint : ''
    };
    return (
      <Tabs.Panel ref={ref} key={activeTab} value={activeTab?.toString() || ''} pt="xs">
        {milestoneHeaderData.component(milestoneProps)}
      </Tabs.Panel>
    );
  };
  const tabsPanels = (
    <Tabs
      variant="outline"
      value={activeTab}
      radius="md"
      onChange={(tab) => HandleOnTabChange(tab)}
    >
      <Tabs.List>
        {/* <Grid
          style={{
            width: '100%'
          }}
        > */}
          {timelineTabItem}
          {tabsList}
        {/* </Grid> */}
      </Tabs.List>
      {activeTab && processTasks()}
    </Tabs>
  );

  return (
    <div
      className={gridCSS}
      //style={{ height: `${modalHeight - modalHeight * 0.21}px`, padding: '15px' }}
      style={{
        height: '100%',
        paddingRight: '15px'
      }}
    >
      {tabsPanels}
    </div>
  );
};

export default DetailGrid;

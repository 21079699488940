import { GridOptions } from 'ag-grid-community';

// const onGridReady = (params: GridReadyEvent) => {
//   params.api.sizeColumnsToFit();
// };

export const DataPanelGridOptions: GridOptions = {
  // detailRowHeight: 100,
  headerHeight: 32,
  masterDetail: true,
  // onGridReady: onGridReady,
  // rowHeight: 40,
  // domLayout: 'autoHeight',
  rowSelection: 'single',
  suppressCellFocus: true,
  defaultColDef: {
    filter: true,
    sortable: true,
    resizable: true
  }
};

import { Table } from '@mantine/core';
import { DetailAccordionItem } from '../../../components/DetailAccordion';

export const showImageAccordionItems = (k: any, width: number) => {
  const asset = k.eventSource === "bms" ? k.details.assets : k.details.asset;
  const hasAviary =
    asset.idNamespace === 'urn:warnermedia:imageid.aviary.warnermedia.com' ? true : false;
  let codeContent;
  if (k.status?.type === 'error' || k.status?.type === 'failure') {
    let code = k.status.codes[0];
    if (code.detail) {
      codeContent = (
        <div key={k}>
          <Table.Tr>
            <Table.Td style={{ textAlign: 'left', width: 90 }}>Status Code: </Table.Td>
            <Table.Td>{code.code}</Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td style={{ textAlign: 'left', width: 90 }}>Code Detail: </Table.Td>
            <Table.Td>{code.detail}</Table.Td>
          </Table.Tr>
        </div>
      );
    } else {
      codeContent = (
        <Table.Tr key={k}>
          <Table.Td style={{ textAlign: 'left', width: 90 }}>Status Code: </Table.Td>
          <Table.Td>{code.code}</Table.Td>
        </Table.Tr>
      );
    }
  }
  return (
    <DetailAccordionItem
      testid={`DetailAccordionItem-${asset.id}-${asset.imageSpec.language}`}
      name={`item-${asset.id}-${asset.imageSpec.language}`}
      key={`item-${asset.id}-${asset.imageSpec.language}`}
      title={`${asset.imageSpec.imageRole} : ${asset.imageSpec.language}`}
      title2={`(${asset.imageSpec.width}x${asset.imageSpec.height})`}
      styles={{
        borderBottom: 0,
        marginLeft: -6,
        width: width - 10,
        minWidth: 350
      }}
      status={k.status}
    >
      <Table
        striped={false}
        highlightOnHover={true}
        style={{ width: '100%', margin: -16, marginTop: -32, borderBottom: 0 }}
      >
        <Table.Thead>
          <Table.Tr>
            <Table.Td>&nbsp;</Table.Td>
            <Table.Td>&nbsp;</Table.Td>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          <Table.Tr>
            <Table.Td style={{ textAlign: 'left', width: 90 }}>Source: </Table.Td>
            <Table.Td>{asset.idNamespace.split(':')[2].split('.')[1]}</Table.Td>
          </Table.Tr>
          {hasAviary && (
            <Table.Tr>
              <Table.Td style={{ textAlign: 'left', width: 90 }}>Image ID: </Table.Td>
              <Table.Td>
                <a target="_blank" href={`https://nest.wb.com/nuxeo/ui/#!/doc/${asset.id}`}>{asset.id}</a>
              </Table.Td>
            </Table.Tr>
          )}
          {!hasAviary && (
            <Table.Tr>
              <Table.Td style={{ textAlign: 'left', width: 90 }}>Image ID: </Table.Td>
              <Table.Td>{asset.id}</Table.Td>
            </Table.Tr>
          )}
          <Table.Tr>
            <Table.Td style={{ textAlign: 'left', width: 90 }}>DID: </Table.Td>
            <Table.Td>{asset.alternateIds[0]?.id}</Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td style={{ textAlign: 'left', width: 90 }}>File Format: </Table.Td>
            <Table.Td>{asset.imageSpec.fileFormat.split(':').slice(-1)}</Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td style={{ textAlign: 'left', width: 90 }}>Language: </Table.Td>
            <Table.Td>{asset.imageSpec.language}</Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td style={{ textAlign: 'left', width: 90 }}>Width: </Table.Td>
            <Table.Td>{asset.imageSpec.width}</Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td style={{ textAlign: 'left', width: 90 }}>Height: </Table.Td>
            <Table.Td>{asset.imageSpec.height}</Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td style={{ textAlign: 'left', width: 90 }}>Status: </Table.Td>
            <Table.Td>{k.status?.type}</Table.Td>
          </Table.Tr>
          {codeContent}
        </Table.Tbody>
      </Table>
    </DetailAccordionItem>
  );
};

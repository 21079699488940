import { getAxiosConfig } from '../axios/config';
import axios from 'axios';
import { BigPandaConfig } from '../../components/BigPandaConfig/models';

const AppConfigType = "bigPandaConfig";
const BigPandaConfigId = "beb5d57c-42e2-4a84-914c-fda35c152f63";

export const BigPandaConfigService = {

  getConfig: async (baseURL: string): Promise<BigPandaConfig> => {
    const { headers } = getAxiosConfig();
    const res = await axios.get(`${baseURL}/rest/appconfigs/v1?type=${AppConfigType}`, {
      headers
    });

    return res.data[0];
  },

  upsertConfig: async (baseURL: string, config: BigPandaConfig): Promise<BigPandaConfig> => {
    const { headers, OKTAConfig } = getAxiosConfig();

    // Add Id, if it does not already have one. 
    if (config.id == undefined || config.id.length == 0) {
      config.id = BigPandaConfigId;
    }

    // Set App.config schema type 
    config.type = AppConfigType;
    config.lastUpdated = new Date();
    config.lastUpdatedBy = OKTAConfig.idToken.claims.email;    
    
    const res = await axios.put(`${baseURL}/rest/appconfigs/v1/${config.id}`,
      config, { headers }
    );
    return res.data[0];
  }
};

import { randomId } from '@mantine/hooks';
export interface Notification {
  [key: string]: string | any;
  footprint: string | null;
  view: string;
  deliveryMethod: string;
  destination: string;
  schedule: DayVal[];
  timeInterval: string;
  active: boolean;
}

export interface DayVal {
  label: string;
  checked: boolean;
  key: string;
  value: number;
}

export interface NotificationStore {
  editing: boolean;
  currentNotification: Notification;
  // TODO: change from any
  notifications: Notification[]
  updateValue: (schedule: any) => void;
  getNotifications: () => Promise<void>;
  resetValues: () => void;
  setCurrentNotification: (payload: Notification) => void;
  setEditing: (payload: boolean) => void;
}

export const initialNotification: Notification = {
  footprint: '',
  view: '',
  active: false,
  timeInterval: '8',
  deliveryMethod: 'email',
  destination: '',
  schedule: [
    { label: 'Sun', checked: false, key: randomId(), value: 0 },
    { label: 'Mon', checked: false, key: randomId(), value: 1 },
    { label: 'Tue', checked: false, key: randomId(), value: 2 },
    { label: 'Wed', checked: false, key: randomId(), value: 3 },
    { label: 'Thu', checked: false, key: randomId(), value: 4 },
    { label: 'Fri', checked: false, key: randomId(), value: 5 },
    { label: 'Sat', checked: false, key: randomId(), value: 6 }
  ],
};

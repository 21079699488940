import useAxios from '../services/axios/axios';

export const fetchCSData = async (
  baseURL: string,
  id: string,
  type: string
) => {
  const { post } = useAxios();

  const query = constructHistoryQuery(id, type);
  if (!query) return null;
  const schema = {
    query: query
  };

  const eventData = await post({
    url: baseURL,
    data: JSON.stringify(schema)
  });
  let result: any = {}
  if (!eventData) return result
  if (eventData.data.history.records.length > 0){
    const removedNulls = eventData.data.history.records.filter((d: any) => d !== null);
    const sortedData =
      removedNulls.length > 0
        ? removedNulls.sort(function (a: any, b: any) {
            return b.audit.generatedAt.localeCompare(a.audit.generatedAt);
          })
        : [];
    const csQuery = constructEventQuery(sortedData[0].eventMetaId);
    if (!csQuery) return null;
    const schema = {
      query: csQuery
    };

    const csEventData = await post({
      url: baseURL,
      data: JSON.stringify(schema)
    });

    if (csEventData && csEventData.data.event.records.length > 0){
      const csMessage = csEventData.data.event.records[0] 
      if(csMessage.data.payload.contentRequests && csMessage.data.payload.contentRequests.length > 0) result = csMessage.data.payload.contentRequests[0]
    }
  }

  return result;
};

const constructHistoryQuery = (id: string, type: string) => {
  const queryFilters = constructHistoryFilters(id, type);
  if (!queryFilters) return null;
  const query = `query {
            history(
                ${queryFilters}
            ) {
                records {
                    eventMetaId
                    audit {
                      generatedAt
                    }
                  }
            }
          }`;

  return query;
};

const constructHistoryFilters = (id: string, type: string) => {
  const queryFilters = `filters: {
    id:"${id}"
    task:["csdelivery"]
    type:${type}
    page:1
    limit:1000
  }`;
  return queryFilters;
};


const constructEventQuery = (id: string) => {
  const queryFilters = constructEventFilters(id);
  if (!queryFilters) return null;
  const query = `query {
    event(
        ${queryFilters}
    ) {
        records {
            data
        }
    }
  }`;

  return query;
};

const constructEventFilters = (id: string) => {
  const queryFilters = `filters: {
    id:"${id}"
    page:1
    limit:1000
  }`;
  return queryFilters;
};
import { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Outlet } from 'react-router-dom';
import { Loader } from '@mantine/core';

import {
  OktaAuth,
  TokenManagerInterface,
  AccessToken,
  IDToken,
  UserClaims
} from '@okta/okta-auth-js';
import { useConfigStore, useUserPrefsStore } from '../store';
import { useOktaAuthStore } from '../../containers/Okta/store';

export const getUser = async (authClient: OktaAuth) => {
  const tokenManager: TokenManagerInterface = authClient.tokenManager;
  const accessToken: AccessToken = (await tokenManager.get('accessToken')) as AccessToken;
  const idToken: IDToken = (await tokenManager.get('idToken')) as IDToken;
  const userInfo: UserClaims = await authClient.token.getUserInfo(accessToken, idToken);
  return userInfo;
};
export const SecureRoute = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const UserPreferencesStore = useUserPrefsStore((state)=>state)
  
  if (oktaAuth && authState){
    const SetOktaAuth = useOktaAuthStore.getState().SetOktaAuth
    const SetAuthState = useOktaAuthStore.getState().SetAuthState
    SetOktaAuth(oktaAuth)
    SetAuthState(authState)
  }

  const userData = useConfigStore((state) => state.userData);
  const setUserData = useConfigStore((state) => state.SetUserData);

  useEffect(() => {
    if (!authState) {
      return;
    } else if (
      authState.isAuthenticated &&
      !Object.values(userData).every((item) => item !== undefined)
    ) {
      (async () => {
        try {
          await UserPreferencesStore.Load()
        } catch(err) {
          console.error('Error Loading User Preferences: ', err);
        }
        const userInfo = await getUser(oktaAuth);
        // console.debug(` userInfo: `, userInfo);
        if (
          userData.name !== userInfo.given_name ||
          userData.surName !== userInfo.family_name ||
          userData.email !== userInfo.email ||
          userData.uid !== userInfo.sub
        ) {
          if (userInfo['ops.lasso.groups']){
            userData.groups = userInfo['ops.lasso.groups'];
          }
          setUserData({
            name: userInfo.given_name,
            surName: userInfo.family_name,
            email: userInfo.email,
            uid: userInfo.sub,
            groups: userData.groups,
          });
        }
      })();
    }

    if (!authState || !authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(window.location.href, window.location.origin);
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
  }, [oktaAuth, !!authState, authState?.isAuthenticated]);

  if (!authState || !authState?.isAuthenticated) {
    return <Loader />;
  }

  return <Outlet />;
};

import {
  Box,
  CloseButton,
  Grid,
  Group,
  Button,
  Stack,
  Text,
  useMantineTheme,
  Tooltip,
  useComputedColorScheme
} from '@mantine/core';
import { ViewsDetail } from '..';
import { ErrorBoundary } from 'react-error-boundary';
import { Recycle } from 'tabler-icons-react';
import { useEffect, useState } from 'react';
import useAxios from '../../../../services/axios/axios';
import { hasOKTAGroup } from '../../../../utils/oktaGroups';
import { useConfigStore } from '../../../../app/store';
import { useElementSize } from '@mantine/hooks';
import { useDetailsStore } from '../../store';
import { findTitle } from '../offering/offeringsDetail';
import AcquireDetail from '../acquire/acquireDetail';

const ErrorFallback = (props: any) => {
  return (
    <div role="alert">
      <p>Error on DetailsModal:</p>
      <pre>{props.error.TypeError}</pre>
    </div>
  );
};

const myErrorHandler = (error: Error, info: { componentStack: string }) => {
  console.log('error', error, 'info', info);
  // Do something with the error
  // E.g. log to an error logging client here
};

/* The DetailsModal component holds detail-related components for a view's
individual row items */
export const DetailsModalSoloAcquire = (props: any) => {
  const details = props.details;
  const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');
  const [refreshLoading, setRefreshLoading] = useState(false);
  const config = useConfigStore((state) => state.config);
  const { get } = useAxios();

  const tabFields = ['Acquisition Prep', 'Transform', 'Manifestation'];
  // This is so if the active tab is set to "Staging" on the offering footprint,
  // this footprint will default to something it includes
  const { activeTab, SetActiveTab, SetDetailsVisible } = useDetailsStore();
  useEffect(() => {
    if (!tabFields.includes(activeTab as string)) SetActiveTab('Timeline');
  }, []);

  const { ref, width } = useElementSize();

  const refresh = () => {
    setRefreshLoading(true);
    const baseUrl =
      config.baseApiGatewayUrl +
      '/rest/refresh/v1?id=' +
      details.id
    get({ url: baseUrl });
    setTimeout(() => {
      setRefreshLoading(false);
    }, 2000);
  };

  const isAdmin = hasOKTAGroup(config.environment, config.oktaAdminGroup);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={myErrorHandler}>
      <Grid columns={24}>
        <Grid.Col span={7} style={{ overflowY: 'auto' }}>
          <Group>
            <Stack style={{ marginTop: 12, width: '100%' }}>
              <Group ref={ref} justify="space-between" style={{ width: '100%' }}>
                <Text
                  size="xl"
                  style={{
                    marginLeft: 24,
                    marginBottom: -32,
                    marginTop: -16
                  }}
                >
                  {details.asset?.titles?.length > 0 ? (
                      findTitle(details.asset.titles, true, width)
                    ) : (
                      <>--No Title--</>
                    )}
                </Text>
                {isAdmin && (
                  <Tooltip label="Refresh" zIndex={9999}>
                    <Button
                      size="xs"
                      onClick={refresh}
                      loading={refreshLoading}
                      justify="center"
                      variant="subtle"
                      style={() => ({
                        marginLeft: 24,
                        marginBottom: -32,
                        marginTop: -16,
                        width: 40
                      })}
                      leftSection={
                        !refreshLoading ? <Recycle style={{ marginRight: -12, color: theme.colors.blue[6] }} /> : <></>
                      }
                    />
                  </Tooltip>
                )}
              </Group>
              <Box
                  style={{
                    borderBottom: `1px solid ${theme.colors.gray[8]}`,
                    display: 'flex',
                    fontSize: '1.3em',
                    height: '35px',
                    justifyContent: 'start',
                    marginLeft: 20,
                    padding: '0 5px'
                  }}
                >
                  <Group justify="flex-start" style={{ width: '100%' }}>
                    {/* <Text
                      style={{
                        marginTop: 10
                      }}
                      size="sm"
                    >
                      {contentNames}
                    </Text> */}
                    {details.isDeleted && (
                      <Text
                        style={{
                          marginTop: 10,
                          marginLeft: -10,
                          color:
                            colorScheme === 'dark'
                              ? theme.colors.red[8]
                              : theme.colors.red[6]
                        }}
                        size="sm"
                        fw={700}
                      >
                        - DELETED
                      </Text>
                    )}
                  </Group>
                </Box>
            </Stack>
          </Group>
          <AcquireDetail details={details} />
        </Grid.Col>
        <Grid.Col span={17}>
          <CloseButton
            iconSize={20}
            onClick={() => SetDetailsVisible(false)}
            size="xl"
            style={{ position: 'absolute', top: 12, right: 12 }}
            title="Close Details"
          />
          <Stack style={{ height: '100%' }}>
            {details && (
              <ViewsDetail
                footprint="footprint-acquire"
                data={details}
                parents={['Acquisition Prep', 'Transform', 'Manifestation']}
                children={['Asset']}
                statusObject={false}
              />
            )}
          </Stack>
        </Grid.Col>
      </Grid>
    </ErrorBoundary>
  );
};

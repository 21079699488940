import { create } from 'zustand';
import { iViewState } from './models';
import { initialViewState } from './models';

export const useViewStore = create<iViewState>()(
(set, _) => ({
    ...initialViewState,
    SetGrid: (payload: any) => {
      if (payload.current !== null) {
        set({ grid: payload });
      }
    },
    SetNavigate: (value: boolean) => {
      set({ navigate: value });
    }
  })
);

import { Option } from './../../components/CascaderNetwork/_networks';
import { useConfigStore } from '../../app/store';
import useAxios from '../../services/axios/axios';
import { iSearch } from '../../containers/Search/models';
import { useAppConfigManager } from '../../components/AppConfigs';
import { useSearchStore } from '../../containers/Search/store';
import { getStatusCodeString } from '../../utils/utilities';

export type Result = {
  rowData: any[];
  rowCount: number;
};

export const fetchCrossPlatformRowData = async (
  startDate: Date | null,
  endDate: Date | null,
  searchState: iSearch,
  limit: number,
  page: number
) => {
  const { post } = useAxios();
  const baseURL = useConfigStore.getState().config.baseApiGatewayUrl + '/graphql/search';

  const query = await constructQuery(startDate, endDate, searchState, limit, page);

  const schema = {
    query: query
  };
  let result: Result = { rowData: [], rowCount: 0 };
  try {
    const eventData = await post({
      url: baseURL,
      data: JSON.stringify(schema)
    });
    if (!eventData) {
      result = { rowData: [], rowCount: 0 };
    } else {
      let totalCount = eventData?.data?.affiliateOfferings?.count;
      result = { rowData: eventData.data.affiliateOfferings.records, rowCount: totalCount };
    }
  } catch (err) {
    console.error('Error fetching Cross Platform row data: ', err);
  } finally {
    return result;
  }
};

const constructQuery = async (
  startDate: Date | null,
  endDate: Date | null,
  searchState: iSearch,
  limit: number,
  page: number
) => {
  const queryFilters = constructCrossPlatformFilters(startDate, endDate, searchState, limit, page);

  let queryReturn = await useSearchStore
    .getState()
    .queries.find((q) => q.id.includes('footprint-crossplatform'));
  if (!queryReturn) {
    const LoadAPI = useSearchStore.getState().LoadAPI;
    await LoadAPI();
    queryReturn = await useSearchStore
      .getState()
      .queries.find((q) => q.id.includes('footprint-crossplatform'));
  }

  const query = `query {
            affiliateOfferings(
              ${queryFilters}
            ) {
              ${queryReturn?.query}
              count
            }
          }`;
  return query;
};

export const constructCrossPlatformFilters = (
  startDate: Date | null,
  endDate: Date | null,
  filters: iSearch,
  limit: number,
  page: number
) => {

  const milestoneString = constructMilestoneObject(filters.Milestone, filters.MilestoneStatus)

  let partialSearchInput: string = '';
  if (filters.SearchInput) {
    let searchInputs;
    if (filters.SearchInputField === 'keyword') {
      let copy = filters.SearchInput;
      let newInput = copy.replaceAll('"', '\\"');
      searchInputs = newInput;
    } else {
      searchInputs = filters.SearchInput.split(' ');
      if (searchInputs.length > 1) {
        searchInputs = searchInputs.join('","');
      }
    }
    partialSearchInput = searchInputs.toString();
  }

  let csId = '';
  if (filters.SearchInputField && filters.SearchInputField === 'csId') {
    let input = '';
    let searchInputs = filters.SearchInput.split(' ');
    if (searchInputs.length > 1) {
      input = searchInputs.join('","');
    } else {
      input = searchInputs[0];
    }
    csId = `
    csId: {
      id: ["${input}"]
    }
    `;
  }

  let idPagination;
  idPagination = `page: ${page}`;

  const crossPlaftormObject = constructCrossPlatformObject(startDate, endDate, filters);
  let partialObject = '';
  if (partialSearchInput && filters.SearchInputField === 'keyword') {
    partialObject = constructPartialCrossPlatformObject(partialSearchInput);
  }

  let auditObject = `audit:{}`;
  if (filters.DateField === 'lastUpdated' && filters.dateEnabled) {
    auditObject = `audit:{
            updatedAtFrom:"${startDate!.toISOString()}",
            updatedAtTo:"${endDate!.toISOString()}"
          }`;
  }

  const taskValueMapper = useAppConfigManager.getState().taskValueMapper;
  const statusCodeString = getStatusCodeString(filters.StatusCodes, taskValueMapper);

  const queryFilters = `filters: {
        ${statusCodeString}
        ${milestoneString}
        ${crossPlaftormObject}
        ${partialObject}
        ${csId}
        ${auditObject}
        limit: ${limit}
        ${idPagination}
    }`;
  return queryFilters;
};

export const constructMilestoneObject = (milestones: string[], milestoneStatus: string[]) => {
  const status: any = {}
  if (milestones.length > 0 && milestoneStatus.length > 0) {
    milestones.forEach(i=> {
      status[i] = `${i}: {status: [${milestoneStatus?.map((status: any) => `"${status}"`)}]}`
    })
  }
  if (Object.keys(status).length > 0) {
    let statusString = '';
    Object.keys(status).forEach((k: string) => {
      statusString = statusString + status[k];
    });
    return `
      status:{
        ${statusString}
      }
    `;
  }
  return ''
}

export const constructCrossPlatformObject = (
  startDate: Date | null,
  endDate: Date | null,
  filters: iSearch
) => {
  let offeringObject: any = {};

  if (filters.Destinations.length > 0) {
    const { queryDestinations } = useAppConfigManager.getState();
    let value: any[] = [];
    filters.Destinations.forEach((destination: string[]) => {
      const option = queryDestinations.find(
        (o: Option) => o.value === destination[destination.length - 1]
      );
      if (option) {
        option.valueList.split(',').forEach((id: string) => {
          value.push(id);
        });
      } else {
        console.error('Could not find destination option within destinations!: ', destination);
      }
    });
    const filterString = `destinationId:{ id: [${value.map((i: any) => `"${i}"`)}] }`;
    offeringObject.destinationId = filterString;
  }

  if (
    filters.DateField === 'startDate' &&
    filters.startDateTime &&
    filters.endDateTime &&
    filters.dateEnabled
  ) {
    offeringObject.startDateFrom = `startDateFrom:"${startDate!.toISOString()}"`;
    offeringObject.startDateTo = `startDateTo:"${endDate!.toISOString()}"`;
  }
  if (
    filters.DateField === 'endDate' &&
    filters.startDateTime &&
    filters.endDateTime &&
    filters.dateEnabled
  ) {
    offeringObject.endDateFrom = `endDateFrom:"${startDate!.toISOString()}"`;
    offeringObject.endDateTo = `endDateTo:"${endDate!.toISOString()}"`;
  }
  if (filters.DateField === 'createdAt' && filters.dateEnabled) {
    offeringObject.createdAt = `audit:{
            createdFrom:"${startDate!.toISOString()}",
            createdTo:"${endDate!.toISOString()}",
          }`;
  }

  if (filters.SearchInputField && filters.SearchInputField !== 'keyword' && filters.SearchInput) {
    let input = '';
    let searchInputs = filters.SearchInput.split(' ');
    if (searchInputs.length > 1) {
      input = searchInputs.join('","');
    } else {
      input = searchInputs[0];
    }

    const excludedIds = [
      'keyword',
      'csId'
    ]

    const ids = useAppConfigManager
      ?.getState()
      ?.GetSearchInputs('footprint-crossplatform')
      ?.map((id) => id.value)
      .filter(i=>!excludedIds.includes(i));

    if (filters.SearchInputField === 'offeringId') {
      offeringObject.id = `id: ["${input}"]`;
    } else if (ids.includes(filters.SearchInputField)) {
      offeringObject[
        filters.SearchInputField
      ] = `${filters.SearchInputField}:{id: ["${input}"]}`;
    }
  }

  if (Object.keys(offeringObject).length > 0) {
    let offeringString = '';
    Object.keys(offeringObject).forEach((k: string) => {
      offeringString = offeringString + offeringObject[k];
    });
    return `offering: {${offeringString}}`;
  }
  return '';
};

export const constructPartialCrossPlatformObject = (searchInput: string) => {
  const partialTitles = ['titles', 'seriesTitles', 'seasonTitles'];

  const excludedIds = [
    'keyword',
    'csId',
    'offeringId'
  ] 
  
  const partialIds = useAppConfigManager
    ?.getState()
    ?.GetSearchInputs('footprint-crossplatform')
    ?.map((id) => id.value)
    .filter((i) => !excludedIds.includes(i));
    let partialObject: any = {
      id: `id:["${searchInput}"]`
    };

  partialTitles.map((t: string) => {
    const partialTitle = `
      ${t}:{
        full:["${searchInput}"]
        type:["main-title", "internal-wm-name", "distribution-title"],
        language:"en-US"
      }`;
    partialObject[t as keyof typeof partialObject] = partialTitle;
  });

  if (searchInput.length > 4 || !searchInput.split('').includes(' ')) {
    partialIds.map((i: string) => {
      partialObject[i as keyof typeof partialObject] = `\n${i}:{id: ["${searchInput}"]}`;
    });
  }

  // csId moved to root level
  let csId = '';
  if (searchInput.length > 4 || !searchInput.split('').includes(' ')) {
    csId = `
    csId: {
      id: ["${searchInput}"]
    }
    `;
  }

  if (Object.keys(partialObject).length > 0) {
    let offeringString = '';
    Object.keys(partialObject).forEach((k: string) => {
      offeringString = offeringString + partialObject[k];
    });
    return `
      partialSearch:{
        offering:{
          ${offeringString}
        }
        ${csId}
      }
    `;
  }
  return '';
};

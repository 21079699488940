// import { TabsValue } from '@mantine/core';
import { ColumnState } from 'ag-grid-community';

interface iBackendId {
  id: string;
  idNamespace?: string;
}

interface iTaskDetails {
  details: string;
  cId: iBackendId;
  trackingIds: iBackendId[];
  movieAssetId: { id: string };
}

export interface iTasks {
  type: string;
  details: iTaskDetails;
  eventMetaId: string;
  eventSource?: string;
}

interface iOffering {
  offering: any;
  csId: iBackendId;
  tasks: iTasks[];
  audit: any;
}

interface iSchedule {
  id: string;
  schedule: any;
  csId: iBackendId;
  audit: any;
  repeatCount: number;
}

export interface iAcquire {
  id: string;
  type: string;
  asset: any;
  audit: any;
  isDeleted: boolean;
}

export interface iDetails {
  isOpen: boolean;
  offering: iOffering;
  schedule: iSchedule;
  acquire: iAcquire;
  asset: any;
  crossplatform: iOffering;
  headerName: string;
  preModalState: iPreModalState;
  detailsVisible: boolean;
  activeTab: any;
  SetPreModalState: (newPreModal: iPreModalState) => void;
  SetDetails: (newDetails: iDetails) => void;
  ToggleIsOpen: () => void;
  SetDetailsVisible: (newState: boolean) => void;
  ToggleDestailsVisible: () => void;
  SetActiveTab: (newTab: any) => void;
}

export interface iPreModalState {
  filters: any;
  columns: ColumnState[];
}

export const initialPreModaValues: iPreModalState = {
  filters: {},
  columns: []
};

export const initialDetailValues = {
  isOpen: false,
  tasks: [],
  audit: {},
  detailsVisible: false,
  activeTab: 'Timeline',
  date: '',
  format: '',
  headerName: '',
  ids: {
    id: '',
    DID: ''
  },
  offering: {
    offering: {},
    csId: {
      id: '',
      idNamespace: ''
    },
    tasks: [],
    audit: {}
  },
  schedule: {
    id: '',
    schedule: {},
    csId: {
      id: ''
    },
    audit: {},
    repeatCount: 0
  },
  acquire: {
    id: '',
    asset: {},
    type: '',
    audit: {},
    isDeleted: false
  },
  asset: {},
  crossplatform: {
    offering: {},
    csId: {
      id: '',
      idNamespace: ''
    },
    tasks: [],
    audit: {}
  },
  preModalState: initialPreModaValues,
  SetPreModalState: (newPreModal: iPreModalState) => {},
  SetDetails: (newDetails: iDetails) => {},
  ToggleIsOpen: () => {},
  SetDetailsVisible: (newState: boolean) => {},
  ToggleDestailsVisible: () => {},
  SetActiveTab: (newTab: any) => {}
};

export const globalColors = {
  wbdblue: [
    '#E6EFFF',
    '#99BEFF',
    '#99BEFF',
    '#4D8DFE',
    '#4D8DFE',
    '#015CFE',
    '#0140B2',
    '#001C4C',
    '#001C4C',
    '#001C4C'
  ],
  dark: [
    '#C1C2C5',
    '#A6A7AB',
    '#909296',
    '#5c5f66',
    '#373A40',
    '#2C2E33',
    '#25262b',
    '#1A1B1E',
    '#141517',
    '#101113'
  ],
  gray: [
    '#f8f9fa',
    '#f1f3f5',
    '#e9ecef',
    '#dee2e6',
    '#ced4da',
    '#adb5bd',
    '#868e96',
    '#495057',
    '#343a40',
    '#212529'
  ],
  red: [
    '#fff5f5',
    '#ffe3e3',
    '#ffc9c9',
    '#ffa8a8',
    '#ff8787',
    '#ff6b6b',
    '#fa5252',
    '#f03e3e',
    '#e03131',
    '#c92a2a'
  ],
  pink: [
    '#fff0f6',
    '#ffdeeb',
    '#fcc2d7',
    '#faa2c1',
    '#f783ac',
    '#f06595',
    '#e64980',
    '#d6336c',
    '#c2255c',
    '#a61e4d'
  ],
  grape: [
    '#f8f0fc',
    '#f3d9fa',
    '#eebefa',
    '#e599f7',
    '#da77f2',
    '#cc5de8',
    '#be4bdb',
    '#ae3ec9',
    '#9c36b5',
    '#862e9c'
  ],
  violet: [
    '#f3f0ff',
    '#e5dbff',
    '#d0bfff',
    '#b197fc',
    '#9775fa',
    '#845ef7',
    '#7950f2',
    '#7048e8',
    '#6741d9',
    '#5f3dc4'
  ],
  indigo: [
    '#edf2ff',
    '#dbe4ff',
    '#bac8ff',
    '#91a7ff',
    '#748ffc',
    '#5c7cfa',
    '#4c6ef5',
    '#4263eb',
    '#3b5bdb',
    '#364fc7'
  ],
  blue: [
    '#e7f5ff',
    '#d0ebff',
    '#a5d8ff',
    '#74c0fc',
    '#4dabf7',
    '#339af0',
    '#228be6',
    '#1c7ed6',
    '#1971c2',
    '#1864ab'
  ],
  cyan: [
    '#e3fafc',
    '#c5f6fa',
    '#99e9f2',
    '#66d9e8',
    '#3bc9db',
    '#22b8cf',
    '#15aabf',
    '#1098ad',
    '#0c8599',
    '#0b7285'
  ],
  teal: [
    '#e6fcf5',
    '#c3fae8',
    '#96f2d7',
    '#63e6be',
    '#38d9a9',
    '#20c997',
    '#12b886',
    '#0ca678',
    '#099268',
    '#087f5b'
  ],
  green: [
    '#ebfbee',
    '#d3f9d8',
    '#b2f2bb',
    '#8ce99a',
    '#69db7c',
    '#51cf66',
    '#40c057',
    '#37b24d',
    '#2f9e44',
    '#2b8a3e'
  ],
  lime: [
    '#f4fce3',
    '#e9fac8',
    '#d8f5a2',
    '#c0eb75',
    '#a9e34b',
    '#94d82d',
    '#82c91e',
    '#74b816',
    '#66a80f',
    '#5c940d'
  ],
  yellow: [
    '#fff9db',
    '#fff3bf',
    '#ffec99',
    '#ffe066',
    '#ffd43b',
    '#fcc419',
    '#fab005',
    '#f59f00',
    '#f08c00',
    '#e67700'
  ],
  orange: [
    '#fff4e6',
    '#ffe8cc',
    '#ffd8a8',
    '#ffc078',
    '#ffa94d',
    '#ff922b',
    '#fd7e14',
    '#f76707',
    '#e8590c',
    '#d9480f'
  ],
  titan: [
    '#fcfeff',
    '#fafdff',
    '#f7f9fa',
    '#f0f3f5',
    '#e6ecf0',
    '#dde4eb',
    '#cfdae5',
    '#8fa1b2',
    '#6b8299',
    '#596c80'
  ],
  success: [
    '#d4ffcc',
    '#bfffb2',
    '#aaff99',
    '#94ff80',
    '#72e55c',
    '#54cc3d',
    '#3cb224',
    '#26990f',
    '#158000',
    '#116600'
  ],
  warning: [
    '#fff6e5',
    '#fff2d9',
    '#ffeabf',
    '#ffe1a6',
    '#ffd073',
    '#ffbf40',
    '#ffb21a',
    '#e59900',
    '#b27700',
    '#805500'
  ],
  danger: [
    '#ffbfca',
    '#ffa6b5',
    '#ff8ca0',
    '#ff738b',
    '#ff5975',
    '#ff4060',
    '#ff1a40',
    '#cc1433',
    '#990f26',
    '#660a1a'
  ],
  info: [
    '#ccf2ff',
    '#a6e9ff',
    '#8ce2ff',
    '#73dcff',
    '#59d6ff',
    '#3dc5f2',
    '#2badd9',
    '#399dbf',
    '#38778c',
    '#2d4e59'
  ],
  cerulean: [
    '#99ccff',
    '#80bfff',
    '#66b2ff',
    '#4da6ff',
    '#3399ff',
    '#1a8dff',
    '#0080ff',
    '#0073e5',
    '#0066cc',
    '#005ab2'
  ]
};

import { Card, Group, Text, useComputedColorScheme } from '@mantine/core';
import { AgGridReact } from 'ag-grid-react';
import { AgGridContainer } from '../..';
import { useRef } from 'react';
import { DataPanelAccordionItem } from '../../DataPanelAccordion';
import { DataPanelGridOptions } from '../../MilestoneDataPanels/MilestonesGridOptions';
import { NoDataToDisplay } from '../../MilestoneDataPanels/NoDataToDisplay';

interface iDataPanelGridProps {
  data: any;
  width: string | number | undefined;
  label: string;
  coldefs: any;
  status: string;
}

export const DataPanelGrid = ({
  data,
  width,
  label,
  coldefs,
  status
}: iDataPanelGridProps) => {
  const removedNulls = data.filter((d: any) => d !== null);
  const sortedData =
    removedNulls.length > 0
      ? removedNulls.sort(function (a: any, b: any) {
          return b.audit.generatedAt.localeCompare(a.audit.generatedAt);
        })
      : [];
  const gridRef = useRef<any>();
  // const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');
  const gridCSS =
    colorScheme === 'dark' ? 'ag-theme-alpine-dark' : 'ag-theme-alpine';
  const gridOptions = { ...DataPanelGridOptions };
  gridOptions.columnDefs = coldefs;
  let content;
  gridOptions.rowData = sortedData;
  let dataLength = sortedData.length >= 6 ? 6 : sortedData.length;
  const gridheight = 34 + dataLength * 40;
  let mappedStatus = status;

  const resizeListener = () => {
    if (gridRef.current.api) return;
    setTimeout(() => {
      gridRef.current.api.sizeColumnsToFit();
    }, 200);
    window.removeEventListener('resize', resizeListener);
  };

  function onFirstDataRendered() {
    // window.addEventListener('resize', resizeListener);
  }

  if (sortedData.length === 0) {
    content = <NoDataToDisplay />;
  } else {
    content = (
      <AgGridContainer>
        <div
          className={gridCSS}
          style={{
            height: gridheight,
            width: width || '900px',
            margin: -16,
            marginTop: -2
          }}
        >
          <AgGridReact
            ref={gridRef}
            gridOptions={gridOptions}
            onGridSizeChanged={onFirstDataRendered}
          />
        </div>
      </AgGridContainer>
    );
  }

  if (mappedStatus) {
    return <DataPanelAccordionItem
              name={label}
              status={mappedStatus}
              title={`${mappedStatus[0].toUpperCase() + mappedStatus.substring(1)} - ${
                label[0].toUpperCase() + label.substring(1)
              }`}
              content={content}
            />
        {/* {content} */}
      // </DataPanelAccordionItem>
    // );
  } else {
    return (
      <Card p="lg">
        <Group>
          <Text>{label[0].toUpperCase() + label.substring(1)}</Text>
        </Group>
        {content}
      </Card>
    );
  }
};

import axios from 'axios';
import { TokenManagerInterface, AccessToken } from '@okta/okta-auth-js';
import { View } from '../../containers/Views/stores/grid/models';
import { useOktaAuthStore } from '../../containers/Okta/store';

const dummyToken = '';
const createAxiosInstance = async (callParams: any, token: string) => {
  const axiosInstance = axios.create({
    headers: {
      Accept: 'application/json',
      Authorization: dummyToken ? `Bearer ${dummyToken}` : `Bearer ${token}`
    },
    paramsSerializer: (params) => {
      let result = Object.keys(params)
        .sort()
        .map((key) =>
          params['skipCaseMapping'] || params['view']
            ? `${key}=${encodeURIComponent(params[key])}`
            : `${key}=${encodeURIComponent(params[key])}`.toLowerCase()
        ) //
        .join('&');
      return result;
    }
  });

  return await axiosInstance(callParams);
};

const createAxiosPostInstance = async (callParams: any, token: string, headers: any) => {
  const axiosPostInstance = axios.create({
    headers: {
      ...headers,
      'Content-Type': 'application/json',
      Authorization: dummyToken ? `Bearer ${dummyToken}` : `Bearer ${token}`
    }
  });

  return await axiosPostInstance(callParams);
};

type AxiosParams = {
  method?: string;
  url: string;
  params?: Object;
  data?: any;
  headers?: any;
  view?: View;
};

const useAxios = () => {
  const authState = useOktaAuthStore.getState().authState;
  const oktaAuth = useOktaAuthStore.getState().oktaAuth;

  function get(config: AxiosParams) {
    config.method = 'get';
    return request(config);
  }

  function post(config: AxiosParams) {
    config.method = 'post';
    return request(config);
  }

  function put(config: AxiosParams) {
    config.method = 'put';
    return request(config);
  }

  function deleteReq(config: AxiosParams) {
    config.method = 'delete';
    return request(config);
  }
  /**
   * Api Request Method
   * @param {*} params
   * @returns api response
   */
  async function request(params: any, headers?: any) {
    // Changed so that more than just /search api url could be hit on other envs
    let env = ''
    // env = 'qa-ops'
    // env = 'uat-ops'
    // env = 'prod-ops'
    if (env) {
      const newUrl = params.url.split('.') 
      newUrl[1] = env
      params.url = newUrl.join('.')
    }
    let callParams = { ...params };
    const isAuthenticated = authState?.isAuthenticated || false;
    if (isAuthenticated) {
      const tokenManager: TokenManagerInterface = oktaAuth!.tokenManager;
      const { accessToken }: AccessToken = (await tokenManager.get(
        'accessToken'
      )) as AccessToken;
      callParams.accessToken = accessToken;
      try {
        let res;
        if (callParams.method === 'post') {
          res = await createAxiosPostInstance(callParams, accessToken, headers);
        } else {
          res = await createAxiosInstance(callParams, accessToken);
        }
        if (res.status < 200 || res.status >= 300) {
          alert(
            'Your search returned a status of ' +
              res.status +
              ' with the following error: "' +
              res.statusText +
              '"'
          );
          return null;
        }
        if (res.data?.errors) {
          const error = res.data?.errors[0];
          alert(
            'Your search returned a status of ' +
              error.code +
              ' with the following error: "' +
              error.message +
              '"'
          );
          return null;
        }
        return res.data;
      } catch (e) {
        Promise.reject(e);
      }
    }
  }

  return {
    get,
    post,
    put,
    deleteReq,
    request
  };
};

export default useAxios;

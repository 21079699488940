import { Box } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { useTID } from '../../helpers/useTestId';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { LicenseManager } from 'ag-grid-enterprise';

export const AgGridContainer = (props: any) => {
  LicenseManager.setLicenseKey(
    'CompanyName=Discovery,LicensedGroup=Discovery- Content Systems,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=4,LicensedProductionInstancesCount=0,AssetReference=AG-032189,SupportServicesEnd=21_October_2023_[v2]_MTY5Nzg0MjgwMDAwMA==18f2db0d1142fd6d350b2fee0dcd6bb0'
  );
  const { ref } = useElementSize();
  const TID = useTID('AgGridContainer', props.testid);

  return (
    <Box {...TID} ref={ref} style={{ height: '100%' }}>
      {props.children}
    </Box>
  );
};

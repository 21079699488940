import useAxios from '../services/axios/axios';
import { useConfigStore } from '../app/store';

export const fetchEventData = async (
  id: string,
  resolved?: boolean
) => {
    const { post } = useAxios();
    const baseURL = useConfigStore.getState().config.baseApiGatewayUrl + '/graphql/search'
    let eventPage = 1;

    const query = constructQuery(eventPage, id, resolved);
    if (!query) return null;
    const schema = {
        query: query
    };

    const eventData = await post({
        url: baseURL,
        data: JSON.stringify(schema)
    });
    if (!eventData) return []

    return eventData.data.event.records
};

const constructQuery = (eventPage: number, id: string, resolved?: boolean) => {
  const queryFilters = constructFilters(eventPage, id);
  if (!queryFilters) return null;
  const resolvedString = resolved ? 'resolved' : ''
  const query = `query {
            event(
                ${queryFilters}
            ) {
                records {
                    data
                    ${resolvedString}
                }
            }
          }`;

  return query;
};

const constructFilters = (eventPage: number, id: string) => {
  const queryFilters = `filters: {
        id:"${id}"
        page:${eventPage}
        limit:1000
    }`;
  return queryFilters;
};

import { Anchor, Divider, Stack, Text, useMantineTheme, useComputedColorScheme } from '@mantine/core';
import { ContainerComponent } from '../../components/ContainerComponent';

export function HowTo() {
  const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme('light');
  return (
    <ContainerComponent testid="Demo" title="How to use DiSCO">
      <Stack>
        <Anchor
          href="https://wbdstreaming.atlassian.net/wiki/spaces/MSC/pages/399180635/DiSCO+New+User+Guide"
          target="_new"
        >
          DiSCO New User Guide
        </Anchor>
        <Anchor
          href="https://wbdstreaming.atlassian.net/wiki/spaces/MSC/pages/251693124/DiSCO+Digital+Supply+Chain+Overview"
          target="_new"
        >
          Confluence Page for all things DiSCO
        </Anchor>
      </Stack>
      <Divider my={16} />
      <Text
        size="lg"
        ml={6}
        mb={12}
        fw={700}
        color={colorScheme === 'dark' ? '#808088' : theme.colors.titan[9]}
      >
        Need to reach out?
      </Text>
      <Stack>
        <Anchor
          href="https://app.smartsheet.com/b/form/15effbc2c76745dca987b0519ea2caf8"
          target="_new"
        >
          Intake form for DiSCO requests
        </Anchor>
        <Anchor href="https://warnermedia.slack.com/archives/C056X2DJ0KD" target="_new">
          Questions? Feel free to post in #ask-disco slack channel
        </Anchor>
      </Stack>
    </ContainerComponent>
  );
}

export default HowTo;
import OktaAuth, { AuthState } from '@okta/okta-auth-js';
import { create } from 'zustand';

import { iOktaAuth, initialOktaAuth } from './models';

export const useOktaAuthStore = create<iOktaAuth>()(
  (set, get) => ({
    ...initialOktaAuth,
    SetOktaAuth: (newOktaAuth: OktaAuth) => {
      set({ oktaAuth: newOktaAuth });
    },
    SetAuthState: (newAuthState: AuthState) => {
      set({ authState: newAuthState });
    },
  })
);

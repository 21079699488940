import { Group, Text, UnstyledButton } from '@mantine/core';
import { Badge } from '../../components/Badge';
import { useSearchStore } from '../Search/store';
import { searchBarValuesMapper } from './values';
import { iSearch } from '../Search/models';
import { X } from 'tabler-icons-react';
import { useAppConfigManager } from '../../components/AppConfigs/store';
import { separateByCapital } from '../../utils/utilities';

interface SynopsisBadge {
  removeSearchElement: Function;
  badgeElement: string;
}

export const getSynopsisLabel = (item: string) => {
  if (!item) {
    return ''
  }
  if (item === 'OfferingType') {
    return 'Content Class';
  }
  if (item === 'LinearExpired') {
    return 'Show Expired';
  }
  if (item === 'ContentTypeAcquire') {
    return 'Content Type';
  }
 return separateByCapital(item);
};

export const SynopsisBadge = (props: SynopsisBadge) => {
  const { removeSearchElement, badgeElement } = props;
  const activeSearch = useSearchStore((state) => state.search);
  const { timeRelative } = activeSearch;
  const renderOnlyTrueValues = ['LinearExpired'];
  const ValueMapper = useAppConfigManager((state) => state.ValueMapper);

  const CreateBadgeGroup = (items: any, valueKey: string) => {
    const badgesGroup = items.map((subBadge: string) => {
      return (
        <Badge
          testid={`group-badge-${ValueMapper(valueKey as keyof iSearch, subBadge)}`}
          key={subBadge}
          size="xs"
          variant="outline"
          color={timeRelative ? 'blue' : 'violet'}
          rightSection={
            <UnstyledButton
              onClick={(event: any) => {
                removeSearchElement(valueKey, subBadge);
                event.stopPropagation();
              }}
            >
              <X size={12} strokeWidth={2} color="gray" />
            </UnstyledButton>
          }
          style={{
            marginLeft: '0px',
            marginRight: '0px',
            padding: '4px 4.5px'
          }}
        >
          {
            /* {searchBarValuesMapper[valueKey as keyof iSearch][subBadge]} */
            ValueMapper(valueKey as keyof iSearch, subBadge)
          }
        </Badge>
      );
    });

    return (
      <Group
        gap="xs"
        style={{
          marginLeft: '-15px',
          padding: '4px',
          maxWidth: '90%',
          borderRadius: '30px'
        }}
      >
        {badgesGroup}
      </Group>
    );
  };

  if (
    renderOnlyTrueValues.includes(badgeElement) &&
    !activeSearch[badgeElement as keyof iSearch]
  ) {
    return null;
  }

  return (
    <Group key={badgeElement}>
      <Text size="xs">{getSynopsisLabel(badgeElement)}:</Text>
      {Array.isArray(activeSearch[badgeElement as keyof iSearch]) ? (
        CreateBadgeGroup(activeSearch[badgeElement as keyof iSearch], badgeElement)
      ) : (
        <Badge
          testid={`${
            searchBarValuesMapper[badgeElement as keyof iSearch]?.[
              activeSearch[badgeElement as keyof iSearch]!.toString()
            ]
          }`}
          key={badgeElement}
          style={{ margin: '-10px' }}
          size="xs"
          variant="outline"
          color={timeRelative ? 'blue' : 'violet'}
          rightSection={
            typeof activeSearch[badgeElement as keyof iSearch] === 'boolean' ? (
              false
            ) : (
              <UnstyledButton
                onClick={(event: any) => {
                  removeSearchElement(badgeElement);
                  event.stopPropagation();
                }}
              >
                <X size={12} strokeWidth={2} color="gray" />
              </UnstyledButton>
            )
          }
        >
          {
            ValueMapper(badgeElement, activeSearch[badgeElement as keyof iSearch]!.toString())
          }
        </Badge>
      )}
    </Group>
  );
};

export default SynopsisBadge;

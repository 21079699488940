import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import {
  iAppConfig,
  iAppConfigManager,
  initialAppConfigValues,
  initialAppConfigManagerValues
} from './models';
import { AppConfigsService } from '../../services/appConfigs/appConfigsService';
import {
  iFieldMapping,
  iItemType,
  iSelectSearchInputsSource
} from '../ListSortable/models';
import { useFootprintStore } from '../../containers/Footprints/store';

export const useAppConfigStore = create<iAppConfig>()(
  devtools((set, get) => ({
    ...initialAppConfigValues
  }))
);

export const useAppConfigManager = create<iAppConfigManager>()(
  devtools((set, get) => ({
    ...initialAppConfigManagerValues,
    GetConfig: async (type: string) => {
      if (get().isLoaded) return;
      const resp = await AppConfigsService.getAppConfigs(type);
      resp.forEach((element) => {
        if (element.type.includes('dropdown:')) {
          if (element.type === 'dropdown:markets') {
            const queryMarkets = element.data.reduce((memo: any, op: any) => {
              if ('children' in op) {
                op.children.forEach((child: any) => {
                  if ('children' in child) {
                    child.children.forEach((c: any) => memo.push(c));
                  }
                  memo.push(child);
                });
                memo.push(op);
                return memo;
              } else {
                memo.push(op);
                return memo;
              }
            }, []);
            set({ queryMarkets });
          }
          if (element.type === 'dropdown:networks') {
            const queryNetworks = element.data.reduce((memo: any, op: any) => {
              if ('children' in op) {
                op.children.forEach((child: any) => {
                  if ('children' in child) {
                    child.children.forEach((c: any) => memo.push(c));
                  }
                  memo.push(child);
                });
                memo.push(op);
                return memo;
              }
            }, []);
            set({ queryNetworks });
          }
          if (element.type === 'dropdown:destinations') {
            const queryDestinations = element.data.reduce((memo: any, op: any) => {
              if ('children' in op) {
                op.children.forEach((child: any) => {
                  if ('children' in child) {
                    child.children.forEach((c: any) => memo.push(c));
                  }
                  memo.push(child);
                });
                memo.push(op);
                return memo;
              }
            }, []);
            set({ queryDestinations });
          }
          if (element.type === 'dropdown:statusHierarchy') {
            const queryStatusCodes = element.data.reduce((memo: any, op: any) => {
              addChildren(op, memo);
              memo.push(op);
              return memo;
            }, []);
            set({ queryStatusCodes });
          }
          let dd = get().dropdowns;
          const t = element.type.split(':');
          if (t.length > 2) {
            dd[t[1] + ':' + t[2]] = element.data as iItemType[];
          } else {
            dd[t[1]] = element.data as iItemType[];
          }
          set({ dropdowns: dd });
        }
        if (element.type.includes('fieldMappings')) {
          let fM = get().fieldMappings;
          const t = element.type.split(':');
          if (t.length > 2) {
            fM[t[1] + ':' + t[2]] = element.data as iFieldMapping[];
          } else {
            fM[t[1]] = element.data as iFieldMapping[];
          }
          set({ fieldMappings: fM });
        }
        if (element.type.includes('taskValueMapper')) {
          set({ taskValueMapper: element.data });
        }
        if (element.type.includes('componentValueMapper')) {
          set({ componentValueMapper: element.data });
        }
      });
    },
    GetSearchInputs: (footprint?: string): iSelectSearchInputsSource[] => {
      let dd = get().dropdowns;
      const searchInputs = dd?.searchInputs as iSelectSearchInputsSource[];
      if (searchInputs !== undefined && searchInputs?.length > 0) {
        if (footprint) {
          return searchInputs.filter((i) =>
            i?.footprint?.some((fp) => fp.footprint === footprint)
          );
        }
        return searchInputs;
      }
      return [{ label: 'Any', value: '' }];
    },
    GetDropDown: (name: string, footprint?: string) => {
      let dd = get().dropdowns;
      if (dd[name] !== undefined && dd[name].length > 0) {
        if (footprint) {
          return dd[name].filter((i) => i.footprint!.includes(footprint));
        }
        return dd[name];
      }
      return [{ label: 'Any', value: '' }];
    },
    GetFieldMap: (name: string) => {
      let fM = get().fieldMappings;
      if (fM[name] !== undefined && fM[name].length > 0) {
        return fM[name];
      }
      return [{ path: 'Any', value: '' }];
    },
    ValueMapper: (name: string, value: string) => {
      if (!get().isLoaded) return 'not yet';
      name = mapDropDowns(name); 
      let dd = get().dropdowns;
      if (dd[name] === undefined || dd[name].length === 0) {
        return 'unknwn dd: ' + name;
      }
      const ddf = dd[name].filter((d) => {
        return d.value === value;
      });
      return ddf[0]?.label?.length > 0 ? ddf[0].label : 'missing';
    },
    ToggleIsLoading: (explicit?: boolean) => {
      if (explicit !== undefined) {
        set({ isLoaded: explicit });
      } else {
        set({ isLoaded: !get().isLoaded });
      }
    }
  }))
);

function mapDropDowns(name: string) {
  switch (name) {
    case 'OfferingType':
      return 'contentClassTypes';
      break;
    case 'ContentSource':
      return 'contentSource';
      break;
    case 'Partner':
      return 'partners';
      break;
    case 'EntityType':
      return 'entityType';
      break;
    case 'Milestone':
      return 'milestones:' + useFootprintStore.getState().active.id;
      break;
    case 'MilestoneStatus':
      return 'milestoneStatuses';
      break;
    case 'ScheduleType':
      return 'scheduleTypes';
      break;
    case 'LinearExpired':
      return 'linearExpired';
      break;
    case 'ContentType':
      return 'contentTypes';
      break;
    case 'Network':
      return 'networks';
      break;
    case 'Feed':
      return 'feeds';
      break;
    case 'SuppressRepeats':
      return 'suppressRepeats';
      break;
    case 'IsLive':
      return 'isLive';
      break;
    case 'AdMarkers':
      return 'adMarkers';
      break;
    case 'MidRollAdsAllowed':
      return 'midRollAdsAllowed';
      break;
    case 'ContentTypeAcquire':
      return 'contentTypesAcquire';
      break;
    case 'Distributor':
      return 'distributors';
      break;
    default:
      return name;
      break;
  }
}

const addChildren = (obj: { children?: any[] }, memo: any) => {
  obj.children?.forEach((child: any) => {
    if ('children' in child) {
      addChildren(child, memo);
    } else {
      child.children.forEach((c: any) => memo.push(c));
    }
    memo.push(child);
  });
};

import { Group, Popover, useComputedColorScheme, useMantineTheme } from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import { StatusColor, StatusIcon } from '../../components/';
import { MilestoneBriefPanel } from './_briefPanel';
import { STATUS_MAP, milestoneHeaders } from '../../constants/constants';
import { getStatus, getCodeDetails } from '../../utils/getStatus';
import { QuestionMark } from 'tabler-icons-react';
import { useState } from 'react';
import { usePanelStateStore } from './store';
import { iMilestone, iCodeDetail } from './models';
import { useFootprintStore } from '../../containers/Footprints/store';
import { getMilestoneByNameAndType } from '../../containers/Views/_columnDefs/offering/offeringValueGetters';
import { ColDef } from 'ag-grid-community';
import { fetchServerTaskHistory } from '../../api/fetchServerTaskHistory';

export const Milestone = (props: iMilestone) => {
  const { type, column, data, status, codes, field } = props;
  const activeFootprint = useFootprintStore.getState().active.id;
  const colorScheme = useComputedColorScheme('light');
  let identifyingType = `-${type}`;
  if (!type) {
    identifyingType = '';
  }

  const [detailData, setDetailData] = useState<any[]>([]);

  let footprintId = '';
  let footprintType = '';
  let statusObject = false;
  // Add additional footprints as needed
  switch (activeFootprint) {
    case 'footprint-offering':
      footprintId = data.offering.id;
      footprintType = 'ondemand';
      break;
    case 'footprint-linear':
      footprintId = data.id;
      footprintType = 'linear';
      break;
    case 'footprint-acquire':
      footprintId = data.id;
      footprintType = 'acquisition';
      break;
    case 'footprint-asset':
      footprintId = data.id;
      footprintType = 'offeringsAsset';
      statusObject = true;
      break;
    case 'footprint-crossplatform':
      footprintId = data.id;
      footprintType = 'affiliateofferings';
      statusObject = true;
      break;
    default:
      footprintId = data.offering.id;
      footprintType = 'ondemand';
      break;
  }
  const fetchDetails = async () => {
    let result = await fetchServerTaskHistory(
      data,
      footprintId,
      footprintType,
      type || '',
      field || '',
      statusObject
    );
    result ? setDetailData(result.rowData) : setDetailData([]);
  };
  // column is the column identifier
  // data._id is the row identifier
  // identifyingType is utilized for the multi-milestone cells
  const id = `${column}-${footprintId}${identifyingType}`;
  const dataPanelId = `DATA-${id}`;
  const briefPanelId = `BRIEF-${id}`;
  const panelState = usePanelStateStore((state) => state.panelState);
  const setPanelState = usePanelStateStore((state) => state.SetPanelState);

  const ref = useClickOutside(() => {
    setPanelState('CLOSED');
  });

  const theme = useMantineTheme();
  const statusColor = StatusColor();
  const headerName = column;
  let milestoneHeaderName;
  if (column === 'Overall Status') {
    milestoneHeaderName = 'OverallStatus';
  } else if (column === 'Acquisition Overall Status') {
    milestoneHeaderName = 'AcquisitionOverallStatus';
  } else {
    if (type) {
      milestoneHeaderName = field + type;
    } else {
      milestoneHeaderName = field;
    }
  }
  const milestoneConstants =
    milestoneHeaders[milestoneHeaderName as keyof typeof milestoneHeaders];
  if (milestoneConstants === undefined) {
    return <QuestionMark />;
  }

  let cdes = codes || ([] as iCodeDetail[]);

  const milestoneProps = {
    status: getStatus(status),
    codeDetails: getCodeDetails(cdes),
    data: detailData,
    headerName: milestoneConstants.headerName,
    // milestonesGrids: milestoneConstants.milestonesGrids,
    type,
    types: [type!]
  };

  function onMouseEnter() {
    if (panelState !== dataPanelId) setPanelState(briefPanelId);
  }

  function onMouseLeave() {
    if (panelState === briefPanelId) setPanelState('CLOSED');
  }

  let nonStatus = false;
  const nonStatuses = ['nodata', 'na', 'null'];
  if (nonStatuses.includes(props.status)) {
    nonStatus = true;
  }
  if (type === '') nonStatus = true;

  async function onClick() {
    switch (panelState) {
      case dataPanelId:
        setPanelState(briefPanelId);
        break;

      case 'CLOSED':
        setPanelState(briefPanelId);
        break;

      default:
        if (!nonStatus) {
          await fetchDetails();
          setPanelState(dataPanelId);
        } else {
          setPanelState('CLOSED');
        }
        break;
    }
  }

  let updated;
  if (headerName === 'Overall Status') {
    let footprintMilestoneName = '';
    // Add additional footprints as needed
    switch (activeFootprint) {
      case 'footprint-offering':
        footprintMilestoneName = 'offering-footprint';
        break;
      case 'footprint-linear':
        footprintMilestoneName = 'schedule-footprint';
        break;
      case 'footprint-acquire':
        footprintMilestoneName = 'acquire-footprint';
        break;
      case 'footprint-asset':
        footprintMilestoneName = 'offeringAsset-footprint';
        break;
      case 'footprint-crossplatform':
        footprintMilestoneName = 'affiliateOfferings-footprint';
        break;
      default:
        footprintMilestoneName = 'offering-footprint';
        break;
    }
    let overall: any = {};
    if (statusObject) {
      overall = data.status[footprintMilestoneName];
    } else {
      overall = data.milestones.find(
        (m: { name: string }) => m.name === footprintMilestoneName
      );
    }
    if (overall && overall.audit) {
      updated = overall.audit.updatedAt;
    }
  } else {
    const { name } = getMilestoneByNameAndType(props.colDef as ColDef);
    let activity: any = {};
    if (statusObject) {
      activity = data.status[name as keyof typeof data.status];
    } else {
      activity = data.milestones.find((m: { name: string }) => m.name === name);
    }
    if (activity && activity.audit) {
      updated = activity.audit.updatedAt;
    }
  }

  let statusWithBadge = milestoneProps.status;
  if (milestoneProps.codeDetails.code != '') {
    const scParts = milestoneProps.codeDetails.code.split(':');
    if (scParts.length > 2) {
      let statusName = scParts[scParts.length - 2];
      STATUS_MAP.forEach((val: any, status: any) => {
        if (val.includes(statusName.toLowerCase())) {
          statusWithBadge = milestoneProps.codeDetails.code;
        }
      });
    }
  }

  return (
    <Popover
      opened={panelState === briefPanelId || panelState === dataPanelId}
      position="left"
      offset={-1}
      withArrow
      withinPortal
      styles={{
        arrow: {
          border: `1px ${statusColor[milestoneProps.status]} solid`,
          backgroundColor:
            colorScheme === 'dark' ? theme.colors.gray[8] : theme.colors.gray[1]
        },
        dropdown: {
          backgroundColor:
            colorScheme === 'dark' ? theme.colors.gray[8] : theme.colors.gray[1],
          padding: 0,
          border: `1px ${theme.colors.gray[6]} solid`
        }
      }}
    >
      <Popover.Target>
        <Group onMouseOver={onMouseEnter} onMouseOut={onMouseLeave} onClick={onClick}>
          <StatusIcon status={statusWithBadge} />
        </Group>
      </Popover.Target>
      <Popover.Dropdown>
        {panelState === briefPanelId && (
          <MilestoneBriefPanel
            column={headerName}
            status={milestoneProps.status}
            code={milestoneProps.codeDetails.code}
            detail={milestoneProps.codeDetails.detail}
            data={milestoneProps.codeDetails.data}
            type={type}
            updated={updated}
          />
        )}
        {panelState === dataPanelId && (
          <Group ref={ref}>{milestoneConstants.component(milestoneProps)}</Group>
        )}
      </Popover.Dropdown>
    </Popover>
  );
};

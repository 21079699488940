import { Link, redirect } from 'react-router-dom';
import { Group, Box, Collapse, ThemeIcon, Text, UnstyledButton, useDirection, useComputedColorScheme  } from '@mantine/core';
import {
  Icon as TablerIcon,
  CalendarStats,
  ChevronLeft,
  ChevronRight
} from 'tabler-icons-react';
// import useStyles from './NavbarLinksGroupStyles';
import classes from './NavbarGroups.module.css';
import { useEffect } from 'react';
import { useNavBarStore } from '../Navbar/store';
import { useNavBarNestedStore } from './store';

export interface iLinksGroupProps {
  icon: TablerIcon;
  label: string;
  initiallyOpened?: boolean;
  path: string;
  links?: { label: string; link: string }[];
}

export function LinksGroup({
  icon: Icon,
  label,
  initiallyOpened,
  path,
  links
}: iLinksGroupProps) {

  const colorScheme = useComputedColorScheme('light')
  const { dir } = useDirection();
  const hasLinks = Array.isArray(links);
  const activeItem = useNavBarNestedStore((state) => state.ActiveItem);
  const SetActiveItem = useNavBarNestedStore((state) => state.SetActiveItem);
  const activeLink = useNavBarNestedStore((state) => state.ActiveLink);
  const SetActiveLink = useNavBarNestedStore((state) => state.SetActiveLink);
  const navCloseOnSelect = useNavBarStore((state) => state.CloseOnSelect);
  const NavbarOpened = useNavBarStore((state) => state.Open);
  const SetNavbarOpened = useNavBarStore((state) => state.SetOpen);
  const ChevronIcon = dir === 'ltr' ? ChevronRight : ChevronLeft;
  const items = (hasLinks ? links : []).map((subLink) => (
    <Text
      component={Link}
      className={classes.link}
      to={subLink.link}
      key={subLink.label}
      onClick={(event) => {
        event.preventDefault();
        SetActiveLink(subLink.link);
        handleLinkClick(subLink.link);
      }}
      style={{
        color: activeLink === subLink.link ? 'blue' : 'black'
      }}
    >
      {subLink.label}
    </Text>
  ));

  const handleLinkClick = (link: string) => {
    SetActiveItem(link);
    if (navCloseOnSelect) {
      SetNavbarOpened(false);
    }
    redirect(link);
  };

  const handlePathClick = (path: string) => {
    // redirect(path);
    SetActiveItem(path);
    SetActiveLink('');
    if (!hasLinks && navCloseOnSelect) {
      SetNavbarOpened(false);
    }
  };

  useEffect(() => {
    let page = window.location.href.split('/')[3] || '/';
    if (page !== '/') {
      page = '/' + page;
    }
    SetActiveItem(page);
  }, []);

  return (
    <>
      {/* <Text component={Link} to={path}>
        {label}
      </Text> */}
      <UnstyledButton
        component={Link}
        to={path}
        onClick={() => handlePathClick(path)}
        className={`${classes.control} ${colorScheme === 'dark' ? classes.controlDark : classes.controlLight}`}
      >
        <Group justify="space-between" >
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <ThemeIcon
              color="blue"
              variant={activeItem === path ? 'filled' : 'light'}
              size={30}
            >
              <Icon size={18} />
            </ThemeIcon>
            <Box ml="md">{label}</Box>
          </Box>
          {hasLinks && (
            <ChevronIcon
              className={classes.chevron}
              size={14}
              style={{
                transform: NavbarOpened
                  ? `rotate(${dir === 'rtl' ? -90 : 90}deg)`
                  : 'none'
              }}
            />
          )}
        </Group>
      </UnstyledButton>
      {hasLinks ? <Collapse in={NavbarOpened ? true : false}>{items}</Collapse> : null}
    </>
  );
}

const mockdata = {
  label: 'Releases',
  icon: CalendarStats,
  path: '/',
  links: [
    { label: 'Upcoming releases', link: '/' },
    { label: 'Previous releases', link: '/' },
    { label: 'Releases schedule', link: '/' }
  ]
};

export function NavbarLinksGroup() {
  return (
    <Box
      style={{
        minHeight: 220,
        padding: 'var(--mantine-spacing-md)',
        backgroundColor: 'light-dark(var(--mantine-color-white), var(--mantine-color-dark-6))',
      }}
    >
      <LinksGroup {...mockdata} />
    </Box>
  );
}

import { create } from 'zustand';
import { iStatusCounts, iStatusCountsStore, initialStatusCountsStore } from './models';
import { iSearch } from '../Search/models';
import { useSearchStore } from '../Search/store';
import { getStatus } from '../../utils/getStatus';

export const useStatusCountsStore = create<iStatusCountsStore>()((set, get) => ({
  ...initialStatusCountsStore,
  SetOfferingStatusCounts: (newCounts: iStatusCounts) => {
    set({ offeringStatusCounts: newCounts });
  },
  SetStatusCounts: (newCounts: iStatusCounts) => {
    set({ statusCounts: newCounts });
  },
  SetStatusField: (newStatusField: string) => {
    set({ statusField: newStatusField });
  },
  SetFieldStatusChartData: async (
    fetchStatusCount: (
      startDate: Date | null,
      endDate: Date | null,
      searchState: iSearch,
      milestoneName: string
    ) => Promise<any>,
    apiName?: string
  ) => {
    const { startDate, endDate } = useSearchStore.getState();
    let statusField = get().statusField;
    const chartSearch = useSearchStore.getState().chartSearch;
    if (apiName) {
      if (statusField.includes('footprint') && !statusField.includes(apiName)) {
        statusField = apiName + '-footprint';
      }
    }

    const countResult = await fetchStatusCount(
      startDate,
      endDate,
      chartSearch,
      statusField
    );

    const dataCount = {
      error: 0,
      failure: 0,
      processing: 0,
      pending: 0,
      completed: 0
    };
    if (countResult && countResult.length > 0) {
      countResult.forEach((i: any) => {
        const status = getStatus(i.status);
        if (status === 'queued' || status === 'pending') {
          dataCount.pending += i.count;
        } else if (status === 'skipped' || status === 'completed') {
          dataCount.completed += i.count;
        } else if (Object.keys(dataCount).includes(status)) {
          dataCount[status as keyof typeof dataCount] = i.count;
        }
      });
    }
    get().SetStatusCounts(dataCount);
  }
}));

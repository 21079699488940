import { Result } from '../../api/offering/fetchOfferingRowData';
import { iSelectItem } from '../../components/ListSortable/models';
import { ItemType } from '../../components/ListSortable/ListSortable';
import { iSearch } from '../Search/models';
import { View, initialActiveGridState } from '../Views/stores/grid/models';
import { Notification } from '../../components/Notification/models';
export interface iSavedViews {
  // Views State Management Start
  notificationModalOpen: boolean; // indicates the notification modal is displayed
  isOpen: boolean; //            indicates the SavedViews modal is displayed
  isDraggable: boolean; //       indicates the dragable state of the SavedViews listing
  isLoading: boolean; //         indicates the status of the fetching of the SavedViews from the DB
  isFetching: boolean; //        indicates we are currently trying to load the SavedViews from the DB, don't try again
  selected: ItemType; //         the currently selected SavedSearch
  isDefault: boolean; //         indicates this is the default view
  isEditable: boolean; //        indicates the current item can be editted
  removeModal: string; //        indicates which view to remove
  active: View; //         the currectly selected View
  global: View[]; //       the loaded list of Global Views
  personal: View[]; //     the loaded list of Personal Views
  list: iSelectItem[]; //          the combined list of Personal and Global/Preset Views names in Select format
  globalList: iSelectItem[]; //     the list of Global Views in Select format
  checked: iSelectItem; //         which personal view is checked, id
  clickOutside: boolean; 
  defaultViewId: string;
  GetViewByID: (id: string) => Promise<View>; //       load a specific view by its ID
  GetViews: () => Promise<iSavedViews>;
  LoadViews: (onLoad?: boolean) => Promise<void>; //                        load the Personal and Global views from the DB, and combine for display
  SaveActiveView: (view: View) => Promise<string>; // save changes to the current Active View
  SaveNotification: (notification: Notification) => Promise<string | null>; // save changes to the current Active View
  SetActiveView: (id: string, onLoad?: boolean) => Promise<void>; //          set the passed view as the new current active View
  SetChecked: (item: iSelectItem) => void; //        starred default view
  SetList: (payload: iSelectItem[]) => void;
  SetListComplete: (payload: iSelectItem[]) => void;
  SetGlobalList: (payload: iSelectItem[]) => void;
  SetRemoveModal: (id: string) => void; //
  SetNotificationModalOpen: (payload: boolean) => void;
  SetIsOpen: (newState: boolean) => void; //
  ToggleIsOpen: () => void; //
  ToggleIsDraggable: () => void; //
  ToggleIsLoading: () => void; //
  ToggleIsDefault: () => void; //
  ToggleIsEditable: () => void; //
  ToggleClickOutside: () => void;
  // Views State Management End
  SetRowData: (rowData: any) => void;
  rowData: any;
  notFoundCodes: string[];
  FetchGridRowData: (
    startDate: Date | null,
    endDate: Date | null,
    searchCriteria: iSearch,
    apiName: string,
    fetchServerData: (
      startDate: Date | null,
      endDate: Date | null,
      searchState: iSearch,
      limit: number,
      page: number
    ) => Promise<Result>,
    fetchStatusCount: (
      startDate: Date | null,
      endDate: Date | null,
      searchState: iSearch,
      milestoneName: string
    ) => Promise<Result>
  ) => Promise<void>;
  UpdateActiveView: (field: string, value: any) => any;
  SetChartActive: (payload: boolean) => void;
}

export const initialSavedViewsValues = {
  notificationModalOpen: false,
  isOpen: false,
  isDraggable: false,
  isLoading: true,
  isFetching: false,
  selected: {} as ItemType,
  isDefault: false,
  isEditable: false,
  active: initialActiveGridState,
  global: [] as View[],
  personal: [] as View[],
  list: [] as iSelectItem[],
  globalList: [] as iSelectItem[],
  checked: {} as iSelectItem,
  removeModal: '',
  clickOutside: true,
  notification: {} as Notification,
  defaultViewId: '',
  GetViewByID: (id: string) => {},
  LoadViews: (onLoad?: boolean) => {},
  GetViews: () => {},
  SaveActiveView: (view: View) => {},
  SetActiveView: (id: string, onLoad?: boolean) => {},
  SetChecked: (item: iSelectItem) => {},
  SetList: (payload: iSelectItem[]) => {},
  SetListComplete: (payload: iSelectItem[]) => {},
  SetGlobalList: (payload: iSelectItem[]) => {},
  SetRemoveModal: (id: string) => {},
  SetIsOpen: (newState: boolean) => {}, //
  SetNotificationModalOpen: (payload: boolean) => {},
  ToggleIsOpen: () => {},
  ToggleIsDraggable: () => {},
  ToggleIsLoading: () => {},
  ToggleIsDefault: () => {},
  ToggleIsEditable: () => {},
  SetRowData: () => {},
  rowData: [],
  notFoundCodes: [],
  FetchGridRowData: async (searchCriteria: any) => {},
  UpdateActiveView: () => {},
  SetChartActive: (payload: boolean) => {},
  ToggleClickOutside: () => {},
};
